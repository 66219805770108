import { IDialogueSnapshot } from "../../backend/chatbot/Dialogue"
import { step } from "../../backend/chatbot/decorators/step"
import BaseScript from "../BaseScript"
import { DialogueIDs } from "../DialogueIDs"
import type { SurveyScriptState } from "../createSurveyDialogue"
import type { IStepData, IStepResult } from "../../backend/chatbot/models/IStep"
import type { ISurveyResponse } from "../../models/ISurvey"
import AdHocDialogue from "../../backend/chatbot/AdHocDialogue"

type State = SurveyScriptState

class IAPTAccommodation extends BaseScript<State> {
  readonly name: string = "IAPTAccommodation"

  /** Script Steps */

  @step
  start(d: IStepData<State>): IStepResult<State> {
    const name = this.getName(d.state)
    this.timeEvent(`${this.name} Finished`)
    return {
      body: this.t(
        "Thanks {name}. I'm going to ask you a few questions about your accommodation status",
        { name }
      ),
      nextStep: this.step1
    }
  }

  @step
  end(d: IStepData<State>): IStepResult {
    this.track(`${this.name} Finished`)
    return super.end(d)
  }

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0)
    return {
      body: this.t("Which best describes your living situation?"),
      prompt: {
        id: this.getPromptId("step1"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Owner occupier"), value: "OWN" },
          {
            body: this.t("Tenant - local authority or registered social housing provider"),
            value: "RENT_PUBLIC"
          },
          { body: this.t("Tenant - private landlord"), value: "RENT_PRIVATE" },
          { body: this.t("Living with family"), value: "FAMILY_LONG_TERM" },
          { body: this.t("Living with friends"), value: "FRIENDS_LONG_TERM" },
          { body: this.t("University or college accommodation"), value: "UNIVERSITY" },
          {
            body: this.t("Accommodation tied to a job (including Armed Forces)"),
            value: "JOB_RELATED"
          },
          { body: this.t("Mobile accommodation"), value: "MOBILE" },
          { body: this.t("Care home without nursing"), value: "CARE_HOME_NO_NURSING" },
          { body: this.t("Care home with nursing"), value: "CARE_HOME_WITH_NURSING" },
          {
            body: this.t(
              "Specialist housing (with suitable adaptations to meet impairment needs and support to live independently)"
            ),
            value: "SPECIALIST_HOUSING"
          },
          { body: this.t("Rough sleeper"), value: "HOMELESS" },
          { body: this.t("Squatting"), value: "SQUATTING" },
          { body: this.t("Sofa surfing"), value: "SOFA_SURFING" },
          {
            body: this.t("Staying with friends/family as a short term guest"),
            value: "FAMILY_FRIENDS_SHORT_TERM"
          },
          {
            body: this.t("Bed and breakfast accommodation to prevent or relieve homelessness"),
            value: "BED_AND_BREAKFAST"
          },
          { body: this.t("Sleeping in a night shelter"), value: "SHELTER" },
          { body: this.t("Hostel to prevent or relieve homelessness"), value: "HOSTEL" },
          {
            body: this.t("Temporary housing to prevent or relieve homelessness"),
            value: "TEMPORARY"
          },
          { body: this.t("Admitted patient settings"), value: "ADMITTED_PATIENT" },
          { body: this.t("Criminal justice settings"), value: "CRIMINAL_JUSTICE" },
          { body: this.t("Other"), value: "NOT_LISTED" }
        ],
        dataPointsName: "IAPTAccommodation - step1",
        isUndoAble: true
      },
      nextStep: this.handleStep1
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "1",
    "1. Which best describes your living situation?",
    "accommodationResponses"
  )
  handleStep1(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.step2 }
  }

  @step
  step2(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Would you describe your accommodation as settled? (Settled is defined by the NHS as secure, medium to long term accommodation)"
      ),
      prompt: {
        id: this.getPromptId("step2"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: "YES" },
          { body: this.t("No"), value: "NO" },
          { body: this.t("Don't want to say"), value: "NOT_ANSWERED" },
          { body: this.t("Don't know"), value: "UNKNOWN" }
        ],
        dataPointsName: "IAPTAccommodation - step2",
        isUndoAble: true
      },
      nextStep: this.handleStep2
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "2",
    "2. Would you describe your accommodation as settled?",
    "accommodationResponses"
  )
  handleStep2(_d: IStepData<State, ISurveyResponse>): IStepResult {
    return { nextStep: this.end }
  }
}

export default class IAPTAccommodationDialogue extends AdHocDialogue<State, BaseScript<State>> {
  static id = DialogueIDs.IAPTAccommodation
  readonly name: string = "IAPTAccommodationDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(IAPTAccommodationDialogue.id, new IAPTAccommodation(), state, snapshot)
  }
}
