import BaseScript, { BaseScriptState } from "../../../BaseScript"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { step } from "../../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { IDefaultChatFlowMessagesCollectGender, ISelectableExtended } from "@limbic/types"

export type ICollectGenderSettings = {
  optionsGender?: ISelectableExtended[] | undefined
  optionsGenderSameAsBirth?: ISelectableExtended[] | undefined
  messages?: IDefaultChatFlowMessagesCollectGender
}

interface State extends BaseScriptState {
  skipSameGenderAsBirth?: boolean
  withTextPrompt?: boolean
  genderOther?: string
}

export type CollectGenderScriptState = State

export class CollectGenderScript extends BaseScript<State> {
  readonly name: string = "CollectGenderScript"
  protected genderOptions: ISelectableExtended[] | undefined
  protected genderSameAsBirthOptions: ISelectableExtended[] | undefined
  protected messages: IDefaultChatFlowMessagesCollectGender | undefined
  constructor(settings: ICollectGenderSettings | undefined = {}) {
    super()
    this.genderOptions = settings?.optionsGender ?? []
    this.genderSameAsBirthOptions = settings?.optionsGenderSameAsBirth ?? []
    this.messages = settings?.messages ?? {}
  }

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askGender }
  }

  @step.logState
  askGender(d: IStepData<State>): IStepResult {
    const genders = this.genderOptions
    if (!genders?.length) {
      this.logBreadcrumb("GENDERS NOT FOUND", d.state, { genders })
      this.logMessage("GENDERS NOT FOUND")
      return { nextStep: this.end }
    }

    return {
      body: this.t(
        this.messages?.askGender ?? "Which gender do you identify as?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askGender"),
        trackResponse: true,
        type: "inlinePicker",
        choices: genders,
        dataPointsName: "askGender",
        textPrompt: d.state.withTextPrompt
          ? { forceValue: true, placeholder: this.t("Other (please specify)") }
          : undefined
      },
      nextStep: this.handleGenderWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: CollectGenderScript) => {
    const genders = script.genderOptions
    const isAnswerFromList = !!genders?.find(s => s.value === d.response)

    d.state.gender = isAnswerFromList ? d.response : undefined
    d.state.genderOther = isAnswerFromList ? undefined : d.response
    script.setPeople({ gender: d.state.gender, genderOther: d.state.genderOther })

    /** We need to do this - in case we already asked title to not override it */
    if (!d.state.title) script.setTitle(d.state, isAnswerFromList)
  })
  @step.checkInputForCrisis({
    getNextStep: (s: CollectGenderScript) => s.askSameGenderAsBirth
  })
  async handleGenderWithCrisis(d: IStepData<State, string | undefined>): Promise<IStepResult> {
    const genders = this.genderOptions
    const isAnswerFromList = !!genders?.find(s => s.value === d.response)

    if (!d.state.withTextPrompt && !isAnswerFromList) {
      return {
        body: this.t(
          "Sorry I can't recognize this gender. Let's try again",
          this.getContext(d.state)
        ),
        nextStep: this.askGender
      }
    }

    return {
      nextStep: d.state.skipSameGenderAsBirth //
        ? this.end
        : this.askSameGenderAsBirth
    }
  }

  @step.logState
  async askSameGenderAsBirth(d: IStepData<State>): Promise<IStepResult> {
    const genderSameAsBirth = this.genderSameAsBirthOptions
    if (!genderSameAsBirth?.length) {
      this.logBreadcrumb("SAME GENDER AS BIRTH VALUES NOT FOUND", d.state, { genderSameAsBirth })
      this.logMessage("SAME GENDER AS BIRTH VALUES NOT FOUND")
      return { nextStep: this.end }
    }

    const name = this.getName(d.state)
    return {
      body: this.t(
        this.messages?.askSameGenderAsBirth ??
          "Thanks {name}, and is your gender the same as the gender you were assigned at birth?",
        { ...this.getContext(d.state), name }
      ),
      prompt: {
        id: this.getPromptId("askSameGenderAsBirth"),
        trackResponse: true,
        type: "inlinePicker",
        choices: genderSameAsBirth.map(v => ({ ...v, body: this.t(v.body), value: v.value })),
        dataPointsName: "askSameGenderAsBirth"
      },
      nextStep: this.handleSameGenderAsBirth
    }
  }

  @step.logState
  async handleSameGenderAsBirth(d: IStepData<State, string>): Promise<IStepResult> {
    d.state.sameGenderAsBirth = d.response
    return {
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  setTitle(state: State, isAnswerFromList: boolean): void {
    if (isAnswerFromList) {
      state.title =
        state.gender?.includes("Male") || state.gender?.includes("Man")
          ? "Mr"
          : state.gender?.includes("Female") || state.gender?.includes("Woman")
            ? "Ms"
            : "Mx"
    } else {
      state.title = state.genderOther?.match(/female/i)
        ? "Ms"
        : state.genderOther?.match(/male/i)
          ? "Mr"
          : "Mx"
    }
  }
}

export default class CollectGenderDialogue extends AdHocDialogue<State, CollectGenderScript> {
  static id = DialogueIDs.CollectGender
  readonly name: string = "CollectGenderDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: ICollectGenderSettings
  ) {
    super(
      CollectGenderDialogue.id,
      new CollectGenderScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
