import React from "react"
import { observer } from "mobx-react"
import { useConfigStore } from "./contexts/RootStoreContext"
import Layout from "./components/Layout"
import Launcher from "./components/Launcher/Launcher"
import Sidebar from "./components/Sidebar/Sidebar"
import ChatWindow from "./components/ChatWindow/ChatWindow"
import ChatMessageList from "./components/ChatMessageList/ChatMessageList"
import UserInput from "./components/UserInput"

function App() {
  const config = useConfigStore()
  return (
    <Layout>
      <Sidebar visible={config.fullscreen} />
      <Launcher>
        <ChatWindow>
          <ChatMessageList />
          <UserInput />
        </ChatWindow>
      </Launcher>
    </Layout>
  )
}

export default observer(App)
