import type IAppLaunchConfig from "../models/IAppLaunchConfig"
import { IAppLaunchConfigOptions } from "../models/IAppLaunchConfig"
import getServiceByAPIKeyWithVersion from "../backend/api/limbic/getServiceByAPIKeyWithVersion"
import {
  clinicalPaths,
  complexComorbidPath,
  IBotServiceData,
  IChatBotFlow,
  IClinicalPath,
  undeterminedPath,
  validateClinicalPath,
  DiscussionSteps
} from "@limbic/types"
import Logger from "../utils/Logger"
import { apiKeysMap } from "../config/apiKeysMap"
import { init } from "./init"
import { defaultDialoguesMap, defaultDiscussionSteps } from "./initData"
import { DialogueIDs } from "../conversation/DialogueIDs"
import invariant from "../utils/invariant"
import { IKeyMappingConfig } from "@limbic/types/dist/access/config/keyMapping"

const isProduction = process.env.REACT_APP_BACKEND_ENV === "production"

type BotVersion = "draft" | "published"
/**
 * Always default BOT_VERSION to 'draft' if not set
 */
const BOT_VERSION = process.env.REACT_APP_BOT_VERSION ?? "draft"

export async function initDashboardConfig(setupConfig: IAppLaunchConfig): Promise<void> {
  const API_KEY = setupConfig.API_KEY
  const urlParams = getURLParams()
  let overrides = hydrateOverrides()
  /**
   * The below is a fail-safe just in case the env variable is not set to 'published' or 'draft'
   */
  const botVersion = BOT_VERSION !== "draft" && BOT_VERSION !== "published" ? "draft" : BOT_VERSION

  if (!overrides) {
    const localConfig = apiKeysMap[API_KEY] ?? apiKeysMap["DASHBOARD_DEFAULT"]
    const dialoguesMap = defaultDialoguesMap[API_KEY] ?? defaultDialoguesMap["DASHBOARD_DEFAULT"]
    const serviceData = await getServiceByAPIKeyWithVersion(API_KEY, botVersion as BotVersion)
    const settings = serviceData[botVersion]
    invariant(settings, `Could not get ${botVersion} service data for ${API_KEY}`)

    const basicConfig = getBasicConfig(settings)
    const keepingSafeEmail = getKeepingSafeEmail(settings)
    const discussionStepsOrder = getDiscussionStepsOrder(settings, API_KEY)
    const dialogueFlows = getDialogueFlows(settings)
    const backendMapping = getBackendMapping(settings)

    const defaultClinicalPaths = getDefaultClinicalPaths(settings)
    const customClinicalPaths = getCustomClinicalPaths(settings)
    const complexComorbidPath = getComplexComorbidPath(settings)
    const undeterminedPath = getUndeterminedPath(settings)
    validateClinicalPaths(
      defaultClinicalPaths,
      customClinicalPaths,
      complexComorbidPath,
      undeterminedPath
    )

    const iaptCCGMap = {}
    const iaptGPMap = {}
    const eligibleIAPTIds: string[] = []
    const shouldSendKeepingSafeEmail = settings.keepingSafeEmail?.shouldSendKeepingSafeEmail
    const customBigBot = settings.bigBot
    const customIAPTS = settings.eligibility?.bot?.iapts?.length
      ? settings.eligibility?.bot.iapts.map(iapt => {
          /**
           * 👇 The "draft" check may not be absolutely necessary but
           * I think its much safer to use this approach
           */
          const shouldUseProductionEmails = isProduction && BOT_VERSION !== "draft"
          const formattedIAPT = {
            ...iapt,
            id: iapt.name,
            emails: shouldUseProductionEmails ? (iapt.emails ?? iapt.emailsDemo) : iapt.emailsDemo,
            riskEmails: shouldUseProductionEmails
              ? (iapt.riskEmails ?? iapt.riskEmailsDemo) /** Fallback to demo emails just in case */
              : iapt.riskEmailsDemo,
            riskEmailsCC: shouldUseProductionEmails
              ? (iapt.riskEmailsCC ??
                iapt.riskEmailsCCDemo) /** Fallback to demo emails just in case */
              : iapt.riskEmailsCCDemo,
            riskEmailsBCC: shouldUseProductionEmails
              ? (iapt.riskEmailsBCC ??
                iapt.riskEmailsBCCDemo) /** Fallback to demo emails just in case */
              : iapt.riskEmailsBCCDemo
          }

          delete formattedIAPT.emailsDemo
          delete formattedIAPT.riskEmailsDemo
          delete formattedIAPT.riskEmailsCCDemo
          delete formattedIAPT.riskEmailsBCCDemo

          return formattedIAPT
        })
      : undefined

    if (customIAPTS?.length) {
      customIAPTS.forEach(iapt => {
        // If customIAPTS - set eligibleIAPTIds
        eligibleIAPTIds.push(iapt.id)
        iapt.ccgs.forEach(ccgCode => {
          // If customIAPTS - set iaptCCGMap
          iaptCCGMap[ccgCode] = iapt.name
        })
        // If customIAPTS - check if gpCodes exist and set iaptGPMap
        if (iapt.gpCodes?.length) {
          iapt.gpCodes.forEach(gpCode => {
            iaptGPMap[gpCode] = iapt.id
          })
        }
      })
    }

    // Note: any new dynamic dialogues we create should be added here
    if (dialogueFlows[DiscussionSteps.Intro]) {
      dialoguesMap.intro = DialogueIDs.IntroductionDynamic
    }
    if (dialogueFlows[DiscussionSteps.Permissions]) {
      /**
       * Temporarily deleting peaceOfMind
       * Not to be used anymore from the dashboard
       * Add what is needed in permissions
       */
      delete dialoguesMap.peaceOfMind
      dialoguesMap.permissions = DialogueIDs.PermissionsDynamic
    }
    if (dialogueFlows[DiscussionSteps.SelfReferral]) {
      /**
       * Temporarily deleting selfReferralPitch
       * Not to be used anymore from the dashboard
       * Add what is needed in selfReferral
       */
      delete dialoguesMap.selfReferralPitch
      dialoguesMap.selfReferral = DialogueIDs.SelfReferralDynamic
    }
    if (dialogueFlows[DiscussionSteps.Eligibility]) {
      dialoguesMap.eligibility = DialogueIDs.EligibilityCheckDynamic
    }
    if (dialogueFlows[DiscussionSteps.GetName]) {
      dialoguesMap.getName = DialogueIDs.GetNameDynamic
    }
    if (dialogueFlows[DiscussionSteps.Crisis]) {
      dialoguesMap.crisis = DialogueIDs.CrisisDynamic
    }
    if (dialogueFlows[DiscussionSteps.Assessment]) {
      /**
       * Temporarily deleting assessmentPitch
       * Not to be used anymore from the dashboard
       * Add what is needed in assessment
       */
      delete dialoguesMap.assessmentPitch
      dialoguesMap.assessment = DialogueIDs.AssessmentDynamic

      /**
       * When assessment dialogueFlow exists we need to set all assessment
       * related dialogues (i.e. that branch off assessment at any time)
       * This includes:
       *  - AssessmentDynamic (if its not ADSM)
       *  - AssessmentADSM (if its ADSM enabled)
       *  - PHQ9Dynamic (in order to handle PHQ9Q9)
       *  - RiskPathwayDynamic (in order to handle RiskPathway and custom messages)
       */
      delete dialoguesMap.assessmentPitch
      dialoguesMap.assessment = DialogueIDs.AssessmentDynamic
      dialoguesMap.assessmentADSM = DialogueIDs.AssessmentADSMDynamic
      dialoguesMap.phq9 = DialogueIDs.PHQ9Dynamic
      dialoguesMap.riskPathway = DialogueIDs.RiskPathwayDynamic
    }
    if (dialogueFlows[DiscussionSteps.Goodbye]) {
      dialoguesMap.goodbye = DialogueIDs.GoodbyeDynamic
    }

    overrides = {
      dashboardServiceKey: serviceData.serviceApiKey,
      ...localConfig,
      ...basicConfig,
      ...keepingSafeEmail,
      ...(setupConfig.overrides ?? {}),
      defaultLanguage: settings.configuration?.translations?.defaultLanguage,
      supportedLanguages: settings.configuration?.translations?.supportedLanguages,
      discussionStepsOrder,
      dialoguesMap,
      defaultClinicalPaths,
      customClinicalPaths,
      complexComorbidPath,
      undeterminedPath,
      dialogueFlows,
      backendMapping,
      directReferral: urlParams.directReferral === "true",
      fullscreen: urlParams.directReferral === "true"
    }

    if (Object.keys(iaptCCGMap).length) overrides.iaptCCGMap = iaptCCGMap
    if (Object.keys(iaptGPMap).length) overrides.iaptGPMap = iaptGPMap
    if (customIAPTS?.length) overrides.customIAPTS = customIAPTS
    if (eligibleIAPTIds.length) overrides.eligibleIAPTIds = eligibleIAPTIds
    if (customBigBot) overrides.customBigBot = customBigBot
    if (shouldSendKeepingSafeEmail)
      overrides.shouldSendKeepingSafeEmail = shouldSendKeepingSafeEmail
  }

  persistOverrides(overrides)
  init({ API_KEY, overrides, withCustomInitCheck: false })
}

function hydrateOverrides(): IAppLaunchConfigOptions | undefined {
  const data = sessionStorage.getItem("@limbic:dashboard:overrides")
  if (data) return JSON.parse(data)
}

function persistOverrides(overrides: IAppLaunchConfigOptions): void {
  sessionStorage.setItem("@limbic:dashboard:overrides", JSON.stringify(overrides))
}

function getBasicConfig(settings: IBotServiceData): Partial<IAppLaunchConfigOptions> {
  const config = {
    title: settings.configuration?.title,
    serviceName: settings.configuration?.serviceName,
    organisationName: settings.configuration?.organisationName,
    organisationPhoneNumbers: settings.configuration?.organisationPhoneNumbers
      ?.filter(Boolean)
      .join("\n"),
    organisationTerms: settings.configuration?.organisationTerms,
    crisisPhoneNumbers: settings.configuration?.crisisPhoneNumbers,
    logo: settings.configuration?.logo,
    userMessageBackground: settings.configuration?.userMessageBackground,
    faqLink: settings.configuration?.faqLink,
    advanced: settings.configuration?.advanced
  }

  return Object.fromEntries(Object.entries(config).filter(([_, v]) => v))
}

function getKeepingSafeEmail(settings: IBotServiceData): Partial<IAppLaunchConfigOptions> {
  const config = {
    keepingSafeSubject: settings.keepingSafeEmail?.subject,
    keepingSafeText: settings.keepingSafeEmail?.emailHTML,
    keepingSafeAttachment: [settings.keepingSafeEmail?.attachmentURL]
  }

  return Object.fromEntries(Object.entries(config).filter(([_, v]) => v))
}

function checkMixedSubItemsPresence(items) {
  let hasSubItems = false
  let noSubItems = false

  items.forEach(item => {
    if (Array.isArray(item.subItems) && item.subItems.length > 0) {
      hasSubItems = true
    } else {
      noSubItems = true
    }
  })

  return hasSubItems && noSubItems
}

function getDiscussionStepsOrder(settings: IBotServiceData, apiKey: string): DiscussionSteps[] {
  if (settings.stepsOrder && Object.keys(settings.stepsOrder).length) {
    let stepsOrder

    const includesPartialSubItems = checkMixedSubItemsPresence(settings.stepsOrder)
    if (includesPartialSubItems) {
      const e = new Error(`stepsOrder from dashboard for ${apiKey} has partial subItems`)
      Logger.getInstance().exception(e, "initDashboard -> getDiscussionStepsOrder")
    }
    /**
     * 👆 The above check is to notify us in case that the steps order coming from the
     * dashboard are a mix of objects that include and do not include subItems
     *
     * 👇 The below conditional is to transition the dashboard stepsOrder away
     * from using the subitems - until then we need to have a failsafe to avoid
     * any production bots crashing
     */
    if (
      settings.stepsOrder.every(step => Array.isArray(step.subItems) && step.subItems.length > 0)
    ) {
      stepsOrder =
        settings.stepsOrder
          ?.filter(step => !step.disabled)
          .map(s => s.subItems?.map(s => s.value as unknown as DiscussionSteps))
          .flat() ?? []
    } else {
      stepsOrder = settings.stepsOrder?.filter(step => !step.disabled).map(s => s.value)
    }

    return stepsOrder.length ? stepsOrder : defaultDiscussionSteps[apiKey]
  }
  return defaultDiscussionSteps[apiKey] ?? defaultDiscussionSteps["DASHBOARD_DEFAULT"]
}

function getDefaultClinicalPaths(settings: IBotServiceData): IClinicalPath[] {
  const paths: IClinicalPath[] = settings.clinicalPaths?.defaultClinicalPaths ?? clinicalPaths
  return paths.filter(p => p.id !== "undetermined" && p.id !== "complex_comorbid")
}

function getCustomClinicalPaths(settings: IBotServiceData): IClinicalPath[] {
  return settings.clinicalPaths?.customClinicalPaths?.paths ?? []
}

function getComplexComorbidPath(settings: IBotServiceData): IClinicalPath {
  const paths: IClinicalPath[] = settings.clinicalPaths?.defaultClinicalPaths ?? clinicalPaths
  return paths.find(p => p.id === "complex_comorbid") ?? complexComorbidPath
}

function getUndeterminedPath(settings: IBotServiceData): IClinicalPath {
  const paths: IClinicalPath[] = settings.clinicalPaths?.defaultClinicalPaths ?? clinicalPaths
  return paths.find(p => p.id === "undetermined") ?? undeterminedPath
}

function validateClinicalPaths(
  defaultClinicalPaths: IClinicalPath[],
  customClinicalPaths: IClinicalPath[],
  complexComorbidPath: IClinicalPath,
  undeterminedPath: IClinicalPath
) {
  const invalidClinicalPaths = [
    ...Object.values(defaultClinicalPaths),
    ...Object.values(customClinicalPaths),
    complexComorbidPath,
    undeterminedPath
  ]
    .map(
      path =>
        path.matcher &&
        validateClinicalPath({
          primaryProblems: path.matcher.primaryProblems,
          secondaryProblems: path.matcher.secondaryProblems ?? [],
          flags: path.matcher.flags ?? []
        })
    )
    .filter(Boolean)
    .filter(v => !v?.isValid)

  if (invalidClinicalPaths.length) {
    console.log("init: invalid clinical paths found")
    console.log(JSON.stringify(invalidClinicalPaths))
    const e = new Error("Invalid clinical paths found")
    Logger.getInstance().exception(e, "init -> Clinical Paths Setup")
  }
}

function getDialogueFlows(settings: IBotServiceData): IChatBotFlow {
  return Object.keys(settings.flow ?? {}).reduce(
    (flow, step) => ({ ...flow, [step]: settings.flow![step].bot }),
    {}
  )
}

function getBackendMapping(settings: IBotServiceData): IKeyMappingConfig {
  return {
    transformMap: settings.backendMapping?.transformMap ?? {},
    targetKeys: settings.backendMapping?.targetKeys ?? {}
  }
}

function getURLParams(): Record<string, any> {
  try {
    const params = new URLSearchParams(window.location.search)
    const result: Record<string, any> = {}
    params.forEach((value, key) => (result[key] = value))
    return result
  } catch {
    return {}
  }
}
