import type { DiscussionSteps } from "@limbic/types"
import { DialogueIDs } from "../../conversation/DialogueIDs"
import dialoguesRegistry, { IDialoguesRegistry } from "../../conversation/dialoguesRegistry"
import Persistable from "../../models/Persistable"
import Syncable from "../../models/Syncable"
import { computed, observable } from "mobx"

export default class DiscussionStore extends Persistable implements Syncable {
  readonly name: string = "DiscussionStore"
  started: boolean
  @observable stepsOrder!: DiscussionSteps[]
  dialoguesMap!: Partial<Record<DiscussionSteps, DialogueIDs>>
  @observable currentStepIndex: number
  presetFlowNextSteps: Record<string, string>

  restart?(): void

  constructor() {
    super()
    this.started = this.hydrate("started") ?? false
    this.stepsOrder = this.hydrate("stepsOrder") ?? []
    this.dialoguesMap = this.hydrate("dialoguesMap") ?? {}
    this.currentStepIndex = this.hydrate("currentStepIndex") ?? 0
    this.presetFlowNextSteps = this.hydrate("presetFlowNextSteps") ?? {}
  }

  rehydrate(): void {
    this.started = this.hydrate("started") ?? false
    this.stepsOrder = this.hydrate("stepsOrder") ?? []
    this.dialoguesMap = this.hydrate("dialoguesMap") ?? {}
    this.currentStepIndex = this.hydrate("currentStepIndex") ?? 0
  }

  setStarted(started: boolean): void {
    this.started = started
    this.persist("started", started)
  }

  setDiscussionStepsOrder(stepsOrder: DiscussionSteps[]): void {
    this.stepsOrder = stepsOrder
    this.persist("stepsOrder", stepsOrder)
  }

  setDialoguesMap(map: Partial<Record<DiscussionSteps, DialogueIDs>>): void {
    this.dialoguesMap = map
    this.persist("dialoguesMap", map)
  }

  setCurrentStepIndex(index: number): void {
    this.currentStepIndex = index
    this.persist("currentStepIndex", index)
  }

  setPresetFlowNextSteps(nextSteps: Record<string, string>): void {
    this.presetFlowNextSteps = nextSteps
    this.persist("presetFlowNextSteps", nextSteps)
  }

  getDialogueClass<Key extends keyof IDialoguesRegistry>(
    step: DiscussionSteps
  ): IDialoguesRegistry[Key] | undefined {
    const id = this.dialoguesMap?.[step] as Key | undefined
    if (id) return dialoguesRegistry.get(id)
  }

  /** Getters / Setters */

  @computed
  get currentStep(): DiscussionSteps {
    return this.stepsOrder[this.currentStepIndex]
  }
}

if (process.env.REACT_APP__DEV_TOOLS__ === "enabled") {
  DiscussionStore.prototype.restart = function restart(this: DiscussionStore) {
    this.setStarted(false)
    this.setCurrentStepIndex(0)
  }
}
