import BaseScript, { BaseScriptState } from "../../../BaseScript"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { step } from "../../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { ISelectableExtended } from "@limbic/types"

export type ICollectEthnicitySettings = {
  options?: ISelectableExtended[] | undefined
}

type State = BaseScriptState

export type CollectEthnicityScriptState = State

export class CollectEthnicityScript extends BaseScript<State> {
  readonly name: string = "CollectEthnicityScript"
  protected ethnicities: ISelectableExtended[] | undefined
  constructor(settings: ICollectEthnicitySettings | undefined = {}) {
    super()
    this.ethnicities = settings?.options ?? []
  }

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askEthnicity }
  }

  @step.logState
  askEthnicity(d: IStepData<State>): IStepResult {
    const ethnicities = this.ethnicities ?? []
    if (!ethnicities?.length) {
      this.logBreadcrumb("ETHNICITIES NOT FOUND", d.state, { ethnicities })
      this.logMessage("ETHNICITIES NOT FOUND")
      return { nextStep: this.end }
    }
    return {
      body: this.t("Can you tell me which ethnicity you identify as?", this.getContext(d.state)),
      prompt: {
        id: this.getPromptId("askEthnicity"),
        trackResponse: true,
        type: "inlinePicker",
        choices: ethnicities,
        dataPointsName: "askEthnicity"
      },
      nextStep: this.handleEthnicity
    }
  }

  @step.logStateAndResponse
  async handleEthnicity(d: IStepData<State, string>): Promise<IStepResult> {
    const ethnicities = this.ethnicities ?? []
    const isValid = ethnicities?.find(s => s.value === d.response)
    if (!isValid) {
      return {
        body: this.t(
          "Sorry I can't recognize this ethnicity. Let's try again",
          this.getContext(d.state)
        ),
        nextStep: this.askEthnicity
      }
    }
    d.state.ethnicity = d.response
    this.setPeople({ ethnicity: d.response })

    return { nextStep: this.end }
  }
}

export default class CollectEthnicityDialogue extends AdHocDialogue<State, CollectEthnicityScript> {
  static id = DialogueIDs.CollectEthnicity
  readonly name: string = "CollectEthnicityDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: ICollectEthnicitySettings
  ) {
    super(
      CollectEthnicityDialogue.id,
      new CollectEthnicityScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
