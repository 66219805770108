import { IAPTGPMap, IAPTIDs } from "../../models/IIAPTService"

export const iaptGPMapBradford: IAPTGPMap = {
  B83012: IAPTIDs.BRADFORD,
  B83038: IAPTIDs.BRADFORD,
  B83045: IAPTIDs.BRADFORD,
  B83641: IAPTIDs.BRADFORD,
  B83015: IAPTIDs.BRADFORD,
  B83029: IAPTIDs.BRADFORD,
  B83041: IAPTIDs.BRADFORD,
  B83042: IAPTIDs.BRADFORD,
  B83014: IAPTIDs.BRADFORD,
  B83022: IAPTIDs.BRADFORD,
  B83031: IAPTIDs.BRADFORD,
  B83037: IAPTIDs.BRADFORD,
  B83067: IAPTIDs.BRADFORD,
  B83052: IAPTIDs.BRADFORD,
  B83604: IAPTIDs.BRADFORD,
  B83614: IAPTIDs.BRADFORD,
  B83642: IAPTIDs.BRADFORD,
  B83660: IAPTIDs.BRADFORD,
  B83025: IAPTIDs.BRADFORD,
  B83034: IAPTIDs.BRADFORD,
  B83051: IAPTIDs.BRADFORD,
  B83621: IAPTIDs.BRADFORD,
  B83622: IAPTIDs.BRADFORD,
  B83627: IAPTIDs.BRADFORD,
  B83628: IAPTIDs.BRADFORD,
  B83653: IAPTIDs.BRADFORD,
  B83659: IAPTIDs.BRADFORD,
  B82007: IAPTIDs.BRADFORD,
  B82053: IAPTIDs.BRADFORD,
  B83002: IAPTIDs.BRADFORD,
  B83008: IAPTIDs.BRADFORD,
  B83019: IAPTIDs.BRADFORD,
  B83620: IAPTIDs.BRADFORD,
  B83624: IAPTIDs.BRADFORD,
  B82061: IAPTIDs.BRADFORD,
  B83010: IAPTIDs.BRADFORD,
  B83028: IAPTIDs.BRADFORD,
  B83035: IAPTIDs.BRADFORD,
  B83055: IAPTIDs.BRADFORD,
  B83018: IAPTIDs.BRADFORD,
  B83039: IAPTIDs.BRADFORD,
  B83005: IAPTIDs.BRADFORD,
  B83026: IAPTIDs.BRADFORD,
  B83032: IAPTIDs.BRADFORD,
  B83058: IAPTIDs.BRADFORD,
  B83611: IAPTIDs.BRADFORD,
  B83626: IAPTIDs.BRADFORD,
  B83629: IAPTIDs.BRADFORD,
  B83657: IAPTIDs.BRADFORD,
  B83661: IAPTIDs.BRADFORD,
  Y01118: IAPTIDs.BRADFORD,
  B83033: IAPTIDs.BRADFORD,
  B83009: IAPTIDs.BRADFORD,
  B83020: IAPTIDs.BRADFORD,
  B83054: IAPTIDs.BRADFORD,
  B83063: IAPTIDs.BRADFORD,
  B83016: IAPTIDs.BRADFORD,
  B83056: IAPTIDs.BRADFORD,
  B83062: IAPTIDs.BRADFORD,
  B83064: IAPTIDs.BRADFORD
}
