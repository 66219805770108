import invariant from "../../../utils/invariant"
import encodeURLParams from "../../../utils/encodeURLParams"
import RootStore from "../../../app/stores/rootStore"
import errorMessage from "../../../utils/parseErrorMessage"
import NetworkError from "../../../models/NetworkError"
import fetchWithTimeout from "../../../utils/fetchWithTimeout"
import Base64 from "../../../utils/base64"

function getRaw(
  path: string,
  params?: string | Record<string, string | number | boolean>,
  headerOverrides?: HeadersInit,
  timeout = 0
): Promise<Response> {
  const baseURL = RootStore.getInstance().backendStore.backendURL
  const headers = getHeaders(headerOverrides)
  const url = params ? `${baseURL}${path}?${encodeURLParams(params)}` : `${baseURL}${path}`
  const request = { method: "GET", headers }
  return timeout ? fetchWithTimeout(url, request, timeout) : fetch(url, request)
}

function postRaw(
  path: string,
  body?: any,
  params?: string | Record<string, string | number | boolean>,
  headerOverrides?: HeadersInit,
  timeout = 0
): Promise<Response> {
  const baseURL = RootStore.getInstance().backendStore.backendURL
  const headers = getHeaders(headerOverrides)
  const method = "POST"
  const url = params ? `${baseURL}${path}?${encodeURLParams(params)}` : `${baseURL}${path}`
  const request = { method, headers, body: JSON.stringify(body) }
  return timeout ? fetchWithTimeout(url, request, timeout) : fetch(url, request)
}

function putRaw(
  path: string,
  body?: any,
  params?: string | Record<string, string | number | boolean>,
  headerOverrides?: HeadersInit,
  timeout = 0
): Promise<Response> {
  const baseURL = RootStore.getInstance().backendStore.backendURL
  const headers = getHeaders(headerOverrides)
  const method = "PUT"
  const url = params ? `${baseURL}${path}?${encodeURLParams(params)}` : `${baseURL}${path}`
  const request = { method, headers, body: JSON.stringify(body) }
  return timeout ? fetchWithTimeout(url, request, timeout) : fetch(url, request)
}

async function get(
  path: string,
  params?: string | Record<string, string | number | boolean>,
  headerOverrides?: HeadersInit,
  timeout = 0
): Promise<any> {
  const result = await getRaw(path, params, headerOverrides, timeout)
  await handleResponse(result)
  const json = await result.json()
  return extractData(json)
}

async function post(
  path: string,
  // eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types
  body?: any,
  params?: string | Record<string, string | number | boolean>,
  headerOverrides?: HeadersInit,
  timeout = 0
): Promise<any> {
  const result = await postRaw(path, body, params, headerOverrides, timeout)
  await handleResponse(result)
  const json = await result.json()
  return extractData(json)
}

async function put(
  path: string,
  // eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types
  body?: any,
  params?: string | Record<string, string | number | boolean>,
  headerOverrides?: HeadersInit,
  timeout = 0
): Promise<any> {
  const result = await putRaw(path, body, params, headerOverrides, timeout)
  await handleResponse(result)
  const json = await result.json()
  return extractData(json)
}

function getHeaders(headerOverrides?: HeadersInit): Headers {
  const API_KEY = RootStore.getInstance().backendStore.APIKey
  invariant<string>(API_KEY, "Environment variable REACT_APP_ACCESS_BACKEND_API_KEY was not found")
  const headers = new Headers()
  headers.set("Content-Type", "application/json")
  headers.set("x-api-key", API_KEY)
  if (headerOverrides) {
    for (const key in headerOverrides) headers.set(key, headerOverrides[key])
  }
  return headers
}

function getTimedResourceAPIKey(id: string): string {
  const headers = client.headers()
  return `${Date.now().toString(16)}.${Base64.btoa(headers["x-api-key"])}.${Base64.btoa(id)}`
}

async function handleResponse(response: Response): Promise<Response> {
  if (!response.ok) {
    const text = await response?.text()
    const message = errorMessage(response, { message: text })
    throw new NetworkError(String(response.status), message)
  }
  return response
}

function extractData(json: Record<string, any>): any {
  const { success, data, error, validations } = json
  if (!success) {
    const e = error ?? "Unknown Error"
    if (validations) throw new NetworkError("", `${e} - ${validations.join(", ")}`)
    throw new NetworkError("", e)
  }
  return data
}

get.raw = getRaw
post.raw = postRaw
put.raw = putRaw

const client = {
  get,
  post,
  put,
  extractData,
  getTimedResourceAPIKey,
  headers: getHeaders
}
export default client
