import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { IStepResult, StepResultBodyType } from "../../../backend/chatbot/models/IStep"
import { DialogueIDs } from "../../DialogueIDs"
import GoodbyeScript from "./GoodbyeScript"
import type { GoodbyeScriptState } from "./GoodbyeScript"

type State = GoodbyeScriptState

export class GoodbyeBradfordScript extends GoodbyeScript {
  readonly name: string = "GoodbyeBradfordScript"

  /** Generic Handlers */

  async onHandleRecapMessage(
    state: State,
    body: StepResultBodyType[],
    recapMessage: string | undefined
  ): Promise<IStepResult> {
    return {
      body,
      prompt: {
        id: this.getPromptId("onHandleRecapMessage"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      },
      nextStep: this.askFeedback
    }
  }

  async getAdminWillCallMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted) {
      return "Someone from the service team will be in contact within 15 days to book your appointment with a relevant clinician"
    }
  }

  async getHighRiskContactMessage(_state: State): Promise<string | void> {
    if (this.clinicalStore.isHighRisk) {
      return "Please remember, NHS Bradford District Talking Therapies is not an emergency response service. If you need urgent help you are encouraged to contact First Response, our  24/7 crisis service at 0800 9521181 999 or NHS 111 and select Option 2.\nYou can also contact the Samaritans on 116123 to talk  through how you are feeling"
    }
  }

  async getModerateRiskContactMessage(_state: State): Promise<string | void> {
    if (this.clinicalStore.isModerateRisk) {
      return "Please remember, NHS Bradford District Talking Therapies is not an emergency response service. If you need urgent help you are encouraged to contact First Response, our  24/7 crisis service at 0800 9521181 999 or NHS 111 and select Option 2.\nYou can also contact the Samaritans on 116123 to talk  through how you are feeling"
    }
  }
}

/* istanbul ignore next */
export default class GoodbyeBradfordDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeBradford
  readonly name: string = "GoodbyeBradfordDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeBradfordDialogue.id, new GoodbyeBradfordScript(), state, snapshot)
  }
}
