import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { GoodbyeScriptState } from "./GoodbyeScript"
import GoodbyeScript from "./GoodbyeScript"
import { IStepData, IStepResult, StepResultBodyType } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"

interface State extends GoodbyeScriptState {
  requiresUrgentSupport?: boolean
}

export type GoodbyeOxfordScriptState = State

export class GoodbyeOxfordScript extends GoodbyeScript {
  readonly name: string = "GoodbyeOxfordScript"

  /** Script Steps */

  @step
  async start(d: IStepData<State>): Promise<IStepResult> {
    // TODO: REMOVE THIS!!!!!!
    // NEEDED BECAUSE FOR NOW SELF REFERRAL DIALOGUE IS NOT BEING LOADED
    // d.state.referralSubmitted = d.state.requiresUrgentSupport ? false : true
    if (d.state.referralSubmitted) {
      return super.start(d)
    }
    return {
      nextStep: this.sayIHopeIHelped
    }
  }

  @step
  async sayGoodBye(d: IStepData<State>): Promise<IStepResult> {
    await this.referralStore.updateReferral({
      isHelpful: d.state.isHelpful,
      improvementSuggestion: d.state.improvementSuggestion
    })
    this.referralStore.stopPinging()

    return {
      body: [
        "Well done for taking this important step towards better mental health",
        "You can now close the window"
      ],
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onHandleRecapMessage(
    state: State,
    body: string[],
    recapMessage: string | undefined
  ): Promise<IStepResult> {
    const choices = [{ body: "Okay" }]
    if (recapMessage) {
      choices.push({ body: "I understand" })
    }

    return {
      body,
      prompt: {
        id: this.getPromptId("recap"),
        trackResponse: true,
        type: "inlinePicker",
        choices
      },
      nextStep: this.askFeedback // TODO: Feedback is skipped if isCrisis - should we change?
    }
  }

  async getIntroMessage(state: State): Promise<string | void> {
    const isCrisis = this.clinicalStore.isCrisis
    const name = this.getName(state)
    if (state.referralSubmitted) {
      return isCrisis
        ? `Thank you for sharing this information with me ${name}`
        : "I hope I’ve been able to help you today"
    }
    return isCrisis
      ? "I hope I’ve been able to help you today"
      : `Thank you for sharing this information with me ${name}`
  }

  // 👇 This is overridden to change the order of the adminWillCall
  // message, to happen after the risk messages
  async getRecapMessage(state: State): Promise<string | void> {
    const recaps: string[] = []
    const referredYouMessage = await this.getReferredYouMessage(state)
    referredYouMessage && recaps.push(referredYouMessage)

    const highRiskContactMessage = await this.getHighRiskContactMessage(state)
    highRiskContactMessage && recaps.push(highRiskContactMessage)

    const moderateRiskContactMessage = await this.getModerateRiskContactMessage(state)
    moderateRiskContactMessage && recaps.push(moderateRiskContactMessage)

    const adminWillCallMessage = await this.getAdminWillCallMessage(state)
    adminWillCallMessage && recaps.push(adminWillCallMessage)

    const treatmentMessage = await this.getTreatmentMessage(state)
    treatmentMessage && recaps.push(treatmentMessage)

    const formattedRecap = recaps.length > 1 ? recaps.map((i, idx) => `${idx + 1}. ${i}`) : recaps
    const recapString = formattedRecap.join("\n")

    if (recapString?.length) {
      return `Just to recap:\n\n${recapString}`
    }
  }

  async getReferredYouMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted) {
      const organisationName = this.rootStore.configStore.organisationName
      const referralMessage = `I've referred you to ${organisationName}`
      return state.isIdleSubmitted //
        ? `It looks like there hasn't been any activity for some time so I've referred you to ${organisationName}`
        : referralMessage
    }
  }

  async getHighRiskContactMessage(_state: State): Promise<string | void> {
    if (this.clinicalStore.isHighRisk) {
      return (
        "Because you identified as potentially experiencing high levels of distress, or potentially at risk please seek help ahead of your appointment as required" +
        "\n\nPlease remember, we are not an emergency response service. If you need urgent help you are encouraged to contact 999 in the case of a physical emergency or NHS 111 online [https://111.nhs.uk/](https://111.nhs.uk/)" +
        "\n\nYou can also contact the Samaritans on 116123 to talk  through how you are feeling, your GP or their our of hours service" +
        "\n\nYou could also contact Safe Haven.  This is a non-clinical,  face-to-face safe space to get short-term support in mental health crisis when other services are not available. Safe Haven is open Friday to Monday 6pm - 10pm" +
        "\nOxford: 01865 903 037 / Banbury: 01295 270 004"
      )
    }
  }

  async getModerateRiskContactMessage(_state: State): Promise<string | void> {
    if (this.clinicalStore.isModerateRisk) {
      return (
        "Because you identified as potentially experiencing high levels of distress, or potentially at risk please seek help ahead of your appointment as required" +
        "\n\nPlease remember, we are not an emergency response service. If you need urgent help you are encouraged to contact 999 in the case of a physical emergency or NHS 111 online [https://111.nhs.uk/](https://111.nhs.uk/)" +
        "\n\nYou can also contact the Samaritans on 116123 to talk  through how you are feeling, your GP or their our of hours service" +
        "\n\nYou could also contact Safe Haven.  This is a non-clinical,  face-to-face safe space to get short-term support in mental health crisis when other services are not available. Safe Haven is open Friday to Monday 6pm - 10pm" +
        "\nOxford: 01865 903 037 / Banbury: 01295 270 004"
      )
    }
  }

  async getCustomRecapMessage(state: State): Promise<StepResultBodyType[] | undefined> {
    const isCrisis = this.clinicalStore.isCrisis
    const name = this.getName(state)
    if (state.referralSubmitted) {
      const lastMessage = isCrisis
        ? `Thank you for sharing this information with me ${name}`
        : `Well done for taking this important step towards better mental health`
      return [lastMessage]
    }
    return [`Well done for taking this important step towards better mental health`]
  }

  async getAdminWillCallMessage(state: State): Promise<string | void> {
    const organisationName = this.rootStore.configStore.organisationName
    const organisationGenericPhoneNumber = this.rootStore.configStore.organisationGenericPhoneNumber
    const iaptName = this.getIAPTName(state) || organisationName
    if (state.referralSubmitted) {
      return (
        `If you have provided a mobile and given consent to receive SMS, you will shortly receive an SMS from ${iaptName} with a link to book your appointment\n` +
        "After booking, you will receive a confirmation email of your appointment with further information\n" +
        "If you do not receive a direct booking link via SMS, the service will contact you to make your appointment\n" +
        `Alternatively you can contact the service on ${organisationGenericPhoneNumber}\n` +
        "Mon & Fri 8am - 5pm, Tues - Thurs 8am - 8pm\n" +
        "\n" +
        "Please also be aware that if you require an interpreter, need specific assistance due to hearing difficulties or part of the Afghan or Ukrainian refugee settlement or are an Asylum Seeker, the appointments offered via the SMS link will not be suitable\n" +
        `Please contact us to book the appointment for you with the additional support required on ${organisationGenericPhoneNumber}\n` +
        "Mon & Fri 8am - 5pm, Tues - Thurs 8am - 8pm\n" +
        "If we do not hear from you within two working days, we will contact you to arrange an appointment"
      )
    }
  }
}

/* istanbul ignore next */
export default class GoodbyeOxfordDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeOxford
  readonly name: string = "GoodbyeOxfordDialogue"

  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeOxfordDialogue.id, new GoodbyeOxfordScript(), state, snapshot)
  }
}
