import Logger from "../../../utils/Logger"
import delay from "../../../utils/delay"
import isOnline from "is-online"
import { MindEligibilityStatus } from "../../../models/IMindEligibility"
import client from "./_client"

const TOTAL_RETRIES = 3

export async function checkEligibilityMind(
  postcode: string,
  retry = 0
): Promise<[string | undefined, MindEligibilityStatus]> {
  const body = { instanceID: "MIND_MAIN", postcode }
  try {
    const hasConnection = await isOnline()
    if (!hasConnection) {
      return [undefined, MindEligibilityStatus.NoInternetConnection]
    }

    const data = await client.post("/v1/views/eligibility/region", body)

    // 👇 Views endpoints are kind of inconsistent, and sometimes
    // they return an array and sometimes just a string ¯\_(ツ)_/¯
    if (typeof data === "string") {
      return [data?.length ? data : undefined, MindEligibilityStatus.Success]
    }

    if (Array.isArray(data)) {
      return [data?.length ? data[0] : undefined, MindEligibilityStatus.Success]
    }

    return [undefined, MindEligibilityStatus.Success]
  } catch (e) {
    Logger.getInstance().exception(e, "checkEligibilityMind fetch failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`checkEligibilityMind body for retry ${retry}`, JSON.stringify(body))
      Logger.getInstance().message("checkEligibilityMind retry")
      await delay(1)
      return await checkEligibilityMind(postcode, retry + 1)
    }
    return [undefined, MindEligibilityStatus.RequestFailed]
  }
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}
