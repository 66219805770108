import ChatFlowPayloadBuilder from "../ChatFlowPayloadBuilder"
import { ChatFlowsEnum, ReferralPayload } from "@limbic/types"

export default class PriorMHTreatmentPayloadBuilder extends ChatFlowPayloadBuilder<ChatFlowsEnum.ASK_PREVIOUS_MH_TREATMENT> {
  chatFlow = ChatFlowsEnum.ASK_PREVIOUS_MH_TREATMENT

  /** Overrides */

  getDefaultPayload(): Partial<Partial<ReferralPayload>> {
    return {
      pastTreatment: this.ctx.state?.priorMHTreatment,
      pastTreatmentDetails: this.ctx.state?.priorMHTreatmentDetails
    } as Partial<ReferralPayload>
  }
}

// prettier-ignore
ChatFlowPayloadBuilder.builders[ChatFlowsEnum.ASK_PREVIOUS_MH_TREATMENT] = PriorMHTreatmentPayloadBuilder
