import formatUnicorn from "./formatUnicorn"

export default function passthroughTranslationFn<T>(phrase: T, ctx?: Record<string, any>): T {
  if (typeof phrase === "string") {
    return (ctx ? formatUnicorn(phrase, ctx) : phrase) as T
  }
  if (Array.isArray(phrase) && phrase.length && phrase.map) {
    return phrase.map(p => passthroughTranslationFn(p, ctx) as string) as T
  }

  return phrase
}
