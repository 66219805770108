import Logger from "../../../utils/Logger"
import client from "./_client"
import delay from "../../../utils/delay"

const TOTAL_RETRIES = 3

export async function createLLMThread(retry = 0): Promise<string | undefined> {
  try {
    const data = await client.post("/v1/llm/thread")
    return data.threadId
  } catch (e) {
    Logger.getInstance().breadcrumb({ message: "createThread get failed" })
    Logger.getInstance().exception(e, "createThread get failed")
    if (retry < TOTAL_RETRIES) {
      Logger.getInstance().message("createLLMThread retry")
      await delay(1)
      return await createLLMThread(retry + 1)
    }
  }
}

export async function addMessage(
  threadId: string,
  message: string,
  retry = 0
): Promise<string | undefined> {
  try {
    const data = await client.post("/v1/llm/message", { threadId, message })
    return data.messageId
  } catch (e) {
    Logger.getInstance().breadcrumb({
      message: "addMessage post failed",
      data: { threadId, message }
    })
    Logger.getInstance().exception(e, "addMessage post failed")
    if (retry < TOTAL_RETRIES) {
      Logger.getInstance().message("addMessage retry")
      await delay(1)
      return await addMessage(threadId, message, retry + 1)
    }
  }
}

export async function getLLMResponse(
  threadId: string,
  message?: string,
  retry = 0
): Promise<string[] | undefined> {
  try {
    if (message) await addMessage(threadId, message)
    const data = await client.post("/v1/llm/response", { threadId })
    const response = data.response?.join(" ").replace(/\.$/, "").trim()
    const index = response.replace(/\.$/, "").lastIndexOf(". ")
    const partA = response.substring(0, index)
    const partB = response.substring(index).replace(". ", "")
    return [partA, partB].filter(Boolean) as string[]
  } catch (e) {
    Logger.getInstance().breadcrumb({
      message: "getLLMResponse post failed",
      data: { threadId, message }
    })
    Logger.getInstance().exception(e, "getLLMResponse post failed")
    if (retry < TOTAL_RETRIES) {
      Logger.getInstance().message("getLLMResponse retry")
      await delay(1)
      return await getLLMResponse(threadId, message, retry + 1)
    }
  }
}
