/* eslint-disable @typescript-eslint/no-empty-function */
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../../DialogueIDs"
import { step } from "../../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import isEmail from "validator/lib/isEmail"
import BaseScript, { BaseScriptState } from "../../../BaseScript"
import {
  IDefaultChatFlowMessagesCollectEmail,
  IDefaultChatFlowSettingsCollectEmail
} from "@limbic/types"

type State = BaseScriptState
export type CollectEmailScriptState = State

export interface ICollectEmailSettings extends IDefaultChatFlowSettingsCollectEmail {
  messages?: IDefaultChatFlowMessagesCollectEmail
}

export class CollectEmailScript extends BaseScript<State> {
  readonly name: string = "CollectEmailScript"
  protected hasNoEmailOption: boolean
  protected hasPreliminaryQuestion: boolean
  protected shouldAskEmailPermission: boolean
  protected messages: IDefaultChatFlowMessagesCollectEmail
  protected customisableOptions:
    | Partial<
        Record<
          keyof IDefaultChatFlowMessagesCollectEmail,
          { body: string; value: boolean | string }[]
        >
      >
    | undefined

  constructor(settings?: ICollectEmailSettings | undefined) {
    super()
    this.hasNoEmailOption = settings?.hasNoEmailOption ?? false
    this.hasPreliminaryQuestion = settings?.hasPreliminaryQuestion ?? false
    this.shouldAskEmailPermission = settings?.shouldAskEmailPermission ?? false
    this.messages = settings?.messages ?? {}
    this.customisableOptions = settings?.customisableOptions
  }

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.hasPreliminaryQuestion ? this.askDoYouWantToShareEmail : this.askEmail }
  }

  @step.logState
  askDoYouWantToShareEmail(d: IStepData<State>): IStepResult {
    const choices =
      this.customisableOptions && this.customisableOptions.askDoYouWantToShareEmail?.length
        ? this.customisableOptions.askDoYouWantToShareEmail
        : [
            { body: this.t("Yes"), value: true },
            { body: this.t("No"), value: false }
          ]

    return {
      body: this.t(
        this.messages.askDoYouWantToShareEmail ??
          "Would you also like to provide an email address?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askDoYouWantToShareEmail"),
        trackResponse: true,
        type: "inlinePicker",
        choices
      },
      nextStep: this.handleDoYouWantToShareEmail
    }
  }

  @step.logStateAndResponse
  handleDoYouWantToShareEmail(d: IStepData<State, boolean>): IStepResult {
    if (d.response) return { nextStep: this.askEmail }
    d.state.canSendEmail = false
    d.state.email = undefined // Just in case there's an undo, clear email
    return { nextStep: this.end }
  }

  @step.logState
  askEmail(d: IStepData<State>): IStepResult {
    if (this.hasNoEmailOption) {
      return {
        body: this.t(
          this.messages.askEmail ?? "Please type your email address",
          this.getContext(d.state)
        ),
        prompt: {
          id: this.getPromptId("askEmail"),
          trackResponse: false,
          type: "inlinePicker",
          choices: [
            {
              body: this.t("I don't have an email address"),
              value: false
            }
          ],
          emailPrompt: {
            placeholder: this.t("Your email")
          }
        },
        nextStep: this.handleEmail
      }
    }
    return {
      body: this.t(
        this.messages.askEmail ?? "Please type your email address",
        this.getContext(d.state)
      ),
      prompt: { id: this.getPromptId("askEmail"), type: "email" },
      nextStep: this.handleEmail
    }
  }

  @step.logStateAndResponse
  async handleEmail(d: IStepData<State, string | false>): Promise<IStepResult> {
    const email = typeof d.response !== "boolean" ? d.response : undefined
    if (!email) d.state.canSendEmail = false
    const isValid = typeof d.response !== "boolean" ? isEmail(d.response) : true
    if (!isValid) {
      return {
        body: this.t("Sorry this is not a valid email address. Let's try again"),
        nextStep: this.askEmail
      }
    }
    d.state.email = email
    return {
      nextStep:
        !!email?.length && this.shouldAskEmailPermission //
          ? this.askEmailPermission
          : this.end
    }
  }

  @step.logState
  askEmailPermission(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messages.askEmailPermission ?? "Do you allow us to contact you over email?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askEmailPermission"),
        trackResponse: false,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleEmailPermission
    }
  }

  @step.logStateAndResponse
  async handleEmailPermission(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.canSendEmail = d.response
    return { nextStep: this.end }
  }
}

/* istanbul ignore next */
export default class CollectEmailDialogue extends AdHocDialogue<State, CollectEmailScript> {
  static id = DialogueIDs.CollectEmail
  readonly name: string = "CollectEmailDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>, settings?: ICollectEmailSettings) {
    super(
      CollectEmailDialogue.id,
      new CollectEmailScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
