import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { AssessmentIAPTScriptState } from "./AssessmentIAPTScript"
import AssessmentIAPTScript from "./AssessmentIAPTScript"
import { IStepResult } from "../../../backend/chatbot/models/IStep"

type State = AssessmentIAPTScriptState
export type AssessmentMindScriptState = State

export class AssessmentMindScript extends AssessmentIAPTScript {
  readonly name: string = "AssessmentMindScript"

  /** Generic Handlers */

  async onHandleIAPTPhobiaScale(_state: State): Promise<IStepResult<State>> {
    return {
      nextStep: this.sayReadyForWASAS
    }
  }

  async onHandleIAPTWorkAndSocialAdjustment(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayThatsEverything }
  }
}

/* istanbul ignore next */
export default class AssessmentMindDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentMind
  readonly name: string = "AssessmentMindDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentMindDialogue.id, new AssessmentMindScript(), state, snapshot)
  }
}
