import React from "react"
import moment from "moment"
import "./DateInput.scss"
import TextInput from "./TextInput"
import { useApplicationStore } from "../../contexts/RootStoreContext"

interface Props {
  inputFormat: string
  displayFormat: string
  placeholder?: string
  onSubmit: (body: string, value: number) => void
}

export default function DateInput(props: Props): JSX.Element {
  const { inputFormat, displayFormat, onSubmit, placeholder } = props
  const app = useApplicationStore()
  const [date, setDate] = React.useState<string | undefined>("")
  const [error, setError] = React.useState<string | undefined>()

  const updateDate = React.useCallback((text?: string) => {
    setDate(text)
    setError(undefined)
  }, [])

  const submit = React.useCallback(() => {
    if (!date) {
      setError(app.t("Input can't be empty"))
      return
    }

    const d = moment(date, inputFormat, true)
    const exampleDate = moment().subtract(18, "years").format(inputFormat)

    if (!d.isValid()) {
      setError(app.t("Please use {inputFormat} (eg. {exampleDate})", { inputFormat, exampleDate }))
      return
    }
    onSubmit?.(d.format(displayFormat), d.toDate().getTime())
  }, [app, date, displayFormat, inputFormat, onSubmit])
  return (
    <>
      {!!error && (
        <div className="lb-date-input-error-container">
          <span className="lb-date-input-error">{error}</span>
        </div>
      )}
      <TextInput
        forceValue
        borderless
        value={date}
        placeholder={placeholder || inputFormat}
        clearOnSubmit={false}
        multiline={false}
        autoCorrect={"none"}
        autoCapitalize={"none"}
        autoComplete={"birthday"}
        onChangeText={updateDate}
        onSubmit={submit}
      />
    </>
  )
}
