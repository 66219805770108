import React from "react"
import { observer } from "mobx-react"
import moment from "moment"
import {
  IAppointmentLegacyProps,
  IAppointmentMindProps,
  IAppointmentPrompt,
  ICheckBoxPrompt,
  IDatePickerPrompt,
  IDatePrompt,
  IEmailPrompt,
  IInlinePickerMultiSelectPrompt,
  IInlinePickerSingleSelectPrompt,
  IPhoneNumberPrompt,
  ISliderPrompt,
  ITextPrompt
} from "../../backend/chatbot/models/IPrompt"
import TextInput from "./UserInputs/TextInput"
import InlinePicker from "./UserInputs/InlinePicker"
import { AppointmentTimeslot, IDataPoints } from "@limbic/types"
import ISelectable from "../../models/ISelectable"
import { useApplicationStore, useBotStore, useChatStore } from "../contexts/RootStoreContext"
import EmailInput from "./UserInputs/EmailInput"
import DateInput from "./UserInputs/DateInput"
import DatePickerInput from "./UserInputs/DatePickerInput"
import SliderInput from "./UserInputs/SliderInput"
import CheckboxInput from "./UserInputs/CheckboxInput"
import PhoneNumberInput from "./UserInputs/PhoneNumberInput"
import NameInput from "./UserInputs/NameInput"
import AppointmentCalendar from "../components/UserInputs/AppointmentCalendar"
import type IName from "../../models/IName"
import IAddress from "../../models/IAddress"
import AddressInput from "./UserInputs/AddressInput"
import AppointmentCalendarMind from "./UserInputs/AppointmentCalendarMind"
import AppointmentCalendarLegacy from "./UserInputs/AppointmentCalendarLegacy"

function UserInput(): JSX.Element | null {
  const app = useApplicationStore()
  const bot = useBotStore()
  const chat = useChatStore()
  if (!chat.userPrompt) {
    return null
  }

  switch (chat.userPrompt?.type) {
    case "inlinePicker": {
      const p = chat.userPrompt as IInlinePickerSingleSelectPrompt
      return (
        <InlinePicker
          choices={p.choices}
          searchable={p.searchable}
          initialCountToShow={p.initialCountToShow || 0}
          multiSelect={false}
          textPrompt={p.textPrompt}
          emailPrompt={p.emailPrompt}
          phonePrompt={p.phonePrompt}
          dataPointsName={p.dataPointsName}
          onSubmit={(item: ISelectable, dataPoints?: IDataPoints) =>
            bot.respond(item.body, item.value, dataPoints)
          }
        />
      )
    }
    case "inlinePickerMultiSelect": {
      const p = chat.userPrompt as IInlinePickerMultiSelectPrompt
      return (
        <InlinePicker
          multiSelect={true}
          choices={p.choices}
          searchable={p.searchable}
          initialCountToShow={p.initialCountToShow || 0}
          dataPointsName={p.dataPointsName}
          onSubmit={(items: ISelectable[], dataPoints?: IDataPoints) =>
            bot.respond(
              items.map(i => i.body).join(", "),
              items.map(i => i.value),
              dataPoints
            )
          }
        />
      )
    }
    case "name":
      return (
        <NameInput //
          onSubmit={(name: IName, dataPoints?: IDataPoints) => {
            const { firstName, middleNames, lastName } = name
            const body = `${firstName}${middleNames ? ` ${middleNames}` : ""} ${lastName}`
            bot.respond(body, name, dataPoints)
          }}
        />
      )
    case "address":
      return (
        <AddressInput //
          onSubmit={(fullAddress: IAddress, dataPoints?: IDataPoints) => {
            const { address, address2, city, county } = fullAddress
            const body = [address, address2, city, county].filter(Boolean).join(", ")
            bot.respond(body, fullAddress, dataPoints)
          }}
        />
      )
    case "email": {
      const p = chat.userPrompt as IEmailPrompt
      return (
        <EmailInput //
          placeholder={p.placeholder}
          onSubmit={(body: string, value: string) => bot.respond(body, value)}
        />
      )
    }
    case "phoneNumber": {
      const p = chat.userPrompt as IPhoneNumberPrompt
      return (
        <PhoneNumberInput //
          cancelLabel={p.cancelLabel}
          cancelIsEmptySubmit={p.cancelIsEmptySubmit}
          skipValue={p.skipValue}
          forceMobile={p.forceMobile}
          forceLandline={p.forceLandline}
          placeholder={p.placeholder}
          supportedCountries={p.supportedCountries}
          onSubmit={(phoneNumber: string) => bot.respond(phoneNumber, phoneNumber)}
        />
      )
    }
    case "date": {
      const p = chat.userPrompt as IDatePrompt
      const inputFormat = p.inputFormat || "DD-MM-YYYY"
      const displayFormat = p.displayFormat || "DD MMM YYYY"
      return (
        <DateInput //
          displayFormat={displayFormat}
          inputFormat={inputFormat}
          placeholder={p.placeholder}
          onSubmit={(body: string, value: number) => bot.respond(body, value)}
        />
      )
    }
    case "datePicker": {
      const p = chat.userPrompt as IDatePickerPrompt
      return (
        <DatePickerInput
          past={p.past}
          future={p.future}
          onSubmit={(t: number) => bot.respond(moment(t).format("DD MMM YYYY"), t)}
        />
      )
    }
    case "slider": {
      const p = chat.userPrompt as ISliderPrompt
      const { title } = p
      return (
        <SliderInput
          min={p.min}
          max={p.max}
          labels={p.labels}
          notApplicable={p.notApplicable}
          onSubmit={(points?: number, answer?: string, dataPoints?: IDataPoints) =>
            bot.respond(app.t(`${answer || points}`), { title, answer, points }, dataPoints)
          }
          dataPointsName={p.dataPointsName}
        />
      )
    }
    case "checkbox": {
      const p = chat.userPrompt as ICheckBoxPrompt
      return (
        <CheckboxInput
          options={p.options}
          isRequired={p.isRequired}
          onSubmit={(b, v) => bot.respond(b, v)}
        />
      )
    }
    case "appointment": {
      const p = chat.userPrompt as IAppointmentPrompt
      return (
        <AppointmentCalendar
          appointments={p.appointments}
          onSubmit={(body: string, slot: AppointmentTimeslot | false) => bot.respond(body, slot)}
        />
      )
    }
    case "appointmentLegacy": {
      const p = chat.userPrompt as IAppointmentLegacyProps
      return (
        <AppointmentCalendarLegacy
          appointments={p.appointments}
          onSubmit={item => bot.respond(item.body, item.value)}
          withUserID={p.withUserID}
          isFullScreen={p.isFullScreen}
          hideDuration={p.hideDuration}
        />
      )
    }
    case "appointmentMind": {
      const p = chat.userPrompt as IAppointmentMindProps
      return (
        <AppointmentCalendarMind
          appointments={p.appointments}
          onSubmit={item => bot.respond(item.body, item.value)}
          isFullScreen={p.isFullScreen}
        />
      )
    }
    case "text":
    default: {
      const p = chat.userPrompt as ITextPrompt | undefined
      return (
        <TextInput
          value={p?.value}
          borderless
          forceValue={p?.forceValue}
          placeholder={p?.placeholder}
          cancelLabel={p?.cancelLabel}
          trimAllSpacesOnSubmit={p?.trimAllSpacesOnSubmit}
          trimAllSpacesOnValidation={p?.trimAllSpacesOnValidation}
          clearOnSubmit={p?.clearOnSubmit}
          cancelIsEmptySubmit={p?.cancelIsEmptySubmit}
          validation={p?.validation}
          validationExplainer={p?.validationExplainer}
          onSubmit={(text?: string, dataPoints?: IDataPoints) =>
            bot.respond(text, text, dataPoints)
          }
          dataPointsName={p?.dataPointsName}
        />
      )
    }
  }
}

export default observer(UserInput)
