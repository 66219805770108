import {
  CheckPostCodeFromAddressLookupScript,
  CheckPostCodeFromAddressLookupScriptState
} from "./CheckPostCodeFromAddressLookupDialogue"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { step } from "../../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"

interface State extends CheckPostCodeFromAddressLookupScriptState {
  accommodationStatus?: string
}

export type CheckPostCodeFromAddressLookupMHMScriptState = State

export class CheckPostCodeFromAddressLookupMHMScript extends CheckPostCodeFromAddressLookupScript {
  readonly name: string = "CheckPostCodeFromAddressLookupMHMScript"

  /** Script Steps */

  // Overriding to remove the message
  @step
  returnToAskAddress(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askAddress }
  }

  @step.logState
  askAccommodationStatus(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("What is your accommodation status?"),
      prompt: {
        id: this.getPromptId("askAccommodationStatus"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Owner Occupier"), value: "OWNER" },
          {
            body: this.t("Tenant - Local Authority/Housing Association/Registered Landlord"),
            value: "TENANT_NON_PRIVATE"
          },
          { body: this.t("Tenant - private landlord"), value: "TENANT_PRIVATE" },
          { body: this.t("Nursing home for older persons"), value: "NURSING_HOME" },
          {
            body: this.t("Staying with friends/family as a short term guest"),
            value: "AT_FRIENDS"
          },
          { body: this.t("Other"), value: "OTHER" },
          { body: this.t("Homeless"), value: "HOMELESS" }
        ],
        dataPointsName: "askAccommodationStatus"
      },
      nextStep: this.handleAccommodationStatus
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.accommodationStatus = d.response
  })
  handleAccommodationStatus(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askPermissionToSendMailToAddress }
  }

  /** Generic Handlers */

  async onHandleSelectAddressFromPostCode(_state: State): Promise<IStepResult> {
    return { nextStep: this.askAccommodationStatus }
  }

  async onHandleAddressWithCrisis(_state: State): Promise<IStepResult> {
    return { nextStep: this.askAccommodationStatus }
  }
}

export default class CheckPostCodeFromAddressLookupMHMDialogue extends AdHocDialogue<
  State,
  CheckPostCodeFromAddressLookupMHMScript
> {
  static id = DialogueIDs.CheckPostCodeFromAddressLookupMHM
  readonly name: string = "CheckPostCodeFromAddressLookupMHMDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      CheckPostCodeFromAddressLookupMHMDialogue.id,
      new CheckPostCodeFromAddressLookupMHMScript(),
      state,
      snapshot
    )
  }
}
