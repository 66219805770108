import React from "react"
import { observer } from "mobx-react"
import "./Sidebar.scss"
import "./SidebarEssex.scss"
import { useConfigStore } from "../../contexts/RootStoreContext"

export interface Props {
  visible?: boolean
}

function Sidebar(props: Props): JSX.Element | null {
  const config = useConfigStore()
  const { visible } = props
  if (!visible) {
    return null
  }

  return (
    <div className="lb-sidebar-container">
      <article className="lb-sidebar-card">
        <div className="lb-header">
          <img src={config.logo} className="lb-header-img" alt="" />
          <h3 className="subtitle">Refer yourself to {config.organisationName}</h3>
        </div>
        <p>
          Buckinghamshire Talking Therapies offers a range of FREE, tried and tested psychological
          therapies that helps people aged 18 or over, who are registered at a Bucks GP, with common
          mental health problems
        </p>
        <p>
          You can find more information about support available{" "}
          <a
            href="https://www.oxfordhealth.nhs.uk/bucks-talking-therapies/"
            target="_blank noreferrer">
            here
          </a>
        </p>
        <p>
          If you would rather talk to us about your referral, please call us on 01865 901600 or text
          "TALK" and your name to 07798 677169 and a member of the team will return your call
        </p>
        <p>
          If you would prefer to use our standard form, please visit the links on this page{" "}
          <a
            href="https://www.iaptportal.co.uk/selfreferral.aspx?ServiceCode=BUCKS&ReferralType=0"
            target="_blank noreferrer">
            this page
          </a>
        </p>
        <p>You can also text TALK and your name to 07798 667 169</p>
        {config.iaptIcons?.map?.(link => (
          <img key={link} className="nhs-logo" src={link} alt={link} />
        ))}
      </article>
      <div className="lb-sidebar-spacer" />
      <article className="lb-sidebar-card">
        <p>
          Limbic helps people access psychological support quicker and easier. Your data is secured
          according to the highest NHS standards.
        </p>
        <img
          className="badges"
          src="https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/data-pics.png"
          alt={"Badges"}
        />
      </article>
    </div>
  )
}

export default observer(Sidebar)
