import Dialogue, { IDialogueSnapshot } from "../../backend/chatbot/Dialogue"
import { step } from "../../backend/chatbot/decorators/step"
import BaseScript from "../BaseScript"
import { DialogueIDs } from "../DialogueIDs"
import type { SurveyScriptState } from "../createSurveyDialogue"
import type { IStepData, IStepResult } from "../../backend/chatbot/models/IStep"
import dialoguesRegistry from "../dialoguesRegistry"

type State = SurveyScriptState

class SpecificPhobiaIntroScript extends BaseScript<State> {
  readonly name: string = "SpecificPhobiaIntroScript"

  /** Script Steps */

  @step
  start(_d: IStepData<State>): IStepResult<State> {
    this.timeEvent(`${this.name} Finished`)
    return { nextStep: this.askWhatMakesYouMostAnxious }
  }

  @step
  end(d: IStepData<State>): IStepResult {
    this.track(`${this.name} Finished`)
    return super.end(d)
  }

  @step
  askWhatMakesYouMostAnxious(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "The following questions ask about thoughts, feelings, and behaviors that you may have had in a variety of situations",
        "Please select the item below that makes you most anxious",
        "Choose only one item and make your ratings based on the situations included in that item"
      ]),
      prompt: {
        id: this.getPromptId("askWhatMakesYouMostAnxious"),
        trackResponse: true,
        type: "inlinePicker",
        // TODO: May need less descriptive "values"
        choices: [
          {
            body: this.t("Driving, flying, tunnels, bridges, or enclosed spaces"),
            value: "Driving, flying, tunnels, bridges, or enclosed spaces"
          },
          { body: this.t("Animals or insects"), value: "Animals or insects" },
          { body: this.t("Heights, storms, or water"), value: "Heights, storms, or water" },
          { body: this.t("Blood, needles, or injections"), value: "Blood, needles, or injections" },
          { body: this.t("Choking or vomiting"), value: "Choking or vomiting" }
        ],
        dataPointsName: "askWhatMakesYouMostAnxious"
      },
      nextStep: this.handleWhatMakesYouMostAnxious
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>("0", "What makes you most anxious?", "specificPhobiaResponses")
  handleWhatMakesYouMostAnxious(_d: IStepData<State, boolean>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.09)
    return { nextStep: this.goToSpecificPhobiaDialogue }
  }

  @step.logState
  goToSpecificPhobiaDialogue(d: IStepData<State>): IStepResult {
    const SpecificPhobiaDialogue = dialoguesRegistry.get(DialogueIDs.SpecificPhobia)
    return {
      nextDialogue: new SpecificPhobiaDialogue({ ...d.state }),
      nextStep: this.handleSpecificPhobiaDialogue
    }
  }

  @step.logState
  async handleSpecificPhobiaDialogue(
    d: IStepData<State, undefined, SurveyScriptState>
  ): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    return { nextStep: this.end }
  }
}

export default class SpecificPhobiaIntroDialogue extends Dialogue<State> {
  static id = DialogueIDs.SpecificPhobiaIntro
  readonly name: string = "SpecificPhobiaIntroDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(SpecificPhobiaIntroDialogue.id, new SpecificPhobiaIntroScript(), state, snapshot)
  }
}
