export function toLowerCaseProperties(obj: Record<string, any>): Record<string, any> {
  const keys = Object.keys(obj)
  return keys.reduce((result, key) => ({ ...result, [key.toLowerCase()]: obj[key] }), {})
}

export function mergeObjects(obj1: Record<string, any>, obj2: Record<string, any>): void {
  Object.keys(obj2).forEach(key => (obj1[key] = obj2[key]))
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function enumToObject(e: any): Record<string, any> {
  return Object.keys(e).reduce((result, key) => ({ ...result, [key]: key }), {})
}

/**
 * Update a value at a given path in an object. If the
 * path does not exist, it will be created.
 * @param obj {Record<string, any>} The object to update
 * @param path {string} The path to update
 * @param value {any} The value to set
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function upsertPath(obj?: Record<string, any>, path?: string, value?: any): void {
  if (!obj || !path?.length) return
  const keys = path?.split(".") ?? []
  const lastKey = keys.pop() ?? ""
  let mutatedObj = obj
  for (let i = 0, { length } = keys; i < length; i++) {
    const key = keys[i]
    mutatedObj[key] ??= {}
    if (typeof mutatedObj[key] !== "object") mutatedObj[key] = {}
    mutatedObj = mutatedObj[key]
  }
  mutatedObj[lastKey] = value
}
