import self from "./createReferral"
import { ReferralPayload } from "@limbic/types"
import Logger from "../../../utils/Logger"
import delay from "../../../utils/delay"
import client from "./_client"

interface Response {
  _id: string
  signupCode: string
  limbic: { tags?: string[] }
}

const TOTAL_RETRIES = 2

export default async function createReferral(
  payload: ReferralPayload,
  retry = 0
): Promise<Response | undefined> {
  try {
    return await client.post("/referral/create", payload)
  } catch (e) {
    Logger.getInstance().exception(e, "createReferral fetch failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`createReferral body for retry ${retry}`, JSON.stringify(payload))
      Logger.getInstance().message("createReferral retry")
      await delay(1)
      return await self(payload, retry + 1)
    }
    throw e
  }
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}
