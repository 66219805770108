import { IClinicalPath } from "@limbic/types"
import { IClinicalPathOverride } from "../../../models/IClinicalPath"

export const clinicalPathsVHG: Record<IClinicalPath["id"], IClinicalPathOverride> = {
  complex_comorbid: { defaultReferralType: "Full Assessment (Complex Comorbid)" },
  undetermined: { defaultReferralType: "Full Assessment (Undetermined)" },
  "1": { defaultReferralType: "Step 1 Limbic Assessment (Below Caseness)" },
  "2": { defaultReferralType: "Step 1 Limbic Assessment (Below Caseness)" },
  "3": { defaultReferralType: "Step 1 Limbic Assessment (Below Caseness)" },
  "4": { defaultReferralType: "Step 1 Limbic Assessment (Moderate Depression)" },
  "5": {
    defaultReferralType:
      "Step 1 Limbic Assessment (Moderate Depression, Moderately Severe Functional Impairment)"
  },
  "6": {
    defaultReferralType:
      "Step 1 Limbic Assessment (Moderate Depression, Severe Functional Impairment)"
  },
  "7": { defaultReferralType: "Step 1 Limbic Assessment (Moderately Severe Depression)" },
  "8": {
    defaultReferralType:
      "Step 1 Limbic Assessment (Moderately Severe Depression, Moderately Severe Functional Impairment)"
  },
  "9": {
    defaultReferralType:
      "Step 1 Limbic Assessment (Moderately Severe Depression, Severe Functional Impairment)"
  },
  "10": { defaultReferralType: "Full Assessment (Severe Depression)" },
  "11": {
    defaultReferralType:
      "Full Assessment (Severe Depression, Moderately Severe Functional Impairment)"
  },
  "12": {
    defaultReferralType: "Full Assessment (Severe Depression, Severe Functional Impairment)"
  },
  "13": { defaultReferralType: "Full Assessment (Social Phobia)" },
  "14": {
    defaultReferralType: "Full Assessment (Social Phobia, Moderately Severe Functional Impairment)"
  },
  "15": { defaultReferralType: "Full Assessment (Social Phobia, Severe Functional Impairment)" },
  "16": { defaultReferralType: "Full Assessment (Moderate Social Phobia)" },
  "17": {
    defaultReferralType:
      "Full Assessment (Moderate Social Phobia, Moderately Severe Functional Impairment)"
  },
  "18": {
    defaultReferralType: "Full Assessment (Moderate Social Phobia, Severe Functional Impairment)"
  },
  "19": { defaultReferralType: "Full Assessment (Severe Social Phobia)" },
  "20": {
    defaultReferralType:
      "Full Assessment (Severe Social Phobia, Moderately Severe Functional Impairment)"
  },
  "21": {
    defaultReferralType: "Full Assessment (Severe Social Phobia, Severe Functional Impairment)"
  },
  "22": { defaultReferralType: "Full Assessment (Specific Phobia)" },
  "23": {
    defaultReferralType:
      "Full Assessment (Specific Phobia, Moderately Severe Functional Impairment)"
  },
  "24": { defaultReferralType: "Full Assessment (Specific Phobia, Severe Functional Impairment)" },
  "25": { defaultReferralType: "Full Assessment (Needle/Blood/Injury Phobia)" },
  "26": {
    defaultReferralType:
      "Full Assessment (Needle/Blood/Injury Phobia, Moderately Severe Functional Impairment)"
  },
  "27": {
    defaultReferralType:
      "Full Assessment (Needle/Blood/Injury Phobia, Severe Functional Impairment)"
  },
  "28": { defaultReferralType: "Full Assessment (Panic)" },
  "29": { defaultReferralType: "Full Assessment (Panic, Moderately Severe Functional Impairment)" },
  "30": { defaultReferralType: "Full Assessment (Severe Panic, Severe Functional Impairment)" },
  "31": { defaultReferralType: "Step 1 Limbic Assessment (Moderate GAD)" },
  "32": {
    defaultReferralType:
      "Step 1 Limbic Assessment (Moderate GAD, Moderately Severe Functional Impairment)"
  },
  "33": {
    defaultReferralType: "Step 1 Limbic Assessment (Moderate GAD, Severe Functional Impairment)"
  },
  "34": { defaultReferralType: "Full Assessment (Severe GAD)" },
  "35": {
    defaultReferralType: "Full Assessment (Severe GAD, Moderately Severe Functional Impairment)"
  },
  "36": { defaultReferralType: "Full Assessment (Severe GAD, Severe Functional Impairment)" },
  "37": { defaultReferralType: "Full Assessment (Health Anxiety)" },
  "38": {
    defaultReferralType: "Full Assessment (Health Anxiety, Moderately Severe Functional Impairment)"
  },
  "39": { defaultReferralType: "Full Assessment (Health Anxiety, Severe Functional Impairment)" },
  "40": { defaultReferralType: "Step 1 Limbic Assessment (Moderate Health Anxiety)" },
  "41": {
    defaultReferralType:
      "Step 1 Limbic Assessment (Moderate Health Anxiety, Moderately Severe Functional Impairment)"
  },
  "42": {
    defaultReferralType:
      "Step 1 Limbic Assessment (Moderate Health Anxiety, Severe Functional Impairment)"
  },
  "43": { defaultReferralType: "Full Assessment (Severe Health Anxiety)" },
  "44": {
    defaultReferralType:
      "Full Assessment (Severe Health Anxiety, Moderately Severe Functional Impairment)"
  },
  "45": {
    defaultReferralType: "Full Assessment (Severe Health Anxiety, Severe Functional Impairment)"
  },
  "46": { defaultReferralType: "Full Assessment (OCD)" },
  "47": { defaultReferralType: "Full Assessment (OCD, Moderately Severe Functional Impairment)" },
  "48": { defaultReferralType: "Full Assessment (OCD, Severe Functional Impairment)" },
  "49": { defaultReferralType: "Full Assessment (PTSD)" },
  "50": { defaultReferralType: "Full Assessment (PTSD, Moderately Severe Functional Impairment)" },
  "51": { defaultReferralType: "Full Assessment (PTSD, Severe Functional Impairment)" }
}
