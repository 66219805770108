import { AssessmentPitchIAPTScript } from "./AssessmentPitchIAPTDialogue"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../DialogueIDs"
import type { AssessmentPitchIAPTScriptState } from "./AssessmentPitchIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

interface State extends AssessmentPitchIAPTScriptState {
  homePhoneNumber?: string
  mobilePhoneNumber?: string
}

export type AssessmentPitchMPFTState = State

export class AssessmentPitchMPFTScript extends AssessmentPitchIAPTScript {
  readonly name: string = "AssessmentPitchMPFTScript"

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    this.updateReferralType(d.state)
    // 👇 this is needed because MPFT ask mobile
    // and home phone numbers separately ¯\_(ツ)_/¯
    if (d.state.phoneNumber || d.state.homePhoneNumber || d.state.mobilePhoneNumber) {
      return { nextStep: this.checkForNeedToBeCalled }
    }
    return { nextStep: this.goToCollectPhoneNumber }
  }

  @step.logState
  checkForNeedToBeCalled(_d: IStepData<State>): IStepResult {
    return { nextStep: this.sayWeCanSpeedUpReferral }
  }

  @step.logState
  sayWeCanSpeedUpReferral(d: IStepData<State>): IStepResult {
    return {
      body: [
        "I'm now going to ask you a few questions to measure symptoms of common mental health issues",
        "We will use your answers to measure your mood throughout your therapy journey with us"
      ],
      prompt: {
        id: this.getPromptId("sayWeCanSpeedUpReferral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      },
      nextStep: this.end
    }
  }
}

export default class AssessmentPitchMPFTDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentPitchMPFT
  readonly name: string = "AssessmentPitchMPFTDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentPitchMPFTDialogue.id, new AssessmentPitchMPFTScript(), state, snapshot)
  }
}
