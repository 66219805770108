import {
  CollectPhoneNumberScript,
  CollectPhoneNumberScriptState,
  ICollectPhoneNumberSettings
} from "./CollectPhoneNumberDialogue"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { step } from "../../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { IAPTIDs } from "../../../../models/IIAPTService"

interface State extends CollectPhoneNumberScriptState {
  preferredPhoneType?: string
}

export type CollectPhoneNumberMHMScriptState = State

export class CollectPhoneNumberMHMScript extends CollectPhoneNumberScript {
  readonly name: string = "CollectPhoneNumberMHMScript"

  /** Script Steps */

  @step.logState
  askPhoneNumber(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    return {
      body: this.t(
        [
          `{iaptName} require your contact details and your consent to contact you, to arrange an appointment as well as supporting your onwards care`,
          "What's the best phone number to reach you on?"
        ],
        { iaptName }
      ),
      nextStep: this.showPhoneNumberPrompt
    }
  }

  @step.logState
  askPreferredPhoneType(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("And what type of phone number is this?"),
      prompt: {
        id: this.getPromptId("askPreferredPhoneType"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Mobile phone"), value: "Mobile" },
          { body: this.t("Work phone"), value: "Work" },
          { body: this.t("Home phone"), value: "Home" }
        ],
        dataPointsName: "askPreferredPhoneType"
      },
      nextStep: this.handlePreferredPhoneType
    }
  }

  @step.logStateAndResponse
  @step.handleResponse(
    (d: IStepData<State, "Mobile" | "Work" | "Home">, script: CollectPhoneNumberMHMScript) => {
      d.state.preferredPhoneType = d.response
      script.referralStore.setCustomField<State>("preferredPhoneType", d.response)
    }
  )
  handlePreferredPhoneType(_d: IStepData<State, "Mobile" | "Work" | "Home">): IStepResult {
    return { nextStep: this.askCanIContactYouOnPhoneNumber }
  }

  /** Generic Handlers */

  async onHandlePhoneNumber(state: State): Promise<IStepResult<State>> {
    if (state.iapt?.id === IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON) {
      return {
        nextStep: this.askPreferredPhoneType
      }
    }
    return {
      nextStep: this.askCanIContactYouOnPhoneNumber
    }
  }
}

export default class CollectPhoneNumberMHMDialogue extends AdHocDialogue<
  State,
  CollectPhoneNumberMHMScript
> {
  static id = DialogueIDs.CollectPhoneNumberMHM
  readonly name: string = "CollectPhoneNumberMHMDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: ICollectPhoneNumberSettings
  ) {
    super(
      CollectPhoneNumberMHMDialogue.id,
      new CollectPhoneNumberMHMScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
