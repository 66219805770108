import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { IntroductionIAPTScript } from "./IntroductionIAPTDialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IntroductionScriptState } from "./IntroductionScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = IntroductionScriptState

export class IntroductionWellbeingHubScript extends IntroductionIAPTScript {
  readonly name: string = "IntroductionWellbeingHubScript"

  /** Script Steps */
  @step.logState
  askCanIHelp(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: this.t("Can I help?"),
      prompt: {
        id: this.getPromptId("askCanIHelp"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          {
            body: this.t("Yes, I'd like to refer myself or someone else to {organisationName}", {
              organisationName
            }),
            value: true
          },
          { body: this.t("No, I'm just browsing"), value: false }
        ],
        isUndoAble: false
      },
      nextStep: this.handleCanIHelp
    }
  }
}

export default class IntroductionWellbeingHubDialogue extends Dialogue<State> {
  static id = DialogueIDs.IntroductionWellbeingHub
  readonly name: string = "IntroductionWellbeingHubDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      IntroductionWellbeingHubDialogue.id,
      new IntroductionWellbeingHubScript(),
      state,
      snapshot
    )
  }
}
