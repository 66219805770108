import type { AssessmentPitchIAPTScriptState } from "./AssessmentPitchIAPTDialogue"
import { AssessmentPitchIAPTScript } from "./AssessmentPitchIAPTDialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"

type State = AssessmentPitchIAPTScriptState

export type AssessmentPitchCWScriptState = State

export class AssessmentPitchCWScript extends AssessmentPitchIAPTScript {
  readonly name: string = "AssessmentPitchCWScript"

  /** Script Steps */

  @step.logState
  checkForNeedToBeCalled(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askWannaDoQuestionnaires }
  }

  @step.logState
  askWannaDoQuestionnaires(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "I'm now going to ask you a few questions to measure symptoms of common mental health issues",
        "And I will use the results to match you with the best therapist for your needs",
        "Please note these results will be not be reviewed by a clinician until your appointment"
      ],
      prompt: {
        id: this.getPromptId("askWannaDoQuestionnaires"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleWannaDoQuestionnaires
    }
  }
}

/* istanbul ignore next */
export default class AssessmentPitchCWDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentPitchCW
  readonly name: string = "AssessmentPitchCWDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentPitchCWDialogue.id, new AssessmentPitchCWScript(), state, snapshot)
  }
}
