import { IAPTGPMap, IAPTIDs } from "../../models/IIAPTService"

// NOTE: List is here:
// https://docs.google.com/spreadsheets/d/1GPilTkRdyZY4OXXk5JX5F4zNWA_-I4-C/edit#gid=1105138086

const iaptBuckinghamshireGPs: IAPTGPMap = {
  K82011: IAPTIDs.BUCKINGHAMSHIRE,
  K82066: IAPTIDs.BUCKINGHAMSHIRE,
  K82012: IAPTIDs.BUCKINGHAMSHIRE,
  K82023: IAPTIDs.BUCKINGHAMSHIRE,
  K82029: IAPTIDs.BUCKINGHAMSHIRE,
  K82046: IAPTIDs.BUCKINGHAMSHIRE,
  K82047: IAPTIDs.BUCKINGHAMSHIRE,
  K82021: IAPTIDs.BUCKINGHAMSHIRE,
  K82028: IAPTIDs.BUCKINGHAMSHIRE,
  K82040: IAPTIDs.BUCKINGHAMSHIRE,
  Y01964: IAPTIDs.BUCKINGHAMSHIRE,
  K82014: IAPTIDs.BUCKINGHAMSHIRE,
  K82019: IAPTIDs.BUCKINGHAMSHIRE,
  K82038: IAPTIDs.BUCKINGHAMSHIRE,
  K82051: IAPTIDs.BUCKINGHAMSHIRE,
  K82078: IAPTIDs.BUCKINGHAMSHIRE,
  K82008: IAPTIDs.BUCKINGHAMSHIRE,
  K82621: IAPTIDs.BUCKINGHAMSHIRE,
  K82024: IAPTIDs.BUCKINGHAMSHIRE,
  K82058: IAPTIDs.BUCKINGHAMSHIRE,
  K82022: IAPTIDs.BUCKINGHAMSHIRE,
  K82010: IAPTIDs.BUCKINGHAMSHIRE,
  K82017: IAPTIDs.BUCKINGHAMSHIRE,
  K82053: IAPTIDs.BUCKINGHAMSHIRE,
  K82044: IAPTIDs.BUCKINGHAMSHIRE,
  K82020: IAPTIDs.BUCKINGHAMSHIRE,
  K82048: IAPTIDs.BUCKINGHAMSHIRE,
  K82603: IAPTIDs.BUCKINGHAMSHIRE,
  K82036: IAPTIDs.BUCKINGHAMSHIRE,
  K82030: IAPTIDs.BUCKINGHAMSHIRE,
  K82001: IAPTIDs.BUCKINGHAMSHIRE,
  K82618: IAPTIDs.BUCKINGHAMSHIRE,
  K82035: IAPTIDs.BUCKINGHAMSHIRE,
  K82004: IAPTIDs.BUCKINGHAMSHIRE,
  K82049: IAPTIDs.BUCKINGHAMSHIRE,
  K82068: IAPTIDs.BUCKINGHAMSHIRE,
  K82070: IAPTIDs.BUCKINGHAMSHIRE,
  K82061: IAPTIDs.BUCKINGHAMSHIRE,
  K82079: IAPTIDs.BUCKINGHAMSHIRE,
  K82031: IAPTIDs.BUCKINGHAMSHIRE,
  K82045: IAPTIDs.BUCKINGHAMSHIRE,
  K82006: IAPTIDs.BUCKINGHAMSHIRE,
  K82055: IAPTIDs.BUCKINGHAMSHIRE,
  K82033: IAPTIDs.BUCKINGHAMSHIRE,
  K82007: IAPTIDs.BUCKINGHAMSHIRE,
  K82073: IAPTIDs.BUCKINGHAMSHIRE
}

export const iaptGPMapBuckinghamshire: IAPTGPMap = {
  ...iaptBuckinghamshireGPs
}
