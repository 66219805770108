/* eslint-disable @typescript-eslint/no-empty-function */
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../../DialogueIDs"
import { step } from "../../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import BaseScript, { BaseScriptState } from "../../../BaseScript"
import {
  ClinicalConditions,
  StateConditions,
  IDefaultChatFlowMessagesGoodbyeRecap,
  IDefaultChatFlowSettingsGoodbyeRecap,
  IGoodbyeRecapMessageConditionChain,
  IGoodbyeRecapMessagesConditions
} from "@limbic/types"

export interface IGoodbyeRecapSettings extends IDefaultChatFlowSettingsGoodbyeRecap {
  messages?: IDefaultChatFlowMessagesGoodbyeRecap
}

interface State extends BaseScriptState {
  appointment?: string
  isIdleSubmitted?: boolean
  stateAndClinicalStore?: Record<ClinicalConditions | StateConditions, any>
}

export type GoodbyeRecapScriptState = State

export class GoodbyeRecapScript extends BaseScript<State> {
  readonly name: string = "GoodbyeRecapScript"
  protected readonly messages: IDefaultChatFlowMessagesGoodbyeRecap | undefined
  protected readonly conditions?: IGoodbyeRecapMessagesConditions

  constructor(settings?: IGoodbyeRecapSettings | undefined) {
    super()
    this.messages = settings?.messages ?? {}
    this.conditions = settings?.conditions ?? {}
  }

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.sayRecapMessage }
  }

  @step
  async sayRecapMessage(d: IStepData<State>): Promise<IStepResult> {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    const organisationGenericPhoneNumber = this.rootStore.configStore.organisationGenericPhoneNumber
    const organisationPhoneNumbers = this.rootStore.configStore.organisationPhoneNumbers

    const body = this.getRecapMessage(d.state)

    return {
      body: this.t(body, {
        ...this.getContext(d.state),
        name,
        organisationName,
        organisationGenericPhoneNumber,
        organisationPhoneNumbers
      }),
      nextStep: this.end
    }
  }

  /** Generic Handlers */
  getRecapMessage(state: State): string | string[] {
    const clinicalStore = this.rootStore.clinicalStore

    const currentStateAndClinicalStore = {
      [ClinicalConditions.IS_CRISIS]: !!clinicalStore.isCrisis,
      [ClinicalConditions.IS_HIGH_RISK]: !!clinicalStore.isHighRisk,
      [ClinicalConditions.IS_MODERATE_RISK]: !!clinicalStore.isModerateRisk,
      [ClinicalConditions.IS_TRIGGERWORDS_CRISIS]: !!clinicalStore.isCrisisFromTriggerWords,
      [ClinicalConditions.TREATMENT]: !!clinicalStore.getAcceptedTreatment(), // we shouldn't care if its undefined
      [StateConditions.APPOINTMENT]: !!state.appointment,
      [StateConditions.CAN_KEEP_SELF_SAFE]: !!state.canKeepSelfSafe,
      [StateConditions.IS_IDLE_SUBMITTED]: !!state.isIdleSubmitted,
      [StateConditions.NEEDS_ASSESSMENT_CALL]: !!state.needsAssessmentCall,
      [StateConditions.NEEDS_TO_CALL]: !!state.needsToCall,
      [StateConditions.REFERRAL_SUBMITTED]: !!state.referralSubmitted,
      [StateConditions.REQUIRES_URGENT_SUPPORT]: !!state.requiresUrgentSupport
    }
    // 👇 This is done on purpose to expose it to the tests for assertions
    state.stateAndClinicalStore = currentStateAndClinicalStore

    if (this.conditions && Object.keys(this.conditions).length) {
      const keys = Object.keys(this.conditions)
      const messages: any[] = []
      for (let i = 0, { length } = keys; i < length; i++) {
        const key = keys[i]
        const keyMessages = this.messages?.[key] ?? []
        if (!keyMessages.length) continue

        const conditionSet: IGoodbyeRecapMessageConditionChain = this.conditions?.[key].filter(
          c => c.value !== "skip"
        )
        if (!conditionSet.length) continue

        const matches = conditionSet.every(
          condition => condition.value === currentStateAndClinicalStore[condition.name]
        )
        if (matches) messages.push(...keyMessages)
      }
      if (messages.length) return messages
    }
    return "Well, it's been a pleasure getting to know you {name}"
  }
}

/* istanbul ignore next */
export default class GoodbyeRecapDialogue extends AdHocDialogue<State, GoodbyeRecapScript> {
  static id = DialogueIDs.GoodbyeRecap
  readonly name: string = "GoodbyeRecapDialogue"

  constructor(state: State, snapshot?: IDialogueSnapshot<State>, settings?: IGoodbyeRecapSettings) {
    super(
      GoodbyeRecapDialogue.id,
      new GoodbyeRecapScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
