export default function fetchWithTimeout(
  url: string,
  options: RequestInit,
  timeout = 2000
): Promise<any> {
  const timeoutPromise = new Promise((_resolve, reject) => {
    const id = setTimeout(() => {
      clearTimeout(id)
      reject(new Error("Request timed out"))
    }, timeout)
  })

  return Promise.race([fetch(url, options), timeoutPromise])
}
