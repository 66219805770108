import { AssessmentPitchIAPTScript } from "./AssessmentPitchIAPTDialogue"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../DialogueIDs"
import type { AssessmentPitchIAPTScriptState } from "./AssessmentPitchIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = AssessmentPitchIAPTScriptState

export type AssessmentPitchEssexState = State

export class AssessmentPitchEssexScript extends AssessmentPitchIAPTScript {
  readonly name: string = "AssessmentPitchEssexScript"

  /** Script Steps */

  @step.logState
  checkForNeedToBeCalled(_d: IStepData<State>): IStepResult {
    return { nextStep: this.sayWeCanSpeedUpReferral }
  }

  @step.logState
  sayWeCanSpeedUpReferral(d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "I'm now going to ask you a few questions to measure symptoms of common mental health issues",
        "And I will use the results to plot your progress throughout therapy"
      ]),
      prompt: {
        id: this.getPromptId("sayWeCanSpeedUpReferral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }]
      },
      nextStep: this.end
    }
  }

  @step.logState
  saySomeoneFromAdminWillContact(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Someone from our admin team will be in touch the next working day to book you in for an assessment with one of our clinicians"
      ),
      prompt: {
        id: this.getPromptId("saySomeoneFromAdminWillContact"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Looking forward to it") }, { body: this.t("Okay") }]
      },
      nextStep: this.goToGoodbye
    }
  }
}

export default class AssessmentPitchEssexDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentPitchEssex
  readonly name: string = "AssessmentPitchEssexDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentPitchEssexDialogue.id, new AssessmentPitchEssexScript(), state, snapshot)
  }
}
