/* eslint-disable @typescript-eslint/no-empty-function */
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../../DialogueIDs"
import { step } from "../../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import BaseScript, { BaseScriptState } from "../../../BaseScript"
import { Category } from "@limbic/crisis-detection"
import {
  IDefaultChatFlowMessagesCollectSubstances,
  IDefaultChatFlowSettingsCollectSubstances
} from "@limbic/types"

export interface ICollectSubstancesSettings extends IDefaultChatFlowSettingsCollectSubstances {
  messages?: IDefaultChatFlowMessagesCollectSubstances
}

type State = BaseScriptState
export type CollectSubstancesScriptState = State

export class CollectSubstancesScript extends BaseScript<State> {
  readonly name: string = "CollectSubstancesScript"
  protected readonly messages: IDefaultChatFlowMessagesCollectSubstances | undefined
  protected readonly shouldAskSubstances?: boolean
  protected readonly shouldAskSubstancesForMood?: boolean
  protected readonly shouldAskSubstancesInfo?: boolean
  protected readonly shouldAskSubstancesImpactLife?: boolean
  protected readonly shouldAskNonPrescribedSubstances?: boolean
  protected readonly shouldAskSubstancesAreMedication?: boolean
  protected readonly shouldAskPrescribedMedication?: boolean
  protected readonly shouldAskMedicationInfo?: boolean
  protected readonly shouldAskMedicationWithinDosage?: boolean
  protected readonly shouldAskMedicationNotTaking?: boolean
  protected readonly shouldAskMedicationNotTakingInfo?: boolean
  protected readonly substancesKey?: string
  protected readonly substancesForMoodKey?: string
  protected readonly substancesInfoKey?: string
  protected readonly substancesImpactLifeKey?: string
  protected readonly nonPrescribedSubstancesKey?: string
  protected readonly substancesAreMedicationKey?: string
  protected readonly prescribedMedicationKey?: string
  protected readonly medicationInfoKey?: string
  protected readonly medicationWithinDosageKey?: string
  protected readonly medicationNotTakingKey?: string
  protected readonly medicationNotTakingInfoKey?: string

  constructor(settings?: ICollectSubstancesSettings | undefined) {
    super()
    this.messages = settings?.messages ?? {}
    this.shouldAskSubstances = settings?.shouldAskSubstances ?? true
    this.shouldAskSubstancesForMood = settings?.shouldAskSubstancesForMood
    this.shouldAskSubstancesInfo = settings?.shouldAskSubstancesInfo
    this.shouldAskSubstancesImpactLife = settings?.shouldAskSubstancesImpactLife
    this.shouldAskNonPrescribedSubstances = settings?.shouldAskNonPrescribedSubstances ?? true
    this.shouldAskSubstancesAreMedication = settings?.shouldAskSubstancesAreMedication ?? true
    this.shouldAskPrescribedMedication = settings?.shouldAskPrescribedMedication ?? true
    this.shouldAskMedicationInfo = settings?.shouldAskMedicationInfo ?? true
    this.shouldAskMedicationWithinDosage = settings?.shouldAskMedicationWithinDosage
    this.shouldAskMedicationNotTaking = settings?.shouldAskMedicationNotTaking
    this.shouldAskMedicationNotTakingInfo = settings?.shouldAskMedicationNotTakingInfo ?? true
    this.substancesKey = settings?.substancesKey
    this.substancesForMoodKey = settings?.substancesForMoodKey
    this.substancesInfoKey = settings?.substancesInfoKey
    this.substancesImpactLifeKey = settings?.substancesImpactLifeKey
    this.nonPrescribedSubstancesKey = settings?.nonPrescribedSubstancesKey
    this.substancesAreMedicationKey = settings?.substancesAreMedicationKey
    this.prescribedMedicationKey = settings?.prescribedMedicationKey
    this.medicationInfoKey = settings?.medicationInfoKey
    this.medicationNotTakingKey = settings?.medicationNotTakingKey
    this.medicationNotTakingInfoKey = settings?.medicationNotTakingInfoKey
    this.medicationWithinDosageKey = settings?.medicationWithinDosageKey
  }

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askSubstances }
  }

  @step.logState
  askSubstances(d: IStepData<State>): IStepResult {
    if (!this.shouldAskSubstances) {
      this.logger.breadcrumb({ message: "askSubstances disabled" })
      this.track("askSubstances disabled")
      return { nextStep: this.askSubstancesForMood }
    }

    return {
      body: this.t(
        this.messages?.askSubstances ?? "Do you currently use any recreational drugs?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askSubstances"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askSubstances"
      },
      nextStep: this.handleSubstances
    }
  }

  @step.logStateAndResponse
  async handleSubstances(d: IStepData<State, boolean>): Promise<IStepResult> {
    const key = this.substancesKey ?? "substances"
    d.state[key] = d.response
    this.setPeople({ [key]: d.response })

    return {
      nextStep: d.response ? this.askSubstancesForMood : this.askNonPrescribedSubstances
    }
  }

  @step.logState
  askSubstancesForMood(d: IStepData<State>): IStepResult {
    if (!this.shouldAskSubstancesForMood) {
      this.logger.breadcrumb({ message: "askSubstancesForMood disabled" })
      this.track("askSubstancesForMood disabled")
      return { nextStep: this.askSubstancesInfo }
    }

    return {
      body: this.t(
        this.messages?.askSubstancesForMood ??
          "Are you currently using any recreational drugs to manage your mood?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askSubstancesForMood"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askSubstancesForMood" // Keep this the same for research purposes (ADSM)
      },
      nextStep: this.handleSubstancesForMood
    }
  }

  @step.logStateAndResponse
  async handleSubstancesForMood(d: IStepData<State, boolean>): Promise<IStepResult> {
    const key = this.substancesForMoodKey ?? "substancesForMood"
    d.state[key] = d.response
    this.setPeople({ [key]: d.response })

    return { nextStep: this.askSubstancesInfo }
  }

  @step.logState
  async askSubstancesInfo(d: IStepData<State>): Promise<IStepResult> {
    if (!this.shouldAskSubstancesInfo) {
      this.logger.breadcrumb({ message: "askSubstancesInfo disabled" })
      this.track("askSubstancesInfo disabled")
      return { nextStep: this.askSubstancesImpactLife }
    }

    return {
      body: this.t(
        this.messages?.askSubstancesInfo ??
          "Could you please describe what your use is, and how often do you use them?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askSubstancesInfo"),
        trackResponse: false,
        type: "text",
        forceValue: false,
        cancelLabel: "skip",
        cancelIsEmptySubmit: true
      },
      nextStep: this.handleSubstancesInfoWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>, script: CollectSubstancesScript) => {
    const key = script.substancesInfoKey ?? "substancesInfo"
    d.state[key] = d.response
    script.setPeople({ [key]: d.response })
  })
  @step.checkInputForCrisis({
    getNextStep: (s: CollectSubstancesScript) => s.askSubstancesImpactLife
  })
  handleSubstancesInfoWithCrisis(_d: IStepData<State, string>): IStepResult {
    // response is handled in the decorator
    return { nextStep: this.askSubstancesImpactLife }
  }

  @step.logState
  askSubstancesImpactLife(d: IStepData<State>): IStepResult {
    if (!this.shouldAskSubstancesImpactLife) {
      this.logger.breadcrumb({ message: "askSubstancesImpactLife disabled" })
      this.track("askSubstancesImpactLife disabled")
      return { nextStep: this.askNonPrescribedSubstances }
    }

    return {
      body: this.t(
        this.messages?.askSubstancesImpactLife ??
          "Do these substances have an impact on your home life i.e., work, studies, family life?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askSubstancesImpactLife"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askSubstancesImpactLife"
      },
      nextStep: this.handleSubstancesImpactLife
    }
  }

  @step.logStateAndResponse
  async handleSubstancesImpactLife(d: IStepData<State, boolean>): Promise<IStepResult> {
    const key = this.substancesImpactLifeKey ?? "substancesImpactLife"
    d.state[key] = d.response
    this.setPeople({ [key]: d.response })

    return { nextStep: this.askNonPrescribedSubstances }
  }

  @step.logState
  askNonPrescribedSubstances(d: IStepData<State>): IStepResult {
    if (!this.shouldAskNonPrescribedSubstances) {
      this.logger.breadcrumb({ message: "askNonPrescribedSubstances disabled" })
      this.track("askNonPrescribedSubstances disabled")
      return { nextStep: this.askPrescribedMedication }
    }

    return {
      body: this.t(
        this.messages?.askNonPrescribedSubstances ??
          "Have you taken any other non-prescribed substances to manage your mood?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askNonPrescribedSubstances"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askNonPrescribedSubstances"
      },
      nextStep: this.handleNonPrescribedSubstances
    }
  }

  @step.logStateAndResponse
  async handleNonPrescribedSubstances(d: IStepData<State, boolean>): Promise<IStepResult> {
    const key = this.nonPrescribedSubstancesKey ?? "nonPrescribedSubstances"
    d.state[key] = d.response
    this.setPeople({ [key]: d.response })

    return {
      nextStep: d.response //
        ? this.askSubstancesAreMedication
        : this.askPrescribedMedication
    }
  }

  @step.logState
  askSubstancesAreMedication(d: IStepData<State>): IStepResult {
    if (!this.shouldAskSubstancesAreMedication) {
      this.logger.breadcrumb({ message: "askSubstancesAreMedication disabled" })
      this.track("askSubstancesAreMedication disabled")
      return { nextStep: this.askPrescribedMedication }
    }

    return {
      body: this.t(
        this.messages?.askSubstancesAreMedication ??
          "Are these medications bought over the counter at a pharmacy or supermarket?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askSubstancesAreMedication"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askSubstancesAreMedication"
      },
      nextStep: this.handleSubstancesAreMedication
    }
  }

  @step.logStateAndResponse
  async handleSubstancesAreMedication(d: IStepData<State, boolean>): Promise<IStepResult> {
    const key = this.substancesAreMedicationKey ?? "substancesAreMedication"
    d.state[key] = d.response
    this.setPeople({ [key]: d.response })

    return { nextStep: this.askPrescribedMedication }
  }

  @step.logState
  askPrescribedMedication(d: IStepData<State>): IStepResult {
    if (!this.shouldAskPrescribedMedication) {
      this.logger.breadcrumb({ message: "askPrescribedMedication disabled" })
      this.track("askPrescribedMedication disabled")
      return { nextStep: this.askMedicationInfo }
    }

    return {
      body: this.t(
        this.messages?.askPrescribedMedication ??
          "Are you currently taking any medication that has been prescribed by a doctor?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askPrescribedMedication"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askPrescribedMedication"
      },
      nextStep: this.handlePrescribedMedication
    }
  }

  @step.logStateAndResponse
  async handlePrescribedMedication(d: IStepData<State, boolean>): Promise<IStepResult> {
    const key = this.prescribedMedicationKey ?? "takingPrescribedMedication"
    d.state[key] = d.response
    this.setPeople({ [key]: d.response })

    return {
      nextStep: d.response //
        ? this.askMedicationInfo
        : this.askMedicationNotTaking
    }
  }

  @step.logState
  askMedicationInfo(d: IStepData<State>): IStepResult {
    if (!this.shouldAskMedicationInfo) {
      this.logger.breadcrumb({ message: "askMedicationInfo disabled" })
      this.track("askMedicationInfo disabled")
      return { nextStep: this.askMedicationWithinDosage }
    }

    return {
      body: this.t(
        this.messages?.askMedicationInfo ??
          "Could you let me know the details of the medication you've been prescribed?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askMedicationInfo"),
        trackResponse: false,
        type: "text",
        forceValue: false,
        dataPointsName: "askMedicationInfo"
      },
      nextStep: this.handleMedicationInfoWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>, script: CollectSubstancesScript) => {
    const key = script.medicationInfoKey ?? "medicationInfo"
    d.state[key] = d.response
    script.setPeople({ [key]: d.response })
  })
  @step.checkInputForCrisis({
    ignoredCategories: [Category.Medication],
    getNextStep: (s: CollectSubstancesScript) => s.askMedicationWithinDosage
  })
  handleMedicationInfoWithCrisis(_d: IStepData<State, string>): IStepResult {
    // response is handled in the decorator
    return { nextStep: this.askMedicationWithinDosage }
  }

  @step.logState
  askMedicationWithinDosage(d: IStepData<State>): IStepResult {
    if (!this.shouldAskMedicationWithinDosage) {
      this.logger.breadcrumb({ message: "askMedicationWithinDosage disabled" })
      this.track("askMedicationWithinDosage disabled")
      return { nextStep: this.askMedicationNotTaking }
    }

    return {
      body: this.t(
        this.messages?.askMedicationWithinDosage ??
          "Are you using the medication within the recommended dose range on the packet?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askMedicationWithinDosage"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: "Yes" },
          { body: "No", value: "No" },
          { body: "Not sure", value: "Not sure" }
        ],
        dataPointsName: "askMedicationWithinDosage"
      },
      nextStep: this.handleMedicationWithinDosage
    }
  }

  @step.logStateAndResponse
  async handleMedicationWithinDosage(d: IStepData<State, boolean>): Promise<IStepResult> {
    const key = this.medicationWithinDosageKey ?? "medicationWithinDosage"
    d.state[key] = d.response
    this.setPeople({ [key]: d.response })

    return { nextStep: this.askMedicationNotTaking }
  }

  @step.logState
  askMedicationNotTaking(d: IStepData<State>): IStepResult {
    if (!this.shouldAskMedicationNotTaking) {
      this.logger.breadcrumb({ message: "askMedicationNotTaking disabled" })
      this.track("askMedicationNotTaking disabled")
      return { nextStep: this.end }
    }

    return {
      body: this.t(
        this.messages?.askMedicationNotTaking ??
          "Okay, and just to check, have you been prescribed any medication that you are not taking?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askMedicationNotTaking"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askMedicationNotTaking"
      },
      nextStep: this.handleMedicationNotTaking
    }
  }

  @step.logStateAndResponse
  async handleMedicationNotTaking(d: IStepData<State, boolean>): Promise<IStepResult> {
    const key = this.medicationNotTakingKey ?? "medicationNotTaking"
    d.state[key] = d.response
    this.setPeople({ [key]: d.response })

    return { nextStep: d.response ? this.askMedicationNotTakingInfo : this.end }
  }

  @step.logState
  async askMedicationNotTakingInfo(d: IStepData<State>): Promise<IStepResult> {
    if (!this.shouldAskMedicationNotTakingInfo) {
      this.logger.breadcrumb({ message: "askMedicationNotTakingInfo disabled" })
      this.track("askMedicationNotTakingInfo disabled")
      return { nextStep: this.end }
    }

    return {
      body: this.t(
        this.messages?.askMedicationNotTakingInfo ??
          "Please let me know the details of the medication you've been prescribed but are not taking",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askMedicationNotTakingInfo"),
        trackResponse: false,
        type: "text",
        forceValue: false,
        dataPointsName: "askMedicationNotTakingInfo"
      },
      nextStep: this.handleMedicationNotTakingInfoWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>, script: CollectSubstancesScript) => {
    const key = script.medicationNotTakingInfoKey ?? "medicationNotTakingInfo"
    d.state[key] = d.response
    script.setPeople({ [key]: d.response })
  })
  @step.checkInputForCrisis({
    ignoredCategories: [Category.Medication],
    getNextStep: (s: CollectSubstancesScript) => s.end
  })
  handleMedicationNotTakingInfoWithCrisis(_d: IStepData<State, string>): IStepResult {
    // response is handled in the decorator
    return { nextStep: this.end }
  }
}

/* istanbul ignore next */
export default class CollectSubstancesDetailsDialogue extends AdHocDialogue<
  State,
  CollectSubstancesScript
> {
  static id = DialogueIDs.CollectSubstances
  readonly name: string = "CollectSubstancesDetailsDialogue"

  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: ICollectSubstancesSettings
  ) {
    super(
      CollectSubstancesDetailsDialogue.id,
      new CollectSubstancesScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
