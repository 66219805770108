import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { PermissionsIAPTScript } from "./PermissionsIAPTDialogue"
import type { IStepResult } from "../../../backend/chatbot/models/IStep"
import type { PermissionsScriptState } from "./PermissionsScript"

type State = PermissionsScriptState
export type PermissionsLincolnshireScriptState = State

export class PermissionsLincolnshireScript extends PermissionsIAPTScript {
  readonly name: string = "PermissionsLincolnshireScript"

  /** General Handlers */

  async onHandleIUnderstandTerms(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayReady }
  }
}

/* istanbul ignore next */
export default class PermissionsLincolnshireDialogue extends Dialogue<State> {
  static id = DialogueIDs.PermissionsLincolnshire
  readonly name: string = "PermissionsLincolnshireDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PermissionsLincolnshireDialogue.id, new PermissionsLincolnshireScript(), state, snapshot)
  }
}
