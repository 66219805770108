import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { UnderAgeSignpostScript, UnderAgeSignpostScriptState } from "./UnderAgeSignpostDialogue"
import { DialogueIDs } from "../../DialogueIDs"

type State = UnderAgeSignpostScriptState
export type UnderAgeSignpostCWScriptState = State

export class UnderAgeSignpostCWScript extends UnderAgeSignpostScript {
  readonly name: string = "UnderAgeSignpostCWScript"

  /** Script Steps */
  @step.logState
  sayNotEligible(d: IStepData<State>): IStepResult {
    const threshold = this.getIAPTServiceAgeThreshold(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    const name = this.getName(d.state)
    return {
      body: this.t([
        `So ${name}, there's a special type of mental health support for people under the age of ${threshold}?`,
        `${iaptName} do not currently provide this so we are unable to refer you at this time`,
        "However, you can find loads of helpful resources from [My Mind](https://mymind.org.uk)",
        "Or you can talk to your doctor and they will help you"
      ]),
      prompt: {
        id: this.getPromptId("sayNotEligible"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Okay"), value: "okay" },
          { body: this.t("Thanks"), value: "thanks" }
        ]
      },
      nextStep: this.handleNotEligible
    }
  }
}

export default class UnderAgeSignpostDialogue extends Dialogue<State> {
  static id = DialogueIDs.UnderAgeSignpostCW
  readonly name: string = "UnderAgeSignpostCWDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(UnderAgeSignpostDialogue.id, new UnderAgeSignpostCWScript(), state, snapshot)
  }
}
