import {
  EligibilityCheckIAPTScript,
  EligibilityCheckIAPTState
} from "./EligibilityCheckIAPTDialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { TrackingEvents } from "../../../models/Constants"
import { DialogueIDs } from "../../DialogueIDs"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { checkEligibilityMind } from "../../../backend/api/limbic/mindEligibility"
import { MindEligibilityStatus } from "../../../models/IMindEligibility"
import { getPostCodeDetails } from "../../../backend/api/external/postcodes"
import { PostcodeStatus } from "../../../models/IPostcode"
import { IneligibilityReason, MindCurrentSupports } from "@limbic/types"

export interface EligibilityCheckMindScriptState extends EligibilityCheckIAPTState {
  currentSupport?: MindCurrentSupports
  currentSupportDetails?: string
  eligibilityRegion?: string
  requiresUrgentSupport?: boolean
  retryRequest?: number
}

type State = EligibilityCheckMindScriptState
export type EligibilityCheckMindState = State

export class EligibilityCheckMindScript extends EligibilityCheckIAPTScript<State> {
  readonly name = "EligibilityCheckMindScript"

  /** Script Steps */

  @step
  sayINeedToAskSomeDetails(_d: IStepData<State>): IStepResult {
    // this was extended to remove the message explaining how undo
    // works because they want it put somewhere earlier in the flow
    // ¯\_(ツ)_/¯
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: this.t(
        "In order to refer you to {organisationName}, I just need to confirm a few details with you",
        { organisationName }
      ),
      nextStep: this.askBirthday
    }
  }

  @step.logState
  checkAgeThresholds(d: IStepData<State>): IStepResult {
    const ageThreshold = this.rootStore.configStore.ageThreshold!
    const age = this.getUserAge(d.state)
    if (ageThreshold > age) {
      this.setUnderAged(d.state, true)
      return { nextStep: this.checkMindEligibility } // basically skipping checking for under aged again
    }
    d.state.isUnderAged = undefined
    d.state.isEligible = undefined
    d.state.signPostToManualReferral = undefined
    return { nextStep: this.startEligibilityCheck }
  }

  @step.logState
  startEligibilityCheck(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askPostCodeOfUser }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    getNextStep: (s: EligibilityCheckIAPTScript) => s.returnToAkPostCodeOfUser
  })
  async handlePostCodeOfUserWithCrisis(d: IStepData<State, string>): Promise<IStepResult> {
    d.state.postcodeEntered = d.response || d.state.retryPostcode
    d.state.retryPostcodeTimes ??= 0
    d.state.retryPostcode = d.state.postcodeEntered

    const [postcode, postcodeStatus] = await getPostCodeDetails(d.response || d.state.retryPostcode)

    if (postcodeStatus === PostcodeStatus.NoInternetConnection) {
      return { nextStep: this.askRetryInternetConnection }
    }
    if (postcodeStatus === PostcodeStatus.Success) {
      d.state.userPostcode = postcode
      return { nextStep: this.askCurrentSupport }
    }
    const isInvalidPostcode = postcodeStatus === PostcodeStatus.InvalidPostcode
    const isNotFoundPostcode = postcodeStatus === PostcodeStatus.PostcodeNotFound
    if (isInvalidPostcode || isNotFoundPostcode) {
      return {
        body: this.t(
          isInvalidPostcode
            ? "Hmmm, this doesn't seem to be a valid UK postcode"
            : "Hmmm, unfortunately I can't find this postcode"
        ),
        nextStep: this.askDidYouTypeThePostCodeCorrectly
      }
    }
    if (postcodeStatus === PostcodeStatus.RequestFailed && d.state.retryPostcodeTimes < 3) {
      d.state.retryPostcodeTimes++
      return { nextStep: this.askPostcodeRetry }
    }

    d.state.retryPostcodeTimes = 0
    return {
      body: this.t([
        "Oh dear, for some reason I can't find anything using your postcode. Sorry about that.",
        "It's important that you provide a valid postcode in order to find the right mental health service for you"
      ]),
      nextStep: this.sayWithoutPostcodeICannotReferYou
    }
  }

  @step.logState
  sayWithoutPostcodeICannotReferYou(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: this.t("Without it, I cannot refer you to {organisationName}", { organisationName }),
      nextStep: this.closeWithCallIntoService
    }
  }

  @step.logStateAndResponse
  async handleDidYouTypeThePostCodeCorrectly(d: IStepData<State, boolean>): Promise<IStepResult> {
    if (d.response) {
      this.track(TrackingEvents.INVALID_POSTCODE, { postcode: d.state.postcodeEntered })
      const result = await this.onInvalidUserPostcodeIsCorrectlyTyped?.(d.state)
      if (result) return result
      return {
        body: this.t([
          "Oh dear, for some reason I couldn't find anything using your postcode. Sorry about that",
          "It's important that you provide a valid postcode in order to find the right mental health service for you"
        ]),
        nextStep: this.sayWithoutPostcodeICannotReferYou
      }
    }
    d.state.retryPostcode = undefined
    const name = this.getName(d.state)
    return { body: this.t("No worries {name}", { name }), nextStep: this.askPostCodeOfUser }
  }

  @step.logState
  askPostcodeRetry(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Hmmm, it looks like something went wrong while looking up postcode"),
      prompt: {
        id: this.getPromptId("askPostcodeRetry"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Try again"), value: false },
          { body: this.t("Oops, let me re-type the postcode"), value: true }
        ],
        isUndoAble: false
      },
      nextStep: this.handleAskPostcodeRetry
    }
  }

  @step.logState
  askCurrentSupport(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "And are you currently receiving any kind of talking therapy or are you on the waiting list for another mental health service?"
      ),
      prompt: {
        id: this.getPromptId("askCurrentSupport"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Currently receiving a talking therapy"), value: "Yes" },
          {
            body: this.t("Currently on the waiting list for another mental health service"),
            value: "Waitlist"
          },
          { body: this.t("No"), value: "No" }
        ]
      },
      nextStep: this.handleCurrentSupport
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, "Yes" | "Waitlist" | "No">, s: EligibilityCheckMindScript) => {
      d.state.currentSupport = d.response
      d.state.hasCurrentSupport = d.response === "Yes"
      s.setPeople({
        currentSupport: d.state.currentSupport,
        hasCurrentSupport: d.state.hasCurrentSupport
      })
    }
  )
  handleCurrentSupport(d: IStepData<State, "Yes" | "Waitlist" | "No">): IStepResult {
    this.setEligibility(d.state, d.response !== "Yes")
    if (d.response === "Waitlist") {
      return {
        body: this.t([
          "Thanks for letting us know",
          "You can still access supported self-help while on a waiting list",
          "There are just a few more details I need from you to complete the referral"
        ]),
        nextStep: this.askCurrentSupportDetails
      }
    }
    if (d.response === "Yes") {
      this.track(TrackingEvents.INELIGIBLE_CURRENT_SUPPORT)
    }

    return { nextStep: this.checkMindEligibility }
  }

  @step.logState
  askCurrentSupportDetails(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Please give details about what service you're waiting for"),
      prompt: {
        id: this.getPromptId("askCurrentSupportDetails"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handleCurrentSupportDetailsWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.currentSupportDetails = d.response
  })
  @step.checkInputForCrisis({
    getNextStep: (s: EligibilityCheckMindScript) => s.checkMindEligibility
  })
  handleCurrentSupportDetailsWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.checkMindEligibility }
  }

  @step.logState
  async checkMindEligibility(d: IStepData<State>): Promise<IStepResult> {
    const isEligible = this.getIsEligible(d.state)

    if (!isEligible) {
      this.setEligibility(d.state, isEligible)
      const needsToSelfReferManually = this.getNeedsToSelfReferManually(d.state)
      this.setSignpostToManualSelfReferral(d.state, needsToSelfReferManually)
      return { nextStep: this.handleEligibilityCheck }
    }

    d.state.retryRequest ??= 0

    const [response, status] = await checkEligibilityMind(String(d.state.userPostcode?.postcode))

    if (status === MindEligibilityStatus.RequestFailed && d.state.retryRequest < 3) {
      this.track(TrackingEvents.ASK_RETRY_CONNECTION_VITALITY_ELIGIBILITY_CHECK)
      return { nextStep: this.saySomethingWentWrongWithEligibilityCheck }
    }

    if (status === MindEligibilityStatus.NoInternetConnection) {
      d.state.retryRequest = 0
      return { nextStep: this.sayNoInternetConnection }
    }

    if (status === MindEligibilityStatus.RequestFailed && d.state.retryRequest === 3) {
      this.track(TrackingEvents.ASK_RETRY_REQUEST_FAILED_VITALITY_ELIGIBILITY_CHECK)
      return { nextStep: this.sayThereIsSomethingWrong }
    }

    if (!response) {
      this.track(TrackingEvents.NO_AVAILABLE_AGENCY)
      this.setEligibility(d.state, false)
      return { nextStep: this.sayNoAvailableAgency }
    }

    d.state.eligibilityRegion = response
    this.setEligibility(d.state, isEligible && !!response)
    return { nextStep: this.handleEligibilityCheck }
  }

  @step.logState
  handleEligibilityCheck(d: IStepData<State>): IStepResult {
    switch (true) {
      case d.state.isUnderAged:
        this.trackUserAsIneligible(d.state, IneligibilityReason.UNDERAGE)
        return { nextStep: this.goToUnder18SignPost }
      case d.state.needsToCall:
        this.trackUserAsIneligible(d.state, IneligibilityReason.ISSUE_WITH_ELIGIBILITY_CHECK)
        return { nextStep: this.sayCallIntoService }
      case d.state.signPostToManualReferral:
        this.trackUserAsIneligible(d.state, IneligibilityReason.ELIGIBLE_FOR_MANUAL_REFERRAL)
        return { nextStep: this.sayEligibleForManualSelfReferral }
      case !d.state.isEligible:
        this.trackUserAsIneligible(d.state, IneligibilityReason.OUT_OF_AREA)
        return { nextStep: this.goToOtherServicesSignPost }
      default:
        return { nextStep: this.sayYoureEligible }
    }
  }

  @step.logState
  sayNoInternetConnection(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Hmmm... it seems that you are not connected to the internet",
        "Please try again"
      ]),
      prompt: {
        id: this.getPromptId("sayNoInternetConnection"),
        type: "inlinePicker",
        choices: [{ body: this.t("Try again") }]
      },
      nextStep: this.checkMindEligibility
    }
  }

  @step.logState
  saySomethingWentWrongWithEligibilityCheck(d: IStepData<State>): IStepResult {
    d.state.retryRequest = d.state.retryRequest! + 1
    return {
      body: this.t([
        "Hmmm, it looks like something went wrong with the eligibility check",
        "Please try again"
      ]),
      prompt: {
        id: this.getPromptId("saySomethingWentWrongWithEligibilityCheck"),
        type: "inlinePicker",
        choices: [{ body: this.t("Try again") }]
      },
      nextStep: this.checkMindEligibility
    }
  }

  @step.logState
  sayThereIsSomethingWrong(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    const organisationPhoneNumbers = this.rootStore.configStore.organisationPhoneNumbers?.length
      ? this.rootStore.configStore.organisationPhoneNumbers
      : this.rootStore.configStore.organisationGenericPhoneNumber
    return {
      body: this.t(
        [
          "Sorry {name}, it looks like there's something wrong",
          "You’ll need to speak to {organisationName} to discuss accessing mental health services",
          "Please contact their customer support team at supportedselfhelp@mind.org.uk"
        ],
        { name, organisationName, organisationPhoneNumbers }
      ),
      prompt: {
        id: this.getPromptId("sayThereIsSomethingWrong"),
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }]
      },
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  sayNoAvailableAgency(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t(
        [
          "Sorry {name}, this service is only available in England and the Channel Islands",
          "Head back to Mind's website for more information"
        ],
        { name }
      ),
      prompt: {
        id: this.getPromptId("sayNoAvailableAgency"),
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }]
      },
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  sayYoureEligible(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t("Thanks {name}", { name }),
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onPostcodeOfUserSuccessful(state: State): Promise<IStepResult> {
    return { nextStep: this.askCurrentSupport }
  }

  getNeedsToSelfReferManually(state: State): boolean {
    return false
  }
}

export default class EligibilityCheckMindDialogue extends Dialogue<State> {
  static id = DialogueIDs.EligibilityCheckMind
  readonly name = "EligibilityCheckMindDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(EligibilityCheckMindDialogue.id, new EligibilityCheckMindScript(), state, snapshot)
  }
}
