import { IAPTGPMap, IAPTIDs } from "../../models/IIAPTService"

export const iaptGPMapDorking: IAPTGPMap = {
  H81037: IAPTIDs.DORKING,
  Y02527: IAPTIDs.DORKING,
  H81045: IAPTIDs.DORKING,
  Y04620: IAPTIDs.DORKING,
  Y01085: IAPTIDs.DORKING,
  Y05210: IAPTIDs.DORKING,
  Y00068: IAPTIDs.DORKING,
  H81116: IAPTIDs.DORKING,
  Y01878: IAPTIDs.DORKING,
  Y04216: IAPTIDs.DORKING,
  Y05477: IAPTIDs.DORKING,
  Y06032: IAPTIDs.DORKING,
  Y05478: IAPTIDs.DORKING,
  H81030: IAPTIDs.DORKING,
  H81055: IAPTIDs.DORKING,
  H81048: IAPTIDs.DORKING,
  H81023: IAPTIDs.DORKING,
  H81083: IAPTIDs.DORKING,
  Y01879: IAPTIDs.DORKING,
  H81056: IAPTIDs.DORKING,
  H81005: IAPTIDs.DORKING,
  Y05093: IAPTIDs.DORKING,
  H81638: IAPTIDs.DORKING,
  H81667: IAPTIDs.DORKING,
  Y04666: IAPTIDs.DORKING,
  H81089: IAPTIDs.DORKING,
  H81060: IAPTIDs.DORKING,
  Y00882: IAPTIDs.DORKING,
  H81119: IAPTIDs.DORKING,
  H81046: IAPTIDs.DORKING,
  H81101: IAPTIDs.DORKING,
  H81058: IAPTIDs.DORKING,
  Y02194: IAPTIDs.DORKING,
  Y02715: IAPTIDs.DORKING,
  Y05680: IAPTIDs.DORKING,
  Y04317: IAPTIDs.DORKING,
  H81006: IAPTIDs.DORKING,
  H81026: IAPTIDs.DORKING,
  H81022: IAPTIDs.DORKING,
  Y04319: IAPTIDs.DORKING,
  Y02005: IAPTIDs.DORKING,
  H81052: IAPTIDs.DORKING,
  H81029: IAPTIDs.DORKING,
  Y01682: IAPTIDs.DORKING,
  Y03113: IAPTIDs.DORKING,
  H81064: IAPTIDs.DORKING,
  Y00951: IAPTIDs.DORKING,
  H81076: IAPTIDs.DORKING,
  Y00350: IAPTIDs.DORKING,
  H81132: IAPTIDs.DORKING,
  H81010: IAPTIDs.DORKING,
  H81062: IAPTIDs.DORKING,
  H81035: IAPTIDs.DORKING,
  H81647: IAPTIDs.DORKING,
  Y02008: IAPTIDs.DORKING,
  Y02006: IAPTIDs.DORKING,
  Y02007: IAPTIDs.DORKING,
  Y06129: IAPTIDs.DORKING,
  Y06066: IAPTIDs.DORKING,
  Y06069: IAPTIDs.DORKING,
  Y04500: IAPTIDs.DORKING,
  H81077: IAPTIDs.DORKING,
  Y04318: IAPTIDs.DORKING,
  H81044: IAPTIDs.DORKING,
  H81655: IAPTIDs.DORKING,
  H81085: IAPTIDs.DORKING,
  A99960: IAPTIDs.DORKING,
  Y05256: IAPTIDs.DORKING,
  Y02092: IAPTIDs.DORKING,
  H81084: IAPTIDs.DORKING,
  H81021: IAPTIDs.DORKING,
  H81053: IAPTIDs.DORKING,
  Y05504: IAPTIDs.DORKING,
  H81031: IAPTIDs.DORKING,
  H81043: IAPTIDs.DORKING,
  H81090: IAPTIDs.DORKING,
  Y02688: IAPTIDs.DORKING,
  Y02858: IAPTIDs.DORKING,
  Y03105: IAPTIDs.DORKING,
  H81663: IAPTIDs.DORKING,
  Y03275: IAPTIDs.DORKING,
  Y05271: IAPTIDs.DORKING,
  Y02895: IAPTIDs.DORKING,
  H81054: IAPTIDs.DORKING,
  Y01660: IAPTIDs.DORKING,
  H81033: IAPTIDs.DORKING,
  Y04322: IAPTIDs.DORKING,
  H81015: IAPTIDs.DORKING,
  H81073: IAPTIDs.DORKING,
  H81042: IAPTIDs.DORKING,
  Y04320: IAPTIDs.DORKING,
  Y04321: IAPTIDs.DORKING,
  Y03115: IAPTIDs.DORKING,
  Y01215: IAPTIDs.DORKING,
  H81095: IAPTIDs.DORKING,
  H81664: IAPTIDs.DORKING,
  H81131: IAPTIDs.DORKING,
  H81057: IAPTIDs.DORKING,
  H81020: IAPTIDs.DORKING,
  H81093: IAPTIDs.DORKING,
  H81024: IAPTIDs.DORKING,
  Y00641: IAPTIDs.DORKING,
  Y01705: IAPTIDs.DORKING,
  H81066: IAPTIDs.DORKING,
  Y02475: IAPTIDs.DORKING,
  H81032: IAPTIDs.DORKING,
  H81065: IAPTIDs.DORKING,
  H81061: IAPTIDs.DORKING,
  H81122: IAPTIDs.DORKING,
  H81002: IAPTIDs.DORKING,
  H81034: IAPTIDs.DORKING,
  H81643: IAPTIDs.DORKING,
  H81658: IAPTIDs.DORKING,
  Y06137: IAPTIDs.DORKING,
  H81036: IAPTIDs.DORKING,
  Y03276: IAPTIDs.DORKING,
  Y03277: IAPTIDs.DORKING,
  Y03278: IAPTIDs.DORKING,
  Y03279: IAPTIDs.DORKING,
  Y03280: IAPTIDs.DORKING,
  H81129: IAPTIDs.DORKING,
  H81007: IAPTIDs.DORKING,
  Y02112: IAPTIDs.DORKING,
  H81123: IAPTIDs.DORKING,
  H81004: IAPTIDs.DORKING,
  H81041: IAPTIDs.DORKING,
  Y03281: IAPTIDs.DORKING,
  H81087: IAPTIDs.DORKING,
  H81025: IAPTIDs.DORKING,
  H81134: IAPTIDs.DORKING,
  H81079: IAPTIDs.DORKING,
  H81104: IAPTIDs.DORKING,
  Y02848: IAPTIDs.DORKING,
  H81009: IAPTIDs.DORKING,
  H81003: IAPTIDs.DORKING,
  H81019: IAPTIDs.DORKING,
  Y03612: IAPTIDs.DORKING,
  Y02863: IAPTIDs.DORKING,
  H81632: IAPTIDs.DORKING,
  H81641: IAPTIDs.DORKING,
  H81094: IAPTIDs.DORKING,
  H81642: IAPTIDs.DORKING,
  H81111: IAPTIDs.DORKING,
  Y02859: IAPTIDs.DORKING,
  Y02860: IAPTIDs.DORKING,
  Y01689: IAPTIDs.DORKING,
  H81050: IAPTIDs.DORKING,
  H81669: IAPTIDs.DORKING,
  Y02861: IAPTIDs.DORKING,
  Y02862: IAPTIDs.DORKING,
  H81668: IAPTIDs.DORKING,
  Y00640: IAPTIDs.DORKING,
  Y02905: IAPTIDs.DORKING,
  Y02714: IAPTIDs.DORKING,
  H81082: IAPTIDs.DORKING,
  Y04316: IAPTIDs.DORKING,
  H81013: IAPTIDs.DORKING,
  H81620: IAPTIDs.DORKING,
  Y02965: IAPTIDs.DORKING,
  H81130: IAPTIDs.DORKING,
  H81622: IAPTIDs.DORKING,
  H81039: IAPTIDs.DORKING,
  H81069: IAPTIDs.DORKING,
  H81040: IAPTIDs.DORKING,
  H81075: IAPTIDs.DORKING,
  Y04314: IAPTIDs.DORKING,
  Y04315: IAPTIDs.DORKING,
  H81017: IAPTIDs.DORKING,
  H81071: IAPTIDs.DORKING,
  H81118: IAPTIDs.DORKING,
  Y00338: IAPTIDs.DORKING,
  H81068: IAPTIDs.DORKING,
  Y05414: IAPTIDs.DORKING,
  H81109: IAPTIDs.DORKING,
  Y04364: IAPTIDs.DORKING,
  Y02364: IAPTIDs.DORKING,
  Y00339: IAPTIDs.DORKING,
  H81067: IAPTIDs.DORKING,
  Y05090: IAPTIDs.DORKING,
  Y05089: IAPTIDs.DORKING,
  Y00341: IAPTIDs.DORKING,
  H81051: IAPTIDs.DORKING,
  Y05880: IAPTIDs.DORKING,
  Y01075: IAPTIDs.DORKING,
  H81028: IAPTIDs.DORKING,
  H81103: IAPTIDs.DORKING,
  Y01918: IAPTIDs.DORKING,
  Y01072: IAPTIDs.DORKING,
  Y04847: IAPTIDs.DORKING,
  Y01298: IAPTIDs.DORKING,
  Y00340: IAPTIDs.DORKING,
  H81099: IAPTIDs.DORKING,
  H81016: IAPTIDs.DORKING,
  H81644: IAPTIDs.DORKING,
  H81125: IAPTIDs.DORKING,
  H81078: IAPTIDs.DORKING,
  Y05273: IAPTIDs.DORKING,
  Y03515: IAPTIDs.DORKING,
  Y05550: IAPTIDs.DORKING,
  H81070: IAPTIDs.DORKING,
  H81672: IAPTIDs.DORKING,
  Y01250: IAPTIDs.DORKING,
  Y00342: IAPTIDs.DORKING,
  H81113: IAPTIDs.DORKING,
  H81038: IAPTIDs.DORKING,
  H81080: IAPTIDs.DORKING,
  Y01916: IAPTIDs.DORKING,
  H81072: IAPTIDs.DORKING,
  Y01260: IAPTIDs.DORKING,
  H81618: IAPTIDs.DORKING,
  Y05883: IAPTIDs.DORKING,
  Y00343: IAPTIDs.DORKING,
  Y05882: IAPTIDs.DORKING,
  H81011: IAPTIDs.DORKING,
  H81107: IAPTIDs.DORKING,
  Y02206: IAPTIDs.DORKING,
  Y05884: IAPTIDs.DORKING,
  Y00287: IAPTIDs.DORKING,
  H81611: IAPTIDs.DORKING,
  H81656: IAPTIDs.DORKING,
  Y05485: IAPTIDs.DORKING,
  H81091: IAPTIDs.DORKING,
  H81074: IAPTIDs.DORKING,
  H81613: IAPTIDs.DORKING,
  H81081: IAPTIDs.DORKING,
  H81126: IAPTIDs.DORKING,
  Y04507: IAPTIDs.DORKING,
  H81133: IAPTIDs.DORKING,
  Y02840: IAPTIDs.DORKING,
  H81086: IAPTIDs.DORKING,
  H81128: IAPTIDs.DORKING,
  Y00336: IAPTIDs.DORKING,
  Y02665: IAPTIDs.DORKING
}
