import {
  CHATFLOW_CUSTOM_KEYS,
  ChatFlowsEnum,
  IBaseDefaultChatFlowSettings,
  IChatFlowKeyMetaData,
  IDefaultChatFlowSettings,
  ReferralPayload
} from "@limbic/types"
import { PayloadBuilder } from "../PayloadBuilder"

type ChatFlowPayloadBuilderConstructor<ChatFlow extends ChatFlowsEnum> = new (
  settings: IDefaultChatFlowSettings
) => ChatFlowPayloadBuilder<ChatFlow>

export default abstract class ChatFlowPayloadBuilder<
  ChatFlow extends ChatFlowsEnum
> extends PayloadBuilder<Partial<ReferralPayload>> {
  static builders: Partial<
    Record<ChatFlowsEnum, ChatFlowPayloadBuilderConstructor<ChatFlowsEnum>>
  > = {}

  abstract chatFlow: keyof IBaseDefaultChatFlowSettings
  private readonly _settings: IDefaultChatFlowSettings

  /**
   * The constructor needs to be public to allow external use of
   * the exposed static builders property as it maps chat flows
   * with ChatFlowPayloadBuilder constructors (so their constructors
   * must be public)
   * @param settings {IDefaultChatFlowSettings} the chat flow's settings
   */
  public constructor(settings: IDefaultChatFlowSettings = {}) {
    super()
    this._settings = settings
  }

  /**
   * Gets the customisable keys for the current chat flow
   * and sees if there's any transformation descriptor in
   * the transformMap for each key that can be used to create
   * a customised payload
   */
  getTransformedPayload(): Partial<ReferralPayload> {
    return Object.entries(this.customisableKeys ?? {}).reduce((payload, [key, metaData]) => {
      const sourceKey = this.settings?.[key] ?? metaData.defaultKey
      const sourceValue = this.ctx.state?.[sourceKey]
      const keyValue = this.getTransformedKeyValue(sourceKey, sourceValue) ?? {}
      return { ...payload, ...keyValue }
    }, {})
  }

  /**
   * Gets the customisable keys for the current chat flow
   * and sees if the corresponding transformation (if any)
   * has been applied to the transformedPayload and returns
   * the sourceKeys of the transformed keys so that you can
   * remove them from the final payload
   * @param transformedPayload {Record<string, any>?} the transformed payload
   */
  getTransformedKeys(transformedPayload?: Record<string, any>): string[] {
    return Object.entries(this.customisableKeys ?? {})
      .map(([key, metaData]) => {
        const sourceKey = this.settings?.[key] ?? metaData?.defaultKey
        const targetKey = this.transformMap[sourceKey]?.targetKey
        if (targetKey && targetKey in (transformedPayload ?? {})) return sourceKey
        return null
      })
      .filter(Boolean)
  }

  /**
   * Gets the source key for a given chatFlow's customisable
   * key, or gets the corresponding default one
   * @param key {string} the customisable key
   */
  getSourceKey(key: string): string | undefined {
    return this.settings?.[key] ?? this.customisableKeys?.[key as string]?.defaultKey
  }

  /** Getters / Setters */

  get settings(): IDefaultChatFlowSettings[ChatFlow] {
    return this._settings[this.chatFlow] ?? {}
  }

  get customisableKeys(): Record<string, IChatFlowKeyMetaData> | undefined {
    return CHATFLOW_CUSTOM_KEYS[this.chatFlow]
  }
}
