import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { UnderAgeSignpostScript, UnderAgeSignpostScriptState } from "./UnderAgeSignpostDialogue"
import { DialogueIDs } from "../../DialogueIDs"

type State = UnderAgeSignpostScriptState
export type UnderAgeSignpostBradfordScriptState = State

export class UnderAgeSignpostBradfordScript extends UnderAgeSignpostScript {
  readonly name: string = "UnderAgeSignpostBradfordScript"

  /** Script Steps */
  @step.logState
  sayNotEligible(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const ageThreshold = this.rootStore.configStore.ageThreshold
    const organisationName = this.rootStore.configStore.organisationName

    return {
      body: [
        `So ${name}, there's a special type of mental health support for people under the age of ${ageThreshold}`,
        `${organisationName} do not currently provide this so we are unable to refer you at this time`,
        "However, you can find loads of helpful resources from the Healthy Minds website [https://www.healthyminds.services/](https://www.healthyminds.services/)",
        "Or you can talk to your doctor and they will help you"
      ],
      prompt: {
        id: this.getPromptId("sayNotEligible"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Okay", value: "okay" },
          { body: "Thanks", value: "thanks" }
        ]
      },
      nextStep: this.handleNotEligible
    }
  }
}

export default class UnderAgeSignpostDialogue extends Dialogue<State> {
  static id = DialogueIDs.UnderageSignpostBradford
  readonly name: string = "UnderAgeSignpostBradfordDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(UnderAgeSignpostDialogue.id, new UnderAgeSignpostBradfordScript(), state, snapshot)
  }
}
