import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import { RiskLevelReason } from "../../../models/Constants"

type State = RiskPathwayScriptState

export type RiskPathwayLincolnshireScriptState = State

export class RiskPathwayLincolnshireScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayLincolnshireScript"

  /** Script Steps */
  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askCanYouKeepYourselfSafe }
  }

  @step.logState
  askCanYouKeepYourselfSafe(_d: IStepData<State>): IStepResult {
    const serviceName = this.rootStore.configStore.serviceName
    return {
      body: this.t(
        "Are you able to keep yourself, and any dependants in your care, safe until {serviceName} contacts you (within one working day)?",
        { serviceName }
      ),
      prompt: {
        id: this.getPromptId("askCanYouKeepYourselfSafe"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askCanYouKeepYourselfSafe"
      },
      nextStep: this.handleCanYouKeepYourselfSafe
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.saveResponse<State>(
    "10",
    "Are you able to keep yourself, and any dependants in your care, safe until your appointment (within 1 working day)?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.canKeepSelfSafe = d.response
  })
  handleCanYouKeepYourselfSafe(d: IStepData<State>): IStepResult {
    if (!d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.CANNOT_KEEP_SELF_SAFE)
      // It seems that Lincs asked not to send a risk email - only Tags
      return { nextStep: this.askHaveYouMadePlans }
    }
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step
  askHaveYouMadePlans(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Have you made any specific plans to harm yourself?"),
      prompt: {
        id: this.getPromptId("Q9 - madePlansToEndLife"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "Q9 - madePlansToEndLife"
      },
      nextStep: this.handleHaveYouMadePlans
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "11",
    "Have you made any specific plans to harm yourself?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  handleHaveYouMadePlans(d: IStepData<State, boolean>): IStepResult {
    const isRisk = this.clinicalStore.isRisk
    if (!isRisk && d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelModerate(d.state, RiskLevelReason.PHQ9_Q9_PLANS)
    }
    this.rootStore.applicationStore.setCurrentProgress(0.88)

    const name = this.getName(d.state)

    const body = [
      "Sorry to hear that {name}",
      "It is normal for people to have thoughts of this nature at times",
      "However, this is not an emergency response service"
    ]
    if (d.response) {
      body.push("Please call the NHS Emergency number 111 or 999 if you need urgent help")
      return {
        body: this.t(body, { name }),
        nextStep: this.saveRiskLevelAndReferralType
      }
    }
    body.push(
      "Please contact the Mental Health Matters Helpline on 0800 001 4331 or SPA on 0303 123 4000 or your GP if you feel you are at risk of harming yourself"
    )
    return {
      body: this.t(body, { name }),
      nextStep: this.saveRiskLevelAndReferralType
    }
  }

  @step
  async theyllCallYou(d: IStepData<State>): Promise<IStepResult> {
    if (!d.state.phoneNumber) {
      return { nextStep: this.askPhoneNumber }
    }

    const serviceName = this.rootStore.configStore.serviceName
    return {
      body: this.t(
        "One of {serviceName}' clinical team will contact you within 1 to 2 working days",
        { serviceName }
      ),
      prompt: {
        id: this.getPromptId("theyllCallYou"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Okay"), value: true },
          { body: this.t("I understand"), value: true },
          { body: this.t("Change number"), value: false }
        ]
      },
      nextStep: this.handleTheyllCallYou
    }
  }

  @step.logStateAndResponse
  handleTheyllCallYou(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.end }
    }
    return { nextStep: this.askPhoneNumber }
  }

  /** Generic Handlers */

  async onHandlePhoneNumber(_state: State): Promise<IStepResult> {
    return { nextStep: this.end }
  }

  async onSaveRiskLevelAndReferralType(state: State): Promise<IStepResult> {
    return { nextStep: state.canKeepSelfSafe ? this.end : this.theyllCallYou }
  }

  getKeepingSafeAttachmentUrls(_state: State): string[] {
    return [
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/lincolnshire/Keeping-Yourself-Safe-Leaflet.pdf"
    ]
  }

  getKeepingSafeEmailSubject(_state: State): string {
    return this.t("Keeping Yourself Safe: Lincolnshire Talking Therapies")
  }

  createKeepingSafeLeafletEmail(title: string, logo: string): string {
    return `
      <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
      <html xmlns='http://www.w3.org/1999/xhtml' lang=''>
        <head>
          <meta http-equiv='Content-Type' content='text/html; charset=utf-8' />
          <meta name='viewport' content='width=device-width, initial-scale=1.0' />
          <title>Limbic Therapy Assistant | Keeping Safe</title>
          ${this.getEmailHTMLStyle()}
          <!--[if IEMobile 7]>
            <style type="text/css">
              /* Targeting Windows Mobile */
            </style>
          <![endif]-->
          <!--[if gte mso 9]>
            <style>
              /* Target Outlook 2007 and 2010 */
            </style>
          <![endif]-->
        </head>
        <body>
          <table cellpadding='0' cellspacing='0' border='0' id='backgroundTable'>
            <tr>
              <td>
                <table cellpadding='0' cellspacing='0' border='0' id='contentTable'>
                  <tr>
                    <td>
                      <img
                        class='image_fix'
                        src='${logo}'
                        alt='${title}'
                        title='${title}'
                        width='x'
                        height='x'
                      /><br /><br />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      You have made a self-referral today to Lincolnshire Talking Therapies. In one of your answers you said you were experiencing some thoughts of self-harm or taking your own life.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <br />
                      As you are waiting to access our service, if you feel you may act on suicidal thoughts, cannot keep yourself or are a risk to others around you, please call 999 or the Lincolnshire Mental Health Helpline on 0800 001 4331.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <br />
                      For more info on keeping safe please have a look at the file attached in this email.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <br />
                      <h2 style='text-align: left;'><i>The Lincolnshire Talking Therapies Team</i></h2>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </body>
      </html>
    `
  }
}

/* istanbul ignore next */
export default class RiskPathwayLincolnshireDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayLincolnshire
  readonly name: string = "RiskPathwayLincolnshireDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(RiskPathwayLincolnshireDialogue.id, new RiskPathwayLincolnshireScript(), state, snapshot)
  }
}
