import { ClinicalGroups } from "@limbic/types"
import { IUserClinicalPathDescriptor } from "../models/IClinicalPath"
import { joinWithAnd } from "./array"

export default function generateClinicalGroupName(input: IUserClinicalPathDescriptor): string {
  if (input.primaryProblems.length > 1) return ClinicalGroups.ComplexComorbid
  const flagsString = joinWithAnd(input.flags)

  let string = joinWithAnd(input.primaryProblems) || ClinicalGroups.BelowCaseness
  if (flagsString) string += ` w/ ${flagsString}`
  return string
}
