import { IntroductionScript, IntroductionScriptState } from "./IntroductionScript"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

export interface IntroductionMindScriptState extends IntroductionScriptState {
  primaryLanguage?: string
}
interface State extends IntroductionMindScriptState {
  infoline?: boolean
  sideBySide?: boolean
  everydayTips?: boolean
  localMind?: boolean
  selfReferral?: boolean
}

export class IntroductionMindScript extends IntroductionScript {
  readonly name = "IntroductionMindScript"

  /** Script Steps */

  @step.logState
  greet(_d: IStepData<State>): IStepResult {
    const { directReferral } = this.rootStore.configStore
    return {
      body: this.t([
        "Hi there!",
        "I’m Limbic",
        "I'm here to help you access Mind's free [supported self-help](https://www.mind.org.uk/supported-self-help/) programme",
        "Over six weeks of guided support, we'll give you materials and regular phone calls with a trained practitioner to help improve your wellbeing",
        "We can help with common mental health problems like anxiety or low mood",
        "But supported self-help is not an emergency service. If you are in crisis and need help now, please visit [this page to get help now](https://www.mind.org.uk/need-urgent-help/using-this-tool/)"
      ]),
      nextStep: directReferral ? this.sayWhatIsOffered : this.askCanIHelp
    }
  }

  @step.logState
  askCanIHelp(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: this.t([
        "Please note this service is only available in England and the Channel Islands",
        "Can I help?"
      ]),
      prompt: {
        id: this.getPromptId("askCanIHelp"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          {
            body: this.t("Yes, I'd like to get support", {
              organisationName
            }),
            value: true
          },
          { body: this.t("No, I'm just browsing"), value: false }
        ],
        isUndoAble: false
      },
      nextStep: this.handleCanIHelp
    }
  }

  @step.logState
  handleCanIHelp(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.sayWhatIsOffered }
    }
    return { nextStep: this.sayIfYouChangeYourMindWakeMeUp }
  }

  @step.logState
  sayWhatIsOffered(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Just to make you aware, there are different ways of accessing support aside from Mind's supported self-help programme:",
        "1. <b>Infoline</b> is a mental health information and signposting service. You can call us on 0300 123 3393",
        "2. <b>Side by Side</b> is an online community where you can listen, share and be heard",
        "3. <b>Tips for everyday living</b>: We’ve put together tips and guides to help you cope with everyday things like money, work, university and more",
        "4. <b>Find Mind where you live</b>: Local Minds run mental health services in local communities. Find your nearest local Mind using our online tool",
        "5. <b>Continue with supported self help</b>"
      ]),
      prompt: {
        id: this.getPromptId("sayWhatIsOffered"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Infoline"), value: "infoline" },
          { body: this.t("Side by Side"), value: "sideBySide" },
          { body: this.t("Tips for everyday living"), value: "everydayTips" },
          { body: this.t("Local Mind"), value: "localMind" },
          { body: this.t("Self-referral"), value: "selfReferral" }
        ],
        isUndoAble: true
      },
      nextStep: this.handleWhatIsOffered
    }
  }

  @step.logState
  sayWhatIsOfferedShortVersion(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Of course!",
        "You have the following options:\n" +
          "\n" +
          "1. <b>Infoline</b> is a mental health information and signposting service. You can call us on 0300 123 3393\n" +
          "2. <b>Side by Side</b> is an online community where you can listen, share and be heard\n" +
          "3. <b>Tips for everyday living</b>: We’ve put together tips and guides to help you cope with everyday things like money, work, university and more\n" +
          "4. <b>Find Mind where you live</b>: Local Minds run mental health services in local communities. Find your nearest local Mind using our online tool\n" +
          "5. <b>Continue with supported self help</b>"
      ]),
      prompt: {
        id: this.getPromptId("sayWhatIsOffered"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Infoline"), value: "infoline" },
          { body: this.t("Side by Side"), value: "sideBySide" },
          { body: this.t("Tips for everyday living"), value: "everydayTips" },
          { body: this.t("Local Mind"), value: "localMind" },
          { body: this.t("Self-referral"), value: "selfReferral" }
        ],
        isUndoAble: true
      },
      nextStep: this.handleWhatIsOffered
    }
  }

  @step.logStateAndResponse
  handleWhatIsOffered(
    d: IStepData<State, "infoline" | "sideBySide" | "everydayTips" | "localMind" | "selfReferral">
  ): IStepResult {
    switch (d.response) {
      case "infoline":
        return { nextStep: this.goToInfoline }
      case "sideBySide":
        return { nextStep: this.goToSideBySide }
      case "everydayTips":
        return { nextStep: this.askEverydayTips }
      case "localMind":
        return { nextStep: this.askLocalMind }
      case "selfReferral":
        return { nextStep: this.end }
      default:
        return { nextStep: this.end }
    }
  }

  @step.logState
  goToInfoline(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Infoline is a mental health information and signposting service",
        "Ask us about:\n\n" +
          "\u00A0- Mental health problems\n" +
          "\u00A0- Where to get help near you\n" +
          "\u00A0- Treatment options\n" +
          "\u00A0- Advocacy services\n" +
          "\u00A0- Welfare benefits (ask to speak to a welfare adviser)\n",
        "We're open 9am to 6pm, Monday to Friday (except for bank holidays)",
        "It's been great chatting to you",
        "You can write 'hi' or 'hello' at any time if you want to carry on with self-referral instead",
        "Or press 'Review options' if you want to see your options again"
      ]),
      prompt: {
        id: this.getPromptId("goToInfoline"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          {
            body: this.t("Call us now"),
            onPress: () => {
              const a = document.createElement("a")
              a.setAttribute("href", "tel:+44-300-123-3393")
              a.setAttribute("target", "_blank")
              a.click()
            }
          },
          {
            body: this.t("Review options"),
            value: undefined
          }
        ],
        textPrompt: {
          placeholder: this.t('Type "Hello Limbic" to continue here'),
          forceValue: true
        }
      },
      nextStep: this.handleSayHelloToContinue
    }
  }

  @step.logState
  goToSideBySide(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "It’s a powerful thing to connect with someone else over shared experiences. Side by Side is an online community where you can listen, share and be heard",
        "We welcome everyone over the age of 18. We know what it’s like to struggle sometimes and that a little extra support can make a big difference",
        "Our community is open 24/7. You’ll always have someone to connect with day or night",
        "Our community is moderated by the team at Mind and protected by our community guidelines",
        "It's been great chatting to you",
        "You can write 'hi' or 'hello' at any time if you want to carry on with self-referral instead",
        "Or press 'Review options' if you want to see your options again"
      ]),
      prompt: {
        id: this.getPromptId("goToSideBySide"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          {
            body: this.t("Find out more"),
            onPress: () => {
              const a = document.createElement("a")
              a.setAttribute("href", "https://sidebyside.mind.org.uk/")
              a.setAttribute("target", "_blank")
              a.click()
            }
          },
          {
            body: this.t("Review options"),
            value: undefined
          }
        ],
        textPrompt: {
          placeholder: this.t('Type "Hello Limbic" to continue here'),
          forceValue: true
        }
      },
      nextStep: this.handleSayHelloToContinue
    }
  }

  @step.logState
  askEverydayTips(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Living with a mental health problem can often have an impact on day to day life, making things that others might not think about a bit more difficult",
        "We've put together these tips and guides to help you cope with everyday things like money, work, university and more",
        "It's been great chatting to you",
        "You can write 'hi' or 'hello' at any time if you want to carry on with self-referral instead",
        "Or press 'Review options' if you want to see your options again"
      ]),
      prompt: {
        id: this.getPromptId("askEverydayTips"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          {
            body: this.t("Find out more"),
            onPress: () => {
              const a = document.createElement("a")
              a.setAttribute(
                "href",
                "https://www.mind.org.uk/information-support/tips-for-everyday-living/"
              )
              a.setAttribute("target", "_blank")
              a.click()
            }
          },
          {
            body: this.t("Review options"),
            value: undefined
          }
        ],
        textPrompt: {
          placeholder: this.t('Type "Hello Limbic" to continue here'),
          forceValue: true
        }
      },
      nextStep: this.handleSayHelloToContinue
    }
  }

  @step.logState
  askLocalMind(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "We have more than 100 local Minds across England, Wales and the Channel Islands. Use our tool to find your nearest local Mind. Each local Mind has their own website with information on what they offer in your area",
        "Each local Mind is different, but the services they offer might include:\n\n" +
          "\u00A0- Talking therapy\n" +
          "\u00A0- Crisis helplines\n" +
          "\u00A0- Advocacy\n" +
          "\u00A0- Employment and training schemes\n" +
          "\u00A0- Counselling\n" +
          "\u00A0- Befriending services\n",
        "It's been great chatting to you",
        "You can write 'hi' or 'hello' at any time if you want to carry on with self-referral instead",
        "Or press 'Review options' if you want to see your options again"
      ]),
      prompt: {
        id: this.getPromptId("askLocalMind"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          {
            body: this.t("Find out more"),
            onPress: () => {
              const a = document.createElement("a")
              a.setAttribute("href", "https://www.mind.org.uk/information-support/local-minds/")
              a.setAttribute("target", "_blank")
              a.click()
            }
          },
          {
            body: this.t("Review options"),
            value: undefined
          }
        ],
        textPrompt: {
          placeholder: this.t('Type "Hello Limbic" to continue here'),
          forceValue: true
        }
      },
      nextStep: this.handleSayHelloToContinue
    }
  }

  @step.logState
  handleSayHelloToContinue(d: IStepData<State, string>): IStepResult {
    if (d.response === "Review options") {
      return { nextStep: this.sayWhatIsOfferedShortVersion }
    }
    return { body: this.t("Welcome back!"), nextStep: this.end }
  }
}

/* istanbul ignore next */
export default class IntroductionMindDialogue extends Dialogue<State> {
  static id = DialogueIDs.IntroductionMind
  readonly name: string = "IntroductionMindDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(IntroductionMindDialogue.id, new IntroductionMindScript(), state, snapshot)
  }
}
