import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { AssessmentScriptState } from "./AssessmentScript"
import type { IStepResult } from "../../../backend/chatbot/models/IStep"
import { AssessmentWithADSMScript } from "./AssessmentWithADSMScript"

type State = AssessmentScriptState

export class AssessmentMHMScript extends AssessmentWithADSMScript {
  readonly name: string = "AssessmentMHMScript"

  /** Generic Handlers */

  async onHandleIAPTPhobiaScale(state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayReadyForWASAS }
  }

  async onHandleIAPTWorkAndSocialAdjustment(state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayThatsEverything }
  }
}

export default class AssessmentMHMDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentMHM
  readonly name: string = "AssessmentMHMDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentMHMDialogue.id, new AssessmentMHMScript(), state, snapshot)
  }
}
