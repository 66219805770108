import React from "react"
import { observer } from "mobx-react"
import "./Sidebar.scss"
import "./SidebarEssex.scss"
import { useConfigStore } from "../../contexts/RootStoreContext"

export interface Props {
  visible?: boolean
}

function Sidebar(props: Props): JSX.Element | null {
  const config = useConfigStore()
  const { visible } = props
  if (!visible) {
    return null
  }

  return (
    <div className="lb-sidebar-container">
      <article className="lb-sidebar-card">
        <div className="lb-header">
          <img src={config.logo} className="lb-header-img" alt="" />
          <h3 className="subtitle">Welcome to {config.organisationName}</h3>
        </div>
        <p>
          We are your local NHS Talking Therapies service. When life gets too tough, we can help
          you. Our NHS service is free, confidential and available for people 17+ who are registered
          with a Surrey GP.
        </p>
        <p>
          Please note, we are not a crisis service. For crisis support, please contact the Mental
          Health Crisis Line on 0800 915 4644 (SMS 07717 989024), Samaritans on 116 123 or your GP.
          You could also visit a local Safe Haven for support. You can find your local Safe Haven{" "}
          <a
            href="https://www.sabp.nhs.uk/our-services/mental-health/safe-havens"
            target="_blank noreferrer">
            here
          </a>
          . If you cannot keep yourself safe and need immediate support, call or text 999 or go to
          A&E.
        </p>
        <p>
          We offer a range of proven psychological therapies designed to help with common mental
          health problems and to support the management of long-term health conditions. These
          include specialised group courses/workshops and 1-2-1 therapy delivered virtually or at
          one of our locations across Surrey. You can find out about group courses/workshops or
          1-2-1 therapies on{" "}
          <a href="https://dhctalkingtherapies.co.uk/" target="_blank noreferrer">
            our website
          </a>
          .
        </p>
        <p>
          To refer yourself to our service, please use the Limbic Self-Referral Assistant on the
          right. This is the easiest way to ensure our team receive your referral quickly.
        </p>
        <p>
          Alternatively, if you would like to speak to a member of our admin team, please call us on
          01483 906392.
        </p>
        {config.iaptIcons?.map?.(link => (
          <img key={link} className="nhs-logo" src={link} alt={link} />
        ))}
      </article>
      <div className="lb-sidebar-spacer" />
      <article className="lb-sidebar-card">
        <p>
          Limbic helps people access psychological support quicker and easier. Your data is secured
          according to the highest NHS standards.
        </p>
        <img
          className="badges"
          src="https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/data-pics.png"
          alt={"Badges"}
        />
      </article>
    </div>
  )
}

export default observer(Sidebar)
