import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../DialogueIDs"
import { SuitableServicesSignpostIAPTScript } from "./SuitableServicesSignpostIAPTDialogue"
import type { SuitableServicesSignpostIAPTScriptState } from "./SuitableServicesSignpostIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = SuitableServicesSignpostIAPTScriptState
export type SuitableServicesSignpostLWCScriptState = State

export class SuitableServicesSignpostLWCScript extends SuitableServicesSignpostIAPTScript {
  readonly name: string = "SuitableServicesSignpostLWCScript"

  /** Script Steps */

  @step.logState
  sayWeDontSupportYourArea(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        `So ${name}, with the NHS, you can get mental health support from services in the same area as your registered GP`,
        `I've just done a search, and it doesn't look like ${organisationName} have any services in the same area as your GP`,
        "If you feel this is an error and you should be eligible for the service, please call us on 0121 663 1217.\nOur phone lines are open 9 – 5pm Monday to Thursday, and 9 – 4pm on Friday"
      ],
      prompt: {
        id: this.getPromptId("sayWeDontSupportYourArea"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Okay" }, { body: "I understand" }, { body: "Fair enough" }]
      },
      nextStep: this.handleWeDontSupportYourArea
    }
  }
}

/* istanbul ignore next */
export default class SuitableServicesSignpostLWCDialogue extends Dialogue<State> {
  static id = DialogueIDs.SuitableServicesSignpostLWC
  readonly name: string = "SuitableServicesSignpostLWCDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      SuitableServicesSignpostLWCDialogue.id,
      new SuitableServicesSignpostLWCScript(),
      state,
      snapshot
    )
  }
}
