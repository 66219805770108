import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import { RiskLevelReason } from "../../../models/Constants"
import sendRiskEmail from "../../../backend/api/limbic/sendRiskEmail"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = RiskPathwayScriptState

export type RiskPathwayDemoPrivateProvidersState = State
export class RiskPathwayDemoPrivateProvidersScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayDemoPrivateProvidersScript"

  /** Script Steps */

  @step.logState
  step1(d: IStepData<State>): IStepResult {
    return { nextStep: this.askCanYouKeepYourselfSafe }
  }

  @step.logState
  askCanYouKeepYourselfSafe(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Are you able to keep yourself, and any dependants in your care, safe until your appointment (within 5 days time)?"
      ),
      prompt: {
        id: this.getPromptId("askCanYouKeepYourselfSafe"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askCanYouKeepYourselfSafe"
      },
      nextStep: this.handleCanYouKeepYourselfSafe
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.saveResponse<State>(
    "10",
    "Are you able to keep yourself, and any dependants in your care, safe until your appointment (within 5 working days)?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.canKeepSelfSafe = d.response
  })
  handleCanYouKeepYourselfSafe(d: IStepData<State>): IStepResult {
    if (!d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.CANNOT_KEEP_SELF_SAFE)
      return { nextStep: this.informRiskToService }
    }
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  async informRiskToService(d: IStepData<State>): Promise<IStepResult> {
    await this.sendRiskEmailToService(d.state)
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  sayCrisis(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const serviceName = this.rootStore.configStore.serviceName

    return {
      body: this.t(
        [
          "Sorry to hear that {name}",
          "It is normal for people to have thoughts of this nature at times",
          "However, this is not an emergency response service",
          "There are alternative services that are better placed to support you",
          "If you need urgent, life threatening medical help please call [999](tel:999)",
          "One of {serviceName}'s clinical team will contact you within 5 working days to check that you have referred yourself in to one of the crisis support services"
        ],
        { name, serviceName }
      ),
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("I understand") }, { body: this.t("Okay") }],
        dataPointsName: "sayCrisis"
      },
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onSaveRiskLevelAndReferralType(state: State): Promise<IStepResult> {
    return { nextStep: !state.canKeepSelfSafe ? this.sayCrisis : this.end }
  }

  async sendRiskEmailToService(state: State): Promise<void> {
    try {
      if (!this.clinicalStore.isRisk) return
      const to =
        new URLSearchParams().get("submitRiskTo") ??
        "john+risk@limbic.ai, ross+risk@limbic.ai, syed+risk@limbic.ai"
      const cc = new URLSearchParams().get("submitRiskToCC")
      const bcc = new URLSearchParams().get("submitRiskToBCC")
      const text = this.createReferralEmail(state, true)
      await sendRiskEmail({
        to: [to],
        cc: cc?.length ? [cc] : undefined, // This needs to be undefined in case of empty array because mailgun is stupid
        bcc: bcc?.length ? [bcc] : undefined, // This needs to be undefined in case of empty array because mailgun is stupid
        text
      })
    } catch (e) {
      this.logException(e, "onReferralFinished -> sendEmail")
    }
  }
}

/* istanbul ignore next */
export default class RiskPathwayDemoPrivateProvidersDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayDemoPrivateProviders
  readonly name: string = "RiskPathwayDemoPrivateProvidersDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      RiskPathwayDemoPrivateProvidersDialogue.id,
      new RiskPathwayDemoPrivateProvidersScript(),
      state,
      snapshot
    )
  }
}
