import { action } from "mobx"
import Persistable from "../../models/Persistable"
import { WellbeingHubUserTypes } from "../../models/Constants"
import Syncable from "../../models/Syncable"

export default class WellbeingHubStore extends Persistable implements Syncable {
  readonly name: string = "WellbeingHubStore"
  userType: WellbeingHubUserTypes

  restart?(): void

  constructor() {
    super()
    this.userType = this.hydrate("userType") ?? "individual"
  }

  rehydrate(): void {
    this.userType = this.hydrate("userType") ?? "individual"
  }

  @action
  setWellbeingHubUserType(type: WellbeingHubUserTypes): void {
    this.userType = type
    this.persist("userType", type)
  }
}

if (process.env.REACT_APP__DEV_TOOLS__ === "enabled") {
  WellbeingHubStore.prototype.restart = function restart(this: WellbeingHubStore) {
    this.setWellbeingHubUserType("individual")
  }
}
