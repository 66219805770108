/* eslint-disable @typescript-eslint/no-empty-function */
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../../DialogueIDs"
import { step } from "../../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import BaseScript, { BaseScriptState } from "../../../BaseScript"
import {
  ALCOHOL_FREQUENCIES,
  ALCOHOL_QUANTITIES,
  IDefaultChatFlowMessagesCollectAlcoholConsumption,
  IDefaultChatFlowSettingsCollectAlcoholConsumption
} from "@limbic/types"
import { ValueOf } from "../../../../models/ValueOf"

export interface ICollectAlcoholConsumptionSettings
  extends IDefaultChatFlowSettingsCollectAlcoholConsumption {
  messages?: IDefaultChatFlowMessagesCollectAlcoholConsumption
}

type State = BaseScriptState
export type CollectAlcoholConsumptionScriptState = State

export class CollectAlcoholConsumptionScript extends BaseScript<State> {
  readonly name: string = "CollectAlcoholConsumptionScript"
  protected readonly messages: IDefaultChatFlowMessagesCollectAlcoholConsumption
  protected readonly shouldAskAlcoholFrequency?: boolean
  protected readonly shouldAskAlcoholQuantity?: boolean
  protected readonly shouldAskAlcoholImpactsLife?: boolean
  protected readonly alcoholKey?: string
  protected readonly alcoholFrequencyKey?: string
  protected readonly alcoholQuantityKey?: string
  protected readonly alcoholImpactsLifeKey?: string

  constructor(settings?: ICollectAlcoholConsumptionSettings) {
    super()
    this.messages = settings?.messages ?? {}
    this.shouldAskAlcoholFrequency = settings?.shouldAskAlcoholFrequency ?? true
    this.shouldAskAlcoholQuantity = settings?.shouldAskAlcoholQuantity ?? true
    this.shouldAskAlcoholImpactsLife = settings?.shouldAskAlcoholImpactsLife
    this.alcoholKey = settings?.alcoholKey
    this.alcoholFrequencyKey = settings?.alcoholFrequencyKey
    this.alcoholQuantityKey = settings?.alcoholQuantityKey
    this.alcoholImpactsLifeKey = settings?.alcoholImpactsLifeKey
  }

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    return { nextStep: this.askAlcohol }
  }

  @step.logState
  askAlcohol(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messages.askAlcohol ?? "Are you currently using alcohol to manage your mood?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askAlcohol"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askAlcohol"
      },
      nextStep: this.handleAlcohol
    }
  }

  @step.logState
  async handleAlcohol(d: IStepData<State, boolean>): Promise<IStepResult> {
    const key = this.alcoholKey ?? "alcohol"
    d.state[key] = d.response
    this.setPeople({ [key]: d.response })

    return {
      nextStep: d.response //
        ? this.askAlcoholFrequency
        : this.end
    }
  }

  @step.logState
  askAlcoholFrequency(d: IStepData<State>): IStepResult {
    if (!this.shouldAskAlcoholFrequency) {
      this.logger.breadcrumb({ message: "askAlcoholFrequency disabled" })
      this.track("askAlcoholFrequency disabled")
      return { nextStep: this.askAlcoholQuantity }
    }

    return {
      body: this.t(
        this.messages.askAlcoholFrequency ?? "How often do you have a drink containing alcohol?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askAlcoholFrequency"),
        type: "inlinePicker",
        choices: [
          {
            body: this.t("Monthly or less"),
            value: ALCOHOL_FREQUENCIES.MONTHLY
          },
          {
            body: this.t("2 to 4 times a month"),
            value: ALCOHOL_FREQUENCIES.MONTHLY_2_TO_4
          },
          {
            body: this.t("2 to 3 times a week"),
            value: ALCOHOL_FREQUENCIES.WEEKLY_2_TO_3
          },
          {
            body: this.t("4 times or more per week"),
            value: ALCOHOL_FREQUENCIES.WEEKLY_4
          }
        ],
        dataPointsName: "askAlcoholFrequency"
      },
      nextStep: this.handleAlcoholFrequency
    }
  }

  @step.logState
  async handleAlcoholFrequency(
    d: IStepData<State, ValueOf<typeof ALCOHOL_FREQUENCIES>>
  ): Promise<IStepResult> {
    const key = this.alcoholFrequencyKey ?? "alcoholFrequency"
    d.state[key] = d.response
    this.setPeople({ [key]: d.response })

    return { nextStep: this.askAlcoholQuantity }
  }

  @step.logState
  askAlcoholQuantity(d: IStepData<State>): IStepResult {
    if (!this.shouldAskAlcoholQuantity) {
      this.logger.breadcrumb({ message: "askAlcoholQuantity disabled" })
      this.track("askAlcoholQuantity disabled")
      return { nextStep: this.askAlcoholImpactsLife }
    }
    return {
      body: this.t(
        this.messages.askAlcoholQuantity ?? [
          "Ok, now take a look at the following image:",
          {
            type: "imageAttachment",
            image:
              "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/alcohol-units.png",
            imageDescription:
              "There is 1 unit of alcohol in a 250ml glass of beer or a 75ml glass of wine or a 25ml glass of spirit"
          },
          "How many units would you have on a typical day when you are drinking alcohol?"
        ],
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askAlcoholQuantity"),
        type: "inlinePicker",
        choices: [
          { body: this.t("0 to 2"), value: ALCOHOL_QUANTITIES._0_2 },
          { body: this.t("3 to 4"), value: ALCOHOL_QUANTITIES._3_4 },
          { body: this.t("5 to 6"), value: ALCOHOL_QUANTITIES._5_6 },
          { body: this.t("7 to 9"), value: ALCOHOL_QUANTITIES._7_9 },
          { body: this.t("10 or more"), value: ALCOHOL_QUANTITIES._10_PLUS }
        ],
        dataPointsName: "askAlcoholQuantity"
      },
      nextStep: this.handleAlcoholQuantity
    }
  }

  @step.logState
  async handleAlcoholQuantity(
    d: IStepData<State, ValueOf<typeof ALCOHOL_QUANTITIES>>
  ): Promise<IStepResult> {
    const key = this.alcoholQuantityKey ?? "alcoholQuantity"
    d.state[key] = d.response
    this.setPeople({ [key]: d.response })

    return { nextStep: this.askAlcoholImpactsLife }
  }

  @step.logState
  askAlcoholImpactsLife(d: IStepData<State>): IStepResult {
    if (!this.shouldAskAlcoholImpactsLife) {
      this.logger.breadcrumb({ message: "askAlcoholImpactsLife disabled" })
      this.track("askAlcoholImpactsLife disabled")
      return { nextStep: this.end }
    }

    return {
      body: this.t(
        this.messages.askAlcoholImpactsLife ??
          "Does drinking alcohol have an impact on your home life i.e., work, studies, family life?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askAlcoholImpactsLife"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askAlcoholImpactsLife"
      },
      nextStep: this.handleAlcoholImpactsLife
    }
  }

  @step.logState
  async handleAlcoholImpactsLife(d: IStepData<State, boolean>): Promise<IStepResult> {
    const key = this.alcoholImpactsLifeKey ?? "alcoholImpactsLife"
    d.state[key] = d.response
    this.setPeople({ [key]: d.response })

    return { nextStep: this.end }
  }
}

/* istanbul ignore next */
export default class CollectAlcoholConsumptionDialogue extends AdHocDialogue<
  State,
  CollectAlcoholConsumptionScript
> {
  static id = DialogueIDs.CollectAlcoholConsumption
  readonly name: string = "CollectAlcoholConsumptionDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: ICollectAlcoholConsumptionSettings
  ) {
    super(
      CollectAlcoholConsumptionDialogue.id,
      new CollectAlcoholConsumptionScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
