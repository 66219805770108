import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import BaseScript, { BaseScriptState } from "../../BaseScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"

type State = BaseScriptState

export class SelfReferralPitchWellbeingHubScript extends BaseScript<State> {
  readonly name: string = "SelfReferralPitchWellbeingHubScript"

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.step1 }
  }

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askWannaBookIn }
  }

  @step.logState
  askWannaBookIn(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("I need to ask you a few questions. Is that okay?"),
      prompt: {
        id: this.getPromptId("askWannaBookIn"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Sure") }, { body: this.t("Okay") }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleWannaBookIn
    }
  }

  @step.logState
  handleWannaBookIn(_d: IStepData<State, boolean>): IStepResult {
    return {
      body: this.t("Wonderful!"),
      nextStep: this.end
    }
  }
}

export default class SelfReferralPitchDialogueWellbeingHub extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralPitchWellbeingHub
  readonly name: string = "SelfReferralPitchDialogueWellbeingHub"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      SelfReferralPitchDialogueWellbeingHub.id,
      new SelfReferralPitchWellbeingHubScript(),
      state,
      snapshot
    )
  }
}
