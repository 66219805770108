import type Script from "../../Script"
import type { ScriptState } from "../../Script"
import type { IStep, IStepData, IStepResult, StepDescriptor } from "../../models/IStep"

function decorate<State extends ScriptState, S extends Script<State> = any>(
  step: IStep<State>
): IStep<State> {
  return async function (this: S, d: IStepData<State>): Promise<IStepResult> {
    this.startTyping()
    return step.call(this, d)
  }
}

export function startTyping<State extends ScriptState, S extends Script<State> = any>(
  _: S,
  __: keyof S,
  desc: StepDescriptor
): StepDescriptor {
  const org = desc.value
  desc.value = decorate(org!)
  return desc
}

startTyping.decorate = decorate
