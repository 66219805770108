import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { RiskLevelReason } from "../../../models/Constants"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { LanguageCodes } from "@limbic/types"

type State = RiskPathwayScriptState
export type RiskPathwayMindScriptState = State

export class RiskPathwayMindScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayMindScript"

  /** Script Steps */

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askCanYouKeepYourselfSafe }
  }

  @step.logState
  askCanYouKeepYourselfSafe(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Are you able to keep yourself, and any dependants in your care, safe until your appointment (within 28 days)?"
      ),
      prompt: {
        id: this.getPromptId("askCanYouKeepYourselfSafe"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askCanYouKeepYourselfSafe"
      },
      nextStep: this.handleCanYouKeepYourselfSafe
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.saveResponse<State>(
    "10",
    "Are you able to keep yourself, and any dependants in your care, safe until your appointment (within 28 days)?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  @step.handleResponse((d: IStepData<State, boolean>, script: RiskPathwayMindScript) => {
    d.state.canKeepSelfSafe = d.response
    script.referralStore.setCustomField<State>("canKeepSelfSafe", d.response)
  })
  handleCanYouKeepYourselfSafe(d: IStepData<State>): IStepResult {
    if (!d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.CANNOT_KEEP_SELF_SAFE)
    }
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  async sayCrisis(d: IStepData<State>): Promise<IStepResult> {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName

    return {
      body: this.t(
        [
          "Sorry to hear that {name}",
          "Mental health emergencies are serious. You're not wasting anyone's time",
          "It is normal for people to have thoughts of this nature at times",
          "However {organisationName} is not an emergency response service"
        ],
        { name, organisationName }
      ),
      nextStep: this.sayCrisisNumbers
    }
  }

  @step.logState
  sayCrisisNumbers(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "If you feel like you might attempt suicide, or may have seriously harmed yourself, you need urgent medical help, please call 999 for an ambulance",
        "If you can keep yourself safe for a short while, but you still need urgent advice please call NHS 111",
        "If you need to talk right now, you can call Samaritans on 116 123",
        'If you are in Wales you can also use the C.A.L.L Mental Health Helpline which is free and available 24/7 on 0800 132 737 or text "help" to 81066'
      ]),
      prompt: {
        id: this.getPromptId("sayCrisisNumbers"),
        type: "inlinePicker",
        choices: [{ body: this.t("I understand") }, { body: this.t("Okay") }]
      },
      nextStep: this.handleCrisisNumbers
    }
  }

  @step.logState
  handleCrisisNumbers(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "999, NHS 111 (Option 2), Samaritans (116 123), C.A.L.L (0800 132 737), SMS to 81066"
    )
    return {
      body: this.t("Ok, let's carry on with the mental health check in"),
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onSaveRiskLevelAndReferralType(state: State): Promise<IStepResult> {
    return { nextStep: !state.canKeepSelfSafe ? this.sayCrisis : this.end }
  }

  getKeepingSafeAttachmentUrls(_state: State): string[] {
    // prettier-ignore
    return this.rootStore.applicationStore.translator?.language === LanguageCodes.CY
      ? ["https://limbic-web-bot.s3.eu-west-2.amazonaws.com/mind/Mind+Keeping+safe+leaflet+-+Welsh+-+Mind.pdf"]
      : ["https://limbic-web-bot.s3.eu-west-2.amazonaws.com/mind/Keeping+Safe+Leaflet+Mind.pdf"]
  }

  createKeepingSafeLeafletEmail(title: string, logo: string): string {
    if (this.rootStore.applicationStore.translator?.language === LanguageCodes.CY) {
      return this.createWelshKeepingSafeLeafletEmail(title, logo)
    }
    return `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" lang='en'>
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>${title}</title>
    ${this.getEmailHTMLStyle()}
    <!--[if IEMobile 7]>
	<style type="text/css">
		/* Targeting Windows Mobile */
	</style>
	<![endif]-->
    <!--[if gte mso 9]>
	<style>
		/* Target Outlook 2007 and 2010 */
	</style>
	<![endif]-->
  </head>

  <body>
    <table cellpadding="0" cellspacing="0" border="0" id="backgroundTable">
      <tr>
        <td>
          <table cellpadding="0" cellspacing="0" border="0" id="contentTable">
            <tr>
              <td><img class="image_fix" src="${logo}" alt="${title}" title="${title}" width="x" height="x" /><br /><br /></td>
            </tr>
            <tr>
              <td>
                Thank you for referring into Mind’s supported self-help programme. This programme has been designed to support people with mild to moderate mental health needs, which isn’t always the appropriate level of support for everyone.
              </td>
            </tr>
            <tr>
              <td>
                <br />
                In one of your answers, you said you were experiencing thoughts of self-harm or suicidal thoughts. If you feel you may act on suicidal thoughts, cannot keep yourself safe, or are a risk to others around you, please call 999, NHS 111 and select Option 2 or Samaritans on 116 123.
              </td>
            </tr>
            <tr>
              <td>
                <br />
                A practitioner will be in touch within 28 days to determine whether this service is able to offer you the appropriate level of support that you may need.
              </td>
            </tr>
            <tr>
              <td>
                <br /><br />
                <em>The Mind team</em>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </body>
</html>`
  }

  createWelshKeepingSafeLeafletEmail(title: string, logo: string): string {
    return `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" lang='en'>
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>${title}</title>
    ${this.getEmailHTMLStyle()}
    <!--[if IEMobile 7]>
	<style type="text/css">
		/* Targeting Windows Mobile */
	</style>
	<![endif]-->
    <!--[if gte mso 9]>
	<style>
		/* Target Outlook 2007 and 2010 */
	</style>
	<![endif]-->
  </head>

  <body>
    <table cellpadding="0" cellspacing="0" border="0" id="backgroundTable">
      <tr>
        <td>
          <table cellpadding="0" cellspacing="0" border="0" id="contentTable">
            <tr>
              <td><img class="image_fix" src="${logo}" alt="${title}" title="${title}" width="x" height="x" /><br /><br /></td>
            </tr>
            <tr>
              <td>
                <b>Cadw eich Hun yn Ddiogel</b>
              </td>
            </tr>
            <tr>
              <td>
                Diolch am gyfeirio at raglen hunangymorth â chymorth Mind. Mae’r rhaglen hon wedi cael ei dylunio i gefnogi pobl sydd ag anghenion iechyd meddwl ysgafn i gymedrol, sydd ddim wastad yn lefel briodol o gymorth i bawb.
              </td>
            </tr>
            <tr>
              <td>
                Yn un o’ch atebion fe ddywedoch eich bod yn meddwl am hunan niweidio neu am ladd eich hun. Os ydych chi’n teimlo y gallech chi weithredu ar eich meddyliau am hunanladdiad, methu â chadw eich hun yn ddiogel, neu eich bod yn peri risg i eraill o’ch cwmpas, ffoniwch 999, NHS 111 a dewiswch Opsiwn 2 neu’r Samariaid ar 116 123.
              </td>
            </tr>
            <tr>
              <td>
                Bydd ymarferydd yn cysylltu â chi o fewn 28 diwrnod i benderfynu a yw’r gwasanaeth hwn yn gallu cynnig y lefel briodol o gymorth sydd ei angen arnoch. 
              </td>
            </tr>
            <tr>
              <td>
                <br /><br />
                <em>Hunangymorth gyda chefnogaeth gan Mind</em>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </body>
</html>`
  }
}

/* istanbul ignore next */
export default class RiskPathwayMindDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayMind
  readonly name: string = "RiskPathwayMindDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(RiskPathwayMindDialogue.id, new RiskPathwayMindScript(), state, snapshot)
  }
}
