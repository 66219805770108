import BaseScript, { BaseScriptState } from "../../../BaseScript"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { step } from "../../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { TrackingEvents } from "../../../../models/Constants"
import {
  IDefaultChatFlowMessagesCollectFeedback,
  IDefaultChatFlowSettingsCollectFeedback
} from "@limbic/types"

export type ICollectFeedbackSettings = IDefaultChatFlowSettingsCollectFeedback & {
  messages?: IDefaultChatFlowMessagesCollectFeedback
}

type State = BaseScriptState

export type CollectFeedbackScriptState = State

export class CollectFeedbackScript extends BaseScript<State> {
  readonly name: string = "CollectFeedbackScript"
  protected messages: IDefaultChatFlowMessagesCollectFeedback | undefined
  protected shouldAskAdditionalFeedback?: boolean

  constructor(settings: ICollectFeedbackSettings | undefined = {}) {
    super()
    this.messages = settings?.messages ?? {}
    this.shouldAskAdditionalFeedback = settings?.shouldAskAdditionalFeedback ?? true
  }

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askFeedback }
  }

  @step.logState
  askFeedback(d: IStepData<State>): IStepResult {
    const requiresUrgentSupport =
      this.clinicalStore.requiresUrgentSupport || d.state.requiresUrgentSupport
    if (this.clinicalStore.isCrisis || requiresUrgentSupport) {
      this.track(TrackingEvents.SKIP_FEEDBACK_CRISIS)
      return { nextStep: this.sayIHopeIHelped }
    }
    return {
      body: this.t(
        this.messages?.askFeedback ?? "I hope I've been able to help you today",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askFeedback"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes thanks - you've helped me"), value: "yes" },
          { body: this.t("I'd have preferred a bit more help"), value: "more" },
          { body: this.t("No - you haven't helped me"), value: "no" }
        ]
      },
      nextStep: this.handleFeedback
    }
  }

  @step.logState
  sayIHopeIHelped(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messages?.askFeedback ?? "I hope I've been able to help you today",
        this.getContext(d.state)
      ),
      nextStep: this.end
    }
  }

  @step
  handleFeedback(d: IStepData<State, "yes" | "more" | "no">): IStepResult {
    if (d.response === "no") {
      d.state.isHelpful = "No"
      return { nextStep: this.saySorryToHearThat }
    }
    if (d.response === "more") {
      d.state.isHelpful = "Needed more"
      return { nextStep: this.sayUnderstood }
    }
    d.state.isHelpful = "Yes"
    return { nextStep: this.sayImGlad }
  }

  @step
  saySorryToHearThat(d: IStepData<State>): IStepResult {
    if (this.shouldAskAdditionalFeedback) {
      return {
        body: this.t(
          this.messages?.saySorryToHearThat ?? [
            "Sorry to hear that. How could I improve?",
            "(Please Note: Feedback is audited and used to inform service improvement however, entries given here are not reviewed or responded to by a trained clinician)"
          ],
          this.getContext(d.state)
        ),
        prompt: {
          id: this.getPromptId("howCanIImprove (Not Helpful)"),
          trackResponse: true,
          type: "text",
          cancelIsEmptySubmit: true
        },
        nextStep: this.handleSorryToHearThatWithCrisis
      }
    }
    return {
      body: this.t(
        this.messages?.saySorryToHearThat ?? ["Sorry to hear that"],
        this.getContext(d.state)
      ),
      nextStep: this.handleSorryToHearThatWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: CollectFeedbackScript) => {
    const suggestion = d.response
    script.track(TrackingEvents.IMPROVEMENT_SUGGESTION, { isHelpful: "No", suggestion })
    if (suggestion?.trim()) d.state.improvementSuggestion = suggestion
  })
  @step.checkInputForCrisis({
    getNextStep: (s: CollectFeedbackScript) => s.end
  })
  handleSorryToHearThatWithCrisis(d: IStepData<State, string>): IStepResult {
    return {
      body: this.shouldAskAdditionalFeedback
        ? this.t(
            "Noted - I'm always learning so hopefully I can do better next time",
            this.getContext(d.state)
          )
        : undefined,
      nextStep: this.end
    }
  }

  @step
  sayUnderstood(_d: IStepData<State>): IStepResult {
    if (this.shouldAskAdditionalFeedback) {
      return {
        body: this.t(
          this.messages?.sayUnderstood ?? [
            "Understood. How could I improve?",
            "(Please Note: Feedback is audited and used to inform service improvement however, entries given here are not reviewed or responded to by a trained clinician)"
          ]
        ),
        prompt: {
          id: this.getPromptId("howCanIImprove (Needed More)"),
          trackResponse: true,
          type: "text",
          cancelIsEmptySubmit: true
        },
        nextStep: this.handleUnderstoodWithCrisis
      }
    }
    return {
      body: this.t(this.messages?.sayUnderstood ?? ["Understood"]),
      nextStep: this.handleUnderstoodWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: CollectFeedbackScript) => {
    const suggestion = d.response
    script.track(TrackingEvents.IMPROVEMENT_SUGGESTION, { isHelpful: "Needed More", suggestion })
    d.state.improvementSuggestion = suggestion
  })
  @step.checkInputForCrisis({
    getNextStep: (s: CollectFeedbackScript) => s.end
  })
  handleUnderstoodWithCrisis(_d: IStepData<State, string>): IStepResult {
    return {
      body: this.shouldAskAdditionalFeedback
        ? this.t("Noted - I'm always learning so hopefully I can do better next time")
        : undefined,
      nextStep: this.end
    }
  }

  @step
  sayImGlad(_d: IStepData<State>): IStepResult {
    if (this.shouldAskAdditionalFeedback) {
      return {
        body: this.t(
          this.messages?.sayImGlad ?? [
            "I'm really glad to hear that",
            "What was the main benefit I was able to bring you?",
            "(Please Note: Feedback is audited and used to inform service improvement however, entries given here are not reviewed or responded to by a trained clinician)"
          ]
        ),
        prompt: {
          id: this.getPromptId("howCanIImprove (Helpful)"),
          trackResponse: true,
          type: "text",
          cancelIsEmptySubmit: true
        },
        nextStep: this.handleImGladWithCrisis
      }
    }
    return {
      body: this.t(this.messages?.sayImGlad ?? ["I'm really glad to hear that"]),
      nextStep: this.handleImGladWithCrisis
    }
  }

  @step
  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: CollectFeedbackScript) => {
    const suggestion = d.response
    script.track(TrackingEvents.IMPROVEMENT_SUGGESTION, { isHelpful: "Yes", suggestion })
    d.state.improvementSuggestion = suggestion
  })
  @step.checkInputForCrisis({
    getNextStep: (s: CollectFeedbackScript) => s.end
  })
  handleImGladWithCrisis(_d: IStepData<State, string>): IStepResult {
    return {
      body: this.shouldAskAdditionalFeedback
        ? this.t("Noted - I'm always learning so hopefully I can do even better next time")
        : undefined,
      nextStep: this.end
    }
  }
}

export default class CollectFeedbackDialogue extends AdHocDialogue<State, CollectFeedbackScript> {
  static id = DialogueIDs.CollectFeedback
  readonly name: string = "CollectFeedbackDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: ICollectFeedbackSettings
  ) {
    super(
      CollectFeedbackDialogue.id,
      new CollectFeedbackScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
