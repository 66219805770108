import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { IStepResult } from "../../../backend/chatbot/models/IStep"
import { AssessmentWithADSMScript, AssessmentWithADSMScriptState } from "./AssessmentWithADSMScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData } from "../../../backend/chatbot/models/IStep"
import { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"

type State = AssessmentWithADSMScriptState
export type AssessmentLWCScriptState = State

export class AssessmentLWCScript extends AssessmentWithADSMScript {
  readonly name: string = "AssessmentLWCScript"

  /** Script Steps */

  @step.logState
  sayLetsGetStarted(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Let's get started...",
        "This first set of 9 questions is designed to measure common symptoms of low mood"
      ],
      prompt: {
        id: this.getPromptId("sayLetsGetStarted"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.goToPHQ9
    }
  }

  /** Generic Handlers */

  async onHandleIAPTPhobiaScale(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayReadyForWASAS }
  }

  async onHandleIAPTWorkAndSocialAdjustment(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayThatsEverything }
  }

  getCustomReferralType(state: State): string | undefined {
    if (state.requiresInterpreter) return "Extended Assessment"
  }
}

/** istanbul ignore next */
export default class AssessmentLWCDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentLWC
  readonly name: string = "AssessmentLWCDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentLWCDialogue.id, new AssessmentLWCScript(), state, snapshot)
  }
}
