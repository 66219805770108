import { IntroductionIAPTScript, IntroductionIAPTScriptState } from "./IntroductionIAPTDialogue"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

export interface IntroductionMPFTScriptState extends IntroductionIAPTScriptState {
  requiresUrgentSupport?: boolean
}
type State = IntroductionMPFTScriptState

export class IntroductionMPFTScript extends IntroductionIAPTScript {
  readonly name: string = "IntroductionMPFTScript"

  /** Script Steps */

  @step.logState
  greet(_d: IStepData<State>): IStepResult {
    const { directReferral } = this.rootStore.configStore
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        "Hi there!",
        "I’m Limbic",
        `I'm a friendly robot assistant to help you access an assessment for talking therapies from ${organisationName}`
      ],
      nextStep: directReferral ? this.sayStaticReferralURL : this.askCanIHelp
    }
  }

  @step.logState
  askCanIHelp(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: "Can I help?",
      prompt: {
        id: this.getPromptId("askCanIHelp"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: `Yes, I'd like your help to refer myself to ${organisationName}`, value: true },
          { body: "No, I'm just browsing", value: false }
        ],
        isUndoAble: false
      },
      nextStep: this.handleCanIHelp
    }
  }

  @step.logState
  handleCanIHelp(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.askRequiresUrgentSupport }
    }
    return { nextStep: this.sayIfYouChangeYourMindWakeMeUp }
  }

  sayStaticReferralURL(_d: IStepData<State>): IStepResult {
    const { formLink } = this.rootStore.configStore
    if (formLink) {
      return {
        body: this.t(
          "Using Limbic is the easiest way to ensure our team receive your referral quickly. If you would prefer to use our standard web form, you can do so [here]({formLink}). Please fill out only one of these referral forms",
          { formLink }
        ),
        isStaticReferralURL: true,
        nextStep: this.end
      }
    }
    return { nextStep: this.end }
  }

  @step.logState
  askRequiresUrgentSupport(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Before we continue, we need to check if you require urgent support",
        "Do you need immediate help for a mental health crisis or emergency?"
      ],
      prompt: {
        id: this.getPromptId("askRequiresUrgentSupport"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: false,
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleRequiresUrgentSupport
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: IntroductionMPFTScript) => {
    d.state.requiresUrgentSupport = d.response
    script.referralStore.setCustomField<State>("requiresUrgentSupport", d.response)
  })
  handleRequiresUrgentSupport(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      this.clinicalStore.setRequiresUrgentSupport(true)
      this.setRiskLevelHigh(d.state, "User said they require urgent support")
      return { nextStep: this.sayCrisis }
    }
    return { nextStep: this.end }
  }

  @step.logState
  sayCrisis(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName

    return {
      body: [
        `Thanks for sharing ${name}, we know it's not always easy`,
        `However ${organisationName} does not provide urgent care`,
        "If you need urgent support, please dial NHS 111 and select Option 2",
        "In medical emergency and life threatening situations only, please dial 999 or attend your local A&E department",
        `You can also contact the local Urgent NHS Mental Health Helpline on 0808 196 4501`,
        "Other helplines available:\n\n" +
          "You can contact Samaritans 24 hours a day, 365 days a year. You can call 116 123 (free from any phone) or email jo@samaritans.org\n" +
          "If you would prefer not to talk but want some mental health support, you could text SHOUT to 85258. Shout offers a confidential 24/7 text service providing support if you are in need of immediate help",
        `You can refer yourself to ${organisationName} when you are no longer in crisis`
      ],
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }]
      },
      nextStep: this.handleSayCrisis
    }
  }

  @step.logState
  handleSayCrisis(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "I hope I've been able to help you today",
        "Well done for taking this important step towards better mental health"
      ],
      nextStep: this.end,
      clearStack: true
    }
  }
}

export default class IntroductionMPFTDialogue extends Dialogue<State> {
  static id = DialogueIDs.IntroductionMPFT
  readonly name = "IntroductionMPFTDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(IntroductionMPFTDialogue.id, new IntroductionMPFTScript(), state, snapshot)
  }
}
