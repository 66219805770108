import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { UnderAgeSignpostScript, UnderAgeSignpostScriptState } from "./UnderAgeSignpostDialogue"
import { DialogueIDs } from "../../DialogueIDs"

type State = UnderAgeSignpostScriptState
export type UnderAgeSignpostBuckinghamshireScriptState = State

export class UnderAgeSignpostBuckinghamshireScript extends UnderAgeSignpostScript {
  readonly name: string = "UnderAgeSignpostBuckinghamshireScript"

  /** Script Steps */
  @step.logState
  sayNotEligible(d: IStepData<State>): IStepResult {
    const threshold = this.getIAPTServiceAgeThreshold(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    const name = this.getName(d.state)
    return {
      body: this.t(
        [
          "So {name}, there's a special type of mental health support for people under the age of {threshold}",
          "{organisationName} only provides a service to those people aged 18 or over, registered with a GP surgery in Buckinghamshire",
          "You indicated that you are currently under the age of 18 so I would like to recommend that you contact one of the following services who might be able to help:" +
            "\n\n- The Buckinghamshire Child and Adolescent Mental Health Service (CAMHS). Any professional can refer to this service or you can self-refer, if you are aged between 16 & 18, as a parent or young person by visiting https://secureforms.oxfordhealth.nhs.uk/camhs/ or contact our Single Point of Access (SPA) on 01865 901 951" +
            "\n- Papyrus provide help and advice for young people thinking about suicide and people who are worried about someone else: 0800 0684141" +
            "\n- Young Minds also provides 24/7 support for any young person experiencing a mental health crisis. Just text YM to 85258" +
            "\n- Childline can be contacted on 0800 1111 for any other concerns relating to young people"
        ],
        { name, iaptName, threshold, organisationName }
      ),
      prompt: {
        id: this.getPromptId("sayNotEligible"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Okay"), value: "okay" },
          { body: this.t("Thanks"), value: "thanks" }
        ]
      },
      nextStep: this.handleNotEligible
    }
  }

  @step.logState
  sayOtherResources(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "You might also be interested in checking out the following resources..." +
          "\n\n- Kooth: a counseling resource for young people (https://kooth.com)" +
          "\n- The Mix: a free and confidential service that aims to find young people the best help, whatever the problem (https://www.themix.org.uk/",
        "Finally, to find the best service for you, there's a helpful website [here](https://www.nhs.uk/service-search/other-services/Mental-health-support-for-young-people/LocationSearch/1430)"
      ]),
      prompt: {
        id: this.getPromptId("sayOtherResources"),
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }]
      },
      nextStep: this.sayCrisisCallLine
    }
  }
}

export default class UnderAgeSignpostDialogue extends Dialogue<State> {
  static id = DialogueIDs.UnderAgeSignpostBuckinghamshire
  readonly name: string = "UnderAgeSignpostBuckinghamshireDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(UnderAgeSignpostDialogue.id, new UnderAgeSignpostBuckinghamshireScript(), state, snapshot)
  }
}
