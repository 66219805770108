import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { DialogueIDs } from "../../DialogueIDs"
import {
  EligibilityCheckWithPDSScript,
  EligibilityCheckWithPDSScriptState
} from "./EligibilityCheckWithPDSScript"
import ISelectable from "../../../models/ISelectable"
import { IneligibilityReason } from "@limbic/types"
import getIAPTById from "../../../utils/getIAPTById"
import { IAPTIDs } from "../../../models/IIAPTService"

type CurrentTalkingTherapies = "CURRENT_TALKING_THERAPY" | "CURRENT_WAITLIST_MH_SERVICE" | "NO"

interface State extends EligibilityCheckWithPDSScriptState {
  requiresUrgentSupport?: boolean
  currentTalkingTherapy?: CurrentTalkingTherapies
  currentTalkingTherapyDetails?: string
  studyingAwayFromGP?: boolean
  onlineGPName?: string
}

export type EligibilityCheckOxfordScriptState = State

export class EligibilityCheckOxfordScript extends EligibilityCheckWithPDSScript {
  readonly name: string = "EligibilityCheckOxfordScript"
  readonly FAILED_ATTEMPTS_THRESHOLD: number = 3

  /** Script Steps */

  @step
  sayINeedToAskSomeDetails(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: this.t(
        [
          "In order to create a referral to {organisationName}, I just need to confirm a few details with you",
          "You can re-type your answer at any point by clicking the 'Undo' button next to the message you want to change. You cannot do this for every question"
        ],
        { organisationName }
      ),
      nextStep: this.askBirthday
    }
  }

  @step.logState
  startEligibilityCheck(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askRequiresUrgentSupport }
  }

  @step.logState
  askRequiresUrgentSupport(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        `Thanks ${name}. Before we continue, we need to check if you require urgent support`,
        "Are you at immediate risk of harming yourself?"
      ],
      prompt: {
        id: this.getPromptId("askRequiresUrgentSupport"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: false,
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleRequiresUrgentSupport
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: EligibilityCheckOxfordScript) => {
    d.state.requiresUrgentSupport = d.response
    script.referralStore.setCustomField<State>("requiresUrgentSupport", d.response)
  })
  handleRequiresUrgentSupport(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      this.clinicalStore.setRequiresUrgentSupport(true)
      this.trackUserAsIneligible(d.state, IneligibilityReason.REQUIRES_URGENT_SUPPORT)
      this.setRiskLevelHigh(d.state, "User said they require urgent support")
    }
    return {
      nextStep: d.response //
        ? this.sayCrisis
        : this.askPostCodeOfUser
    }
  }

  @step.logState
  sayCrisis(_d: IStepData<State>): IStepResult {
    const serviceName =
      this.rootStore.configStore.serviceName ?? this.rootStore.configStore.organisationName
    const organisationGenericPhoneNumber = this.rootStore.configStore.organisationGenericPhoneNumber

    return {
      body: [
        "Sorry to hear that",
        `However ${serviceName} does not provide urgent care`,
        "If you need urgent support, please dial NHS 111 and select Option 2",
        "In medical emergency and life threatening situations only, please dial 999 or attend your local A&E department",
        "You could also contact Safe Haven. This is a non-clinical, face-to-face safe space to get short-term support in mental health crisis when other services are not available. It is open Friday to Monday 6pm-10pm",
        "Oxford: 01865 903 037\nBanbury: 01295 270 004",
        "Other helplines available:",
        "You can contact Samaritans 24 hours a day, 365 days a year. You can call 116 123 (free from any phone) or email [jo@samaritans.org](jo@samaritans.org)",
        "If you would prefer not to talk but want some mental health support, you could text SHOUT to 85258. Shout offers a confidential 24/7 text service providing support if you are in need of immediate help",
        `You can refer yourself back to ${serviceName} when you are no longer in crisis`,
        `Or you can contact them on ${organisationGenericPhoneNumber} if you still feel you are appropriate to make a self referral`
      ],
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }]
      },
      nextStep: this.handleSayCrisis
    }
  }

  @step.logState
  handleSayCrisis(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "999, NHS 111, 116 123, Samaritans, 01295 270 004 and 01865 903 037"
    )
    return { nextStep: this.goToGoodbye }
  }

  @step.logState
  askSelectIAPTServiceManually(d: IStepData<State>): IStepResult {
    const eligibleIAPTs = this.getEligibleIAPTSByAgeThreshold(d.state)
    return {
      body: "And which service would you like to be referred into?",
      prompt: {
        id: this.getPromptId("askSelectIAPTServiceManually"),
        trackResponse: true,
        type: "inlinePicker",
        choices: (
          eligibleIAPTs.map(iapt => ({
            body: iapt.formattedName,
            value: iapt
          })) as ISelectable<any>[]
        ) //
          .concat({
            body: "Actually, I want to speak to a human",
            value: "speakToHuman",
            backgroundColor: "#EC9CC8"
          })
      },
      nextStep: this.handleSelectIAPTServiceManually
    }
  }

  @step
  checkEligibility(d: IStepData<State>): IStepResult {
    const isEligible = this.getIsEligible(d.state)
    this.setEligibility(d.state, isEligible)

    const needsToSelfReferManually = this.getNeedsToSelfReferManually(d.state)
    this.setSignpostToManualSelfReferral(d.state, needsToSelfReferManually)
    return { nextStep: this.handleEligibilityCheckStep1 }
  }

  @step.logState
  handleEligibilityCheckStep1(d: IStepData<State>): IStepResult {
    switch (true) {
      case d.state.isUnderAged:
        return { nextStep: this.goToUnder18SignPost }
      case d.state.needsToCall:
      case d.state.signPostToManualReferral:
      case !d.state.isEligible:
        return { nextStep: this.askStudyingAwayFromGP }
      default:
        return { nextStep: this.sayYoureEligible }
    }
  }

  @step.logState
  askStudyingAwayFromGP(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: this.t(
        [
          "Hmmm... it doesn't look like {organisationName} have any services in the same area as your GP",
          "Are you studying in the Oxfordshire area away from your registered GP or living in Oxfordshire and registered to Medicus or another online GP?"
        ],
        { organisationName }
      ),
      prompt: {
        id: this.getPromptId("askStudyingAwayFromGP"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Studying away from registered GP", value: "away" },
          { body: "Online GP", value: "online" },
          { body: "Neither", value: "no" }
        ]
      },
      nextStep: this.handleStudyingAwayFromGP
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: EligibilityCheckOxfordScript) => {
    const studyingAway = ["away", "online"].includes(d.response)
    d.state.studyingAwayFromGP = studyingAway
    script.referralStore.setCustomField<State>("studyingAwayFromGP", studyingAway)
  })
  handleStudyingAwayFromGP(d: IStepData<State, string>): IStepResult {
    const iapt = getIAPTById(IAPTIDs.OXFORDSHIRE)

    if (d.response === "away") {
      this.setIAPT(d.state, iapt)
      this.setEligibility(d.state, true)
      this.setSignpostToManualSelfReferral(d.state, false)
      return { nextStep: this.sayYoureEligible }
    } else if (d.response === "online") {
      this.setIAPT(d.state, iapt)
      this.setEligibility(d.state, true)
      this.setSignpostToManualSelfReferral(d.state, false)
      return { nextStep: this.askOnlineGPName }
    } else {
      return { nextStep: this.handleEligibilityCheckStep2 }
    }
  }

  @step.logState
  askOnlineGPName(_d: IStepData<State>): IStepResult {
    return {
      body: "Could you tell me the name of your online GP?",
      prompt: {
        id: this.getPromptId("askOnlineGPName"),
        trackResponse: true,
        type: "text",
        isUndoAble: true
      },
      nextStep: this.handleAskOnlineGPNameWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    getNextStep: (s: EligibilityCheckOxfordScript) => s.askOnlineGPName
  })
  @step.handleResponse((d: IStepData<State, string>, _script: EligibilityCheckOxfordScript) => {
    d.state.onlineGPName = d.response
  })
  async handleAskOnlineGPNameWithCrisis(_d: IStepData<State, string>): Promise<IStepResult> {
    return { nextStep: this.handleEligibilityCheckStep2 }
  }

  @step.logState
  handleEligibilityCheckStep2(d: IStepData<State>): IStepResult {
    switch (true) {
      case d.state.isUnderAged:
        return { nextStep: this.goToUnder18SignPost }
      case d.state.needsToCall:
        return { nextStep: this.sayCallIntoService }
      case d.state.signPostToManualReferral:
        return { nextStep: this.sayEligibleForManualSelfReferral }
      case !d.state.isEligible:
        return { nextStep: this.goToOtherServicesSignPost }
      default:
        return { nextStep: this.sayYoureEligible }
    }
  }

  @step.logState
  sayCallIntoService(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const organisationPhoneNumbers = this.rootStore.configStore.organisationPhoneNumbers ?? ""
    const formLink = this.rootStore.configStore.formLink
    return {
      body: this.t(
        [
          "I'm just a humble robot. My only goal is to help you. Sorry I wasn't able to do that on this occasion",
          "Don't worry though - I have a lot of human colleagues at {organisationName} who are ready to help you!",
          "Please give any one of our services a call on the following phone numbers:\n{organisationPhoneNumbers}",
          "Alternatively you could try using our e-referral form [here]({formLink})"
        ],
        { organisationName, organisationPhoneNumbers, formLink }
      ),
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  sayExplanation(d: IStepData<State>): IStepResult {
    d.state.explanationCount ??= 0
    d.state.explanationCount++
    const organisationName = this.rootStore.configStore.organisationName
    const organisationPhoneNumbers = this.rootStore.configStore.organisationPhoneNumbers ?? ""
    const formLink = this.rootStore.configStore.formLink
    return {
      body: this.t(
        [
          "The reason as to why you can't be found in the NHS database is because the information you have provided is different from what what you are registered with at your GP",
          "Common reasons for confusion are:\n" +
            "\n" +
            "1. You are married, but you are still registered with your GP under your maiden name\n" +
            "2. You have recently moved, but you haven't updated your GP with your new address",
          "If this has helped, you can go ahead and edit some of your information. Alternatively, you can try to phone {organisationName} on {organisationPhoneNumbers} and they will be able to help you or you can use our e-referral form [here]({formLink})"
        ],
        { organisationName, organisationPhoneNumbers, formLink }
      ),
      nextStep: this.promptConfirmDetails
    }
  }

  /** Generic handlers */

  async onFailedSpineSearchCountReached(state: State): Promise<IStepResult> {
    return { nextStep: this.sayICouldntFindYouInPDSAndGoManual }
  }

  async onHandleBirthday(_state: State): Promise<IStepResult<State> | void> {
    return { nextStep: this.checkAgeThresholds }
  }

  getExArmedForcesValues(_state: State): string[] {
    return [
      "No",
      "Ex-services member",
      "Dependent of an ex-services member",
      "Not known/not sure",
      "Don't wish to say"
    ]
  }
}

/* istanbul ignore next */
export default class EligibilityCheckOxfordDialogue extends Dialogue<State> {
  static id = DialogueIDs.EligibilityCheckOxford
  readonly name: string = "EligibilityCheckOxfordDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(EligibilityCheckOxfordDialogue.id, new EligibilityCheckOxfordScript(), state, snapshot)
  }
}
