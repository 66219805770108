import moment from "moment"
import { IResponsePDSPatientResponse } from "../backend/api/limbic/pds"
import IName from "../models/IName"

/**
 * Extracts the most recent name from the spine patient response
 * and returns it as a partial IName object
 * @param spinePatient - The spine patient response
 */
export function extractSpineName(spinePatient: IResponsePDSPatientResponse): Partial<IName> {
  const name = [...spinePatient.name].sort((a, b) => {
    if (!(a.start && b.start)) return 0
    const dateA = moment(a.start)
    const dateB = moment(b.start)
    return dateA.isAfter(dateB) ? -1 : 1
  })[0]
  const spineName: Partial<IName> = {}
  const first = name?.first
  const last = name?.last
  if (first?.length) {
    const [firstName, ...middleNames] = first
    spineName.firstName = firstName
    spineName.middleNames = middleNames.join(" ")
  }
  if (last) spineName.lastName = last

  return spineName
}
