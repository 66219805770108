import BaseScript, { BaseScriptState } from "../../../BaseScript"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { step } from "../../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { IDefaultChatFlowMessagesCollectADHD } from "@limbic/types"

export type ICollectADHDSettings = {
  messages?: IDefaultChatFlowMessagesCollectADHD
}

type State = BaseScriptState

export type CollectADHDScriptState = State

export class CollectADHDScript extends BaseScript<State> {
  readonly name: string = "CollectADHDScript"
  protected messages: IDefaultChatFlowMessagesCollectADHD | undefined
  constructor(settings: ICollectADHDSettings | undefined = {}) {
    super()
    this.messages = settings?.messages ?? {}
  }

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askHasADHD }
  }

  @step.logState
  askHasADHD(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messages?.askHasADHD ??
          "Do you have a confirmed diagnosis of Attention Deficit Hyperactivity Disorder (ADHD)?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askHasADHD"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askHasADHD"
      },
      nextStep: this.handleHasADHD
    }
  }

  @step.logStateAndResponse
  async handleHasADHD(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.hasADHD = d.response
    this.referralStore.setCustomField<State>("hasADHD", d.state.hasADHD)
    return { nextStep: this.end }
  }
}

export default class CollectADHDDialogue extends AdHocDialogue<State, CollectADHDScript> {
  static id = DialogueIDs.CollectADHD
  readonly name: string = "CollectADHDDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>, settings?: ICollectADHDSettings) {
    super(
      CollectADHDDialogue.id,
      new CollectADHDScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
