import { UnderAgeSignpostScript, UnderAgeSignpostScriptState } from "./UnderAgeSignpostDialogue"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"

type State = UnderAgeSignpostScriptState
export type UnderAgeSignpostMindScriptState = State

export class UnderAgeSignpostMindScript extends UnderAgeSignpostScript {
  readonly name = "UnderAgeSignpostMindScript"

  /** Script Steps */

  @step.logState
  sayNotEligible(d: IStepData<State>): IStepResult {
    const threshold = this.getIAPTServiceAgeThreshold(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    const name = this.getName(d.state)
    return {
      body: this.t(
        [
          "So {name}, there's a special type of mental health support for people under the age of {threshold}",
          "{organisationName} do not currently provide this",
          "To find the best service for you, there's a helpful website [here](https://www.nhs.uk/service-search/other-services/Mental-health-support-for-young-people/LocationSearch/1430)",
          "Or you can talk to your doctor and they will help you"
        ],
        { name, organisationName, threshold }
      ),
      prompt: {
        id: this.getPromptId("sayNotEligible"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Okay"), value: "okay" },
          { body: this.t("Thanks"), value: "thanks" }
        ]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleNotEligible
    }
  }

  @step
  sayOtherResources(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "You might also be interested in checking out the following resources...",
        "Kooth: a counseling resource for young people (https://kooth.com)",
        "The Mix: a free and confidential service that aims to find young people the best help, whatever the problem (https://www.themix.org.uk/",
        "Childline: 0800 1111",
        "[Mind for children and young people](https://www.mind.org.uk/information-support/for-children-and-young-people/)",
        "[Useful Contacts](https://www.mind.org.uk/information-support/for-children-and-young-people/useful-contacts/)"
      ]),
      prompt: {
        id: this.getPromptId("sayOtherResources"),
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.sayCrisisCallLine
    }
  }

  @step
  closeDiscussion(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t(
        [
          "Please note, that we haven't saved any of your details",
          "I'm really glad you took this important step towards better mental health {name}",
          "Well done!",
          "And good luck"
        ],
        { name }
      ),
      nextStep: this.end,
      clearStack: true
    }
  }
}

/* istanbul ignore next */
export default class UnderAgeSignpostMindDialogue extends Dialogue<State> {
  static id = DialogueIDs.UnderageSignpostMind
  readonly name: string = "UnderAgeSignpostDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(UnderAgeSignpostMindDialogue.id, new UnderAgeSignpostMindScript(), state, snapshot)
  }
}
