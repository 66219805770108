import BaseScript, { BaseScriptState } from "../../../BaseScript"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { step } from "../../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import moment from "moment"
import invariant from "../../../../utils/invariant"
import {
  IDefaultChatFlowMessagesCollectDateOfBirth,
  EligibilityPresetFlowResults
} from "@limbic/types"

export type ICollectDateOfBirthSettings = {
  messages?: IDefaultChatFlowMessagesCollectDateOfBirth
}

type State = BaseScriptState

export type CollectDateOfBirthScriptState = State

export class CollectDateOfBirthScript extends BaseScript<State> {
  readonly name: string = "CollectDateOfBirthScript"
  protected readonly messages: IDefaultChatFlowMessagesCollectDateOfBirth | undefined
  constructor(settings?: ICollectDateOfBirthSettings | undefined) {
    super()
    this.messages = settings?.messages ?? {}
  }

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askBirthday }
  }

  @step.logState
  askBirthday(d: IStepData<State>): IStepResult {
    let body: string | string[] = ["First off, what's your date of birth?"]
    if (this.messages?.askBirthday && this.messages?.askBirthday.length) {
      body = this.t(this.messages?.askBirthday, this.getContext(d.state))
    }
    return {
      body,
      nextStep: this.showPromptForBirthday
    }
  }

  @step.logState
  showPromptForBirthday(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("showPromptForBirthday"),
        trackResponse: true,
        type: "date"
      },
      nextStep: this.handleBirthday
    }
  }

  @step
  async handleBirthday(d: IStepData<State, number>): Promise<IStepResult> {
    try {
      const date = moment(d.response)
      invariant(date, "I'm sorry that's not a valid date. Please enter your date of birth")
      invariant(
        date.isValid(),
        "I'm sorry that's not a valid date. Please enter your date of birth"
      )
      invariant(
        date.isBefore(moment()),
        "Hmm… I don't think humans can time-travel. Can you try and edit your date of birth?"
      )
      invariant(
        date.isAfter(moment("1899-12-31")),
        "Hmm… I don't think humans live that long. Can you try and edit your date of birth?"
      )
      d.state.birthday = date.toDate().getTime()
      this.setPeople({ age: moment().diff(date, "years") })
    } catch (e) {
      this.logException(e, "handleBirthday")
      return {
        body: this.t(e.message),
        nextStep: this.showPromptForBirthday
      }
    }

    return {
      body: this.t("Thanks for sharing"),
      nextStep: this.checkAgeThresholds
    }
  }

  @step.logState
  checkAgeThresholds(d: IStepData<State>): IStepResult {
    const eligibleIAPTs = this.getEligibleIAPTSByAgeThreshold(d.state)
    if (!eligibleIAPTs.length) {
      this.setUnderAged(d.state, true)
      d.state.presetFlowResult = EligibilityPresetFlowResults.UNDERAGE
      return { nextStep: this.end }
    }
    d.state.isUnderAged = undefined
    d.state.isEligible = undefined
    d.state.signPostToManualReferral = undefined
    d.state.presetFlowResult = EligibilityPresetFlowResults.ELIGIBLE
    return { nextStep: this.end }
  }

  /** Generic handlers */
  getContext(state: State): Record<string, any> {
    return {
      ...this.rootStore.configStore,
      name: this.getName(state)
    }
  }
}

export default class CollectDateOfBirthDialogue extends AdHocDialogue<
  State,
  CollectDateOfBirthScript
> {
  static id = DialogueIDs.CollectDateOfBirth
  readonly name: string = "CollectDateOfBirthDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: ICollectDateOfBirthSettings
  ) {
    super(
      CollectDateOfBirthDialogue.id,
      new CollectDateOfBirthScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
