import {
  ARMED_FORCES_VIEWS_MIND,
  DISABILITY_VIEWS_MIND,
  ETHNICITY_VIEWS_MIND,
  GenderBirthAssigned,
  GENDER_VIEWS_MIND,
  LTC_VIEWS_MIND,
  PERINATAL_VIEWS_MIND,
  RELIGION_VIEWS_MIND,
  SEXUALITY_VIEWS_MIND,
  LANGUAGE_VIEWS_MIND,
  NATIONALITY_VIEWS_MIND
} from "@limbic/types"

/**
 * not used
 */
export const ltcs: Record<string, LTC_VIEWS_MIND | undefined> = {
  Asthma: "ASTHMA",
  Cancer: "CANCER",
  "Chronic Pain": "CHRONIC_PAIN",
  Diabetes: "DIABETES",
  Dementia: "DEMENTIA",
  Epilepsy: "EPILEPSY",
  "Heart disease": "HEART_DISEASE",
  "Chronic Muscular Skeletal": "MUSCULOSKELETAL",
  "Respiratory disease": "RESPIRATORY",
  "Skin Condition including Eczema": "SKIN",
  Other: "NOT_LISTED"
}

export const sexualities: Record<string, SEXUALITY_VIEWS_MIND> = {
  Heterosexual: "HETEROSEXUAL",
  "Lesbian or Gay": "HOMOSEXUAL",
  Bisexual: "BISEXUAL",
  Other: "NOT_LISTED",
  "Do not wish to say": "NOT_ANSWERED"
}

export const genders: Record<string, GENDER_VIEWS_MIND> = {
  "Male (including trans man)": "MALE",
  "Female (including trans woman)": "FEMALE",
  "Non-binary": "NON_BINARY",
  Other: "NOT_LISTED",
  "I don't want to say": "NOT_ANSWERED"
}

export const sameGenderAsBirth: Record<string, GenderBirthAssigned> = {
  Yes: "YES",
  No: "NO",
  "I don't want to say": "NOT_ANSWERED"
}

export const ethnicities: Record<string, ETHNICITY_VIEWS_MIND> = {
  "White - British": "WHITE_BRITISH",
  "White - Irish": "WHITE_IRISH",
  "White - Eastern European": "WHITE_EASTERN_EUROPEAN",
  "White - Any other White background": "WHITE_OTHER",
  "Mixed - White and Black Caribbean": "MIXED_WHITE_BLACK_CARIBBEAN",
  "Mixed - White and Black African": "MIXED_WHITE_BLACK_AFRICAN",
  "Mixed - White and Asian": "MIXED_WHITE_ASIAN",
  "Mixed - Any other mixed background": "MIXED_OTHER",
  "Mixed - Black African and East Asian": "MIXED_BLACK_AFRICAN_EAST_ASIAN",
  "Mixed - Black African and South Asian": "MIXED_BLACK_AFRICAN_SOUTH_ASIAN",
  "Mixed - Black Caribbean and East Asian": "MIXED_BLACK_CARIBBEAN_EAST_ASIAN",
  "Mixed - Black Caribbean and South Asian": "MIXED_BLACK_CARIBBEAN_SOUTH_ASIAN",
  "Asian or Asian British - Indian": "ASIAN_BRITISH_INDIAN",
  "Asian or Asian British - Pakistani": "ASIAN_BRITISH_PAKISTANI",
  "Asian or Asian British - Bangladeshi": "ASIAN_BRITISH_BANGLADESHI",
  "Asian or Asian British - Any other Asian background": "ASIAN_OTHER",
  "Asian or Asian British - Japanese": "ASIAN_BRITISH_JAPANESE",
  "Asian or Asian British - Chinese": "ASIAN_BRITISH_CHINESE",
  "Black or Black British - Caribbean": "BLACK_BRITISH_CARIBBEAN",
  "Black or Black British - African": "BLACK_BRITISH_AFRICAN",
  "Black or Black British - Any other Black background": "BLACK_OTHER",
  Chinese: "CHINESE",
  "Gipsy or Traveller": "GYPSY",
  Arab: "ARAB",
  "Other Ethnic Groups - Any other ethnic group": "OTHER",
  "Not Stated": "NOT_ANSWERED",
  "Not known": "UNKNOWN"
}

export const exArmedForces: Record<string, ARMED_FORCES_VIEWS_MIND> = {
  "Yes - ex services": "YES_EX",
  "Yes - currently serving": "YES",
  "Dependant on ex-serving member": "DEPENDENT",
  No: "NO",
  "Not stated (Person asked but declined to provide a response)": "NOT_ANSWERED"
}

export const disabilities: Record<string, DISABILITY_VIEWS_MIND> = {
  "Autistic spectrum disorder": "AUTISM",
  "Behaviour and Emotional": "BEHAVIOUR",
  Hearing: "HEARING",
  "Manual Dexterity": "MANUAL",
  "Memory or ability to concentrate, learn or understand (Learning Disability)": "LEARNING",
  "Mobility and Gross Motor": "MOTOR",
  "Perception of Physical Danger": "PERCEPTION",
  "Personal, Self Care and Continence": "SELF_CARE",
  "Progressive Conditions and Physical Health (such as HIV, cancer, multiple sclerosis, fits, etc)":
    "PROGRESSIVE",
  Sight: "SIGHT",
  Speech: "SPEECH",
  Other: "NOT_LISTED",
  "Do not wish to say": "NOT_ANSWERED"
}

/**
 * not used
 */
export const religions: Record<string, RELIGION_VIEWS_MIND> = {
  "(None)": "NONE",
  "Do not wish to say": "DECLINE_TO_RESPOND",
  Agnostic: "AGNOSTIC",
  Bahai: "BAHAI",
  Buddhist: "BUDDHIST",
  Christian: "CHRISTIAN",
  Hindu: "HINDU",
  Jain: "JAIN",
  Jewish: "JEWISH",
  Muslim: "MUSLIM",
  Pagan: "PAGAN",
  Sikh: "SIKH",
  Zoroastrian: "ZOROASTRIAN",
  Other: "OTHER",
  "Patient Religion Unknown": "UNKNOWN"
}

/**
 * not used
 */
export const perinatalStatuses: Record<string, PERINATAL_VIEWS_MIND> = {
  "None apply": "NONE",
  Pregnant: "PREGNANT",
  "Child under 1": "CHILD_UNDER_1",
  "Do not wish to say": "NOT_ANSWERED"
}

export const languages: Record<string, LANGUAGE_VIEWS_MIND> = {
  English: "ENGLISH",
  Welsh: "WELSH"
}

export const nationalities: Record<string, NATIONALITY_VIEWS_MIND> = {
  English: "ENGLAND",
  Scottish: "SCOTLAND",
  "Northern Irish": "NORTHERN_IRELAND",
  Welsh: "WALES",
  British: "BRITAIN",
  Other: "NOT_LISTED"
}

const form = {
  filled_by: "API",
  perinatalStatuses: Object.keys(perinatalStatuses),
  sexualities: Object.keys(sexualities),
  genders: Object.keys(genders),
  sameGenderAsBirth: Object.keys(sameGenderAsBirth),
  ethnicities: Object.keys(ethnicities),
  exArmedForces: Object.keys(exArmedForces),
  disabilities: Object.keys(disabilities),
  LTCs: Object.keys(ltcs),
  religions: Object.keys(religions),
  languages: Object.keys(languages),
  nationalities: Object.keys(nationalities)
}

export default form
