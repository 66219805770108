export type IAppointmentResponse = {
  localsessionid: string
  agencyid: string
  sessiontype: string
  contacttype: string
  name: string
  startdate: string
  starttime: string
  duration: string
  cancelled: string
  activity: string
  notes: string | null
  leadstaff: LeadStaffResponse
  venue: VenueResponse
  created: string
  createdby: string
  updated: string
  updatedby: string
  reasonforcancellation: string | null
  stafflanguages: string
  datetime_start: string
  datetime_end: string
}

export type LeadStaffResponse = {
  id: string
  nicename: string
  forename: string
  surname: string
  languagues: string[]
}

export type VenueResponse = {
  id: string
  name: string
  buildingnameno: string
  street: string
  town: string
  county: string
  postcode: string
  lsoa: string
  ward: string
  ladcua: string
}

export enum IAppointmentStatus {
  Success = "SUCCESS",
  RequestFailed = "REQUEST_FAILED",
  NoInternetConnection = "NO_INTERNET_CONNECTION"
}

export type IAppointmentMind = {
  date: string
  timeslots: ITimeslot[]
}

export type ITimeslot = {
  sessionID: string
  date: string
  startTime: string
  endTime: string
  agencyName: string
}

export type IAppointmentBookResponse = {
  success: boolean
  id: string
  errors: string[]
}
