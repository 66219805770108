import BaseScript, { BaseScriptState } from "../../BaseScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"

type State = BaseScriptState
export type SuitableServicesSignpostScriptState = State

export abstract class SuitableServicesSignpostScript extends BaseScript<State> {
  /** Script Steps */

  @step
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.sayWeDontSupportYourArea }
  }

  abstract sayWeDontSupportYourArea(d: IStepData<State>): IStepResult

  @step.logState
  promptThanksOK(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("promptThanksOK"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Okay"), value: "okay" },
          { body: this.t("Thanks"), value: "thanks" }
        ]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleThanksOK
    }
  }

  @step
  handleThanksOK(d: IStepData<State, "okay" | "thanks">): IStepResult {
    return {
      body: this.t(d.response === "thanks" ? "You're welcome" : "Great"),
      nextStep: this.sayEndingMessage
    }
  }

  @step.logState
  sayEndingMessage(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t(
        [
          "I'm really glad you took this important step towards better mental health",
          "Well done {name}!",
          "And good luck"
        ],
        { name }
      ),
      nextStep: this.end,
      clearStack: true
    }
  }
}
