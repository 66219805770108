import IPostcode from "../../../models/IPostcode"
import NetworkError from "../../../models/NetworkError"
import Logger from "../../../utils/Logger"
import encodeURLParams from "../../../utils/encodeURLParams"
import errorMessage from "../../../utils/parseErrorMessage"
import delay from "../../../utils/delay"
import { IGetAddressStatus } from "../../../models/IGetAddress"
import isOnline from "is-online"

// const API_KEY = "PCWM5-X57DP-J7GX6-GMGYG"
// const BASE_URL = "https://ws.postcoder.com/pcw"
const API_KEY = "2rGC51XG9UO9hPW72lTDgw28712"
const BASE_URL = "https://api.getaddress.io/find"
const TOTAL_RETRIES = 3
const headers = new Headers()
headers.set("Content-Type", "application/json")
headers.set("Accept", "application/json")

interface IServerAddress {
  formatted_address: string[]
  thoroughfare: string
  building_name: string
  sub_building_name: string
  sub_building_number: string
  building_number: string
  line_1: string
  line_2: string
  line_3: string
  line_4: string
  locality: string
  town_or_city: string
  county: string
  district: string
  country: string
}

interface IServerAddressResponse {
  postcode: string
  latitude: number
  longitude: number
  addresses: IServerAddress[]
  error?: string
  code?: string
}

export interface IAddress {
  address: string
  address2?: string
  city: string
  county: string
}

export default async function getAddressesByPostcode(
  p?: IPostcode
): Promise<[IAddress[] | undefined, IGetAddressStatus]> {
  if (!p?.postcode) {
    return [[], IGetAddressStatus.Success]
  }
  const params = encodeURLParams({ "api-key": API_KEY, expand: true })
  const url = `${BASE_URL}/${encodeURIComponent(p.postcode)}?${params}`
  const [addresses, addressesStatus] = await get(url)
  if (addressesStatus === IGetAddressStatus.Success) {
    if (addresses && !addresses?.length) return [[], addressesStatus]
    if (addresses) return [addresses?.map(transformAddress), addressesStatus]
  }
  return [undefined, addressesStatus]
}

function transformAddress(serverAddress: IServerAddress): IAddress {
  return {
    address: serverAddress.line_1,
    address2: [serverAddress.line_2, serverAddress.line_3, serverAddress.line_4]
      .filter(Boolean)
      .join(", "),
    city: serverAddress.town_or_city,
    county: serverAddress.county
  }
}

export async function get(
  path: string,
  retry = 0
): Promise<[IServerAddress[] | undefined, IGetAddressStatus]> {
  try {
    const hasConnection = await isOnline()
    if (!hasConnection) {
      return [undefined, IGetAddressStatus.NoInternetConnection]
    }

    const method = "GET"
    const response = await fetch(path, { method, headers })

    if (!response.ok) {
      const text = await response.text()
      const message = errorMessage(response, { message: text })
      throw new NetworkError(String(response.status), message)
    }

    const data: IServerAddressResponse = await response.json()
    if (data?.error) {
      throw new NetworkError(data?.code, data?.error)
    }

    // 👇 Make sure that addresses is not undefined or empty
    // to prevent from the .sort below from failing
    const addresses = data.addresses && data.addresses.length ? data.addresses : []

    const sorted = addresses.sort(function (a, b) {
      return Number(a.building_number) - Number(b.building_number)
    })

    return [sorted, IGetAddressStatus.Success]
  } catch (e) {
    if (retry < TOTAL_RETRIES) {
      Logger.getInstance().message("getAddressesByPostcode Retry")
      await delay(3 * retry || 1.5)
      return await get(path, retry + 1)
    }
    Logger.getInstance().exception(e, "getAddressesByPostcode get failed")
    return [undefined, IGetAddressStatus.RequestFailed]
  }
}
