import "./initLogger"
import loadDevTools from "../dev-tools/load"
import { init } from "./init"

declare let window: WindowWithLimbicNameSpace

export interface WindowWithLimbicNameSpace extends Window {
  LimbicChatbot: {
    onStart?(initFn: typeof init): void
    init: typeof init
    initialized: boolean
  }
}

loadDevTools(() => {
  if (!window.LimbicChatbot) {
    window.LimbicChatbot = {
      init,
      initialized: false
    }
  }

  /**
   * the `onStart` method is to be added externally - for example
   * in the HTML where the chatbot would be embedded. This is so
   * that people can declare an `onStart` function before they
   * even embed the chatbot (for example in cases where they
   * inject the bot as a deferred script) so that the chatbot
   * can use it to immediately trigger the init function whenever
   * the chatbot finishes loading (which is something they wouldn't
   * have control over, hence the need to shift the responsibility
   * of launching from the HTML to the actual bot itself)
   */
  window.LimbicChatbot.onStart && window.LimbicChatbot.onStart(init)
})
