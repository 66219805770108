import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { GetNameScriptState } from "./GetNameScript"
import { GetNameIAPTScript } from "./GetNameIAPTDialogue"
import { Titles } from "../../../models/Constants"

interface State extends GetNameScriptState {
  preferredName?: string
  nameTitle?: Titles
}

export type GetNameEssexScriptState = State

export class GetNameEssexScript extends GetNameIAPTScript {
  readonly name: string = "GetNameEssexScript"

  /** Script Steps */
  @step.logState
  askIsPreferredName(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t("Is {name} your preferred first name?", { name }),
      prompt: {
        id: this.getPromptId("askIsPreferredName"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleIsPreferredName
    }
  }

  @step.logStateAndResponse
  async handleIsPreferredName(d: IStepData<State, boolean>): Promise<IStepResult> {
    return {
      nextStep: d.response //
        ? this.sayNiceToMeetYou
        : this.sayPleaseGivePreferredName
    }
  }

  @step
  sayPleaseGivePreferredName(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("What would you like to be called?"),
      nextStep: this.showPromptForPreferredName
    }
  }

  @step.logState
  showPromptForPreferredName(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("showPromptForPreferredName"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handlePreferredNameWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>, script: GetNameEssexScript) => {
    const preferredName = d.response?.trim()
    d.state.preferredName = preferredName
    script.rootStore.applicationStore.setPreferredName(preferredName)
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    getNextStep: (s: GetNameEssexScript) => s.sayPleaseGivePreferredName
  })
  async handlePreferredNameWithCrisis(_d: IStepData<State, string>): Promise<IStepResult> {
    return { nextStep: this.checkPreferredName }
  }

  @step.logState
  async checkPreferredName(d: IStepData<State>): Promise<IStepResult> {
    if (!d.state.preferredName || d.state.preferredName.trim() === "") {
      return { nextStep: this.sayPleaseGivePreferredName }
    }
    return { nextStep: this.sayNiceToMeetYou }
  }

  @step
  sayNiceToMeetYou(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t("Nice to meet you {name}", { name }),
      prompt: {
        id: this.getPromptId("sayNiceToMeetYou"),
        type: "inlinePicker",
        choices: [{ body: this.t("Nice to meet you too") }]
      },
      nextStep: this.askHowWouldYouLikeToBeAddressed
    }
  }

  @step.logState
  askHowWouldYouLikeToBeAddressed(d: IStepData<State>): IStepResult {
    const titles = this.getTitles()
    if (!titles?.length) {
      this.logBreadcrumb("TITLES NOT FOUND", d.state, { titles })
      this.logMessage("TITLES NOT FOUND")
      return { nextStep: this.end }
    }

    return {
      body: this.t("And how would you like to be addressed?"),
      prompt: {
        id: this.getPromptId("askHowWouldYouLikeToBeAddressed"),
        type: "inlinePicker",
        choices: titles.map(title => ({ body: this.t(title), value: title })),
        isUndoAble: true
      },
      nextStep: this.handleHowWouldYouLikeToBeAddressed
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, Titles>) => {
    d.state.nameTitle = d.response
  })
  handleHowWouldYouLikeToBeAddressed(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.end }
  }

  /** Generic Handlers */

  async onCheckFullName(_state: State): Promise<IStepResult> {
    return { nextStep: this.askIsPreferredName }
  }

  getTitles(): Titles[] {
    return [
      Titles.MR,
      Titles.MRS,
      Titles.MISS,
      Titles.MS,
      Titles.DR,
      Titles.REV,
      Titles.PROF,
      Titles.MX
    ]
  }
}

export default class GetNameEssexDialogue extends Dialogue<State> {
  static id = DialogueIDs.GetNameEssex
  readonly name: string = "GetNameEssexDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GetNameEssexDialogue.id, new GetNameEssexScript(), state, snapshot)
  }
}
