/**
 * Load the dev tools by dynamically importing them. This is done to avoid
 * including the dev tools in the production bundle.
 * @param callback The callback to call after the dev tools have been loaded.
 */
export default function load(callback: () => void): void {
  if (process.env.REACT_APP__DEV_TOOLS__ === "enabled") {
    import("./dev-tools").then(mod => mod.install()).finally(callback)
  } else {
    callback()
  }
}
