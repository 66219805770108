import { IAPTGPMap, IAPTIDs } from "../../models/IIAPTService"

export const iaptGPsMapVHG: IAPTGPMap = {
  C84065: IAPTIDs.VHG_NOTTS,
  C84037: IAPTIDs.VHG_NOTTS,
  C84679: IAPTIDs.VHG_NOTTS,
  C84067: IAPTIDs.VHG_NOTTS,
  C84091: IAPTIDs.VHG_NOTTS,
  C84693: IAPTIDs.VHG_NOTTS,
  Y05369: IAPTIDs.VHG_NOTTS,
  C84009: IAPTIDs.VHG_NOTTS,
  C84101: IAPTIDs.VHG_NOTTS,
  C84704: IAPTIDs.VHG_NOTTS,
  C84017: IAPTIDs.VHG_NOTTS,
  Y06356: IAPTIDs.VHG_NOTTS,
  C84688: IAPTIDs.VHG_NOTTS,
  C84123: IAPTIDs.VHG_NOTTS,
  C84112: IAPTIDs.VHG_NOTTS,
  C84092: IAPTIDs.VHG_NOTTS,
  C84077: IAPTIDs.VHG_NOTTS,
  Y06792: IAPTIDs.VHG_NOTTS,
  C84710: IAPTIDs.VHG_NOTTS,
  C84605: IAPTIDs.VHG_NOTTS,
  C84120: IAPTIDs.VHG_NOTTS,
  C84034: IAPTIDs.VHG_NOTTS,
  C84020: IAPTIDs.VHG_NOTTS,
  C84046: IAPTIDs.VHG_NOTTS,
  C84045: IAPTIDs.VHG_NOTTS,
  C84035: IAPTIDs.VHG_NOTTS,
  C84066: IAPTIDs.VHG_NOTTS,
  C84044: IAPTIDs.VHG_NOTTS,
  C84039: IAPTIDs.VHG_NOTTS,
  C84025: IAPTIDs.VHG_NOTTS,
  C84032: IAPTIDs.VHG_NOTTS,
  C84011: IAPTIDs.VHG_NOTTS,
  C84105: IAPTIDs.VHG_NOTTS,
  C84074: IAPTIDs.VHG_NOTTS,
  C84018: IAPTIDs.VHG_NOTTS,
  C84036: IAPTIDs.VHG_NOTTS,
  C84019: IAPTIDs.VHG_NOTTS,
  C84667: IAPTIDs.VHG_NOTTS,
  Y03124: IAPTIDs.VHG_NOTTS,
  C84063: IAPTIDs.VHG_NOTTS,
  C84104: IAPTIDs.VHG_NOTTS,
  C84676: IAPTIDs.VHG_NOTTS,
  C84624: IAPTIDs.VHG_NOTTS,
  C84629: IAPTIDs.VHG_NOTTS,
  C84705: IAPTIDs.VHG_NOTTS,
  C84055: IAPTIDs.VHG_NOTTS,
  C84691: IAPTIDs.VHG_NOTTS,
  C84656: IAPTIDs.VHG_NOTTS,
  C84660: IAPTIDs.VHG_NOTTS,
  C84078: IAPTIDs.VHG_NOTTS,
  C84654: IAPTIDs.VHG_NOTTS,
  C84081: IAPTIDs.VHG_NOTTS,
  C84613: IAPTIDs.VHG_NOTTS,
  C84048: IAPTIDs.VHG_NOTTS,
  C84013: IAPTIDs.VHG_NOTTS,
  C84061: IAPTIDs.VHG_NOTTS,
  Y05690: IAPTIDs.VHG_NOTTS,
  C84076: IAPTIDs.VHG_NOTTS,
  C84001: IAPTIDs.VHG_NOTTS,
  C84043: IAPTIDs.VHG_NOTTS,
  C84694: IAPTIDs.VHG_NOTTS,
  C84029: IAPTIDs.VHG_NOTTS,
  C84140: IAPTIDs.VHG_NOTTS,
  C84113: IAPTIDs.VHG_NOTTS,
  C84144: IAPTIDs.VHG_NOTTS,
  C84658: IAPTIDs.VHG_NOTTS,
  C84116: IAPTIDs.VHG_NOTTS,
  C84021: IAPTIDs.VHG_NOTTS,
  C84106: IAPTIDs.VHG_NOTTS,
  C84090: IAPTIDs.VHG_NOTTS,
  C84024: IAPTIDs.VHG_NOTTS,
  C84131: IAPTIDs.VHG_NOTTS,
  C84692: IAPTIDs.VHG_NOTTS,
  C84095: IAPTIDs.VHG_NOTTS,
  C84016: IAPTIDs.VHG_NOTTS,
  C84051: IAPTIDs.VHG_NOTTS,
  C82040: IAPTIDs.VHG_NOTTS,
  C84064: IAPTIDs.VHG_NOTTS,
  Y02847: IAPTIDs.VHG_NOTTS,
  C84133: IAPTIDs.VHG_NOTTS,
  C84115: IAPTIDs.VHG_NOTTS,
  C84057: IAPTIDs.VHG_NOTTS,
  C84135: IAPTIDs.VHG_NOTTS,
  C84084: IAPTIDs.VHG_NOTTS,
  C84096: IAPTIDs.VHG_NOTTS,
  C84117: IAPTIDs.VHG_NOTTS,
  C84087: IAPTIDs.VHG_NOTTS,
  C84680: IAPTIDs.VHG_NOTTS,
  C84129: IAPTIDs.VHG_NOTTS,
  C84127: IAPTIDs.VHG_NOTTS,
  C84060: IAPTIDs.VHG_NOTTS,
  C84717: IAPTIDs.VHG_NOTTS,
  C84094: IAPTIDs.VHG_NOTTS,
  C84069: IAPTIDs.VHG_NOTTS,
  C84637: IAPTIDs.VHG_NOTTS,
  C84042: IAPTIDs.VHG_NOTTS,
  C84142: IAPTIDs.VHG_NOTTS,
  Y06581: IAPTIDs.VHG_NOTTS,
  C84682: IAPTIDs.VHG_NOTTS,
  Y05976: IAPTIDs.VHG_NOTTS,
  C84059: IAPTIDs.VHG_NOTTS,
  C84628: IAPTIDs.VHG_NOTTS,
  C84114: IAPTIDs.VHG_NOTTS,
  Y05622: IAPTIDs.VHG_NOTTS,
  C84049: IAPTIDs.VHG_NOTTS,
  C84138: IAPTIDs.VHG_NOTTS,
  C84004: IAPTIDs.VHG_NOTTS,
  C84086: IAPTIDs.VHG_NOTTS,
  C84031: IAPTIDs.VHG_NOTTS,
  C84136: IAPTIDs.VHG_NOTTS,
  C84026: IAPTIDs.VHG_NOTTS,
  C84714: IAPTIDs.VHG_NOTTS,
  C84695: IAPTIDs.VHG_NOTTS,
  C84047: IAPTIDs.VHG_NOTTS,
  C84103: IAPTIDs.VHG_NOTTS,
  C84703: IAPTIDs.VHG_NOTTS,
  C84646: IAPTIDs.VHG_NOTTS,
  C84107: IAPTIDs.VHG_NOTTS,
  C84080: IAPTIDs.VHG_NOTTS,
  C84151: IAPTIDs.VHG_NOTTS,
  C84030: IAPTIDs.VHG_NOTTS,
  Y00026: IAPTIDs.VHG_NOTTS,
  C84028: IAPTIDs.VHG_NOTTS,
  C84023: IAPTIDs.VHG_NOTTS,
  C84072: IAPTIDs.VHG_NOTTS,
  C84683: IAPTIDs.VHG_NOTTS,
  C84053: IAPTIDs.VHG_NOTTS,
  C84010: IAPTIDs.VHG_NOTTS,
  C84619: IAPTIDs.VHG_NOTTS,
  C84008: IAPTIDs.VHG_NOTTS,
  C84150: IAPTIDs.VHG_NOTTS,
  C84085: IAPTIDs.VHG_NOTTS,
  C84005: IAPTIDs.VHG_NOTTS,
  C84664: IAPTIDs.VHG_NOTTS,
  C84621: IAPTIDs.VHG_NOTTS,
  C84696: IAPTIDs.VHG_NOTTS,
  C84033: IAPTIDs.VHG_NOTTS,
  Y02833: IAPTIDs.VHG_NOTTS,
  Y05346: IAPTIDs.VHG_NOTTS,
  Y01282: IAPTIDs.VHG_NOTTS,
  C84124: IAPTIDs.VHG_NOTTS,
  Y06443: IAPTIDs.VHG_NOTTS,
  C84012: IAPTIDs.VHG_NOTTS,
  C84122: IAPTIDs.VHG_NOTTS,
  C84014: IAPTIDs.VHG_NOTTS
}
