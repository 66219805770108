import React from "react"
import "./ImageBubble.scss"
import { IBubbleProps } from "./IBubbleProps"
import BotBubble from "./BotBubble"

interface Props extends IBubbleProps {
  image?: string
  description?: string
}

export default function ImageBubble(props: Props): JSX.Element {
  const { image, description, ...restProps } = props
  return (
    <BotBubble {...restProps}>
      <div className="lb-image-bubble-container">
        <img
          src={image}
          className="lb-image-bubble-img"
          title={description}
          aria-label={description}
          alt={description ?? "Inspirational Quote"}
        />
      </div>
    </BotBubble>
  )
}
