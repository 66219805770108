import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import {
  TreatmentOptionsIAPTScript,
  TreatmentOptionsIAPTScriptState
} from "./TreatmentOptionsIAPTDialogue"

type State = TreatmentOptionsIAPTScriptState

export class TreatmentOptionsLWCScript extends TreatmentOptionsIAPTScript {
  readonly name: string = "TreatmentOptionsLWCScript"

  /** Generic Handlers */

  getCustomReferralType(state: State): string | undefined {
    if (state.requiresInterpreter) return "Extended Assessment"
  }
}

/* istanbul ignore next */
export default class TreatmentOptionsLWCDialogue extends Dialogue<State> {
  static id = DialogueIDs.TreatmentOptionsLWC
  readonly name: string = "TreatmentOptionsLWCDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(TreatmentOptionsLWCDialogue.id, new TreatmentOptionsLWCScript(), state, snapshot)
  }
}
