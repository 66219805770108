import GoodbyeScript, { GoodbyeScriptState } from "./GoodbyeScript"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type {
  IStepData,
  IStepResult,
  StepResultBodyType
} from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import { ITimeslot } from "../../../models/IAppointmentMind"

interface State extends GoodbyeScriptState {
  appointmentMind?: ITimeslot
}

export type GoodbyeMindScriptState = State

export class GoodbyeMindScript extends GoodbyeScript {
  readonly name: string = "GoodbyeMindScript"

  /** Script Steps */

  // This is a replacement for sayFavoriteQuotes
  @step
  async sayGoodBye(d: IStepData<State>): Promise<IStepResult> {
    void this.referralStore.updateReferral({
      isHelpful: d.state.isHelpful,
      improvementSuggestion: d.state.improvementSuggestion
    })
    this.referralStore.stopPinging()
    const name = this.getName(d.state)

    return {
      body: this.t(
        [
          "Well done for taking this important step towards better mental health",
          "Goodbye {name} 👋"
        ],
        { name }
      ),
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onHandleRecapMessage(
    _state: State,
    body: StepResultBodyType[],
    recapMessage: string | void
  ): Promise<IStepResult | void> {
    const isCrisis = this.clinicalStore.isCrisis
    if (!body.length) {
      return { nextStep: isCrisis ? this.sayGoodBye : this.askFeedback }
    }

    const choices: any[] = [
      { body: this.t("Okay") },
      recapMessage && { body: this.t("I understand") }
    ] //
      .filter(Boolean)

    return {
      body,
      prompt: {
        id: this.getPromptId("recap"),
        trackResponse: true,
        type: "inlinePicker",
        choices
      },
      nextStep: isCrisis ? this.sayGoodBye : this.askFeedback
    }
  }

  async getIntroMessage(state: State): Promise<string | void> {
    const isCrisis = this.clinicalStore.isCrisis
    const name = this.getName(state)
    return this.t(
      isCrisis
        ? "Thank you for sharing this information with me {name}"
        : "It's been a pleasure getting to know you {name}",
      { name }
    )
  }

  // Changing order of recap messages
  async getRecapMessage(state: State): Promise<string | void> {
    const recaps: string[] = []
    const referredYouMessage = await this.getReferredYouMessage(state)
    referredYouMessage && recaps.push(referredYouMessage)

    const highRiskContactMessage = await this.getHighRiskContactMessage(state)
    highRiskContactMessage && recaps.push(highRiskContactMessage)

    const moderateRiskContactMessage = await this.getModerateRiskContactMessage(state)
    moderateRiskContactMessage && recaps.push(moderateRiskContactMessage)

    const adminWillCallMessage = await this.getAdminWillCallMessage(state)
    adminWillCallMessage && recaps.push(adminWillCallMessage)

    const appointmentMessage = await this.getAppointmentMessage(state)
    appointmentMessage && recaps.push(appointmentMessage)

    const treatmentMessage = await this.getTreatmentMessage(state)
    treatmentMessage && recaps.push(treatmentMessage)

    const formattedRecap = recaps.length > 1 ? recaps.map((i, idx) => `${idx + 1}. ${i}`) : recaps
    const recapString = formattedRecap.join("\n")

    if (recapString?.length) {
      const justToRecap = this.t("Just to recap")
      return `${justToRecap}:\n\n${recapString}`
    }
  }

  async getReferredYouMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted) {
      const organisationName = this.rootStore.configStore.organisationName
      return this.t(
        state.isIdleSubmitted //
          ? "It looks like there hasn’t been any activity for some time so I've referred you to {organisationName}"
          : "I've referred you to {organisationName}",
        { organisationName }
      )
    }
  }

  async getAdminWillCallMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted && !state.appointmentMind?.date) {
      return this.t(
        "Someone from the service team will be in contact within 28 days to book your appointment with a relevant practitioner"
      )
    }
  }

  async getHighRiskContactMessage(_state: State): Promise<string | void> {
    const organisationName = this.rootStore.configStore.organisationName
    if (this.clinicalStore.isHighRisk) {
      return this.t(
        "Please remember, {organisationName} is not an emergency response service. If you need urgent help you are encouraged to contact 999 or NHS 111 and select Option 2. You can also contact the Samaritans on 116123 to talk through how you are feeling",
        { organisationName }
      )
    }
  }

  async getModerateRiskContactMessage(_state: State): Promise<string | void> {
    const organisationName = this.rootStore.configStore.organisationName
    if (this.clinicalStore.isModerateRisk) {
      return this.t(
        "Please remember, {organisationName} is not an emergency response service. If you need urgent help you are encouraged to contact 999 or NHS 111 and select Option 2. You can also contact the Samaritans on 116123 to talk through how you are feeling",
        { organisationName }
      )
    }
  }

  async getAppointmentMessage(state: State): Promise<string | void> {
    if (state.appointmentMind?.date) {
      return this.t(
        "The following appointment was booked:\n{date}, {startTime}-{endTime}\n{agencyName}",
        {
          date: state.appointmentMind?.date,
          startTime: state.appointmentMind?.startTime,
          endTime: state.appointmentMind?.endTime,
          agencyName: state.appointmentMind?.agencyName
        }
      )
    }
  }
}

/* istanbul ignore next */
export default class GoodbyeMindDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeMind
  readonly name: string = "GoodbyeMindDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeMindDialogue.id, new GoodbyeMindScript(), state, snapshot)
  }
}
