import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import { RiskLevelReason } from "../../../models/Constants"
import sendRiskEmail from "../../../backend/api/limbic/sendRiskEmail"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

const HEALIX_RISK_EMAILS =
  process.env.REACT_APP_BACKEND_ENV === "production"
    ? ["healix@mindright.co.uk"]
    : ["andreas@limbic.ai", "john@limbic.ai"]

type State = RiskPathwayScriptState
export type RiskPathwayHealixState = State
export class RiskPathwayHealixScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayHealixScript"

  /** Script Steps */

  @step.logState
  step1(d: IStepData<State>): IStepResult {
    return { nextStep: this.askCanYouKeepYourselfSafe }
  }

  @step.logState
  askCanYouKeepYourselfSafe(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Are you able to keep yourself, and any dependants in your care, safe until your appointment (within 5 days time)?"
      ),
      prompt: {
        id: this.getPromptId("askCanYouKeepYourselfSafe"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askCanYouKeepYourselfSafe"
      },
      nextStep: this.handleCanYouKeepYourselfSafe
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.saveResponse<State>(
    "10",
    "Are you able to keep yourself, and any dependants in your care, safe until your appointment (within 5 working days)?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.canKeepSelfSafe = d.response
  })
  handleCanYouKeepYourselfSafe(d: IStepData<State>): IStepResult {
    if (!d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.CANNOT_KEEP_SELF_SAFE)
      return { nextStep: this.informRiskToService }
    }
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  async informRiskToService(d: IStepData<State>): Promise<IStepResult> {
    await this.sendRiskEmailToService(d.state)
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  sayCrisis(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const serviceName = this.rootStore.configStore.serviceName

    return {
      body: this.t(
        [
          "Sorry to hear that {name}",
          "It is normal for people to have thoughts of this nature at times",
          "However, this is not an emergency response service",
          "There are alternative services that are better placed to support you",
          "If you need urgent, life threatening medical help please call [999](tel:999)",
          "One of {serviceName}'s clinical team will contact you within 5 working days to check that you have referred yourself in to one of the crisis support services"
        ],
        { name, serviceName }
      ),
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("I understand") }, { body: this.t("Okay") }],
        dataPointsName: "sayCrisis"
      },
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onSaveRiskLevelAndReferralType(state: State): Promise<IStepResult> {
    return { nextStep: !state.canKeepSelfSafe ? this.sayCrisis : this.end }
  }

  async sendRiskEmailToService(state: State): Promise<void> {
    try {
      if (!this.clinicalStore.isRisk) return
      const emails = HEALIX_RISK_EMAILS || []
      if (emails?.length) {
        const text = this.createReferralEmail(state, true)
        await sendRiskEmail({
          to: emails,
          text
        })
      }
    } catch (e) {
      this.logException(e, "sendRiskEmailToService")
    }
  }
}

/* istanbul ignore next */
export default class RiskPathwayHealixDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayHealix
  readonly name: string = "RiskPathwayHealixDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(RiskPathwayHealixDialogue.id, new RiskPathwayHealixScript(), state, snapshot)
  }
}
