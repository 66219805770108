import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { PermissionsIAPTScript } from "./PermissionsIAPTDialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import type { PermissionsScriptState } from "./PermissionsScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

interface State extends PermissionsScriptState {
  consentDataShareGPAndNHS?: boolean
}
export type PermissionsMPFTScriptState = State

export class PermissionsMPFTScript extends PermissionsIAPTScript {
  readonly name: string = "PermissionsMPFTScript"

  /** Script Steps */

  @step.logState
  showTOSLinks(_d: IStepData<State>): IStepResult {
    const organisationName =
      this.rootStore.configStore.organisationName ?? this.rootStore.configStore.serviceName
    const organisationTerms = this.rootStore.configStore.organisationTerms ?? ""
    const organisationPhoneNumber = this.rootStore.configStore.organisationPhoneNumbers ?? ""
    return {
      body: [
        `To get you the best referral, I will need to share your answers with ${organisationName} (part of Midlands Partnership University NHS Foundation Trust (MPFT))`,
        "I want to reassure you that your details will be stored safely and kept confidential",
        "You can find all the details of the Limbic Terms of Service and Privacy Policy, and MPFT's Privacy Notice here:\n\n" +
          "Limbic [Terms of Service](https://www.limbic.ai/terms-of-use)\n" +
          "Limbic [Privacy Policy](https://www.limbic.ai/privacy)\n" +
          organisationTerms,
        `(If you don't wish to consent to the terms, you can call ${organisationName} on ${organisationPhoneNumber})`
      ],
      nextStep: this.promptIUnderstandTerms
    }
  }

  @step.logState
  promptIUnderstandTerms(_d: IStepData<State>): IStepResult {
    const organisationName =
      this.rootStore.configStore.organisationName ?? this.rootStore.configStore.serviceName

    return {
      prompt: {
        id: this.getPromptId("promptIUnderstandTerms"),
        trackResponse: true,
        // 👇 There seems to be a bug when there are two
        // consecutive checkbox questions, and you hit undo
        // the checkbox component crashes as it receives the
        // data of the previous checkbox and not the checkbox
        // that is loaded on Undo
        // Setting it to isUndoAble false for now
        isUndoAble: false,
        type: "checkbox",
        isRequired: true,
        options: [
          {
            body: `I confirm that I have read the MPFT Privacy Notice`,
            key: "agreeTerms",
            isRequired: true,
            initialValue: false
          },
          {
            body: `I understand my details will be shared with ${organisationName}`,
            key: `agreeDetailsShared`,
            isRequired: true,
            initialValue: false
          }
        ].filter(Boolean)
      },
      nextStep: this.handleIUnderstandTerms
    }
  }

  @step.logState
  askCreateReferralConsent(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        `Please note that once you have provided all the required information, this will automatically generate a referral to ${organisationName} and create a health record for you`
      ],
      prompt: {
        id: this.getPromptId("askCreateReferralConsent"),
        type: "inlinePicker",
        choices: [{ body: "I understand" }]
      },
      nextStep: this.sayReady
    }
  }

  /**
   * This is overridden to add one more step at
   * the end of the script to tell the user not
   * to share names or other personal information
   * in their free text responses
   */
  @step.logState
  sayReady(_d: IStepData<State>): IStepResult {
    return {
      body: ["Let's continue", "Ready?"],
      prompt: {
        id: this.getPromptId("sayReady"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Let's get started" }]
      },
      nextStep: this.end
    }
  }

  @step.logState
  sayDoNotSharePersonalInfoInFreeText(_d: IStepData<State>): IStepResult {
    return {
      body: "Also, please don't share any names or other personal data in your free text responses",
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onHandleIUnderstandTerms(state: State): Promise<IStepResult> {
    return { nextStep: this.askCreateReferralConsent }
  }
}

/* istanbul ignore next */
export default class PermissionsMPFTDialogue extends Dialogue<State> {
  static id = DialogueIDs.PermissionsMPFT
  readonly name: string = "PermissionsMPFTDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PermissionsMPFTDialogue.id, new PermissionsMPFTScript(), state, snapshot)
  }
}
