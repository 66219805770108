interface Data {
  message?: string
  description?: string
}

export default function errorMessage(response?: Response, data?: Data): string {
  const { status, statusText } = response || {}
  const { message, description } = data || {}
  return `${status || "-"} ${statusText || message || description || "Unknown Error"}`
}
