import { z, ZodSchema } from "zod"
import BaseScript, { BaseScriptState, BaseScriptStateSchema } from "../../BaseScript"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { TrackingEvents } from "../../../models/Constants"
import { IAppointmentStatus } from "../../../models/IAppointment"
import {
  getMindAppointments,
  reserveMindAppointment
} from "../../../backend/api/limbic/mindAppointments"
import { ITimeslot } from "../../../models/IAppointmentMind"
import { submitMindReferral } from "../../../backend/api/limbic/submitMindReferral"
import { SubmitReferralStatus } from "../../../models/SubmitReferral"

interface State extends BaseScriptState {
  retryReserveAppointment?: number
  retryBookAppointment?: number
  tempAppointment?: ITimeslot
  appointmentMind?: ITimeslot
  appointmentTime?: string
  assessmentPreference?: "telephone" | "digital"
  eligibilityRegion?: string
  isSelfReferrer?: boolean
}

export type BookAppointmentMindState = State

export const BookAppointmentMindStateSchema = BaseScriptStateSchema.extend({
  retryReserveAppointment: z.number().optional(),
  retryBookAppointment: z.number().optional(),
  tempAppointment: z.string().optional(),
  appointment: z.string().optional(),
  appointmentTimestamps: z.string().optional(),
  agencyId: z.string().optional()
})

export class BookAppointmentMindScript extends BaseScript<State> {
  readonly name: string = "BookAppointmentMindScript"

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    // if we are here, it means we have an eligibilityRegion,
    // but it doesn't hurt to check just in case
    if (d.state.isSelfReferrer && d.state?.eligibilityRegion) {
      return { nextStep: this.startAppointmentBooking }
    }
    return { nextStep: this.end }
  }

  @step.logState
  startAppointmentBooking(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("I'm going to put you in touch with a practitioner at Mind"),
      prompt: {
        id: this.getPromptId("startAppointmentBooking"),
        type: "inlinePicker",
        choices: [{ body: this.t("Okay"), value: true }]
      },
      nextStep: this.triggerReferralSubmission
    }
  }

  @step.logState
  async triggerReferralSubmission(d: IStepData<State>): Promise<IStepResult> {
    this.referralStore.setIdleSubmissionActive(false)
    const patientId = this.referralStore.patientId!
    const [isSubmitSuccessful, referralStatus] = await submitMindReferral(patientId)

    if (referralStatus === SubmitReferralStatus.NoInternetConnection) {
      return { nextStep: this.sayNoInternetConnectSubmitReferral }
    }

    if (!isSubmitSuccessful || referralStatus === SubmitReferralStatus.RequestFailed) {
      this.referralStore.setIdleSubmissionActive(true)
      return { nextStep: this.end }
    }

    d.state.retryBookAppointment = 0
    return { nextStep: this.askSelectAppointmentSlot }
  }

  @step.logState
  sayNoInternetConnectSubmitReferral(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Hmmm, it looks like you're not connected to the internet"),
      prompt: {
        id: this.getPromptId("sayNoInternetConnectSubmitReferral"),
        type: "inlinePicker",
        choices: [{ body: this.t("Try again") }],
        isUndoAble: false
      },
      nextStep: this.triggerReferralSubmission
    }
  }

  @step.logState
  @step.startTyping
  async askSelectAppointmentSlot(d: IStepData<State>): Promise<IStepResult> {
    d.state.retryReserveAppointment ??= 0
    const isFullScreen = this.rootStore.configStore.fullscreen
    const language = d.state.primaryLanguage?.toLowerCase() || "english"
    const region = d.state.eligibilityRegion!

    const [appointments, appointmentsStatus] = await getMindAppointments(language, region)

    if (appointmentsStatus === IAppointmentStatus.NoInternetConnection) {
      return { nextStep: this.sayNoInternetConnectionGetAppointments }
    }

    if (appointmentsStatus === IAppointmentStatus.RequestFailed) {
      return { nextStep: this.saySomethingWentWrongRetrievingAppointments }
    }

    if (appointments?.length === 0) {
      return { nextStep: this.saySomeoneWillCallYou }
    }

    this.track(TrackingEvents.APPOINTMENTS_PROVIDED)
    return {
      body: this.t([
        "Let's see all the available appointment slots",
        "Please choose a date and time that works best for you to have a telephone appointment with one of our team",
        "If you can't find an appointment slot that suits you, please click \"continue\" and you'll be added to our waitlist. A local Mind practitioner will be in touch within 28 days to arrange one with you",
        "Please use the arrows either side of the dates to view more available options"
      ]),
      prompt: {
        id: this.getPromptId("askSelectAppointmentSlot"),
        type: "appointmentMind",
        isFullScreen,
        appointments: appointments || [],
        hideDuration: true,
        isUndoAble: true
      },
      nextStep: this.handleSelectAppointmentSlot
    }
  }

  @step.logState
  sayNoInternetConnectionGetAppointments(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Hmmm, it looks like you're not connected to the internet"),
      prompt: {
        id: this.getPromptId("sayNoInternetConnectionGetAppointments"),
        type: "inlinePicker",
        choices: [{ body: this.t("Try again") }],
        isUndoAble: false
      },
      nextStep: this.askSelectAppointmentSlot
    }
  }

  @step.logState
  saySomethingWentWrongRetrievingAppointments(d: IStepData<State>): IStepResult {
    d.state.retryBookAppointment ??= 0
    d.state.retryBookAppointment = d.state.retryBookAppointment + 1

    if (d.state.retryBookAppointment === 2) {
      const serviceName = this.rootStore.configStore.serviceName
      const name = this.getName(d.state)
      return {
        body: this.t(
          [
            "Sorry {name}, we're encountering a persistent issue when trying to load the available appointments",
            "One of {serviceName}'s practitioners will call you to discuss this. The {serviceName} team will be in touch to organise a time and date for this call"
          ],
          { name, serviceName }
        ),
        nextStep: this.end
      }
    }
    return {
      body: this.t([
        "Hmmm... something went wrong while loading the available appointments",
        "Please try again"
      ]),
      prompt: {
        id: this.getPromptId("saySomethingWentWrongRetrievingAppointments"),
        type: "inlinePicker",
        choices: [{ body: this.t("Try again") }],
        isUndoAble: false
      },
      nextStep: this.askSelectAppointmentSlot
    }
  }

  @step.logState
  saySomeoneWillCallYou(_d: IStepData<State>): IStepResult {
    const serviceName = this.rootStore.configStore.serviceName
    return {
      body: this.t(
        "One of {serviceName}'s practitioners will call you to discuss this. The {serviceName} team will be in touch to organise a time and date for this call",
        { serviceName }
      ),
      nextStep: this.end
    }
  }

  @step.logStateAndResponse
  async handleSelectAppointmentSlot(d: IStepData<State, ITimeslot | false>): Promise<IStepResult> {
    if (d.response === false) return { nextStep: this.sayNoProblemAndClose }

    d.state.tempAppointment = d.response
    return { nextStep: this.askConfirmAppointment }
  }

  @step.logState
  askConfirmAppointment(d: IStepData<State>): IStepResult {
    const { date, startTime, agencyName } = d.state.tempAppointment!
    return {
      body: this.t(
        [
          "You have selected the following date and time",
          `${date} - ${startTime}`,
          "Over the phone with {agencyName}",
          "Is this correct?"
        ],
        { agencyName }
      ),
      prompt: {
        id: this.getPromptId("askConfirmAppointment"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No, let me change it"), value: false }
        ],
        isUndoAble: false
      },
      nextStep: this.handleConfirmAppointment
    }
  }

  @step.logState
  async handleConfirmAppointment(d: IStepData<State, boolean>): Promise<IStepResult> {
    if (d.response) {
      return { nextStep: this.doAppointmentReservation }
    }
    d.state.tempAppointment = undefined
    return { nextStep: this.askSelectAppointmentSlot }
  }

  @step.logState
  @step.startTyping
  async doAppointmentReservation(d: IStepData<State>): Promise<IStepResult> {
    const time = d.state.tempAppointment?.startTime
    d.state.appointmentTime = time
    this.referralStore.setCustomField("appointmentTime", time)
    const { startTime, endTime, date, sessionID } = d.state.tempAppointment!
    const patientID = d.state.patientId!
    const [appointment, status] = await reserveMindAppointment(sessionID, patientID)

    if (status === IAppointmentStatus.NoInternetConnection) {
      return { nextStep: this.sayNoInternetConnectionReserveAppointment }
    }

    if (appointment) {
      d.state.appointmentMind = d.state.tempAppointment
      this.referralStore.setCustomField("appointmentSet", true)
      this.track(TrackingEvents.APPOINTMENT_BOOKED)
      this.referralStore.addClinicalNote(`Appointment: ${startTime}-${endTime} on ${date}`)
      await this.referralStore.updateReferral({ sessionID })
      this.rootStore.clearUndoHistory()
      return { nextStep: this.end }
    }

    if (!appointment || status === IAppointmentStatus.RequestFailed) {
      this.track(TrackingEvents.APPOINTMENT_BOOKING_FAILED)
      d.state.tempAppointment = undefined
      d.state.retryReserveAppointment ??= 0
      d.state.retryReserveAppointment = d.state.retryReserveAppointment + 1
      if (d.state.retryReserveAppointment > 2) {
        return { nextStep: this.sayAppointmentBookingFailed }
      }
    }
    return {
      body: this.t([
        "Hmmm... something went wrong while booking your appointment",
        "Please try again"
      ]),
      nextStep: this.askSelectAppointmentSlot
    }
  }

  @step.logState
  sayNoInternetConnectionReserveAppointment(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Hmmm, it looks like you're not connected to the internet"),
      prompt: {
        id: this.getPromptId("sayNoInternetConnectionReserveAppointment"),
        type: "inlinePicker",
        choices: [{ body: this.t("Try again") }],
        isUndoAble: false
      },
      nextStep: this.doAppointmentReservation
    }
  }

  @step.logState
  sayAppointmentBookingFailed(d: IStepData<State>): IStepResult {
    const serviceName = this.rootStore.configStore.serviceName
    const name = this.getName(d.state)
    // This should never be the case but adding it as a safeguard 👇
    const hasAppointment = d.state.appointmentMind?.startTime
    return {
      body: this.t(
        [
          "Sorry {name}, we're encountering a persistent issue when trying to confirm your appointment booking",
          hasAppointment
            ? "Your referral has been submitted successfully"
            : "Your referral has been submitted successfully, so we'll ask one of the {serviceName} team to give you a call to organise your appointment"
        ],
        { name, serviceName }
      ),
      nextStep: this.end
    }
  }

  @step.logState
  sayNoProblemAndClose(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Okay"),
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return BookAppointmentMindStateSchema
  }
}

/* istanbul ignore next */
export default class BookAppointmentMindDialogue extends Dialogue<State> {
  static id = DialogueIDs.BookAppointmentMind
  readonly name: string = "BookAppointmentMindDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(BookAppointmentMindDialogue.id, new BookAppointmentMindScript(), state, snapshot)
  }
}
