import moment from "moment"
import { z, ZodSchema } from "zod"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import AssessmentIAPTScript, {
  AssessmentIAPTScriptState,
  AssessmentIAPTScriptStateSchema
} from "./AssessmentIAPTScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import {
  CollectMainIssueScriptState,
  ICollectMainIssueSettings
} from "../ad-hoc/CollectMainIssue/CollectMainIssueDialogue"
import { IStep } from "../../../backend/chatbot/models/IStep"

interface State extends AssessmentIAPTScriptState {
  assessmentPreference?: "telephone" | "digital"
}
export type AssessmentVitalityScriptState = State

export const AssessmentVitalityScriptStateSchema = AssessmentIAPTScriptStateSchema.extend({
  assessmentPreference: z.union([z.literal("telephone"), z.literal("digital")]).optional()
})

export class AssessmentVitalityScript extends AssessmentIAPTScript {
  readonly name: string = "AssessmentVitalityScript"

  /** Script Steps */
  @step.logState
  start(d: IStepData<State>): IStepResult {
    if (d.state.assessmentPreference === "telephone") {
      return { nextStep: this.end }
    }
    this.rootStore.applicationStore.setTotalProgressBars(4)
    this.rootStore.applicationStore.setCurrentProgressBar(1)
    this.rootStore.applicationStore.setCurrentProgress(0)
    return {
      nextStep: this.goToCollectMainIssue
    }
  }

  sayLetsGetStarted(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "I am now going to ask you a set of 9 questions designed to measure common symptoms of low mood"
      ),
      nextStep: this.goToPHQ9
    }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return AssessmentVitalityScriptStateSchema
  }

  async getCollectMainIssueSettings(state: State): Promise<ICollectMainIssueSettings> {
    const name = this.getName(state)
    return {
      messages: {
        askMainIssue: this.t(
          [
            "So {name}, please could you describe the main concern or problem that brought you here today (be sure to include specific feelings, behaviours, or thoughts that are bothering you)"
          ],
          { name }
        ),
        closingMessage: this.t(["Thank you for sharing {name}, you've come to the right place"], {
          name
        })
      }
    }
  }

  async getCollectMainIssueState(state: State): Promise<CollectMainIssueScriptState> {
    return { shouldUpdateReferral: true }
  }

  async onCollectMainIssueEnded(state: State): Promise<IStep> {
    return this.sayLetsGetStarted
  }

  async onHandleIAPTPhobiaScale(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayReadyForWASAS }
  }

  async onHandleIAPTWorkAndSocialAdjustment(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayThatsEverything }
  }

  getPersonalInfoHTML(state: State): string {
    // prettier-ignore
    return ([] as Array<string | false | undefined>)
      .concat(
        `<b>Name:</b> ${state.username}<br/>`,
        !!state.birthday && `<b>Date of Birth:</b> ${moment(state.birthday).format("DD/MM/YYYY")}<br/>`,
        `<b>Phone number:</b> ${state.phoneNumber || "N/A"}<br/>`,
      )
      .filter(Boolean)
      .join("\n")
  }

  getClinicalNotesHTML(_state: State): string {
    return ""
  }

  getQuestionnairesInfoHTML(_state: State): string {
    return ""
  }
}

/* istanbul ignore next */
export default class AssessmentVitalityDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentVitality
  readonly name: string = "AssessmentVitalityDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentVitalityDialogue.id, new AssessmentVitalityScript(), state, snapshot)
  }
}
