import React, { useState } from "react"
import "./AppointmentCalendar.scss"
import ISelectable from "../../../models/ISelectable"
import { IAppointmentMind, ITimeslot } from "../../../models/IAppointmentMind"
import InlineButton from "../InlineButton/InlineButton"
import { useApplicationStore, useConfigStore } from "../../contexts/RootStoreContext"
import clsx from "clsx"
import moment from "moment/moment"

interface Props {
  appointments: IAppointmentMind[]
  onSubmit: (item: ISelectable) => void
  isFullScreen?: boolean
}

export default function AppointmentCalendarMind(props: Props): JSX.Element {
  const { appointments = [], onSubmit, isFullScreen = false } = props
  const app = useApplicationStore()
  const [currentPosition, setCurrentPosition] = useState(0)
  const lastPosition = appointments.length - 1
  const config = useConfigStore()

  const { date } = appointments[currentPosition]
  const formattedDate = moment(date, "DD MM YYYY").format("ddd, MMMM DD")

  const onHandlePreviousPress = React.useCallback(
    (): void => setCurrentPosition(currentPosition - 1),
    [setCurrentPosition, currentPosition]
  )

  const onHandleNextPress = React.useCallback(
    (): void => setCurrentPosition(currentPosition + 1),
    [setCurrentPosition, currentPosition]
  )

  const handleSubmit = React.useCallback(
    (formattedDate: string, timeslot: ITimeslot): void =>
      onSubmit({
        body: `${formattedDate} at ${timeslot.startTime}`,
        value: timeslot
      }),
    [onSubmit]
  )

  const onContinuePress = React.useCallback(
    () => onSubmit({ body: app.t("Continue"), value: false }),
    [app, onSubmit]
  )

  return (
    <div className="lb-appointment-wrapper" data-testid="appointment-input">
      <div className="lb-appointment-container">
        <InlineButton
          btn={{ body: app.t("Continue") }}
          onSelect={onContinuePress}
          style={{ backgroundColor: "#EC9CC8", cursor: "pointer" }}
        />

        <div className="lb-appointment-calendar">
          {appointments[currentPosition].timeslots.map(timeslot => {
            const backgroundColor = config.userMessageBackground
            const btn: ISelectable = {
              body: `${timeslot.startTime} - ${timeslot.endTime} with ${timeslot.agencyName}`,
              value: timeslot.sessionID
            }

            return (
              <InlineButton
                className={clsx("lb-appointment-button", {
                  "lb-appointment-button-fullscreen": isFullScreen
                })}
                buttonContainerClassName="lb-appointment-button-container"
                style={{ backgroundColor, width: "100%" }}
                key={timeslot.sessionID}
                btn={btn}
                onSelect={() => handleSubmit(formattedDate, timeslot)}
              />
            )
          })}
        </div>
        <div className="lb-current-day-controls">
          <div
            onClick={onHandlePreviousPress}
            data-testid="previous-appointment-date"
            className={clsx(
              "lb-appointment-prev",
              currentPosition === 0 && "lb-appointment-disabled"
            )}>
            &#10094;
          </div>
          <div className="lb-current-day">{formattedDate}</div>
          <div
            onClick={onHandleNextPress}
            data-testid="next-appointment-date"
            className={clsx(
              "lb-appointment-next",
              currentPosition === lastPosition && "lb-appointment-disabled"
            )}>
            &#10095;
          </div>
        </div>
      </div>
    </div>
  )
}
