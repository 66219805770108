import { createTheme } from "@material-ui/core"

const theme = createTheme({
  palette: {
    type: "light"
  },
  typography: {
    body1: {
      fontWeight: 100,
      wordBreak: "break-word"
    },
    fontFamily: [
      "Aeroport",
      "Roboto",
      "-apple-system",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(",")
  }
})

export default theme
