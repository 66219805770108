import invariant from "../../../../utils/invariant"
import formatUnicorn from "../../../../utils/formatUnicorn"
import type Script from "../../Script"
import type { ScriptState } from "../../Script"
import type {
  IStep,
  IStepData,
  IStepResult,
  StepDecorator,
  StepDescriptor
} from "../../models/IStep"

function decorate<State extends ScriptState, S extends Script<State> = any>(
  step: IStep<State>,
  id: string,
  question: string,
  responsesKey: keyof State,
  transform?: (response: any) => string
): IStep<State> {
  invariant(id, "You can't save the response without an id [%s]", id)
  invariant(question, "You can't save a response without the question [%s]", question)
  return async function (this: S, d: IStepData<State>): Promise<IStepResult> {
    if (d) {
      try {
        const name = this.getName(d.state)
        const title = formatUnicorn(question, { name })
        const transformedAnswer = transform ? transform(d.response) : d.response
        const answer = this.normalizeAnswer(transformedAnswer)
        const item = { id, title, answer }
        this.saveResponse(item, d.state, responsesKey)
      } catch (e) {
        this.logException(e, "step saveResponse decorator")
      }
    }
    return step.call(this, d)
  }
}

export function saveResponse<State extends ScriptState, S extends Script<State> = any>(
  id: string,
  question: string,
  responsesKey: keyof State,
  transform?: (response: any) => string
): StepDecorator<State, S> {
  invariant(id, "You can't save the response without an id [%s]", id)
  invariant(question, "You can't save a response without the question [%s]", question)
  return function (target: S, key: keyof S, desc: StepDescriptor): StepDescriptor {
    const org = desc.value
    desc.value = decorate(org!, id, question, responsesKey, transform) as IStep
    return desc
  }
}

saveResponse.decorate = decorate
