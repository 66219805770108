import { IClinicalPath } from "@limbic/types"
import { IClinicalPathOverride } from "../../../models/IClinicalPath"

//prettier-ignore
export const clinicalPathsOxford: Record<IClinicalPath["id"], IClinicalPathOverride> = {
  complex_comorbid: { defaultReferralType: "PWP (Complex Comorbid)" },
  undetermined: { defaultReferralType: "PWP (Undetermined)" },
  "1": {
    defaultReferralType: "Assessment (Below Caseness)"
  },
  "2": {
    defaultReferralType: "Assessment (Below Caseness)"
  },
  "3": {
    defaultReferralType: "Assessment (Below Caseness)"
  },
  "4": {
    defaultReferralType: "Telephone Screen (Moderate Depression)"
  },
  "5": {
    defaultReferralType:
      "Assessment (Moderate Depression, Moderately Severe Functional Impairment)"
  },
  "6": {
    defaultReferralType:
      "Assessment (Moderate Depression, Severe Functional Impairment)"
  },
  "7": {
    defaultReferralType: "Telephone Screen (Moderately Severe Depression)"
  },
  "8": {
    defaultReferralType:
      "Assessment (Moderately Severe Depression, Moderately Severe Functional Impairment)"
  },
  "9": {
    defaultReferralType:
      "Assessment (Moderately Severe Depression, Severe Functional Impairment)"
  },
  "10": {
    defaultReferralType: "Telephone Screen (Severe Depression)"
  },
  "11": {
    defaultReferralType:
      "Assessment (Severe Depression, Moderately Severe Functional Impairment)"
  },
  "12": {
    defaultReferralType: "Assessment (Severe Depression, Severe Functional Impairment)"
  },
  "13": {
    defaultReferralType: "Telephone Screen (Social Phobia)"
  },
  "14": {
    defaultReferralType: "Assessment (Social Phobia, Moderately Severe Functional Impairment)"
  },
  "15": {
    defaultReferralType: "Assessment (Social Phobia, Severe Functional Impairment)"
  },
  "16": {
    defaultReferralType: "Telephone Screen (Specific Phobia)"
  },
  "17": {
    defaultReferralType:
      "Assessment (Specific Phobia, Moderately Severe Functional Impairment)"
  },
  "18": {
    defaultReferralType: "Assessment (Specific Phobia, Severe Functional Impairment)"
  },
  "19": {
    defaultReferralType: "Telephone Screen (Needle/Blood/Injury Phobia)"
  },
  "20": {
    defaultReferralType:
      "Assessment (Needle/Blood/Injury Phobia, Moderately Severe Functional Impairment)"
  },
  "21": {
    defaultReferralType:
      "Assessment (Needle/Blood/Injury Phobia, Severe Functional Impairment)"
  },
  "22": {
    defaultReferralType: "Telephone Screen (Panic)"
  },
  "23": {
    defaultReferralType: "Assessment (Panic, Moderately Severe Functional Impairment)"
  },
  "24": {
    defaultReferralType: "Assessment (Severe Panic, Severe Functional Impairment)"
  },
  "25": {
    defaultReferralType: "Telephone Screen (Moderate GAD)"
  },
  "26": {
    defaultReferralType:
      "Assessment (Moderate GAD, Moderately Severe Functional Impairment)"
  },
  "27": {
    defaultReferralType:
      "Assessment (Moderate GAD, Severe Functional Impairment)"
  },
  "28": {
    defaultReferralType: "Telephone Screen  (Severe GAD)"
  },
  "29": {
    defaultReferralType:
      "Assessment (Severe GAD, Moderately Severe Functional Impairment)"
  },
  "30": {
    defaultReferralType: "Assessment (Severe GAD, Severe Functional Impairment)"
  },
  "31": {
    defaultReferralType: "Telephone Screen (Health Anxiety)"
  },
  "32": {
    defaultReferralType: "Assessment (Health Anxiety, Moderately Severe Functional Impairment)"
  },
  "33": {
    defaultReferralType: "Assessment (Health Anxiety, Severe Functional Impairment)"
  },
  "34": {
    defaultReferralType: "Telephone Screen (OCD)"
  },
  "35": {
    defaultReferralType: "Assessment (OCD, Moderately Severe Functional Impairment)"
  },
  "36": {
    defaultReferralType: "Assessment (OCD, Severe Functional Impairment)"
  },
  "37": {
    defaultReferralType: "Telephone Screen (PTSD)"
  },
  "38": {
    defaultReferralType: "Assessment (PTSD, Moderately Severe Functional Impairment)"
  },
  "39": {
    defaultReferralType: "Assessment (PTSD, Severe Functional Impairment)"
  },
  "40": {
    defaultReferralType: "Telephone Screen (Anxiety Disorder (Undetermined))"
  },
  "41": {
    defaultReferralType:
      "Assessment (Anxiety Disorder (Undetermined) with Moderately Severe functional impairment)"
  },
  "42": {
    defaultReferralType:
      "Assessment (Anxiety Disorder (Undetermined) with Severe functional impairment)"
  },
  "43": {
    defaultReferralType:
      "Telephone Screen (Agoraphobia)"
  }, "44": {
    defaultReferralType:
      "Assessment (Agoraphobia with Moderately Severe functional impairment)"
  }, "45": {
    defaultReferralType:
      "Assessment (Agoraphobia with Severe functional impairment)"
  }
}
