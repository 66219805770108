import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { step } from "../../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { TrackingEvents } from "../../../../models/Constants"
import {
  IAppointmentStatus,
  IDefaultChatFlowSettingsBookAppointment,
  IDefaultChatFlowMessagesBookAppointment,
  AppointmentTimeslot
} from "@limbic/types"
import { ZodSchema, z } from "zod"
import moment from "moment"
import BaseScript, { BaseScriptState, BaseScriptStateSchema } from "../../../BaseScript"
import { bookAppointmentSlot, getAppointments } from "../../../../backend/api/limbic/appointments"
import invariant from "../../../../utils/invariant"
import { SubmitReferralStatus } from "../../../../models/SubmitReferral"
import { submitReferral } from "../../../../backend/api/limbic/submitReferral"

const botConfigVersion = process.env.REACT_APP_BOT_VERSION ?? "draft"
invariant(
  ["published", "draft"].includes(botConfigVersion),
  "REACT_APP_BOT_VERSION must be 'draft' or 'published'"
)

export type IBookAppointmentSettings = IDefaultChatFlowSettingsBookAppointment & {
  messages?: IDefaultChatFlowMessagesBookAppointment
}

export interface State extends BaseScriptState {
  retryBookAppointment?: number

  tempAppointment?: AppointmentTimeslot
  finalAppointment?: AppointmentTimeslot
  retryReserveAppointment?: number
}

export const BookAppointmentScriptStateSchema = BaseScriptStateSchema.extend({
  retryBookAppointment: z.number().optional(),
  tempAppointment: z.string().optional(),
  finalAppointment: z.string().optional(),
  retryReserveAppointment: z.number().optional()
})

export type BookAppointmentScriptState = State

export class BookAppointmentScript extends BaseScript<State> {
  readonly name: string = "BookAppointmentScript"
  protected readonly messages: IDefaultChatFlowMessagesBookAppointment | undefined

  constructor(settings?: IBookAppointmentSettings | undefined) {
    super()
    this.messages = settings?.messages ?? {}
  }

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    if (!this.referralStore.patientId || !this.referralStore.signupCode) {
      return { nextStep: this.end }
    }

    this.timeEvent(this.name)
    return { nextStep: this.sayIntroToBookAppointment }
  }

  @step.logState
  sayIntroToBookAppointment(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messages?.sayBookAppointmentIntro ??
          "I'm going to put you in touch with a qualified mental health professional",
        this.getContext(d.state)
      ),
      nextStep: this.triggerReferralSubmission
    }
  }

  @step.logState
  async triggerReferralSubmission(d: IStepData<State>): Promise<IStepResult> {
    this.referralStore.setIdleSubmissionActive(false)
    const patientId = this.referralStore.patientId!
    const [isSubmitSuccessful, referralStatus] = await submitReferral(patientId)

    if (referralStatus === SubmitReferralStatus.NoInternetConnection) {
      return { nextStep: this.sayNoInternetConnectionSubmitReferral }
    }

    if (!isSubmitSuccessful || referralStatus === SubmitReferralStatus.RequestFailed) {
      this.referralStore.setIdleSubmissionActive(true)
      return { nextStep: this.saySubmitReferralFailed }
    }

    d.state.retryBookAppointment = 0
    return { nextStep: this.askSelectAppointmentSlot }
  }

  @step.logState
  sayNoInternetConnectionSubmitReferral(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Hmmm, it looks like you're not connected to the internet"),
      prompt: {
        id: this.getPromptId("sayNoInternetConnectionSubmitReferral"),
        type: "inlinePicker",
        choices: [{ body: this.t("Try again") }],
        isUndoAble: false
      },
      nextStep: this.triggerReferralSubmission
    }
  }

  // TODO: move messages from this into the dashboard
  @step.logState
  saySubmitReferralFailed(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messages?.sayBookAppointmentSubmitReferralFailed ?? [
          "Sorry, we're encountering a persistent issue and can't continue with the appointment booking",
          "Someone from the service team will call you to organise your appointment"
        ],
        this.getContext(d.state)
      ),
      nextStep: this.end
    }
  }

  @step.logState
  @step.startTyping
  @step.setState<State>({ retryReserveAppointment: 0 })
  async askSelectAppointmentSlot(d: IStepData<State>): Promise<IStepResult> {
    // If the service specifies some treatment plan, the calendar slots
    // should be selected from the plan's date window.
    // From PCMIS: 👇
    // Specify the start/end of the date range in which the CalendarSlot date
    // falls in (if it is before or after the service specified earliest or
    // latest treatment time it will be ignored)
    const signupCode = this.referralStore.signupCode!
    const botApiKey = this.rootStore.configStore.key
    const [appointments, status] = await getAppointments(
      signupCode,
      botApiKey,
      botConfigVersion as "draft" | "published"
    )

    if (status === IAppointmentStatus.NoInternetConnection) {
      return { nextStep: this.sayNoInternetConnectionGetSlots }
    }

    if (!appointments?.length || status === IAppointmentStatus.RequestFailed) {
      return { nextStep: this.sayGetSlotsFailed }
    }

    return {
      body: this.t(
        this.messages?.askBookAppointmentSelectSlot ?? [
          "Let's see all the available appointment slots",
          "If you can't find an appointment slot that suits you, please click \"continue\" and you'll be added to our waitlist. A local practitioner will be in touch to arrange one with you",
          "Please use the arrows either side of the dates to view more available options"
        ],
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askSelectAppointmentSlot"),
        type: "appointment",
        appointments: appointments || [],
        isUndoAble: true
      },
      nextStep: this.handleSelectAppointmentSlot
    }
  }

  @step.logState
  sayNoInternetConnectionGetSlots(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Hmmm, it looks like you're not connected to the internet"),
      prompt: {
        id: this.getPromptId("sayNoInternetConnectionGetSlots"),
        type: "inlinePicker",
        choices: [{ body: this.t("Try again") }],
        isUndoAble: false
      },
      nextStep: this.askSelectAppointmentSlot
    }
  }

  @step.logState
  sayGetSlotsFailed(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messages?.sayBookAppointmentGetSlotsFailed ?? [
          "Hmmm... something went wrong while fetching your appointment slots",
          "Someone from the service team will call you to organise your appointment"
        ],
        this.getContext(d.state)
      ),
      nextStep: this.end
    }
  }

  @step.logStateAndResponse
  async handleSelectAppointmentSlot(
    d: IStepData<State, AppointmentTimeslot | false>
  ): Promise<IStepResult> {
    console.log(JSON.parse(JSON.stringify({ response: d.response })))
    // Exiting with no appointment slot selected.
    if (d.response === false) return { nextStep: this.sayNoProblemAndClose }

    d.state.tempAppointment = d.response
    return { nextStep: this.askConfirmAppointment }
  }

  @step.logState
  askConfirmAppointment(d: IStepData<State>): IStepResult {
    const { startTime, endTime } = d.state.tempAppointment!

    const slotDate = moment(startTime).format("dddd, MMMM Do YYYY")
    const slotStartTime = moment(startTime).format("HH:mm")
    const slotEndTime = moment(endTime).format("HH:mm")

    return {
      body: this.t(
        this.messages?.sayBookAppointmentConfirmSlot ?? [
          "You have selected the following date and time",
          `{slotDate}\n{slotStartTime} - {slotEndTime}`,
          "Is this correct?"
        ],
        { ...this.getContext(d.state), slotDate, slotStartTime, slotEndTime }
      ),
      prompt: {
        id: this.getPromptId("askConfirmAppointment"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No, let me change it"), value: false }
        ],
        isUndoAble: false
      },
      nextStep: this.handleConfirmAppointment
    }
  }

  @step.logState
  async handleConfirmAppointment(d: IStepData<State, boolean>): Promise<IStepResult> {
    if (d.response) {
      return { nextStep: this.doAppointmentBooking }
    }

    d.state.tempAppointment = undefined
    return { nextStep: this.askSelectAppointmentSlot }
  }

  @step.logState
  @step.startTyping
  async doAppointmentBooking(d: IStepData<State>): Promise<IStepResult> {
    if (!d.state.tempAppointment) {
      return { nextStep: this.sayAppointmentBookingFailed }
    }
    const { startTime: from, endTime: to, id } = d.state.tempAppointment! as AppointmentTimeslot

    const startTime = moment(from).format("h:mm A")
    const endTime = moment(to).format("h:mm A")

    const signupCode = d.state.signupCode! ?? "demo-4388"
    const botApiKey = this.rootStore.configStore.key
    const [appointment, status] = await bookAppointmentSlot(
      signupCode,
      botApiKey,
      botConfigVersion as "draft" | "published",
      id
    )

    if (status === IAppointmentStatus.NoInternetConnection) {
      return { nextStep: this.sayNoInternetConnectionBooking }
    }

    if (appointment) {
      d.state.finalAppointment = d.state.tempAppointment
      this.track(TrackingEvents.APPOINTMENT_BOOKED)
      this.referralStore.addClinicalNote(`Appointment: ${startTime}-${endTime} on ${from}`)
      return { nextStep: this.sayAppointmentBookingSucceeded }
    }

    if (!appointment || status === IAppointmentStatus.RequestFailed) {
      this.track(TrackingEvents.APPOINTMENT_BOOKING_FAILED)
      d.state.tempAppointment = undefined
      d.state.retryReserveAppointment ??= 0
      d.state.retryReserveAppointment = d.state.retryReserveAppointment + 1
      if (d.state.retryReserveAppointment > 2) {
        return { nextStep: this.sayAppointmentBookingFailed }
      }
    }
    return {
      body: this.t(
        this.messages?.sayBookAppointmentSlotUnavailable ?? [
          "Hmmm... something went wrong while booking your appointment",
          "Please try again"
        ],
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("sayNoInternetConnectSubmitReferral"),
        type: "inlinePicker",
        choices: [{ body: this.t("Try again") }],
        isUndoAble: false
      },
      nextStep: this.askSelectAppointmentSlot
    }
  }

  @step.logState
  sayAppointmentBookingSucceeded(d: IStepData<State>): IStepResult {
    const serviceName = this.rootStore.configStore.serviceName
    const name = this.getName(d.state)
    // This should never be the case but adding it as a safeguard 👇
    return {
      body: this.t(
        this.messages?.sayBookAppointmentBookedSuccessfully ?? [
          "Great {name}, your appointment has been booked successfully"
        ],
        { ...this.getContext(d.state), name, serviceName }
      ),
      nextStep: this.end
    }
  }

  @step.logState
  sayAppointmentBookingFailed(d: IStepData<State>): IStepResult {
    const serviceName = this.rootStore.configStore.serviceName
    const name = this.getName(d.state)
    // This should never be the case but adding it as a safeguard 👇
    return {
      body: this.t(
        this.messages?.sayBookAppointmentBookingError ?? [
          "Sorry {name}, we're encountering a persistent issue when trying to confirm your appointment booking",
          "So we'll ask one of the {serviceName} team to give you a call to organise your appointment"
        ],
        { ...this.getContext(d.state), name, serviceName }
      ),
      nextStep: this.end
    }
  }

  @step.logState
  sayNoInternetConnectionBooking(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Hmmm, it looks like you're not connected to the internet"),
      prompt: {
        id: this.getPromptId("sayNoInternetConnectionBooking"),
        type: "inlinePicker",
        choices: [{ body: this.t("Try again") }],
        isUndoAble: false
      },
      nextStep: this.doAppointmentBooking
    }
  }

  @step.logState
  sayNoProblemAndClose(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messages?.sayBookAppointmentGoodbyeLeave ?? "Okay",
        this.getContext(d.state)
      ),
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return BookAppointmentScriptStateSchema
  }
}

export default class BookAppointmentDialogue extends AdHocDialogue<State, BookAppointmentScript> {
  static id = DialogueIDs.BookAppointmentChatflow
  readonly name: string = "BookAppointmentDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: IBookAppointmentSettings
  ) {
    super(
      BookAppointmentDialogue.id,
      new BookAppointmentScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
