/** Setup */
import Persistable from "./Persistable"
import {
  updateListFromRemote,
  detectCrisis,
  Category,
  setList,
  setCrisisDetectionConfig,
  Language as CrisisDetectionLanguage
} from "@limbic/crisis-detection"
import { CrisisDetectionData } from "./CrisisDetectionData"
import invariant from "../utils/invariant"
import { LanguageCodes } from "@limbic/types"

function isValidCrisisDetectionLanguage(language: string): language is CrisisDetectionLanguage {
  return (["en", "es", "cy"] as Array<CrisisDetectionLanguage>).includes(language as any)
}

export default class CrisisDetector extends Persistable {
  static _instance: CrisisDetector

  /** Static Methods */

  static getInstance(): CrisisDetector {
    if (!this._instance) {
      this._instance = new CrisisDetector()
    }
    return this._instance
  }

  /** Instance */

  readonly name: string = "CrisisDetector"

  detectionData: CrisisDetectionData
  skipNextCheck = false

  constructor() {
    super()
    this.detectionData = this.hydrate("detectionData") ?? {}
  }

  async setCrisisDetectionLanguage(language: string = LanguageCodes.EN): Promise<void> {
    if (!isValidCrisisDetectionLanguage(language)) return
    setCrisisDetectionConfig({ language })
    const data = this.getCrisisDetectionData()
    try {
      this.clearCrisisDetectionData()
      await this.updateCrisisDetection()
    } catch (e) {
      this.setCrisisDetectionData(data)
      this.logException(e, "setCrisisDetectionLanguage")
    }
  }

  disableCrisisDetectionForNextInput(): void {
    this.skipNextCheck = true
  }

  async detectCrisis(
    input: string | null | undefined = "",
    ignoredCategories?: Category[]
  ): Promise<string[] | null> {
    try {
      if (this.skipNextCheck) {
        this.skipNextCheck = false
        return null
      }
      if (!input) return null
      invariant(typeof input === "string", "crisis detection input must be a string")
      const triggerWords = await detectCrisis(input, ignoredCategories && { ignoredCategories })
      return triggerWords.map(i => i.match)
    } catch (e) {
      console.log(`${this.name} - detectCrisis`)
      this.logBreadcrumb("CrisisDetector.detectCrisis()", { input, options: { ignoredCategories } })
      this.logException(e, "detectCrisis")
    }
    return null
  }

  async updateCrisisDetection(): Promise<void> {
    try {
      this.logBreadcrumb("updateCrisisDetection()")
      const storedData = this.getCrisisDetectionData()

      if (storedData?.version === "1") {
        setList(storedData.list!)
      }

      const list = await updateListFromRemote()
      this.setCrisisDetectionData({
        version: "1",
        list
      })
    } catch (e) {
      this.logBreadcrumb("updateCrisisDetection", e)
    }
  }

  setCrisisDetectionData(data?: CrisisDetectionData): void {
    if (data?.version === "1") {
      return localStorage.setItem("@Crisis:detectionData", JSON.stringify(data))
    }
  }

  clearCrisisDetectionData(): void {
    localStorage.removeItem("@Crisis:detectionData")
  }

  getCrisisDetectionData(): CrisisDetectionData | undefined {
    const data = localStorage.getItem("@Crisis:detectionData")
    let result: CrisisDetectionData | void
    if (data) {
      try {
        result = JSON.parse(data)
      } catch (error) {
        this.logException(error, "getCrisisDetectionData() failed")
      }
    }
    return result ?? undefined
  }
}
