import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { IStepResult } from "../../../backend/chatbot/models/IStep"
import { AssessmentWithADSMScript, AssessmentWithADSMScriptState } from "./AssessmentWithADSMScript"

type State = AssessmentWithADSMScriptState

export class AssessmentDorkingScript extends AssessmentWithADSMScript {
  readonly name: string = "AssessmentDorkingScript"

  /** Generic Handlers */

  async onHandleIAPTPhobiaScale(state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayReadyForWASAS }
  }

  async onHandleIAPTWorkAndSocialAdjustment(state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayThatsEverything }
  }
}

export default class AssessmentDorkingDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentDorking
  readonly name: string = "AssessmentDorkingDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentDorkingDialogue.id, new AssessmentDorkingScript(), state, snapshot)
  }
}
