import Logger from "../../../utils/Logger"
import delay from "../../../utils/delay"
import { postToSlack } from "../../../utils/slack"
import { SendEmailStatus } from "../../../models/ISendEmail"
import client from "./_client"

const TOTAL_RETRIES = 2

interface Props {
  /**
   * Recipient email address
   */
  email: string
  serviceApiKey: string
  version: "draft" | "published"
  attachmentsOverride?: string[]
}

export default async function sendKeepingSafeEmail(
  props: Props,
  retry = 0
): Promise<SendEmailStatus> {
  const { email, serviceApiKey, version, attachmentsOverride } = props
  const body = { email, serviceApiKey, version, attachmentsOverride }
  try {
    await client.post("/v1/email/send/keeping-safe", body) // 👈 data is empty
    return SendEmailStatus.Success
  } catch (e) {
    Logger.getInstance().exception(e, "sendKeepingSafeEmail fetch failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`email data for retry ${retry}`, JSON.stringify(body))
      Logger.getInstance().message("sendKeepingSafeEmail Retry")
      void postToSlack({ text: getSlackRetryMessage(serviceApiKey, email, retry) })
      await delay(3 * retry || 1.5)
      return await sendKeepingSafeEmail(props, retry + 1)
    }
    logLongJSON("sendKeepingSafeEmail fetch failed data", JSON.stringify(body))
    Logger.getInstance().exception(e, "sendKeepingSafeEmail fetch failed")
    void postToSlack({ text: getSlackFailureMessage(serviceApiKey, email, retry) })
    return SendEmailStatus.SendEmailFailed
  }
}

function getSlackRetryMessage(
  serviceApiKey: string | undefined,
  to: string,
  retry: number
): string {
  return `🔄 *RETRY [${serviceApiKey}]:* Keeping safe email to *${to}* failed to sent on attempt ${
    retry + 1
  } [Limbic Access Bot]`
}

function getSlackFailureMessage(
  serviceApiKey: string | undefined,
  to: string,
  retries: number
): string {
  return `❌ *FAILURE [${serviceApiKey}]:* Keeping safe email to *${to}* failed to sent after ${retries} retries [Limbic Access Bot]`
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}
