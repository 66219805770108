import self from "./setIneligibleUser"
import Logger from "../../../utils/Logger"
import delay from "../../../utils/delay"
import { IDocIneligibleUser, IIneligibleUserData } from "@limbic/types"
import client from "./_client"

const TOTAL_RETRIES = 2

export default async function setIneligibleUser(
  payload: IIneligibleUserData,
  retry = 0
): Promise<IDocIneligibleUser | undefined> {
  try {
    return await client.post("/v1/ineligibility/add-user-ineligible", payload)
  } catch (e) {
    Logger.getInstance().exception(e, "setIneligibleUser fetch failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`setIneligibleUser body for retry ${retry}`, JSON.stringify(payload))
      Logger.getInstance().message("setIneligibleUser retry")
      await delay(1)
      return await self(payload, retry + 1)
    }
    throw e
  }
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}
