import self from "./updateReferral"
import invariant from "../../../utils/invariant"
import { ReferralPayload } from "@limbic/types"
import Logger from "../../../utils/Logger"
import delay from "../../../utils/delay"
import client from "./_client"

export interface IUpdateReferralResponse {
  _id: string
  signupCode: string
  status: "SUCCESS" | "PENDING" | "ERROR"
  limbic: { tags?: string[] }
}

const TOTAL_RETRIES = 2

export default async function updateReferral(
  _id: string,
  payload: ReferralPayload<any>,
  retry = 0
): Promise<IUpdateReferralResponse | undefined> {
  invariant(_id, "Cannot update referral without an id")
  const body = { ...payload, _id }
  try {
    return await client.put("/referral/update", body)
  } catch (e) {
    Logger.getInstance().exception(e, "updateReferral fetch failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`updateReferral body for retry ${retry}`, body)
      Logger.getInstance().message("updateReferral retry")
      await delay(1)
      return await self(_id, payload, retry + 1)
    }
    throw e
  }
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}
