import ChatFlowPayloadBuilder from "../ChatFlowPayloadBuilder"
import { ChatFlowsEnum, ReferralPayload } from "@limbic/types"

export default class NationalityPayloadBuilder extends ChatFlowPayloadBuilder<ChatFlowsEnum.COLLECT_NATIONALITY> {
  chatFlow = ChatFlowsEnum.COLLECT_NATIONALITY

  /** Overrides */

  getDefaultPayload(): Partial<Partial<ReferralPayload>> {
    return { nationality: this.ctx.state?.nationality } as Partial<ReferralPayload>
  }
}

ChatFlowPayloadBuilder.builders[ChatFlowsEnum.COLLECT_NATIONALITY] = NationalityPayloadBuilder
