/* eslint-disable @typescript-eslint/no-empty-function */
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { SelfReferralIAPTScriptState } from "./SelfReferralIAPTScript"
import SelfReferralIAPTScript from "./SelfReferralIAPTScript"
import {
  ARMED_FORCES_MAYDEN_MHM,
  DISABILITY_MAYDEN_MHM,
  ETHNICITY_MAYDEN_MHM,
  GENDER_MAYDEN_MHM,
  GenderBirthAssigned,
  LANGUAGE_MAYDEN_MHM,
  LIMBIC_IMPACT_LEVEL,
  LTC_MAYDEN_MHM,
  NATIONALITY_MAYDEN,
  PERINATAL_MAYDEN_MHM,
  PronounMaydenMHM,
  ReferralPayloadMaydenMHMNorthumberland,
  ReferralPayloadMaydenMHMSefton,
  ReferralPayloadMaydenMHMWarrington,
  RELIGION_MAYDEN_MHM,
  SEXUALITY_MAYDEN_MHM
} from "@limbic/types"
import { isValidLandlineNumber, isValidMobilePhone } from "../../../utils/isValidPhoneNumber"
import moment from "moment/moment"
import { IStep, IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { IAPTIDs } from "../../../models/IIAPTService"
import { step } from "../../../backend/chatbot/decorators/step"
import { fullNameRegex } from "../../../utils/fullNameRegex"
import invariant from "../../../utils/invariant"
import {
  disabilities,
  ethnicities,
  exArmedForces,
  genders,
  languages,
  ltcs,
  religions,
  sameGenderAsBirth,
  sexualities,
  nationalities,
  perinatalStatuses
} from "../../../config/referralForms/mental-health-matters"
import { toLowerCaseProperties } from "../../../utils/object"
import { MHMTitles } from "../../../models/Constants"
import {
  CollectMainIssueScriptState,
  ICollectMainIssueSettings
} from "../ad-hoc/CollectMainIssue/CollectMainIssueDialogue"
import {
  CollectGenderScriptState,
  ICollectGenderSettings
} from "../ad-hoc/CollectGender/CollectGenderDialogue"
import { ICollectDisabilitiesSettings } from "../ad-hoc/CollectDisabilities/CollectDisabilitiesDialogue"
import { ICollectLongTermMedicalConditionSettings } from "../ad-hoc/CollectLongTermMedicalConditionDetails/CollectLongTermMedicalConditionDetailsDialogue"
import {
  CollectLanguageAndInterpreterScriptState,
  ICollectLanguageAndInterpreterSettings
} from "../ad-hoc/CollectLanguageAndInterpreter/CollectLanguageAndInterpreterDialogue"
import { ICollectReligionSettings } from "../ad-hoc/CollectReligion/CollectReligionDialogue"
import { ICollectEmailSettings } from "../ad-hoc/CollectEmail/CollectEmailDialogue"
import { parsePhoneNumber } from "awesome-phonenumber"

const educationChoices = ["No", "Yes - School", "Yes - College", "Yes - University"]

interface State extends SelfReferralIAPTScriptState {
  preferredPhoneType?: "Mobile" | "Work" | "Home"
  emailPermission?: boolean
  disabilities?: string[]
  accommodationStatus?: string
  agreeProvideDetailsOfNextOfKin?: boolean
  nameOfNextOfKin?: string
  phoneNumberOfNextOfKin?: string
  relationshipOfNextOfKin?: string
  education?: string
  educationOrWorkImpact?: boolean
  employmentAdvisor?: boolean
  nationality?: string
  primaryLanguage?: string
  requiresInterpreter?: boolean
  interpreterLanguage?: string
  requiresSignInterpreter?: boolean
  accessibilityConsiderations?: string
  ltcOtherDetails?: string
  otherSubstances?: boolean
  substancesForMood?: boolean
  substancesOverTheCounter?: boolean
  takingPrescribedMedication?: boolean
  prescribedMedications?: string
  mainIssue?: string
  mainGoal?: string
  nhsStaff?: boolean
  currentTherapy?: boolean
  currentTherapyDetails?: string
  previousTherapy?: boolean
  previousTherapyDetails?: string
  preferredTitle?: MHMTitles
  preferredPronouns?: PronounMaydenMHM
  gpConsent?: boolean
  relationshipStatus?: string
}

export type SelfReferralMHMState = State

export class SelfReferralMHMScript extends SelfReferralIAPTScript {
  readonly name: string = "SelfReferralMHMScript"

  /** Script Steps */

  @step.logState
  askPermissionToSendEmail(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        d.state.iapt?.id === IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON
          ? "Do we have permission to send you an email (incl. webforms) to that address?"
          : "Do we have permission to send you an email to that address?"
      ),
      prompt: {
        id: this.getPromptId("askPermissionToSendEmail"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askPermissionToSendEmail"
      },
      nextStep: this.handlePermissionToSendEmail
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralMHMScript) => {
    d.state.emailPermission = d.response
    script.referralStore.setCustomField<State>("emailPermission", d.response)
  })
  handlePermissionToSendEmail(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.goToCollectPreferredCorrespondence }
  }

  startSelfReferralPart2(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askProvideDetailsNextOfKin }
  }

  @step.logState
  askProvideDetailsNextOfKin(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Are you able to provide details for someone to contact in case of an emergency only?",
        "You do not have to give us this information if you don't wish to and this will not affect your treatment with us"
      ]),
      prompt: {
        id: this.getPromptId("askProvideDetailsNextOfKin"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askProvideDetailsNextOfKin"
      },
      nextStep: this.handleProvideDetailsNextOfKin
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralMHMScript) => {
    d.state.agreeProvideDetailsOfNextOfKin = d.response
    script.referralStore.setCustomField<State>("agreeProvideDetailsOfNextOfKin", d.response)
  })
  handleProvideDetailsNextOfKin(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return {
        nextStep: this.askNameOfNextOfKin
      }
    }
    return {
      nextStep: this.goToCollectGender
    }
  }

  @step.logState
  askNameOfNextOfKin(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("What's their full name?"),
      prompt: {
        id: this.getPromptId("askNameOfNextOfKin"),
        type: "text",
        validation: [new RegExp(fullNameRegex)],
        validationExplainer: this.t(["Please enter their full name"]),
        placeholder: this.t("Please type their name"),
        forceValue: true
      },
      nextStep: this.handleNameOfNextOfKinWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralMHMScript) => {
    d.state.nameOfNextOfKin = d.response
    script.referralStore.setCustomField<State>("nameOfNextOfKin", d.response)
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    getNextStep: (s: SelfReferralMHMScript) => s.returnToAskNameOfNextOfKin
  })
  handleNameOfNextOfKinWithCrisis(_d: IStepData<State, string>): IStepResult {
    return {
      nextStep: this.askRelationshipOfNextOfKin
    }
  }

  @step
  returnToAskNameOfNextOfKin(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("So I was going to ask you about your contact"),
      nextStep: this.askNameOfNextOfKin
    }
  }

  @step.logState
  askRelationshipOfNextOfKin(d: IStepData<State>): IStepResult {
    const nameOfNextOfKin = d.state.nameOfNextOfKin
    return {
      body: this.t("What's {nameOfNextOfKin}'s relationship to you?", { nameOfNextOfKin }),
      prompt: {
        id: this.getPromptId("askRelationshipOfNextOfKin"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Parent"), value: "Parent" },
          { body: this.t("Sibling"), value: "Sibling" },
          { body: this.t("Child"), value: "Child" },
          { body: this.t("Friend"), value: "Friend" }
        ],
        textPrompt: {
          forceValue: true,
          placeholder: this.t("Other - please specify")
        },
        dataPointsName: "askRelationshipOfNextOfKin"
      },
      nextStep: this.handleRelationshipOfNextOfKinWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralMHMScript) => {
    d.state.relationshipOfNextOfKin = d.response
    script.referralStore.setCustomField<State>("relationshipOfNextOfKin", d.response)
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    getNextStep: (s: SelfReferralMHMScript) => s.returnToAskRelationshipOfNextOfKin
  })
  handleRelationshipOfNextOfKinWithCrisis(_d: IStepData<State, string>): IStepResult {
    return {
      nextStep: this.askPhoneNumberOfNextOfKin
    }
  }

  @step
  returnToAskRelationshipOfNextOfKin(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("So I was going to ask you about your contact"),
      nextStep: this.askRelationshipOfNextOfKin
    }
  }

  @step.logState
  askPhoneNumberOfNextOfKin(d: IStepData<State>): IStepResult {
    const nameOfNextOfKin = d.state.nameOfNextOfKin
    return {
      body: this.t("What's {nameOfNextOfKin}'s phone number?", { nameOfNextOfKin }),
      prompt: {
        id: this.getPromptId("askPhoneNumberOfNextOfKin"),
        trackResponse: true,
        type: "phoneNumber",
        placeholder: this.t("Type their phone number")
      },
      nextStep: this.handlePhoneNumberOfNextOfKin
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralMHMScript) => {
    d.state.phoneNumberOfNextOfKin = d.response
    script.referralStore.setCustomField<State>("phoneNumberOfNextOfKin", d.response)
  })
  handlePhoneNumberOfNextOfKin(_d: IStepData<State, string>): IStepResult {
    return {
      nextStep: this.goToCollectGender
    }
  }

  @step.logState
  askRelationshipStatus(_d: IStepData<State>): IStepResult {
    // Not part of the form - will be setting them in the notes for now
    const relationshipStatuses = [
      { body: this.t("Single"), value: "Single" },
      { body: this.t("Married"), value: "Married" },
      { body: this.t("Divorced"), value: "Divorced" },
      { body: this.t("Widowed"), value: "Widowed" },
      { body: this.t("Separated"), value: "Separated" },
      { body: this.t("Co-Habiting"), value: "Co-Habiting" },
      { body: this.t("Long Term"), value: "Long Term" },
      { body: this.t("Civil Partnership"), value: "Civil Partnership" },
      { body: this.t("Prefer Not To Say"), value: "Not discolsed" }
    ]

    return {
      body: this.t("What is your relationship status?"),
      prompt: {
        id: this.getPromptId("askRelationshipStatus"),
        type: "inlinePicker",
        choices: relationshipStatuses,
        isUndoAble: true,
        dataPointsName: "askRelationshipStatus",
        trackResponse: true
      },
      nextStep: this.handleRelationshipStatus
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralMHMScript) => {
    d.state.relationshipStatus = d.response
    script.referralStore.setCustomField<State>("relationshipStatus", d.response)
  })
  handleRelationshipStatus(d: IStepData<State, string>): IStepResult {
    this.referralStore.addClinicalNote(`Relationship Status: ${d.state.relationshipStatus}`)
    return { nextStep: this.askEducation }
  }

  @step.logState
  askEducation(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Are you currently in education?"),
      prompt: {
        id: this.getPromptId("askEducation"),
        trackResponse: true,
        type: "inlinePicker",
        choices: educationChoices.map(edu => ({ body: this.t(edu), value: edu })),
        dataPointsName: "askEducation"
      },
      nextStep: this.handleEducation
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralMHMScript) => {
    d.state.education = d.response
    script.referralStore.setCustomField<State>("education", d.response)
  })
  handleEducation(d: IStepData<State, string>): IStepResult {
    if (d.state.iapt?.id === IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND || d.response === "No") {
      return {
        nextStep: this.goToCollectReligion
      }
    }

    return {
      nextStep: this.askEducationOrWorkImpact
    }
  }

  @step.logState
  askEducationOrWorkImpact(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Do you feel that your work/education or search for work, is impacting on, or being impacted by your mental health?"
      ),
      prompt: {
        id: this.getPromptId("askEducationOrWorkImpact"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askEducationOrWorkImpact"
      },
      nextStep: this.handleEducationOrWorkImpact
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralMHMScript) => {
    d.state.educationOrWorkImpact = d.response
    script.referralStore.setCustomField<State>("educationOrWorkImpact", d.response)
  })
  handleEducationOrWorkImpact(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return {
        nextStep: this.askReferToEmploymentAdvisor
      }
    }

    return {
      nextStep: this.goToCollectReligion
    }
  }

  @step.logState
  askReferToEmploymentAdvisor(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Would you like to be referred to one of our Employment Advisors who can help you with this?"
      ),
      prompt: {
        id: this.getPromptId("askReferToEmploymentAdvisor"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askReferToEmploymentAdvisor"
      },
      nextStep: this.handleReferToEmploymentAdvisor
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralMHMScript) => {
    d.state.employmentAdvisor = d.response
    script.referralStore.setCustomField<State>("employmentAdvisor", d.response)
  })
  handleReferToEmploymentAdvisor(_d: IStepData<State, boolean>): IStepResult {
    return {
      nextStep: this.goToCollectReligion
    }
  }

  @step.logState
  askRequiresSignInterpreter(d: IStepData<State>): IStepResult {
    if (d.state.spineInterpreterRequired !== undefined) {
      return { nextStep: this.goToCollectDisabilities }
    }
    return {
      body: this.t("Do you need a sign language interpreter?"),
      prompt: {
        id: this.getPromptId("askRequiresSignInterpreter"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleRequiresSignInterpreter
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralMHMScript) => {
    d.state.requiresSignInterpreter = d.response
    script.referralStore.setCustomField<State>("requiresSignInterpreter", d.response)
  })
  handleRequiresSignInterpreter(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.goToCollectDisabilities }
  }

  @step.logState
  askPerinatal(d: IStepData<State>): IStepResult {
    const perinatalStatuses = this.getPerinatalStatuses(d.state)
    if (!perinatalStatuses?.length) {
      this.logBreadcrumb("PERINATAL STATUSES NOT FOUND", d.state, { perinatalStatuses })
      this.logMessage("PERINATAL STATUSES NOT FOUND")
      return { nextStep: this.goToCollectDisabilities }
    }
    return {
      body: this.t(
        "Are you a parent with caring responsibilities for a child or children under 2, or are you or your partner currently pregnant?"
      ),
      prompt: {
        id: this.getPromptId("askPerinatal"),
        trackResponse: true,
        type: "inlinePicker",
        choices: perinatalStatuses.map(g => ({ body: this.t(g), value: g })),
        dataPointsName: "askPerinatal"
      },
      nextStep: this.handlePerinatal
    }
  }

  @step.logState
  askAccessibilityConsiderations(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Is there any other information that would be useful for us to know to help you access the service (e.g. are you hard of hearing, do you struggle with stairs)?"
      ),
      prompt: {
        id: this.getPromptId("askAccessibilityConsiderations"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("No impairment"), value: "No impairment" },
          { body: this.t("Visual impairment"), value: "Visual impairment" },
          { body: this.t("Hearing impairment"), value: "Hearing impairment" }
        ],
        textPrompt: {
          forceValue: true,
          placeholder: this.t("Other - please specify")
        },
        dataPointsName: "askAccessibilityConsiderations"
      },
      nextStep: this.handleAccessibilityConsiderationsWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralMHMScript) => {
    d.state.accessibilityConsiderations = d.response
    script.referralStore.setCustomField<State>("accessibilityConsiderations", d.response)
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralMHMScript) => s.goToCollectLongTermMedicalConditionDetails
  })
  handleAccessibilityConsiderationsWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.goToCollectLongTermMedicalConditionDetails }
  }

  @step.logState
  askSubstances(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Are you currently using any recreational drugs?"),
      prompt: {
        id: this.getPromptId("askSubstances"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askSubstances"
      },
      nextStep: this.handleSubstances
    }
  }

  @step.logStateAndResponse
  async handleSubstances(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.substances = d.response //recreational
    this.setPeople({ substances: d.response })

    return {
      nextStep: d.response //
        ? this.askSubstancesForMood
        : this.otherSubstances
    }
  }

  @step.logState
  askSubstancesForMood(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Are you using these recreational drugs to manage your mood?"),
      prompt: {
        id: this.getPromptId("askSubstancesForMood"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askSubstancesForMood"
      },
      nextStep: this.handleSubstancesForMood
    }
  }

  @step.logStateAndResponse
  async handleSubstancesForMood(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.substancesForMood = d.response //recreational
    this.setPeople({ substancesForMood: d.response })

    return {
      nextStep: this.otherSubstances
    }
  }

  @step.logState
  otherSubstances(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Have you taken any other non-prescribed substances to manage your mood?"),
      prompt: {
        id: this.getPromptId("otherSubstances"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "otherSubstances"
      },
      nextStep: this.handleOtherSubstances
    }
  }

  @step.logStateAndResponse
  async handleOtherSubstances(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.otherSubstances = d.response // substancesOther - non-prescribed substances
    this.setPeople({ otherSubstances: d.response })

    return {
      nextStep: d.response //
        ? this.otherSubstancesOrigin
        : this.askPrescribedMedication
    }
  }

  @step.logState
  otherSubstancesOrigin(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Are these medications bought over the counter at a pharmacy or supermarket?"),
      prompt: {
        id: this.getPromptId("otherSubstancesOrigin"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "otherSubstancesOrigin"
      },
      nextStep: this.handleOtherSubstancesOrigin
    }
  }

  @step.logStateAndResponse
  async handleOtherSubstancesOrigin(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.substancesOverTheCounter = d.response // substancesAreMedication - over the counter
    this.setPeople({ substancesOverTheCounter: d.response })

    return {
      nextStep: this.askPrescribedMedication
    }
  }

  @step.logState
  askPrescribedMedication(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Are you currently taking any medication that has been prescribed by a doctor?"),
      prompt: {
        id: this.getPromptId("prescribedMedication"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "prescribedMedication"
      },
      nextStep: this.handlePrescribedMedication
    }
  }

  @step.logStateAndResponse
  async handlePrescribedMedication(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.takingPrescribedMedication = d.response
    this.setPeople({ takingPrescribedMedication: d.response })

    return {
      nextStep: d.response //
        ? this.askWhichMedication
        : this.askGPConsent
    }
  }

  @step.logState
  askWhichMedication(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Could you let me know the details of the medication you've been prescribed?"),
      prompt: {
        id: this.getPromptId("askWhichMedication"),
        type: "text",
        forceValue: true,
        dataPointsName: "askWhichMedication"
      },
      nextStep: this.handleWhichMedicationWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralMHMScript) => {
    d.state.prescribedMedications = d.response // medication - prescribed medication
    script.referralStore.setCustomField<State>("prescribedMedications", d.response)
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralMHMScript) => s.askGPConsent
  })
  handleWhichMedicationWithCrisis(_d: IStepData<State, string>): IStepResult {
    return {
      nextStep: this.askGPConsent
    }
  }

  @step.logState
  askGPConsent(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Do we have permission to contact your GP about your referral?"),
      prompt: {
        id: this.getPromptId("askGPConsent"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleGPConsent
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralMHMScript) => {
    d.state.gpConsent = d.response
    script.referralStore.setCustomField<State>("gpConsent", d.response)
  })
  handleGPConsent(d: IStepData<State, boolean>): IStepResult {
    this.referralStore.addClinicalNote(`GP Consent: ${d.state.gpConsent ? "Yes" : "No"}`)
    return { nextStep: this.goToCollectMainIssue }
  }

  @step.logState
  askMainGoal(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t(
        [
          "Thank you for sharing {name}, I know that may have been difficult",
          "What would you like to change about your situation, or feel differently about?",
          "What would you like to achieve as your goal in having therapy?"
        ],
        { name }
      ),
      prompt: {
        id: this.getPromptId("askMainGoal"),
        type: "text",
        forceValue: true,
        dataPointsName: "askMainGoal"
      },
      nextStep: this.handleMainGoalWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralMHMScript) => {
    d.state.mainGoal = d.response
    script.referralStore.setCustomField<State>("mainGoal", d.response)
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralMHMScript) =>
      s.rootStore.botStore?.bot?.activeDialogue?.state.iapt?.id ===
      IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON
        ? s.doReferralSubmission
        : s.askWhereDidYouHearAboutUs
  })
  handleMainGoalWithCrisis(d: IStepData<State, string>): IStepResult {
    if (d.state.iapt?.id === IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON) {
      return {
        nextStep: this.doReferralSubmission
      }
    }
    return {
      nextStep: this.askWhereDidYouHearAboutUs
    }
  }

  @step.logState
  askWhereDidYouHearAboutUs(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Before we finish this part, how did you hear about the service?"),
      prompt: {
        id: this.getPromptId("askWhereDidYouHearAboutUs"),
        type: "text",
        cancelIsEmptySubmit: true
      },
      nextStep: this.handleWhereDidYouHearAboutUsWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, _script: SelfReferralMHMScript) => {
    d.state.whereDidYouHearAboutService = d.response
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralMHMScript) => s.doReferralSubmission
  })
  handleWhereDidYouHearAboutUsWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.doReferralSubmission }
  }

  @step.logState
  sayReferralSucceeded(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    return {
      body: this.t(
        [
          "And that's everything",
          "You've officially been referred to {iaptName}",
          "Congratulations on taking this important step towards better mental health!"
        ],
        { iaptName }
      ),
      prompt: {
        id: this.getPromptId("sayReferralSucceeded"),
        type: "inlinePicker",
        choices: [{ body: this.t("What happens next?") }]
      },
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async getCollectReligionSettings(state: State): Promise<ICollectReligionSettings> {
    const askReligion = ["What's your religious group?"]
    const optionsReligions = this.getReligions(state).map(g => ({ body: g, value: g }))

    return {
      messages: {
        askReligion
      },
      options: optionsReligions
    }
  }

  async getCollectEmailSettings(state: State): Promise<ICollectEmailSettings> {
    return {
      messages: {
        askEmailPermission: this.t([
          state.iapt?.id === IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON
            ? "Do we have permission to send you an email (incl. webforms) to that address?"
            : "Do we have permission to send you an email to that address?"
        ]),
        askDoYouWantToShareEmail: this.t(["Do you have an email address?"])
      },
      shouldAskEmailPermission: true,
      hasPreliminaryQuestion: true,
      customisableOptions: {
        askDoYouWantToShareEmail: [
          { body: this.t("Yes, I have an email"), value: true },
          { body: this.t("No, I don't have an email"), value: false }
        ]
      }
    }
  }

  async onCollectEmailEnded(state: State): Promise<IStep> {
    return this.goToCollectPreferredCorrespondence
  }

  async getCollectGenderSettings(state: State): Promise<ICollectGenderSettings> {
    const genders = this.getGenders(state)
    const gendersSameAsBirth = this.getGenderSameAsBirthValues(state)
    // This is temporary - to be replaced by actual value when we have the dashboard
    return {
      messages: {
        askGender: this.t([
          "Thank you. We now need to ask you some demographic questions. We ask this in order to ensure we are equally supporting all members of our community",
          "Which gender do you identify as?"
        ])
      },
      optionsGender: genders.map(g => ({ body: this.t(g), value: g })),
      optionsGenderSameAsBirth: gendersSameAsBirth.map(g => ({ body: this.t(g), value: g }))
    }
  }

  async getCollectGenderState(state: State): Promise<CollectGenderScriptState> {
    return { skipSameGenderAsBirth: false }
  }

  async onCollectGenderEnded(state: State): Promise<IStep> {
    return this.goToCollectEthnicity
  }

  async onCollectEthnicityEnded(state: State): Promise<IStep> {
    return this.goToCollectSexuality
  }

  async onCollectSexualityEnded(state: State): Promise<IStep> {
    return this.askRelationshipStatus
  }

  async onCollectReligionEnded(_state: State): Promise<IStep> {
    return this.goToCollectNationality
  }

  async onCollectNationalityEnded(state: State): Promise<IStep> {
    return this.goToCollectLanguageAndInterpreter
  }

  async getCollectLanguageAndInterpreterSettings(
    state: State
  ): Promise<ICollectLanguageAndInterpreterSettings> {
    // This is temp - to use actual value when dashboard is integrated
    return {
      options: this.getLanguages(state).map(l => ({ body: l, value: l })),
      messages: {
        askPrimaryLanguage: this.t(["What is your main/preferred spoken language?"])
      }
    }
  }

  async getCollectLanguageAndInterpreterState(
    state: State
  ): Promise<CollectLanguageAndInterpreterScriptState> {
    return {
      disableInterpreterLanguageQuestion: state.spineInterpreterRequired !== undefined,
      includeInterpreterLanguageTextPrompt: true
    }
  }

  async onCollectLanguageAndInterpreterEnded(state: State): Promise<IStep> {
    return this.askRequiresSignInterpreter
  }

  async getCollectDisabilitiesSettings(state: State): Promise<ICollectDisabilitiesSettings> {
    return {
      choicesMap: this.getDisabilities(state).map(d => ({ body: this.t(d), value: d })),
      shouldAskDisabilityStatus: true,
      multiSelectDisabilities: state.iapt?.id !== IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND
    }
  }

  async onCollectDisabilitiesEnded(state: State): Promise<IStep> {
    return this.goToCollectASD
  }

  async onCollectADHDEnded(state: State): Promise<IStep> {
    return this.askPerinatal
  }

  async onHandlePerinatal(state: State): Promise<IStepResult<State>> {
    return {
      nextStep: this.askAccessibilityConsiderations
    }
  }

  async getCollectLongTermMedicalConditionSettings(
    state: State
  ): Promise<ICollectLongTermMedicalConditionSettings> {
    const result = await super.getCollectLongTermMedicalConditionSettings(state)
    return {
      ...result,
      messages: { askLongTermMedicalConditionOther: this.t(["Please specify"]) },
      shouldAskOtherDetails: true
    }
  }

  async onCollectLongTermMedicalConditionEnded(state: State): Promise<IStep> {
    return this.goToCollectAlcoholConsumption
  }

  async getCollectMainIssueState(state: State): Promise<CollectMainIssueScriptState> {
    return { hideClosingMessage: true }
  }

  async getCollectMainIssueSettings(state: State): Promise<ICollectMainIssueSettings> {
    return {
      messages: {
        askMainIssue: this.t([
          "Can you describe the problem you are seeking our help with and how long it has been troubling you?",
          "(Please give as much detail as you feel able to give)"
        ])
      }
    }
  }

  async onCollectMainIssueEnded(state: State): Promise<IStep> {
    return this.askMainGoal
  }

  getNationalities(state: State): string[] {
    return state.iapt?.referralForm?.nationalities ?? []
  }

  getReligion(state: State): RELIGION_MAYDEN_MHM {
    return religions[state.religion!] ?? "NOT_ANSWERED"
  }

  getPerinatal(state: State): PERINATAL_MAYDEN_MHM {
    return perinatalStatuses[state.perinatalStatus!] ?? "NONE"
  }

  getDisability(state: State): DISABILITY_MAYDEN_MHM[] {
    if (state.disabilityStatus === false) return ["NONE"]

    if (state.iapt?.id === IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND && state.disability) {
      return [disabilities[state.disability]]
    }

    if (!state.disabilityStatus || !state.disabilities?.length) {
      this.logBreadcrumb("getDisability without answer", state)
      this.logMessage("getDisability without answer")
    }
    return state.disabilities
      ? state.disabilities?.map(disability => disabilities[disability]).filter(Boolean)
      : ["NONE"]
  }

  getArmedForce(state: State): ARMED_FORCES_MAYDEN_MHM {
    return exArmedForces[state.isExArmedForces!] ?? "NOT_ANSWERED"
  }

  getNationality(state: State): NATIONALITY_MAYDEN {
    return nationalities[state.nationality!] || "NOT_LISTED"
  }

  getEthnicity(state: State): ETHNICITY_MAYDEN_MHM {
    return ethnicities[state.ethnicity!] ?? "NOT_ANSWERED"
  }

  getGender(state: State): GENDER_MAYDEN_MHM {
    return genders[state.gender!] ?? "NOT_ANSWERED"
  }

  getGenderSameAsBirthAssigned(state: State): GenderBirthAssigned {
    const genderSameAsBirthAssigned = sameGenderAsBirth[state.sameGenderAsBirth!]
    return genderSameAsBirthAssigned ?? "NOT_ANSWERED"
  }

  getSexuality(state: State): SEXUALITY_MAYDEN_MHM | undefined {
    // NOT_LISTED when the response if a free text (custom input)
    return sexualities[state.sexuality!] || "NOT_ANSWERED"
  }

  getLanguage(state: State): LANGUAGE_MAYDEN_MHM | undefined {
    const spineLanguageKey = `${state.spineLanguage ?? ""}`.toLowerCase()
    const primaryLanguageKey = `${state.primaryLanguage ?? ""}`.toLowerCase()
    const languagesLowerCase = toLowerCaseProperties(languages)
    return languagesLowerCase[spineLanguageKey] ?? languagesLowerCase[primaryLanguageKey]
  }

  getLTC(state: State): LTC_MAYDEN_MHM[] | undefined {
    const ltc = state.longTermMedicalCondition?.map(i => ltcs[i]).filter(Boolean)
    return ltc?.length ? ltc : undefined
  }

  getLTCMoodImpact(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      somewhat: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcMoodImpact!]
  }

  getLTCManagement(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      fairly: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcManagement!]
  }

  getLanguages(state: State): string[] {
    return state.iapt?.referralForm?.languages ?? []
  }

  async getReferralPayload(
    state: State
  ): Promise<
    | ReferralPayloadMaydenMHMNorthumberland
    | ReferralPayloadMaydenMHMWarrington
    | ReferralPayloadMaydenMHMSefton
  > {
    const instanceID = state.iapt?.backendInstanceID
    invariant(instanceID, "Cannot create referral without an Instance ID")
    const isValidMobile = isValidMobilePhone(state.phoneNumber || "0")
    const isValidLandline = isValidLandlineNumber(state.phoneNumber || "0") && !isValidMobile
    const parsed = parsePhoneNumber(state.phoneNumber || "0", { regionCode: "GB" })

    // TODO should be moved somewhere else but setting ADSM to active
    // here for now, until insight is done with the consent and randomisation
    this.clinicalStore.setADSMActive(true)

    const basePayload = {
      instanceID,
      nameFirst: this.getFirstName(state),
      nameLast: this.getLastName(state),
      nameFirstPreferred: state.preferredName,
      title: state.preferredTitle,
      pronounsPreferredMHM: state.preferredPronouns,
      dob: moment(state.birthday).format("YYYY-MM-DD"),
      addressHome: {
        address1: state.address,
        address2: state.address2,
        // If address is entered manually then city/county/postcode are undefined
        // Pass an alternate value to avoid errors in the referral submission
        city: state.city || "unknown",
        county: state.county || "unknown",
        postcode: state.userPostcode?.postcode || state.invalidPostcodeEntered || "unknown",
        consentMail: !!state.canSendMailToAddress
      },
      gpName: state.odsGP?.name ?? state.gp?.name,
      phoneHome: isValidLandline
        ? {
            cc: String(parsed.countryCode || ""),
            number: parsed.number?.national.replace(/ /g, "") ?? state.phoneNumber!,
            isMobile: false,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      phoneMobile: isValidMobile
        ? {
            cc: String(parsed.countryCode || ""),
            number: parsed.number?.national.replace(/ /g, "") ?? state.phoneNumber!,
            isMobile: true,
            consentSMS: !!state.canSendTextMessagesToPhoneNumber,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      email: state.email,
      consentEmail: state.canSendEmail,
      consentDataShare: true,
      consentDataStore: true,
      output: this.referralStore.referralType,
      riskLevel: this.clinicalStore.riskLevel,
      riskLevelReason: this.clinicalStore.riskLevelReason,
      triggerWords: this.clinicalStore.triggerWords,
      alcohol: state.alcohol,
      alcoholFrequency: state.alcoholFrequency,
      alcoholQuantity: state.alcoholQuantity,
      substances: state.substances,
      substancesForMood: state.substancesForMood,
      substancesOther: state.otherSubstances,
      substancesAreMedication: state.substancesOverTheCounter,
      medication: state?.prescribedMedications ? [state.prescribedMedications] : [],
      ltc: this.getLTC(state),
      ltcAffectMood: state.ltcAffectsMood,
      ltcMoodImpact: this.getLTCMoodImpact(state),
      ltcManagement: this.getLTCManagement(state),
      ltcOtherDetails: state?.ltcOtherDetails,
      disability: this.getDisability(state),
      language: this.getLanguage(state),
      interpreterSignLanguage: state.requiresSignInterpreter,
      interpreter:
        state.spineInterpreterRequired ??
        !!this.referralStore.getCustomField<State>("requiresInterpreter"),
      sexuality: this.getSexuality(state),
      gender: this.getGender(state),
      genderSameAsBirthAssigned: this.getGenderSameAsBirthAssigned(state),
      ethnicity: this.getEthnicity(state),
      armedForces: this.getArmedForce(state),
      nationality: this.getNationality(state),
      religion: this.getReligion(state),
      problemInOwnWords: state?.mainIssue,
      treatmentExpectation: state?.mainGoal,
      perinatal: this.getPerinatal(state),
      questionnaires: this.getQuestionnairesPayload(state),
      consentResearch: state.consentResearch,
      currentSupport: state?.currentTherapy,
      currentSupportDetailsOther: state?.currentTherapyDetails,
      pastTreatment: state?.previousTherapy,
      pastTreatmentDetails: state?.previousTherapyDetails,
      keyWorker: state?.nhsStaff,
      correspondencePreferred: state?.preferredContactMethod,
      emergencyContactName: state?.nameOfNextOfKin,
      emergencyContactRelationship: state?.relationshipOfNextOfKin,
      emergencyContactPhone: state?.phoneNumberOfNextOfKin,
      accessibilityIssue: state.accessibilityConsiderations,
      educationSetting: state.education,
      educationWorkImpact: state.educationOrWorkImpact,
      employmentAdvisorAccepted: state.employmentAdvisor,
      accommodationStatusDetails: state.accommodationStatus,
      adhd: state?.hasADHD,
      asd: state?.hasASD,
      clinicalNotes: this.referralStore.clinicalNotes
    }

    if (instanceID === "MHM_WARRINGTON") {
      return {
        ...basePayload,
        phoneType: state.preferredPhoneType || "Mobile",
        gpPhone: state.odsGP?.phone.join(", ") ?? state.gp?.phoneNumber
      }
    } else if (instanceID === "MHM_SEFTON") {
      return {
        ...basePayload,
        whereHeard: state.whereDidYouHearAboutService,
        keyWorker: state.nhsStaff
      }
    } else if (instanceID === "MHM_NORTHUMBERLAND") {
      return {
        ...basePayload,
        whereHeard: state.whereDidYouHearAboutService,
        gpAddress: state.odsGP?.address
          ? `${state.odsGP.address.address1}, ${state.odsGP.address.address2}, ${state.odsGP.address.city}, ${state.odsGP.address.postcode}`
          : `${state.gp?.address1}, ${state.gp?.address2}, ${state.gp?.postcode}`
      }
    } else {
      throw Error("Unknown backendID")
    }
  }
}

export default class SelfReferralMHMDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralMHM
  readonly name: string = "SelfReferralMHMDialogue"

  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(SelfReferralMHMDialogue.id, new SelfReferralMHMScript(), state, snapshot)
  }
}
