/* eslint-disable @typescript-eslint/no-empty-function */
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../../DialogueIDs"
import { step } from "../../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { ISelectableExtended } from "@limbic/types"
import BaseScript, { BaseScriptState } from "../../../BaseScript"

export type ICollectCurrentMHTreatmentSettings = {
  options?: ISelectableExtended[] | undefined
}

interface State extends BaseScriptState {
  currentMHTreatment?: boolean
  currentMHTreatmentDetails?: string[]
}

export type CollectCurrentMHTreatmentDetailsScriptState = State

export class CollectCurrentMHTreatmentDetailsScript extends BaseScript<State> {
  readonly name: string = "CollectCurrentMHTreatmentDetailsScript"
  protected options: ISelectableExtended[] | undefined
  constructor(settings: ICollectCurrentMHTreatmentSettings | undefined = {}) {
    super()
    this.options = settings.options
  }

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askCurrentMHTreatment }
  }

  @step.logState
  askCurrentMHTreatment(d: IStepData<State>): IStepResult {
    const professionals = this.options
    if (!professionals?.length) {
      this.logBreadcrumb("MH PROFESSIONALS NOT FOUND", d.state, { professionals })
      this.logMessage("MH PROFESSIONALS NOT FOUND")
      return { nextStep: this.end }
    }
    return {
      body: this.t(
        "Are you currently seeing a mental health professional?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askCurrentMHTreatment"),
        trackResponse: true,
        type: "inlinePickerMultiSelect",
        choices: professionals.map(p =>
          p.selectIndividually ? { ...p, body: this.t(p.body), value: null } : p
        ),
        dataPointsName: "askCurrentSupport"
      },
      nextStep: this.handleCurrentMHTreatment
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, Array<string | null>>, script: CollectCurrentMHTreatmentDetailsScript) => {
      const filteredResponse = (d.response?.filter(Boolean) || []) as string[]
      d.state.currentMHTreatment = !!filteredResponse.length
      d.state.currentMHTreatmentDetails = filteredResponse
      script.referralStore.setCustomField<State>("currentMHTreatment", d.state.currentMHTreatment)
      script.referralStore.setCustomField<State>(
        "currentMHTreatmentDetails",
        d.state.currentMHTreatmentDetails
      )
    }
  )
  handleCurrentMHTreatment(_d: IStepData<State, Array<string | null>>): IStepResult {
    return { nextStep: this.end }
  }

  /** Generic Handlers */
}

/* istanbul ignore next */
export default class CollectCurrentMHTreatmentDetailsDialogue extends AdHocDialogue<
  State,
  CollectCurrentMHTreatmentDetailsScript
> {
  static id = DialogueIDs.CollectCurrentMHTreatmentDetails
  readonly name: string = "CollectCurrentMHTreatmentDetailsDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: ICollectCurrentMHTreatmentSettings
  ) {
    super(
      CollectCurrentMHTreatmentDetailsDialogue.id,
      new CollectCurrentMHTreatmentDetailsScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
