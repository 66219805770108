import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../DialogueIDs"
import CrisisScript, { CrisisScriptState } from "./CrisisScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { MHMCrisisPhoneMappedText } from "../../../config/apiKeysMap"

type State = CrisisScriptState
export type CrisisMHMScriptState = State

export class CrisisMHMScript extends CrisisScript {
  readonly name: string = "CrisisMHMScript"

  @step.logState
  sayCrisis(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    const crisisPhoneNumbers = this.rootStore.configStore.crisisPhoneNumbers
    return {
      body: this.t(
        [
          "Sorry to hear that {name}",
          "It is normal for people to have thoughts of this nature at times",
          "However {organisationName} is not an emergency response service",
          "To get more urgent help, please call NHS 111 and select Option 2",
          "If you need urgent, life threatening medical help please call 999",
          "Alternatively, you can also call Samaritans on 116 123",
          "You could also give any one of our services a call 24/7 on the following phone numbers:\n{crisisPhoneNumbers}",
          "You can refer yourself back to {organisationName} when you are no longer in crisis"
        ],
        { name, organisationName, crisisPhoneNumbers }
      ),
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("I understand") }, { body: this.t("Okay") }]
      },
      nextStep: this.handleSayCrisis
    }
  }

  @step.logState
  handleSayCrisis(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "999, NHS 111 option 2 and Samaritans (116 123)"
    )
    return { nextStep: this.goToGoodbye }
  }

  @step
  saySorryToHear(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const name = this.getName(d.state)
    return {
      body: this.t(
        [
          "Sorry to hear that {name}",
          "It is normal for people to have thoughts of this nature at times",
          "However {organisationName} does not provide urgent care",
          "If you need urgent support, please dial NHS 111 and select Option 2",
          "In medical emergency and life threatening situations only, please dial 999 or attend your local A&E department"
        ],
        { name, organisationName }
      ),
      prompt: {
        id: this.getPromptId("saySorryToHear"),
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }]
      },
      nextStep: this.sayCrisisNumbers
    }
  }

  @step.logState
  sayCrisisNumbers(d: IStepData<State>): IStepResult {
    const specificPhoneNumber = d.state.iapt?.id
      ? [MHMCrisisPhoneMappedText[d.state.iapt?.id]]
      : [
          "You could also give any one of our services a call 24/7 on the following phone numbers:\n" +
            this.rootStore.configStore.crisisPhoneNumbers
        ]
    return {
      body: this.t([
        "Other helplines available:",
        "You can contact Samaritans 24 hours a day, 365 days a year",
        "You can call 116 123 (free from any phone) or email jo@samaritans.org",
        "If you would prefer not to talk but want some mental health support, you could text SHOUT to 85258",
        "[Shout](https://giveusashout.org/) offers a confidential 24/7 text service providing support if you are in need of immediate help",
        ...specificPhoneNumber
      ]),
      prompt: {
        id: this.getPromptId("sayCrisisNumbers"),
        type: "inlinePicker",
        choices: [{ body: this.t("I understand") }, { body: this.t("Okay") }]
      },
      nextStep: this.handleCrisisNumbers
    }
  }

  @step.logState
  handleCrisisNumbers(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName

    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "999, NHS 111 and select Option 2 and Samaritans"
    )

    return {
      body: d.state.birthday
        ? this.t(
            [
              "We’ll continue with the referral, however {organisationName} does not provide urgent care",
              "If you require immediate help, please contact one of the numbers listed above"
            ],
            { organisationName }
          )
        : undefined,
      nextStep: d.state.birthday ? this.end : this.goToGoodbye
    }
  }

  @step.logState
  saySorryForTheMisunderstanding(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Okay",
        "My creators have taught me to listen carefully for specific words or phrases",
        "In case you need help",
        "Sorry for the misunderstanding",
        "Ok, let's carry on with the mental health check in"
      ]),
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onHandlePhoneNumber(state: State): Promise<IStepResult> {
    return {
      body: this.t(["Thanks"]),
      nextStep: this.sayCrisisNumbers
    }
  }

  async onHandleAreYouInCrisis(state: State): Promise<IStepResult> {
    return {
      nextStep: this.clinicalStore.isCrisis //
        ? state.birthday
          ? this.saySorryToHear
          : this.sayCrisis
        : this.saySorryForTheMisunderstanding
    }
  }
}

/* istanbul ignore next */
export default class CrisisMHMDialogue extends AdHocDialogue<State, CrisisMHMScript> {
  static id = DialogueIDs.CrisisMHM
  readonly name: string = "CrisisMHMDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(CrisisMHMDialogue.id, new CrisisMHMScript(), state, snapshot)
  }
}
