import Logger from "./Logger"

export default function validateCustomFields(
  allFields: string[],
  requiredFields: string[],
  customFields: Record<string, any> = {}
): void {
  try {
    const allNonRequiredFields = allFields.filter(key => !requiredFields.includes(key))
    const passedCustomFieldKeys = Object.keys(customFields)
    // prettier-ignore
    const notRequiredButNotPassedKeys = allNonRequiredFields.filter(k => !passedCustomFieldKeys.includes(k))
    // prettier-ignore
    const requiredButNotPassedKeys = requiredFields.filter(key => !passedCustomFieldKeys.includes(key))
    const notSupportedKeys = passedCustomFieldKeys.filter(key => !allFields.includes(key))
    // prettier-ignore
    const undefinedKeys = passedCustomFieldKeys.filter(key => customFields && customFields[key] == null)
    if (notRequiredButNotPassedKeys.length) {
      // prettier-ignore
      console.warn(`custom fields [${notRequiredButNotPassedKeys.join(", ")}] are not always required but have not been passed`)
    }
    if (undefinedKeys.length) {
      console.error(`custom fields [${undefinedKeys.join(", ")}] should not be undefined or null`)
    }
    if (requiredButNotPassedKeys.length) {
      // prettier-ignore
      console.error(`custom fields [${requiredButNotPassedKeys.join(", ")}] are required but not passed`)
    }
    if (notSupportedKeys.length) {
      console.error(`custom fields passed [${notSupportedKeys.join(", ")}] are not supported`)
    }
    if (requiredButNotPassedKeys.length || notSupportedKeys.length) {
      console.error(`Supported keys are: ${allFields.join(", ")}`)
      // prettier-ignore
      console.error(`Custom fields env variable should be in the format {"key": "value", "key": "value"}`)
    }
  } catch (e) {
    Logger.getInstance().exception(e, "validateCustomFields error")
  }
}
