import { z, ZodSchema } from "zod"
import {
  AssessmentPitchIAPTScript,
  AssessmentPitchIAPTScriptStateSchema
} from "./AssessmentPitchIAPTDialogue"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../DialogueIDs"
import type { AssessmentPitchIAPTScriptState } from "./AssessmentPitchIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

interface State extends AssessmentPitchIAPTScriptState {
  mainIssue?: string
}

export type AssessmentPitchLincolnshireState = State

export const AssessmentPitchLincolnshireStateSchema = AssessmentPitchIAPTScriptStateSchema.extend({
  mainIssue: z.string().optional()
})

export class AssessmentPitchLincolnshireScript extends AssessmentPitchIAPTScript {
  readonly name: string = "AssessmentPitchLincolnshireScript"

  /** Script Steps */

  @step.logState
  checkForNeedToBeCalled(_d: IStepData<State>): IStepResult {
    return { nextStep: this.sayWeCanSpeedUpReferral }
  }

  @step.logState
  sayWeCanSpeedUpReferral(d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "I'm now going to ask you a few questions to measure symptoms of common mental health issues",
        "And I will use the results to match you with the most appropriate therapy"
      ]),
      prompt: {
        id: this.getPromptId("sayWeCanSpeedUpReferral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }]
      },
      nextStep: this.end
    }
  }

  @step.logState
  saySomeoneFromAdminWillContact(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Someone from our admin team will be in touch within 1-2 working days to book you in for an assessment with one of our clinicians"
      ),
      prompt: {
        id: this.getPromptId("saySomeoneFromAdminWillContact"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Looking forward to it") }, { body: this.t("Okay") }]
      },
      nextStep: this.goToGoodbye
    }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return AssessmentPitchLincolnshireStateSchema
  }
}

/* istanbul ignore next */
export default class AssessmentPitchLincolnshireDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentPitchLincolnshire
  readonly name: string = "AssessmentPitchLincolnshireDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      AssessmentPitchLincolnshireDialogue.id,
      new AssessmentPitchLincolnshireScript(),
      state,
      snapshot
    )
  }
}
