import { IAPTGPMap, IAPTIDs } from "../../models/IIAPTService"

// NOTE: List is here:
// https://docs.google.com/spreadsheets/d/1OaZkKQ4PuoNcbMsMizXEgauHhTNaUfmp/edit#gid=896471914

const iaptOxfordshireGPs: IAPTGPMap = {
  A91029: IAPTIDs.OXFORDSHIRE,
  A91030: IAPTIDs.OXFORDSHIRE,
  A91108: IAPTIDs.OXFORDSHIRE,
  K84001: IAPTIDs.OXFORDSHIRE,
  K84002: IAPTIDs.OXFORDSHIRE,
  K84003: IAPTIDs.OXFORDSHIRE,
  K84006: IAPTIDs.OXFORDSHIRE,
  K84007: IAPTIDs.OXFORDSHIRE,
  K84008: IAPTIDs.OXFORDSHIRE,
  K84009: IAPTIDs.OXFORDSHIRE,
  K84010: IAPTIDs.OXFORDSHIRE,
  K84011: IAPTIDs.OXFORDSHIRE,
  K84013: IAPTIDs.OXFORDSHIRE,
  K84014: IAPTIDs.OXFORDSHIRE,
  K84015: IAPTIDs.OXFORDSHIRE,
  K84016: IAPTIDs.OXFORDSHIRE,
  K84017: IAPTIDs.OXFORDSHIRE,
  K84019: IAPTIDs.OXFORDSHIRE,
  K84020: IAPTIDs.OXFORDSHIRE,
  K84021: IAPTIDs.OXFORDSHIRE,
  K84023: IAPTIDs.OXFORDSHIRE,
  K84024: IAPTIDs.OXFORDSHIRE,
  K84025: IAPTIDs.OXFORDSHIRE,
  K84026: IAPTIDs.OXFORDSHIRE,
  K84027: IAPTIDs.OXFORDSHIRE,
  K84028: IAPTIDs.OXFORDSHIRE,
  K84030: IAPTIDs.OXFORDSHIRE,
  K84031: IAPTIDs.OXFORDSHIRE,
  K84032: IAPTIDs.OXFORDSHIRE,
  K84033: IAPTIDs.OXFORDSHIRE,
  K84035: IAPTIDs.OXFORDSHIRE,
  K84036: IAPTIDs.OXFORDSHIRE,
  K84037: IAPTIDs.OXFORDSHIRE,
  K84038: IAPTIDs.OXFORDSHIRE,
  K84041: IAPTIDs.OXFORDSHIRE,
  K84042: IAPTIDs.OXFORDSHIRE,
  K84043: IAPTIDs.OXFORDSHIRE,
  K84044: IAPTIDs.OXFORDSHIRE,
  K84045: IAPTIDs.OXFORDSHIRE,
  K84046: IAPTIDs.OXFORDSHIRE,
  K84047: IAPTIDs.OXFORDSHIRE,
  K84048: IAPTIDs.OXFORDSHIRE,
  K84049: IAPTIDs.OXFORDSHIRE,
  K84050: IAPTIDs.OXFORDSHIRE,
  K84052: IAPTIDs.OXFORDSHIRE,
  K84054: IAPTIDs.OXFORDSHIRE,
  K84055: IAPTIDs.OXFORDSHIRE,
  K84056: IAPTIDs.OXFORDSHIRE,
  K84058: IAPTIDs.OXFORDSHIRE,
  K84059: IAPTIDs.OXFORDSHIRE,
  K84060: IAPTIDs.OXFORDSHIRE,
  K84062: IAPTIDs.OXFORDSHIRE,
  K84063: IAPTIDs.OXFORDSHIRE,
  K84065: IAPTIDs.OXFORDSHIRE,
  K84066: IAPTIDs.OXFORDSHIRE,
  K84071: IAPTIDs.OXFORDSHIRE,
  K84072: IAPTIDs.OXFORDSHIRE,
  K84075: IAPTIDs.OXFORDSHIRE,
  K84077: IAPTIDs.OXFORDSHIRE,
  K84079: IAPTIDs.OXFORDSHIRE,
  K84080: IAPTIDs.OXFORDSHIRE,
  K84082: IAPTIDs.OXFORDSHIRE,
  K84605: IAPTIDs.OXFORDSHIRE,
  K84610: IAPTIDs.OXFORDSHIRE,
  K84613: IAPTIDs.OXFORDSHIRE,
  K84615: IAPTIDs.OXFORDSHIRE,
  K84618: IAPTIDs.OXFORDSHIRE,
  K84620: IAPTIDs.OXFORDSHIRE,
  K84624: IAPTIDs.OXFORDSHIRE,
  M82012: IAPTIDs.OXFORDSHIRE
}

export const iaptGPMapOxford: IAPTGPMap = {
  ...iaptOxfordshireGPs
}
