import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { UnderAgeSignpostScript, UnderAgeSignpostScriptState } from "./UnderAgeSignpostDialogue"
import { DialogueIDs } from "../../DialogueIDs"

type State = UnderAgeSignpostScriptState
export type UnderAgeSignpostBexleyScriptState = State

export class UnderAgeSignpostBexleyScript extends UnderAgeSignpostScript {
  readonly name: string = "UnderAgeSignpostBexleyScript"

  /** Script Steps */
  @step.logState
  sayNotEligible(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const ageThreshold = this.rootStore.configStore.ageThreshold
    const organisationName = this.rootStore.configStore.organisationName

    return {
      body: this.t(
        [
          "So {name}, unfortunately {organisationName} are only able to provide support for people aged {ageThreshold} and above",
          "However, you can find helpful resources below:",
          "1. The Mix: a free and confidential service that aims to find young people the best help, whatever the problem [https://www.themix.org.uk/](https://www.themix.org.uk/)\n" +
            "2. Childline: 0800 1111\n" +
            "3. [https://youngminds.org.uk/](https://youngminds.org.uk/)\n" +
            "4. Counselling Matters Bexley this service offers low cost therapy, you can contact them on 0800 047 2600 or email mail@cmbtalk.org\n" +
            "5. [https://www.kooth.com/](https://www.kooth.com/) Kooth offers online support to anyone aged 11-19 in Bexley borough\n" +
            "6. [http://www.bexleymoorings.co.uk/](http://www.bexleymoorings.co.uk/)",
          "Or we would suggest that you talk to your doctor and they may be able to refer you to a more suitable service"
        ],
        { name, ageThreshold, organisationName }
      ),
      prompt: {
        id: this.getPromptId("sayNotEligible"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Okay", value: "okay" },
          { body: "Thanks", value: "thanks" }
        ]
      },
      nextStep: this.handleNotEligible
    }
  }

  @step
  handleNotEligible(d: IStepData<State, "okay" | "thanks">): IStepResult {
    return {
      body: this.t(d.response === "thanks" ? "You're welcome" : "Great"),
      nextStep: this.sayCrisisCallLine
    }
  }
}

export default class UnderAgeSignpostDialogue extends Dialogue<State> {
  static id = DialogueIDs.UnderAgeSignpostBexley
  readonly name: string = "UnderAgeSignpostBexleyDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(UnderAgeSignpostDialogue.id, new UnderAgeSignpostBexleyScript(), state, snapshot)
  }
}
