import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { GoodbyeInsightScript, GoodbyeInsightScriptState } from "./GoodbyeInsightDialogue"

type State = GoodbyeInsightScriptState

export type GoodbyeInsightFollowUpScriptState = State
export class GoodbyeInsightFollowUpScript extends GoodbyeInsightScript {
  readonly name: string = "GoodbyeInsightFollowUpScript"

  /** Generic Handler */
  async getReferredYouMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted) {
      const organisationName = this.rootStore.configStore.organisationName
      const iaptName = this.getIAPTName(state) || organisationName
      return this.t(
        state.isIdleSubmitted //
          ? "It looks like there hasn’t been any activity for some time so I've shared your responses with the {iaptName} team"
          : "I have shared your responses with the {iaptName} team. These will be discussed with you at your upcoming assessment",
        { iaptName }
      )
    }
  }

  getRiskRecapMessage(_state: State): string | undefined {
    return this.t(
      "Some of your answers suggest you may be struggling quite a bit right now.\n\n" +
        "If you need urgent help please follow this [link](https://www.insightiapt.org/need-urgent-help/local-nhs-crisis-contacts/) to see what services are available to you locally"
    )
  }
}

/* istanbul ignore next */
export default class GoodbyeInsightFollowUpDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeInsightFollowUp
  readonly name: string = "GoodbyeInsightFollowUpDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeInsightFollowUpDialogue.id, new GoodbyeInsightFollowUpScript(), state, snapshot)
  }
}
