export type AppointmentTimeslot = {
  time: string
  duration: string
  practicioner?: string
  id: string
  timestamp: string
  startTime?: string
  endTime?: string
}

export default interface IAppointment {
  date: string
  timeslots: AppointmentTimeslot[]
}

export enum IAppointmentStatus {
  Success = "SUCCESS",
  RequestFailed = "REQUEST_FAILED",
  NoInternetConnection = "NO_INTERNET_CONNECTION"
}
