import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { IStepResult } from "../../../backend/chatbot/models/IStep"
import { DialogueIDs } from "../../DialogueIDs"
import {
  EligibilityCheckWithPDSScript,
  EligibilityCheckWithPDSScriptState
} from "./EligibilityCheckWithPDSScript"

type State = EligibilityCheckWithPDSScriptState
export type EligibilityCheckMPFTScriptState = State

export class EligibilityCheckMPFTScript extends EligibilityCheckWithPDSScript {
  readonly name: string = "EligibilityCheckMPFTScript"
  readonly FAILED_ATTEMPTS_THRESHOLD: number = 3

  /** Generic Handlers */

  async onFailedSpineSearchCountReached(state: State): Promise<IStepResult> {
    return { nextStep: this.sayICouldntFindYouInPDSAndGoManual }
  }
}

/* istanbul ignore next */
export default class EligibilityCheckMPFTDialogue extends Dialogue<State> {
  static id = DialogueIDs.EligibilityCheckMPFT
  readonly name: string = "EligibilityCheckMPFTDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(EligibilityCheckMPFTDialogue.id, new EligibilityCheckMPFTScript(), state, snapshot)
  }
}
