import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import BaseScript, { BaseScriptState, BaseScriptStateSchema } from "../../BaseScript"
import { ZodType } from "zod"

type State = BaseScriptState
export type ResearchConsentScriptState = State
export const ResearchConsentScriptStateSchema = BaseScriptStateSchema.extend({})

export class ResearchConsentScript extends BaseScript<State> {
  readonly name: string = "ResearchConsentScript"

  onHandleResearchConsent?(state: State): Promise<IStepResult | void>

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askResearchConsent }
  }

  @step.logState
  askResearchConsent(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "I'm also helping mental health researchers. They'd like to link your answers here to the data that mental health services store on your progress, click [here](https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/documents/InformationSheet_LimbicAccess_v2.0.pdf) to find more information",
        "Would you like to contribute to research by sharing your anonymised data?"
      ]),
      prompt: {
        id: this.getPromptId("askResearchConsent"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleResearchConsent
    }
  }

  @step.logState
  async handleResearchConsent(d: IStepData<State>): Promise<IStepResult> {
    d.state.consentResearch = d.response
    await this.referralStore
      .updateReferral({ consentResearch: d.response })
      .catch(e => this.logException(e, "handleResearchConsent"))
    const result = await this.onHandleResearchConsent?.(d.state)
    if (result) return result
    if (d.response) return { nextStep: this.sayThanks }
    return { nextStep: this.sayNoWorries }
  }

  @step.logState
  sayNoWorries(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t("No worries, {name}. Let's continue", { name }),
      nextStep: this.end
    }
  }

  @step.logState
  sayThanks(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t("Thank you {name}! Let's continue", { name }),
      nextStep: this.end
    }
  }

  /** Generic Handlers */
  getStateSchema(): ZodType | undefined {
    return ResearchConsentScriptStateSchema
  }
}
