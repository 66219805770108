import { step } from "../../../backend/chatbot/decorators/step"
import type { IntroductionIAPTScriptState } from "./IntroductionIAPTDialogue"
import {
  IntroductionIAPTScript,
  IntroductionIAPTScriptStateSchema
} from "./IntroductionIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { z, ZodSchema } from "zod"

export type TreatmentPaths = "Self Referral" | "Wellbeing Courses and Webinars" | "Online Modules"
export type AppointmentOption = "Self Referral" | "Wellbeing Courses and Webinars"

interface State extends IntroductionIAPTScriptState {
  treatmentPath?: "Silvercloud" | "Self Referral"
}

export const IntroductionOxfordStateSchema = IntroductionIAPTScriptStateSchema.extend({
  treatmentPath: z.union([z.literal("Silvercloud"), z.literal("Self Referral")]).optional()
})

export class IntroductionOxfordScript extends IntroductionIAPTScript {
  readonly name: string = "IntroductionOxfordScript"

  @step.logState
  greet(_d: IStepData<State>): IStepResult {
    const { directReferral } = this.rootStore.configStore
    const organisationName = this.rootStore.configStore.organisationName
    const formLink = this.rootStore.configStore.formLink
    return {
      body: this.t(
        [
          "Hi there!",
          "I’m Limbic",
          "I'm a friendly robot assistant to help you access psychological support from {organisationName}",
          "If you prefer just to fill in our e-referral form yourself click [here]({formLink})",
          "Please note your referral information will be sent at the end of the self referral questions"
        ],
        { organisationName, formLink }
      ),
      nextStep: directReferral ? this.sayStaticReferralURL : this.askCanIHelp
    }
  }

  sayStaticReferralURL(_d: IStepData<State>): IStepResult {
    const { formLink } = this.rootStore.configStore
    if (formLink) {
      return {
        body: this.t(
          "If you are not in need of urgent help today and prefer to fill in a standard web form, you can do so [here]({formLink})",
          { formLink }
        ),
        isStaticReferralURL: true,
        nextStep: this.askTreatmentPath
      }
    }
    return { nextStep: this.askTreatmentPath }
  }

  @step.logState
  handleCanIHelp(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.askTreatmentPath }
    }
    return { nextStep: this.sayIfYouChangeYourMindWakeMeUp }
  }

  /* Script Steps */

  @step.logState
  askTreatmentPath(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName

    return {
      body: [
        `${organisationName} has a number of treatment choices or options available to offer you`,
        "To help you choose the right level of support and a choice of evidence based therapy, we will discuss the following options at your assessment",
        "You can find more information about options [here](https://www.oxfordhealth.nhs.uk/oxon-talking-therapies/getting-help/) or access our online therapy programme Silvercloud now",
        "Which treatment path are you most interested in?"
      ],
      prompt: {
        id: this.getPromptId("askTreatmentPath"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          {
            body: "Continue with self-referral for talking therapy",
            value: "Self Referral"
          },
          { body: "Start online therapy with Silvercloud now", value: "Silvercloud" }
        ]
      },
      nextStep: this.handleTreatmentPath
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, "Silvercloud" | "Self Referral">, script: IntroductionOxfordScript) => {
      d.state.treatmentPath = d.response
      script.referralStore.setCustomField<State>("treatmentPath", d.response)
    }
  )
  handleTreatmentPath(d: IStepData<State, "Silvercloud" | "Self Referral">): IStepResult {
    if (d.response === "Silvercloud") {
      return { nextStep: this.signpostSilvercloud }
    }
    return {
      body: "Please note this self referral will take around 10-15 minutes to complete",
      nextStep: this.end
    }
  }

  @step.logState
  signpostSilvercloud(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Please visit the link below to get started with your online therapy",
        "Write 'Hello Limbic' at any time if you want to carry on with me instead"
      ],
      prompt: {
        id: this.getPromptId("signpostSilvercloud"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          {
            body: "Online Therapy 👉",
            onPress: () => {
              const a = document.createElement("a")
              a.setAttribute(
                "href",
                "https://www.oxfordhealth.nhs.uk/oxon-talking-therapies/getting-help/online/"
              )
              a.setAttribute("target", "_blank")
              a.click()
            }
          }
        ],
        textPrompt: {
          placeholder: 'Type "Hello Limbic" to continue here',
          forceValue: true
        }
      },
      nextStep: this.handleTypeHeyLimbicToWakeMeUpWithCrisis
    }
  }

  getStateSchema(): ZodSchema | undefined {
    return IntroductionOxfordStateSchema
  }
}

/* istanbul ignore next */
export default class IntroductionOxfordDialogue extends Dialogue<State> {
  static id = DialogueIDs.IntroductionOxford
  readonly name: string = "IntroductionOxfordDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(IntroductionOxfordDialogue.id, new IntroductionOxfordScript(), state, snapshot)
  }
}
