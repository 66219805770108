import Logger from "../../../utils/Logger"
import delay from "../../../utils/delay"
import { postToSlack } from "../../../utils/slack"
import { SendEmailStatus } from "../../../models/ISendEmail"
import client from "./_client"

const TOTAL_RETRIES = 2

export interface EmailProps {
  to: string[]
  from?: string
  cc?: string[]
  bcc?: string[]
  text: string
  subject?: string
  attachmentUrls?: string[]
}

export default async function sendEmail(
  props: EmailProps,
  organisationName?: string,
  retry = 0
): Promise<SendEmailStatus> {
  const { to, cc, bcc, text, subject, attachmentUrls, from } = props
  const body = { to, cc, bcc, text, subject, attachmentUrls, from }
  try {
    await client.post("/v1/email/enqueue", body) // 👈 data is empty
    return SendEmailStatus.Success
  } catch (e) {
    Logger.getInstance().exception(e, "sendEmail fetch failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`email data for retry ${retry}`, JSON.stringify(body))
      Logger.getInstance().message("sendEmail Retry")
      void postToSlack({ text: getSlackRetryMessage(organisationName, subject, to, retry) })
      await delay(3 * retry || 1.5)
      return await sendEmail(props, organisationName, retry + 1)
    }
    logLongJSON("sendEmail fetch failed data", JSON.stringify(body))
    Logger.getInstance().exception(e, "sendEmail fetch failed")
    void postToSlack({ text: getSlackFailureMessage(organisationName, subject, to, retry) })
    return SendEmailStatus.SendEmailFailed
  }
}

function getSlackRetryMessage(
  organisationName: string | undefined,
  subject: string | undefined,
  to: string[],
  retry: number
): string {
  return `🔄 *RETRY [${organisationName}]:* Email with subject *${subject}* to *${to}* failed to sent on attempt ${
    retry + 1
  } [Limbic Access Bot]`
}

function getSlackFailureMessage(
  organisationName: string | undefined,
  subject: string | undefined,
  to: string[],
  retries: number
): string {
  return `❌ *FAILURE [${organisationName}]:* Email with subject *${subject}* to *${to}* failed to sent after ${retries} retries [Limbic Access Bot]`
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}
