import React from "react"
import "./TypingIndicator.scss"
import { useConfigStore } from "../../contexts/RootStoreContext"

export default function TypingIndicator(): JSX.Element {
  return (
    <div className="lb-typing-indicator-container">
      <div className="lb-typing-indicator-content">
        <Ball />
        <Ball animationDelay={"200ms"} />
        <Ball animationDelay={"400ms"} />
      </div>
    </div>
  )
}

interface BallProps {
  animationDelay?: string
}

function Ball(props: BallProps) {
  const { animationDelay = "0s" } = props
  const config = useConfigStore()
  const backgroundColor = config.userMessageBackground

  return <div className="lb-typing-indicator-ball" style={{ animationDelay, backgroundColor }} />
}
