import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { RiskLevelReason } from "../../../models/Constants"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = RiskPathwayScriptState

export type RiskPathwayBradfordScriptState = State

export class RiskPathwayBradfordScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayBradfordScript"

  /** Script Steps */

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askCanYouKeepYourselfSafe }
  }

  @step.logState
  askCanYouKeepYourselfSafe(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you able to keep yourself, and any dependants in your care, safe until your appointment? This could be up to 6 weeks, although likely to be sooner",
      prompt: {
        id: this.getPromptId("askCanYouKeepYourselfSafe"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askCanYouKeepYourselfSafe"
      },
      nextStep: this.handleCanYouKeepYourselfSafe
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.saveResponse<State>(
    "10",
    "Are you able to keep yourself, and any dependants in your care, safe until your appointment? This could be up to 6 weeks, although likely to be sooner",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  @step.handleResponse((d: IStepData<State, boolean>, script: RiskPathwayBradfordScript) => {
    d.state.canKeepSelfSafe = d.response
    script.referralStore.setCustomField<State>("canKeepSelfSafe", d.response)
  })
  handleCanYouKeepYourselfSafe(d: IStepData<State>): IStepResult {
    if (!d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.CANNOT_KEEP_SELF_SAFE)
    }
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  sayThanksAndContinue(_d: IStepData<State>): IStepResult {
    return {
      body: ["Thank you for sharing this information", "Let's carry on with your referral"],
      nextStep: this.end
    }
  }

  @step.logState
  sayCrisis(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        "Thank you for sharing this information",
        `However ${organisationName} does not provide urgent care`
      ],
      nextStep: this.sayCrisisNumbers
    }
  }

  @step.logState
  sayCrisisNumbers(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "You can call First Response, our 24/7 crisis service at 0800 9521181",
        "If you need urgent support, please dial NHS 111 and select Option 2",
        "In medical emergency and life threatening situations only, please dial 999, or attend your local A&E department",
        "Other helplines available:\n" +
          "You can contact Samaritans 24 hours a day, 365 days a year. You can call 116 123 (free from any phone) or email jo@samaritans.org\n" +
          "If you would prefer not to talk but want some mental health support, you could text SHOUT to 85258. Shout offers a confidential 24/7 service providing support if you are in need of immediate help"
      ],
      prompt: {
        id: this.getPromptId("sayCrisisNumbers"),
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }]
      },
      nextStep: this.handleCrisisNumbers
    }
  }

  @step.logState
  handleCrisisNumbers(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "NHS 111, 999, First Response (0800 9521181), Samaritans, Shout"
    )
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body:
        "To recap:\n" +
        `We'll continue with the referral, however ${organisationName} does not provide urgent care. If you require immediate help, please contact one of the numbers listed above`,
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onSaveRiskLevelAndReferralType(state: State): Promise<IStepResult> {
    return { nextStep: state.canKeepSelfSafe ? this.sayThanksAndContinue : this.sayCrisis }
  }

  createKeepingSafeLeafletEmail(title: string, logo: string): string {
    return `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" lang='en'>
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Limbic Therapy Assistant | Keeping Safe</title>
    <style type="text/css">
      #outlook a {padding: 0;}

      body {
        width: 100% !important;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        margin: 0;
        padding: 0;
        font-family: sans-serif;
      }

      .ExternalClass {width: 100%;}
      .ExternalClass,.ExternalClass p,.ExternalClass span,.ExternalClass font,.ExternalClass td,.ExternalClass div {line-height: 100%;}

      #backgroundTable {
        margin: 0;
        padding: 0;
        width: 100% !important;
        line-height: 100% !important;
      }

      #contentTable {
        margin: 64px auto;
        max-width: 800px !important;
        line-height: 100% !important;
      }

      img {
        outline: none;
        text-decoration: none;
        -ms-interpolation-mode: bicubic;
      }

      a img {border: none;}
      .image_fix {display: block;}
      p {margin: 1em 0;}

      h1,h2,h3,h4,h5,h6 {color: black !important;}
      h1 a,h2 a,h3 a,h4 a,h5 a,h6 a {color: blue !important;}
      h1 a:active,h2 a:active,h3 a:active,h4 a:active,h5 a:active,h6 a:active {color: red !important;}
      h1 a:visited,h2 a:visited,h3 a:visited,h4 a:visited,h5 a:visited,h6 a:visited {color: purple !important;}

      table td {
        padding-left: 24px;
        padding-right: 24px;
        border-collapse: collapse;
      }

      table {
        border-collapse: collapse;
        mso-table-lspace: 0;
        mso-table-rspace: 0;
      }

      a {color: orange;}
      h1,h2,h3,a {color: #4AA4CB !important;}
      h1 {text-align: center;}
      .red {color: red;}
      
      .blue {
        color: #4AA4CB !important;
        font-weight: bold;
      }

      .big {
        font-size: 21px;
        font-weight: bold;
      }

      .image_fix {
        margin-left: auto;
        margin-right: auto;
      }
    </style>
    <!--[if IEMobile 7]>
	<style type="text/css">
		/* Targeting Windows Mobile */
	</style>
	<![endif]-->
    <!--[if gte mso 9]>
	<style>
		/* Target Outlook 2007 and 2010 */
	</style>
	<![endif]-->
  </head>

  <body>
    <table cellpadding="0" cellspacing="0" border="0" id="backgroundTable">
      <tr>
        <td>
          <table cellpadding="0" cellspacing="0" border="0" id="contentTable">
            <tr>
              <td><img class="image_fix" src="${logo}" alt="${title}" title="${title}" width="x" height="x" /><br /><br /></td>
            </tr>
            <tr>
              <td>
                <h1>Keeping Safe</h1>
                It is common for people experiencing emotional distress to have thoughts of harming themselves or that they would be better off dead.<br /><br />
                If you are feeling concerned about your safety, please do speak with your practitioner, course facilitator or make an appointment with your GP.
                You will be asked to complete a questionnaire (PHQ9) prior to assessment and during treatment. If you have scored a 2 or 3 on question 9 of the questionnaire, this may indicate that you need more support.<br /><br />
                We have a duty of care to keep people safe. This is a duty we share with patients and GPs. To share this duty, it is important that we keep GPs informed. Therefore, we inform GPs when patients score highly on PHQ9.
              </td>
            </tr>
            <tr>
              <td>
                <h3>Remember suicide is preventable. You are not alone and can get help now.</h3>
                <h4>"Suicide is not chosen; it happens when pain exceeds resources for coping with pain."</h4>
                You are not a bad person, weak, or flawed because you feel suicidal, experience suicidal thoughts or thoughts of being better off dead.
                It doesn't even mean that you really want to die – it only means that you have more pain than you can cope with right now.
              </td>
            </tr>
            <tr>
              <td>
                <h3>How can I help myself?</h3>
                If you are feeling suicidal don't try to cope alone. Sometimes problems seem impossible to manage or the mental anguish is unbearable.<br /><br />
                <span class="blue">1.</span>&nbsp;&nbsp;&nbsp;You have made the first step by telling your doctor or a health professional. They will have discussed treatments and options with you. If you have been given antidepressants, remember that they take two weeks to start working, and then gradually.<br /><br />
                <span class="blue">2.</span>&nbsp;&nbsp;&nbsp;Please use any self-help information your practitioner has provided you with.<br /><br />
                <span class="blue">3.</span>&nbsp;&nbsp;&nbsp;Try and tell your friends and family, who will support you by spending time with you. Talking to a family member or a friend or a colleague can bring huge relief.<br /><br />
                <span class="blue">4.</span>&nbsp;&nbsp;&nbsp;Try to avoid long periods of time on your own, especially if you just sit and dwell on things.<br /><br />
                <span class="blue">5.</span>&nbsp;&nbsp;&nbsp;Plan your day and set small, easy to achieve tasks. This will keep you occupied and give a sense of achievement.<br /><br />
                <span class="blue">6.</span>&nbsp;&nbsp;&nbsp;You must try and eat, at best little and often and try to drink up to two litres of water each day.<br /><br />
                <span class="blue">7.</span>&nbsp;&nbsp;&nbsp;Avoid alcohol and non-prescription drugs.<br /><br />
                <span class="blue">8.</span>&nbsp;&nbsp;&nbsp;Get someone to help you clear out old medicines and anything harmful when you find yourself dwelling on this.<br /><br />
                <span class="blue">9.</span>&nbsp;&nbsp;&nbsp;Try to distract yourself by phoning a friend, going out, reading a magazine, etc.<br /><br />
                <span class="blue">10.</span>&nbsp;&nbsp;Exercise can make you feel better, at least 30 mins a day.<br /><br />
                <span class="blue">11.</span>&nbsp;&nbsp;Just try and be kind to yourself. It will pass, don’t be afraid of how you feel, try and be brave and keep safe.<br /><br />
              </td>
            </tr>
            <tr>
              <td>
                <h3>When it feels really bad, or when you find things building up RING:</h3>
                <b>First Response</b> our 24/7 crisis service on <b>0800 952 1181</b>
                <b>Samaritans</b> To speak to a Samaritan volunteer anytime day or night call <b>116 123</b>. Calls on this number are automatically sent to the nearest free line, which could be your local branch or another.<br />
                <a href="www.samaritans.org">www.samaritans.org</a><br /><br />
                <b>Breathing Space</b> on <b>0800 83 85 87</b> between 6pm and 2am on weekdays (Monday to Thursday) and 24 hours at weekends (6pm Friday to 6am Monday). Remember your call is confidential and free.<br />
                <a href="www.breathingspacescotland.co.uk">www.breathingspacescotland.co.uk</a><br /><br />
                Campaign Against Living Miserably (CALM) For young men who are feeling unhappy. As well as the website,<br />
                CALM also has a helpline <b>0800 58 58 58</b><br /><br />
                Get Connected: 0800 8084994 line open 1pm – 11pm daily (help line for young people up to age of 25yrs).
                <a href="www.getconnected.org.uk">www.getconnected.org.uk</a><br /><br />
                <b>Papyrus</b> prevention of young suicide. Hope line UK: <b>0800 068 41 41</b>.<br /><br />
              </td>
            </tr>
            <tr>
              <td>
                <h3>Are feelings of suicide common?</h3>
                Most people at some point in their lives will have a suicidal thought but for the majority this will be a fleeting or at least short-lived experience.
              </td>
            </tr>
            <tr>
              <td>
                <h3>When do suicidal thoughts become problematic?</h3>
                Thoughts of suicide should always be taken seriously but if these thoughts are persistent, occur frequently, are strong and for the individual there appears to be no alternative, immediate action should be taken to get support and help.<br /><br /><br /><br />
              </td>
            </tr>
            <tr>
              <td>
                <span class="big red">Contact emergency services</span> – are you at high risk at this time of killing yourself? Do you have a plan and the means to complete suicide?<br /><br /><br /><br />
                <span class="big">If so,</span> <span class="big red">call 999 RIGHT NOW.</span><br /><br /><br /><br />
                It's okay to feel the way you are feeling now and there are people around who can help you.<br />
                When you are feeling suicidal talk to someone immediately.
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </body>

</html>`
  }

  getKeepingSafeAttachmentUrls(state: State): string[] {
    return ["https://cdn-access.limbic.ai/bradford/Bradford+-+Keeping+safe+leaflet_updated.pdf"]
  }
}

/* istanbul ignore next */
export default class RiskPathwayBradfordDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayBradford
  readonly name: string = "RiskPathwayBradfordDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(RiskPathwayBradfordDialogue.id, new RiskPathwayBradfordScript(), state, snapshot)
  }
}
