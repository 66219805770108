import { IAPTGPMap, IAPTIDs } from "../../models/IIAPTService"

/**
 * NOTE: List is here:
 * https://docs.google.com/spreadsheets/d/1iPFHNoriwVwBU9MFjcohaQwkDdC-T3BN/edit?gid=416193753#gid=416193753
 */

export const iaptGPMapMPFT: IAPTGPMap = {
  M82002: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82003: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82004: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82005: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82006: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82007: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82008: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82009: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82010: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82011: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82012: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82013: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82014: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82016: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82017: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82018: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82019: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82020: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82021: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82022: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82023: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82024: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82025: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82026: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82028: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82030: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82032: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82033: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82034: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82035: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82038: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82039: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82040: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82041: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82042: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82043: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82046: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82047: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82048: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82051: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82056: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82057: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82058: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82059: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82060: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82601: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82606: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82616: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  M82620: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
  Y01929: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT
}
