import { DiscussionSteps } from "@limbic/types"

export const stepsOrder = [
  DiscussionSteps.Intro,
  DiscussionSteps.Permissions,
  DiscussionSteps.GetName,
  DiscussionSteps.Eligibility,
  DiscussionSteps.SelfReferralPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.Goodbye
]

export const stepsOrder_SABP = [
  DiscussionSteps.Intro,
  DiscussionSteps.Permissions,
  DiscussionSteps.GetName,
  DiscussionSteps.Eligibility,
  DiscussionSteps.SelfReferralPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.ResearchConsent,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.Goodbye
]

export const stepsOrder_ESSEX = [
  DiscussionSteps.Intro,
  DiscussionSteps.Permissions,
  DiscussionSteps.GetName,
  DiscussionSteps.Eligibility,
  DiscussionSteps.SelfReferralPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.ResearchConsent,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.Goodbye
]

export const stepsOrder_LINCS = [
  DiscussionSteps.Intro,
  DiscussionSteps.Permissions,
  DiscussionSteps.GetName,
  DiscussionSteps.Eligibility,
  DiscussionSteps.SelfReferralPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.ResearchConsent,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.Goodbye
]

export const stepsOrder_INSIGHT = [
  DiscussionSteps.Intro,
  DiscussionSteps.Permissions,
  DiscussionSteps.GetName,
  DiscussionSteps.Eligibility,
  DiscussionSteps.SelfReferralPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.ADSMConsent,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.Goodbye
]

export const stepsOrder_GM_HUB = [
  DiscussionSteps.Intro,
  DiscussionSteps.Eligibility,
  DiscussionSteps.PeaceOfMind,
  DiscussionSteps.Permissions,
  DiscussionSteps.SelfReferralPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.Goodbye
]

export const stepsOrder_HEALIX = [
  DiscussionSteps.Intro,
  DiscussionSteps.PeaceOfMind,
  DiscussionSteps.Permissions,
  DiscussionSteps.GetName,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.SelfReferralPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.Goodbye
]

export const stepsOrder_VITALITY = [
  DiscussionSteps.Intro,
  DiscussionSteps.PeaceOfMind,
  DiscussionSteps.Permissions,
  DiscussionSteps.GetName,
  DiscussionSteps.Eligibility,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.BookAppointment,
  DiscussionSteps.Goodbye
]

export const stepsOrder_DEMO_PRIVATE_PROVIDERS = [
  DiscussionSteps.Intro,
  DiscussionSteps.GetName,
  DiscussionSteps.PeaceOfMind,
  DiscussionSteps.Permissions,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.SelfReferralPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.Goodbye
]

export const stepsOrder_Insight_Waitlist = [
  DiscussionSteps.Intro,
  DiscussionSteps.GetName,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.ADSMConsent,
  DiscussionSteps.Assessment,
  DiscussionSteps.Goodbye
]

export const stepsOrder_MHM = [
  DiscussionSteps.Intro,
  DiscussionSteps.Permissions,
  DiscussionSteps.GetName,
  DiscussionSteps.Eligibility,
  DiscussionSteps.SelfReferralPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.Goodbye
]

export const stepsOrder_BRADFORD = [
  DiscussionSteps.Intro,
  DiscussionSteps.Permissions,
  DiscussionSteps.GetName,
  DiscussionSteps.Eligibility,
  DiscussionSteps.SelfReferralPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.Goodbye
]

export const stepsOrder_MIND = [
  DiscussionSteps.Intro,
  DiscussionSteps.Permissions,
  DiscussionSteps.GetName,
  DiscussionSteps.Eligibility,
  DiscussionSteps.SelfReferralPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.BookAppointment,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.Goodbye
]

export const stepsOrder_LWC = [
  DiscussionSteps.Intro,
  DiscussionSteps.Permissions,
  DiscussionSteps.GetName,
  DiscussionSteps.Eligibility,
  DiscussionSteps.SelfReferralPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.Goodbye
]

export const stepsOrder_CHESHIRE_WIRRAL = [
  DiscussionSteps.Intro,
  DiscussionSteps.Permissions,
  DiscussionSteps.GetName,
  DiscussionSteps.Eligibility,
  DiscussionSteps.SelfReferralPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.Goodbye
]

export const stepsOrder_OXFORD_BUCKINGHAMSHIRE = [
  DiscussionSteps.Intro,
  DiscussionSteps.Permissions,
  DiscussionSteps.GetName,
  DiscussionSteps.Eligibility,
  DiscussionSteps.SelfReferralPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.Goodbye
]

export const stepsOrder_DORKING = [
  DiscussionSteps.Intro,
  DiscussionSteps.Permissions,
  DiscussionSteps.GetName,
  DiscussionSteps.Eligibility,
  DiscussionSteps.SelfReferralPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.Goodbye
]

export const stepsOrder_BEXLEY = [
  DiscussionSteps.Intro,
  DiscussionSteps.Permissions,
  DiscussionSteps.GetName,
  DiscussionSteps.Eligibility,
  DiscussionSteps.SelfReferralPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.Goodbye
]
