import React, { useCallback, useRef, useState } from "react"
import classnames from "classnames"
import { useApplicationStore, useConfigStore } from "../../contexts/RootStoreContext"
import TextInput from "./TextInput"
import InlineButton from "../InlineButton/InlineButton"
import "./NameInput.scss"
import type IName from "../../../models/IName"
import type { IDataPoints } from "@limbic/types"
import isValidName from "../../../utils/isValidName"

interface IProps {
  onSubmit?: (name: IName, dataPoints?: IDataPoints) => void
}

export default function NameInput(props: IProps): JSX.Element {
  const config = useConfigStore()
  const app = useApplicationStore()
  const [firstName, setFirstName] = useState<string>()
  const [middleName, setMiddleName] = useState<string>()
  const [lastName, setLastName] = useState<string>()
  const [firstNameError, setFirstNameError] = useState<string | undefined>()
  const [middleNameError, setMiddleNameError] = useState<string | undefined>()
  const [lastNameError, setLastNameError] = useState<string | undefined>()
  const dirtyFields = useRef({ firstName: false, lastName: false })

  const changeFirstName = useCallback((text?: string) => {
    const isDirty = dirtyFields.current.firstName
    dirtyFields.current.firstName = true
    if (text?.trim().replace("*", "")) setFirstNameError(undefined)
    else if (isDirty) setFirstNameError("First Name can't be empty")
    setFirstName(text)
  }, [])

  const changeMiddleNames = useCallback((text?: string) => {
    if (text?.trim()) setMiddleNameError(undefined)
    setMiddleName(text)
  }, [])

  const changeLastName = useCallback((text?: string) => {
    const isDirty = dirtyFields.current.lastName
    dirtyFields.current.lastName = true
    if (text?.trim().replace("*", "")) setLastNameError(undefined)
    else if (isDirty) setLastNameError("Last Name can't be empty")
    setLastName(text)
  }, [])

  const onSubmit = useCallback(() => {
    const first = firstName?.trim().replace("*", "")
    const middle = middleName?.trim().replace("*", "")
    const last = lastName?.trim().replace("*", "")

    // sanity check
    if (!first || !last) {
      !first && setFirstNameError("First name can not be empty")
      !last && setLastNameError("Last name can not be empty")
      return
    }

    if (first.length === 1 || last.length === 1) {
      first.length === 1 && setFirstNameError("First name must be at least 2 characters long")
      last.length === 1 && setLastNameError("Last name must be at least 2 characters long")
      return
    }

    //validation
    if (!isValidName(first) || (middle && !isValidName(middle)) || !isValidName(last)) {
      !isValidName(first) &&
        setFirstNameError(
          "First name can include letters, a single apostrophe ('), a single hyphen (-), and spaces"
        )
      middle &&
        !isValidName(middle) &&
        setMiddleNameError(
          "Middle names can include letters, a single apostrophe ('), a single hyphen (-), and spaces"
        )
      !isValidName(last) &&
        setLastNameError(
          "Last name can include letters, a single apostrophe ('), a single hyphen (-), and spaces"
        )
      return
    }

    const name = {
      firstName: first,
      middleNames: middle,
      lastName: last
    }

    props.onSubmit?.(name as IName)
  }, [firstName, lastName, middleName, props])

  const active = firstName?.length && lastName?.length
  const submitButtonCSS = classnames("lb-user-input-name-submit-button", { active })
  return (
    <div className="lb-user-input-name-container" data-testid="name-input">
      <ErrorInfo error={firstNameError} />
      <TextInput
        borderless
        hasError={!!firstNameError}
        placeholder={app.t("First Name") + " *"}
        clearOnSubmit={false}
        multiline={false}
        autoCorrect={"none"}
        autoComplete={"name"}
        onChangeText={changeFirstName}
        onSubmit={onSubmit}
        showOnSubmitButton={false}
      />
      <ErrorInfo error={middleNameError} />
      <TextInput
        borderless
        autoFocus={false}
        placeholder={app.t("Middle Name(s)")}
        clearOnSubmit={false}
        multiline={false}
        autoCorrect={"none"}
        autoComplete={"additional-name"}
        onChangeText={changeMiddleNames}
        onSubmit={onSubmit}
        showOnSubmitButton={false}
      />
      <ErrorInfo error={lastNameError} />
      <TextInput
        borderless
        autoFocus={false}
        hasError={!!lastNameError}
        placeholder={app.t("Last Name") + " *"}
        clearOnSubmit={false}
        multiline={false}
        autoCorrect={"none"}
        autoComplete={"family-name"}
        onChangeText={changeLastName}
        onSubmit={onSubmit}
        showOnSubmitButton={false}
      />
      <InlineButton //
        fullWidth
        disabled={!active}
        style={{ backgroundColor: config.userMessageBackground }}
        btn={{ body: app.t("Submit") }}
        onSelect={onSubmit}
        buttonClassName={submitButtonCSS}
      />
    </div>
  )
}

interface IErrorInfoProps {
  error?: string
}

function ErrorInfo(props: IErrorInfoProps) {
  const { error } = props
  if (!error) return null
  return (
    <div className="lb-user-input-name-error-container">
      <span className="lb-user-input-name-error">{error}</span>
    </div>
  )
}
