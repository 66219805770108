import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../DialogueIDs"
import { SuitableServicesSignpostIAPTScript } from "./SuitableServicesSignpostIAPTDialogue"
import type { SuitableServicesSignpostIAPTScriptState } from "./SuitableServicesSignpostIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = SuitableServicesSignpostIAPTScriptState
export type SuitableServicesSignpostOxfordScriptState = State

export class SuitableServicesSignpostOxfordScript extends SuitableServicesSignpostIAPTScript {
  readonly name: string = "SuitableServicesSignpostOxfordScript"

  /** Script Steps */

  @step.logState
  sayOrganisationPhoneNumbers(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const organisationPhoneNumbers = this.rootStore.configStore.organisationPhoneNumbers
    const formLink = this.rootStore.configStore.formLink
    return {
      body: this.t(
        [
          "If you still think that {organisationName} is right for you please use our e-referral form [here]({formLink}), or please call one of our services:",
          organisationPhoneNumbers,
          "Our staff are always happy to talk options through with you"
        ],
        { organisationName, formLink }
      ),
      nextStep: this.promptThanksOK
    }
  }
}

/* istanbul ignore next */
export default class SuitableServicesSignpostOxfordDialogue extends Dialogue<State> {
  static id = DialogueIDs.SuitableServicesSignpostOxford
  readonly name: string = "SuitableServicesSignpostOxfordDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      SuitableServicesSignpostOxfordDialogue.id,
      new SuitableServicesSignpostOxfordScript(),
      state,
      snapshot
    )
  }
}
