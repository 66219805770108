// Taken and adapted from https://basarat.gitbooks.io/typescript/docs/tips/typed-event.html

export type Listener<T> = (event: T) => any

export interface Disposable {
  dispose(): void
}

/** passes through events as they happen. You will not get events from before you start listening */
export class TypedEvent<T> {
  #listeners: Array<Listener<T>> = []
  #listenersOnce: Array<Listener<T>> = []

  addListener(listener: Listener<T>): Disposable {
    this.#listeners.push(listener)
    return {
      dispose: () => this.removeListener(listener)
    }
  }

  addOneOffListener(listener: Listener<T>): Disposable {
    this.#listenersOnce.push(listener)
    return {
      dispose: () => this.removeListener(listener)
    }
  }

  removeListener(listener: Listener<T>): void {
    const index = this.#listeners.indexOf(listener)
    if (index > -1) {
      this.#listeners.splice(index, 1)
    }
    const oneOffIndex = this.#listenersOnce.indexOf(listener)
    if (oneOffIndex > -1) {
      this.#listenersOnce.splice(oneOffIndex, 1)
    }
  }

  emit(event: T): void {
    /** Update any general listeners */
    this.#listeners.forEach(listener => listener(event))

    /** Clear the `once` queue */
    if (this.#listenersOnce.length > 0) {
      const listenersOncer = this.#listenersOnce
      this.#listenersOnce = []
      listenersOncer.forEach(listener => listener(event))
    }
  }

  clear(): void {
    this.#listeners = []
    this.#listenersOnce = []
  }
}
