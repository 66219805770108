import { IIAPTService } from "../models/IIAPTService"
import { iapts } from "../config/iapts"
import { IIAPTBotService } from "@limbic/types"

export default function getIAPTById(
  id?: IIAPTService["id"],
  customIAPTS?: IIAPTBotService[]
): IIAPTService | undefined {
  if (id?.length) {
    return customIAPTS?.find(iapt => iapt.id === id) ?? iapts[id]
  }
}
