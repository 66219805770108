import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { GetNameScript, GetNameScriptState, GetNameScriptStateSchema } from "./GetNameScript"
import type { ZodSchema } from "zod"

type State = GetNameScriptState
export type GetNameIAPTScriptState = State

export const GetNameIAPTScriptStateSchema = GetNameScriptStateSchema.extend({})

export class GetNameIAPTScript extends GetNameScript {
  readonly name: string = "GetNameIAPTScript"

  /** Script Steps */

  @step
  step1(_d: IStepData<State>): IStepResult {
    const { directReferral } = this.rootStore.configStore
    return {
      body: this.t(
        directReferral
          ? "I'm now going to create a patient record for you"
          : "Ok, I'm just going to create a patient record for you"
      ),
      prompt: directReferral
        ? {
            id: this.getPromptId("step1"),
            type: "inlinePicker",
            choices: [{ body: this.t("Okay") }]
          }
        : undefined,
      nextStep: this.askFullName
    }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return GetNameIAPTScriptStateSchema
  }
}

/* istanbul ignore next */
export default class GetNameIAPTDialogue extends Dialogue<State> {
  static id = DialogueIDs.GetNameIAPT
  readonly name: string = "GetNameIAPTDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GetNameIAPTDialogue.id, new GetNameIAPTScript(), state, snapshot)
  }
}
