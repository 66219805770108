/* eslint-disable @typescript-eslint/no-empty-function */
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../../DialogueIDs"
import { step } from "../../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import BaseScript, { BaseScriptState } from "../../../BaseScript"
import { CovidStatus } from "@limbic/types"
import moment from "moment"
import invariant from "../../../../utils/invariant"

interface State extends BaseScriptState {
  askHasLongCovid?: boolean
  askIsReferralForLongCovid?: boolean
  hasLongCovid?: boolean
  isReferralForLongCovid?: boolean
}

export type CheckCovidAndDetailsScriptState = State

export class CheckCovidAndDetailsScript extends BaseScript<State> {
  readonly name: string = "CheckCovidAndDetailsScript"

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askHaveYouEverCaughtCovid }
  }

  @step.logState
  askHaveYouEverCaughtCovid(d: IStepData<State>): IStepResult {
    return {
      body: this.t("Have you ever caught COVID-19?", this.getContext(d.state)),
      prompt: {
        id: this.getPromptId("askHaveYouEverCaughtCovid"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          {
            body: this.t("Yes - confirmed by PCR or lateral flow test"),
            value: "YES_CONFIRMED_TEST"
          },
          {
            body: this.t("Yes - suspected I've had it but not confirmed by a test"),
            value: "YES_SUSPECTED"
          },
          { body: this.t("No"), value: "NO" }
        ],
        dataPointsName: "askHaveYouEverCaughtCovid"
      },
      nextStep: this.handleHaveYouEverCaughtCovid
    }
  }

  @step.logStateAndResponse
  async handleHaveYouEverCaughtCovid(d: IStepData<State, CovidStatus>): Promise<IStepResult> {
    d.state.covidStatus = d.response
    if (d.response === "NO") {
      return { nextStep: this.end }
    }
    return { nextStep: this.askWhenDidYouHaveCovid }
  }

  @step.logState
  askWhenDidYouHaveCovid(d: IStepData<State>): IStepResult {
    return {
      body: this.t("When did you have COVID-19?", this.getContext(d.state)),
      prompt: {
        id: this.getPromptId("askWhenDidYouHaveCovid"),
        trackResponse: true,
        type: "date",
        isUndoAble: true
      },
      nextStep: this.handleWhenDidYouHaveCovid
    }
  }

  @step.logStateAndResponse
  async handleWhenDidYouHaveCovid(d: IStepData<State, number>): Promise<IStepResult> {
    try {
      const date = moment(d.response)
      invariant(date, "I'm sorry that's not a valid date. Please enter the date you had covid")
      invariant(
        date.isValid(),
        "I'm sorry that's not a valid date. Please enter the date you had covid"
      )
      invariant(
        date.isBefore(moment()),
        "Hmm… I don't think you can predict the date you will have Covid. Can you please make sure the date is correct?"
      )
      invariant(
        date.isAfter(moment("2019-10-31")),
        "Hmm… the first case of Covid was reported in November 2019. Can you please make sure the date is correct?"
      )
      d.state.covidDate = moment(date).format("YYYY-MM-DD")
    } catch (e) {
      return {
        body: this.t(e.message),
        nextStep: this.askWhenDidYouHaveCovid
      }
    }
    return { nextStep: d.state.askHasLongCovid ? this.askHasLongCovid : this.end }
  }

  @step.logState
  askHasLongCovid(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Do you have a diagnosis of long covid or you feel you are suffering with symptoms of long covid?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askHasLongCovid"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleLongCovid
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.hasLongCovid = d.response
  })
  handleLongCovid(d: IStepData<State, boolean>): IStepResult {
    if (d.response && d.state.askIsReferralForLongCovid) {
      return {
        nextStep: this.askIsReferralForLongCovid
      }
    }
    return { nextStep: this.end }
  }

  @step.logState
  askIsReferralForLongCovid(d: IStepData<State>): IStepResult {
    return {
      body: this.t("Is this referral in relation to your long covid?", this.getContext(d.state)),
      prompt: {
        id: this.getPromptId("askIsReferralForLongCovid"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleIsReferralForLongCovid
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.isReferralForLongCovid = d.response
  })
  handleIsReferralForLongCovid(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.end }
  }

  /** Generic Handlers */
}

/* istanbul ignore next */
export default class CheckCovidAndDetailsDialogue extends AdHocDialogue<
  State,
  CheckCovidAndDetailsScript
> {
  static id = DialogueIDs.CheckCovidAndDetails
  readonly name: string = "CheckCovidAndDetailsDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(CheckCovidAndDetailsDialogue.id, new CheckCovidAndDetailsScript(), state, snapshot)
  }
}
