import React from "react"
import { observer } from "mobx-react"
import "./Sidebar.scss"
import { useConfigStore } from "../../contexts/RootStoreContext"

export interface Props {
  visible?: boolean
}

function Sidebar(props: Props): JSX.Element | null {
  const config = useConfigStore()
  const { visible } = props
  if (!visible) {
    return null
  }

  return (
    <div className="lb-sidebar-container">
      <article className="lb-sidebar-card">
        <div className="lb-header">
          <img style={{ marginRight: 24 }} src={config.logo} className="lb-header-img" alt="" />
          <h3 className="subtitle">Refer yourself to {config.organisationName}</h3>
        </div>
        <p>
          NHS Bradford and Craven District Talking Therapies is a free and confidential NHS service{" "}
          <b>for people aged 16 and over</b> who are registered with a GP in Bradford, Airedale,
          Wharfedale or Craven. The service is part of the national NHS Talking Therapies for
          Anxiety and Depression programme.
        </p>
        <p>
          Our service supports people with their mental health and wellbeing by providing access to
          evidence-based, short term psychological therapies for mild to moderate depression and
          anxiety disorders. We do this by recommending suitable therapies best suited to the
          problems you are experiencing and the goals that you have set for yourself. Our therapy
          options include online courses or remote therapy that can be attended in the comfort of
          your own home.
        </p>
        <p>
          You can be referred by a health professional, such as your GP, or you can self-refer
          online or by calling Bradford District Talking Therapies Administration Team on{" "}
          <b>01274 221 234</b>
        </p>
        <p>
          If you prefer to use our standard referral from, click{" "}
          <a href="https://clientportal.pcmis.com/bradself.html" target="_blank" rel="noreferrer">
            here
          </a>
        </p>
        {config.iaptIcons?.map?.(link => (
          <img key={link} className="nhs-logo" src={link} alt={link} />
        ))}
      </article>
      <div className="lb-sidebar-spacer" />
      <article className="lb-sidebar-card full-height">
        <p>
          Limbic helps people access psychological support quicker and easier. Your data is secured
          according to the highest NHS standards.
        </p>
        <img
          className="badges"
          src="https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/data-pics.png"
          alt={"Badges"}
        />
      </article>
    </div>
  )
}

export default observer(Sidebar)
