import { z, ZodSchema } from "zod"
import BaseScript, { BaseScriptState, BaseScriptStateSchema } from "../../BaseScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { TrackingEvents } from "../../../models/Constants"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { ALCOHOL_FREQUENCIES, ALCOHOL_QUANTITIES } from "@limbic/types"
import { Category } from "@limbic/crisis-detection"
import { IStep } from "../../../backend/chatbot/models/IStep"
import { ICollectNationalitySettings } from "../ad-hoc/CollectNationality/CollectNationalityDialogue"
import { ICollectEthnicitySettings } from "../ad-hoc/CollectEthnicity/CollectEthnicityDialogue"
import {
  CollectGenderScriptState,
  ICollectGenderSettings
} from "../ad-hoc/CollectGender/CollectGenderDialogue"
import { ICollectDisabilitiesSettings } from "../ad-hoc/CollectDisabilities/CollectDisabilitiesDialogue"
import { ICollectSexualitySettings } from "../ad-hoc/CollectSexuality/CollectSexualityDialogue"
import { ICollectLanguageAndInterpreterSettings } from "../ad-hoc/CollectLanguageAndInterpreter/CollectLanguageAndInterpreterDialogue"
import { ICollectLongTermMedicalConditionSettings } from "../ad-hoc/CollectLongTermMedicalConditionDetails/CollectLongTermMedicalConditionDetailsDialogue"
import { ICollectEmailSettings } from "../ad-hoc/CollectEmail/CollectEmailDialogue"

interface State extends BaseScriptState {
  userHasEmail?: boolean
}

export type SelfReferralScriptState = State

export const SelfReferralScriptStateSchema = BaseScriptStateSchema.extend({
  userHasEmail: z.boolean().optional()
})

export default abstract class SelfReferralScript extends BaseScript<State> {
  /** Abstract Script Steps */
  abstract sayReferralSucceeded(d: IStepData<State>): IStepResult | Promise<IStepResult>
  abstract sayReferralFailed(d: IStepData<State>): IStepResult | Promise<IStepResult>

  /** Optional Abstract Script Steps */

  sayIntro?(_d: IStepData<State>): IStepResult | Promise<IStepResult>

  /** Abstract Generic Handlers */

  abstract getReferralPayload(state: State): Promise<Record<string, any>>
  onReferralFinished?(state: State): Promise<void>
  onRiskReferralFinished?(state: State): Promise<void>

  /** Optional Abstract Generic Handlers */

  onHandleReligion?(state: State): Promise<IStepResult | void>
  onHandlePerinatal?(state: State): Promise<IStepResult | void>
  onHandleExArmedForces?(state: State): Promise<IStepResult | void>
  onHandleSubstances?(_state: State): Promise<IStepResult | void>
  onHandleSubstancesOrigin?(state: State): Promise<IStepResult | void>
  onHandleSubstancesInfoWithCrisis?(state: State): Promise<IStepResult | void>
  onHandleMedicationInfoWithCrisis?(state: State): Promise<IStepResult | void>
  onHandleMedicationWithinDoseRange?(state: State): Promise<IStepResult | void>
  onHandleCurrentSupport?(state: State): Promise<IStepResult | void>

  /** Default Chat Flow Handlers */

  async onCollectNHSNumberEnded(state: State): Promise<IStep> {
    return this.goToCollectPhoneNumber
  }

  async onCollectPhoneNumberEnded(state: State): Promise<IStep> {
    return this.goToCollectEmail
  }

  async getCollectEmailSettings(state: State): Promise<ICollectEmailSettings> {
    return {
      messages: {
        askDoYouWantToShareEmail: this.t(["Do you have an email address?"])
      },
      hasPreliminaryQuestion: true,
      customisableOptions: {
        askDoYouWantToShareEmail: [
          { body: this.t("Yes, I have an email"), value: true },
          { body: this.t("No, I don't have an email"), value: false }
        ]
      }
    }
  }

  async onCollectEmailEnded(state: State): Promise<IStep> {
    return this.goToCheckPostCodeForAddressLookup
  }

  async onCollectPreferredCorrespondenceEnded(state: State): Promise<IStep> {
    return this.goToCheckPostCodeForAddressLookup
  }

  async onCollectPostcodeAndAddressEnded(state: State): Promise<IStep> {
    return this.finishSelfReferral
  }

  async getCollectNationalitySettings(state: State): Promise<ICollectNationalitySettings> {
    const nationalities = this.getNationalities(state)
    return {
      // This is temporary should use the actual value when dashboard is done
      options: nationalities.map(n => ({ body: this.t(n), value: n }))
    }
  }

  async onCollectNationalityEnded(state: State): Promise<IStep> {
    return this.goToCollectReligion
  }

  async getCollectEthnicitySettings(state: State): Promise<ICollectEthnicitySettings> {
    const ethnicities = this.getEthnicities(state)
    return {
      // This is temp - when we move to the dashboard it should be the actual value
      options: ethnicities.map(e => ({ body: this.t(e), value: e }))
    }
  }

  async getCollectReligionSettings(state: State): Promise<ICollectEthnicitySettings> {
    const religions = this.getReligions(state)
    return {
      // This is temp - when we move to the dashboard it should be the actual value
      options: religions.map(e => ({ body: e, value: e }))
    }
  }

  async onCollectReligionEnded(_state: State): Promise<IStep> {
    return this.goToCollectGender
  }

  async onCollectEthnicityEnded(state: State): Promise<IStep> {
    return this.goToCollectGender
  }

  async getCollectGenderSettings(state: State): Promise<ICollectGenderSettings> {
    const genders = this.getGenders(state)
    // This is temporary - to be replaced by actual value when we have the dashboard
    return { optionsGender: genders.map(g => ({ body: this.t(g), value: g })) }
  }

  async getCollectGenderState(state: State): Promise<CollectGenderScriptState> {
    return { skipSameGenderAsBirth: true }
  }

  async onCollectGenderEnded(state: State): Promise<IStep> {
    return this.askPerinatal
  }

  async getCollectSexualitySettings(state: State): Promise<ICollectSexualitySettings> {
    const sexualities = this.getSexualities(state)
    return { options: sexualities.map(s => ({ body: this.t(s), value: s })) }
  }

  async onCollectSexualityEnded(state: State): Promise<IStep> {
    return this.goToCollectLongTermMedicalConditionDetails
  }

  async getCollectLongTermMedicalConditionSettings(
    state: State
  ): Promise<ICollectLongTermMedicalConditionSettings> {
    const medicalConditions = this.getMedicalConditions(state)
    return {
      choicesMap: medicalConditions.map(m => ({ body: this.t(m), value: m })),
      includeOther: false,
      shouldAskOtherDetails: false
    }
  }

  async onCollectLongTermMedicalConditionEnded(state: State): Promise<IStep> {
    return this.goToCollectAlcoholConsumption
  }

  async onCheckCovidDetailsEnded(state: State): Promise<IStep> {
    return this.goToCollectDisabilities
  }

  async getCollectDisabilitiesSettings(state: State): Promise<ICollectDisabilitiesSettings> {
    const disabilities = this.getDisabilities(state)
    return {
      choicesMap: disabilities.map(d => ({ body: this.t(d), value: d })),
      shouldAskDisabilityStatus: true,
      shouldAskOtherDetails: false
    }
  }

  async onCollectDisabilitiesEnded(state: State): Promise<IStep> {
    return this.goToCollectASD
  }

  async getCollectLanguageAndInterpreterSettings(
    state: State
  ): Promise<ICollectLanguageAndInterpreterSettings> {
    // This is temp - to use actual value when dashboard is integrated
    return { options: this.getLanguages(state).map(l => ({ body: this.t(l), value: l })) }
  }

  async onCollectLanguageAndInterpreterEnded(state: State): Promise<IStep> {
    return this.askPerinatal
  }

  async onCollectAlcoholConsumptionEnded(state: State): Promise<IStep> {
    return this.askSubstances
  }

  async onCollectASDEnded(state: State): Promise<IStep> {
    return this.goToCollectADHD
  }

  async onCollectADHDEnded(state: State): Promise<IStep> {
    return this.askExArmedForces
  }

  async onCollectMainIssueEnded(state: State): Promise<IStep> {
    return this.goToCollectGoalForTherapy
  }

  async onCollectGoalForTherapyEnded(state: State): Promise<IStep> {
    return this.doReferralSubmission
  }

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    this.timeEvent(this.name)
    return { nextStep: this.sayIntro || this.startSelfReferralPart1 }
  }

  @step
  end(d: IStepData<State>): IStepResult {
    this.track(this.name)
    return super.end(d)
  }

  // ------------ PART 1 ------------

  @step.logState
  startSelfReferralPart1(d: IStepData<State>): IStepResult {
    const phoneNumber = d.state.phoneNumber || this.referralStore.getCustomField("phoneNumber")
    if (phoneNumber) {
      d.state.phoneNumber = phoneNumber
      return { nextStep: this.goToCollectEmail }
    }
    return { nextStep: this.goToCollectPhoneNumber }
  }

  @step.logState
  finishSelfReferral(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askReadyForMore }
  }

  @step.logState
  askReadyForMore(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(["Great. We're nearly finished", "Just a few more questions", "Ready?"]),
      prompt: {
        id: this.getPromptId("askReadyForMore"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("👍"), value: false },
          { body: this.t("Yes"), value: true },
          { body: this.t("Go on"), value: false }
        ]
      },
      nextStep: this.handleReadyForMore
    }
  }

  @step.logState
  handleReadyForMore(d: IStepData<State, boolean>): IStepResult {
    return {
      body: this.t(d.response ? "That's what I like to hear!" : "Okay..."),
      nextStep: this.startSelfReferralPart2
    }
  }

  // ------------ PART 2 ------------

  @step.logState
  startSelfReferralPart2(_d: IStepData<State>): IStepResult {
    return { nextStep: this.goToCollectEthnicity }
  }

  @step.logState
  askPerinatal(d: IStepData<State>): IStepResult {
    const perinatalStatuses = this.getPerinatalStatuses(d.state)
    if (!perinatalStatuses?.length) {
      this.logBreadcrumb("PERINATAL STATUSES NOT FOUND", d.state, { perinatalStatuses })
      this.logMessage("PERINATAL STATUSES NOT FOUND")
      return { nextStep: this.goToCollectDisabilities }
    }
    return {
      body: this.t("What is your perinatal status?"),
      prompt: {
        id: this.getPromptId("askPerinatal"),
        trackResponse: true,
        type: "inlinePicker",
        choices: perinatalStatuses.map(g => ({ body: this.t(g), value: g })),
        dataPointsName: "askPerinatal"
      },
      nextStep: this.handlePerinatal
    }
  }

  @step.logState
  async handlePerinatal(d: IStepData<State, string>): Promise<IStepResult> {
    this.setPeople({ perinatalStatus: d.response })
    d.state.perinatalStatus = d.response

    const result = await this.onHandlePerinatal?.(d.state)
    if (result) return result
    return { nextStep: this.goToCollectDisabilities }
  }

  @step.logState
  async askExArmedForces(d: IStepData<State>): Promise<IStepResult> {
    const exArmedForcesOptions = this.getExArmedForcesValues(d.state)
    if (!exArmedForcesOptions?.length) {
      this.logBreadcrumb("EX ARMED FORCES OPTIONS NOT FOUND", d.state, { exArmedForcesOptions })
      this.logMessage("EX ARMED FORCES OPTIONS NOT FOUND")
      const result = await this.onHandleExArmedForces?.(d.state)
      if (result) return result
      return { nextStep: this.goToCollectSexuality }
    }
    return {
      body: this.t("Have you ever served in the British Armed Forces?"),
      prompt: {
        id: this.getPromptId("askExArmedForces"),
        trackResponse: true,
        type: "inlinePicker",
        choices: exArmedForcesOptions.map(g => ({ body: this.t(this.getChoiceBody(g)), value: g })),
        dataPointsName: "askExArmedForces"
      },
      nextStep: this.handleExArmedForces
    }
  }

  @step
  async handleExArmedForces(d: IStepData<State, string>): Promise<IStepResult> {
    this.setPeople({ isExArmedForces: d.response })
    d.state.isExArmedForces = d.response
    const result = await this.onHandleExArmedForces?.(d.state)
    if (result) return result
    return { nextStep: this.goToCollectSexuality }
  }

  @step.logState
  askSubstances(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Okay. And over the past month, have you taken any non-prescribed medication or substances to help you manage your mood?"
      ),
      prompt: {
        id: this.getPromptId("askSubstances"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askSubstances"
      },
      nextStep: this.handleSubstances
    }
  }

  @step.logStateAndResponse
  async handleSubstances(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.substances = d.response
    this.setPeople({ substances: d.response })
    const result = await this.onHandleSubstances?.(d.state)
    if (result) return result

    return {
      nextStep: d.response //
        ? this.askSubstancesOrigin
        : this.askCurrentSupport
    }
  }

  @step.logState
  askSubstancesOrigin(_d: IStepData<State>): IStepResult {
    return {
      // prettier-ignore
      body: this.t("Are these medications brought over the counter at a pharmacy or supermarket? (and include herbal remedies, probiotics and vitamins)"),
      prompt: {
        id: this.getPromptId("askSubstancesOrigin"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askSubstancesOrigin"
      },
      nextStep: this.handleSubstancesOrigin
    }
  }

  @step.logState
  async handleSubstancesOrigin(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.substancesAreMedications = d.response
    this.setPeople({ substancesAreMedications: d.response })
    const result = await this.onHandleSubstancesOrigin?.(d.state)
    if (result) return result

    return {
      nextStep: d.state.substancesAreMedications
        ? this.askMedicationWithinDoseRange
        : this.askSubstancesInfo
    }
  }

  @step.logState
  askSubstancesInfo(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Could you please describe what your use is, and how often do you use it?"),
      prompt: {
        id: this.getPromptId("askSubstancesInfo"),
        trackResponse: false,
        type: "text",
        forceValue: false,
        cancelLabel: "skip",
        cancelIsEmptySubmit: true
      },
      nextStep: this.handleSubstancesInfoWithCrisis
    }
  }

  @step.logState
  returnToAskSubstancesInfo(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "So we were talking about the substances you're taking to help manage your mood"
      ),
      nextStep: this.askSubstancesInfo
    }
  }

  @step.logState
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    ignoredCategories: [Category.Medication],
    getNextStep: (s: SelfReferralScript) => s.returnToAskSubstancesInfo
  })
  async handleSubstancesInfoWithCrisis(d: IStepData<State, string>): Promise<IStepResult> {
    d.state.substancesInfo = d.response
    this.setPeople({ substancesInfo: d.response })
    const result = await this.onHandleSubstancesInfoWithCrisis?.(d.state)
    if (result) return result
    return { nextStep: this.askCurrentSupport }
  }

  @step.logState
  askMedicationInfo(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Could you please describe what your use is, and how often do you use it?"),
      prompt: {
        id: this.getPromptId("askMedicationInfo"),
        trackResponse: false,
        type: "text",
        forceValue: false,
        cancelLabel: "skip",
        cancelIsEmptySubmit: true,
        dataPointsName: "askMedicationInfo"
      },
      nextStep: this.handleMedicationInfoWithCrisis
    }
  }

  @step.logState
  returnToAskMedicationInfo(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "So we were talking about the medication you're taking to help manage your mood"
      ),
      nextStep: this.askSubstancesInfo
    }
  }

  @step.logState
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    ignoredCategories: [Category.Medication],
    getNextStep: (s: SelfReferralScript) => s.returnToAskMedicationInfo
  })
  async handleMedicationInfoWithCrisis(d: IStepData<State, string>): Promise<IStepResult> {
    d.state.medicationInfo = d.response
    this.setPeople({ medicationInfo: d.response })
    const result = await this.onHandleMedicationInfoWithCrisis?.(d.state)
    if (result) return result
    return { nextStep: this.askCurrentSupport }
  }

  @step.logState
  askMedicationWithinDoseRange(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Are you using the medication within the recommended dose range on the packet?"),
      prompt: {
        id: this.getPromptId("askMedicationDoseRange"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: "Yes" },
          { body: this.t("No"), value: "No" },
          { body: this.t("Not sure"), value: "Not sure" }
        ],
        dataPointsName: "askMedicationDoseRange"
      },
      nextStep: this.handleMedicationWithinDoseRange
    }
  }

  @step.logState
  async handleMedicationWithinDoseRange(
    d: IStepData<State, "Yes" | "No" | "Not sure">
  ): Promise<IStepResult> {
    d.state.medicationWithinDoseRange = d.response
    this.setPeople({ medicationWithinDoseRange: d.response })
    const result = await this.onHandleMedicationWithinDoseRange?.(d.state)
    if (result) return result

    return { nextStep: this.askCurrentSupport }
  }

  @step.logState
  askCurrentSupport(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Are you currently receiving mental health support from somewhere else?"),
      prompt: {
        id: this.getPromptId("askCurrentSupport"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: false,
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askCurrentSupport"
      },
      nextStep: this.handleCurrentSupport
    }
  }

  @step.logStateAndResponse
  async handleCurrentSupport(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.hasCurrentSupport = d.response
    this.setPeople({ hasCurrentSupport: d.response })
    const result = await this.onHandleCurrentSupport?.(d.state)
    if (result) return result

    return { nextStep: this.goToCollectMainIssue }
  }

  @step.logState
  async doReferralSubmission(d: IStepData<State>): Promise<IStepResult> {
    const referralSubmitted = await this.onSubmitReferralData(d.state)
    if (!referralSubmitted) {
      d.state.referralSubmitted = false
      d.state.referralSubmissionFailed = true
      this.setPeople({ referralSubmissionFailed: true })
      return { nextStep: this.sayReferralFailed }
    }
    const age = this.getUserAge(d.state)
    await this.rootStore.dataPointsStore.sendDataPoints(age)
    d.state.referralSubmitted = true
    d.state.referralSubmissionFailed = false
    this.setPeople({ referralSubmitted: true })
    await this.onReferralFinished?.(d.state)
    if (this.clinicalStore.isRisk) await this.onRiskReferralFinished?.(d.state)
    return { nextStep: this.sayReferralSucceeded }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return SelfReferralScriptStateSchema
  }

  getEthnicities(state: State): string[] {
    return []
  }

  getNationalities(state: State): string[] {
    return []
  }

  getReligions(state: State): string[] {
    return []
  }

  getGenders(state: State): string[] {
    return []
  }

  getGenderSameAsBirthValues(_state: State): string[] {
    return []
  }

  getPerinatalStatuses(state: State): string[] {
    return []
  }

  getLanguages(state: State): string[] {
    return []
  }

  getDisabilities(state: State): string[] {
    return []
  }

  getExArmedForcesValues(state: State): string[] {
    return []
  }

  getSexualities(state: State): string[] {
    return []
  }

  getMedicalConditions(state: State): string[] {
    return []
  }

  getAlcoholRisk(state: State): boolean {
    switch (state.alcoholQuantity) {
      case ALCOHOL_QUANTITIES._3_4:
        return state.alcoholFrequency === ALCOHOL_FREQUENCIES.WEEKLY_4
      case ALCOHOL_QUANTITIES._5_6:
        return [
          ALCOHOL_FREQUENCIES.WEEKLY_2_TO_3, //
          ALCOHOL_FREQUENCIES.WEEKLY_4
        ].includes(state.alcoholFrequency! as any)
      case ALCOHOL_QUANTITIES._7_9:
        return [
          ALCOHOL_FREQUENCIES.MONTHLY_2_TO_4,
          ALCOHOL_FREQUENCIES.WEEKLY_2_TO_3,
          ALCOHOL_FREQUENCIES.WEEKLY_4
        ].includes(state.alcoholFrequency! as any)
      case ALCOHOL_QUANTITIES._10_PLUS:
        return true
      case ALCOHOL_QUANTITIES._0_2:
      default:
        return false
    }
  }

  getChoiceBody(text: string): string {
    return (
      {
        "Not stated (Person asked but declined to provide a response)": "Prefer not to say",
        "Person asked and does not know or is not sure": "Not sure",
        "Unknown (Person asked and does not know or isn't sure)": "Not sure",
        "Patient Religion Unknown": "Not sure",
        "(None)": "None"
      }[text] || text
    )
  }

  async onSubmitReferralData(state: State): Promise<boolean> {
    try {
      const payload = await this.getReferralPayload(state)
      await this.referralStore.createReferral(payload)
      this.referralStore.setShouldHavePatientIdAndInstanceId(true)
      state.patientId = this.referralStore.patientId
      state.signupCode = this.referralStore.signupCode
      this.track(TrackingEvents.SELF_REFERRAL_SUBMITTED)
    } catch (e) {
      this.referralStore.setShouldHavePatientIdAndInstanceId(false)
      this.logException(e, "onSubmitReferralData")
      return false
    }
    return true
  }
}
