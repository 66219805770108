import React from "react"
import { ThemeProvider } from "@material-ui/core"
import theme from "../styles/theme"

interface Props {
  children: React.ReactNode
}

export default function Layout(props: Props): JSX.Element {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
}
