import BaseScript, { BaseScriptState } from "../../../BaseScript"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { step } from "../../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { IDefaultChatFlowMessagesCollectASD } from "@limbic/types"

export type ICollectASDSettings = {
  messages?: IDefaultChatFlowMessagesCollectASD
}

type State = BaseScriptState

export type CollectASDScriptState = State

export class CollectASDScript extends BaseScript<State> {
  readonly name: string = "CollectASDScript"
  protected messages: IDefaultChatFlowMessagesCollectASD | undefined
  constructor(settings: ICollectASDSettings | undefined = {}) {
    super()
    this.messages = settings?.messages ?? {}
  }

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askHasASD }
  }

  @step.logState
  askHasASD(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messages?.askHasASD ??
          "Do you have a confirmed diagnosis of Autistic Spectrum Disorder (ASD)?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askHasASD"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askHasASD"
      },
      nextStep: this.handleHasASD
    }
  }

  @step.logStateAndResponse
  async handleHasASD(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.hasASD = d.response
    this.referralStore.setCustomField<State>("hasASD", d.state.hasASD)
    return { nextStep: this.end }
  }
}

export default class CollectASDDialogue extends AdHocDialogue<State, CollectASDScript> {
  static id = DialogueIDs.CollectASD
  readonly name: string = "CollectASDDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>, settings?: ICollectASDSettings) {
    super(
      CollectASDDialogue.id,
      new CollectASDScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
