import { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../DialogueIDs"
import type { UnderAgeSignpostScriptState } from "./UnderAgeSignpostDialogue"
import { UnderAgeSignpostScript } from "./UnderAgeSignpostDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"

type State = UnderAgeSignpostScriptState

export type UnderAgeSignpostGMHubScriptState = State

export class UnderAgeSignpostGMHubScript extends UnderAgeSignpostScript {
  readonly name: string = "UnderAgeSignpostGMHubScript"

  /** Script Steps */

  @step.logState
  sayNotEligible(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    const referralUrl = "https://www.penninecare.nhs.uk/c19screening"
    const phoneNumbers = this.rootStore.configStore.organisationPhoneNumbers
    const userType = this.wellbeingHubStore.userType
    const message =
      !userType || userType === "individual"
        ? [
            "The links to refer yourself are [here]({referralUrl})",
            "If you are between 14 and 15 or 8-13 years old, you can find the related questionnaires on the left of the screen",
            "You'll need a parent or carer to help you"
          ]
        : [
            "The links to refer someone else under 16 years old are [here]({referralUrl})",
            "For children aged between 14 and 15 or 8-13, you can find the related questionnaires on the left of the screen",
            "They'll need a parent or carer to help them",
            "Call on {phoneNumbers} or [email](GM.help@nhs.net) the hub directly to discuss needs of children under 8"
          ]

    return {
      body: this.t(
        [
          "Unfortunately I can't assist with referrals to the {serviceName} for people under the age of 16",
          ...message
        ],
        { serviceName: organisationName || iaptName, referralUrl, phoneNumbers }
      ),
      prompt: {
        id: this.getPromptId("sayNotEligible"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Okay"), value: "okay" },
          { body: this.t("Thanks"), value: "thanks" }
        ],
        isUndoAble: false
      },
      nextStep: this.handleNotEligible
    }
  }
}

export default class UnderAgeSignpostGMHubDialogue extends AdHocDialogue<
  State,
  UnderAgeSignpostGMHubScript
> {
  static id = DialogueIDs.UnderageSignpostGMHub
  readonly name: string = "UnderAgeSignpostGMHubDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(UnderAgeSignpostGMHubDialogue.id, new UnderAgeSignpostGMHubScript(), state, snapshot)
  }
}
