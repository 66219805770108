import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../DialogueIDs"
import CrisisScript, { CrisisScriptState } from "./CrisisScript"
import type { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import CrisisDetector from "../../../models/CrisisDetector"
import { RiskLevel, RiskLevelReason } from "../../../models/Constants"

type State = CrisisScriptState
export type CrisisVHGState = State

export class CrisisVHGScript extends CrisisScript {
  readonly name: string = "CrisisVHGScript"

  /** Script Steps */

  @step
  async handleAreYouInCrisis(d: IStepData<State, boolean>): Promise<IStepResult> {
    this.clinicalStore.setIsCrisis(d.response)
    this.setCrisisDetectionCorrect(d.state, d.response)

    d.response
      ? this.setRiskLevelHigh(d.state, RiskLevelReason.CRISIS_DETECTION)
      : this.setRiskLevelModerate(d.state, RiskLevelReason.CRISIS_DETECTION)

    this.referralStore
      .updateReferral({
        riskLevel: d.response ? this.clinicalStore.riskLevel : RiskLevel.Moderate,
        riskLevelReason: this.clinicalStore.riskLevelReason,
        triggerWords: this.clinicalStore.triggerWords
      })
      .catch(e => this.logException(e, "handleAreYouInCrisis -> updateReferral"))

    this.updateReferralType(d.state)

    const result = await this.onHandleAreYouInCrisis?.(d.state)
    if (result) return result

    if (!d.response && d.state.disableDetectionIfWrong) {
      CrisisDetector.getInstance().disableCrisisDetectionForNextInput()
    }
    return {
      nextStep: d.response //
        ? this.saySorryToHear
        : this.saySorryForTheMisunderstanding
    }
  }

  @step
  saySorryToHear(d: IStepData<State>): IStepResult {
    const crisisPhoneNumbers = this.rootStore.configStore.crisisPhoneNumbers
    const name = this.getName(d.state)
    return {
      body: this.t(
        [
          "Sorry to hear that {name}",
          "It is normal for people to have thoughts of this nature at times",
          "However, this is not an emergency response service",
          "If you need urgent, life threatening medical help please call [999](tel:999)",
          "Alternatively, you can call our services if it's not a life-threatening situation on the following numbers:\n{crisisPhoneNumbers}"
        ],
        { name, crisisPhoneNumbers }
      ),
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("I understand") }, { body: this.t("Okay") }]
      },
      nextStep: this.handleSorryToHear
    }
  }

  @step.logState
  handleSorryToHear(d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField("crisisNumbersShared", "999 and crisis numbers from services")
    return { nextStep: d.state.referralSubmitted ? this.sayTheyWillCall : this.end }
  }

  sayTheyWillCall(d: IStepData<State>): IStepResult {
    const serviceName = this.rootStore.configStore.serviceName
    return {
      body: this.t(
        "One of {serviceName}'s clinical team will contact you on {phoneNumber} to check that you have referred yourself in to one of the crisis support services",
        { serviceName, phoneNumber: d.state.phoneNumber }
      ),
      nextStep: this.end
    }
  }

  /* Generic Handlers */

  async onHandlePhoneNumber(_state: State): Promise<IStepResult | void> {
    return { body: this.t("Thank you"), nextStep: this.sayTheyWillCall }
  }
}

/* istanbul ignore next */
export default class CrisisVHGDialogue extends AdHocDialogue<State, CrisisVHGScript> {
  static id = DialogueIDs.CrisisVHG
  readonly name: string = "CrisisVHGDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(CrisisVHGDialogue.id, new CrisisVHGScript(), state, snapshot)
  }
}
