import { z, ZodSchema } from "zod"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import {
  EligibilityCheckIAPTScript,
  EligibilityCheckIAPTStateSchema
} from "./EligibilityCheckIAPTDialogue"
import type { EligibilityCheckIAPTState } from "./EligibilityCheckIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

const UNDO_ENABLED = process.env.REACT_APP_UNDO === "enabled"

type SEEKING_SUPPORT_FOR = "myself" | "someone_else"

interface State extends EligibilityCheckIAPTState {
  seekingSupportFor?: SEEKING_SUPPORT_FOR
  isHealthOrSocialWorker?: boolean
  supportForTeam?: boolean
  organisation?: string
}

export type EligibilityCheckWellbeingHubScriptState = State

export const EligibilityCheckWellbeingHubScriptStateSchema = EligibilityCheckIAPTStateSchema.extend(
  {
    seekingSupportFor: z.union([z.literal("myself"), z.literal("someone_else")]).optional(),
    isHealthOrSocialWorker: z.boolean().optional(),
    supportForTeam: z.boolean().optional(),
    organisation: z.string().optional()
  }
)

export class EligibilityCheckWellbeingHubScript extends EligibilityCheckIAPTScript {
  readonly name: string = "EligibilityCheckWellbeingHubScript"

  /** Script Steps */

  @step.logState
  startEligibilityCheck(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askAreYouSeekingSupportForYourSelf }
  }

  @step.logState
  askAreYouSeekingSupportForYourSelf(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Are you seeking wellbeing support for yourself or someone else?",
        "Please note we are unable to support individuals under the age of 16"
      ]),
      prompt: {
        id: this.getPromptId("askAreYouSeekingSupportForYourSelf"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: this.t("Myself"), value: "myself" },
          { body: this.t("Someone else (over 16)"), value: "someone_else" }
        ]
      },
      nextStep: this.handleAreYouSeekingSupportForYourSelf
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, SEEKING_SUPPORT_FOR>, script: EligibilityCheckWellbeingHubScript) => {
      d.state.seekingSupportFor = d.response
      script.referralStore.setCustomField<State>("seekingSupportFor", d.response)
    }
  )
  handleAreYouSeekingSupportForYourSelf(d: IStepData<State, SEEKING_SUPPORT_FOR>): IStepResult {
    if (d.response === "someone_else") {
      return { nextStep: this.askIndividualOrTeam }
    }
    return { nextStep: this.askDoYouWorkInHealthOrSocialCare }
  }

  @step.logState
  askIndividualOrTeam(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Are you looking for wellbeing support for a team?"),
      prompt: {
        id: this.getPromptId("askIndividualOrTeam"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleIndividualOrTeam
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, boolean>, script: EligibilityCheckWellbeingHubScript) => {
      d.state.supportForTeam = d.response
      script.referralStore.setCustomField<State>("supportForTeam", d.response)
    }
  )
  handleIndividualOrTeam(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      this.wellbeingHubStore.setWellbeingHubUserType("managerTeam")
      return { nextStep: this.sayYoureEligible }
    }
    this.wellbeingHubStore.setWellbeingHubUserType("managerIndividual")
    return { nextStep: this.sayYoureEligible }
  }

  @step.logState
  askDoYouWorkInHealthOrSocialCare(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Do you work in health or social care?"),
      prompt: {
        id: this.getPromptId("askDoYouWorkInHealthOrSocialCare"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: false,
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleDoYouWorkInHealthOrSocialCare
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, boolean>, script: EligibilityCheckWellbeingHubScript) => {
      d.state.isHealthOrSocialWorker = d.response
      script.referralStore.setCustomField<State>("isHealthOrSocialWorker", d.response)
    }
  )
  handleDoYouWorkInHealthOrSocialCare(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.askWhichOrganisationDoYouWorkFor }
    }
    return { nextStep: this.sayNotEligible }
  }

  @step.logState
  askWhichOrganisationDoYouWorkFor(d: IStepData<State>): IStepResult {
    const organisations = this.getOrganisations(d.state)
    if (!organisations?.length) {
      this.logBreadcrumb("ORGANISATIONS NOT FOUND", d.state, { organisations })
      this.logMessage("ORGANISATIONS NOT FOUND")
      return { nextStep: this.sayYoureEligible }
    }

    return {
      body: this.t("Which organisation do you work in?"),
      prompt: {
        id: this.getPromptId("askWhichOrganisationDoYouWorkFor"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: false,
        choices: organisations.map(o => ({ body: this.t(o), value: o }))
      },
      nextStep: this.handleWhichOrganisationDoYouWorkFor
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, string>, script: EligibilityCheckWellbeingHubScript) => {
      d.state.organisation = d.response
      script.referralStore.setCustomField<State>("organisation", d.response)
    }
  )
  handleWhichOrganisationDoYouWorkFor(_d: IStepData<State, string>): IStepResult {
    this.wellbeingHubStore.setWellbeingHubUserType("individual")
    return { nextStep: this.sayYoureEligible }
  }

  @step.logState
  sayYoureEligible(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    const userType = this.wellbeingHubStore.userType

    if (userType === "individual") {
      return {
        body: this.t(["Good news - you're eligible for free support from {iaptName}"], {
          iaptName
        }),
        nextStep: this.goToSelfReferralIndividual
      }
    }

    if (userType === "managerIndividual") {
      return {
        body: this.t(
          [
            "Okay you want to refer a team member for support. Thanks!",
            "Good news - they're eligible for free support from {iaptName}"
          ],
          { iaptName }
        ),
        nextStep: this.goToSelfReferralManagerIndividual
      }
    }

    if (userType === "managerTeam") {
      return {
        body: this.t(
          [
            "Okay you want to refer a team for support. Thanks!",
            "Good news - they're eligible for free support from {iaptName}"
          ],
          { iaptName }
        ),
        nextStep: this.goToSelfReferralManagerTeam
      }
    }
    return { nextStep: this.goToGoodbye }
  }

  @step.logState
  sayNotEligible(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: this.t(
        [
          "So {name}, {organisationName} currently support staff members working within the Health and Social care sector only",
          "Or you can talk to your GP and they will help you"
        ],
        { name, organisationName }
      ),
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  goToSelfReferralManagerTeam(_d: IStepData<State>): IStepResult {
    this.wellbeingHubStore.setWellbeingHubUserType("managerTeam")
    return { nextStep: this.end }
  }

  @step.logState
  goToSelfReferralManagerIndividual(_d: IStepData<State>): IStepResult {
    this.wellbeingHubStore.setWellbeingHubUserType("managerIndividual")
    return { nextStep: this.end }
  }

  @step.logState
  goToSelfReferralIndividual(_d: IStepData<State>): IStepResult {
    this.wellbeingHubStore.setWellbeingHubUserType("individual")
    return { nextStep: this.end }
  }

  @step
  sayINeedToAskSomeDetails(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: this.t(
        UNDO_ENABLED
          ? [
              "In order to create a referral to {organisationName}, I just need to confirm a few details with you",
              "You can re-type your answer at any point by clicking the 'Undo' button next to the message you want to change"
            ]
          : "In order to create a referral to {organisationName}, I just need to confirm a few details with you",
        { organisationName }
      ),
      nextStep: this.askAreYouSeekingSupportForYourSelf
    }
  }

  getOrganisations(_state: State): string[] {
    return []
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return EligibilityCheckWellbeingHubScriptStateSchema
  }
}

export default class EligibilityCheckWellbeingHubDialogue extends Dialogue<State> {
  static id = DialogueIDs.EligibilityCheckWellbeingHub
  readonly name: string = "EligibilityCheckWellbeingHubDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      EligibilityCheckWellbeingHubDialogue.id,
      new EligibilityCheckWellbeingHubScript(),
      state,
      snapshot
    )
  }
}
