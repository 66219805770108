import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { IntroductionScript, IntroductionScriptState } from "./IntroductionScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = IntroductionScriptState

export class IntroductionGPScript extends IntroductionScript {
  readonly name: string = "IntroductionGPScript"

  /** Script Steps */

  @step.logState
  greet(_d: IStepData<State>): IStepResult {
    const { directReferral } = this.rootStore.configStore
    const serviceName = this.rootStore.configStore.serviceName
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: this.t(
        [
          "Hi there!",
          "I’m Limbic",
          serviceName !== organisationName
            ? "I'm a friendly robot assistant to help you access psychological support via {serviceName} to {organisationName} who are the local provider for mental health services"
            : "I'm a friendly robot assistant to help you access psychological support via {serviceName}"
        ],
        { serviceName, organisationName }
      ),
      nextStep: directReferral ? this.end : this.askCanIHelp
    }
  }

  @step.logState
  askCanIHelp(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Can I help?"),
      prompt: {
        id: this.getPromptId("askCanIHelp"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes, I'm looking for mental health support"), value: true },
          { body: this.t("No, I'm just browsing"), value: false }
        ],
        isUndoAble: false
      },
      nextStep: this.handleCanIHelp
    }
  }
}

/* istanbul ignore next */
export default class IntroductionGPDialogue extends Dialogue<State> {
  static id = DialogueIDs.IntroductionGP
  readonly name: string = "IntroductionGPDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(IntroductionGPDialogue.id, new IntroductionGPScript(), state, snapshot)
  }
}
