import React from "react"
import { withStyles } from "@material-ui/core/styles"
import MUICheckbox, { CheckboxProps } from "@material-ui/core/Checkbox"
import FormControl from "@material-ui/core/FormControl"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"

interface Props {
  label: string
  value: boolean
  onValueChange: (newValue: boolean) => void
}

export default function SwitchInput(props: Props): JSX.Element {
  return (
    <FormControl component="fieldset">
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={props.value}
              onChange={e => props.onValueChange(e.target.checked)}
              inputProps={{ "aria-label": props.label, tabIndex: 0 }}
            />
          }
          label={props.label}
        />
      </FormGroup>
    </FormControl>
  )
}

const Checkbox = withStyles({
  root: {
    color: "#EC9CC8"
  }
})((props: CheckboxProps) => <MUICheckbox color={"default"} {...props} />)
