import invariant from "../utils/invariant"

export const backendEnv = (process.env.REACT_APP_BACKEND_ENV || "production") as
  | "staging"
  | "development"
  | "production"

const SLACK_WEBHOOK_TOKEN = process.env.REACT_APP_SLACK_WEBHOOK_TOKEN
export const slackUrl = `https://hooks.slack.com/services/${SLACK_WEBHOOK_TOKEN}`

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || ""
export const API_KEY = process.env.REACT_APP_ACCESS_BACKEND_API_KEY || ""
invariant(BACKEND_URL, "Environment variable REACT_APP_BACKEND_URL was not found")
invariant(API_KEY, "Environment variable REACT_APP_ACCESS_BACKEND_API_KEY was not found")
