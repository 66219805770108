import ChatFlowPayloadBuilder from "../ChatFlowPayloadBuilder"
import { ChatFlowsEnum, ReferralPayload } from "@limbic/types"

export default class ASDPayloadBuilder extends ChatFlowPayloadBuilder<ChatFlowsEnum.COLLECT_ASD> {
  chatFlow = ChatFlowsEnum.COLLECT_ASD

  /** Overrides */

  getDefaultPayload(): Partial<Partial<ReferralPayload>> {
    return { asd: this.ctx.state?.hasASD } as Partial<ReferralPayload>
  }
}

// prettier-ignore
ChatFlowPayloadBuilder.builders[ChatFlowsEnum.COLLECT_ASD] = ASDPayloadBuilder
