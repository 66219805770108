import { LanguageCodes } from "@limbic/types"
import { Translations } from "../i18n/Languages"

export function getBrowserLanguage(): LanguageCodes {
  return (window.navigator?.language.toLowerCase() as LanguageCodes) ?? LanguageCodes.EN_GB
}

export function hasTranslationCode(code: LanguageCodes, translations: Translations): boolean {
  return Object.values(translations).some(translation => Object.keys(translation).includes(code))
}
