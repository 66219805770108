import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { BaseScriptState } from "../../BaseScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"
import { SelfReferralPitchIAPTScript } from "./SelfReferralPitchIAPTDialogue"

interface State extends BaseScriptState {
  userAborted?: boolean
}

export type SelfReferralPitchMindScriptState = State

export class SelfReferralPitchMindScript extends SelfReferralPitchIAPTScript {
  readonly name: string = "SelfReferralPitchMindScript"

  /** Script Steps */

  @step.logState
  askWannaBookIn(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "In order to connect you to the service, I need to ask you a few questions. Is that okay?"
      ),
      prompt: {
        id: this.getPromptId("askWannaBookIn"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Sure") }, { body: this.t("Okay") }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleWannaBookIn
    }
  }
}

/* istanbul ignore next */
export default class SelfReferralPitchMindDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralPitchMind
  readonly name: string = "SelfReferralPitchMindDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(SelfReferralPitchMindDialogue.id, new SelfReferralPitchMindScript(), state, snapshot)
  }
}
