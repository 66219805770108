import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import { PermissionsIAPTScript } from "./PermissionsIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { PermissionsScriptState } from "./PermissionsScript"
import { TrackingEvents } from "../../../models/Constants"
import { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"

interface State extends PermissionsScriptState {
  requiresUrgentSupport?: boolean
  referrerName?: string
  referrerEmail?: string
  isSelfReferrer?: boolean
}
export type PermissionsMindScriptState = State

export class PermissionsMindScript extends PermissionsIAPTScript {
  readonly name: string = "PermissionsMindScript"

  /** Script Steps */

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "I know that talking about mental health can be difficult",
        "Before I start finding out more about you, I'm going to run you through how we keep your personal information safe 👇"
      ]),
      nextStep: this.showTOSLinks
    }
  }

  @step.logState
  showTOSLinks(_d: IStepData<State>): IStepResult {
    const organisationName =
      this.rootStore.configStore.organisationName ?? this.rootStore.configStore.serviceName
    const organisationTerms = this.rootStore.configStore.organisationTerms ?? ""
    return {
      body: this.t(
        [
          "To get you the best referral, I will need to share your answers with {organisationName}",
          "You can find all the details here:\n\n" +
            "Limbic [Terms of Service](https://www.limbic.ai/terms-of-use)\n" +
            "Limbic [Privacy Policy](https://www.limbic.ai/privacy)\n{organisationTerms}",
          "Please note that if you'd like to access supported self-help you will need to agree to the terms and allow your details to be shared with {organisationName}",
          "They will be stored safely and kept confidential",
          "Alternatively, you can find other support [here](https://www.mind.org.uk/information-support/)"
        ],
        { organisationName, organisationTerms }
      ),
      nextStep: this.promptIUnderstandTerms
    }
  }

  @step.logState
  askRequiresUrgentSupport(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Supported self-help is not an emergency service",
        "We’re sorry, but our service can't help you if you're currently in crisis, or are concerned about keeping yourself, or any dependents safe whilst accessing the service",
        "Before we continue, we need to check if you require urgent support",
        "Are you currently experiencing a crisis and require immediate help because of suicidal thoughts?"
      ]),
      prompt: {
        id: this.getPromptId("askRequiresUrgentSupport"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: false,
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askRequiresUrgentSupport"
      },
      nextStep: this.handleRequiresUrgentSupport
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.requiresUrgentSupport = d.response
  })
  handleRequiresUrgentSupport(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      this.setEligibility(d.state, false)
      this.clinicalStore.setRequiresUrgentSupport(true)
      this.clinicalStore.setIsCrisis(d.response)
      this.track(TrackingEvents.INELIGIBLE_REQUIRES_URGENT_SUPPORT)
    }
    return {
      nextStep: d.response //
        ? this.sayCrisis
        : this.askWhoIsReferring
    }
  }

  @step.logState
  sayCrisis(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: this.t(
        [
          "Sorry to hear that {name}",
          "Mental health emergencies are serious. You're not wasting anyone's time",
          "It is normal for people to have thoughts of this nature at times",
          "However {organisationName} is not an emergency response service",
          "If you feel like you might attempt suicide, or may have seriously harmed yourself, you need urgent medical help, please call 999 for an ambulance",
          "If you can keep yourself safe for a short while, but you still need urgent advice please call NHS 111",
          "If you need to talk right now, you can call Samaritans on 116 123",
          'If you are in Wales you can also use the C.A.L.L Mental Health Helpline which is free and available 24/7 on 0800 132 737 or text "help" to 81066',
          "You can refer yourself back to {organisationName} when you are no longer in crisis"
        ],
        { name, organisationName }
      ),
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("I understand") }, { body: this.t("Okay") }]
      },
      nextStep: this.handleSayCrisis
    }
  }

  @step.logState
  handleSayCrisis(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "999, NHS 111 (Option 2), Samaritans (116 123), C.A.L.L (0800 132 737), SMS to 81066"
    )
    return { nextStep: this.goToGoodbye }
  }

  @step.logState
  askWhoIsReferring(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Do you need supported self-help for yourself or on behalf of someone else?"),
      prompt: {
        id: this.getPromptId("askWhoIsReferring"),
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: this.t("It's for myself"), value: true },
          { body: this.t("It's for someone else"), value: false }
        ]
      },
      nextStep: this.handleWhoIsReferring
    }
  }

  @step.logState
  handleWhoIsReferring(d: IStepData<State, boolean>): IStepResult {
    d.state.isSelfReferrer = d.response

    if (!d.response) {
      return {
        nextStep: this.showReferringSomeoneElseConsent
      }
    }
    return { nextStep: this.askResearchConsent }
  }

  @step.logState
  showReferringSomeoneElseConsent(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Has the person you're referring agreed that you can make this referral, and do they know what they are being referred for?"
      ),
      prompt: {
        id: this.getPromptId("showReferringSomeoneElseConsent"),
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleShowReferringSomeoneElseConsent
    }
  }

  @step.logState
  handleShowReferringSomeoneElseConsent(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.askReferrerName }
    }

    return { nextStep: this.sayConsentIsRequired }
  }

  @step
  sayConsentIsRequired(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "The person you're referring has to know and agree to being referred",
        "Please come back to Mind's supported self-help once agreement has been given"
      ]),
      prompt: {
        id: this.getPromptId("sayOtherResources"),
        type: "inlinePicker",
        choices: [{ body: this.t("I understand") }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  askReferrerName(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Please type your name"),
      prompt: {
        id: this.getPromptId("askReferrerName"),
        type: "text",
        isUndoAble: true
      },
      nextStep: this.handleReferrerNameWithCrisis
    }
  }

  @step
  returnToAskReferrerName(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("So..."),
      nextStep: this.askReferrerName
    }
  }

  @step.logStateAndResponse
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    getNextStep: (s: PermissionsMindScript) => s.returnToAskReferrerName
  })
  handleReferrerNameWithCrisis(d: IStepData<State>): IStepResult {
    d.state.referrerName = d.response
    return { nextStep: this.askReferrerEmail }
  }

  @step.logState
  askReferrerEmail(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Please type your email"),
      prompt: {
        id: this.getPromptId("askReferrerEmail"),
        type: "text",
        isUndoAble: true
      },
      nextStep: this.handleReferrerEmailWithCrisis
    }
  }

  @step
  returnToAskReferrerEmail(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("So..."),
      nextStep: this.askReferrerEmail
    }
  }

  @step.logStateAndResponse
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    getNextStep: (s: PermissionsMindScript) => s.returnToAskReferrerEmail
  })
  handleReferrerEmailWithCrisis(d: IStepData<State>): IStepResult {
    d.state.referrerEmail = d.response
    return { nextStep: this.showReferringSomeoneElseMessage }
  }

  @step.logState
  showReferringSomeoneElseMessage(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Please answer the following questions on behalf of someone else"),
      prompt: {
        id: this.getPromptId("showReferringSomeoneElseMessage"),
        type: "inlinePicker",
        isUndoAble: true,
        choices: [{ body: this.t("Okay") }]
      },
      nextStep: this.handleReferringSomeoneElseMessage
    }
  }

  @step.logState
  handleReferringSomeoneElseMessage(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askResearchConsent }
  }

  @step.logState
  askResearchConsent(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "I am also working with researchers to improve mental health treatment",
        "Are you ok if Limbic uses your data anonymously to support the development of the product and research, which might be used for scientific publications?",
        "Your answer will not impact the decision on whether or not we can provide you with this service"
      ]),
      prompt: {
        id: this.getPromptId("askResearchConsent"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleResearchConsent
    }
  }

  @step.logState
  sayReady(d: IStepData<State>): IStepResult {
    return {
      body: this.t(["Thanks, let's continue", "Ready?"]),
      prompt: {
        id: this.getPromptId("sayReady"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Let's get started") }]
      },
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onHandleIUnderstandTerms(_state: State): Promise<IStepResult | void> {
    return { nextStep: this.askRequiresUrgentSupport }
  }
}

/* istanbul ignore next */
export default class PermissionsMindDialogue extends Dialogue<State> {
  static id = DialogueIDs.PermissionsMind
  readonly name: string = "PermissionsMindDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PermissionsMindDialogue.id, new PermissionsMindScript(), state, snapshot)
  }
}
