import BaseScript from "../../BaseScript"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { SuitableServicesSignpostScriptState } from "./SuitableServicesSignpostScript"

export interface SuitableServicesSignpostMindScriptState
  extends SuitableServicesSignpostScriptState {
  treatmentOption?: TreatmentPath
}
type State = SuitableServicesSignpostMindScriptState

type TreatmentPath = "Infoline" | "Side by Side" | "Website" | "Local Mind"

export class SuitableServicesSignpostMindScript extends BaseScript<State> {
  readonly name = "SuitableServicesSignpostMindScript"

  /** Script Steps */

  @step
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.sayWeDontSupportCurrentSupport }
  }

  @step.logState
  sayWeDontSupportCurrentSupport(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t(
        "So {name}, this programme is for people who are not already receiving talking therapies from another service",
        { name }
      ),
      prompt: {
        id: this.getPromptId("sayWeDontSupportYourArea"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Goodbye") },
          { body: this.t("Fair enough") },
          { body: this.t("Show me what other options I have") }
        ]
      },
      nextStep: this.handleWeDontSupportCurrentSupport
    }
  }

  @step
  handleWeDontSupportCurrentSupport(d: IStepData<State, string>): IStepResult {
    if (d.response === "Show me what other options I have") {
      return {
        nextStep: this.showTreatmentOptions
      }
    }
    const name = this.getName(d.state)
    return {
      body: this.t("Sorry about that {name}", { name }),
      nextStep: this.closeDiscussion
    }
  }

  @step.logState
  showTreatmentOptions(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Our [Infoline](https://www.mind.org.uk/information-support/helplines/) provides an information and signposting service. Ring 0300 123 3393 from 9am to 6pm, Monday to Friday except for bank holidays",
        "We all know what it's like to struggle sometimes. [Side by Side](https://sidebyside.mind.org.uk/) is a supportive online community, which provides a safe place to listen, share and be heard",
        "When you're living with a mental health problem, or supporting someone who is, having access to the right information is vital. Our [website](https://www.mind.org.uk/information-support/) contains lots of information and support",
        "You can also find Mind where you live. [Local Mind](https://www.mind.org.uk/information-support/local-minds/) services include talking therapies, crisis helplines, drop-in centres, employment and training schemes, counselling and befriending"
      ]),
      prompt: {
        id: this.getPromptId("showTreatmentOptions"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }]
      },
      nextStep: this.closeDiscussion
    }
  }

  @step
  closeDiscussion(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t([
        `I'm really glad you took this important step towards better mental health ${name}`,
        "Well done!",
        "And good luck"
      ]),
      nextStep: this.end,
      clearStack: true
    }
  }
}

/* istanbul ignore next */
export default class SuitableServicesSignpostMindDialogue extends Dialogue<State> {
  static id = DialogueIDs.SuitableServicesSignpostMind
  readonly name: string = "SuitableServicesSignpostMindDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      SuitableServicesSignpostMindDialogue.id,
      new SuitableServicesSignpostMindScript(),
      state,
      snapshot
    )
  }
}
