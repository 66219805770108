import { action, computed, observable } from "mobx"
import Persistable from "../../models/Persistable"
import { API_KEY, BACKEND_URL } from "../../config/config"
import invariant from "../../utils/invariant"

export default class BackendStore extends Persistable {
  readonly name: string = "BackendStore"

  @observable backendURL: string
  @observable APIKey: string

  constructor() {
    super()
    this.backendURL = this.hydrate("backendURL") ?? BACKEND_URL
    this.APIKey = this.hydrate("APIKey") ?? API_KEY
  }

  /** Actions */

  @action
  setBackendURL(backendURL: string): void {
    this.ensureDevTools()
    this.backendURL = backendURL
    this.persist("backendURL", backendURL)
  }

  @action
  setAPIKey(APIKey: string): void {
    this.ensureDevTools()
    this.APIKey = APIKey
    this.persist("APIKey", APIKey)
  }

  @action
  reset(): void {
    this.setBackendURL(BACKEND_URL)
    this.setAPIKey(API_KEY)
  }

  /* Generic Handlers */

  ensureDevTools(): void {
    try {
      // prettier-ignore
      invariant(process.env.REACT_APP__DEV_TOOLS__ === "enabled", "Mutating BackendStore w/ dev tools disabled")
    } catch (e) {
      this.logException(e, "BackendStore mutation")
      throw e
    }
  }

  /** Getters / Setters */

  @computed
  get isDefault(): boolean {
    return this.backendURL === BACKEND_URL && this.APIKey === API_KEY
  }
}
