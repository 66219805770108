import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import BaseScript, { BaseScriptState } from "../../BaseScript"
import { DialogueIDs } from "../../DialogueIDs"

type State = BaseScriptState

export type UnderAgeSignpostScriptState = State

export class UnderAgeSignpostScript extends BaseScript<State> {
  readonly name: string = "UnderAgeSignpostScript"

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.sayNotEligible }
  }

  @step.logState
  sayNotEligible(d: IStepData<State>): IStepResult {
    const threshold = Math.round(this.getIAPTServiceAgeThreshold(d.state))
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    const name = this.getName(d.state)
    return {
      body: this.t(
        [
          "So {name}, there's a special type of mental health support for people under the age of {threshold}",
          "{iaptName} do not currently provide this",
          "To find the best service for you, there's a helpful website [here](https://www.nhs.uk/service-search/other-services/Mental-health-support-for-young-people/LocationSearch/1430)",
          "Or you can talk to your doctor and they will help you"
        ],
        { name, iaptName, threshold }
      ),
      prompt: {
        id: this.getPromptId("sayNotEligible"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Okay"), value: "okay" },
          { body: this.t("Thanks"), value: "thanks" }
        ]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleNotEligible
    }
  }

  @step
  handleNotEligible(d: IStepData<State, "okay" | "thanks">): IStepResult {
    return {
      body: this.t(d.response === "thanks" ? "You're welcome" : "Great"),
      nextStep: this.sayOtherResources
    }
  }

  @step
  sayOtherResources(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "You might also be interested in checking out the following resources...",
        "Kooth: a counseling resource for young people (https://kooth.com)",
        "The Mix: a free and confidential service that aims to find young people the best help, whatever the problem (https://www.themix.org.uk/",
        "Childline: 0800 1111"
      ]),
      prompt: {
        id: this.getPromptId("sayOtherResources"),
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.sayCrisisCallLine
    }
  }

  @step
  sayCrisisCallLine(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "One last thing",
        "If you feel that you or someone else is in danger, or that you are not able to keep yourself safe for any reason, please call 999 immediately"
      ]),
      prompt: {
        id: this.getPromptId("sayCrisisCallLine"),
        type: "inlinePicker",
        choices: [{ body: this.t("Understood") }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleCrisisCallLine
    }
  }

  @step.logState
  handleCrisisCallLine(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField("crisisNumbersShared", "999")
    return { nextStep: this.closeDiscussion }
  }

  @step
  closeDiscussion(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t(
        [
          "I'm really glad you took this important step towards better mental health {name}",
          "Well done!",
          "And good luck"
        ],
        { name }
      ),
      nextStep: this.end,
      clearStack: true
    }
  }
}

/* istanbul ignore next */
export default class UnderAgeSignpostDialogue extends Dialogue<State> {
  static id = DialogueIDs.UnderageSignpost
  readonly name: string = "UnderAgeSignpostDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(UnderAgeSignpostDialogue.id, new UnderAgeSignpostScript(), state, snapshot)
  }
}
