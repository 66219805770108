/* eslint-disable @typescript-eslint/no-empty-function */
import moment from "moment"
import { z, ZodSchema } from "zod"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import SelfReferralIAPTScript, { SelfReferralIAPTScriptStateSchema } from "./SelfReferralIAPTScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { SelfReferralIAPTScriptState } from "./SelfReferralIAPTScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import invariant from "../../../utils/invariant"
import type {
  ARMED_FORCES_MAYDEN_LINCS,
  CIVIL_STATUS_MAYDEN_LINCS,
  DISABILITY_MAYDEN_LINCS,
  ETHNICITY_MAYDEN_LINCS,
  GENDER_MAYDEN_LINCS,
  LANGUAGE_MAYDEN_LINCS,
  LIMBIC_IMPACT_LEVEL,
  LTC_MAYDEN_LINCS,
  PERINATAL_MAYDEN_LINCS,
  ReferralPayloadMaydenLincs,
  RELIGION_MAYDEN_LINCS,
  SEXUALITY_MAYDEN_LINCS,
  GenderBirthAssigned
} from "@limbic/types"
import { TrackingEvents } from "../../../models/Constants"
import { isValidLandlineNumber, isValidMobilePhone } from "../../../utils/isValidPhoneNumber"
import {
  disabilities,
  ethnicities,
  exArmedForces,
  genders,
  genderSameAsBirth,
  languages,
  ltcs,
  maritalStatuses,
  perinatalStatuses,
  religions,
  sexualities
} from "../../../config/referralForms/lincolnshire-form"
import { removeCountryCodeFromPhoneNumber } from "../../../utils/formatPhoneNumber"
import { IStep } from "../../../backend/chatbot/models/IStep"
import { ICollectMainIssueSettings } from "../ad-hoc/CollectMainIssue/CollectMainIssueDialogue"
import {
  CollectGenderScriptState,
  ICollectGenderSettings
} from "../ad-hoc/CollectGender/CollectGenderDialogue"
import { ICollectDisabilitiesSettings } from "../ad-hoc/CollectDisabilities/CollectDisabilitiesDialogue"
import {
  CollectLanguageAndInterpreterScriptState,
  ICollectLanguageAndInterpreterSettings
} from "../ad-hoc/CollectLanguageAndInterpreter/CollectLanguageAndInterpreterDialogue"
import { ICollectReligionSettings } from "../ad-hoc/CollectReligion/CollectReligionDialogue"
import { ICollectEmailSettings } from "../ad-hoc/CollectEmail/CollectEmailDialogue"
import { parsePhoneNumber } from "awesome-phonenumber"

interface State extends SelfReferralIAPTScriptState {
  gpConsent?: boolean
  sameGenderAsBirth?: string
  mainIssue?: string
  relationshipStatus?: string
  hasMobilityIssues?: boolean
  hasFormalDiagnosis?: boolean
  formalDiagnosisDetails?: string
  currentSupportDetails?: string
  isTakingMedication?: boolean
}

export type SelfReferralLincolnshireState = State

export const SelfReferralLincolnshireStateSchema = SelfReferralIAPTScriptStateSchema.extend({
  gpConsent: z.boolean().optional(),
  sameGenderAsBirth: z.string().optional(),
  relationshipStatus: z.string().optional(),
  mainSpokenLanguage: z.string().optional(),
  hasMobilityIssues: z.boolean().optional(),
  hasFormalDiagnosis: z.boolean().optional(),
  formalDiagnosisDetails: z.string().optional(),
  currentSupportDetails: z.string().optional(),
  isTakingMedication: z.boolean().optional()
})

export class SelfReferralLincolnshireScript extends SelfReferralIAPTScript {
  readonly name: string = "SelfReferralLincolnshireScript"

  /** Script Steps */

  @step.logState
  startSelfReferralPart1(d: IStepData<State>): IStepResult {
    if (d.state.nhsNumber) {
      return { nextStep: this.goToCollectPhoneNumber }
    }
    return { nextStep: this.goToCollectNHSNumber }
  }

  @step.logState
  async goToCollectPhoneNumber(d: IStepData<State>): Promise<IStepResult> {
    const result = await super.goToCollectPhoneNumber(d)
    return { ...result, nextStep: this.goToCollectEmail }
  }

  @step.logState
  askGPConsent(_d: IStepData<State>): IStepResult {
    const serviceName = this.rootStore.configStore.serviceName

    return {
      body: this.t(
        [
          "As part of our process in {serviceName}, we share assessment outcomes and treatment summaries with your GP",
          "Are you happy for us to share these summaries with your GP?"
        ],
        { serviceName }
      ),
      prompt: {
        id: this.getPromptId("askGPConsent1"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleGPConsent
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralLincolnshireScript) => {
    d.state.gpConsent = d.response
    script.referralStore.setCustomField<State>("gpConsent", d.response)
  })
  handleGPConsent(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.goToCollectMainIssue }
    }
    return {
      body: this.t(
        "You selected that you don’t want the clinical summaries shared with you GP, please be aware that in the event of an emergency or issues with risk or safeguarding we would contact your GP for their support"
      ),
      nextStep: this.goToCollectMainIssue
    }
  }

  @step.logState
  startSelfReferralPart2(_d: IStepData<State>): IStepResult {
    return { nextStep: this.goToCollectGender }
  }

  @step.logState
  askRelationshipStatus(d: IStepData<State>): IStepResult {
    const relationshipStatuses = this.getMaritalStatuses(d.state)
    if (!relationshipStatuses?.length) {
      this.logBreadcrumb("RELATIONSHIP STATUSES NOT FOUND", d.state, { relationshipStatuses })
      this.logMessage("RELATIONSHIP STATUSES NOT FOUND")
      return { nextStep: this.askPerinatal }
    }

    return {
      body: this.t("What is your relationship status?"),
      prompt: {
        id: this.getPromptId("askRelationshipStatus"),
        type: "inlinePicker",
        choices: relationshipStatuses.map(g => ({ body: this.t(g), value: g })),
        isUndoAble: true,
        dataPointsName: "askRelationshipStatus"
      },
      nextStep: this.handleRelationshipStatus
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralLincolnshireScript) => {
    d.state.relationshipStatus = d.response
    script.referralStore.setCustomField<State>("relationshipStatus", d.response)
  })
  handleRelationshipStatus(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askPerinatal }
  }

  @step.logState
  askPerinatal(d: IStepData<State>): IStepResult {
    const perinatalStatuses = this.getPerinatalStatuses(d.state)
    if (!perinatalStatuses?.length) {
      this.logBreadcrumb("PERINATAL STATUSES NOT FOUND", d.state, { perinatalStatuses })
      this.logMessage("PERINATAL STATUSES NOT FOUND")
      return { nextStep: this.goToCollectLanguageAndInterpreter }
    }
    return {
      body: this.t(
        "Do any of the following statements about pregnancy or young children apply to you?"
      ),
      prompt: {
        id: this.getPromptId("askPerinatal"),
        trackResponse: true,
        type: "inlinePicker",
        choices: perinatalStatuses.map(g => ({ body: this.t(g), value: g })),
        dataPointsName: "askPerinatal"
      },
      nextStep: this.handlePerinatal
    }
  }

  @step.logState
  askMobilityIssues(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Do you have mobility issues?"),
      prompt: {
        id: this.getPromptId("askMobilityIssues"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        isUndoAble: true,
        dataPointsName: "askMobilityIssues"
      },
      nextStep: this.handleMobilityIssues
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralLincolnshireScript) => {
    d.state.hasMobilityIssues = d.response
    script.referralStore.setCustomField<State>("hasMobilityIssues", d.response)
  })
  handleMobilityIssues(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.goToCollectLongTermMedicalConditionDetails }
  }

  @step.logState
  askMedication(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Are you currently taking any medication that has been prescribed by a doctor?"),
      prompt: {
        id: this.getPromptId("askMedication"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askMedication"
      },
      nextStep: this.handleMedication
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralLincolnshireScript) => {
    d.state.isTakingMedication = d.response
    script.referralStore.setCustomField<State>("hasMobilityIssues", d.response)
  })
  handleMedication(d: IStepData<State, boolean>): IStepResult {
    return { nextStep: d.response ? this.askMedicationInfo : this.askCurrentSupport }
  }

  @step.logState
  askMedicationInfo(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Could you let me know the details of the medication you've been prescribed?"),
      prompt: {
        id: this.getPromptId("askMedicationInfo"),
        trackResponse: false,
        type: "text",
        forceValue: false,
        cancelLabel: this.t("skip"),
        cancelIsEmptySubmit: true,
        dataPointsName: "askMedicationInfo"
      },
      nextStep: this.handleMedicationInfoWithCrisis
    }
  }

  @step.logState
  askCurrentSupportDetails(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Please give details"),
      prompt: {
        id: this.getPromptId("askCurrentSupportDetails"),
        type: "text",
        forceValue: true,
        isUndoAble: false
      },
      nextStep: this.handleCurrentSupportDetailsWithCrisis
    }
  }

  @step.logState
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralLincolnshireScript) => s.askFormalDiagnosis
  })
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralLincolnshireScript) => {
    d.state.currentSupportDetails = d.response
    script.referralStore.setCustomField<State>("currentSupportDetails", d.response)
  })
  handleCurrentSupportDetailsWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askFormalDiagnosis }
  }

  @step.logState
  askFormalDiagnosis(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Have you had any formal diagnosis from a GP, psychiatrist or other mental health professional?"
      ),
      prompt: {
        id: this.getPromptId("askFormalDiagnosis"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        isUndoAble: true
      },
      nextStep: this.handleFormalDiagnosis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralLincolnshireScript) => {
    d.state.hasFormalDiagnosis = d.response
    script.referralStore.setCustomField<State>("hasFormalDiagnosis", d.response)
  })
  handleFormalDiagnosis(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.askGiveDetailsFormalDiagnosis }
    }
    return { nextStep: this.goToCollectGoalForTherapy }
  }

  @step.logState
  askGiveDetailsFormalDiagnosis(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Please give details?"),
      prompt: {
        id: this.getPromptId("askGiveDetailsFormalDiagnosis"),
        type: "text",
        forceValue: true,
        isUndoAble: false
      },
      nextStep: this.handleGiveDetailsFormalDiagnosisWithCrisis
    }
  }

  @step.logState
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralLincolnshireScript) => s.goToCollectGoalForTherapy
  })
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralLincolnshireScript) => {
    d.state.formalDiagnosisDetails = d.response
    script.referralStore.setCustomField<State>("formalDiagnosisDetails", d.response)
  })
  handleGiveDetailsFormalDiagnosisWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.goToCollectGoalForTherapy }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return SelfReferralLincolnshireStateSchema
  }

  async onSubmitReferralData(state: State): Promise<boolean> {
    try {
      const payload = await this.getReferralPayload(state)
      await this.referralStore.createReferral(payload)
      this.referralStore.setShouldHavePatientIdAndInstanceId(true)
      state.patientId = this.referralStore.patientId
      state.signupCode = this.referralStore.signupCode
      this.track(TrackingEvents.SELF_REFERRAL_SUBMITTED)
    } catch (e) {
      this.referralStore.setShouldHavePatientIdAndInstanceId(false)
      this.logException(e, "onSubmitReferralData")
      return false
    }

    return true
  }

  async getReferralPayload(state: State): Promise<ReferralPayloadMaydenLincs> {
    const instanceID = state.iapt?.backendInstanceID
    invariant(instanceID, "Cannot create referral without an Instance ID")
    const isValidMobile = isValidMobilePhone(state.phoneNumber || "0")
    const isValidLandline = isValidLandlineNumber(state.phoneNumber || "0") && !isValidMobile
    const parsed = parsePhoneNumber(state.phoneNumber || "0", { regionCode: "GB" })

    // TODO should be moved somewhere else but setting ADSM to active
    // here for now, until insight is done with the consent and randomisation
    this.clinicalStore.setADSMActive(true)

    return {
      instanceID,
      nhsNumber: state.nhsNumber,
      nameFirst: this.getFirstName(state),
      nameLast: this.getLastName(state),
      nameFirstPreferred: this.getName(state),
      dob: moment(state.birthday).format("YYYY-MM-DD"),
      addressHome: {
        address1: state.address,
        address2: state.address2,
        // If address is entered manually City and County maybe undefined
        // The backend submission then fails
        // Setting them to "unknown" if undefined
        city: state.city || "unknown",
        county: state.county || "unknown",
        postcode: state.userPostcode?.postcode,
        consentMail: !!state.canSendMailToAddress
      },
      phoneHome: isValidLandline
        ? {
            cc: String(parsed.countryCode || ""),
            number: parsed.number?.national.replace(/ /g, "") ?? state.phoneNumber!,
            isMobile: false,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      phoneMobile: isValidMobile
        ? {
            cc: String(parsed.countryCode || ""),
            number: parsed.number?.national.replace(/ /g, "") ?? state.phoneNumber!,
            isMobile: true,
            consentSMS: !!state.canSendTextMessagesToPhoneNumber,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      email: state.email,
      consentEmail: state.canSendEmail,
      problemInOwnWords: state.mainIssue,
      civilStatus: this.getRelationshipStatus(state),
      gender: this.getGender(state),
      genderSameAsBirthAssigned: this.getGenderSameAsBirth(state),
      disability: this.getDisability(state),
      adhd: state.hasADHD,
      asd: state.hasASD,
      perinatal: this.getPerinatal(state),
      language: this.getLanguage(state),
      speakEnglish: state.ableToCommunicateInEnglish ?? true,
      interpreter: state.requiresInterpreter,
      title: state.nameTitle,
      ethnicity: this.getEthnicity(state),
      armedForces: this.getArmedForce(state),
      sexuality: this.getSexuality(state),
      religion: this.getReligion(state),
      ltc: this.getLTC(state),
      ltcAffectMood: state.ltcAffectsMood,
      ltcMoodImpact: this.getLTCMoodImpact(state),
      ltcManagement: this.getLTCManagement(state),
      mobilityProblems: state.hasMobilityIssues,
      consentDataShareGP: state.gpConsent,
      consentDataShare: true,
      consentDataStore: true,
      // When spine it's "odsGP" not "gp" 👇
      gpPhone: removeCountryCodeFromPhoneNumber(state.odsGP?.phone[0] ?? state.gp?.phoneNumber),
      gpCodeNACS: state.odsGP?.id ?? state.gp?.nacsCode,
      gpName: state.odsGP?.name ?? state.gp?.name,
      alcohol: state.alcohol,
      alcoholFrequency: state.alcoholFrequency,
      alcoholQuantity: state.alcoholQuantity,
      medicationWithinDosage: state.isTakingMedication,
      medication: state.isTakingMedication ? [state.medicationInfo!] : undefined,
      currentSupport: state.hasCurrentSupport,
      currentSupportDetailsOther: state.currentSupportDetails || undefined,
      diagnosisMHProfessional: state.hasFormalDiagnosis,
      diagnosisMHProfessionalDetails: state.formalDiagnosisDetails,
      output: this.referralStore.referralType,
      riskLevel: this.clinicalStore.riskLevel,
      riskLevelReason: this.clinicalStore.riskLevelReason,
      triggerWords: this.clinicalStore.triggerWords,
      treatmentExpectation: state.therapyGoal
    }
  }

  getGenderSameAsBirthChoices(state: State): string[] {
    return state.iapt?.referralForm?.sameGenderAsBirth ?? []
  }

  getDisabilities(state: State): string[] {
    return super.getDisabilities(state)
  }

  getGender(state: State): GENDER_MAYDEN_LINCS {
    const gender = genders[state.gender!]
    return gender ?? "UNKNOWN"
  }

  getGenderSameAsBirth(state: State): GenderBirthAssigned {
    return genderSameAsBirth[state.sameGenderAsBirth!]
  }

  getDisability(state: State): DISABILITY_MAYDEN_LINCS[] {
    if (!state.disabilities?.length) {
      this.logBreadcrumb("getDisability without answer", state)
      this.logMessage("getDisability without answer")
    }

    return state.disabilities
      ? state.disabilities?.map(disability => disabilities[disability]).filter(Boolean)
      : ["NONE"]
  }

  getPerinatal(state: State): PERINATAL_MAYDEN_LINCS {
    const perinatal = perinatalStatuses[state.perinatalStatus!]
    return perinatal ?? "NONE"
  }

  getEthnicity(state: State): ETHNICITY_MAYDEN_LINCS {
    const ethnicity = ethnicities[state.ethnicity!]
    return ethnicity ?? "UNKNOWN"
  }

  getArmedForce(state: State): ARMED_FORCES_MAYDEN_LINCS {
    const armedForces = exArmedForces[state.isExArmedForces!]
    return armedForces ?? "NO"
  }

  getSexuality(state: State): SEXUALITY_MAYDEN_LINCS {
    const sexuality = sexualities[state.sexuality!]
    return sexuality ?? "UNKNOWN"
  }

  getRelationshipStatus(state: State): CIVIL_STATUS_MAYDEN_LINCS {
    const relationshipStatus = maritalStatuses[state.relationshipStatus!]
    return relationshipStatus ?? "NOT_ANSWERED"
  }

  getReligion(state: State): RELIGION_MAYDEN_LINCS {
    const religion = religions[state.religion!]
    return religion ?? "NOT_ANSWERED"
  }

  getLanguage(state: State): LANGUAGE_MAYDEN_LINCS {
    const language = languages[state.primaryLanguage!]
    return language ?? "NOT_ANSWERED"
  }

  getLTC(state: State): LTC_MAYDEN_LINCS[] | undefined {
    const ltc = state.longTermMedicalCondition?.map(i => ltcs[i]).filter(Boolean)
    return ltc?.length ? ltc : undefined
  }

  getLTCMoodImpact(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      somewhat: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcMoodImpact!]
  }

  getLTCManagement(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      fairly: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcManagement!]
  }

  async onCollectPhoneNumberEnded(state: State): Promise<IStep> {
    return this.goToCollectEmail
  }

  async getCollectEmailSettings(state: State): Promise<ICollectEmailSettings> {
    return { shouldAskEmailPermission: true }
  }

  async onCollectEmailEnded(state: State): Promise<IStep> {
    return this.goToCheckPostCodeForAddressLookup
  }

  async onCollectPostcodeAndAddressEnded(state: State): Promise<IStep> {
    return this.askGPConsent
  }

  async getCollectMainIssueSettings(state: State): Promise<ICollectMainIssueSettings> {
    const name = this.getName(state)
    return {
      messages: {
        askMainIssue: this.t([
          "What's the main problem that has brought you here today? (Please try to describe your thoughts, feelings, things that trouble you, and the impact this is having on your life)"
        ]),
        closingMessage: this.t(
          [
            "Thank you for sharing {name}",
            "We'll use the information collected in this referral to try and get you the best available help"
          ],
          { name }
        )
      }
    }
  }

  async onCollectMainIssueEnded(state: State): Promise<IStep> {
    return this.finishSelfReferral
  }

  async getCollectGenderSettings(state: State): Promise<ICollectGenderSettings> {
    const genders = this.getGenders(state)
    const gendersSameAsBirth = this.getGenderSameAsBirthValues(state)
    // This is temporary - to be replaced by actual value when we have the dashboard
    return {
      messages: {
        askSameGenderAsBirth: this.t([
          "Do you identify as the same gender you were assigned at birth?"
        ])
      },
      optionsGender: genders.map(g => ({ body: this.t(g), value: g })),
      optionsGenderSameAsBirth: gendersSameAsBirth.map(g => ({ body: this.t(g), value: g }))
    }
  }

  async getCollectGenderState(state: State): Promise<CollectGenderScriptState> {
    return { skipSameGenderAsBirth: false }
  }

  async onCollectGenderEnded(state: State): Promise<IStep> {
    return this.askRelationshipStatus
  }

  async onHandlePerinatal(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.goToCollectLanguageAndInterpreter }
  }

  async getCollectLanguageAndInterpreterSettings(
    state: State
  ): Promise<ICollectLanguageAndInterpreterSettings> {
    // This is temp - to use actual value when dashboard is integrated
    return {
      options: this.getLanguages(state).map(l => ({ body: l, value: l })),
      messages: {
        askRequiresAnInterpreter: this.t(["Ok, do you require an interpreter?"])
      }
    }
  }

  async getCollectLanguageAndInterpreterState(
    state: State
  ): Promise<CollectLanguageAndInterpreterScriptState> {
    return { shouldAskAbleToCommunicateInEnglish: true, skipInterpreterLanguageQuestion: true }
  }

  async onCollectLanguageAndInterpreterEnded(state: State): Promise<IStep> {
    return this.goToCollectEthnicity
  }

  async onCollectEthnicityEnded(state: State): Promise<IStep> {
    return this.goToCollectReligion
  }

  async getCollectReligionSettings(state: State): Promise<ICollectReligionSettings> {
    const optionsReligions = this.getReligions(state).map(g => ({ body: g, value: g }))

    return {
      options: optionsReligions
    }
  }

  async onCollectReligionEnded(_state: State): Promise<IStep> {
    return this.goToCollectSexuality
  }

  async onCollectSexualityEnded(state: State): Promise<IStep> {
    return this.askMobilityIssues
  }

  async onCollectLongTermMedicalConditionEnded(state: State): Promise<IStep> {
    return this.goToCollectDisabilities
  }

  async getCollectDisabilitiesSettings(state: State): Promise<ICollectDisabilitiesSettings> {
    return {
      choicesMap: this.getDisabilities(state).map(d => ({ body: this.t(d), value: d })),
      shouldAskDisabilityStatus: false,
      multiSelectDisabilities: true
    }
  }

  async onCollectDisabilitiesEnded(state: State): Promise<IStep> {
    return this.goToCollectASD
  }

  async onCollectADHDEnded(state: State): Promise<IStep> {
    return this.goToCollectAlcoholConsumption
  }

  async onCollectAlcoholConsumptionEnded(state: State): Promise<IStep> {
    return this.askMedication
  }

  async onHandleCurrentSupport(state: State): Promise<IStepResult<State>> {
    if (state.hasCurrentSupport) {
      return { nextStep: this.askCurrentSupportDetails }
    }
    return { nextStep: this.askFormalDiagnosis }
  }

  getGenders(state: State): string[] {
    return state.iapt?.referralForm?.genders ?? []
  }

  getMaritalStatuses(state: State): string[] {
    return state.iapt?.referralForm?.maritalStatuses ?? []
  }

  getPerinatalStatuses(state: State): string[] {
    return state.iapt?.referralForm?.perinatalStatuses ?? []
  }
}

/* istanbul ignore next */
export default class SelfReferralLincolnshireDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralLincolnshire
  readonly name: string = "SelfReferralLincolnshireDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      SelfReferralLincolnshireDialogue.id,
      new SelfReferralLincolnshireScript(),
      state,
      snapshot
    )
  }
}
