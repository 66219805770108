import ChatFlowPayloadBuilder from "../ChatFlowPayloadBuilder"
import { ChatFlowsEnum, ReferralPayload } from "@limbic/types"

export default class LanguagePayloadBuilder extends ChatFlowPayloadBuilder<ChatFlowsEnum.COLLECT_LANGUAGE> {
  chatFlow = ChatFlowsEnum.COLLECT_LANGUAGE

  /** Overrides */

  getDefaultPayload(): Partial<Partial<ReferralPayload>> {
    return {
      language: this.ctx.state?.primaryLanguage,
      speakEnglish:
        this.ctx.state?.ableToCommunicateInEnglish ?? this.ctx.primaryLanguage?.match(/english/i),
      interpreter: this.ctx.state?.requiresInterpreter,
      interpreterLanguage: this.ctx.state?.interpreterLanguage
    } as Partial<ReferralPayload>
  }
}

ChatFlowPayloadBuilder.builders[ChatFlowsEnum.COLLECT_LANGUAGE] = LanguagePayloadBuilder
