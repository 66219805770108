import ChatFlowPayloadBuilder from "../ChatFlowPayloadBuilder"
import { ChatFlowsEnum, ReferralPayload } from "@limbic/types"

export default class ADHDPayloadBuilder extends ChatFlowPayloadBuilder<ChatFlowsEnum.COLLECT_ADHD> {
  chatFlow = ChatFlowsEnum.COLLECT_ADHD

  /** Overrides */

  getDefaultPayload(): Partial<Partial<ReferralPayload>> {
    return { adhd: this.ctx.state?.hasADHD } as Partial<ReferralPayload>
  }
}

// prettier-ignore
ChatFlowPayloadBuilder.builders[ChatFlowsEnum.COLLECT_ADHD] = ADHDPayloadBuilder
