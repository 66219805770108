import BaseScript, { BaseScriptState } from "../../../BaseScript"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { step } from "../../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { ISelectableExtended } from "@limbic/types"

export type ICollectNationalitySettings = {
  options?: ISelectableExtended[] | undefined
}

interface State extends BaseScriptState {
  withTextPrompt?: boolean // TODO: this is not in the dashboard
}

export type CollectNationalityScriptState = State

export class CollectNationalityScript extends BaseScript<State> {
  readonly name: string = "CollectNationalityScript"
  protected nationalities: ISelectableExtended[] | undefined
  constructor(settings: ICollectNationalitySettings | undefined = {}) {
    super()
    this.nationalities = settings?.options ?? []
  }

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askNationality }
  }

  @step.logState
  async askNationality(d: IStepData<State>): Promise<IStepResult> {
    const nationalities = this.nationalities ?? []
    if (!nationalities?.length) {
      this.logBreadcrumb("NATIONALITIES NOT FOUND", d.state, { nationalities })
      this.logMessage("NATIONALITIES NOT FOUND")
      return { nextStep: this.end }
    }

    return {
      body: this.t("What's your nationality?", this.getContext(d.state)),
      prompt: {
        id: this.getPromptId("askNationality"),
        trackResponse: true,
        type: "inlinePicker",
        choices: nationalities,
        dataPointsName: "askNationality",
        textPrompt: d.state.withTextPrompt
          ? { forceValue: true, placeholder: this.t("Other (please specify)") }
          : undefined
      },
      nextStep: this.handleNationalityWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: CollectNationalityScript) => {
    const nationalities = script.nationalities ?? []
    const isAnswerFromList = !!nationalities?.find(s => s.value === d.response)

    d.state.nationality = isAnswerFromList ? d.response : undefined
    d.state.nationalityOther = isAnswerFromList ? undefined : d.response
    script.setPeople({
      nationality: d.state.nationality,
      nationalityOther: d.state.nationalityOther
    })
  })
  @step.checkInputForCrisis({
    getNextStep: (s: CollectNationalityScript) => s.end
  })
  async handleNationalityWithCrisis(d: IStepData<State, string>): Promise<IStepResult> {
    const nationalities = this.nationalities ?? []
    const isAnswerFromList = !!nationalities?.find(s => s.value === d.response)

    if (!d.state.withTextPrompt && !isAnswerFromList) {
      return {
        body: this.t(
          "Sorry I can't recognize this nationality. Let's try again",
          this.getContext(d.state)
        ),
        nextStep: this.askNationality
      }
    }

    return { nextStep: this.end }
  }
}

export default class CollectNationalityDialogue extends AdHocDialogue<
  State,
  CollectNationalityScript
> {
  static id = DialogueIDs.CollectNationality
  readonly name: string = "CollectNationalityDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: ICollectNationalitySettings
  ) {
    super(
      CollectNationalityDialogue.id,
      new CollectNationalityScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
