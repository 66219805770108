import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { GoodbyeScriptState } from "./GoodbyeScript"
import GoodbyeScript from "./GoodbyeScript"
import { IStepData, IStepResult, StepResultBodyType } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"

interface State extends GoodbyeScriptState {
  canKeepSelfSafe?: boolean
  requiresUrgentSupport?: boolean
}

export type GoodbyeMPFTScriptState = State

export class GoodbyeMPFTScript extends GoodbyeScript {
  readonly name: string = "GoodbyeMPFTScript"

  /** Script Steps */

  @step
  async sayGoodBye(d: IStepData<State>): Promise<IStepResult> {
    void this.referralStore.updateReferral({
      isHelpful: d.state.isHelpful,
      improvementSuggestion: d.state.improvementSuggestion
    })
    this.referralStore.stopPinging()

    return {
      body: [
        "Well done for taking this important step towards better mental health",
        "You can now close this chat"
      ],
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async getIntroMessage(state: State): Promise<string | void> {
    if (state.needsToCall) {
      return
    }
    return state.requiresUrgentSupport
      ? "I'll be happy to help you with your journey after you've received this immediate support"
      : "I'm looking forward to going on this journey with you"
  }

  async getReferredYouMessage(state: State): Promise<string | void> {
    const organisationName = this.rootStore.configStore.organisationName
    const serviceName = this.rootStore.configStore.serviceName
    const name = organisationName ?? serviceName
    if (state.referralSubmitted) {
      return state.isIdleSubmitted
        ? `It looks like there hasn't been any activity for some time so I've referred you to ${name}`
        : `I've referred you to ${name}`
    }
  }

  async getAdminWillCallMessage(state: State): Promise<string | void> {
    const isRisk = this.clinicalStore.isRisk
    if (state.referralSubmitted && !isRisk) {
      // 👆not using this if the user is flagged with risk
      // because we will say it in the risk recap messages
      return `Someone from the service team will be in contact. You will be contacted by the team within 10 working days, either via telephone or email to book your appointment for an initial assessment`
    }
  }

  async getHighRiskContactMessage(_state: State): Promise<string | void> {
    const serviceName = this.rootStore.configStore.serviceName
    if (this.clinicalStore.isHighRisk) {
      return `Because you identified yourself as potentially experiencing high levels of distress, or potentially at risk, someone from our team will contact you within 5 working days, either via telephone or email, to book your appointment for an initial assessment\nPlease remember, ${serviceName} is not an emergency response service. If you are currently experiencing a Mental Health Crisis and / or need urgent help, please contact the Urgent NHS Mental Health Helpline on 0808 196 4501, NHS 111 or 999 or the Samaritans on 116 123`
    }
  }

  async getModerateRiskContactMessage(_state: State): Promise<string | void> {
    const serviceName = this.rootStore.configStore.serviceName

    if (this.clinicalStore.isModerateRisk) {
      return `Because you identified yourself as potentially experiencing high levels of distress, or potentially at risk, someone from our team will contact you within 5 working days, either via telephone or email, to book your appointment for an initial assessment\nPlease remember, ${serviceName} is not an emergency response service. If you are currently experiencing a Mental Health Crisis and / or need urgent help, please contact the Urgent NHS Mental Health Helpline on 0808 196 4501, NHS 111 or 999 or the Samaritans on 116 123`
    }
  }

  async getResourcesMessage(_state: State): Promise<string | void> {
    return "In the meantime, you can also access additional resources on our website: [here](https://twiapt.mpft.nhs.uk/resources)"
  }

  async getCustomRecapMessage(state: State): Promise<StepResultBodyType[] | undefined> {
    const isCrisis = this.clinicalStore.isCrisis
    const name = this.getName(state)
    if (state.needsToCall) return
    if (isCrisis) return [`Thank you for sharing this information with me ${name}`]
  }

  /**
   * This is overridden to skip asking the where
   * did you hear about us question
   */
  async onHandleRecapMessage(
    _state: State,
    body: StepResultBodyType[],
    recapMessage: string | void
  ): Promise<IStepResult | void> {
    if (!body.length) {
      return { nextStep: this.askFeedback }
    }

    const choices: any[] = [{ body: "Okay" }, recapMessage && { body: "I understand" }] //
      .filter(Boolean)

    return {
      body,
      prompt: {
        id: this.getPromptId("onHandleRecapMessage"),
        trackResponse: true,
        type: "inlinePicker",
        choices
      },
      nextStep: this.askFeedback
    }
  }
}

export default class GoodbyeMPFTDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeMPFT
  readonly name: string = "GoodbyeMPFTDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeMPFTDialogue.id, new GoodbyeMPFTScript(), state, snapshot)
  }
}
