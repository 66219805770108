import safeParseJSON from "../../utils/safeParseJSON"
import Trackable from "../../models/Trackable"
import invariant from "../../utils/invariant"

const SNAPSHOT_STORAGE_KEY = "@limbic-chatbot/snapshots"
const SNAPSHOTS_LIST_THRESHOLD = Number(process.env.REACT_APP_UNDO_SNAPSHOTS || 2)

export default class UndoStore extends Trackable {
  readonly name: string = "UndoStore"
  readonly storage: Storage

  constructor(storage: Storage = sessionStorage) {
    super()
    this.storage = storage
  }

  getCurrentSnapshot(): Record<string, any> | undefined {
    try {
      // get everything in the current storage besides the snapshots
      return Object.keys(this.storage)
        .filter(k => k !== SNAPSHOT_STORAGE_KEY)
        .reduce(
          (snap, key) => ({ ...snap, [key]: safeParseJSON(this.storage.getItem(key) ?? {}) }),
          {}
        )
    } catch (e) {
      console.error(e)
      this.logException(e, "getCurrentSnapshot")
    }
  }

  saveSnapshot(id: string): void {
    try {
      const snapshot = this.getCurrentSnapshot()
      if (!snapshot) return

      const snapshotsList = this.getSnapshotsList()
      const newSnapshot = { id, ...snapshot }
      snapshotsList.push(newSnapshot)

      if (snapshotsList.length > SNAPSHOTS_LIST_THRESHOLD) snapshotsList.shift()

      this.setSnapshotsList(snapshotsList)
    } catch (e) {
      this.logException(e, "saveSnapshot")
    }
  }

  loadSnapshot(id: string): void {
    const snapshotsList = this.getSnapshotsList()
    const index = snapshotsList.findIndex((s: any) => s.id === id)
    // 👇 we don't want to hydrate the snapshot id
    const { id: _, ...snapshot } = snapshotsList[index] ?? {}
    invariant(index >= 0 && snapshot, `hydrateSnapshot: snapshot with id ${id} not found`)
    this.storage.clear()
    Object.keys(snapshot).forEach(key => this.storage.setItem(key, JSONify(snapshot[key])))
    // We want the snapshot at the current index to remain because
    // we use the bot message id as the snapshot id so since the bot
    // message remains we want the snapshot to remain as well so that
    // the user can rewind to this bot message again as many times as
    // they want

    this.setSnapshotsList(snapshotsList.slice(0, index + 1))
  }

  getSnapshotsList(): Array<Record<string, any>> {
    const list = safeParseJSON(this.storage.getItem(SNAPSHOT_STORAGE_KEY) ?? "[]") ?? []
    if (!Array.isArray(list)) throw new Error("snapshotsList is not an array")
    return list
  }

  setSnapshotsList(snapshotsList: Record<string, any>[]): void {
    this.storage.setItem(SNAPSHOT_STORAGE_KEY, JSON.stringify(snapshotsList))
  }
}

function JSONify<T>(value: T): string | T {
  if (typeof value === "object" && !(value instanceof Date) && value !== null) {
    return JSON.stringify(value)
  }
  return value
}
