import { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../DialogueIDs"
import { UnderAgeSignpostScript } from "./UnderAgeSignpostDialogue"
import type { UnderAgeSignpostScriptState } from "./UnderAgeSignpostDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"

type State = UnderAgeSignpostScriptState

export type UnderAgeSignpostWellbeingHubScriptState = State

export class UnderAgeSignpostWellbeingHubScript extends UnderAgeSignpostScript {
  readonly name: string = "UnderAgeSignpostWellbeingHubScript"

  /** Script Steps */
  @step
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.sayNotEligible }
  }

  @step.logState
  sayNotEligible(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    const ageThreshold = this.rootStore.configStore.ageThreshold

    return {
      body: this.t(
        [
          "Unfortunately I can't assist with referrals to {serviceName} for people under the age of {ageThreshold}"
        ],
        { serviceName: organisationName || iaptName, ageThreshold }
      ),
      prompt: {
        id: this.getPromptId("sayNotEligible"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Okay"), value: "okay" },
          { body: this.t("Thanks"), value: "thanks" }
        ]
      },
      nextStep: this.handleNotEligible
    }
  }
}

export default class UnderAgeSignpostWellbeingHubDialogue extends AdHocDialogue<
  State,
  UnderAgeSignpostWellbeingHubScript
> {
  static id = DialogueIDs.UnderageSignpostWellbeingHub
  readonly name: string = "UnderAgeSignpostWellbeingHubDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      UnderAgeSignpostWellbeingHubDialogue.id,
      new UnderAgeSignpostWellbeingHubScript(),
      state,
      snapshot
    )
  }
}
