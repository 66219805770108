import { DialogueIDs } from "../../DialogueIDs"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { ResearchConsentScript, ResearchConsentScriptState } from "./ResearchConsentScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = ResearchConsentScriptState

export type ResearchConsentSABPScriptState = State

export class ResearchConsentSABPScript extends ResearchConsentScript {
  readonly name: string = "ResearchConsentSABPScript"

  /** Script Steps */
  @step.logState
  sayNoWorries(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t("No worries, {name}", { name }),
      nextStep: this.end
    }
  }

  @step.logState
  sayThanks(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t("Thank you {name}!", { name }),
      nextStep: this.end
    }
  }
}

/* istanbul ignore next */
export default class ResearchConsentSABPDialogue extends Dialogue<State> {
  static id = DialogueIDs.ResearchConsentSABP
  readonly name: string = "ResearchConsentSABPDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(ResearchConsentSABPDialogue.id, new ResearchConsentSABPScript(), state, snapshot)
  }
}
