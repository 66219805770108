import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import { PermissionsIAPTScript } from "./PermissionsIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { PermissionsScriptState } from "./PermissionsScript"
import { IneligibilityReason } from "@limbic/types"

interface State extends PermissionsScriptState {
  requiresUrgentSupport?: boolean
  referrer?: "self" | "self-assisted" | "3rd-party" | "3rd-party-org"
  referral3rdPartyOrgName?: string
  consentTraineePractitioner?: boolean
}
export type PermissionsLWCScriptState = State

export class PermissionsLWCScript extends PermissionsIAPTScript {
  readonly name: string = "PermissionsLWCScript"

  /** Script Steps */

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "I know that talking about mental health can be difficult",
        "Before I start finding out more about you, I'm going to run you through how we keep your personal information safe 👇"
      ],
      nextStep: this.showTOSLinks
    }
  }

  @step.logState
  askRequiresUrgentSupport(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Before we continue, we need to check if you require urgent support",
        "Are you at immediate risk of harming yourself?"
      ],
      prompt: {
        id: this.getPromptId("askRequiresUrgentSupport"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: false,
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askRequiresUrgentSupport"
      },
      nextStep: this.handleRequiresUrgentSupport
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.requiresUrgentSupport = d.response
  })
  handleRequiresUrgentSupport(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      this.setEligibility(d.state, false)
      this.clinicalStore.setRequiresUrgentSupport(true)
      this.clinicalStore.setIsCrisis(d.response)
      this.trackUserAsIneligible(d.state, IneligibilityReason.REQUIRES_URGENT_SUPPORT)
    }
    return {
      nextStep: d.response //
        ? this.sayCrisis
        : this.askWhoIsReferring
    }
  }

  @step.logState
  sayCrisis(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        `Sorry to hear that ${name}`,
        `However ${organisationName} does not provide urgent care`,
        "If you need urgent support, please dial NHS 111 and select Option 2",
        "In a medical emergency and life threatening situations only, please dial 999 or attend your local A&E department",
        "If you are under 25 and live in Birmingham, please contact the Forward Thinking Birmingham Crisis Team at 0300 300 0099",
        "If you are under 19 and live in Solihull, please contact Solar's Crisis Team at 0121 301 2750",
        "If you are over 18, please call the Birmingham Mind Urgent Mental Health Helpline at 0121 262 3555 (freephone - 0800 915 9292)",
        "Other helplines available:",
        "You can contact Samaritans 24 hours a day, 365 days a year. You can call 116 123 (free from any phone) or email jo@samaritans.org",
        "If you would prefer not to talk but want some mental health support, you could text SHOUT to 85258. Shout offers a confidential 24/7 text service providing support if you are in need of immediate help",
        `You can refer yourself back to ${organisationName} when you are no longer in crisis`
      ],
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }]
      },
      nextStep: this.handleSayCrisis
    }
  }

  @step.logState
  handleSayCrisis(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "999, NHS 111 (Option 2), SHOUT to 85258, Forward Thinking Birmingham, Solar's Crisis Team, Birmingham Mind Urgent Mental Health Helpline"
    )
    return { nextStep: this.goToGoodbye }
  }

  @step.logState
  askWhoIsReferring(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you completing this referral for yourself or for somebody else?",
      prompt: {
        id: this.getPromptId("askWhoIsReferring"),
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: "I am completing this for myself", value: "self" },
          { body: "I am completing this for myself with support", value: "self-assisted" },
          { body: "I am refering somebody else I know", value: "3rd-party" },
          {
            body: "I am referring somebody else from another organisation",
            value: "3rd-party-org"
          }
        ]
      },
      nextStep: this.handleWhoIsReferring
    }
  }

  @step.logState
  handleWhoIsReferring(
    d: IStepData<State, "self" | "self-assisted" | "3rd-party" | "3rd-party-org">
  ): IStepResult {
    d.state.referrer = d.response

    if (["3rd-party", "3rd-party-org"].includes(d.response)) {
      return {
        nextStep: this.showReferringSomeoneElseConsent
      }
    }
    return { nextStep: this.askResearchConsent }
  }

  @step.logState
  showReferringSomeoneElseConsent(_d: IStepData<State>): IStepResult {
    return {
      body: "The person you are referring must be with you while you complete this form. We cannot take referrals without the clients knowledge and consent. Is the client currently with you?",
      prompt: {
        id: this.getPromptId("showReferringSomeoneElseConsent"),
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleShowReferringSomeoneElseConsent
    }
  }

  @step.logState
  handleShowReferringSomeoneElseConsent(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      if (d.state.referrer === "3rd-party-org") {
        return { nextStep: this.ask3rdPartyOrgName }
      }
      return { nextStep: this.showReferringSomeoneElseMessage }
    }

    return { nextStep: this.sayConsentIsRequired }
  }

  @step
  sayConsentIsRequired(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "The person you are referring must be with you while you complete this referral",
        "Please come back once the person is with you"
      ],
      prompt: {
        id: this.getPromptId("sayConsentIsRequired"),
        type: "inlinePicker",
        choices: [{ body: "They're with me now", value: true }]
      },
      nextStep: this.handleShowReferringSomeoneElseConsent
    }
  }

  @step.logState
  ask3rdPartyOrgName(_d: IStepData<State>): IStepResult {
    return {
      body: "What is the name of the organisation you are referring them from?",
      prompt: {
        id: this.getPromptId("ask3rdPartyOrgName"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handle3rdPartyOrgNameWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.referral3rdPartyOrgName = d.response
  })
  @step.checkInputForCrisis({
    getNextStep: (s: PermissionsLWCScript) => s.showReferringSomeoneElseMessage
  })
  handle3rdPartyOrgNameWithCrisis(d: IStepData<State, string>): IStepResult {
    d.state.referral3rdPartyOrgName = d.response
    return { nextStep: this.showReferringSomeoneElseMessage }
  }

  @step.logState
  showReferringSomeoneElseMessage(_d: IStepData<State>): IStepResult {
    return {
      body: "Please answer the following questions on behalf of the person you are referring",
      prompt: {
        id: this.getPromptId("showReferringSomeoneElseMessage"),
        type: "inlinePicker",
        isUndoAble: true,
        choices: [{ body: "Ok" }]
      },
      nextStep: this.handleReferringSomeoneElseMessage
    }
  }

  @step.logState
  handleReferringSomeoneElseMessage(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askResearchConsent }
  }

  @step.logState
  askResearchConsent(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "I am also working with researchers to improve mental health treatment",
        "Are you ok if Limbic and Living Well Consortium use your data anonymously to support the development of the product and research, which might be used for scientific publications?",
        "Your answer will not impact the decision on whether or not we can provide you with this service"
      ],
      prompt: {
        id: this.getPromptId("askResearchConsent"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleResearchConsent
    }
  }

  @step.logState
  askTraineePractitioner(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you happy to have your sessions observed by a trainee practitioner?",
      prompt: {
        id: this.getPromptId("askTraineePractitioner"),
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleTraineePractitioner
    }
  }

  @step.logState
  handleTraineePractitioner(d: IStepData<State, boolean>): IStepResult {
    d.state.consentTraineePractitioner = d.response
    return { nextStep: this.sayReady }
  }

  @step.logState
  sayReady(_d: IStepData<State>): IStepResult {
    return {
      body: [`Thanks, let's continue`, "Ready?"],
      prompt: {
        id: this.getPromptId("sayReady"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Let's get started" }]
      },
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onHandleIUnderstandTerms(_state: State): Promise<IStepResult | void> {
    return { nextStep: this.askRequiresUrgentSupport }
  }

  async onHandleResearchConsent(_state: State): Promise<IStepResult | void> {
    return { nextStep: this.askTraineePractitioner }
  }
}

/* istanbul ignore next */
export default class PermissionsLWCDialogue extends Dialogue<State> {
  static id = DialogueIDs.PermissionsLWC
  readonly name: string = "PermissionsLWCDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PermissionsLWCDialogue.id, new PermissionsLWCScript(), state, snapshot)
  }
}
