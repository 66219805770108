import { ProblemCategories } from "@limbic/types"

export enum ReferralType {
  LIMBIC_RISK_PATIENT = "Limbic RISK patient",
  SELF_REFERRAL = "Limbic Self-Referral",
  ASSESSMENT_CALL_REQUIRED = "Limbic Full Assessment - Assessment call required",
  HIGH_RISK_PATIENT = "HIGH RISK PATIENT",
  MODERATE_RISK_PATIENT = "MODERATE RISK PATIENT",
  RISK_PATIENT = "RISK PATIENT"
}

export enum AssessmentType {
  DEPRESSION = "depression",
  ANXIETY_WORRY = "anxiety (worry group)",
  ANXIETY_PHOBIA = "anxiety (phobia group)",
  ANXIETY_GAD = "anxiety (GAD group)",
  ANXIETY_PANIC = "anxiety (panic group)"
}

export enum AssessmentCallReason {
  LTC_NOT_MANAGED_WELL = "LTC not managed well",
  DRINKING_ALCOHOL = "Drinking alcohol to manage mood",
  USING_SUBSTANCES = "Using non-prescribed medication or substances to manage mood",
  USING_SUBSTANCES_OUTSIDE_DOSE_RANGE = "Using medication or substances outside the recommended dose range",
  CURRENT_SUPPORT = "Receiving 3rd party mental health support",
  UNDETERMINED_ELIGIBILITY = "Limbic could not determine user eligibility for the service"
}

export enum RiskLevel {
  Low = "LOW",
  Moderate = "MODERATE",
  High = "HIGH"
}

export enum RiskLevelReason {
  PHQ9_Q9 = "Scored 3 on PHQ9 Q9",
  PHQ9_Q9_ABOVE_ZERO = "Scored above 0 on PHQ9 Q9",
  PHQ9_Q9_PLANS = "Has made plans to end life",
  PHQ9_Q9_PREPARATIONS = "Has made preparations to end life",
  PHQ9_Q9_WILL_U_ACT_SOMEWHAT = "Somewhat likely to act on plans to end life",
  PHQ9_Q9_WILL_U_ACT_HIGHLY = "Quite likely to act on plans to end life",
  PHQ9_Q9_RISK_FROM_SOMEONE_ELSE = "In risk from someone else",
  PHQ9_Q9_CANT_KEEP_SAFE = "User can't keep self safe",
  PHQ9_Q9_CANT_KEEP_SAFE_1 = "Scored 1 on PHQ9 Q9 and can't keep self safe",
  PHQ9_Q9_CANT_KEEP_SAFE_2_PLUS = "Scored 2+ on PHQ9 Q9 and can't keep self safe",
  PHQ9_Q9_2_PLUS_WITH_CRISIS = "Scored 2+ on PHQ9 Q9, can keep self safe but crisis was detected",
  PHQ9_Q9_2_PLUS = "Scored 2+ on PHQ9 Q9 and can keep self safe",
  CANNOT_KEEP_SELF_SAFE = "Scored 1+ on PHQ9 and cannot keep self safe",
  CRISIS_DETECTION = "Crisis was detected in user input"
}

export const KeepingSafeLeafletViaStatus = {
  SENT: "SENT",
  FAILED: "FAILED",
  NO_RECIPIENT: "NO_RECIPIENT",
  NO_EMAIL_PERMISSION: "NO_EMAIL_PERMISSION"
}

export const TrackingEvents = {
  [AssessmentType.DEPRESSION]: "Assessment - Depression",
  [AssessmentType.ANXIETY_WORRY]: "Assessment - Anxiety Worry",
  [AssessmentType.ANXIETY_PHOBIA]: "Assessment - Anxiety Phobia",
  [AssessmentType.ANXIETY_GAD]: "Assessment - Anxiety GAD",
  [AssessmentType.ANXIETY_PANIC]: "Assessment - Anxiety Panic",
  ASSESSMENT_REACHED: "Assessment Reached",
  APPOINTMENT_BOOKED: "Appointment Booked",
  APPOINTMENTS_PROVIDED: "Appointments Provided to User",
  APPOINTMENT_BOOKING_FAILED: "Appointment Reservation Failed",
  APPOINTMENT_SKIPPED: "Appointment Skipped",
  TREATMENT_DETERMINED: "Treatment Determined",
  CRISIS_DETECTED: "Crisis Detected",
  CRISIS_DETECTION_CORRECT: "Crisis Detection Was Correct",
  CRISIS_DETECTION_WRONG: "Crisis Detection Was Wrong",
  IAPT_DETERMINED: "IAPT Determined",
  EXTERNAL_IAPTS_DETERMINED: "External IAPT Suggestions Determined",
  SIGNPOST_IAPTS: "Signpost to IAPTs",
  SIGNPOST_GP: "Signpost to GP",
  SIGNPOST_WITHOUT_GP_INFO: "Signpost w/o GP info",
  SIGNPOST_MANUAL_REFERRAL: "Signpost To Manual Referral",
  USER_NEEDS_TO_CALL: "User Needs To Call In",
  USER_DOES_NOT_NEED_TO_CALL: "User Does Not Need To Call In",
  INVALID_POSTCODE: "Invalid Postcode",
  CUSTOM_POSTCODE: "Custom Postcode",
  ELIGIBILITY_DETERMINED: "Eligibility Determined",
  UNDO: "Undo",
  SELECT_GP_BY_POSTCODE: "Select GP by Postcode",
  SELECT_GP_BY_NAME: "Select GP by Name",
  SELECT_GP_BY_ELIGIBLE_LIST: "Select GP from eligible list",
  DO_U_KNOW_GP_NAME: "Do you know the name of your GP?",
  MEDICATION_PRESCRIBED_BOTH: "Taking medication (Prescribed / Not Prescribed)",
  MEDICATION_PRESCRIBED: "Taking medication (Prescribed)",
  MEDICATION_NOT_PRESCRIBED: "Taking medication (Not Prescribed)",
  NO_MEDICATION_PRESCRIBED: "Not taking medication (Prescribed)",
  NO_MEDICATION_NOT_PRESCRIBED: "Not taking medication (Not Prescribed)",
  SKIP_FEEDBACK_CRISIS: "Skipping Feedback (Crisis Detected)",
  SKIP_WHERE_DID_YOU_HEAR: "Skipping Where id you hear about us (Crisis Detected)",
  IMPROVEMENT_SUGGESTION: "Improvement Suggestion",
  CONVERSATION_END: "Conversation End",
  CHAT_BOT_LAUNCH: "Chat Bot Launch",
  SELF_REFERRAL_SUBMITTED: "Self Referral Data Submitted",
  SELF_REFERRAL_NOT_SUBMITTED: "Self Referral Data Not Submitted Successfully",
  MANAGER_INDIVIDUAL_REFERRAL_SUBMITTED: "Manager Individual Referral Data Submitted",
  MANAGER_INDIVIDUAL_REFERRAL_NOT_SUBMITTED: "Manager Individual Referral Data Not Submitted",
  MANAGER_TEAM_REFERRAL_SUBMITTED: "Manager Team Referral Data Submitted",
  MANAGER_TEAM_REFERRAL_NOT_SUBMITTED: "Manager Team Referral Data Not Submitted",
  BELOW_CASENESS_EMAIL: "Service notified user is below caseness",
  BOOK_MENTAL_HEALTH_PROFESSIONAL_SUBMITTED: "Book Mental Health Professional Submitted",
  BOOK_MENTAL_HEALTH_PROFESSIONAL_NOT_SUBMITTED:
    "Book Mental Health Professional Not Submitted Successfully",
  BOOKING_ABORTED: "User Has Aborted The Booking Process",
  PHQ9_Q9: "PHQ9 - Q9",
  INVALID_PHONE_NUMBER: "Invalid phone number",
  NO_MOBILE_PHONE: "No Mobile Phone",
  PHONE_PERMISSIONS: "Phone Permissions",
  DO_YOU_HAVE_EMAIL: "doYouHaveEmail",
  SELECT_ADDRESS: "Select Address",
  MANUAL_ADDRESS_POSTCODE_NOT_FOUND: "Postcode Not Found Enter Manual Address",
  NO_GP_FOUND: "No GP found",
  NO_ELIGIBLE_IAPT_FOUND: "No eligible IAPT found",
  NO_IAPTS_FOUND: "No IAPT found",
  NO_IAPTS_FOUND_VIA_ODS_GP: "No IAPT found via ODS GP",
  INELIGIBLE_GP_MATCHING_IAPT: "Ineligible GP with matching IAPT",
  TRY_AGAIN_POSTCODE: "Try Again Pressed",
  RE_ENTER_POSTCODE: "Something Went Wrong - Re-enter Postcode",
  ASK_RETRY_CONNECTION_GP_FROM_GP_POSTCODE: "Ask User to Retry Connection for GP from GP Postcode",
  ASK_RETRY_CONNECTION_GP_FROM_USER_POSTCODE:
    "Ask User to Retry Connection for GP from User Postcode",
  ASK_RETRY_CONNECTION_SELECT_IAPT_SERVICE_BY_GP:
    "Ask User to Retry Connection for Select IAPT Service from GP",
  ASK_RETRY_CONNECTION_VITALITY_ELIGIBILITY_CHECK:
    "Ask User to Retry Connection for Vitality Eligibility Check",
  ASK_RETRY_REQUEST_FAILED_VITALITY_ELIGIBILITY_CHECK:
    "Ask User to Retry Request Failed for Vitality Eligibility Check",
  MEMBER_ID_NOT_PROVIDED: "Member ID not Provided",
  ASK_RETRY_SOMETHING_WRONG_SELECT_GP_FROM_GP_POSTCODE:
    "Something Went Wrong Retry for Select GP from GP Postcode",
  ASK_RETRY_SOMETHING_WRONG_SELECT_GP_FROM_USER_POSTCODE:
    "Something Went Wrong Retry for Select GP from User Postcode",
  ASK_RETRY_SOMETHING_WRONG_SELECT_IAPT_SERVICE_BY_GP:
    "Something Went Wrong Retry for Select IAPT Service by GP",
  NO_ELIGIBLE_GP_KM: "No eligible GP found for K&M",
  ODS_GP_BY_NACSCODE_NOT_FOUND: "Could not find ODS GP via NACSCode",
  SPINE_SUCCESS_ON_1ST_TRY: "Successful spine search on 1st try",
  SPINE_SEARCH_RESULT: "Spine Search Result",
  MULTIPLE_ELIGIBLE_IAPTS: "Multiple Eligible IAPTs",
  RISK_EMAIL_SKIPPED: "Risk email skipped - no risk emails available",
  VITALITY_ALTERNATE_SUGGESTIONS_HELPED: "Vitality Alternative Suggestions Helped",
  VITALITY_ALTERNATE_SUGGESTIONS_DID_NOT_HELP: "Vitality Alternative Suggestions Did Not Help",
  VITALITY_CHECKIN: "Vitality Digital Triage Route",
  VITALITY_APPOINTMENT: "Vitality Appointment Route",
  GO_TO_CHANGE_GROW_LIVE: "Switch to Change Grow Live",
  ARMED_FORCES_SIGNPOST: "Ineligible Signposting - Currently Serving in Armed Forces",
  CURRENT_SUPPORT_SIGNPOST: "Ineligible Signposting - Currently Receiving Support",
  WAITLIST_SUPPORT_SIGNPOST: "Ineligible Signposting - Currently on a Waitlist for Support",
  DRUG_ALCOHOL_SIGNPOST: "Ineligible Signposting - Main Issue is Drug or Alcohol",
  LEARNING_DISABILITY_SIGNPOST:
    "Ineligible Signposting - Under Care of a Learning Disabilities Service",
  WILL_SIGNPOST_TO_INSIGHT: "Will signpost to Insight",
  SIGNPOSTED_TO_INSIGHT: "Signposted to Insight",
  INELIGIBLE: "Ineligible",
  INELIGIBLE_CURRENT_SUPPORT: "Ineligible due to currently receiving support",
  INELIGIBLE_WAITLIST_SUPPORT:
    "Ineligible due to currently being on the waitlist of another service",
  INELIGIBLE_DRUG_OR_ALCOHOL: "Ineligible due to main issue relating to drugs or alcohol",
  INELIGIBLE_LEARNING_DISABILITY: "Ineligible due to receiving learning disability support",
  INELIGIBLE_REQUIRES_URGENT_SUPPORT: "Ineligible due to requiring urgent support",
  ADSM_CONSENT: "User accepted ADSM study",
  ADSM_NO_CONSENT: "User declined ADSM study",
  ADSM_NO_CONSENT_A: "User declined ADSM study - 1st Question",
  ADSM_NO_CONSENT_B: "User declined ADSM study - 2nd Question",
  ADSM_IS_ACTIVE: "ADSM Study Activated",
  ADSM_IS_NOT_ACTIVE: "ADSM Study Deactivated",
  ADSM_DEACTIVATED_ERROR: "ADSM Study Deactivated Datapoints Failed",
  ADSM_DEACTIVATED_REQUEST_ERROR: "ADSM Study Deactivated Triage Request Failed",
  ADSM_DEACTIVATED_NON_ENGLISH_SPEAKING: "ADSM Study Deactivated Non-English speaking user",
  NO_AVAILABLE_AGENCY: "User is not eligible based on postcode",
  NO_3RD_PARTY_CONSENT: "User doesn't have consent to refer someone else",
  SERIOUS_MENTAL_ILLNESS: "Serious mental illness"
}

export const problemCategoryUserFriendlyNames: Record<ProblemCategories, string> = {
  [ProblemCategories.M_Depression]: "Low Mood",
  [ProblemCategories.MS_Depression]: "Low Mood",
  [ProblemCategories.S_Depression]: "Low Mood",
  [ProblemCategories.SocialPhobia]: "Social Phobia",
  [ProblemCategories.M_SocialPhobia]: "Social Phobia",
  [ProblemCategories.S_SocialPhobia]: "Social Phobia",
  [ProblemCategories.SpecificPhobia]: "Specific Phobia",
  [ProblemCategories.SpecificPhobiaNBI]: "Phobia related to needles, blood or injury",
  [ProblemCategories.Agoraphobia]:
    "Phobia related to going out, being in crowds or taking public transport",
  [ProblemCategories.Panic]: "Panic",
  [ProblemCategories.M_GAD]: "General Anxiety",
  [ProblemCategories.S_GAD]: "General Anxiety",
  [ProblemCategories.HealthAnxiety]: "Worrying about your health/illness",
  [ProblemCategories.S_HealthAnxiety]: "Worrying about your health/illness",
  [ProblemCategories.M_HealthAnxiety]: "Worrying about your health/illness",
  [ProblemCategories.OCD]: "Intrusive or unwanted thoughts/images",
  [ProblemCategories.PTSD]: "Distress over past traumatic experience(s)"
}

export enum JobCategory {
  NursingMidwifery = "Nursing / Midwifery",
  DrConsultant = "Doctor / Consultant",
  TherapistAssistant = "Therapist / Healthcare Assistant",
  NonClinicalSenior = "Senior Non-Clinical (e.g manager, director, lead)",
  NonClinicalOther = "Other Non-Clinical (e.g administrator, cleaner)"
}

export enum JobCategoryKM {
  NHSManagers = "NHS Managers or senior leads (clinical and non-clinical)",
  NursingMidwifery = "Nursing or midwifery staff (excluding critical care staff)",
  MedicalStaff = "Medical staff (excluding critical care staff)",
  NHSClinicalStaff = "NHS clinical staff (excluding nursing, midwifery & medical)",
  NHSNonClinicalStaff = "NHS non-clinical staff (excluding managers)",
  SocialCare = "Social care (any profession)",
  PrimaryCare = "Primary care (clinical & non-clinical staff in general practice, dentististry or optometry)",
  VoluntaryCommunitySocial = "Voluntary, community organisation or social enterprise (any profession)",
  AmbulanceTrusts = "Ambulance trusts",
  CriticalCare = "Critical Care"
}

export enum AssessmentClinicalNotes {
  BelowCaseness = "BelowCaseness",
  BC_Depression = "BC_Depression",
  BC_Anxiety = "BC_Anxiety",
  Agoraphobia = "Agoraphobia",
  SpecificPhobia = "SpecificPhobia",
  NonSpecificPhobia = "NonSpecificPhobia",
  AnxietySpeedYes = "AnxietySpeedYes",
  AnxietySpeedNo = "AnxietySpeedNo",
  AnxietyPeakYes = "AnxietyPeakYes",
  AnxietyPeakNo = "AnxietyPeakNo",
  AnxietySensationsYes = "AnxietySensationsYes",
  AnxietySensationsNo = "AnxietySensationsNo",
  WorryYes = "WorryYes",
  WorryNo = "WorryNo",
  HealthAnxiety = "HealthAnxiety",
  OCDAnxiety = "OCDAnxiety",
  PTSDAnxiety = "PTSDAnxiety"
}

export enum InsightTreatmentOptions {
  IESO = "Typed CBT (IESO)",
  WEBINAR = "Wellbeing Webinar",
  DIGITAL_THERAPY = "Digital Therapy",
  GSH = "Guided Self-Help"
}

export enum Titles {
  MR = "Mr",
  MRS = "Mrs",
  MISS = "Miss",
  MS = "Ms",
  DR = "Dr",
  REV = "Rev",
  PROF = "Prof",
  MX = "Mx"
}

export type VHGTitles = "Mr" | "Mrs" | "Miss" | "Ms" | "Mx" | "Dr" | "Rev" | "Prof"
export type VitalityTitles = "Mr" | "Ms" | "Mrs" | "Miss" | "Mx"

export type MPFTTitles =
  | "Mr"
  | "Mrs"
  | "Miss"
  | "Ms"
  | "Dr"
  | "Rev"
  | "Prof"
  | "Mx"
  | "Sir"
  | "Lady"

export type BradfordTitles = "Mr" | "Mrs" | "Miss" | "Ms" | "Mx" | "Dr" | "Rev" | "Prof"
export type MindTitles =
  | "Mr"
  | "Mrs"
  | "Miss"
  | "Ms"
  | "Dr"
  | "Rev"
  | "Prof"
  | "Mx"
  | "Sir"
  | "Lady"

export type MHMTitles = "Mr" | "Mrs" | "Miss" | "Ms" | "Dr" | "Rev" | "Prof" | "Mx" | "Sir" | "Lady"

export type PreferredPronouns =
  | "She/Her"
  | "He/Him/His"
  | "They/Them/Their"
  | "Ae/Aer"
  | "Ey/Em/Eir"
  | "Fae/Faer"
  | "Per/Pers"
  | "Ve/Ver/Vers"
  | "Xe/Xem/Xyr"
  | "Ze/Hir"

export enum VitalityRoute {
  VITALITY_CHECKIN = "VITALITY_CHECKIN",
  VITALITY_APPOINTMENT = "VITALITY_APPOINTMENT",
  NONE = "NONE"
}

export type WellbeingHubUserTypes = "individual" | "managerIndividual" | "managerTeam"

export let isIE = false
if (typeof document !== "undefined") {
  // Internet Explorer 6-11
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  isIE = /*@cc_on!@*/ false || !!document.documentMode
}

export const cutoffs = {
  PCL5: 32,
  OCI: 22,
  PDSS: 8,
  // There is no literature for the Specific Phobia questionnaire
  // Max advised cutoff to be 21
  SPECIFIC_PHOBIA: 21,
  SPIN_MODERATE: 19,
  SPIN_SEVERE: 41,
  SHAI18_MODERATE: 18,
  SHAI18_SEVERE: 28
}
