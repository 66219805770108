import GoodbyeScript, { GoodbyeScriptState } from "./GoodbyeScript"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type {
  IStepData,
  IStepResult,
  StepResultBodyType
} from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import { TrackingEvents } from "../../../models/Constants"

type State = GoodbyeScriptState

export type GoodbyeLincolnshireScriptState = State

export class GoodbyeLincolnshireScript extends GoodbyeScript {
  readonly name: string = "GoodbyeLincolnshireScript"

  /** Script Tests */

  @step
  goToAskWhereDidYouHearAboutUs(_d: IStepData<State>): IStepResult {
    const referralSubmitted = this.referralStore.referralSubmitted
    return {
      body: this.t(
        referralSubmitted
          ? "Thank you for the referral. In the meantime, please look at the website if you need more information"
          : "If you need more information, please have a look at the website"
      ),
      nextStep: this.askWhereDidYouHearAboutUs
    }
  }

  @step.logState
  askWhereDidYouHearAboutUs(d: IStepData<State>): IStepResult {
    const requiresUrgentSupport =
      this.clinicalStore.requiresUrgentSupport || d.state.requiresUrgentSupport
    if (this.clinicalStore.isCrisis || requiresUrgentSupport) {
      this.track(TrackingEvents.SKIP_WHERE_DID_YOU_HEAR)
      return { nextStep: this.sayIHopeIHelped }
    }
    const serviceName = this.rootStore.configStore.serviceName
    return {
      body: this.t("Before we close, where did you hear about {serviceName}?", { serviceName }),
      prompt: {
        id: this.getPromptId("askWhereDidYouHearAboutUs"),
        type: "text",
        cancelIsEmptySubmit: true
      },
      nextStep: this.handleWhereDidYouHearAboutUsWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: GoodbyeScript) => {
    d.state.whereDidYouHearAboutService = d.response
    void script.referralStore.updateReferral({
      whereHeard: d.response
    })
  })
  @step.checkInputForCrisis({
    getNextStep: (s: GoodbyeScript) => s.askFeedback
  })
  handleWhereDidYouHearAboutUsWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askFeedback }
  }

  /** Generic Handlers */

  async onHandleRecapMessage(
    _state: State,
    body: StepResultBodyType[],
    recapMessage: string | void
  ): Promise<IStepResult | void> {
    if (!body.length) {
      return { nextStep: this.goToAskWhereDidYouHearAboutUs }
    }

    const choices: any[] = [
      { body: this.t("Okay") },
      recapMessage && { body: this.t("I understand") }
    ] //
      .filter(Boolean)

    return {
      body,
      prompt: {
        id: this.getPromptId("recap"),
        trackResponse: true,
        type: "inlinePicker",
        choices
      },
      nextStep: this.goToAskWhereDidYouHearAboutUs
    }
  }

  async getReferredYouMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted && !this.clinicalStore.isRisk) {
      const organisationName = this.rootStore.configStore.organisationName
      const iaptName = this.getIAPTName(state) || organisationName
      return this.t(
        state.isIdleSubmitted //
          ? "It looks like there hasn’t been any activity for some time so I've referred you to {iaptName}"
          : "I've referred you to {iaptName}\n\nSomeone from the {iaptName} team will be in contact within 1-2 working days",
        { iaptName }
      )
    }
  }
}

/* istanbul ignore next */
export default class GoodbyeLincolnshireDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeLincolnshire
  readonly name: string = "GoodbyeLincolnshireDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeLincolnshireDialogue.id, new GoodbyeLincolnshireScript(), state, snapshot)
  }
}
