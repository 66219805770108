import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../DialogueIDs"
import CrisisScript, { CrisisScriptState } from "./CrisisScript"
import { step } from "../../../backend/chatbot/decorators/step"
import sendRiskEmail from "../../../backend/api/limbic/sendRiskEmail"
import type { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import moment from "moment"
import isValidPhoneNumber from "../../../utils/isValidPhoneNumber"

export const VITALITY_RISK_EMAILS =
  process.env.REACT_APP_BACKEND_ENV === "production"
    ? ["mentalhealthreports@iprsgroup.com"]
    : ["andreas+vitality@limbic.ai", "john+vitality@limbic.ai"]

type State = CrisisScriptState

export type CrisisVitalityScriptState = State

export class CrisisVitalityScript extends CrisisScript {
  readonly name: string = "CrisisVitalityScript"

  /** Script Steps */
  @step
  saySorryToHear(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t(
        [
          "Sorry to hear that {name}",
          "It is normal for people to have thoughts of this nature at times",
          "However, this is not an emergency response service",
          "There are alternative services that are better placed to support you",
          "If you need urgent, life threatening medical help please call [999](tel:999)"
        ],
        { name }
      ),
      nextStep: this.theyllCallYou
    }
  }

  @step
  async theyllCallYou(d: IStepData<State>): Promise<IStepResult> {
    if (!d.state.phoneNumber) {
      return { nextStep: this.askPhoneNumber }
    }
    try {
      const dutyEmails = VITALITY_RISK_EMAILS || []
      const text = this.createReferralEmail(d.state, true)
      await sendRiskEmail({
        to: dutyEmails,
        text
      })
    } catch (e) {
      this.logException(e, "Crisis theyllCallYou send email")
    }
    const serviceName = this.rootStore.configStore.serviceName
    return {
      body: this.t(
        "One of {serviceName}'s clinical team will contact you within the next working day to talk through these thoughts with you (This team operates Monday to Friday)",
        { serviceName }
      ),
      prompt: {
        id: this.getPromptId("theyllCallYou"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("I understand") }, { body: this.t("Okay") }]
      },
      nextStep: this.handleTheyllCallYou
    }
  }

  @step
  async sayInstructions(d: IStepData<State>): Promise<IStepResult> {
    const name = this.getName(d.state)
    const serviceName = this.rootStore.configStore.serviceName
    return {
      body: this.t(
        [
          "I really appreciate you sharing these thoughts with me, {name}",
          "I'll continue with the digital assessment, but if you do need to stop at any time, that's fine (someone from {serviceName} will be in touch regardless)"
        ],
        { name, serviceName }
      ),
      prompt: {
        id: this.getPromptId("sayInstructions"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }, { body: this.t("I understand") }]
      },
      nextStep: this.handleSayInstructions
    }
  }

  @step.logState
  async handleSayInstructions(d: IStepData<State>): Promise<IStepResult> {
    this.referralStore.setCustomField("crisisNumbersShared", "999 and The Samaritans")
    return { nextStep: this.sayClosingMessage }
  }

  @step.logState
  sayClosingMessage(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Okay..."),
      nextStep: this.end
    }
  }

  @step.logState
  async handlePhoneNumber(d: IStepData<State, string>): Promise<IStepResult> {
    const isValid = isValidPhoneNumber(d.response)
    if (!isValid) {
      return {
        body: this.t("Sorry this is not a valid phone number. Let's try again"),
        nextStep: this.askPhoneNumber
      }
    }
    d.state.phoneNumber = d.response
    try {
      const dutyEmails = VITALITY_RISK_EMAILS || []
      const text = this.createReferralEmail(d.state, true)
      await sendRiskEmail({
        to: dutyEmails,
        text
      })
    } catch (e) {
      this.logException(e, "Crisis sayInstructions send email")
    }
    const serviceName = this.rootStore.configStore.serviceName
    return {
      body: this.t(
        [
          "Thanks",
          "One of {serviceName}'s clinical team will contact you within the next working day to talk through these thoughts with you (This team operates Monday to Friday)"
        ],
        { serviceName }
      ),
      nextStep: this.sayInstructions
    }
  }

  getPersonalInfoHTML(state: State): string {
    // prettier-ignore
    return ([] as Array<string | false | undefined>)
      .concat(
        `<b>Name:</b> ${state.username}<br/>`,
        !!state.birthday && `<b>Date of Birth:</b> ${moment(state.birthday).format("DD/MM/YYYY")}<br/>`,
        `<b>Phone number:</b> ${state.phoneNumber || "N/A"}<br/>`,
      )
      .filter(Boolean)
      .join("\n")
  }

  getClinicalNotesHTML(_state: State): string {
    return ""
  }

  getQuestionnairesInfoHTML(_state: State): string {
    return ""
  }

  getAdditionalInfoHTML(_state: State): string {
    const triggerWords = this.clinicalStore.triggerWords?.length
      ? `<b>Crisis Trigger Words:</b> ${this.clinicalStore.triggerWords?.join(", ")}<br/>`
      : ""
    const triggerWordsPhrase =
      this.clinicalStore.triggerWords?.length && this.clinicalStore.triggerWordsPhrase //
        ? `<b>User Input With Trigger Words:</b> ${this.clinicalStore.triggerWordsPhrase}<br/>`
        : ""
    return `
      ${triggerWords}
      ${triggerWordsPhrase}
    `
  }
}

/* istanbul ignore next */
export default class CrisisVitalityDialogue extends AdHocDialogue<State, CrisisVitalityScript> {
  static id = DialogueIDs.CrisisVitality
  readonly name: string = "CrisisVitalityDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(CrisisVitalityDialogue.id, new CrisisVitalityScript(), state, snapshot)
  }
}
