import {
  ARMED_FORCES_MAYDEN_SABP,
  DISABILITY_MAYDEN_SABP,
  ETHNICITY_MAYDEN_SABP,
  GENDER_MAYDEN_SABP,
  LANGUAGE_MAYDEN_SABP,
  LTC_MAYDEN_SABP,
  RELIGION_MAYDEN_SABP,
  SEXUALITY_MAYDEN_SABP,
  GenderBirthAssigned
} from "@limbic/types"

export const genders: Record<string, GENDER_MAYDEN_SABP> = {
  "Male (including trans man)": "MALE",
  "Female (including trans woman)": "FEMALE",
  "Non-binary": "NON_BINARY",
  Other: "NOT_LISTED",
  "I don't want to say": "NOT_ANSWERED"
}

export const ethnicities: Record<string, ETHNICITY_MAYDEN_SABP> = {
  "White - British": "WHITE_BRITISH",
  "White - Irish": "WHITE_IRISH",
  "White - Any other White background": "WHITE_OTHER",
  "Mixed - White and Black Caribbean": "MIXED_WHITE_BLACK_CARIBBEAN",
  "Mixed - White and Black African": "MIXED_WHITE_BLACK_AFRICAN",
  "Mixed - White and Asian": "MIXED_WHITE_ASIAN",
  "Mixed - Any other mixed background": "MIXED_OTHER",
  "Asian or Asian British - Indian": "ASIAN_BRITISH_INDIAN",
  "Asian or Asian British - Pakistani": "ASIAN_BRITISH_PAKISTANI",
  "Asian or Asian British - Bangladeshi": "ASIAN_BRITISH_BANGLADESHI",
  "Asian or Asian British - Any other Asian background": "ASIAN_BRITISH_OTHER",
  "Black or Black British - Caribbean": "BLACK_BRITISH_CARIBBEAN",
  "Black or Black British - African": "BLACK_BRITISH_AFRICAN",
  "Black or Black British - Any other Black background": "BLACK_BRITISH_OTHER",
  "Other Ethnic Groups - Chinese": "CHINESE",
  "Other Ethnic Groups - Gypsy/Traveller": "GYPSY",
  "Other Ethnic Groups - Any other ethnic group": "OTHER",
  "Not Stated - Not Stated": "UNKNOWN",
  "Not known - Not known": "UNKNOWN"
}

export const religions: Record<string, RELIGION_MAYDEN_SABP> = {
  "No religious group or secular": "NONE",
  "Atheist / Agnostic": "AGNOSTIC",
  "Church of England": "CHURCH_OF_ENGLAND",
  "Other protestant": "PROTESTANT",
  "Orthodox Christian": "CHRISTIAN_ORTHODOX",
  "Roman Catholic": "ROMAN_CATHOLIC",
  "Other Christian": "CHRISTIAN",
  Muslim: "MUSLIM",
  "Shi'ite Muslim": "SHIITE_MUSLIM",
  "Sunni Muslim": "SUNNI_MUSLIM",
  Sikh: "SIKH",
  Jewish: "JEWISH",
  "Orthodox Jewish": "JEWISH_ORTHODOX",
  Buddhist: "BUDDHIST",
  Hindu: "HINDU",
  Jain: "JAIN",
  "Parsi / Zoroastrian": "ZOROASTRIAN",
  Rastafarian: "RASTAFARIAN",
  "Any other religion": "OTHER"
}

export const languages: Record<string, LANGUAGE_MAYDEN_SABP> = {
  "Akan (Ashanti)": "AKAN",
  Albanian: "ALBANIAN",
  Amharic: "AMHARIC",
  Arabic: "ARABIC",
  "Bengali & Sylheti": "BENGALI",
  "Brawa & Somali": "BRAWA",
  "British Signing Language": "BRITISH_SIGN_LANGUAGE",
  Cantonese: "CANTONESE",
  "Cantonese & Vietnamese": "CANTONESE",
  Creole: "CREOLE",
  Dutch: "DUTCH",
  English: "ENGLISH",
  Ethiopian: "ETHIOPIAN",
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  "Farsi (Persian)": undefined, // 👈 TODO: Fix this
  Finnish: "FINNISH",
  "Flemish French": "FLEMISH",
  "French Creole": "FRENCH_CREOLE",
  Gaelic: "GAELIC",
  German: "GERMAN",
  Greek: "GREEK",
  Gujarati: "GUJARATI",
  Hakka: "HAKKA",
  Hause: "HAUSA",
  Hebrew: "HEBREW",
  Hindi: "HINDI",
  "Igbo (Ibo)": "IGBO",
  Italian: "ITALIAN",
  Japanese: "JAPANESE",
  Korean: "KOREAN",
  Kurdish: "KURDISH",
  Lingala: "LINGALA",
  Luganda: "LUGANDA",
  "Makaton (Sign Language)": "MAKATON_SIGN_LANGUAGE",
  Malayalam: "MALAYALAM",
  Mandarin: "MANDARIN",
  Norwegian: "NORWEGIAN",
  "Pashto (Pushtoo)": "PASHTO",
  Patois: "PATOIS",
  Polish: "POLISH",
  Portuguese: "PORTUGUESE",
  Punjabi: "PUNJABI",
  Russian: "RUSSIAN",
  "Serbian / Croatian": "SERBIAN",
  Sinhala: "SINHALA",
  Somali: "SOMALI",
  Spanish: "SPANISH",
  Swahili: "SWAHILI",
  Swedish: "SWEDISH",
  Sylheti: "SYLHETI",
  "Tagalog (Filipino)": "TAGALOG_FILIPINO",
  Tamil: "TAMIL",
  Thai: "THAI",
  Tigrinya: "TIGRINYA",
  Turkish: "TURKISH",
  Urdu: "URDU",
  Vietnamese: "VIETNAMESE",
  Welsh: "WELSH",
  Yoruba: "YORUBA",
  Other: "NOT_LISTED"
}

export const sexualities: Record<string, SEXUALITY_MAYDEN_SABP> = {
  Heterosexual: "HETEROSEXUAL",
  "Lesbian or gay": "HOMOSEXUAL",
  Bisexual: "BISEXUAL",
  Other: "NOT_LISTED",
  "Not known": "UNKNOWN",
  "Not stated": "NOT_ANSWERED"
}

export const ltcs: Record<string, LTC_MAYDEN_SABP> = {
  Cancer: "CANCER",
  "Chronic Fatigue": "FATIGUE",
  "Chronic Obstructive Pulmonary Disease (COPD)": "COPD",
  "Chronic Pain (including Fibromyalgia)": "CHRONIC_PAIN",
  "Coronary Heart Disease": "CHD",
  Diabetes: "DIABETES",
  "Irritable Bowel Syndrome": "IBS",
  "Medically Unexplained Symptoms": "MEDICALLY_UNEXPLAINED",
  Other: "NOT_LISTED",
  "Decline to say": "NOT_ANSWERED",
  "Do not know / not sure": "UNKNOWN"
}

export const disabilities: Record<string, DISABILITY_MAYDEN_SABP> = {
  "Behaviour and Emotional": "BEHAVIOUR",
  Hearing: "HEARING",
  "Manual Dexterity": "MANUAL",
  "Learning Disability": "LEARNING",
  "Asperger's syndrome": "ASPERGER",
  Mobility: "MOTOR",
  "Perception of Physical Danger": "PERCEPTION",
  "Personal Care": "SELF_CARE",
  "Progressive Conditions and Physical Health (such as HIV, cancer, multiple sclerosis, fits, etc)":
    "PROGRESSIVE",
  Sight: "SIGHT",
  Speech: "SPEECH",
  Other: "NOT_LISTED",
  "Prefer not to say": "NOT_ANSWERED"
}

export const exArmedForces: Record<string, ARMED_FORCES_MAYDEN_SABP> = {
  "Yes - ex services": "YES_EX",
  No: "NO",
  "Dependant of a ex-serving member": "DEPENDENT",
  "Not stated (Person asked but declined to provide a response)": "NOT_ANSWERED",
  "Unknown (Person asked and does not know or isn't sure)": "UNKNOWN"
}

export const sameGenderAsBirth: Record<string, GenderBirthAssigned> = {
  Yes: "YES",
  No: "NO",
  "I don't know": "UNKNOWN",
  "I don't want to say": "NOT_ANSWERED"
}

const form = {
  form_url: "https://gateway.mayden.co.uk/referral-v2/acb41eaf-2580-4f61-b72f-77bfb1dd137e",
  filled_by: "form",
  // We are not using this in SABP Self Referral
  perinatalStatuses: [
    "None apply",
    "Pregnant",
    "Child under 1",
    "Pregnancy in the last year",
    "Pregnant and Child under 1",
    "Pregnancy in the last year and Child under 1",
    "New Father - Child born within last 12 months"
  ],
  sexualities: Object.keys(sexualities),
  genders: Object.keys(genders),
  sameGenderAsBirth: Object.keys(sameGenderAsBirth),
  ethnicities: Object.keys(ethnicities),
  religions: Object.keys(religions),
  exArmedForces: Object.keys(exArmedForces),
  disabilities: Object.keys(disabilities),
  LTCs: Object.keys(ltcs)
}

export default form
