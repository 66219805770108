import { AssessmentPitchIAPTScript } from "./AssessmentPitchIAPTDialogue"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../DialogueIDs"
import type { AssessmentPitchIAPTScriptState } from "./AssessmentPitchIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"

type State = AssessmentPitchIAPTScriptState

export type AssessmentPitchLWCState = State

export class AssessmentPitchLWCScript extends AssessmentPitchIAPTScript {
  readonly name: string = "AssessmentPitchLWCScript"

  /** Script Steps */

  @step.logState
  checkForNeedToBeCalled(_d: IStepData<State>): IStepResult {
    return { nextStep: this.sayWeCanSpeedUpReferral }
  }

  @step.logState
  sayWeCanSpeedUpReferral(d: IStepData<State>): IStepResult {
    return {
      body: [
        "I'm now going to ask you a few questions to measure symptoms of common mental health issues",
        "And I will use the results to plot your progress throughout therapy"
      ],
      prompt: {
        id: this.getPromptId("sayWeCanSpeedUpReferral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Sounds interesting 🤔", value: true },
          { body: "Okay", value: false }
        ]
      },
      nextStep: this.handleWeCanSpeedUpReferral
    }
  }

  @step.logState
  askWannaDoQuestionnaires(d: IStepData<State>): IStepResult {
    return {
      body: [
        "To do this, we need to go through a few more questions together",
        "It should take no more than 15 minutes 😊",
        "Alternatively, we can finish our conversation here, and someone from our team will be in touch the next few weeks to discuss next steps",
        "What do you think? Shall we go through the questions together now?"
      ],
      prompt: {
        id: this.getPromptId("askWannaDoQuestionnaires"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Sure!", value: "sure" },
          { body: "Okay... If it could help speed things up", value: "okay" },
          {
            body: "No thanks, I'll wait for you to call me in the next few weeks",
            value: "no"
          }
        ]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleWannaDoQuestionnaires
    }
  }

  @step.logState
  saySomeoneFromAdminWillContact(d: IStepData<State>): IStepResult {
    return {
      body: "Someone from our team will be in touch the next few weeks to discuss next steps",
      prompt: {
        id: this.getPromptId("saySomeoneFromAdminWillContact"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Looking forward to it" }, { body: "Okay" }]
      },
      nextStep: this.goToGoodbye
    }
  }

  /** Generic Handlers */

  getCustomReferralType(state: State): string | undefined {
    if (state.requiresInterpreter) return "Extended Assessment"
  }
}

/** istanbul ignore next */
export default class AssessmentPitchLWCDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentPitchLWC
  readonly name: string = "AssessmentPitchLWCDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentPitchLWCDialogue.id, new AssessmentPitchLWCScript(), state, snapshot)
  }
}
