export default function safeParseJSON(
  value?: unknown,
  reviver?: (this: any, key: string, value: any) => any
): unknown {
  try {
    return JSON.parse(value as string, reviver)
  } catch {
    return value
  }
}
