import { step } from "../../../backend/chatbot/decorators/step"
import type { IntroductionIAPTScriptState } from "./IntroductionIAPTDialogue"
import { IntroductionIAPTScript } from "./IntroductionIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"

type State = IntroductionIAPTScriptState

export class IntroductionBuckinghamshireScript extends IntroductionIAPTScript {
  readonly name: string = "IntroductionBuckinghamshireScript"

  @step.logState
  greet(_d: IStepData<State>): IStepResult {
    const { directReferral } = this.rootStore.configStore
    const organisationName = this.rootStore.configStore.organisationName
    const formLink = this.rootStore.configStore.formLink
    return {
      body: this.t(
        [
          "Hi there!",
          "I’m Limbic",
          "I'm a friendly robot assistant to help you access psychological support from {organisationName}",
          "If you prefer just to fill in our e-referral form yourself click [here]({formLink})",
          "Please note your referral information will be sent at the end of the self referral questions"
        ],
        { organisationName, formLink }
      ),
      nextStep: directReferral ? this.sayStaticReferralURL : this.askCanIHelp
    }
  }

  @step.logState
  handleCanIHelp(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return {
        body: this.t("Please note this self referral will take around 10-15 minutes to complete"),
        nextStep: this.end
      }
    }
    return { nextStep: this.sayIfYouChangeYourMindWakeMeUp }
  }

  /* Script Steps */
}

/* istanbul ignore next */
export default class IntroductionBuckinghamshireDialogue extends Dialogue<State> {
  static id = DialogueIDs.IntroductionBuckinghamshire
  readonly name: string = "IntroductionBuckinghamshireDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      IntroductionBuckinghamshireDialogue.id,
      new IntroductionBuckinghamshireScript(),
      state,
      snapshot
    )
  }
}
