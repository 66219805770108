import { IClinicalPath } from "@limbic/types"
import { IClinicalPathOverride } from "../../../models/IClinicalPath"

// prettier-ignore
export const clinicalPathsMHM: Record<IClinicalPath["id"], IClinicalPathOverride> = {
  complex_comorbid: { defaultReferralType: "Full Assessment (Complex Comorbid)" },
  undetermined: { defaultReferralType: "Full Assessment (Undetermined)" },
  "1": { defaultReferralType: "Shortened - Accelerated Assessments (Below Caseness)" },
  "2": { defaultReferralType: "Shortened - Accelerated Assessments (Below Caseness)" },
  "3": { defaultReferralType: "Shortened - Accelerated Assessments (Below Caseness)" },
  "4": { defaultReferralType: "Shortened - Accelerated Assessments (Moderate Depression)" },
  "5": { defaultReferralType: "Shortened - Accelerated Assessments (Moderate Depression, Moderately Severe Functional Impairment)" },
  "6": { defaultReferralType: "Shortened - Accelerated Assessments (Moderate Depression, Severe Functional Impairment)" },
  "7": { defaultReferralType: "Shortened - Accelerated Assessments (Moderately Severe Depression)" },
  "8": { defaultReferralType: "Shortened - Accelerated Assessments (Moderately Severe Depression, Moderately Severe Functional Impairment)" },
  "9": { defaultReferralType: "Shortened - Accelerated Assessments (Moderately Severe Depression, Severe Functional Impairment)" },
  "10": { defaultReferralType: "Shortened - Accelerated Assessments (Severe Depression)" },
  "11": { defaultReferralType: "Shortened - Accelerated Assessments (Severe Depression, Moderately Severe Functional Impairment)" },
  "12": { defaultReferralType: "Full Assessment (Severe Depression, Severe Functional Impairment)" },
  "13": { defaultReferralType: "Shortened - Accelerated Assessments (Social Phobia)" },
  "14": { defaultReferralType: "Shortened - Accelerated Assessments (Social Phobia, Moderately Severe Functional Impairment)" },
  "15": { defaultReferralType: "Full Assessment (Social Phobia, Severe Functional Impairment)" },
  "16": { defaultReferralType: "Shortened - Accelerated Assessments (Moderate Social Phobia)" },
  "17": { defaultReferralType: "Shortened - Accelerated Assessments (Moderate Social Phobia, Moderately Severe Functional Impairment)" },
  "18": { defaultReferralType: "Full Assessment (Moderate Social Phobia, Severe Functional Impairment)" },
  "19": { defaultReferralType: "Shortened - Accelerated Assessments (Severe Social Phobia)" },
  "20": { defaultReferralType: "Shortened - Accelerated Assessments (Severe Social Phobia, Moderately Severe Functional Impairment)" },
  "21": { defaultReferralType: "Full Assessment (Severe Social Phobia, Severe Functional Impairment)" },
  "22": { defaultReferralType: "Shortened - Accelerated Assessments (Specific Phobia)" },
  "23": { defaultReferralType: "Shortened - Accelerated Assessments (Specific Phobia, Moderately Severe Functional Impairment)" },
  "24": { defaultReferralType: "Shortened - Accelerated Assessments (Specific Phobia, Severe Functional Impairment)" },
  "25": { defaultReferralType: "Shortened - Accelerated Assessments (Needle/Blood/Injury Phobia)" },
  "26": { defaultReferralType: "Shortened - Accelerated Assessments (Needle/Blood/Injury Phobia, Moderately Severe Functional Impairment)" },
  "27": { defaultReferralType: "Shortened - Accelerated Assessments (Needle/Blood/Injury Phobia, Severe Functional Impairment)" },
  "28": { defaultReferralType: "Shortened - Accelerated Assessments (Panic)" },
  "29": { defaultReferralType: "Shortened - Accelerated Assessments (Panic, Moderately Severe Functional Impairment)" },
  "30": { defaultReferralType: "Full Assessment (Severe Panic, Severe Functional Impairment)" },
  "31": { defaultReferralType: "Shortened - Accelerated Assessments (Moderate GAD)" },
  "32": { defaultReferralType: "Shortened - Accelerated Assessments (Moderate GAD, Moderately Severe Functional Impairment)" },
  "33": { defaultReferralType: "Full Assessment (Moderate GAD, Severe Functional Impairment)" },
  "34": { defaultReferralType: "Shortened - Accelerated Assessments (Severe GAD)" },
  "35": { defaultReferralType: "Shortened - Accelerated Assessments (Severe GAD, Moderately Severe Functional Impairment)" },
  "36": { defaultReferralType: "Full Assessment (Severe GAD, Severe Functional Impairment)" },
  "37": { defaultReferralType: "Shortened - Accelerated Assessments (Health Anxiety)" },
  "38": { defaultReferralType: "Shortened - Accelerated Assessments (Health Anxiety, Moderately Severe Functional Impairment)" },
  "39": { defaultReferralType: "Full Assessment (Health Anxiety, Severe Functional Impairment)" },
  "40": { defaultReferralType: "Shortened - Accelerated Assessments (Moderate Health Anxiety)" },
  "41": { defaultReferralType: "Shortened - Accelerated Assessments (Moderate Health Anxiety, Moderately Severe Functional Impairment)" },
  "42": { defaultReferralType: "Full Assessment (Moderate Health Anxiety, Severe Functional Impairment)" },
  "43": { defaultReferralType: "Shortened - Accelerated Assessments (Severe Health Anxiety)" },
  "44": { defaultReferralType: "Shortened - Accelerated Assessments (Severe Health Anxiety, Moderately Severe Functional Impairment)" },
  "45": { defaultReferralType: "Full Assessment (Severe Health Anxiety, Severe Functional Impairment)" },
  "46": { defaultReferralType: "Shortened - Accelerated Assessments (OCD)" },
  "47": { defaultReferralType: "Shortened - Accelerated Assessments (OCD, Moderately Severe Functional Impairment)" },
  "48": { defaultReferralType: "Full Assessment (OCD, Severe Functional Impairment)" },
  "49": { defaultReferralType: "Full Assessment (PTSD)" },
  "50": { defaultReferralType: "Full Assessment (PTSD, Moderately Severe Functional Impairment)" },
  "51": { defaultReferralType: "Full Assessment (PTSD, Severe Functional Impairment)" }
}
