import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../DialogueIDs"
import { SuitableServicesSignpostIAPTScript } from "./SuitableServicesSignpostIAPTDialogue"
import type { SuitableServicesSignpostIAPTScriptState } from "./SuitableServicesSignpostIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = SuitableServicesSignpostIAPTScriptState
export type SuitableServicesSignpostBuckinghamshireScriptState = State

export class SuitableServicesSignpostBuckinghamshireScript extends SuitableServicesSignpostIAPTScript {
  readonly name: string = "SuitableServicesSignpostBuckinghamshireScript"

  /** Script Steps */

  @step.logState
  sayOrganisationPhoneNumbers(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const organisationPhoneNumbers = this.rootStore.configStore.organisationPhoneNumbers
    return {
      body: this.t(
        [
          "If you still think that {organisationName} is right for you please use our e-referral form [here](https://www.iaptportal.co.uk/selfreferral.aspx?ServiceCode=BUCKS&ReferralType=0), or please call one of our services:",
          organisationPhoneNumbers,
          "Our staff are always happy to talk options through with you"
        ],
        { organisationName }
      ),
      nextStep: this.promptThanksOK
    }
  }
}

/* istanbul ignore next */
export default class SuitableServicesSignpostBuckinghamshireDialogue extends Dialogue<State> {
  static id = DialogueIDs.SuitableServicesSignpostBuckinghamshire
  readonly name: string = "SuitableServicesSignpostBuckinghamshireDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      SuitableServicesSignpostBuckinghamshireDialogue.id,
      new SuitableServicesSignpostBuckinghamshireScript(),
      state,
      snapshot
    )
  }
}
