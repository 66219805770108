import ChatFlowPayloadBuilder from "../ChatFlowPayloadBuilder"
import { ChatFlowsEnum, ReferralPayload } from "@limbic/types"

export default class ReligionPayloadBuilder extends ChatFlowPayloadBuilder<ChatFlowsEnum.COLLECT_RELIGION> {
  chatFlow = ChatFlowsEnum.COLLECT_RELIGION

  /** Overrides */

  getDefaultPayload(): Partial<Partial<ReferralPayload>> {
    return { religion: this.ctx.state?.religion } as Partial<ReferralPayload>
  }
}

// prettier-ignore
ChatFlowPayloadBuilder.builders[ChatFlowsEnum.COLLECT_RELIGION] = ReligionPayloadBuilder
