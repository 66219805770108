import { DialogueIDs } from "../../DialogueIDs"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { ResearchConsentScript, ResearchConsentScriptState } from "./ResearchConsentScript"

type State = ResearchConsentScriptState

export default class ResearchConsentDialogue extends Dialogue<State> {
  static id = DialogueIDs.ResearchConsent
  readonly name: string = "ResearchConsentDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(ResearchConsentDialogue.id, new ResearchConsentScript(), state, snapshot)
  }
}
