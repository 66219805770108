import { BooleanOperators, ListOperators, NumberOperators, TextOperators } from "@limbic/types"

type OperatorFn = (a: unknown, b: unknown) => boolean

// this is used to combine the keys of all operator enums
// to be able to use them as keys in the operatorsMap
// and be notified if a new operator is added in the types
// but not added in the operatorsMap
const allOperators = [
  ...Object.values(TextOperators),
  ...Object.values(NumberOperators),
  ...Object.values(BooleanOperators),
  ...Object.values(ListOperators)
]

export const operatorsMap: Record<(typeof allOperators)[number], OperatorFn> = {
  [TextOperators.EQUALS]: isEqual,
  [TextOperators.NOT_EQUALS]: notIsEqual,
  [TextOperators.CONTAINS]: includes,
  [TextOperators.NOT_CONTAINS]: notIncludes,
  [TextOperators.STARTS_WITH]: startsWith,
  [TextOperators.ENDS_WITH]: endsWith,
  [TextOperators.HAS_LENGTH]: hasLength,
  [NumberOperators.EQUALS]: isEqual,
  [NumberOperators.NOT_EQUALS]: notIsEqual,
  [NumberOperators.GREATER_THAN]: greaterThan,
  [NumberOperators.GREATER_THAN_OR_EQUAL]: greaterThanOrEqual,
  [NumberOperators.LESS_THAN]: lessThan,
  [NumberOperators.LESS_THAN_OR_EQUAL]: lessThanOrEqual,
  [BooleanOperators.IS_TRUE]: isTrue,
  [BooleanOperators.IS_FALSE]: isFalse,
  [ListOperators.INCLUDES]: includes,
  [ListOperators.NOT_INCLUDES]: notIncludes
}

function isEqual(a: unknown, b: unknown): boolean {
  return a === b
}

function notIsEqual(a: unknown, b: unknown): boolean {
  return !isEqual(a, b)
}

function includes(a: unknown, b: unknown): boolean {
  if (Array.isArray(a)) return a.includes(b)

  if (typeof b === "string") {
    const hasRegex = b.split("").filter(char => char === "/").length === 2
    if (hasRegex) return new RegExp(b).test(a as string)
    return (a as string).includes(b)
  }
  return false
}

function notIncludes(a: unknown, b: unknown): boolean {
  return !includes(a, b)
}

function startsWith(a: unknown, b: unknown): boolean {
  if (typeof a === "string") return new RegExp(`^${b}`).test(a)
  return false
}

function endsWith(a: unknown, b: unknown): boolean {
  if (typeof a === "string") return new RegExp(`${b}$`).test(a)
  return false
}

function hasLength(a: unknown, b: unknown): boolean {
  if (Array.isArray(a)) return a.length === Number(b)
  if (typeof a === "string") return a.length === Number(b)
  return false
}

function greaterThan(a: unknown, b: unknown): boolean {
  if (typeof a === "string" && typeof b === "string") return a > b
  if (typeof a === "number" && typeof b === "number") return a > b
  return (a as any) > (b as any)
}

function greaterThanOrEqual(a: unknown, b: unknown): boolean {
  if (typeof a === "string" && typeof b === "string") return a >= b
  if (typeof a === "number" && typeof b === "number") return a >= b
  return (a as any) >= (b as any)
}

function lessThan(a: unknown, b: unknown): boolean {
  if (typeof a === "string" && typeof b === "string") return a < b
  if (typeof a === "number" && typeof b === "number") return a < b
  return (a as any) < (b as any)
}

function lessThanOrEqual(a: unknown, b: unknown): boolean {
  if (typeof a === "string" && typeof b === "string") return a <= b
  if (typeof a === "number" && typeof b === "number") return a <= b
  return (a as any) <= (b as any)
}

function isTrue(a: unknown): boolean {
  return a === true
}

function isFalse(a: unknown): boolean {
  return a === false
}
