import { IAPTGPMap, IAPTIDs } from "../../models/IIAPTService"

export const iaptGPMapBexley: IAPTGPMap = {
  G83004: IAPTIDs.BEXLEY,
  G83029: IAPTIDs.BEXLEY,
  G83047: IAPTIDs.BEXLEY,
  G83057: IAPTIDs.BEXLEY,
  G83061: IAPTIDs.BEXLEY,
  G83064: IAPTIDs.BEXLEY,
  G83066: IAPTIDs.BEXLEY,
  G83605: IAPTIDs.BEXLEY,
  G83005: IAPTIDs.BEXLEY,
  G83010: IAPTIDs.BEXLEY,
  G83018: IAPTIDs.BEXLEY,
  G83037: IAPTIDs.BEXLEY,
  G83043: IAPTIDs.BEXLEY,
  G83049: IAPTIDs.BEXLEY,
  G83052: IAPTIDs.BEXLEY,
  G83053: IAPTIDs.BEXLEY,
  G83062: IAPTIDs.BEXLEY,
  G83623: IAPTIDs.BEXLEY,
  G83630: IAPTIDs.BEXLEY,
  G83632: IAPTIDs.BEXLEY,
  G83642: IAPTIDs.BEXLEY,
  G83643: IAPTIDs.BEXLEY,
  G83672: IAPTIDs.BEXLEY,
  G83028: IAPTIDs.BEXLEY,
  G83048: IAPTIDs.BEXLEY,
  G83002: IAPTIDs.BEXLEY,
  G83006: IAPTIDs.BEXLEY,
  G83009: IAPTIDs.BEXLEY,
  G83024: IAPTIDs.BEXLEY,
  G83025: IAPTIDs.BEXLEY,
  G83033: IAPTIDs.BEXLEY,
  G83046: IAPTIDs.BEXLEY
}
