import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { KeepingSafeLeafletViaStatus, RiskLevel, RiskLevelReason } from "../../../models/Constants"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { IDefaultChatFlowMessagesRiskPathway } from "@limbic/types"
import sendEmail, { EmailProps } from "../../../backend/api/limbic/sendEmail"
import { SendEmailStatus } from "../../../models/ISendEmail"
import invariant from "../../../../src/utils/invariant"
import { IPersistableSurveyResponse } from "../../../models/ISurvey"
import sendKeepingSafeEmail from "../../../backend/api/limbic/sendKeepingSafeEmail"
import Logger from "../../../utils/Logger"

interface ICanKeepSelfSafeResponse {
  id: string
  title: string
  responseKey: keyof State
}

const CAN_KEEP_SELF_SAFE_RESPONSE_VALUES: ICanKeepSelfSafeResponse = {
  id: "10",
  title:
    "Are you able to keep yourself, and any dependants in your care, safe until your appointment?",
  responseKey: "riskPathwayResponses"
}

const isRiskEmailEnabledByEnvVariable = process.env.REACT_APP_RISK_EMAIL === "enabled"
const shouldSendKeepingSafeByEnvVariable =
  process.env.REACT_APP_SHOULD_SEND_KEEPING_SAFE === "enabled"
const botConfigVersion = process.env.REACT_APP_BOT_VERSION ?? "draft"
invariant(
  ["published", "draft"].includes(botConfigVersion),
  "REACT_APP_BOT_VERSION must be 'draft' or 'published'"
)

export type IRiskPathwaySettings = {
  crisisNumbersShared?: string
  shouldSendRiskEmail?: boolean
  messages?: IDefaultChatFlowMessagesRiskPathway
}

type State = RiskPathwayScriptState

export type RiskPathwayDynamicScriptState = State

export class RiskPathwayDynamicScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayDynamicScript"
  protected crisisNumbersShared: string | undefined
  protected shouldSendRiskEmail: boolean | undefined
  protected messages: IDefaultChatFlowMessagesRiskPathway | undefined
  constructor(settings: IRiskPathwaySettings | undefined = {}) {
    super()
    this.crisisNumbersShared = settings.crisisNumbersShared
    this.shouldSendRiskEmail = settings.shouldSendRiskEmail
    this.messages = settings?.messages ?? {}
  }

  /** Script Steps */

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askCanYouKeepYourselfSafe }
  }

  @step.logState
  askCanYouKeepYourselfSafe(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messages?.askCanYouKeepYourselfSafe ??
          "Are you able to keep yourself, and any dependants in your care, safe until your appointment?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askCanYouKeepYourselfSafe"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askCanYouKeepYourselfSafe"
      },
      nextStep: this.handleCanYouKeepYourselfSafe
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.saveResponse<State>(
    CAN_KEEP_SELF_SAFE_RESPONSE_VALUES.id,
    CAN_KEEP_SELF_SAFE_RESPONSE_VALUES.title,
    CAN_KEEP_SELF_SAFE_RESPONSE_VALUES.responseKey,
    (r: boolean) => (r ? "Yes" : "No")
  )
  @step.handleResponse((d: IStepData<State, boolean>, script: RiskPathwayDynamicScript) => {
    d.state.canKeepSelfSafe = d.response
    script.referralStore.setCustomField<State>("canKeepSelfSafe", d.response)
  })
  async handleCanYouKeepYourselfSafe(d: IStepData<State>): Promise<IStepResult> {
    if (!d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.CANNOT_KEEP_SELF_SAFE)
    }

    if (this.clinicalStore.isRisk) {
      const riskEmails = d.state.iapt?.riskEmails
      const riskEmailSent = d.state.riskEmailSent
      const isRiskEmailEnabled = this.shouldSendRiskEmail ?? isRiskEmailEnabledByEnvVariable

      if (isRiskEmailEnabled && !riskEmails?.length) {
        this.logException(
          new Error("riskEmail"),
          "risk email is enabled but no email addresses provided"
        )
      }

      const shouldSendEmail = isRiskEmailEnabled && riskEmails?.length && !riskEmailSent
      if (shouldSendEmail) {
        const riskEmailsCC = d.state.iapt?.riskEmailsCC
        const riskEmailsBCC = d.state.iapt?.riskEmailsBCC
        const organisationName = this.rootStore.configStore.organisationName
        const email: EmailProps = {
          from: "noreply@limbic.ai",
          to: riskEmails,
          cc: riskEmailsCC,
          bcc: riskEmailsBCC,
          text: this.createRiskEmail(
            d.state,
            this.clinicalStore.isCrisis,
            this.clinicalStore.riskLevelReason ?? "N/A"
          ),
          subject: "Urgent: LIMBIC RISK"
        }
        if (!this.clinicalStore.riskLevelReason && this.clinicalStore.riskLevel !== RiskLevel.Low) {
          Logger.getInstance().exception(new Error("riskLevelReason"), "no riskLevelReason")
        }
        const result = await sendEmail(email, organisationName)
        if (result === SendEmailStatus.Success) d.state.riskEmailSent = true
      }
    }
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  sayCrisis(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messages?.sayCrisisRiskPathway ?? [
          "Sorry to hear that {name}",
          "It is normal for people to have thoughts of this nature at times",
          "However, this is not an emergency response service",
          "I'll make sure to notify {serviceName} of this in the referral"
        ],
        this.getContext(d.state)
      ),
      nextStep: this.sayCrisisNumbers
    }
  }

  @step.logState
  sayCrisisNumbers(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messages?.sayCrisisNumbersRiskPathway ?? [
          "To get more appropriate help, you can call NHS 111 and selection Option 2",
          "If you need urgent, life threatening medical help please call 999",
          "Alternatively, you can also call Samaritans on 116 123"
        ],
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("sayCrisisNumbers"),
        type: "inlinePicker",
        choices: [{ body: this.t("I understand") }, { body: this.t("Okay") }]
      },
      nextStep: this.handleCrisisNumbers
    }
  }

  @step.logState
  handleCrisisNumbers(d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField(
      "crisisNumbersShared",
      this.crisisNumbersShared ?? "999, NHS 111 and Samaritans (116 123)"
    )
    return {
      body: this.t(
        this.messages?.sayLetsCarryOn ?? "Ok, let's carry on with the mental health check in",
        this.getContext(d.state)
      ),
      nextStep: this.closeDialogue
    }
  }

  /** Generic Handlers */

  async onSaveRiskLevelAndReferralType(state: State): Promise<IStepResult> {
    return { nextStep: !state.canKeepSelfSafe ? this.sayCrisis : this.end }
  }

  async sendKeepingSafeEmail(state: State): Promise<void> {
    try {
      const shouldSendKeepingSafe =
        this.rootStore.configStore.shouldSendKeepingSafeEmail ?? shouldSendKeepingSafeByEnvVariable
      const hasPermissionToSendEmail = state.canSendEmail ?? true

      if (shouldSendKeepingSafe && !state.email) {
        state.keepingSafeSent = false
        await this.referralStore.updateReferral({
          keepingSafeLeafletStatus: KeepingSafeLeafletViaStatus.NO_RECIPIENT
        })
      }

      if (shouldSendKeepingSafe && state.email && hasPermissionToSendEmail) {
        const API_KEY = this.rootStore.configStore.key
        const serviceApiKey = this.rootStore.configStore.dashboardServiceKey
        invariant(serviceApiKey, `Could not find serviceApiKey for ${API_KEY}`)

        /**
         * Some context for the following line:
         * The `attachmentsOverride` is a prop that is passed to the `sendKeepingSafeEmail` function.
         * This prop is used to override the default attachments that are sent with the email
         * (instead of using the standard attachmentURL saved in the keepingSafeEmail in the botConfig).
         * At this moment, the attachment overrides can be set in the dashboard at the Eligibility section
         * for a specific IAPT.
         */
        const attachmentsOverride = state.iapt?.keepingSafeLeaflet?.attachmentUrls

        const response: SendEmailStatus = await sendKeepingSafeEmail({
          email: state.email,
          serviceApiKey: serviceApiKey ?? "",
          version: botConfigVersion as "draft" | "published",
          attachmentsOverride
        })
        const hasKeepingSafeBeenSent = response === SendEmailStatus.Success
        state.keepingSafeSent = hasKeepingSafeBeenSent
        const keepingSafeLeafletStatus = hasKeepingSafeBeenSent
          ? KeepingSafeLeafletViaStatus.SENT
          : KeepingSafeLeafletViaStatus.FAILED
        await this.referralStore.updateReferral({
          keepingSafeLeafletVia: "EMAIL",
          keepingSafeLeafletStatus
        })
      }
      if (shouldSendKeepingSafe && state.email && !hasPermissionToSendEmail) {
        state.keepingSafeSent = false
        await this.referralStore.updateReferral({
          keepingSafeLeafletStatus: KeepingSafeLeafletViaStatus.NO_EMAIL_PERMISSION
        })
      }
    } catch (e) {
      state.keepingSafeSent = false
      await this.referralStore.updateReferral({
        keepingSafeLeafletStatus: KeepingSafeLeafletViaStatus.FAILED
      })
      this.logException(e, "sendKeepingSafe")
    }
  }

  getContext(state: State): Record<string, any> {
    return {
      ...this.rootStore.configStore,
      name: this.getName(state),
      iaptName: this.getIAPTName(state)
    }
  }

  saveResponse<T extends IPersistableSurveyResponse>(item: T, state: State): void {
    const newItem = { ...item }
    /**
     * With the item.id check we ensure that the correct question is being modified
     * (in case we add more questions in the future)
     */
    if (
      item.id === CAN_KEEP_SELF_SAFE_RESPONSE_VALUES?.id &&
      this.messages?.askCanYouKeepYourselfSafe
    ) {
      /**
       * 👇 Adding only first item (in case the question has multiple bot bubbles) to keep it short
       * and to avoid an empty title, setting a fallback title (the default one)
       */
      const title =
        this.messages?.askCanYouKeepYourselfSafe[0] || CAN_KEEP_SELF_SAFE_RESPONSE_VALUES.title
      newItem.title = title as string
    }
    super.saveResponse(newItem, state, CAN_KEEP_SELF_SAFE_RESPONSE_VALUES.responseKey)
  }
}

export default class RiskPathwayDynamicDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayDynamic
  readonly name: string = "RiskPathwayDynamicDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>, settings?: IRiskPathwaySettings) {
    super(
      RiskPathwayDynamicDialogue.id,
      new RiskPathwayDynamicScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
