import BaseScript, { BaseScriptState } from "../../../BaseScript"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { step } from "../../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { IDefaultChatFlowMessagesCollectNHSNumber } from "@limbic/types"

export type ICollectNHSNumberSettings = {
  options?: string[] | undefined
  messages?: IDefaultChatFlowMessagesCollectNHSNumber
}

type State = BaseScriptState

export type CollectNHSNumberScriptState = State

export class CollectNHSNumberScript extends BaseScript<State> {
  readonly name: string = "CollectNHSNumberScript"
  protected messages: IDefaultChatFlowMessagesCollectNHSNumber | undefined

  constructor(settings: ICollectNHSNumberSettings | undefined = {}) {
    super()
    this.messages = settings?.messages ?? {}
  }

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    return { nextStep: d.state.nhsNumber ? this.end : this.askNHSNumber }
  }

  @step.logState
  askNHSNumber(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messages?.askNHSNumber ?? "What is your NHS number?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askNHSNumber"),
        trackResponse: false,
        type: "inlinePicker",
        choices: [{ body: this.t("I don't know"), value: null }],
        textPrompt: {
          trimAllSpacesOnSubmit: true,
          trimAllSpacesOnValidation: true,
          validation: [/^\d{10}$/],
          validationExplainer: this.t([
            "This is not a valid NHS number",
            "It needs to be a number with 10 digits"
          ]),
          forceValue: true
        }
      },
      nextStep: this.handleNHSNumber
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string | null>) => {
    // This 👇 is important to make sure we don't add empty strings
    d.state.nhsNumber = d.response ? d.response : undefined
  })
  handleNHSNumber(_d: IStepData<State, string | null>): IStepResult {
    return { nextStep: this.end }
  }
}

export default class CollectNHSNumberDialogue extends AdHocDialogue<State, CollectNHSNumberScript> {
  static id = DialogueIDs.CollectNHSNumber
  readonly name: string = "CollectNHSNumberDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: ICollectNHSNumberSettings
  ) {
    super(
      CollectNHSNumberDialogue.id,
      new CollectNHSNumberScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
