import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../DialogueIDs"
import CrisisScript, { CrisisScriptState } from "./CrisisScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = CrisisScriptState
export type CrisisMindScriptState = State

export class CrisisMindScript extends CrisisScript {
  readonly name: string = "CrisisMindScript"

  /** Script Steps */

  @step
  saySorryToHear(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const name = this.getName(d.state)
    return {
      body: this.t(
        [
          "Sorry to hear that {name}",
          "Mental health emergencies are serious. You're not wasting anyone's time",
          "It is normal for people to have thoughts of this nature at times",
          "However {organisationName} is not an emergency response service",
          "I'll make sure to notify {organisationName} of this in the referral"
        ],
        { name, organisationName }
      ),
      prompt: {
        id: this.getPromptId("saySorryToHear"),
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }]
      },
      nextStep: this.sayCrisisNumbers
    }
  }

  @step.logState
  sayCrisisNumbers(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "If you feel like you might attempt suicide, or may have seriously harmed yourself, you need urgent medical help, please call 999 for an ambulance",
        "If you can keep yourself safe for a short while, but you still need urgent advice please call NHS 111",
        "If you need to talk right now, you can call Samaritans on 116 123",
        'If you are in Wales you can also use the C.A.L.L Mental Health Helpline which is free and available 24/7 on 0800 132 737 or text "help" to 81066'
      ]),
      prompt: {
        id: this.getPromptId("sayCrisisNumbers"),
        type: "inlinePicker",
        choices: [{ body: this.t("I understand") }, { body: this.t("Okay") }]
      },
      nextStep: this.handleCrisisNumbers
    }
  }

  @step.logState
  handleCrisisNumbers(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "999, NHS 111 (Option 2), Samaritans (116 123), C.A.L.L (0800 132 737), SMS to 81066"
    )
    return {
      body: this.t("Ok, let's carry on with the mental health check in"),
      nextStep: this.end
    }
  }

  @step.logState
  saySorryForTheMisunderstanding(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Okay",
        "My creators have taught me to listen carefully for specific words or phrases",
        "In case you need help",
        "Sorry for the misunderstanding",
        "Ok, let's carry on with the mental health check in"
      ]),
      nextStep: this.end
    }
  }
}

/* istanbul ignore next */
export default class CrisisMindDialogue extends AdHocDialogue<State, CrisisMindScript> {
  static id = DialogueIDs.CrisisMind
  readonly name: string = "CrisisMindDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(CrisisMindDialogue.id, new CrisisMindScript(), state, snapshot)
  }
}
