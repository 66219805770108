import {
  CheckPostCodeFromAddressLookupScript,
  CheckPostCodeFromAddressLookupScriptState
} from "./CheckPostCodeFromAddressLookupDialogue"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { step } from "../../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { TrackingEvents } from "../../../../models/Constants"

type State = CheckPostCodeFromAddressLookupScriptState
export type CheckPostCodeFromAddressLookupMindScriptState = State

export class CheckPostCodeFromAddressLookupMindScript extends CheckPostCodeFromAddressLookupScript {
  readonly name: string = "CheckPostCodeFromAddressLookupMindScript"

  /** Script Steps */

  @step
  askPostCodeForAddressLookup(d: IStepData<State>): IStepResult {
    if (d.response === "manual-address") {
      this.track(TrackingEvents.MANUAL_ADDRESS_POSTCODE_NOT_FOUND, {
        body: d.state.postcodeEntered
      })
      return {
        nextStep: this.askAddress
      }
    }
    return {
      body: this.t([
        "Okay, so what's your postcode?",
        "We ask for this information to make sure supported self-help is available in your area"
      ]),
      prompt: {
        id: this.getPromptId("askPostCodeForAddressLookup"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handlePostCodeForAddressLookupWithCrisis
    }
  }

  /** Generic Handlers */

  // Basically don't ask for permissions
  // and also, as of this time - during testing - staging has zero
  // referrals with pii.addressHome.consentMail true. They are all
  // set to false, so this means they don't care about mailing, and
  // they don't have a problem that we set it to false by default
  // (as we should). So if you're here looking for a bug because
  // they reported that they all come in with consentMail false,
  // then tell them this is how it has always been because they didn't
  // want to ask the user for consent
  async onHandleSelectAddressFromPostCode(_state: State): Promise<IStepResult> {
    return { nextStep: this.end }
  }

  // Basically don't ask for permissions
  // and also, as of this time - during testing - staging has zero
  // referrals with pii.addressHome.consentMail true. They are all
  // set to false, so this means they don't care about mailing, and
  // they don't have a problem that we set it to false by default
  // (as we should). So if you're here looking for a bug because
  // they reported that they all come in with consentMail false,
  // then tell them this is how it has always been because they didn't
  // want to ask the user for consent
  async onHandleAddressWithCrisis(_state: State): Promise<IStepResult> {
    return { nextStep: this.end }
  }
}

export default class CheckPostCodeFromAddressLookupMindDialogue extends AdHocDialogue<
  State,
  CheckPostCodeFromAddressLookupMindScript
> {
  static id = DialogueIDs.CheckPostCodeFromAddressLookupMind
  readonly name: string = "CheckPostCodeFromAddressLookupMindDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      CheckPostCodeFromAddressLookupMindDialogue.id,
      new CheckPostCodeFromAddressLookupMindScript(),
      state,
      snapshot
    )
  }
}
