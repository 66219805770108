import { step } from "../../../backend/chatbot/decorators/step"
import { IntroductionIAPTScript } from "./IntroductionIAPTDialogue"
import type { IntroductionIAPTScriptState } from "./IntroductionIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"

type State = IntroductionIAPTScriptState

export class IntroductionInsightWaitlistScript extends IntroductionIAPTScript {
  readonly name: string = "IntroductionInsightWaitlistScript"

  /* Script Steps */

  @step.logState
  greet(d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Hi there!",
        "Welcome to Everyturn Mental Health",
        "I’m Limbic",
        "I'm a friendly robot assistance to help you on your next step to access our service",
        "We are working with Talking Helps Newcastle to support some of their patients that have been waiting for treatment",
        "To do this, we need to take some details from you"
      ]),
      prompt: {
        id: this.getPromptId("greet"),
        type: "inlinePicker",
        choices: [{ body: this.t("Hi Limbic") }]
      },
      nextStep: this.end
    }
  }
}

/* istanbul ignore next */
export default class IntroductionInsightWaitlistDialogue extends Dialogue<State> {
  static id = DialogueIDs.IntroductionInsightWaitlist
  readonly name: string = "IntroductionInsightWaitlistDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      IntroductionInsightWaitlistDialogue.id,
      new IntroductionInsightWaitlistScript(),
      state,
      snapshot
    )
  }
}
