import BaseScript, { BaseScriptState } from "../../../BaseScript"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { step } from "../../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { IDefaultChatFlowMessagesCollectMainIssue } from "@limbic/types"

export type ICollectMainIssueSettings = {
  options?: string[] | undefined
  messages?: IDefaultChatFlowMessagesCollectMainIssue
}

interface State extends BaseScriptState {
  hideClosingMessage?: boolean
  forceMainIssueValue?: boolean
  shouldUpdateReferral?: boolean
  updateReferralMainIssueKey?: string
  cancelIsEmptySubmit?: boolean
}

export type CollectMainIssueScriptState = State

export class CollectMainIssueScript extends BaseScript<State> {
  readonly name: string = "CollectMainIssueScript"
  protected messages: IDefaultChatFlowMessagesCollectMainIssue | undefined

  constructor(settings: ICollectMainIssueSettings | undefined = {}) {
    super()
    this.messages = settings?.messages ?? {}
  }

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askMainIssue }
  }

  @step.logState
  async askMainIssue(d: IStepData<State>): Promise<IStepResult> {
    const name = this.getName(d.state)
    const body = this.t(
      this.messages?.askMainIssue ?? [
        "So {name}, what's the main issue that has brought you here today?",
        "(Please try to describe your thoughts, feelings, things that trouble you, and the impact this is having on your life)"
      ],
      { ...this.getContext(d.state), name }
    )
    await this.appendQuestionToLLMThread?.(body)

    return {
      body,
      prompt: {
        id: this.getPromptId("askMainIssue"),
        type: "text",
        dataPointsName: "askMainIssue",
        forceValue: d.state.forceMainIssueValue ?? true,
        cancelIsEmptySubmit: d.state.cancelIsEmptySubmit ?? undefined
      },
      nextStep: this.handleMainIssueWithCrisis
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, string | undefined>, script: CollectMainIssueScript) => {
      d.state.mainIssue = d.response
      script.referralStore.setCustomField<State>("mainIssue", d.response)
      if (d.state.shouldUpdateReferral && !d.state.updateReferralMainIssueKey) {
        // Defining a standard key for the main issue
        // (so the option exists not to provide one)
        script.referralStore.updateReferral({ problemInOwnWords: d.response })
      }
      if (d.state.updateReferralMainIssueKey) {
        script.referralStore.updateReferral({ [d.state.updateReferralMainIssueKey]: d.response })
      }
    }
  )
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: CollectMainIssueScript) => s.end
  })
  async handleMainIssueWithCrisis(d: IStepData<State, string | undefined>): Promise<IStepResult> {
    const llmResponse = await this.getLLMResponse?.(d.response ?? "[skipped]")
    if (llmResponse) return { body: llmResponse, nextStep: this.end }

    if (d.state.hideClosingMessage) return { nextStep: this.end }

    const name = this.getName(d.state)
    const body = d.response
      ? this.t(
          this.messages?.closingMessage ??
            `Thank you for sharing {name}, you've come to the right place`,
          { ...this.getContext(d.state), name }
        )
      : undefined
    return {
      body,
      nextStep: this.end
    }
  }
}

export default class CollectMainIssueDialogue extends AdHocDialogue<State, CollectMainIssueScript> {
  static id = DialogueIDs.CollectMainIssue
  readonly name: string = "CollectMainIssueDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: ICollectMainIssueSettings
  ) {
    super(
      CollectMainIssueDialogue.id,
      new CollectMainIssueScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
