import createSurveyDialogue from "../createSurveyDialogue"
import ISurvey, { SurveyType } from "../../models/ISurvey"
import { DialogueIDs } from "../DialogueIDs"

const GAD_7: ISurvey = {
  startingQuestion: [
    "This next set of 7 questions is designed to measure common symptoms of anxiety",
    "Over the last 2 weeks, how often have you been bothered by the following problems?"
  ],
  questions: [
    {
      id: "1",
      questionName: "GAD7 - step1",
      question: "1. Feeling nervous, anxious or on edge?",
      type: "inline",
      progress: 0.15,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "Several days" },
        { points: 2, answer: "More than half the days" },
        { points: 3, answer: "Nearly every day" }
      ]
    },
    {
      id: "2",
      questionName: "GAD7 - step2",
      question: "2. Not being able to stop or control worrying?",
      type: "inline",
      progress: 0.3,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "Several days" },
        { points: 2, answer: "More than half the days" },
        { points: 3, answer: "Nearly every day" }
      ]
    },
    {
      id: "3",
      questionName: "GAD7 - step3",
      question: "3. Worrying too much about different things?",
      type: "inline",
      progress: 0.45,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "Several days" },
        { points: 2, answer: "More than half the days" },
        { points: 3, answer: "Nearly every day" }
      ]
    },
    {
      id: "4",
      questionName: "GAD7 - step4",
      question: "4. Trouble relaxing?",
      type: "inline",
      progress: 0.6,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "Several days" },
        { points: 2, answer: "More than half the days" },
        { points: 3, answer: "Nearly every day" }
      ]
    },
    {
      id: "5",
      questionName: "GAD7 - step5",
      question: "5. Being so restless that it is hard to sit still?",
      type: "inline",
      progress: 0.75,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "Several days" },
        { points: 2, answer: "More than half the days" },
        { points: 3, answer: "Nearly every day" }
      ]
    },
    {
      id: "6",
      questionName: "GAD7 - step6",
      question: "6. Becoming easily annoyed or irritable?",
      type: "inline",
      progress: 0.9,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "Several days" },
        { points: 2, answer: "More than half the days" },
        { points: 3, answer: "Nearly every day" }
      ]
    },
    {
      id: "7",
      questionName: "GAD7 - step7",
      question: "7. Feeling afraid as if something awful might happen?",
      type: "inline",
      progress: 1,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "Several days" },
        { points: 2, answer: "More than half the days" },
        { points: 3, answer: "Nearly every day" }
      ]
    }
  ]
}

export default createSurveyDialogue(DialogueIDs.GAD7, GAD_7, SurveyType.GAD7)
