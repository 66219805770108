import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { IAPTIDs } from "../../../models/IIAPTService"
import { DialogueIDs } from "../../DialogueIDs"
import { ADSMConsentScript, ADSMConsentScriptState } from "./ADSMConsentDialogue"

type State = ADSMConsentScriptState
export type ADSMConsentInsightDialogueState = State

export class ADSMConsentInsightScript extends ADSMConsentScript {
  readonly name: string = "ADSMConsentInsightScript"

  /** Script Steps */
  @step.logState
  start(d: IStepData<State>): IStepResult {
    const disableADSMInsightIAPTIDs = [
      IAPTIDs.INSIGHT_KENT,
      IAPTIDs.INSIGHT_KENT_DEMO,
      IAPTIDs.INSIGHT_MEDWAY,
      IAPTIDs.INSIGHT_MEDWAY_DEMO,
      IAPTIDs.INSIGHT_NOTTS
    ]

    const adsmEnabled =
      this.rootStore.configStore.adsmEnabled &&
      !disableADSMInsightIAPTIDs.includes(d.state.iapt?.id as IAPTIDs)

    if (adsmEnabled && this.referralStore.isMainSpokenLanguageEnglish) {
      return { nextStep: this.intro }
    }
    this.clinicalStore.disableADSM()
    return { nextStep: this.end }
  }

  /** Generic Handlers */
}

/* istanbul ignore next */
export default class ADSMConsentInsightDialogue extends Dialogue<State> {
  static id = DialogueIDs.ADSMConsentInsight
  readonly name: string = "ADSMConsentInsightDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(ADSMConsentInsightDialogue.id, new ADSMConsentInsightScript(), state, snapshot)
  }
}
