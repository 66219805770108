import { IClinicalPath } from "@limbic/types"
import { IClinicalPathOverride } from "../../../models/IClinicalPath"

//prettier-ignore
export const clinicalPathsLincolnshire: Record<IClinicalPath["id"], IClinicalPathOverride> = {
  complex_comorbid: { defaultReferralType: "Full Assessment (Complex Comorbid)" },
  undetermined: { defaultReferralType: "Full Assessment (Undetermined)" },
  "1": { defaultReferralType: "Short Assessment (Below Caseness)" },
  "2": { defaultReferralType: "Short Assessment (Below Caseness)" },
  "3": { defaultReferralType: "Short Assessment (Below Caseness)" },
  "4": { defaultReferralType: "Short Assessment (Moderate Depression)" },
  "5": { defaultReferralType: "Short Assessment (Moderate Depression, Moderate Disability)" },
  "6": { defaultReferralType: "Full Assessment (Moderate Depression, Severe Disability)" },
  "7": { defaultReferralType: "Short Assessment (Moderately Severe Depression)" },
  "8": { defaultReferralType: "Short Assessment (Moderately Severe Depression, Moderate Disability)" },
  "9": { defaultReferralType: "Full Assessment (Moderately Severe Depression, Severe Disability)" },
  "10": { defaultReferralType: "Full Assessment (Severe Depression)" },
  "11": { defaultReferralType: "Full Assessment (Severe Depression, Moderate Disability)" },
  "12": { defaultReferralType: "Full Assessment (Severe Depression, Severe Disability)" },
  "13": { defaultReferralType: "Full Assessment (Social Phobia)" },
  "14": { defaultReferralType: "Full Assessment (Social Phobia, Moderate Disability)" },
  "15": { defaultReferralType: "Full Assessment (Social Phobia, Severe Disability)" },
  "16": { defaultReferralType: "Full Assessment (Moderate Social Phobia)" },
  "17": { defaultReferralType: "Full Assessment (Moderate Social Phobia, Moderate Disability)" },
  "18": { defaultReferralType: "Full Assessment (Moderate Social Phobia, Severe Disability)" },
  "19": { defaultReferralType: "Full Assessment (Severe Social Phobia)" },
  "20": { defaultReferralType: "Full Assessment (Severe Social Phobia, Moderate Disability)" },
  "21": { defaultReferralType: "Full Assessment (Severe Social Phobia, Severe Disability)" },
  "22": { defaultReferralType: "Short Assessment (Specific Phobia)" },
  "23": { defaultReferralType: "Short Assessment (Specific Phobia, Moderate Disability)" },
  "24": { defaultReferralType: "Short Assessment (Specific Phobia, Severe Disability)" },
  "25": { defaultReferralType: "Full Assessment (Needle/Blood/Injury Phobia)" },
  "26": { defaultReferralType: "Full Assessment (Needle/Blood/Injury Phobia, Moderate Disability)" },
  "27": { defaultReferralType: "Full Assessment (Needle/Blood/Injury Phobia, Severe Disability)" },
  "28": { defaultReferralType: "Short Assessment (Panic)" },
  "29": { defaultReferralType: "Short Assessment (Panic, Moderate Disability)" },
  "30": { defaultReferralType: "Short Assessment (Severe Panic, High Disability)" },
  "31": { defaultReferralType: "Short Assessment (Moderate GAD)" },
  "32": { defaultReferralType: "Short Assessment (Moderate GAD, Moderate Disability)" },
  "33": { defaultReferralType: "Full Assessment (Moderate GAD, Severe Disability)" },
  "34": { defaultReferralType: "Short Assessment (Severe GAD)" },
  "35": { defaultReferralType: "Short Assessment (Severe GAD, Moderate Disability)" },
  "36": { defaultReferralType: "Full Assessment (Severe GAD, Severe Disability)" },
  "37": { defaultReferralType: "Short Assessment (Health Anxiety)" },
  "38": { defaultReferralType: "Short Assessment (Health Anxiety, Moderate Disability)" },
  "39": { defaultReferralType: "Short Assessment (Health Anxiety, Severe Disability)" },
  "40": { defaultReferralType: "Short Assessment (Moderate Health Anxiety)" },
  "41": { defaultReferralType: "Short Assessment (Moderate Health Anxiety, Moderate Disability)" },
  "42": { defaultReferralType: "Short Assessment (Moderate Health Anxiety, Severe Disability)" },
  "43": { defaultReferralType: "Short Assessment (Severe Health Anxiety)" },
  "44": { defaultReferralType: "Short Assessment (Severe Health Anxiety, Moderate Disability)" },
  "45": { defaultReferralType: "Short Assessment (Severe Health Anxiety, Severe Disability)" },
  "46": { defaultReferralType: "Short Assessment (OCD)" },
  "47": { defaultReferralType: "Short Assessment (OCD, Moderate Disability)" },
  "48": { defaultReferralType: "Short Assessment (OCD, Severe Disability)" },
  "49": { defaultReferralType: "Full Assessment (PTSD)" },
  "50": { defaultReferralType: "Full Assessment (PTSD, Moderate Disability)" },
  "51": { defaultReferralType: "Full Assessment (PTSD, Severe Disability)" }
}
