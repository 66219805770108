import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../DialogueIDs"
import CrisisScript, { CrisisScriptState } from "./CrisisScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { ReferralType } from "../../../models/Constants"
import type { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = CrisisScriptState
export type CrisisSABPScriptState = State

export class CrisisSABPScript extends CrisisScript {
  readonly name: string = "CrisisSABPScript"

  /** Script Steps */

  @step
  saySorryToHear(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    const name = this.getName(d.state)
    return {
      body: this.t(
        [
          "Sorry to hear that {name}",
          "It is normal for people to have thoughts of this nature at times",
          "However, this is not an emergency response service",
          "I'll make sure to notify {iaptName} of this in the referral"
        ],
        { name, iaptName }
      ),
      prompt: {
        id: this.getPromptId("saySorryToHear"),
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }]
      },
      nextStep: this.sayCrisisNumbers
    }
  }

  @step.logState
  sayCrisisNumbers(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "If you need urgent, life threatening medical help please call [999](tel:999)",
        "Alternatively, you can call the Mental Health Crisis Helpline on [0800 915 4644](tel:08009154644) if it's not a life-threatening situation"
      ]),
      prompt: {
        id: this.getPromptId("sayCrisisNumbers"),
        type: "inlinePicker",
        choices: [{ body: this.t("I understand") }, { body: this.t("Okay") }]
      },
      nextStep: this.handleCrisisNumbers
    }
  }

  @step.logState
  handleCrisisNumbers(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "999 and Mental Health Crisis Helpline (0800 915 4644)"
    )
    return {
      body: this.t("Ok, let's carry on with the mental health check in"),
      nextStep: this.end
    }
  }

  @step.logState
  saySorryForTheMisunderstanding(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Okay",
        "My creators have taught me to listen carefully for specific words or phrases",
        "In case you need help",
        "Sorry for the misunderstanding",
        "Ok, let's carry on with the mental health check in"
      ]),
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  getCustomReferralType(state: State): string | undefined {
    const currentMHTreatment = this.referralStore.getCustomField("currentMHTreatment")
    const hasCurrentMHTreatment = !!currentMHTreatment?.length
    if (hasCurrentMHTreatment) return "Extended Assessment (User seeing other MH professionals)"
  }

  getReferralTypeForRisk(_state: State): string | undefined {
    if (this.clinicalStore.isHighRisk) return ReferralType.RISK_PATIENT
    if (this.clinicalStore.isModerateRisk) return ReferralType.RISK_PATIENT
  }
}

/* istanbul ignore next */
export default class CrisisSABPDialogue extends AdHocDialogue<State, CrisisSABPScript> {
  static id = DialogueIDs.CrisisSABP
  readonly name: string = "CrisisSABPDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(CrisisSABPDialogue.id, new CrisisSABPScript(), state, snapshot)
  }
}
