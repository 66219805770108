import React from "react"
import "./DeviceLabel.scss"

export default function DeviceLabel(): JSX.Element {
  const imgUrl =
    "https://limbic-web-bot-versioning.s3.eu-west-2.amazonaws.com/label/device-label.png"
  const openDeviceLabel = (): void => {
    window.open(imgUrl, "_blank")
  }

  return (
    <div
      id="device-label"
      className="lb-device-label-box"
      onClick={openDeviceLabel}
      title="Device label">
      <img src={imgUrl} className="lb-device-label" alt="Limbic Access device label" />
    </div>
  )
}
