/* eslint-disable @typescript-eslint/no-empty-function */
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../../DialogueIDs"
import { step } from "../../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import BaseScript, { BaseScriptState } from "../../../BaseScript"
import { IDefaultChatFlowMessagesAskPreviousMHTreatment } from "@limbic/types"
import formatUnicorn from "../../../../utils/formatUnicorn"

export type ICollectPriorMHTreatmentDetails = {
  options?: string[] | undefined
  messages?: IDefaultChatFlowMessagesAskPreviousMHTreatment
}

interface State extends BaseScriptState {
  priorMHTreatment?: boolean
  priorMHTreatmentDetails?: string
}

export type CollectPriorMHTreatmentDetailsScriptState = State

export class CollectPriorMHTreatmentDetailsScript extends BaseScript<State> {
  readonly name: string = "CollectPriorMHTreatmentDetailsScript"
  protected messages: IDefaultChatFlowMessagesAskPreviousMHTreatment | undefined

  constructor(settings: ICollectPriorMHTreatmentDetails | undefined = {}) {
    super()
    this.messages = settings?.messages ?? {}
  }
  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askPriorMHTreatment }
  }

  @step.logState
  askPriorMHTreatment(d: IStepData<State>): IStepResult {
    let body: string | string[] = this.t([
      "Have you had any prior treatment for your mental health?",
      "If yes, please give me some details"
    ])

    if (this.messages?.askPriorMHTreatment && this.messages?.askPriorMHTreatment.length) {
      body = this.t(
        this.messages?.askPriorMHTreatment.map(message =>
          formatUnicorn(message, this.getContext(d.state))
        )
      )
    }

    return {
      body,
      prompt: {
        id: this.getPromptId("askPriorMHTreatment"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("I haven't had any prior treatment"), value: null }],
        textPrompt: {
          forceValue: true
        },
        dataPointsName: "askPriorMHTreatment"
      },
      nextStep: this.handlePriorMHTreatment
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, string | null>, script: CollectPriorMHTreatmentDetailsScript) => {
      d.state.priorMHTreatment = !!d.response
      d.state.priorMHTreatmentDetails = d.response ?? undefined
      script.referralStore.setCustomField<State>("priorMHTreatment", d.state.priorMHTreatment)
      script.referralStore.setCustomField<State>(
        "priorMHTreatmentDetails",
        d.state.priorMHTreatmentDetails
      )
    }
  )
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: CollectPriorMHTreatmentDetailsScript) => s.end
  })
  handlePriorMHTreatment(_d: IStepData<State, string | null>): IStepResult {
    return { nextStep: this.end }
  }

  /** Generic Handlers */

  getContext(state: State): Record<string, any> {
    return {
      ...this.rootStore.configStore,
      name: this.getName(state),
      iaptName: this.getIAPTName(state)
    }
  }
}

/* istanbul ignore next */
export default class CollectPriorMHTreatmentDetailsDialogue extends AdHocDialogue<
  State,
  CollectPriorMHTreatmentDetailsScript
> {
  static id = DialogueIDs.CollectPriorMHTreatmentDetails
  readonly name: string = "CollectPriorMHTreatmentDetailsDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      CollectPriorMHTreatmentDetailsDialogue.id,
      new CollectPriorMHTreatmentDetailsScript(),
      state,
      snapshot
    )
  }
}
