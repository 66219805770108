export enum DialogueIDs {
  MainFlow = "mainFlow",
  IntroductionDynamic = "introductionDynamic",
  IntroductionIAPT = "introductionIAPT",
  IntroductionWellbeingHub = "introductionWellbeingHub",
  IntroductionGP = "introductionGP",
  IntroductionEssex = "introductionEssex",
  IntroductionHealix = "introductionHealix",
  IntroductionGMHub = "introductionGMHub",
  IntroductionDemoPrivateProviders = "introductionDemoPrivateProviders",
  IntroductionInsight = "introductionInsight",
  IntroductionInsightWaitlist = "introductionInsightWaitlist",
  IntroductionInsightFollowUp = "introductionInsightFollowUp",
  IntroductionOxford = "introductionOxford",
  IntroductionBuckinghamshire = "introductionBuckinghamshire",
  IntroductionVitality = "introductionVitality",
  IntroductionMPFT = "introductionMPFT",
  IntroductionVHG = "introductionVHG",
  IntroductionMind = "introductionMind",
  IntroductionLWC = "introductionLWC",
  IntroductionDorking = "introductionDorking",
  GetNameDynamic = "getNameDynamic",
  GetNameIAPT = "getNameIAPT",
  GetNameBradford = "getNameBradford",
  GetNameEssex = "getNameEssex",
  GetNameInsight = "getNameInsight",
  GetNameLincolnshire = "getNameLincolGetNameLincolnshire",
  GetNameHealix = "getNameHealix",
  GetNameVitality = "getNameVitality",
  GetNameDemoPrivateProviders = "getNameDemoPrivateProviders",
  GetNameInsightWaitlist = "getNameInsightWaitlist",
  GetNameInsightFollowUp = "getNameInsightFollowUp",
  GetNameOxfordBucks = "getNameOxfordBucks",
  GetNameSABP = "getNameSABP",
  GetNameVHG = "getNameVHG",
  GetNameMPFT = "getNameMPFT",
  GetNameMHM = "getNameMHM",
  GetNameMind = "getNameMind",
  GetNameLWC = "getNameLWC",
  GetNameCW = "getNameCW",
  GetNameDorking = "getNameDorking",
  EligibilityCheckDynamic = "eligibilityCheckDynamic",
  EligibilityCheckBradford = "eligibilityCheckBradford",
  EligibilityCheckDemo = "eligibilityCheckDemo",
  EligibilityCheckIAPT = "eligibilityCheckIAPT",
  EligibilityCheckEssex = "eligibilityCheckEssex",
  EligibilityCheckWithPDSSpine = "eligibilityCheckWithPDSSpine",
  EligibilityCheckGP = "eligibilityCheckGP",
  EligibilityCheckWellbeingHub = "eligibilityCheckWellbeingHub",
  EligibilityCheckGMHub = "eligibilityCheckGMHub",
  EligibilityCheckInsight = "eligibilityCheckInsight",
  EligibilityCheckLincolnshire = "eligibilityCheckLincolnshire",
  EligibilityCheckOxford = "eligibilityCheckOxford",
  EligibilityCheckBuckinghamshire = "eligibilityCheckBuckinghamshire",
  EligibilityCheckSABP = "eligibilityCheckSABP",
  EligibilityCheckVHG = "eligibilityCheckVHG",
  EligibilityCheckVitality = "eligibilityCheckVitality",
  EligibilityCheckMPFT = "eligibilityCheckMPFT",
  EligibilityCheckMHM = "eligibilityCheckMHM",
  EligibilityCheckMind = "eligibilityCheckMind",
  EligibilityCheckCW = "eligibilityCheckCW",
  EligibilityCheckDorking = "eligibilityCheckDorking",
  SuitableServicesSignpostIAPT = "suitableServicesSignpostIAPT",
  SuitableServicesSignpostCCG = "suitableServicesSignpostCCG",
  SuitableServicesSignpostSABP = "suitableServicesSignpostSABP",
  SuitableServicesSignpostEssex = "suitableServicesSignpostEssex",
  SuitableServicesSignpostMind = "suitableServicesSignpostMind",
  SuitableServicesSignpostLWC = "suitableServicesSignpostLWC",
  SuitableServicesSignpostCW = "suitableServicesSignpostCW",
  SuitableServicesSignpostBuckinghamshire = "suitableServicesSignpostBuckinghamshire",
  SuitableServicesSignpostDorking = "suitableServicesSignpostDorking",
  SuitableServicesSignpostOxford = "suitableServicesSignpostOxford",
  UnderageSignpost = "underAgeSignpost",
  UnderageSignpostOxford = "underageSignpostOxford",
  UnderAgeSignpostBuckinghamshire = "underAgeSignpostBuckinghamshire",
  UnderageSignpostBradford = "underageSignpostBradford",
  UnderageSignpostGMHub = "underAgeSignpostGMhub",
  UnderageSignpostWellbeingHub = "underageSignpostWellbeingHub",
  UnderageSignpostEssex = "underAgeSignpostEssex",
  UnderageSignpostLincolnshire = "underAgeSignpostLincolnshire",
  UnderageSignpostSABP = "underageSignpostSABP",
  UnderageSignpostMPFT = "underageSignpostMPFT",
  UnderageSignpostMind = "underageSignpostMind",
  UnderAgeSignpostLWC = "underAgeSignpostLWC",
  UnderAgeSignpostCW = "UnderAgeSignpostCW",
  UnderageSignpostDorking = "underageSignpostDorking",
  UnderAgeSignpostBexley = "underAgeSignpostBexley",
  PeaceOfMindIAPT = "peaceOfMindIAPT",
  PeaceOfMindGP = "peaceOfMindGP",
  PeaceOfMindWellbeingHub = "peaceOfMindWellbeingHub",
  PeaceOfMindHealix = "peaceOfMindHealix",
  PeaceOfMindVitality = "peaceOfMindVitality",
  PeaceOfMindDemoPrivateProviders = "peaceOfMindDemoPrivateProviders",
  PermissionsDynamic = "permissionsDynamic",
  PermissionsEssex = "permissionsEssex",
  PermissionsIAPT = "permissionsIAPT",
  PermissionsWellbeingHub = "permissionsWellbeingHub",
  PermissionsGMHub = "permissionsGMHub",
  PermissionsSABP = "permissionsSABP",
  PermissionsInsight = "permissionsInsight",
  PermissionsDemoPrivateProviders = "permissionsDemoPrivateProviders",
  PermissionsVitality = "permissionsVitality",
  PermissionsMPFT = "permissionsMPFT",
  PermissionsMHM = "permissionsMHM",
  PermissionsLincolnshire = "permissionsLincolnshire",
  PermissionsCW = "permissionsCW",
  PermissionsDorking = "permissionsDorking",
  ResearchConsent = "researchConsent",
  ResearchConsentSABP = "researchConsentSABP",
  PermissionsMind = "permissionsMind",
  PermissionsLWC = "permissionsLWC",
  PermissionsOxfordBucks = "PermissionsOxfordBucks",
  ADSMConsent = "adsmConsent",
  ADSMConsentInsight = "adsmConsentInsight",
  AssessmentPitchBradford = "assessmentPitchBradford",
  AssessmentPitchIAPT = "assessmentPitchIAPT",
  AssessmentPitchGP = "assessmentPitchGP",
  AssessmentPitchWellbeingHub = "assessmentPitchWellbeingHub",
  AssessmentPitchEssex = "assessmentPitchEssex",
  AssessmentPitchGMHub = "assessmentPitchGMHub",
  AssessmentPitchHealix = "assessmentPitchHealix",
  AssessmentPitchInsight = "assessmentPitchInsight",
  AssessmentPitchInsightFollowUp = "assessmentPitchInsightFollowUp",
  AssessmentPitchLincolnshire = "assessmentPitchLincolnshire",
  AssessmentPitchOxfordBucks = "assessmentPitchOxfordBucks",
  AssessmentPitchSABP = "assessmentPitchSABP",
  AssessmentPitchVHG = "assessmentPitchVHG",
  AssessmentPitchDemoPrivateProviders = "assessmentPitchDemoPrivateProviders",
  AssessmentPitchVitality = "assessmentPitchVitality",
  AssessmentPitchMPFT = "assessmentPitchMPFT",
  AssessmentPitchMHM = "assessmentPitchMHM",
  AssessmentDynamic = "assessmentDynamic",
  AssessmentBradford = "assessmentBradford",
  AssessmentPitchMind = "assessmentPitchMind",
  AssessmentPitchLWC = "assessmentPitchLWC",
  AssessmentPitchCW = "assessmentPitchCW",
  AssessmentAndTreatments = "assessmentAndTreatments",
  AssessmentPitchDorking = "assessmentPitchDorking",
  AssessmentDemo = "assessmentDemo",
  AssessmentWellbeingHub = "assessmentWellbeingHub",
  AssessmentEssex = "assessmentEssex",
  AssessmentSABP = "assessmentSABP",
  AssessmentGMHub = "assessmentGMHub",
  AssessmentHealix = "assessmentHealix",
  AssessmentInsight = "assessmentInsight",
  AssessmentADSMDynamic = "assessmentADSMDynamic",
  AssessmentADSMInsight = "assessmentADSMInsight",
  AssessmentVHG = "assessmentVHG",
  AssessmentLincolnshire = "assessmentLincolnshire",
  AssessmentDemoPrivateProviders = "assessmentDemoPrivateProviders",
  AssessmentVitality = "assessmentVitality",
  AssessmentMPFT = "assessmentMPFT",
  AssessmentMHM = "assessmentMHM",
  AssessmentMind = "assessmentMind",
  AssessmentLWC = "assessmentLWC",
  AssessmentCW = "assessmentCW",
  AssessmentOxfordBucks = "assessmentOxfordBucks",
  AssessmentDorking = "assessmentDorking",
  TreatmentOptionsIAPT = "treatmentOptionsIAPT",
  TreatmentOptionsSABP = "treatmentOptionsSABP",
  TreatmentOptionsHealix = "treatmentOptionsHealix",
  TreatmentOptionsInsight = "treatmentOptionsInsight",
  TreatmentOptionsDemoPrivateProviders = "treatmentOptionsDemoPrivateProviders",
  TreatmentOptionsVitality = "treatmentOptionsVitality",
  TreatmentOptionsLWC = "treatmentOptionsLWC",
  BookMentalHealthProfessional = "bookMentalHealthProfessional",
  WellbeingHealix = "wellbeingHealix",
  WellbeingDemoPrivateProviders = "wellbeingDemoPrivateProviders",
  SelfReferralDynamic = "selfReferralDynamic",
  SelfReferralDemo = "selfReferralDemo",
  SelfReferralEssex = "selfReferralEssex",
  SelfReferralPitchIAPT = "selfReferralPitchIAPT",
  SelfReferralPitchWellbeingHub = "selfReferralPitchWellbeingHub",
  SelfReferralPitchHealix = "selfReferralPitchHealix",
  SelfReferralPitchGMHub = "selfReferralPitchGMHub",
  SelfReferralPitchDemoPrivateProviders = "selfReferralPitchDemoPrivateProviders",
  SelfReferralPitchMind = "selfReferralPitchMind",
  SelfReferralVHG = "selfReferralVHG",
  SelfReferralGMHub = "selfReferralGMHub",
  SelfReferralGMHubManagerTeam = "selfReferralGMHubManagerTeam",
  SelfReferralGMHubManagerIndividual = "selfReferralGMHubManagerIndividual",
  SelfReferralGMHubIndividual = "selfReferralGMHubIndividual",
  SelfReferralWellbeingHub = "selfReferralWellbeingHub",
  SelfReferralWellbeingHubIndividual = "selfReferralWellbeingHubIndividual",
  SelfReferralWellbeingHubManagerIndividual = "selfReferralWellbeingHubManagerIndividual",
  SelfReferralWellbeingHubManagerTeam = "selfReferralWellbeingHubManagerTeam",
  SelfReferralHealix = "selfReferralHealix",
  SelfReferralInsight = "selfReferralInsight",
  SelfReferralSABP = "selfReferralSABP",
  SelfReferralLincolnshire = "selfReferralLincolnshire",
  SelfReferralDemoPrivateProviders = "selfReferralDemoPrivateProviders",
  SelfReferralInsightWaitlist = "selfReferralInsightWaitlist",
  SelfReferralVitality = "selfReferralVitality",
  SelfReferralMPFT = "selfReferralMPFT",
  SelfReferralMHM = "selfReferralMHM",
  SelfReferralMind = "selfReferralMind",
  SelfReferralCW = "selfReferralCW",
  PHQ9 = "survey:PHQ9",
  PHQ9Dynamic = "survey:PHQ9_Dynamic",
  PHQ9GMHub = "survey:PHQ9_GMHub",
  PHQ9WellbeingHub = "survey:PHQ9_WellbeingHub",
  PHQ9SABP = "survey:PHQ9_SABP",
  PHQ9VHG = "survey:PHQ9_VHG",
  PHQ9Vitality = "survey:PHQ9_Vitality",
  RiskPathwayDynamic = "riskPathwayDynamic",
  RiskPathwayBradford = "riskPathwayBradford",
  RiskPathwayDemo = "riskPathwayDemo",
  RiskPathwayGMHub = "riskPathwayGMHub",
  RiskPathwayWellbeingHub = "riskPathwayWellbeingHub",
  RiskPathwayEssex = "riskPathwayEssex",
  RiskPathwayHealix = "riskPathwayHealix",
  RiskPathwayInsight = "riskPathwayInsight",
  RiskPathwayInsightFollowUp = "riskPathwayInsightFollowUp",
  RiskPathwayOxford = "riskPathwayOxford",
  RiskPathwayBuckinghamshire = "riskPathwayBuckinghamshire",
  RiskPathwaySABP = "riskPathwaySABP",
  RiskPathwayVHG = "riskPathwayVHG",
  RiskPathwayMHM = "riskPathwayMHM",
  RiskPathwayLincolnshire = "riskPathwayLincolnshire",
  RiskPathwayDemoPrivateProviders = "riskPathwayDemoPrivateProviders",
  RiskPathwayVitality = "riskPathwayVitality",
  RiskPathwayMPFT = "riskPathwayMPFT",
  RiskPathwayMind = "riskPathwayMind",
  RiskPathwayLWC = "riskPathwayLWC",
  RiskPathwayCW = "riskPathwayCW",
  RiskPathwayDorking = "riskPathwayDorking",
  GAD7 = "survey:GAD7",
  PCL5 = "survey:PCL5",
  PDSS = "survey:PDSS",
  SPIN = "survey:SPIN",
  OCI = "survey:OCI",
  SHAI18 = "survey:SHAI18",
  SpecificPhobiaIntro = "survey:SpecificPhobiaIntro",
  SpecificPhobia = "survey:SpecificPhobia",
  Audit = "survey:Audit",
  DrugsAndSmoking = "survey:DrugsAndSmoking",
  ITQ = "survey:ITQ",
  IRQA = "survey:IRQA",
  IAPTAccommodation = "survey:IAPTAccommodation",
  IAPTPhobiaScale = "survey:IAPTPhobiaScale",
  IAPTEmploymentStatus = "survey:IAPTEmploymentStatus",
  IAPTEmploymentStatusKM = "survey:IAPTEmploymentStatusKM",
  IAPTWorkAndSocialAdjustment = "survey:IAPTWorkAndSocialAdjustment",
  IAPTMedication = "survey:iaptMedication",
  Goodbye = "goodbye",
  GoodbyeDynamic = "goodbyeDynamic",
  GoodbyeBradford = "goodbyeBradford",
  GoodbyeDemo = "goodbyeDemo",
  GoodbyeGMHub = "goodbyeGMHub",
  GoodbyeWellbeingHub = "goodbyeWellbeingHub",
  GoodbyeEssex = "goodbyeEssex",
  GoodbyeSABP = "goodbyeSABP",
  GoodbyeHealix = "goodbyeHealix",
  GoodbyeInsight = "goodbyeInsight",
  GoodbyeInsightFollowUp = "goodbyeInsightFollowUp",
  GoodbyeOxford = "goodbyeOxford",
  GoodbyeBuckinghamshire = "goodbyeBuckinghamshire",
  GoodbyeVHG = "goodbyeVHG",
  GoodbyeMHM = "goodbyeMHM",
  GoodbyeLincolnshire = "goodbyeLincolnshire",
  GoodbyeDemoPrivateProviders = "goodbyeDemoPrivateProviders",
  GoodbyeVitality = "goodbyeVitality",
  GoodbyeMPFT = "goodbyeMPFT",
  GoodbyeMind = "goodbyeMind",
  GoodbyeLWC = "goodbyeLWC",
  GoodbyeCW = "goodbyeCW",
  GoodbyeDorking = "goodbyeDorking",
  GoodbyeBexley = "goodbyeBexley",
  CrisisDynamic = "crisisDynamic",
  CrisisDemo = "crisisDemo",
  Crisis = "crisis",
  CrisisBradford = "crisisBradford",
  CrisisGMHub = "crisisGMHub",
  CrisisWellbeingHub = "crisisWellbeingHub",
  CrisisEssex = "crisisEssex",
  CrisisSABP = "crisisSABP",
  CrisisHealix = "crisisHealix",
  CrisisInsight = "crisisInsight",
  CrisisVHG = "crisisVHG",
  CrisisLincolnshire = "crisisLincolnshire",
  CrisisOxfordBucks = "crisisOxfordBucks",
  CrisisDemoPrivateProviders = "crisisDemoPrivateProviders",
  CrisisVitality = "crisisVitality",
  CrisisMPFT = "crisisMPFT",
  CrisisMHM = "crisisMHM",
  CrisisMind = "crisisMind",
  CrisisLWC = "crisisLWC",
  CrisisCW = "crisisCW",
  CrisisDorking = "crisisDorking",
  BookAppointment = "bookAppointment",
  BookAppointmentDynamic = "bookAppointmentDynamic",
  BookAppointmentVitality = "bookAppointmentVitality",
  BookAppointmentMind = "bookAppointmentMind",
  BookAppointmentChatflow = "bookAppointmentChatflow",
  CollectPhoneNumber = "collectPhoneNumber",
  CollectPhoneNumberEssex = "collectPhoneNumberEssex",
  CollectPhoneNumberInsight = "collectPhoneNumberInsight",
  CollectPhoneNumberMPFT = "collectPhoneNumberMPFT",
  CollectPhoneNumberMHM = "collectPhoneNumberMHM",
  CollectPhoneNumberCW = "collectPhoneNumberCW",
  CollectPhoneNumberDorking = "collectPhoneNumberDorking",
  CollectAlcoholConsumption = "collectAlcoholConsumption",
  CollectSMI = "collectSMI",
  CheckPostCodeFromAddressLookup = "checkPostCodeFromAddressLookup",
  CheckPostCodeFromAddressLookupMHM = "checkPostCodeFromAddressLookupMHM",
  CheckPostCodeFromAddressLookupSABP = "checkPostCodeFromAddressLookupSABP",
  CheckPostCodeFromAddressLookupMind = "checkPostCodeFromAddressLookupMind",
  CheckCovidAndDetails = "checkCovidAndDetails",
  CollectMainIssue = "collectMainIssue",
  CollectGoalForTherapy = "collectGoalForTherapy",
  CollectNHSNumber = "collectNHSNumber",
  CollectASD = "collectASD",
  CollectADHD = "collectADHD",
  CollectPreferredCorrespondence = "collectPreferredCorrespondence",
  CollectNationality = "collectNationality",
  CollectEthnicity = "collectEthnicity",
  CollectPriorMHTreatmentDetails = "collectPriorMHTreatmentDetails",
  CollectCurrentMHTreatmentDetails = "collectCurrentMHTreatmentDetails",
  CollectSexuality = "collectSexuality",
  CollectGender = "collectGender",
  CollectDisabilities = "collectDisabilities",
  CollectLanguageAndInterpreter = "collectLanguageAndInterpreter",
  CollectEmail = "collectEmail",
  CollectMedicationAndSubstancesDetails = "collectMedicationAndSubstancesDetails",
  CollectSubstances = "collectSubstances",
  CollectLongTermMedicalConditionDetails = "collectLongTermMedicalConditionDetails",
  CollectReligion = "collectReligion",
  CollectDateOfBirth = "collectDateOfBirth",
  CollectName = "collectName",
  CollectFeedback = "collectFeedback",
  CheckCrisisDetection = "checkCrisisDetection",
  GetPermissions = "getPermissions",
  ServiceSearch = "serviceSearch",
  SpineSearch = "spineSearch",
  SubmitReferral = "submitReferral",
  GoodbyeRecap = "goodbyeRecap"
}
