import { AssessmentPitchIAPTScript } from "./AssessmentPitchIAPTDialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { AssessmentPitchIAPTScriptState } from "./AssessmentPitchIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"

type State = AssessmentPitchIAPTScriptState

export class AssessmentPitchInsightFollowUpScript extends AssessmentPitchIAPTScript {
  readonly name: string = "AssessmentPitchInsightFollowUpScript"

  /** Script Steps */
  checkForNeedToBeCalled(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askWannaDoQuestionnaires }
  }

  @step.logState
  askWannaDoQuestionnaires(d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "I'm now going to ask you a few questions to measure symptoms of common mental health issues",
        "You will recognise these question from when you made your referral a few weeks ago",
        "We are asking the questions again now to ensure we have up-to-date answers for your assessment"
      ]),
      prompt: {
        id: this.getPromptId("askWannaDoQuestionnaires"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleWannaDoQuestionnaires
    }
  }
}

/* istanbul ignore next */
export default class AssessmentPitchInsightFollowUpDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentPitchInsightFollowUp
  readonly name: string = "AssessmentPitchInsightFollowUpDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      AssessmentPitchInsightFollowUpDialogue.id,
      new AssessmentPitchInsightFollowUpScript(),
      state,
      snapshot
    )
  }
}
