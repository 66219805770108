import React from "react"
import classnames from "classnames"
import "./InlinePicker.scss"
import ISelectable from "../../../models/ISelectable"
import Timer from "tiny-timer"
import {
  IEmailPrompt,
  IPhoneNumberPrompt,
  ITextPrompt
} from "../../../backend/chatbot/models/IPrompt"
import InlineButton from "../InlineButton/InlineButton"
import TextInput from "./TextInput"
import { observer } from "mobx-react"
import { useApplicationStore, useConfigStore } from "../../contexts/RootStoreContext"
import { DataPointsType } from "../../../models/DataPoints"
import { IDataPoints } from "@limbic/types"
import EmailInput from "./EmailInput"
import PhoneNumberInput from "./PhoneNumberInput"

const timer = new Timer({ stopwatch: true })

interface SingleChoiceProps {
  choices: ISelectable[]
  textPrompt?: Omit<ITextPrompt, "type" | "id">
  emailPrompt?: Omit<IEmailPrompt, "type" | "id">
  phonePrompt?: Omit<IPhoneNumberPrompt, "type" | "id">
  initialCountToShow?: number
  multiSelect?: false
  searchable?: boolean
  dataPointsName?: string
  onSubmit: (item: ISelectable, dataPoints?: IDataPoints | undefined) => void
}

interface MultipleChoiceProps {
  choices: ISelectable[]
  textPrompt?: undefined
  emailPrompt?: undefined
  phonePrompt?: undefined
  initialCountToShow?: number
  multiSelect: true
  searchable?: boolean
  dataPointsName?: string
  onSubmit: (item: ISelectable[], dataPoints?: IDataPoints) => void
}

type Props = SingleChoiceProps | MultipleChoiceProps

function InlinePicker(props: Props): JSX.Element {
  const {
    multiSelect = false,
    initialCountToShow = 0,
    choices: initialChoices,
    textPrompt,
    emailPrompt,
    phonePrompt,
    searchable
  } = props
  const config = useConfigStore()
  const app = useApplicationStore()
  const [timeOfFirstSelection, setTimeOfFirstSelection] = React.useState<number>(0)
  const [timeOfLastSelection, setTimeOfLastSelection] = React.useState<number>(0)
  const [showsMore, setShowsMore] = React.useState(false)
  const [choices, setChoices] = React.useState<ISelectable[]>([])
  const [restChoices, setRestChoices] = React.useState<ISelectable[]>([])
  const [selectedChoices, setSelectedChoices] = React.useState<ISelectable[]>([])
  const [searchString, setSearchString] = React.useState<string>()

  const onMorePress = React.useCallback((): void => setShowsMore(true), [setShowsMore])
  const onBackPress = React.useCallback((): void => setShowsMore(false), [setShowsMore])

  const getDataPoints = React.useCallback(
    (value, type: DataPointsType) => {
      if (!props.dataPointsName) {
        return undefined
      }
      return type === DataPointsType.INLINE_PICKER
        ? {
            q: props.dataPointsName,
            type,
            timeOfSingleSelection: timer.time / 1000,
            submitTimeFromFirstViewing: timer.time / 1000,
            value
          }
        : {
            q: props.dataPointsName,
            type,
            timeOfFirstSelection:
              timeOfFirstSelection !== 0 ? timeOfFirstSelection / 1000 : timer.time / 1000,
            timeOfLastSelection: timeOfLastSelection / 1000,
            submitTimeFromFirstViewing: timer.time / 1000,
            value
          }
    },
    [timeOfFirstSelection, timeOfLastSelection, props.dataPointsName]
  )

  const onItemPress = React.useCallback(
    (item: ISelectable) => {
      if (props.multiSelect) {
        if (!selectedChoices.length && timeOfFirstSelection === 0) {
          setTimeOfFirstSelection(timer.time)
        }
        const exists = !!selectedChoices.find(i => i.body === item.body)
        let newSelectedChoices: ISelectable[]
        if (exists) {
          newSelectedChoices = selectedChoices.filter(i => i.body !== item.body)
        } else {
          if (item.selectIndividually) {
            const dataPoints: IDataPoints | undefined = getDataPoints(
              item.body,
              DataPointsType.INLINE_PICKER_MULTIPLE
            )
            timer.stop()
            props.onSubmit([item], dataPoints)
            return
          }
          newSelectedChoices = selectedChoices.concat(item)
        }

        setTimeOfLastSelection(timer.time)
        setSelectedChoices(newSelectedChoices)
        return
      }
      const dataPoints: IDataPoints | undefined = getDataPoints(
        item.body,
        DataPointsType.INLINE_PICKER
      )
      timer.stop()
      props.onSubmit(item, dataPoints)
    },
    [props, selectedChoices, timeOfFirstSelection, getDataPoints]
  )

  const onSubmitMultipleSelectionPress = React.useCallback(() => {
    if (props.multiSelect && selectedChoices.length) {
      const value = selectedChoices.map(choice => choice.body || "")
      const dataPoints: IDataPoints | undefined = getDataPoints(
        value,
        DataPointsType.INLINE_PICKER_MULTIPLE
      )
      timer.stop()
      props.onSubmit(selectedChoices, dataPoints)
    }
  }, [props, selectedChoices, getDataPoints])

  const onCustomChoiceSubmit = React.useCallback(
    (text?: string) => {
      if (props.multiSelect) {
        return
      }
      if (!text && textPrompt?.forceValue) {
        return
      }
      props.onSubmit({ body: text, value: text })
    },
    [props, textPrompt]
  )

  const onEmailSubmit = React.useCallback(
    (body: string, value: string) => {
      if (props.multiSelect) {
        return
      }
      props?.onSubmit({ body, value })
    },
    [props]
  )

  const onPhoneNumberSubmit = React.useCallback(
    (phoneNumber: string) => {
      if (props.multiSelect) {
        return
      }
      props?.onSubmit({ body: phoneNumber, value: phoneNumber })
    },
    [props]
  )

  React.useEffect(() => {
    timer.start(90000)
    if (initialCountToShow <= 0) {
      setChoices(initialChoices || [])
      setRestChoices([])
      return
    }
    setChoices(initialChoices.slice(0, initialCountToShow))
    setRestChoices(initialChoices.slice(initialCountToShow))
  }, [initialChoices, initialCountToShow, props.dataPointsName])

  React.useEffect(() => {
    return () => {
      timer.stop()
    }
  }, [])

  const choicesToRender = React.useMemo(() => {
    const result = showsMore ? restChoices : choices
    if (!searchString?.length) return result
    return result.filter(choice => {
      const loweredSearch = searchString.toLocaleLowerCase().split(" ")
      const loweredChoice = choice.body?.toLocaleLowerCase()
      return loweredSearch.every(s => loweredChoice?.includes(s))
    })
  }, [searchString, showsMore, restChoices, choices])
  const showMoreButton = !showsMore && !!restChoices.length

  const submitButtonCSS = classnames("lb-user-input-inline-picker-submit-button", {
    "lb-user-input-inline-picker-button-multi-select": !selectedChoices.length,
    "lb-user-input-inline-picker-button-multi-select-active": selectedChoices.length
  })

  return (
    <div className="lb-user-input-inline-picker-container" data-testid="inlinePicker-input">
      <div className="lb-user-input-inline-picker-choices-scroll-container">
        <div className="lb-user-input-inline-picker-content">
          {choicesToRender.map((item: ISelectable, i) => {
            const { backgroundColor, color } = item
            const isSelected = selectedChoices.find(choice => choice.body === item.body)
            const className = classnames({
              "lb-user-input-inline-picker-button-multi-select":
                multiSelect && !isSelected && !item.selectIndividually,
              "lb-user-input-inline-picker-button-multi-select-active": multiSelect && isSelected
            })
            return (
              <InlineButton
                key={i}
                btn={item}
                style={{ backgroundColor: backgroundColor || config.userMessageBackground }}
                textStyle={color ? { color } : undefined}
                fullWidth={item.fullWidth}
                onSelect={onItemPress}
                buttonClassName={className}
              />
            )
          })}
          {showMoreButton && (
            <InlineButton btn={{ body: app.t("More 👉") }} onSelect={onMorePress} />
          )}
          {showsMore && <InlineButton btn={{ body: app.t("👈 Back") }} onSelect={onBackPress} />}
        </div>
      </div>
      {multiSelect && (
        <InlineButton //
          fullWidth
          style={{ backgroundColor: config.userMessageBackground }}
          btn={{ body: app.t("Submit") }}
          onSelect={onSubmitMultipleSelectionPress}
          buttonClassName={submitButtonCSS}
        />
      )}
      {((!!textPrompt && !multiSelect) || searchable) && (
        <TextInput
          borderless
          clearOnSubmit
          trimAllSpacesOnSubmit={textPrompt?.trimAllSpacesOnSubmit}
          trimAllSpacesOnValidation={textPrompt?.trimAllSpacesOnValidation}
          value={textPrompt?.value}
          placeholder={textPrompt?.placeholder}
          cancelLabel={textPrompt?.cancelLabel}
          forceValue={!!textPrompt?.forceValue}
          validation={textPrompt?.validation}
          validationExplainer={textPrompt?.validationExplainer}
          cancelIsEmptySubmit={!!textPrompt?.cancelIsEmptySubmit}
          onChangeText={searchable ? text => setSearchString(text) : undefined}
          onSubmit={searchable ? undefined : onCustomChoiceSubmit}
        />
      )}
      {!!emailPrompt && !multiSelect && (
        <EmailInput
          placeholder={emailPrompt?.placeholder || app.t("Enter your Email")}
          onSubmit={onEmailSubmit}
        />
      )}
      {!!phonePrompt && !multiSelect && (
        <PhoneNumberInput
          cancelLabel={phonePrompt.cancelLabel}
          cancelIsEmptySubmit={phonePrompt.cancelIsEmptySubmit}
          skipValue={phonePrompt.skipValue}
          forceLandline={phonePrompt.forceLandline}
          forceMobile={phonePrompt.forceMobile}
          placeholder={phonePrompt.placeholder}
          onSubmit={onPhoneNumberSubmit}
        />
      )}
    </div>
  )
}

export default observer(InlinePicker)
