import delay from "../../../../utils/delay"
import type Script from "../../Script"
import type { ScriptState } from "../../Script"
import type { IStep } from "../../models/IStep"
import type { IStepData, IStepResult, StepDecorator, StepDescriptor } from "../../models/IStep"

function decorate<State extends ScriptState, S extends Script<State> = any>(
  step: IStep<State>,
  seconds: number
): IStep<State> {
  return async function (this: S, d: IStepData<State>): Promise<IStepResult> {
    await delay(seconds)
    return step.call(this, d)
  }
}

export function delayStep<State extends ScriptState, S extends Script<State> = any>(
  seconds: number
): StepDecorator<State, S> {
  return function (_: S, __: keyof S, desc: StepDescriptor): StepDescriptor {
    const org = desc.value
    desc.value = decorate(org!, seconds)
    return desc
  }
}

delayStep.decorate = decorate
