import React from "react"
import "./PoweredByLimbic.scss"

export default function PoweredByLimbic(): JSX.Element {
  return (
    <li className="lb-powered-by-limbic">
      <img
        src={"https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/limbic-logo.png"}
        alt={"Logo"}
        aria-label="logo"
      />
      Powered by<span>Limbic</span>
    </li>
  )
}
