import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import { PermissionsIAPTScript } from "./PermissionsIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { PermissionsScriptState } from "./PermissionsScript"

interface State extends PermissionsScriptState {
  consentDataShareGPAndNHS?: boolean
}
export type PermissionsEssexScriptState = State

export class PermissionsEssexScript extends PermissionsIAPTScript {
  readonly name: string = "PermissionsEssexScript"

  /** Script Steps */

  @step.logState
  askConsentDataShare(_d: IStepData<State>): IStepResult {
    const organisationName =
      this.rootStore.configStore.organisationName ?? this.rootStore.configStore.serviceName
    return {
      body: this.t(
        "Are you okay with {organisationName} sharing this data with your GP and wider NHS services as needed?",
        { organisationName }
      ),
      prompt: {
        id: this.getPromptId("askConsentDataShare"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleConsentDataShare
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: PermissionsEssexScript) => {
    d.state.consentDataShareGPAndNHS = d.response
    script.referralStore.setCustomField<State>("consentDataShareGPAndNHS", d.response)
  })
  handleConsentDataShare(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      this.setUserDoesNotNeedToCallIn(d.state)
      return { nextStep: this.sayReady }
    }
    this.referralStore.setCustomField("callInReason", "dataConsent")
    this.setUserNeedsToCallIn(d.state)
    return { nextStep: this.sayUserNeedsToCallIn }
  }

  @step.logState
  sayUserNeedsToCallIn(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName

    return {
      body: this.t(
        [
          "Unfortunately we cannot continue with the referral if we cannot share your info with your GP and wider NHS services",
          "Please give {organisationName} a call on 01268 739128/01206 334001 to discuss your referral with a service manager"
        ],
        { organisationName }
      ),
      prompt: {
        id: this.getPromptId("sayUserNeedsToCallIn"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Okay"), value: "okay" },
          { body: this.t("Thanks"), value: "thanks" }
        ]
      },
      nextStep: this.goToGoodbye
    }
  }

  /** General Handlers */

  async onHandleIUnderstandTerms(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askConsentDataShare }
  }
}

/* istanbul ignore next */
export default class PermissionsEssexDialogue extends Dialogue<State> {
  static id = DialogueIDs.PermissionsEssex
  readonly name: string = "PermissionsEssexDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PermissionsEssexDialogue.id, new PermissionsEssexScript(), state, snapshot)
  }
}
