import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import { EligibilityCheckWithPDSScript } from "./EligibilityCheckWithPDSScript"
import type { EligibilityCheckIAPTState } from "./EligibilityCheckIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { IneligibilityReason } from "@limbic/types"

type State = EligibilityCheckIAPTState

export type EligibilityCheckSABPScriptState = State

export class EligibilityCheckSABPScript extends EligibilityCheckWithPDSScript {
  readonly name: string = "EligibilityCheckSABPScript"

  /** Script Steps */

  @step.logState
  startEligibilityCheck(d: IStepData<State>): IStepResult {
    return { nextStep: this.askAreYouInCrisis }
  }

  @step.logState
  askAreYouInCrisis(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Are you in need of immediate help because you are in a Mental Health crisis and feel unable to keep either yourself or someone else safe?"
      ),
      prompt: {
        id: this.getPromptId("askAreYouInCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askAreYouInCrisis"
      },
      nextStep: this.handleAreYouInCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.canKeepSelfSafe = d.response
  })
  handleAreYouInCrisis(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      this.trackUserAsIneligible(d.state, IneligibilityReason.REQUIRES_URGENT_SUPPORT)
    }
    return {
      nextStep: d.response //
        ? this.sayCrisis
        : this.continueEligibilityCheck
    }
  }

  @step.logState
  sayCrisis(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t(
        [
          "{name}, you have indicated you are in crisis and need urgent help. You can visit ths [link](https://www.sabp.nhs.uk/our-services/advice-guidance/getting-help-crisis) to find out more about the options you have.",
          "If you need urgent, life threatening medical help please call [999](tel:999)",
          "Alternatively, you can call the Mental Health Crisis Helpline on [0800 915 4644](tel:08009154644) if it's not a life-threatening situation"
        ],
        { name }
      ),
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("I understand") }, { body: this.t("Okay") }]
      },
      nextStep: this.handleSayCrisis
    }
  }

  @step.logState
  handleSayCrisis(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "999 and Mental Health Crisis Helpline (0800 915 4644)"
    )
    return { nextStep: this.goToGoodbye }
  }

  @step.logState
  continueEligibilityCheck(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askPostCodeOfUser }
  }

  @step.logState
  sayWeDontSupportYourGP(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    return {
      body: this.t("Unfortunately, {iaptName} doesn't support your GP", { iaptName }),
      nextStep: this.closeWithCallIntoService
    }
  }

  @step.logState
  sayCallIntoService(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const url = "https://www.mindmattersnhs.co.uk/how-self-refer"
    return {
      body: this.t(
        [
          "I'm just a humble robot. My only goal is to help you. Sorry I wasn't able to do that on this occasion",
          "Don't worry though - I have a lot of human colleagues at {organisationName} who are ready to help you!",
          "Please give our services a call on 0300 330 5450, or click [here]({url}) for more info on how to self refer into {organisationName}"
        ],
        { url, organisationName }
      ),
      nextStep: this.goToGoodbye
    }
  }

  /** Generic Handlers */

  getNeedsToSelfReferManually(state: State): boolean {
    return false
  }

  async onIneligibleGPForIAPTFound(state: State): Promise<IStepResult<State> | void> {
    this.setEligibility(state, false)
    return { nextStep: this.sayWeDontSupportYourGP }
  }
}

/* istanbul ignore next */
export default class EligibilityCheckSABPDialogue extends Dialogue<State> {
  static id = DialogueIDs.EligibilityCheckSABP
  readonly name: string = "EligibilityCheckSABPDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(EligibilityCheckSABPDialogue.id, new EligibilityCheckSABPScript(), state, snapshot)
  }
}
