import Persistable from "../../models/Persistable"
import { addMessage, createLLMThread, getLLMResponse } from "../../backend/api/limbic/llm"
import type Syncable from "../../models/Syncable"

export default class LLMStore extends Persistable implements Syncable {
  readonly name: string = "LLMStore"

  threadId?: string

  constructor() {
    super()
    this.threadId = this.hydrate("threadId")
  }

  rehydrate(): void {
    this.threadId = this.hydrate("threadId")
  }

  setThreadID(threadId: string): void {
    this.threadId = threadId
    this.persist("threadId", threadId)
  }

  async createLLMThreadId(): Promise<void> {
    const threadId = await createLLMThread()
    if (threadId) this.setThreadID(threadId)
  }

  async addMessage(message: string): Promise<void> {
    if (!this.threadId) await this.createLLMThreadId()
    if (!this.threadId) return
    await addMessage(this.threadId, message)
  }

  async getLLMResponse(message?: string): Promise<string[] | undefined> {
    if (message) await this.addMessage(message)
    if (!this.threadId) return
    return await getLLMResponse(this.threadId, message)
  }
}
