import Dialogue, { IDialogueSnapshot } from "../../backend/chatbot/Dialogue"
import { step } from "../../backend/chatbot/decorators/step"
import BaseScript from "../BaseScript"
import { TrackingEvents } from "../../models/Constants"
import { DialogueIDs } from "../DialogueIDs"
import type { IInlinePickerSingleSelectPrompt } from "../../backend/chatbot/models/IPrompt"
import type { SurveyScriptState } from "../createSurveyDialogue"
import type { IStepData, IStepResult } from "../../backend/chatbot/models/IStep"
import { Category } from "@limbic/crisis-detection"

type State = SurveyScriptState

export class IAPTMedicationScript extends BaseScript<State> {
  readonly name: string = "IAPTMedicationScript"

  /** Script Steps */

  @step
  start(_d: IStepData<State>): IStepResult<State> {
    this.timeEvent(`${this.name} Finished`)
    return { nextStep: this.askAreYouTakingMedication }
  }

  @step
  end(d: IStepData<State>): IStepResult {
    this.track(`${this.name} Finished`)
    return super.end(d)
  }

  @step
  askAreYouTakingMedication(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("May I ask if you are currently taking any sort of medication?"),
      prompt: {
        id: this.getPromptId("askAreYouTakingMedication"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("I am taking medication"), value: true },
          { body: this.t("I am not taking medication"), value: false }
        ],
        dataPointsName: "askAreYouTakingMedication"
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleAreYouTakingMedication
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "1",
    "Are you currently taking any sort of medication?",
    "medicationResponses",
    (r: boolean) => (r ? "I am taking medication" : "I am not taking medication")
  )
  handleAreYouTakingMedication(d: IStepData<State, boolean>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.9)
    if (d.response) {
      return { nextStep: this.askNamesOfMedication }
    }
    return { nextStep: this.askPrescribedButNotTaking }
  }

  @step
  askNamesOfMedication(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Okay, please type the names of all the medication you are taking below"),
      prompt: {
        id: this.getPromptId("askNamesOfMedication"),
        type: "text",
        forceValue: true,
        dataPointsName: "askNamesOfMedication"
      },
      nextStep: this.handleNamesOfMedicationWithCrisis
    }
  }

  @step
  returnToAskNamesOfMedication(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("So you said you're taking medication"),
      nextStep: this.askNamesOfMedication
    }
  }

  @step
  @step.saveResponse<State>(
    "2a",
    "Type the names of all the medication you are taking",
    "medicationResponses"
  )
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    ignoredCategories: [Category.Medication],
    getNextStep: (s: IAPTMedicationScript) => s.returnToAskNamesOfMedication
  })
  handleNamesOfMedicationWithCrisis(_d: IStepData<State, string>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.95)
    return { body: "Thanks", nextStep: this.askPrescribedMedication }
  }

  @step
  askPrescribedMedication(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "And just for my own understanding, is the medication you're taking prescribed?"
      ),
      prompt: {
        id: this.getPromptId("askPrescribedMedication"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false },
          { body: this.t("Some are, some aren't"), value: "some" }
        ],
        dataPointsName: "askPrescribedMedication"
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handlePrescribedMedication
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "2b",
    "Is the medication you're taking prescribed?",
    "medicationResponses",
    (r: boolean | "some") => (r === "some" ? "Some are, some aren't" : r ? "Yes" : "No")
  )
  handlePrescribedMedication(d: IStepData<State, boolean | "some">): IStepResult {
    if (d.response === "some") {
      this.track(TrackingEvents.MEDICATION_PRESCRIBED_BOTH)
    } else if (d.response) {
      this.track(TrackingEvents.MEDICATION_PRESCRIBED)
    } else {
      this.track(TrackingEvents.MEDICATION_NOT_PRESCRIBED)
    }
    this.rootStore.applicationStore.setCurrentProgress(1)
    return { nextStep: this.end }
  }

  @step
  askPrescribedButNotTaking(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Okay, and just to check, have you been prescribed any medication that you are not taking?"
      ),
      prompt: {
        id: this.getPromptId("askPrescribedButNotTaking"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askPrescribedButNotTaking"
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handlePrescribedButNotTaking
    }
  }

  @step
  @step.saveResponse<State>(
    "3a",
    "Have you been prescribed any medication that you are not taking?",
    "medicationResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  handlePrescribedButNotTaking(d: IStepData<State>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.95)
    if (d.response) {
      return { nextStep: this.askNamesOfPrescribedButNotTaking }
    }
    this.track(TrackingEvents.NO_MEDICATION_NOT_PRESCRIBED)
    this.rootStore.applicationStore.setCurrentProgress(1)
    return { nextStep: this.end }
  }

  @step
  askNamesOfPrescribedButNotTaking(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Okay, please type the names of medication you have been prescribed but are not taking"
      ),
      prompt: {
        id: this.getPromptId("askNamesOfPrescribedButNotTaking"),
        type: "text",
        forceValue: true,
        dataPointsName: "askNamesOfPrescribedButNotTaking"
      },
      nextStep: this.handleNamesOfPrescribedButNotTakingWithCrisis
    }
  }

  @step
  returnToAskNamesOfPrescribedButNotTaking(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("So you said you have been prescribed medication that you're not taking"),
      nextStep: this.askNamesOfPrescribedButNotTaking
    }
  }

  @step
  @step.saveResponse<State>(
    "3b",
    "Type the names of medication you have been prescribed but are not taking",
    "medicationResponses"
  )
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    getNextStep: (s: IAPTMedicationScript) => s.returnToAskNamesOfPrescribedButNotTaking,
    ignoredCategories: [Category.Medication]
  })
  handleNamesOfPrescribedButNotTakingWithCrisis(_d: IStepData<State, string>): IStepResult {
    this.track(TrackingEvents.NO_MEDICATION_PRESCRIBED)
    this.rootStore.applicationStore.setCurrentProgress(1)
    return { nextStep: this.end }
  }
}

export default class IAPTMedicationDialogue extends Dialogue<State> {
  static id = DialogueIDs.IAPTMedication
  readonly name: string = "IAPTMedicationDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(IAPTMedicationDialogue.id, new IAPTMedicationScript(), state, snapshot)
  }
}
