import { AssessmentPitchIAPTScript } from "./AssessmentPitchIAPTDialogue"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../DialogueIDs"
import type { AssessmentPitchIAPTScriptState } from "./AssessmentPitchIAPTDialogue"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { IStep } from "../../../backend/chatbot/models/IStep"
import { ICollectMainIssueSettings } from "../ad-hoc/CollectMainIssue/CollectMainIssueDialogue"
import { ICollectEmailSettings } from "../ad-hoc/CollectEmail/CollectEmailDialogue"

type State = AssessmentPitchIAPTScriptState
export type AssessmentPitchHealixState = State

export class AssessmentPitchHealixScript extends AssessmentPitchIAPTScript {
  readonly name: string = "AssessmentPitchHealixScript"

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    this.updateReferralType(d.state)
    return {
      nextStep: this.sayIntro
    }
  }

  @step.logState
  sayIntro(d: IStepData<State>): IStepResult {
    this.updateReferralType(d.state)
    return {
      body: this.t(
        "I've been designed by therapists to give you a free digital mental health assessment"
      ),
      prompt: {
        id: this.getPromptId("sayIntro"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Okay"), value: true },
          { body: this.t("Tell me more"), value: false }
        ]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleIntro
    }
  }

  @step.logState
  async handleIntro(d: IStepData<State, boolean>): Promise<IStepResult> {
    return {
      body: d.response ? undefined : this.t("Certainly"),
      nextStep: this.sayWeCanSpeedUpReferral
    }
  }

  @step.logState
  sayWeCanSpeedUpReferral(d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "I'm now going to ask you a few questions to measure symptoms of common mental illness",
        "I'll then share the results with you and help you find the best support options available",
        "Sounds ok?"
      ]),
      prompt: {
        id: this.getPromptId("sayWeCanSpeedUpReferral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("Help me decide"), value: false }
        ]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleWeCanSpeedUpReferral
    }
  }

  @step.logState
  async handleWeCanSpeedUpReferral(d: IStepData<State>): Promise<IStepResult> {
    const name = this.getName(d.state)
    if (d.response) {
      return {
        body: this.t("Good to hear it, {name}", { name }),
        nextStep: this.goToCollectPhoneNumber
      }
    }
    return { nextStep: this.explainMore }
  }

  @step.logState
  explainMore(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t(
        [
          "Of course. Mental wellbeing is a complicated topic. There are no quick fixes",
          "But trust me when I say that this is the first step towards a solution",
          "You're at the beginning of an evidence-based pathway that has been shown to help people with a range of common mental health issues",
          "I think this is worth doing now that you're here, {name}"
        ],
        { name }
      ),
      prompt: {
        id: this.getPromptId("explainMore"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }, { body: this.t("Go on then") }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleExplainMore
    }
  }

  @step.logState
  handleExplainMore(_d: IStepData<State>): IStepResult {
    return { body: this.t("👍"), nextStep: this.goToCollectPhoneNumber }
  }

  /** Generic Handlers */

  async onCollectPhoneNumberEnded(state: State): Promise<IStep | void> {
    return this.goToCollectEmail
  }

  async getCollectEmailSettings(state: State): Promise<ICollectEmailSettings> {
    return {
      messages: {
        askDoYouWantToShareEmail: this.t([
          "It's optional, but you can also share your email with me if you like to be contacted this way?"
        ])
      },
      hasPreliminaryQuestion: true,
      customisableOptions: {
        askDoYouWantToShareEmail: [
          { body: this.t("Yes, I'd like to be contacted by email"), value: true },
          { body: this.t("No, just the phone number is fine"), value: false }
        ]
      }
    }
  }

  async onCollectEmailEnded(state: State): Promise<IStep> {
    return this.goToCollectMainIssue
  }

  async getCollectMainIssueSettings(state: State): Promise<ICollectMainIssueSettings> {
    const name = this.getName(state)
    return {
      messages: {
        askMainIssue: this.t(
          [
            "So {name}, please could you describe the main concern or problem that brought you here today (be sure to include specific feelings, behaviours, or thoughts that are bothering you)"
          ],
          { name }
        ),
        closingMessage: this.t(["Thank you for sharing {name}, you've come to the right place"], {
          name
        })
      }
    }
  }

  async onCollectMainIssueEnded(state: State): Promise<IStep> {
    return this.end
  }
}

/* istanbul ignore next */
export default class AssessmentPitchHealixDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentPitchHealix
  readonly name: string = "AssessmentPitchHealixDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentPitchHealixDialogue.id, new AssessmentPitchHealixScript(), state, snapshot)
  }
}
