import { step } from "../../../backend/chatbot/decorators/step"
import SelfReferralScript, {
  SelfReferralScriptState,
  SelfReferralScriptStateSchema
} from "./SelfReferralScript"
import sendRiskEmail from "../../../backend/api/limbic/sendRiskEmail"
import { TrackingEvents } from "../../../models/Constants"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"
import type { ZodSchema } from "zod"

type State = SelfReferralScriptState
export type SelfReferralIAPTScriptState = State

export const SelfReferralIAPTScriptStateSchema = SelfReferralScriptStateSchema.extend({})

export default abstract class SelfReferralIAPTScript extends SelfReferralScript {
  /** Script Steps */

  @step.logState
  @step.setState<State>({ addressLookupCounter: 0 })
  sayIntro(d: IStepData<State>): IStepResult {
    this.timeEvent(this.name)
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    return {
      body: this.t("I'm now going to add you to {iaptName} as a new referral", { iaptName }),
      prompt: {
        id: this.getPromptId("I'll add your referral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Great") }, { body: this.t("Okay") }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.askWannaDoSelfReferral
    }
  }

  @step
  askWannaDoSelfReferral(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "There are just a few more details I need from you",
        "This should take no more than 5 minutes"
      ]),
      prompt: {
        id: this.getPromptId("askWannaDoSelfReferral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Sure") }, { body: this.t("Okay") }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.startSelfReferralPart1
    }
  }

  @step.logState
  sayReferralSucceeded(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    return {
      body: this.t(
        [
          "And that's everything",
          "You've officially been referred to {iaptName}",
          "Congratulations on taking this important step towards better mental health!"
        ],
        { iaptName }
      ),
      prompt: {
        id: this.getPromptId("sayReferralSucceeded"),
        type: "inlinePicker",
        choices: [{ body: this.t("What happens next?") }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.end
    }
  }

  @step.logState
  sayReferralFailed(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    const url = d.state.iapt?.referralForm?.form_url
    return {
      body: this.t(
        [
          "Oops... I'm really sorry about this, but it seems like something has gone wrong when trying to submit your data to {iaptName}",
          "I've notified my creators of this issue",
          "If you don't wish to wait, you can manually refer yourself by following this link [here]({url})"
        ],
        { url, iaptName }
      ),
      prompt: {
        id: this.getPromptId("sayReferralFailed"),
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.goToGoodbye
    }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return SelfReferralIAPTScriptStateSchema
  }

  getEthnicities(state: State): string[] {
    return state.iapt?.referralForm?.ethnicities ?? []
  }

  getNationalities(state: State): string[] {
    return state.iapt?.referralForm?.nationalities ?? []
  }

  getReligions(state: State): string[] {
    return state.iapt?.referralForm?.religions ?? []
  }

  getGenders(state: State): string[] {
    return state.iapt?.referralForm?.genders ?? []
  }

  getGenderSameAsBirthValues(state: State): string[] {
    return state.iapt?.referralForm?.sameGenderAsBirth ?? []
  }

  getPerinatalStatuses(state: State): string[] {
    return state.iapt?.referralForm?.perinatalStatuses ?? []
  }

  getLanguages(state: State): string[] {
    return state.iapt?.referralForm?.languages ?? []
  }

  getDisabilities(state: State): string[] {
    return state.iapt?.referralForm?.disabilities ?? []
  }

  getExArmedForcesValues(state: State): string[] {
    return state.iapt?.referralForm?.exArmedForces ?? []
  }

  getSexualities(state: State): string[] {
    return state.iapt?.referralForm?.sexualities ?? []
  }

  getMedicalConditions(state: State): string[] {
    return state.iapt?.referralForm?.LTCs ?? []
  }

  // TODO: this doesn't have proper error handling
  async onRiskReferralFinished(state: State): Promise<void> {
    if (state.iapt?.riskEmails && state.iapt?.riskEmails.length) {
      try {
        const dutyEmails = state.iapt?.riskEmails || []
        const dutyEmailsCC = state.iapt?.riskEmailsCC || []
        const dutyEmailsBCC = state.iapt?.riskEmailsBCC || []
        const text = this.createReferralEmail(state, true)
        await sendRiskEmail({
          to: dutyEmails,
          cc: dutyEmailsCC.length ? dutyEmailsCC : undefined, // This needs to be undefined in case of empty array because mailgun is stupid
          bcc: dutyEmailsBCC.length ? dutyEmailsBCC : undefined, // This needs to be undefined in case of empty array because mailgun is stupid
          text
        })
      } catch (e) {
        this.logException(e, "onRiskReferralFinished -> sendEmail")
      }
    } else {
      this.logMessage(TrackingEvents.RISK_EMAIL_SKIPPED)
      this.track(TrackingEvents.RISK_EMAIL_SKIPPED)
    }
  }
}
