import { IIAPTBotService } from "@limbic/types"
import { iapts } from "../config/iapts"

export default function customIAPTSHaveError(
  dashboardServiceKey: string | undefined,
  customIAPTS: IIAPTBotService[] | undefined,
  eligibleIAPTIds: string[]
): boolean {
  if (!dashboardServiceKey) return false
  const hasIAPTIDInHardcodedIAPTS = Object.keys(iapts).some(iaptID =>
    eligibleIAPTIds.includes(iaptID)
  )
  if (hasIAPTIDInHardcodedIAPTS) return false

  if (customIAPTS?.length) return false

  return true
}
