import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import { RiskLevelReason } from "../../../models/Constants"
import sendRiskEmail from "../../../backend/api/limbic/sendRiskEmail"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

const shouldSendKeepingSafe = process.env.REACT_APP_SHOULD_SEND_KEEPING_SAFE === "enabled"

// TODO: Missing emails + May not be needed - check with Ben
const GM_HUB_RISK_EMAILS =
  process.env.REACT_APP_BACKEND_ENV === "production"
    ? []
    : ["andreas+gmhub+risk@limbic.ai", "john+gmhub+risk@limbic.ai", "zsuzsa+gmhub+risk@limbic.ai"]

type State = RiskPathwayScriptState

export type RiskPathwayGMHubScriptState = State

export class RiskPathwayGMHubScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayGMHubScript"

  /** Script Steps */
  async start(d: IStepData<State>): Promise<IStepResult<State>> {
    if (d.state.email && shouldSendKeepingSafe) {
      await this.sendKeepingSafeEmail(d.state)
    }
    this.rootStore.applicationStore.setCurrentProgress(0.88)
    return { nextStep: this.step1 }
  }

  @step.logState
  step1(d: IStepData<State>): IStepResult {
    return { nextStep: this.askCanYouKeepYourselfSafe }
  }

  @step.logState
  askCanYouKeepYourselfSafe(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Are you able to keep yourself, and any dependants in your care, safe until the service contacts you (within 5 working days time)?"
      ),
      prompt: {
        id: this.getPromptId("askCanYouKeepYourselfSafe"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askCanYouKeepYourselfSafe"
      },
      nextStep: this.handleCanYouKeepYourselfSafe
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.saveResponse<State>(
    "10",
    "Are you able to keep yourself, and any dependants in your care, safe until the service contacts you (within 5 working days)?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.canKeepSelfSafe = d.response
  })
  handleCanYouKeepYourselfSafe(d: IStepData<State>): IStepResult {
    if (!d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.CANNOT_KEEP_SELF_SAFE)
      return { nextStep: this.informRiskToService }
    }
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  async informRiskToService(d: IStepData<State>): Promise<IStepResult> {
    // TODO: WE MAY NOT NEED TO SEND RISK EMAIL - CHECK WITH BEN
    // await this.sendRiskEmailToService(d.state)
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  sayCrisis(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const serviceName = this.rootStore.configStore.serviceName

    return {
      body: this.t(
        [
          "Sorry to hear that {name}",
          "It is normal for people to have thoughts of this nature at times",
          "However, this is not an emergency response service",
          "There are alternative services that are better placed to support you",
          "If you need urgent, life threatening medical help please call [999](tel:999)",
          "One of {serviceName}'s team will contact you within 5 working days to check that you have referred yourself in to one of the crisis support services"
        ],
        { name, serviceName }
      ),
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("I understand") }, { body: this.t("Okay") }],
        dataPointsName: "sayCrisis"
      },
      nextStep: this.sayInstructions
    }
  }

  async sayInstructions(_d: IStepData<State>): Promise<IStepResult> {
    return {
      body: this.t([
        "If you are experiencing a mental health crisis please to go the [NHS Getting Help page](https://www.nhs.uk/mental-health/feelings-symptoms-behaviours/behaviours/help-for-suicidal-thoughts/)",
        "You can also contact Papyrus on 0800 0684141 (call), 07860 039967 (text) or [pat@papyrus-uk.org](pat@papyrus-uk.org) if you're under 35"
      ]),
      prompt: {
        id: this.getPromptId("sayInstructions"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }]
      },
      nextStep: this.handleSayAdditionalInstructions
    }
  }

  @step.logState
  async handleSayAdditionalInstructions(_d: IStepData<State>): Promise<IStepResult> {
    this.referralStore.setCustomField("crisisLinksShared", "NHS Getting Help and Papyrus")
    return { nextStep: this.end }
  }

  /** Generic Handlers */

  async onSaveRiskLevelAndReferralType(state: State): Promise<IStepResult> {
    return { nextStep: !state.canKeepSelfSafe ? this.sayCrisis : this.end }
  }

  // TODO: We may need to remove this
  async sendRiskEmailToService(state: State): Promise<void> {
    try {
      if (!this.clinicalStore.isRisk) return
      const emails = GM_HUB_RISK_EMAILS || []
      if (emails?.length) {
        const text = this.createReferralEmail(state, true)
        await sendRiskEmail({
          to: emails,
          text
        })
      }
    } catch (e) {
      this.logException(e, "sendRiskEmailToService")
    }
  }

  createKeepingSafeLeafletEmail(title: string, logo: string): string {
    return `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" lang='en'>
<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
    <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
    <title>Limbic Therapy Assistant | Keeping Safe</title>
    <style type="text/css">
        #outlook a {
            padding: 0;
        }

        body {
            width: 100% !important;
            -webkit-text-size-adjust: 100%;
            -ms-text-size-adjust: 100%;
            margin: 0;
            padding: 0;
            font-family: sans-serif;
        }

        .ExternalClass {
            width: 100%;
        }

        .ExternalClass, .ExternalClass p, .ExternalClass span, .ExternalClass font, .ExternalClass td, .ExternalClass div {
            line-height: 100%;
        }

        #backgroundTable {
            margin: 0;
            padding: 0;
            width: 100% !important;
            line-height: 100% !important;
        }

        #contentTable {
            margin: 64px auto;
            max-width: 800px !important;
            line-height: 100% !important;
        }

        img {
            outline: none;
            text-decoration: none;
            -ms-interpolation-mode: bicubic;
        }

        a img {
            border: none;
        }

        .image_fix {
            display: block;
        }

        p {
            margin: 1em 0;
        }

        h1, h2, h3, h4, h5, h6 {
            color: black !important;
        }

        h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
            color: blue !important;
        }

        h1 a:active, h2 a:active, h3 a:active, h4 a:active, h5 a:active, h6 a:active {
            color: red !important;
        }

        h1 a:visited, h2 a:visited, h3 a:visited, h4 a:visited, h5 a:visited, h6 a:visited {
            color: purple !important;
        }

        table td {
            padding-left: 24px;
            padding-right: 24px;
            border-collapse: collapse;
        }

        table {
            border-collapse: collapse;
            mso-table-lspace: 0;
            mso-table-rspace: 0;
        }

        a {
            color: orange;
        }

        h1, h2, h3, a {
            color: #4AA4CB !important;
        }

        h1 {
            text-align: center;
        }

        .red {
            color: red;
        }

        .blue {
            color: #4AA4CB !important;
            font-weight: bold;
        }

        .big {
            font-size: 21px;
            font-weight: bold;
        }

        .image_fix {
            margin-left: auto;
            margin-right: auto;
        }
    </style>
    <!--[if IEMobile 7]>
    <style type="text/css">
        /* Targeting Windows Mobile */
    </style>
    <![endif]-->
    <!--[if gte mso 9]>
    <style>
        /* Target Outlook 2007 and 2010 */
    </style>
    <![endif]-->
</head>
<body>
<table cellpadding="0" cellspacing="0" border="0" id="backgroundTable">
    <tr>
        <td>
            <table cellpadding="0" cellspacing="0" border="0" id="contentTable">
                <tr>
                    <td><img class="image_fix" src="${logo}" alt="${title}" title="${title}" width="x" height="x"/><br/><br/>
                    </td>
                </tr>
                <tr>
                    <td>
                        Thank you for taking part in our emotional wellbeing screening programme. Your scores suggest
                        you are struggling with some difficult emotions and thoughts. This is understandable given the
                        events you have been experiencing. We will therefore aim to call you on the number you have
                        provided order to discuss how you are feeling and coping in more detail and to ensure that you
                        are getting the help that you need. Alternatively, you can call us at a time that suits
                        you.<br/><br/>
                        Please contact us by telephone or email:<br/>
                        <ul>
                            <li>Telephone number: <a href="tel:+443330095071">0333 009 5071</a></li>
                            <li>Email address: GM.help@nhs.net</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>
                        If we are unable to answer your call immediately, you will have the option of leaving a message
                        and we will call you back as soon as possible. You can also leave a message outside of our
                        opening hours on this number.<br/><br/>
                    </td>
                </tr>
                <tr>
                    <td>
                        <h4><u>If you are really struggling now:</u></h4>
                        <ul>
                            <li>You can contact your GP</li>
                            <li>You can contact the Samaritans <a href="tel:116123">116 123</a></li>
                            <li>You can contact Papyrus (under 35’s) <a href="tel:+448000684141">0800 0684141</a>, <a
                                    href="mailto:pat@papyrus-uk.org">pat@papyrus-uk.org</a>, text: <a
                                    href="tel:+447860039967">07860 039967</a></li>
                            <li>If you are at immediate risk of harm, you can go to your local emergency department</li>
                        </ul>
                        <br/>
                    </td>
                </tr>
                <tr>
                    <td>
                        If you need help accessing these services, we can support you if you contact us within our
                        opening hours.<br/><br/>
                        Please find below links to a variety of resources that you may find useful.<br/><br/><br/>
                        With best wishes,<br/>
                        The Greater Manchester Resilience Hub Team<br/><br/><br/><br/>
                    </td>
                </tr>
                <tr>
                    <td>
                        <h4>Where to go for support:</h4>
                        <ul>
                            <li>Greater Manchester Health & Social Care Partnership COVID-19 wellbeing resources</li>
                            <li>Mental health service in GM</li>
                            <li>Community Hubs across GM</li>
                            <li>GM bereavement service</li>
                        </ul>
                    </td>
                <tr>
                    <td>
                        You can self-refer to your local psychological therapy service <a
                            href="https://www.nhs.uk/service-search/mental-health/find-a-psychological-therapies-service/">here</a>.
                    </td>
                </tr>
                <tr>
                    <td>
                        If you are experiencing a mental health crisis you can find more help <a
                            href="http://www.nhs.uk/Conditions/Suicide/Pages/Getting-help.aspx">here</a>.
                    </td>
                </tr>
                <tr>
                    <td>
                        If you are employed by health and social care or emergency services, you can also access <a
                            href="https://www.mentalhealthatwork.org.uk/ourfrontline/ ">Our Frontline</a>.
                    </td>
                </tr>
                <tr>
                    <td>
                        You could also contact your employer for details of their occupational health offer and employee
                        assistance programme if they have one.
                    </td>
                </tr>
            </table>
        </td>
    </tr>
</table>
</body>
</html>`
  }
}

export default class RiskPathwayGMHubDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayGMHub
  readonly name: string = "RiskPathwayGMHubDialogue"

  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(RiskPathwayGMHubDialogue.id, new RiskPathwayGMHubScript(), state, snapshot)
  }
}
