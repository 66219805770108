import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { PermissionsScriptState } from "./PermissionsScript"
import { PermissionsScript, PermissionsScriptStateSchema } from "./PermissionsScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { ZodType } from "zod"

type State = PermissionsScriptState
export type PermissionsIAPTScriptState = State

export const PermissionsIAPTScriptStateSchema = PermissionsScriptStateSchema.extend({})

export class PermissionsIAPTScript extends PermissionsScript {
  readonly name: string = "PermissionsIAPTScript"

  /** Script Steps */

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "I know that talking about mental health can be difficult",
        "Before I start finding out more about you, I'm going to run you through how we keep your data safe 👇"
      ]),
      nextStep: this.showTOSLinks
    }
  }

  @step.logState
  sayReady(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(["Let's continue", "Ready?"]),
      prompt: {
        id: this.getPromptId("sayReady"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Let's get started") }]
      },
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  getStateSchema(): ZodType | undefined {
    return PermissionsIAPTScriptStateSchema
  }
}

/** istanbul ignore next */
export default class PermissionsIAPTDialogue extends Dialogue<State> {
  static id = DialogueIDs.PermissionsIAPT
  readonly name: string = "PermissionsIAPTDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PermissionsIAPTDialogue.id, new PermissionsIAPTScript(), state, snapshot)
  }
}
