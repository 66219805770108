import moment from "moment"
import { z, ZodSchema } from "zod"
import { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { SelfReferralIAPTScriptState } from "./SelfReferralIAPTScript"
import SelfReferralIAPTScript, { SelfReferralIAPTScriptStateSchema } from "./SelfReferralIAPTScript"
import { step } from "../../../backend/chatbot/decorators/step"
import invariant from "../../../utils/invariant"
import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"
import { DiscussionSteps } from "@limbic/types"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { IStep } from "../../../backend/chatbot/models/IStep"
import {
  CollectMainIssueScriptState,
  ICollectMainIssueSettings
} from "../ad-hoc/CollectMainIssue/CollectMainIssueDialogue"
import { ICollectPreferredCorrespondenceSettings } from "../ad-hoc/CollectPreferredCorrespondence/CollectPreferredCorrespondenceDialogue"
import { ICollectReligionSettings } from "../ad-hoc/CollectReligion/CollectReligionDialogue"
import { ICollectEmailSettings } from "../ad-hoc/CollectEmail/CollectEmailDialogue"

interface State extends SelfReferralIAPTScriptState {
  interpreterLanguage?: string
  preferredTitle?: string
  safeSpace?: boolean
  requiresUrgentSupport?: boolean
  preferredPronouns?: string
  jobCategory?: string
  nationality?: string
  accessibilityConsiderations?:
    | "No impairment"
    | "Visual impairment"
    | "Hearing impairment"
    | "Other"
}

export type SelfReferralWellbeingHubIndividualScriptState = State

export const SelfReferralWellbeingHubIndividualScriptStateSchema =
  SelfReferralIAPTScriptStateSchema.extend({
    interpreterLanguage: z.string().optional(),
    preferredTitle: z.string().optional(),
    safeSpace: z.boolean().optional(),
    requiresUrgentSupport: z.boolean().optional(),
    preferredPronouns: z.string().optional(),
    jobCategory: z.string().optional(),
    nationality: z.string().optional(),
    accessibilityConsiderations: z
      .union([
        z.literal("No impairment"),
        z.literal("Visual impairment"),
        z.literal("Hearing impairment"),
        z.literal("Other")
      ])
      .optional()
  })

export class SelfReferralWellbeingHubIndividualScript extends SelfReferralIAPTScript {
  readonly name: string = "SelfReferralWellbeingHubIndividualScript"

  /** Script Steps */

  @step.logState
  @step.setState<State>({ addressLookupCounter: 0, postcodeLookupCounter: 0 })
  start(_d: IStepData<State>): IStepResult {
    this.timeEvent(this.name)
    return { nextStep: this.askWannaDoSelfReferral }
  }

  @step.logState
  startSelfReferralPart1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askFullName }
  }

  @step
  askFullName(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "What's your full name? ✏️",
        "Feel free to use initials or an alias if preferred"
      ]),
      nextStep: this.showPromptForFullName
    }
  }

  @step
  sayPleaseGiveFullName(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Please enter your name",
        "Feel free to use initials or an alias if preferred"
      ]),
      nextStep: this.showPromptForFullName
    }
  }

  @step.logState
  showPromptForFullName(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("showPromptForFullName"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handleFullNameWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.handleResponse(
    (d: IStepData<State, string>, script: SelfReferralWellbeingHubIndividualScript) => {
      const username = d.response?.trim()
      d.state.username = username
      script.rootStore.applicationStore.setUsername(username)
    }
  )
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    getNextStep: (s: SelfReferralWellbeingHubIndividualScript) => s.sayPleaseGiveFullName
  })
  async handleFullNameWithCrisis(_d: IStepData<State, string>): Promise<IStepResult> {
    return { nextStep: this.checkFullName }
  }

  @step.logState
  async checkFullName(d: IStepData<State>): Promise<IStepResult> {
    if (!d.state.username || d.state.username.trim() === "") {
      return { nextStep: this.sayPleaseGiveFullName }
    }
    return { nextStep: this.askIsPreferredName }
  }

  @step.logState
  askIsPreferredName(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t("Is {name} your preferred first name?", { name }),
      prompt: {
        id: this.getPromptId("askIsPreferredName"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleIsPreferredName
    }
  }

  @step.logStateAndResponse
  async handleIsPreferredName(d: IStepData<State, boolean>): Promise<IStepResult> {
    return {
      nextStep: d.response //
        ? this.sayNiceToMeetYou
        : this.sayPleaseGivePreferredName
    }
  }

  @step
  sayPleaseGivePreferredName(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("What would you like to be called?"),
      nextStep: this.showPromptForPreferredName
    }
  }

  @step.logState
  showPromptForPreferredName(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("showPromptForPreferredName"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handlePreferredNameWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.handleResponse(
    (d: IStepData<State, string>, script: SelfReferralWellbeingHubIndividualScript) => {
      const preferredName = d.response?.trim()
      d.state.preferredName = preferredName
      script.rootStore.applicationStore.setPreferredName(preferredName)
    }
  )
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    getNextStep: (s: SelfReferralWellbeingHubIndividualScript) => s.sayPleaseGivePreferredName
  })
  async handlePreferredNameWithCrisis(_d: IStepData<State, string>): Promise<IStepResult> {
    return { nextStep: this.checkPreferredName }
  }

  @step.logState
  async checkPreferredName(d: IStepData<State>): Promise<IStepResult> {
    if (!d.state.preferredName || d.state.preferredName.trim() === "") {
      return { nextStep: this.sayPleaseGivePreferredName }
    }
    return { nextStep: this.sayNiceToMeetYou }
  }

  @step
  sayNiceToMeetYou(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t("Nice to meet you {name}", { name }),
      prompt: {
        id: this.getPromptId("sayNiceToMeetYou"),
        type: "inlinePicker",
        choices: [{ body: this.t("Nice to meet you too") }]
      },
      nextStep: this.askHowToBeAddressed
    }
  }

  @step
  askHowToBeAddressed(d: IStepData<State>): IStepResult {
    const titles = this.getTitles(d.state)
    return {
      body: this.t("How would you like to be addressed?"),
      prompt: {
        id: this.getPromptId("askHowToBeAddressed"),
        trackResponse: true,
        type: "inlinePicker",
        choices: titles.map(g => ({ body: this.t(g), value: g })),
        dataPointsName: "askHowToBeAddressed"
      },
      nextStep: this.handleHowToBeAddressed
    }
  }

  @step.logStateAndResponse
  async handleHowToBeAddressed(d: IStepData<State, string>): Promise<IStepResult> {
    d.state.preferredTitle = d.response
    this.referralStore.setCustomField<State>("preferredTitle", d.response)
    this.setPeople({ preferredTitle: d.response })
    this.track(d.response)
    return { nextStep: this.askSafeSpace }
  }

  @step
  askSafeSpace(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("First of all, are you in a safe space to provide us with some information?"),
      prompt: {
        id: this.getPromptId("askSafeSpace"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askSafeSpace"
      },
      nextStep: this.handleSafeSpace
    }
  }

  @step.logStateAndResponse
  async handleSafeSpace(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.safeSpace = d.response
    this.referralStore.setCustomField<State>("safeSpace", d.response)
    if (d.response) {
      return { nextStep: this.askBirthday }
    }

    return {
      body: this.t([
        "You can come back to this tab at any time and come back to where you left off. Limbic is available 24/7",
        "Just continue typing to wake me up when you're ready"
      ]),
      prompt: {
        id: this.getPromptId("wakeMeUp"),
        trackResponse: true,
        type: "text",
        placeholder: this.t('Type "Hello Limbic" to wake me up'),
        isUndoAble: false,
        disableCrisis: true
      },
      nextStep: this.askSafeSpace
    }
  }

  @step.logState
  askBirthday(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("What is your date of birth?"),
      nextStep: this.showPromptForBirthday
    }
  }

  @step.logState
  showPromptForBirthday(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("showPromptForBirthday"),
        trackResponse: true,
        type: "date"
      },
      nextStep: this.handleBirthday
    }
  }

  @step
  handleBirthday(d: IStepData<State, number>): IStepResult {
    try {
      const date = moment(d.response)
      invariant(date, "I'm sorry that's not a valid date. Please enter your date of birth")
      invariant(
        date.isValid(),
        "I'm sorry that's not a valid date. Please enter your date of birth"
      )
      invariant(
        date.isBefore(moment()),
        "Hmm… I don’t think humans can time-travel. Can you try and edit your date of birth?"
      )
      invariant(
        date.isAfter(moment("1899-12-31")),
        "Hmm… I don’t think humans live that long. Can you try and edit your date of birth?"
      )
      d.state.birthday = date.toDate().getTime()
      this.setPeople({ age: moment().diff(date, "years") })
    } catch (e) {
      this.logException(e, "handleBirthday")
      return {
        body: this.t(e.message),
        nextStep: this.showPromptForBirthday
      }
    }

    return {
      body: this.t("Thanks for sharing"),
      nextStep: this.checkAgeThresholds
    }
  }

  @step.logState
  checkAgeThresholds(d: IStepData<State>): IStepResult {
    const age = this.getUserAge(d.state)
    const ageThreshold = this.rootStore.configStore.ageThreshold
    if (Number(ageThreshold) > age) {
      this.setUnderAged(d.state, true)
      return { nextStep: this.askCheckAge }
    }
    return { nextStep: this.askRequiresUrgentSupport }
  }

  @step.logState
  askCheckAge(d: IStepData<State>): IStepResult {
    const age = this.getUserAge(d.state)
    const ageThreshold = this.rootStore.configStore.ageThreshold
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: this.t(
        [
          "You have indicated that you are {age} years old, which is under the age of {ageThreshold}",
          "{organisationName} is unable to support individuals under the age of 16",
          "If this was a mistake, please check your date of birth"
        ],
        { age: Math.floor(age), organisationName, ageThreshold }
      ),
      prompt: {
        id: this.getPromptId("askCheckAge"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          {
            body: this.t("Yes, I'm under the age of {ageThreshold}", { ageThreshold }),
            value: true
          },
          { body: this.t("No, I made a mistake"), value: false }
        ],
        dataPointsName: "askCheckAge"
      },
      nextStep: this.handleCheckAge
    }
  }

  handleCheckAge(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.goToUnder16SignPost }
    }
    return { nextStep: this.askBirthday }
  }

  @step.logState
  goToUnder16SignPost(d: IStepData<State>): IStepResult {
    // prettier-ignore
    const UnderageSignPostDialogue = this.discussionStore.getDialogueClass(DiscussionSteps.UnderageSignpost)
    const nextDialogue = UnderageSignPostDialogue
      ? new UnderageSignPostDialogue({ ...d.state } as any)
      : undefined
    return { nextDialogue, nextStep: this.goToGoodbye }
  }

  @step.logState
  askRequiresUrgentSupport(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Before we continue, we need to check if you require urgent support",
        "Do you currently feel at risk of harming yourself, or can't keep yourself safe?"
      ]),
      prompt: {
        id: this.getPromptId("askRequiresUrgentSupport"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: false,
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleRequiresUrgentSupport
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, boolean>, script: SelfReferralWellbeingHubIndividualScript) => {
      d.state.requiresUrgentSupport = d.response
      script.referralStore.setCustomField<State>("requiresUrgentSupport", d.response)
    }
  )
  handleRequiresUrgentSupport(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      this.clinicalStore.setRequiresUrgentSupport(true)
      this.setRiskLevelHigh(d.state, "User said they require urgent support")
    }
    return {
      body: d.response
        ? undefined
        : this.t(
            "Thank you for your response, you're doing really well.  Let's continue with you referral"
          ),
      nextStep: d.response //
        ? this.sayCrisis
        : this.askPreferredPronouns
    }
  }

  @step.logState
  askPreferredPronouns(d: IStepData<State>): IStepResult {
    const pronouns = this.getPronouns(d.state)
    if (!pronouns?.length) {
      this.logBreadcrumb("PRONOUNS NOT FOUND", d.state, { pronouns })
      this.logMessage("PRONOUNS NOT FOUND")
      return { nextStep: this.askWhichJobCategory }
    }
    return {
      body: this.t(["What are your preferred pronouns?"]),
      prompt: {
        id: this.getPromptId("askPreferredPronouns"),
        trackResponse: true,
        type: "inlinePicker",
        choices: pronouns.map(g => ({ body: this.t(g), value: g }))
      },
      nextStep: this.handlePreferredPronouns
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.preferredPronouns = d.response
  })
  handlePreferredPronouns(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askWhichJobCategory }
  }

  @step.logState
  askWhichJobCategory(d: IStepData<State>): IStepResult {
    const jobCategories = this.getJobCategories(d.state)
    if (!jobCategories?.length) {
      this.logBreadcrumb("JOB CATEGORIES NOT FOUND", d.state, { jobCategories })
      this.logMessage("JOB CATEGORIES NOT FOUND")
      return { nextStep: this.askWhichJobCategory }
    }
    return {
      body: this.t(
        "Which of these job categories best describes your role?  This is to help us support you, all staff will be accepted"
      ),
      prompt: {
        id: this.getPromptId("askWhichJobCategory"),
        trackResponse: true,
        type: "inlinePicker",
        choices: jobCategories.map(job => ({ body: this.t(job), value: job })),
        textPrompt: {
          forceValue: false,
          placeholder: this.t("Other (please describe)")
        }
      },
      nextStep: this.handleWhichJobCategory
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.jobCategory = d.response
  })
  handleWhichJobCategory(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.goToCollectPhoneNumber }
  }

  @step.logState
  sayCrisis(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName

    return {
      body: this.t(
        [
          "Sorry to hear that {name}",
          "It is common for people to have thoughts of this nature at times",
          "However {organisationName} is not an emergency response service.  In emergencies always dial 999 or, to contact the Police in a non-emergency, use 101",
          "For urgent mental health support and advice:",
          "Call NHS 111 for physical or mental health emergencies (available 24/7)",
          "Samaritans: call 116 123 (available 24/7)",
          "SHOUT85258: text 'SHOUT' to 85258 (available 24/7)"
        ],
        { name, organisationName }
      ),
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("I understand") }, { body: this.t("Okay") }]
      },
      nextStep: this.handleSayCrisis
    }
  }

  @step.logState
  handleSayCrisis(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "NHS 111, Samaritans, SHOUT85258 and service"
    )
    return { nextStep: this.goToGoodbye }
  }

  @step.logState
  async goToCollectPhoneNumber(d: IStepData<State>): Promise<IStepResult> {
    const result = await super.goToCollectPhoneNumber(d)
    return { ...result, nextStep: this.goToCollectEmail }
  }

  @step.logState
  askAccessibilityConsiderations(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Are there any accessibility considerations we should be aware of before making contact?"
      ),
      prompt: {
        id: this.getPromptId("askAccessibilityConsiderations"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("No impairment"), value: "No impairment" },
          { body: this.t("Visual impairment"), value: "Visual impairment" },
          { body: this.t("Hearing impairment"), value: "Hearing impairment" },
          { body: this.t("Other"), value: "Other" }
        ]
      },
      nextStep: this.handleAccessibilityConsiderations
    }
  }

  @step.logStateAndResponse
  @step.handleResponse(
    (
      d: IStepData<State, "No impairment" | "Visual impairment" | "Hearing impairment" | "Other">
    ) => {
      d.state.accessibilityConsiderations = d.response
    }
  )
  handleAccessibilityConsiderations(
    _d: IStepData<State, "No impairment" | "Visual impairment" | "Hearing impairment" | "Other">
  ): IStepResult {
    return {
      body: this.t([
        "Now we need to ask a few demographic questions",
        "We ask this in order to ensure we are equally supporting all members of our community",
        "It won't take long and all questions are optional"
      ]),
      nextStep: this.goToCollectGender
    }
  }

  @step.logState
  sayReferralSucceeded(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(["Congratulations on taking this important step towards better mental health!"]),
      prompt: {
        id: this.getPromptId("sayReferralSucceeded"),
        type: "inlinePicker",
        choices: [{ body: this.t("What happens next?") }]
      },
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return SelfReferralWellbeingHubIndividualScriptStateSchema
  }

  async onCollectPhoneNumberEnded(state: State): Promise<IStep> {
    return this.goToCollectEmail
  }

  async getCollectEmailSettings(state: State): Promise<ICollectEmailSettings> {
    return {
      messages: {
        askEmail: this.t([
          "And what's the best email for contact?",
          "This can be a personal email address if preferred"
        ]),
        askEmailPermission: this.t([
          "Thank you. We require an email on system but this will only be used to reach you for essential communication",
          "Do we have permission to send you an email to that address?"
        ])
      },
      shouldAskEmailPermission: true
    }
  }

  async onCollectEmailEnded(state: State): Promise<IStep> {
    return this.goToCollectPreferredCorrespondence
  }

  async getCollectPreferredCorrespondenceSettings(
    state: State
  ): Promise<ICollectPreferredCorrespondenceSettings> {
    return {
      messages: {
        askPreferredContactMethod: this.t([
          "And what is your preferred method of contact for appointment booking and correspondence?"
        ])
      }
    }
  }

  async onCollectPreferredCorrespondenceEnded(state: State): Promise<IStep> {
    return this.askAccessibilityConsiderations
  }

  async onCollectGenderEnded(state: State): Promise<IStep> {
    return this.goToCollectSexuality
  }

  async onCollectSexualityEnded(state: State): Promise<IStep> {
    return this.goToCollectNationality
  }

  async onCollectNationalityEnded(state: State): Promise<IStep> {
    return this.goToCollectEthnicity
  }

  async onCollectEthnicityEnded(state: State): Promise<IStep> {
    return this.goToCollectReligion
  }

  async getCollectReligionSettings(state: State): Promise<ICollectReligionSettings> {
    const optionsReligions = this.getReligions(state).map(g => ({ body: g, value: g }))

    return {
      options: optionsReligions
    }
  }

  async onCollectReligionEnded(_state: State): Promise<IStep> {
    return this.goToCollectLanguageAndInterpreter
  }

  async onCollectLanguageAndInterpreterEnded(state: State): Promise<IStep> {
    return this.goToCollectMainIssue
  }

  async getCollectMainIssueState(state: State): Promise<CollectMainIssueScriptState> {
    return { hideClosingMessage: true }
  }

  async getCollectMainIssueSettings(state: State): Promise<ICollectMainIssueSettings> {
    const name = this.getName(state)
    return {
      messages: {
        askMainIssue: this.t(
          [
            "So {name}, what's the main issue that has brought you here today? (be sure to include specific feelings, behaviours, or thoughts that are bothering you)"
          ],
          { name }
        )
      }
    }
  }

  async onCollectMainIssueEnded(state: State): Promise<IStep> {
    return this.doReferralSubmission
  }

  async getReferralPayload(state: State): Promise<Record<string, any>> {
    return {}
  }

  getTitles(_state: State): string[] {
    return []
  }

  getPronouns(_state: State): string[] {
    return []
  }

  getJobCategories(_state: State): string[] {
    return []
  }

  getGenders(_state: State): string[] {
    return []
  }

  getNationalities(_state: State): string[] {
    return []
  }

  getReligions(_state: State): string[] {
    return []
  }

  getLanguages(_state: State): string[] {
    return []
  }

  getEthnicities(_state: State): string[] {
    return []
  }

  getSexualities(_state: State): string[] {
    return []
  }

  getDisabilities(_state: State): string[] {
    return []
  }
}

export default class SelfReferralWellbeingHubIndividualDialogue extends AdHocDialogue<
  State,
  SelfReferralWellbeingHubIndividualScript
> {
  static id = DialogueIDs.SelfReferralWellbeingHubIndividual
  readonly name: string = "SelfReferralWellbeingHubIndividualDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      SelfReferralWellbeingHubIndividualDialogue.id,
      new SelfReferralWellbeingHubIndividualScript(),
      state,
      snapshot
    )
  }
}
