import React, { useState } from "react"
import moment from "moment"
import "./AppointmentCalendar.scss"
import ISelectable from "../../../models/ISelectable"
import IAppointment, { AppointmentTimeslot } from "../../../models/IAppointment"
import InlineButton from "../InlineButton/InlineButton"
import { useApplicationStore, useConfigStore } from "../../contexts/RootStoreContext"
import clsx from "clsx"

interface Props {
  appointments: IAppointment[]
  onSubmit: (body: string, slot: AppointmentTimeslot | false) => void
}

export default function AppointmentCalendar(props: Props): JSX.Element {
  const { appointments = [], onSubmit } = props
  const app = useApplicationStore()
  const [index, setIndex] = useState(0)
  const lastPosition = appointments.length - 1
  const config = useConfigStore()

  const { date, timeslots } = appointments[index]
  const formattedDate = moment(date, "DD-MM-YYYY").format("ddd MMM DD")
  const isScrollable = timeslots.length > 5

  const onPreviousDatePress = React.useCallback((): void => setIndex(index - 1), [setIndex, index])
  const onNextDatePress = React.useCallback((): void => setIndex(index + 1), [setIndex, index])
  const onContinuePress = React.useCallback(
    () => onSubmit(app.t("Continue"), false),
    [app, onSubmit]
  )

  const handleSubmit = React.useCallback(
    (formattedDate: string, timeslot: AppointmentTimeslot): void =>
      onSubmit(`${formattedDate} at ${timeslot.time}`, timeslot),
    [onSubmit]
  )

  // prettier-ignore
  const containerCSS = clsx("lb-appointment-container", { "lb-appointment-container-scrollable": isScrollable })
  return (
    <div className="lb-appointment-wrapper" data-testid="appointment-input">
      <div className={containerCSS}>
        <InlineButton
          btn={{ body: app.t("Continue") }}
          onSelect={onContinuePress}
          style={{ backgroundColor: "#EC9CC8", cursor: "pointer" }}
        />
        <div className="lb-appointment-calendar">
          {timeslots.map(timeslot => {
            // prettier-ignore
            const duration = `${moment(timeslot.startTime).format("HH:mm")} - ${moment(timeslot.endTime).format("HH:mm")}`
            const btn: ISelectable = { body: duration, value: timeslot.time }
            return (
              <InlineButton
                key={timeslot.time}
                className="lb-appointment-button"
                buttonContainerClassName="lb-appointment-button-container"
                style={{ backgroundColor: config.userMessageBackground }}
                btn={btn}
                onSelect={() => handleSubmit(formattedDate, timeslot)}
              />
            )
          })}
        </div>
        <div className="lb-current-day-controls">
          <div
            onClick={onPreviousDatePress}
            data-testid="previous-appointment-date"
            className={clsx("lb-appointment-prev", index === 0 && "lb-appointment-disabled")}>
            &#10094;
          </div>
          <div className="lb-current-day">{formattedDate}</div>
          <div
            onClick={onNextDatePress}
            data-testid="next-appointment-date"
            className={clsx("lb-appointment-next", {
              "lb-appointment-disabled": index === lastPosition
            })}>
            &#10095;
          </div>
        </div>
      </div>
    </div>
  )
}
