import { z, ZodSchema } from "zod"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { PermissionsScriptState } from "./PermissionsScript"
import { PermissionsScript, PermissionsScriptStateSchema } from "./PermissionsScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

interface State extends PermissionsScriptState {
  consentDataShareGP?: boolean
}

export type PermissionsWellbeingHubScriptState = State

export const PermissionsWellbeingHubScriptStateSchema = PermissionsScriptStateSchema.extend({
  consentDataShareGP: z.boolean().optional()
})

export class PermissionsWellbeingHubScript extends PermissionsScript {
  readonly name: string = "PermissionsWellbeingHubScript"

  /** Script Steps */

  @step.logState
  step1(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t(
        [
          "I also know that talking about mental health can be difficult",
          "So {name}, before I start finding out more about you, I'm going to run you through how we keep your data safe 👇"
        ],
        { name }
      ),
      nextStep: this.showTOSLinks
    }
  }

  @step.logState
  async handleIUnderstandTerms(d: IStepData<State>): Promise<IStepResult> {
    d.state.agreeTerms = d.response.agreeTerms
    d.state.agreeDetailsShared = d.response.agreeDetailsShared
    return {
      nextStep: this.askConsentDataShareGP
    }
  }

  @step.logState
  askConsentDataShareGP(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Are you comfortable with your data being shared with your GP and wider providers if needed?",
        "Where possible, this will be discussed with you in advance and would only occur in cases of serious risk to yourself or others"
      ]),
      prompt: {
        id: this.getPromptId("askConsentDataShareGP"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askConsentDataShareGP"
      },
      nextStep: this.handleConsentDataShareGP
    }
  }

  @step.logState
  handleConsentDataShareGP(d: IStepData<State>): IStepResult {
    d.state.consentDataShareGP = d.response
    if (d.response) {
      return { nextStep: this.sayReady }
    }
    return {
      nextStep: this.sayCannotProceedWithoutConsent
    }
  }

  @step.logState
  sayCannotProceedWithoutConsent(_d: IStepData<State>): IStepResult {
    const genericPhoneNumber = this.rootStore.configStore.organisationGenericPhoneNumber
    return {
      body: this.t(
        [
          "We will need to gain this consent before continuing your referral",
          "Please call in to refer by phone on {genericPhoneNumber}"
        ],
        { genericPhoneNumber }
      ),
      prompt: {
        id: this.getPromptId("sayCannotProceedWithoutConsent"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Ok, let me read that again"), value: true },
          { body: this.t("Thanks, noted"), value: false }
        ],
        dataPointsName: "sayCannotProceedWithoutConsent"
      },
      nextStep: this.handleCannotProceedWithoutConsent
    }
  }

  @step.logState
  handleCannotProceedWithoutConsent(d: IStepData<State>): IStepResult {
    if (d.response) {
      return { nextStep: this.askConsentDataShareGP }
    }
    return {
      nextStep: this.goToGoodbye
    }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return PermissionsWellbeingHubScriptStateSchema
  }
}

/** istanbul ignore next */
export default class PermissionsWellbeingHubDialogue extends Dialogue<State> {
  static id = DialogueIDs.PermissionsWellbeingHub
  readonly name: string = "PermissionsWellbeingHubDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PermissionsWellbeingHubDialogue.id, new PermissionsWellbeingHubScript(), state, snapshot)
  }
}
