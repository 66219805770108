import BaseScript, { BaseScriptState } from "../../../BaseScript"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import {
  IDefaultChatFlowMessagesGetPermissions,
  IDefaultChatFlowSettingsGetPermissions
} from "@limbic/types"
import { step } from "../../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { joinWithAnd } from "../../../../utils/array"
import formatUnicorn from "../../../../utils/formatUnicorn"

export type IGetPermissionsSettings = IDefaultChatFlowSettingsGetPermissions & {
  messages?: IDefaultChatFlowMessagesGetPermissions
}

type State = BaseScriptState

export type GetPermissionsScriptState = State

export class GetPermissionsScript extends BaseScript<State> {
  readonly name: string = "GetPermissionsScript"
  protected trigger: string
  protected readonly messages: IDefaultChatFlowMessagesGetPermissions | undefined
  protected readonly shouldAskResearchConsent?: boolean
  protected readonly shouldSayReady?: boolean

  constructor(settings?: IGetPermissionsSettings | undefined) {
    super()
    this.trigger = joinWithAnd(this.clinicalStore.triggerWords?.filter(Boolean).map(w => `"${w}"`))
    this.messages = settings?.messages ?? {}
    this.shouldAskResearchConsent = settings?.shouldAskResearchConsent ?? true
    this.shouldSayReady = settings?.shouldSayReady ?? true
  }

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.showTOSLinks }
  }

  @step.logState
  showTOSLinks(d: IStepData<State>): IStepResult {
    const organisationName =
      this.rootStore.configStore.organisationName ?? this.rootStore.configStore.serviceName
    const organisationTerms = this.rootStore.configStore.organisationTerms ?? ""

    let body = [
      "To get you the best referral, I will need to share your answers with {organisationName}",
      "I want to reassure you that your details will be stored safely and kept confidential"
    ]
    if (this.messages?.showTOSLinks && this.messages?.showTOSLinks.length) {
      body = this.messages?.showTOSLinks.map(message =>
        formatUnicorn(message, this.getContext(d.state))
      )
    }

    body.push(
      "You can find all the details here:\n\n" +
        "Limbic [Terms of Service](https://www.limbic.ai/terms-of-use)\n" +
        `Limbic [Privacy Policy](https://www.limbic.ai/privacy)\n${organisationTerms}`
    )

    return {
      body: this.t(body, { ...this.getContext(d.state), organisationName, organisationTerms }),
      nextStep: this.promptIUnderstandTerms
    }
  }

  @step.logState
  promptIUnderstandTerms(_d: IStepData<State>): IStepResult {
    const organisationName =
      this.rootStore.configStore.organisationName ?? this.rootStore.configStore.serviceName

    return {
      prompt: {
        id: this.getPromptId("promptIUnderstandTerms"),
        trackResponse: true,
        // 👇 There seems to be a bug when there are two
        // consecutive checkbox questions, and you hit undo
        // the checkbox component crashes as it receives the
        // data of the previous checkbox and not the checkbox
        // that is loaded on Undo
        // Setting it to isUndoAble false for now
        isUndoAble: false,
        type: "checkbox",
        isRequired: true,
        options: [
          {
            body: this.t("I agree to the terms"),
            key: "agreeTerms",
            isRequired: true,
            initialValue: false
          },
          {
            // prettier-ignore
            body: this.t("I understand my details will be shared with {organisationName}", { organisationName }),
            key: `agreeDetailsShared`,
            isRequired: true,
            initialValue: false
          }
        ].filter(Boolean)
      },
      nextStep: this.handleIUnderstandTerms
    }
  }

  @step.logState
  async handleIUnderstandTerms(d: IStepData<State>): Promise<IStepResult> {
    d.state.agreeTerms = d.response.agreeTerms
    d.state.agreeDetailsShared = d.response.agreeDetailsShared
    return {
      nextStep: this.shouldAskResearchConsent
        ? this.askResearchConsent
        : this.shouldSayReady
          ? this.sayReady
          : this.end
    }
  }

  @step.logState
  askResearchConsent(d: IStepData<State>): IStepResult {
    let body = [
      "I am also working with researchers to improve mental health treatment",
      "Are you ok if Limbic uses your data anonymously to support the development of the product and research, which might be used for scientific publications?",
      "Your answer will not impact the decision on whether or not we can provide you with this service"
    ]
    if (this.messages?.askResearchConsent && this.messages?.askResearchConsent.length) {
      body = this.messages?.askResearchConsent.map(message =>
        formatUnicorn(message, this.getContext(d.state))
      )
    }

    return {
      body: this.t(body, this.getContext(d.state)),
      prompt: {
        id: this.getPromptId("askResearchConsent"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleResearchConsent
    }
  }

  @step.logState
  async handleResearchConsent(d: IStepData<State>): Promise<IStepResult> {
    d.state.consentResearch = d.response
    return {
      nextStep: this.shouldSayReady ? this.sayReady : this.end
    }
  }

  @step.logState
  sayReady(d: IStepData<State>): IStepResult {
    let body = ["Let's continue", "Ready?"]
    if (this.messages?.sayReady && this.messages?.sayReady.length) {
      body = this.messages?.sayReady.map(message =>
        formatUnicorn(message, this.getContext(d.state))
      )
    }

    return {
      body: this.t(body, this.getContext(d.state)),
      prompt: {
        id: this.getPromptId("sayReady"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Let's get started") }]
      },
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  getContext(state: State): Record<string, any> {
    return {
      ...this.rootStore.configStore,
      name: this.getName(state),
      trigger: this.trigger
    }
  }
}

export default class GetPermissionsDialogue extends AdHocDialogue<State, GetPermissionsScript> {
  static id = DialogueIDs.GetPermissions
  readonly name: string = "GetPermissionsDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: IGetPermissionsSettings
  ) {
    super(
      GetPermissionsDialogue.id,
      new GetPermissionsScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
