import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import { PermissionsIAPTScript } from "./PermissionsIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { PermissionsScriptState } from "./PermissionsScript"

type State = PermissionsScriptState
export type PermissionsOxfordBucksScriptState = State

export class PermissionsOxfordBucksScript extends PermissionsIAPTScript {
  readonly name: string = "PermissionsOxfordBucksScript"

  /** Script Steps */

  @step.logState
  showTOSLinks(_d: IStepData<State>): IStepResult {
    const organisationName =
      this.rootStore.configStore.organisationName ?? this.rootStore.configStore.serviceName
    const organisationTerms = this.rootStore.configStore.organisationTerms ?? ""
    return {
      body: [
        `To get you the best referral, I will need to share your answers with ${organisationName}`,
        `In addition ${organisationName} need to ensure that you are aware of how your records are looked after, the main points are:\n\n` +
          "1. We share information about your care with your GP\n" +
          "2. We share information with the Department of Health and other healthcare providers such as your GP about the contact you have or have had with them or us; this is for statistical purposes only and will not disclose your identity\n\n" +
          "Please feel free to discuss these points with any staff member involved in your care",
        "I want to reassure you that your details will be stored safely and kept confidential",
        "You can find all the details here:\n\n" +
          "Limbic [Terms of Service](https://www.limbic.ai/terms-of-use)\n" +
          "Limbic [Privacy Policy](https://www.limbic.ai/privacy)\n" +
          organisationTerms
      ],
      nextStep: this.promptIUnderstandTerms
    }
  }

  /** General Handlers */

  async onHandleIUnderstandTerms(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayReady }
  }
}

/* istanbul ignore next */
export default class PermissionsOxfordBucksDialogue extends Dialogue<State> {
  static id = DialogueIDs.PermissionsOxfordBucks
  readonly name: string = "PermissionsOxfordBucksDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PermissionsOxfordBucksDialogue.id, new PermissionsOxfordBucksScript(), state, snapshot)
  }
}
