import {
  CollectPhoneNumberScript,
  CollectPhoneNumberScriptState,
  ICollectPhoneNumberSettings
} from "./CollectPhoneNumberDialogue"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { step } from "../../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"

interface State extends CollectPhoneNumberScriptState {
  homePhoneNumber?: string
  mobilePhoneNumber?: string
  canLeaveVoicemailToHomePhoneNumber?: boolean
  canLeaveVoicemailToMobilePhoneNumber?: boolean
  canSendTextMessagesToMobilePhoneNumber?: boolean
}
export type CollectPhoneNumberMPFTScriptState = State

export class CollectPhoneNumberMPFTScript extends CollectPhoneNumberScript {
  readonly name: string = "CollectPhoneNumberMPFTScript"

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askHomePhoneNumber }
  }

  @step.logState
  askHomePhoneNumber(_d: IStepData<State>): IStepResult {
    return {
      body: "What's your home phone number?",
      prompt: {
        id: this.getPromptId("askHomePhoneNumber"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "I don't have one", value: "noLandline" }],
        phonePrompt: {
          forceLandline: true
        }
      },
      nextStep: this.handleHomePhoneNumber
    }
  }

  @step.logState
  handleHomePhoneNumber(d: IStepData<State, "noLandline" | string>): IStepResult {
    if (d.response === "noLandline") {
      // 👇 making sure we delete it in case of undo
      d.state.homePhoneNumber = undefined
      return { nextStep: this.askMobilePhoneNumber }
    }
    d.state.homePhoneNumber = d.response
    // 👇 This is a backup, i'm sure we will forget to add homePhoneNumber to the payload...
    d.state.phoneNumber = d.response
    return { nextStep: this.askHomePhonePermissions }
  }

  @step.logState
  askHomePhonePermissions(_d: IStepData<State>): IStepResult {
    return {
      body: "And is it okay to leave voicemail messages on that number?",
      prompt: {
        id: this.getPromptId("askHomePhonePermissions"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleHomePhonePermissions
    }
  }

  @step.logState
  handleHomePhonePermissions(d: IStepData<State, boolean>): IStepResult {
    d.state.canLeaveVoicemailToHomePhoneNumber = d.response
    this.setPeople({ canLeaveVoicemailToHomePhoneNumber: d.response })
    return { nextStep: this.askMobilePhoneNumber }
  }

  @step.logState
  askMobilePhoneNumber(_d: IStepData<State>): IStepResult {
    return {
      body: "And what's your mobile phone number?",
      prompt: {
        id: this.getPromptId("askMobilePhoneNumber"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "I don't have one", value: "noMobilePhone" }],
        phonePrompt: {
          forceMobile: true
        }
      },
      nextStep: this.handleMobilePhoneNumber
    }
  }

  @step.logState
  handleMobilePhoneNumber(d: IStepData<State, "noMobilePhone" | string>): IStepResult {
    if (d.response === "noMobilePhone") {
      // 👇 making sure we delete it in case of undo
      d.state.mobilePhoneNumber = undefined
      return { nextStep: d.state.homePhoneNumber ? this.end : this.sayAPhoneNumberIsMandatory }
    }
    d.state.mobilePhoneNumber = d.response
    // 👇 This is a backup, i'm sure we will forget to add homePhoneNumber to the payload...
    if (!d.state.phoneNumber) d.state.phoneNumber = d.response
    return { nextStep: this.askMobilePhonePermissions }
  }

  @step.logState
  sayAPhoneNumberIsMandatory(_d: IStepData<State>): IStepResult {
    const organisationPhoneNumber = this.rootStore.configStore.organisationGenericPhoneNumber
    const organisationEmails = this.rootStore.configStore.emails?.join(", ")

    return {
      body: [
        "We need at least one phone number to contact you",
        `If you do not have a phone number, please contact the service on ${organisationPhoneNumber} or ${organisationEmails}`
      ],
      prompt: {
        id: this.getPromptId("sayAPhoneNumberIsMandatory"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Add a landline phone number", value: "landline" },
          { body: "Add a mobile phone number", value: "mobile" }
        ]
      },
      nextStep: this.handleAPhoneNumberIsMandatory
    }
  }

  @step.logState
  handleAPhoneNumberIsMandatory(d: IStepData<State, "landline" | "mobile">): IStepResult {
    if (d.response === "landline") {
      return { nextStep: this.askHomePhoneNumber }
    }
    return { nextStep: this.askMobilePhoneNumber }
  }

  @step.logState
  askMobilePhonePermissions(_d: IStepData<State>): IStepResult {
    const smsKey = this.smsConsentKey ?? "canSendTextMessagesToPhoneNumber"
    const voicemailKey = this.voicemailConsentKey ?? "canLeaveVoicemailToPhoneNumber"

    const VOICEMAIL_MESSAGES_PROMPT = {
      body: this.t("You may leave voicemail messages"),
      key: voicemailKey
    }
    const TEXT_MESSAGES_PROMPT = { body: this.t("You may send me text messages"), key: smsKey }

    return {
      body: "And is it ok to send you text messages or leave a voicemail if I can't get through?",
      prompt: {
        id: this.getPromptId("askMobilePhonePermissions"),
        trackResponse: true,
        type: "checkbox",
        options: [TEXT_MESSAGES_PROMPT, VOICEMAIL_MESSAGES_PROMPT]
      },
      nextStep: this.handleMobilePhonePermissions
    }
  }

  @step.logState
  handleMobilePhonePermissions(
    d: IStepData<
      State,
      {
        canSendTextMessagesToPhoneNumber?: boolean
        canLeaveVoicemailToPhoneNumber: boolean
      }
    >
  ): IStepResult {
    const canSendTextMessagesToPhoneNumber = d.response.canSendTextMessagesToPhoneNumber
    const canLeaveVoicemailToPhoneNumber = d.response.canLeaveVoicemailToPhoneNumber
    d.state.canSendTextMessagesToMobilePhoneNumber = canSendTextMessagesToPhoneNumber
    d.state.canLeaveVoicemailToMobilePhoneNumber = canLeaveVoicemailToPhoneNumber
    const data = { canSendTextMessagesToPhoneNumber, canLeaveVoicemailToPhoneNumber }
    this.setPeople(data)
    return { nextStep: this.end }
  }
}

export default class CollectPhoneNumberMPFTDialogue extends AdHocDialogue<
  State,
  CollectPhoneNumberMPFTScript
> {
  static id = DialogueIDs.CollectPhoneNumberMPFT
  readonly name: string = "CollectPhoneNumberMPFTDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: ICollectPhoneNumberSettings
  ) {
    super(
      CollectPhoneNumberMPFTDialogue.id,
      new CollectPhoneNumberMPFTScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
