import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import GoodbyeScript from "./GoodbyeScript"
import type { GoodbyeScriptState } from "./GoodbyeScript"
import { RiskLevelReason } from "../../../models/Constants"
import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult, StepResultBodyType } from "../../../backend/chatbot/models/IStep"

interface State extends GoodbyeScriptState {
  requiresUrgentSupport?: boolean
}

export type GoodbyeVHGScriptState = State
export class GoodbyeVHGScript extends GoodbyeScript {
  readonly name: string = "GoodbyeVHGScript"

  @step
  async sayGoodBye(d: IStepData<State>): Promise<IStepResult> {
    const crisisInInput = this.clinicalStore.riskLevelReason === RiskLevelReason.CRISIS_DETECTION
    if (!crisisInInput) {
      void this.referralStore.updateReferral({
        isHelpful: d.state.isHelpful,
        improvementSuggestion: d.state.improvementSuggestion
      })
    }
    this.referralStore.stopPinging()

    return {
      body: crisisInInput
        ? undefined
        : this.t("Well done for taking this important step towards better mental health"),
      nextStep: this.sayDynamicLink
    }
  }

  /** Generic Handlers */

  async onHandleRecapMessage(
    state: State,
    body: StepResultBodyType[],
    recapMessage: string | undefined
  ): Promise<IStepResult> {
    return {
      body,
      prompt: {
        id: this.getPromptId("onHandleRecapMessage"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      },
      nextStep: this.askFeedback
    }
  }

  async getAdminWillCallMessage(state: State): Promise<string | void> {
    const treatment = this.clinicalStore.getAcceptedTreatment()
    if (state.referralSubmitted && !this.clinicalStore.isRisk && !treatment) {
      return this.t(
        "A member of the Nottingham and Nottinghamshire Talking Therapies team will contact you via telephone or text within the next week to begin your access into Talking Therapies"
      )
    }
  }

  async getHighRiskContactMessage(state: State): Promise<string | void> {
    if (this.clinicalStore.isHighRisk) {
      if (
        this.clinicalStore.riskLevelReason === RiskLevelReason.CRISIS_DETECTION ||
        !state.referralSubmitted
      ) {
        const organisationName = this.rootStore.configStore.organisationName
        const iaptName = this.getIAPTName(state) || organisationName
        return this.t(
          "Because you are in need of urgent support, I cannot refer you into {iaptName}\n",
          { iaptName }
        )
      }
      return this.t(
        "A member of the Nottingham and Nottinghamshire Talking Therapies team will contact you via telephone or text within the next week to begin your access into Talking Therapies"
      )
    }
  }

  async getModerateRiskContactMessage(state: State): Promise<string | void> {
    if (this.clinicalStore.isModerateRisk && state.referralSubmitted) {
      return this.t(
        "A member of the Nottingham and Nottinghamshire Talking Therapies team will contact you via telephone or text within the next week to begin your access into Talking Therapies"
      )
    }
  }

  async getTreatmentMessage(state: State): Promise<string | void> {
    const canKeepSelfSafe = state.canKeepSelfSafe ?? true

    if (!state.isEligible) return undefined

    if (canKeepSelfSafe && !state.requiresUrgentSupport && !this.clinicalStore.isRisk) {
      return this.t(
        "Someone from our team will contact you within 5 working days to book your assessment appointment\n\nIf you haven’t heard from them in this time, please give them a call to arrange your assessment"
      )
    }
  }
}

/* istanbul ignore next */
export default class GoodbyeVHGDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeVHG
  readonly name: string = "GoodbyeVHGDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeVHGDialogue.id, new GoodbyeVHGScript(), state, snapshot)
  }
}
