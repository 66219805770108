const INTERVAL = 20

interface TimoutTimer {
  timeout: NodeJS.Timeout
}

export default function scrollIntoView(
  parent?: HTMLDivElement,
  child?: HTMLDivElement,
  duration = 240
): TimoutTimer {
  const timer = { timeout: undefined as any }
  if (parent && child) {
    const { top: pTop = 0, bottom: pBottom = 0 } = parent.getBoundingClientRect()
    const { top: cTop = 0, bottom: cBottom = 0 } = child.getBoundingClientRect()

    const scrollTo = (el: HTMLDivElement, to: number, duration) => {
      const start = el.scrollTop
      let dt = 0
      const animateScroll = function () {
        dt += INTERVAL
        el.scrollTop = easeInOutQuad(dt, start, to, duration)
        if (dt < duration) {
          timer.timeout = setTimeout(animateScroll, INTERVAL)
        }
      }
      animateScroll()
    }

    if (pTop >= cTop) {
      scrollTo(parent, -(pTop - cTop), duration)
    } else if (cBottom > pBottom) {
      scrollTo(parent, cBottom - pBottom, duration)
    }
  }
  return timer
}

// prettier-ignore
// ts-ignore
// eslint-disable-next-line no-sequences
const easeInOutQuad = (t, b, c, d): number => ((t /= d / 2), t < 1 ? (c / 2) * t * t + b : (t--, (-c / 2) * (t * (t - 2) - 1) + b))
