import type Script from "../../Script"
import type { ScriptState } from "../../Script"
import type {
  IStep,
  IStepData,
  IStepResult,
  StepDecorator,
  StepDescriptor
} from "../../models/IStep"

function decorate<State extends ScriptState, S extends Script<State> = any>(
  step: IStep<State>,
  handler: (d: IStepData<State>, script: S) => void | Promise<void>
): IStep<State> {
  return async function (this: S, d: IStepData<State>): Promise<IStepResult> {
    await handler?.(d, this)
    return step.call(this, d)
  }
}

export function handleResponse<State extends ScriptState, S extends Script<State> = any>(
  handler: (d: IStepData<State>, script: S) => void | Promise<void>
): StepDecorator<State, S> {
  return function (target: S, key: keyof S, desc: StepDescriptor): StepDescriptor {
    const org = desc.value
    desc.value = decorate(org!, handler) as IStep<State>
    return desc
  }
}

handleResponse.decorate = decorate
