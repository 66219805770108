import { action, observable } from "mobx"
import { LoggableType } from "../../models/Logger/LoggableType"
import type { Translator } from "../../i18n/Translator"
import passthroughTranslationFn from "../../utils/passthroughTranslationFn"
import Syncable from "../../models/Syncable"
import Persistable from "../../models/Persistable"

export default class ApplicationStore extends Persistable implements Syncable {
  readonly name: string = "ApplicationStore"

  @observable username?: string
  @observable preferredName?: string
  @observable totalProgressBars: number
  @observable currentProgressBar: number
  @observable currentProgress: number
  translator?: Translator

  restart?(): void

  constructor() {
    super()
    this.totalProgressBars = this.hydrate("totalProgressBars") ?? 0
    this.currentProgressBar = this.hydrate("currentProgressBar") ?? 0
    this.currentProgress = this.hydrate("currentProgress") ?? 0
    this.username = this.hydrate("username")
    this.preferredName = this.hydrate("preferredName")
  }

  /** Actions */

  @action
  setUsername(username: string | undefined): void {
    this.username = username
    this.persist("username", username)
  }

  @action
  setPreferredName(preferredName: string | undefined): void {
    this.preferredName = preferredName
    this.persist("preferredName", preferredName)
  }

  @action
  setTotalProgressBars(totalProgressBars: number): void {
    this.totalProgressBars = totalProgressBars
    this.persist("totalProgressBars", totalProgressBars)
  }

  @action
  setCurrentProgressBar(currentProgressBar: number): void {
    this.currentProgressBar = currentProgressBar
    this.persist("currentProgressBar", currentProgressBar)
  }

  @action
  setCurrentProgress(currentProgress: number): void {
    this.currentProgress = currentProgress
    this.persist("currentProgress", currentProgress)
  }

  /** Generic Handlers */

  setTranslator(translator: Translator): void {
    this.translator?.removeEventListeners()
    this.translator = translator
    // force re-render when language changes
    this.translator.events.languageChanged.addListener(() => this.setUsername(this.username))
    this.translator.events.translationsChanged.addListener(() => this.setUsername(this.username))
  }

  rehydrate(): void {
    this.totalProgressBars = this.hydrate("totalProgressBars") ?? 0
    this.currentProgressBar = this.hydrate("currentProgressBar") ?? 0
    this.currentProgress = this.hydrate("currentProgress") ?? 0
    this.username = this.hydrate("username")
    this.preferredName = this.hydrate("preferredName")
    this.translator?.rehydrate()
  }

  /** Getters / Setters */

  get t(): Translator["get"] {
    if (this.translator) return this.translator.get
    return passthroughTranslationFn
  }
}

ApplicationStore.setLogType(LoggableType.UI)

if (process.env.REACT_APP__DEV_TOOLS__ === "enabled") {
  ApplicationStore.prototype.restart = action(function restart(this: ApplicationStore) {
    this.setUsername(undefined)
    this.setPreferredName(undefined)
    this.setTotalProgressBars(0)
    this.setCurrentProgressBar(0)
    this.setCurrentProgress(0)
  })
}
