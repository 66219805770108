import BaseScript, { BaseScriptState } from "../../../BaseScript"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import {
  IDefaultChatFlowMessagesCollectName,
  IDefaultChatFlowSettingsCollectName
} from "@limbic/types"
import { step } from "../../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import IName from "../../../../models/IName"

export type ICollectNameSettings = IDefaultChatFlowSettingsCollectName & {
  messages?: IDefaultChatFlowMessagesCollectName
}

type State = BaseScriptState

export type CollectNameScriptState = State

export class CollectNameScript extends BaseScript<State> {
  readonly name: string = "CollectNameScript"
  protected readonly messages: IDefaultChatFlowMessagesCollectName | undefined
  protected readonly shouldAskPreferredName?: boolean
  protected readonly shouldSayNiceToMeetYou?: boolean

  constructor(settings?: ICollectNameSettings | undefined) {
    super()
    this.messages = settings?.messages ?? {}
    this.shouldAskPreferredName = settings?.shouldAskPreferredName ?? false
    this.shouldSayNiceToMeetYou = settings?.shouldSayNiceToMeetYou ?? false
  }

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    this.timeEvent(this.name)
    return { nextStep: this.askFullName }
  }

  @step
  askFullName(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messages?.askFullName ?? "What's your full name? ✏️",
        this.getContext(d.state)
      ),
      nextStep: this.showPromptForFullName
    }
  }

  @step
  sayPleaseGiveFullName(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messages?.sayPleaseGiveFullName ?? "Please enter your full name",
        this.getContext(d.state)
      ),
      nextStep: this.showPromptForFullName
    }
  }

  @step.logState
  showPromptForFullName(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("showPromptForFullName"),
        type: "name"
      },
      nextStep: this.handleFullNameWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    getInput: (d: IStepData<State, IName>) => {
      const { firstName, lastName, middleNames } = d.response
      return `${firstName}${middleNames ? ` ${middleNames}` : ""} ${lastName}`
    },
    getNextStep: (s: CollectNameScript) => s.sayPleaseGiveFullName
  })
  @step.handleResponse((d: IStepData<State, IName>, script: CollectNameScript) => {
    d.state.name = d.response
    const username = script.getFullName(d.state)
    d.state.username = username
    script.rootStore.applicationStore.setUsername(username)
  })
  async handleFullNameWithCrisis(_d: IStepData<State, IName>): Promise<IStepResult> {
    return { nextStep: this.checkFullName }
  }

  @step.logState
  async checkFullName(d: IStepData<State>): Promise<IStepResult> {
    if (!d.state.username || d.state.username.trim() === "") {
      return { nextStep: this.sayPleaseGiveFullName }
    }

    if (this.shouldAskPreferredName) return { nextStep: this.askIsPreferredName }
    if (this.shouldSayNiceToMeetYou) return { nextStep: this.sayNiceToMeetYou }
    return { nextStep: this.end }
  }

  @step.logState
  askIsPreferredName(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messages?.askIsPreferredName ?? "Is {name} your preferred first name?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askIsPreferredName"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleIsPreferredName
    }
  }

  @step.logStateAndResponse
  async handleIsPreferredName(d: IStepData<State, boolean>): Promise<IStepResult> {
    return {
      nextStep: !d.response
        ? this.sayPleaseGivePreferredName
        : this.shouldSayNiceToMeetYou
          ? this.sayNiceToMeetYou
          : this.end
    }
  }

  @step
  sayPleaseGivePreferredName(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messages?.sayPleaseGivePreferredName ?? "What would you like to be called?",
        this.getContext(d.state)
      ),
      nextStep: this.showPromptForPreferredName
    }
  }

  @step.logState
  showPromptForPreferredName(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("showPromptForPreferredName"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handlePreferredNameWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>, script: CollectNameScript) => {
    const preferredName = d.response?.trim()
    d.state.preferredName = preferredName
    script.rootStore.applicationStore.setPreferredName(preferredName)
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    getNextStep: (s: CollectNameScript) => s.sayPleaseGivePreferredName
  })
  async handlePreferredNameWithCrisis(_d: IStepData<State, string>): Promise<IStepResult> {
    return { nextStep: this.checkPreferredName }
  }

  @step.logState
  async checkPreferredName(d: IStepData<State>): Promise<IStepResult> {
    if (!d.state.preferredName || d.state.preferredName.trim() === "") {
      return { nextStep: this.sayPleaseGivePreferredName }
    }

    if (this.shouldSayNiceToMeetYou) {
      return { nextStep: this.sayNiceToMeetYou }
    }
    return { nextStep: this.end }
  }

  @step
  sayNiceToMeetYou(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messages?.sayNiceToMeetYou ?? "Nice to meet you {name}",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("sayNiceToMeetYou"),
        type: "inlinePicker",
        choices: [{ body: this.t("Nice to meet you too") }]
      },
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  getContext(state: State): Record<string, any> {
    return {
      ...this.rootStore.configStore,
      name: this.getName(state)
    }
  }
}

export default class CollectNameDialogue extends AdHocDialogue<State, CollectNameScript> {
  static id = DialogueIDs.CollectName
  readonly name: string = "CollectNameDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>, settings?: ICollectNameSettings) {
    super(
      CollectNameDialogue.id,
      new CollectNameScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
