import { ZodError, ZodSchema } from "zod"
import Logger from "./Logger"
import { Severity } from "../models/Logger/Severity"
import sendEmail from "../backend/api/limbic/sendEmail"

// this will act as a break to not get spammed.
// Basically we will use it to only notify an
// invalid state once per new state created or
// in other words, once per dialogue
let invalidStateNotified = false
let emailSent = false
const isTestEnv = process.env.NODE_ENV === "test"

type Validatable = Record<string, any>

export function createValidatableObject<T extends Validatable>(obj: T, schema?: ZodSchema): T {
  // try/catching for cases where Proxy doesn't work
  try {
    invalidStateNotified = false
    const handlers = getProxyHandlers(schema)
    return new Proxy(obj, handlers) as T
  } catch {
    return obj
  }
}

function getProxyHandlers(schema?: ZodSchema) {
  const validate = getValidatorFn(schema)
  return {
    set(target: Record<string, any>, p: string, value: any) {
      target[p] = value
      try {
        validate?.(target) // unnecessary try/catch, but you never know ¯\_(ツ)_/¯
      } catch (e) {
        if (isTestEnv) throw e
      }
      return true
    }
  }
}

function getValidatorFn(schema?: ZodSchema): ((target: Record<string, any>) => void) | undefined {
  if (schema) {
    return (target: Record<string, any>) => {
      try {
        if (invalidStateNotified) return
        const result = schema?.safeParse(target)
        if (!result?.success) {
          const { paths, error } = formatZodError(result?.error)
          Logger.getInstance().breadcrumb({
            message: `State validation errored [${paths?.join(", ")}]`,
            data: { error, paths, errorJSON: JSON.stringify(result.error), target }
          })
          Logger.getInstance().message("State validation errored", Severity.Info)
          if (isTestEnv) {
            console.log(`ERROR: ${error}`)
            throw new Error("State validation errored")
          }

          if (!emailSent) {
            void sendEmail({
              to: ["john@limbic.ai", "andreas@limbic.ai"],
              subject: `State validation errored [${paths?.join(", ")}]`,
              text:
                "State validation errored:\n\n" +
                `PATHS: ${paths?.join(", ") ?? "-"}\n\n` +
                `ERROR: ${error}\n\n` +
                `JSON: ${JSON.stringify(result.error, null, 2)}`
            }).catch(() =>
              Logger.getInstance().message(
                "State validation errored w/o email alert",
                Severity.Error
              )
            )
          }

          emailSent = true
          invalidStateNotified = true
        }
      } catch (e) {
        if (isTestEnv) throw e
        Logger.getInstance().exception(e, "state validation threw a silent error")
      }
    }
  }
}

function formatZodError(error?: ZodError): { paths?: string[]; error?: string } {
  if (!error) return {}
  const result = [] as string[]
  const paths = [] as string[]
  for (let i = 0, { length } = error.issues; i < length; i++) {
    const e = error.issues[i] as any
    const path = e.path.join(".")
    const expectation = `${e.expected} vs ${e.received}`
    result.push(`[${path}] (${expectation}) - ${e.message}`)
    paths.push(path)
  }
  return {
    paths,
    error: result.join("\n")
  }
}
