import {
  ARMED_FORCES_MOCK,
  DISABILITY_MOCK,
  ETHNICITY_MOCK,
  GENDER_MOCK,
  LTC_MOCK,
  PERINATAL_MOCK,
  SEXUALITY_MOCK
} from "@limbic/types"

export const genders: Record<string, GENDER_MOCK> = {
  Male: "MALE",
  Female: "FEMALE",
  "Not known": "UNKNOWN",
  "Not specified": "NOT_ANSWERED"
}

export const disabilities: Record<string, DISABILITY_MOCK> = {
  "Behaviour and Emotional": "BEHAVIOUR",
  Hearing: "HEARING",
  "Manual Dexterity": "MANUAL",
  "Memory or ability to concentrate, learn or understand (Learning Disability)": "LEARNING",
  "Mobility and Gross Motor": "MOTOR",
  "Perception of Physical Danger": "PERCEPTION",
  "Personal, Self Care and Continence": "SELF_CARE",
  "Progressive Conditions and Physical Health (such as HIV, cancer, multiple sclerosis, fits, etc)":
    "PROGRESSIVE",
  Sight: "SIGHT",
  Speech: "SPEECH",
  Other: "NOT_LISTED"
}

export const perinatalStatuses: Record<string, PERINATAL_MOCK> = {
  "None apply": "NONE",
  Pregnant: "PREGNANT",
  "Child under 1": "CHILD_UNDER_1",
  "Pregnancy in the last year": "PREGNANCY_WITHIN_1_YEAR",
  "Pregnant and Child under 1": "PREGNANT_AND_CHILD_UNDER_1",
  "Pregnancy in the last year and Child under 1": "PREGNANCY_WITHIN_1_YEAR_AND_CHILD_UNDER_1",
  "New Father - Child born within last 12 months": "NEW_FATHER"
}

export const ethnicities: Record<string, ETHNICITY_MOCK> = {
  "White - British": "WHITE_BRITISH",
  "White - Irish": "WHITE_IRISH",
  "White - Any other White background": "WHITE_OTHER",
  "Mixed - White and Black Caribbean": "MIXED_WHITE_BLACK_CARIBBEAN",
  "Mixed - White and Black African": "MIXED_WHITE_BLACK_AFRICAN",
  "Mixed - White and Asian": "MIXED_WHITE_ASIAN",
  "Mixed - Any other mixed background": "MIXED_OTHER",
  "Asian or Asian British - Indian": "ASIAN_BRITISH_INDIAN",
  "Asian or Asian British - Pakistani": "ASIAN_BRITISH_PAKISTANI",
  "Asian or Asian British - Bangladeshi": "ASIAN_BRITISH_BANGLADESHI",
  "Asian or Asian British - Any other Asian background": "ASIAN_BRITISH_OTHER",
  "Black or Black British - Caribbean": "BLACK_BRITISH_CARIBBEAN",
  "Black or Black British - African": "BLACK_BRITISH_AFRICAN",
  "Black or Black British - Any other Black background": "BLACK_BRITISH_OTHER",
  "Other Ethnic Groups - Chinese": "CHINESE",
  "Other Ethnic Groups - Any other ethnic group": "OTHER",
  "Not Stated - Not Stated": "NOT_ANSWERED",
  "Not known - Not known": "UNKNOWN"
}

export const exArmedForces: Record<string, ARMED_FORCES_MOCK> = {
  "Yes - ex services": "YES_EX",
  "Yes - currently serving": "YES_CURRENT",
  No: "NO",
  "Not stated (Person asked but declined to provide a response)": "NOT_ANSWERED"
}

export const sexualities: Record<string, SEXUALITY_MOCK> = {
  Heterosexual: "HETEROSEXUAL",
  "Female homosexual": "HOMOSEXUAL_FEMALE",
  "Male homosexual": "HOMOSEXUAL_MALE",
  Bisexual: "BISEXUAL",
  "Sexually attracted to neither male nor female sex": "ASEXUAL",
  "Not stated (Person asked but declined to provide a response)": "NOT_ANSWERED",
  Unknown: "UNKNOWN",
  "Person asked and does not know or is not sure": "UNSURE"
}

export const ltcs: Record<string, LTC_MOCK> = {
  Asthma: "ASTHMA",
  "Bladder/Bowel Condition": "BLADDER_BOWEL",
  Cancer: "CANCER",
  "Chronic Kidney Disease": "KIDNEY",
  "Chronic Muscular Skeletal": "MUSCULOSKELETAL",
  COPD: "COPD",
  "Chronic Pain": "CHRONIC_PAIN",
  "Chronic Pancreatitis": "PANCREATITIS",
  "Coronary Heart Disease (CHD)": "CHD",
  Crohns: "CROHNS",
  Dementia: "DEMENTIA",
  "Diabetes - Type 1": "DIABETES_TYPE1",
  "Diabetes - Type 2": "DIABETES_TYPE2",
  "Digestive Tract Conditions": "DIGESTIVE",
  Epilepsy: "EPILEPSY",
  Fibromyalgia: "FIBROMYALGIA",
  "Heart Failure": "HEART_FAILURE",
  Hypertension: "HYPERTENSION",
  "Irritable Bowel Syndrome": "IBS",
  "M.E (Chronic Fatigue)": "FATIGUE",
  "Medically Unexplained Conditions": "MEDICALLY_UNEXPLAINED",
  "Multiple Sclerosis": "MS",
  Osteoporosis: "OSTEOPOROSIS",
  "Parkinson's": "PARKINSON",
  "Sickle Cell Disease": "SICKLE_CELL",
  "Skin Condition including Eczema": "SKIN",
  "Stroke and Transient Ischaemic Attack": "STIA",
  Tinnitus: "TINNITUS"
}

const form = {
  form_url: "https://limbic.ai",
  filled_by: "API",
  perinatalStatuses: Object.keys(perinatalStatuses),
  sexualities: Object.keys(sexualities),
  genders: Object.keys(genders),
  ethnicities: Object.keys(ethnicities),
  exArmedForces: Object.keys(exArmedForces),
  disabilities: Object.keys(disabilities),
  LTCs: Object.keys(ltcs)
}

export default form
