import React, { useState } from "react"
import moment from "moment"
import "./AppointmentCalendarLegacy.scss"
import ISelectable from "../../../models/ISelectable"
import IAppointment, { AppointmentTimeslot } from "../../../models/IAppointment"
import InlineButton from "../InlineButton/InlineButton"
import { useConfigStore } from "../../contexts/RootStoreContext"
import clsx from "clsx"

interface Props {
  appointments: IAppointment[]
  onSubmit: (item: ISelectable) => void
  withUserID?: boolean
  isFullScreen?: boolean
  hideDuration?: boolean
}

export default function AppointmentCalendarLegacy(props: Props): JSX.Element {
  const {
    appointments = [],
    onSubmit,
    withUserID = true,
    isFullScreen = false,
    hideDuration = false
  } = props
  const [currentPosition, setCurrentPosition] = useState(0)
  const lastPosition = appointments.length - 1
  const config = useConfigStore()

  const { date } = appointments[currentPosition]
  const isScrollable = appointments[currentPosition].timeslots.length > 5

  const onHandlePreviousPress = React.useCallback(
    (): void => setCurrentPosition(currentPosition - 1),
    [setCurrentPosition, currentPosition]
  )

  const onHandleNextPress = React.useCallback(
    (): void => setCurrentPosition(currentPosition + 1),
    [setCurrentPosition, currentPosition]
  )

  const handleSubmit = React.useCallback(
    (formattedDate: string, timeslot: AppointmentTimeslot): void =>
      onSubmit({
        body: `${formattedDate} at ${timeslot.time}`,
        value: withUserID
          ? `${timeslot.id}_${formattedDate} at ${timeslot.time}`
          : `${timeslot.startTime}&&${timeslot.endTime}_${formattedDate}`
      }),
    [onSubmit, withUserID]
  )

  return (
    <div className="lb-appointment-wrapper" data-testid="appointment-input">
      <div
        className={clsx(
          "lb-appointment-container",
          isScrollable
            ? "lb-appointment-container-scrollable"
            : "lb-appointment-container-non-scrollable"
        )}>
        <div className="lb-appointment-calendar">
          {appointments[currentPosition].timeslots.map(timeslot => {
            const backgroundColor = config.userMessageBackground
            const btn: ISelectable = {
              body: hideDuration
                ? moment(timeslot.timestamp).format("HH:mm")
                : `${moment(timeslot.timestamp).format("HH:mm")} - ${moment(timeslot.timestamp)
                    .add(timeslot.duration, "m")
                    .format("HH:mm")}`,
              value: timeslot.time
            }
            const formattedDate = moment(appointments[currentPosition].date, "DD MM YYYY").format(
              "ddd MMM DD"
            )
            return (
              <InlineButton
                className={clsx("lb-appointment-button", {
                  "lb-appointment-button-fullscreen": isFullScreen
                })}
                buttonContainerClassName="lb-appointment-button-container"
                style={{ backgroundColor, width: "100%" }}
                key={timeslot.time}
                btn={btn}
                onSelect={() => handleSubmit(formattedDate, timeslot)}
              />
            )
          })}
        </div>
        <div className="lb-current-day-controls">
          <div
            onClick={onHandlePreviousPress}
            data-testid="previous-appointment-date"
            className={clsx(
              "lb-appointment-prev",
              currentPosition === 0 && "lb-appointment-disabled"
            )}>
            &#10094;
          </div>
          <div className="lb-current-day">{moment(date, "DD MM YYYY").format("ddd, MMMM DD")}</div>
          <div
            onClick={onHandleNextPress}
            data-testid="next-appointment-date"
            className={clsx(
              "lb-appointment-next",
              currentPosition === lastPosition && "lb-appointment-disabled"
            )}>
            &#10095;
          </div>
        </div>
      </div>
    </div>
  )
}
