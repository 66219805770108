import { z, ZodSchema } from "zod"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import {
  IntroductionIAPTScript,
  IntroductionIAPTScriptState,
  IntroductionIAPTScriptStateSchema
} from "./IntroductionIAPTDialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import Logger from "../../../utils/Logger"

interface State extends IntroductionIAPTScriptState {
  claimNumber?: string
  source?: "telephone" | "memberzone"
}
export type IntroductionVitalityState = State

export const IntroductionVitalityStateSchema = IntroductionIAPTScriptStateSchema.extend({
  claimNumber: z.string().optional(),
  source: z.union([z.literal("telephone"), z.literal("memberzone")]).optional()
})

export class IntroductionVitalityScript extends IntroductionIAPTScript {
  readonly name: string = "IntroductionVitalityScript"

  /** Script Steps */

  @step.logState
  greet(d: IStepData<State>): IStepResult {
    const entityId = this.referralStore.getCustomField("entityId")
    if (!entityId) {
      Logger.getInstance().exception(new Error("entityId"), "no entityId was found")
    }
    const claimNumber = this.referralStore.getCustomField("claimNumber")
    if (claimNumber) {
      d.state.claimNumber = claimNumber
      d.state.source = "telephone"
    } else {
      d.state.claimNumber = undefined
      d.state.source = "memberzone"
    }

    const firstName = this.referralStore.getCustomField("firstName") ?? undefined
    const middleNames = this.referralStore.getCustomField("middleNames") ?? undefined
    const lastName =
      this.referralStore.getCustomField("lastName") ??
      this.referralStore.getCustomField("lastname") ??
      undefined
    let greet = "Hi there!"
    if (firstName && lastName) {
      d.state.name = { firstName, middleNames, lastName }
      const username = this.getFullName(d.state)
      d.state.username = username
      this.rootStore.applicationStore.setUsername(username)
      greet = "Hi {name}!"
    }
    const organisationName = this.rootStore.configStore.organisationName
    const name = this.getName(d.state)
    return {
      body: this.t(
        [
          greet,
          "I’m Limbic",
          "I'm a friendly robot assistant to help you access talking therapies from {organisationName}"
        ],
        { name, organisationName }
      ),
      nextStep: this.promptLetsGetStarted
    }
  }

  @step.logState
  promptLetsGetStarted(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("promptLetsGetStarted"),
        type: "inlinePicker",
        choices: [{ body: this.t("Let's get started") }]
      },
      nextStep: this.sayLetsDoIt
    }
  }

  @step.logState
  sayLetsDoIt(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(["Let's do it!", "I'm just going to look up your details in my system"]),
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return IntroductionVitalityStateSchema
  }
}

/* istanbul ignore next */
export default class IntroductionVitalityDialogue extends Dialogue<State> {
  static id = DialogueIDs.IntroductionVitality
  readonly name: string = "IntroductionVitalityDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(IntroductionVitalityDialogue.id, new IntroductionVitalityScript(), state, snapshot)
  }
}
