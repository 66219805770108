import { IClinicalPath } from "@limbic/types"
import { IClinicalPathOverride } from "../../../models/IClinicalPath"

//prettier-ignore
export const clinicalPathsSABP: Record<IClinicalPath["id"], IClinicalPathOverride> = {
    complex_comorbid: { defaultReferralType: "Standard Assessment" },
    undetermined: { defaultReferralType: "Extended Assessment" },
    "1": { defaultReferralType: "Standard Assessment" },
    "2": { defaultReferralType: "Standard Assessment" },
    "3": { defaultReferralType: "Standard Assessment" },
    "4": { defaultReferralType: "Shortened Assessment" },
    "5": { defaultReferralType: "Shortened Assessment" },
    "6": { defaultReferralType: "Shortened Assessment" },
    "7": { defaultReferralType: "Shortened Assessment" },
    "8": { defaultReferralType: "Shortened Assessment" },
    "9": { defaultReferralType: "Shortened Assessment" },
    "10": { defaultReferralType: "Shortened Assessment" },
    "11": { defaultReferralType: "Shortened Assessment" },
    "12": { defaultReferralType: "Standard Assessment" },
    "13": { defaultReferralType: "Shortened Assessment" },
    "14": { defaultReferralType: "Shortened Assessment" },
    "15": { defaultReferralType: "Standard Assessment" },
    "16": { defaultReferralType: "Shortened Assessment" },
    "17": { defaultReferralType: "Shortened Assessment" },
    "18": { defaultReferralType: "Standard Assessment" },
    "19": { defaultReferralType: "Standard Assessment" },
    "20": { defaultReferralType: "Standard Assessment" },
    "21": { defaultReferralType: "Standard Assessment" },
    "22": { defaultReferralType: "Shortened Assessment" },
    "23": { defaultReferralType: "Shortened Assessment" },
    "24": { defaultReferralType: "Shortened Assessment" },
    "25": { defaultReferralType: "Shortened Assessment" },
    "26": { defaultReferralType: "Shortened Assessment" },
    "27": { defaultReferralType: "Shortened Assessment" },
    "28": { defaultReferralType: "Shortened Assessment" },
    "29": { defaultReferralType: "Standard Assessment" },
    "30": { defaultReferralType: "Standard Assessment" },
    "31": { defaultReferralType: "Shortened Assessment" },
    "32": { defaultReferralType: "Shortened Assessment" },
    "33": { defaultReferralType: "Shortened Assessment" },
    "34": { defaultReferralType: "Shortened Assessment" },
    "35": { defaultReferralType: "Shortened Assessment" },
    "36": { defaultReferralType: "Standard Assessment" },
    "37": { defaultReferralType: "Shortened Assessment" },
    "38": { defaultReferralType: "Shortened Assessment" },
    "39": { defaultReferralType: "Standard Assessment" },
    "40": { defaultReferralType: "Shortened Assessment" },
    "41": { defaultReferralType: "Shortened Assessment" },
    "42": { defaultReferralType: "Standard Assessment" },
    "43": { defaultReferralType: "Standard Assessment" },
    "44": { defaultReferralType: "Standard Assessment" },
    "45": { defaultReferralType: "Standard Assessment" },
    "46": { defaultReferralType: "Shortened Assessment" },
    "47": { defaultReferralType: "Shortened Assessment" },
    "48": { defaultReferralType: "Standard Assessment" },
    "49": { defaultReferralType: "Standard Assessment" },
    "50": { defaultReferralType: "Standard Assessment" },
    "51": { defaultReferralType: "Standard Assessment" }
}
