import BaseScript, { BaseScriptState } from "../../../BaseScript"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { step } from "../../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { IDefaultChatFlowMessagesCollectReligion, ISelectableExtended } from "@limbic/types"

export type ICollectReligionSettings = {
  options?: ISelectableExtended[] | undefined
  messages?: IDefaultChatFlowMessagesCollectReligion
}

type State = BaseScriptState

export type CollectReligionScriptState = State

export class CollectReligionScript extends BaseScript<State> {
  readonly name: string = "CollectReligionScript"
  protected religions: ISelectableExtended[] | undefined
  protected messages: IDefaultChatFlowMessagesCollectReligion | undefined

  constructor(settings: ICollectReligionSettings | undefined = {}) {
    super()
    this.religions = settings?.options ?? []
    this.messages = settings?.messages ?? {}
  }

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askReligion }
  }

  @step.logState
  askReligion(d: IStepData<State>): IStepResult {
    const religions = this.religions ?? []
    if (!religions?.length) {
      this.logBreadcrumb("RELIGIONS NOT FOUND", d.state, { religions })
      this.logMessage("RELIGIONS NOT FOUND")
      return { nextStep: this.end }
    }
    return {
      body: this.t(
        this.messages?.askReligion ?? "What is your religion?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askReligion"),
        trackResponse: true,
        type: "inlinePicker",
        choices: religions,
        dataPointsName: "askReligion"
      },
      nextStep: this.handleReligion
    }
  }

  @step.logStateAndResponse
  async handleReligion(d: IStepData<State, string>): Promise<IStepResult> {
    const religions = this.religions ?? []
    const isValid = religions?.find(s => s.value === d.response)
    if (!isValid) {
      return {
        body: "Sorry I can't recognize this religion. Let's try again",
        nextStep: this.askReligion
      }
    }
    d.state.religion = d.response
    this.setPeople({ religion: d.response })

    return { nextStep: this.end }
  }

  /** Generic Handlers */

  getContext(state: State): Record<string, any> {
    return {
      ...this.rootStore.configStore,
      name: this.getName(state)
    }
  }
}

export default class CollectReligionDialogue extends AdHocDialogue<State, CollectReligionScript> {
  static id = DialogueIDs.CollectReligion
  readonly name: string = "CollectReligionDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: ICollectReligionSettings
  ) {
    super(
      CollectReligionDialogue.id,
      new CollectReligionScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
