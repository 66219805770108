import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import { RiskLevelReason } from "../../../models/Constants"
import sendRiskEmail from "../../../backend/api/limbic/sendRiskEmail"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = RiskPathwayScriptState

export type RiskPathwayWellbeingHubScriptState = State

export class RiskPathwayWellbeingHubScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayWellbeingHubScript"

  /** Script Steps */
  async start(d: IStepData<State>): Promise<IStepResult<State>> {
    if (d.state.email && d.state.canSendEmail) {
      await this.sendKeepingSafeEmail(d.state)
    }
    this.rootStore.applicationStore.setCurrentProgress(0.88)
    return { nextStep: this.step1 }
  }

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askCanYouKeepYourselfSafe }
  }

  @step.logState
  askCanYouKeepYourselfSafe(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Are you able to keep yourself, and any dependants in your care, safe until your appointment? This could be up to three working days, although likely to be sooner"
      ),
      prompt: {
        id: this.getPromptId("askCanYouKeepYourselfSafe"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askCanYouKeepYourselfSafe"
      },
      nextStep: this.handleCanYouKeepYourselfSafe
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.saveResponse<State>(
    "10",
    "Are you able to keep yourself, and any dependants in your care, safe until the service contacts you (within 5 working days)?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.canKeepSelfSafe = d.response
  })
  handleCanYouKeepYourselfSafe(d: IStepData<State>): IStepResult {
    if (!d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.CANNOT_KEEP_SELF_SAFE)
      return { nextStep: this.informRiskToService }
    }
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  async informRiskToService(d: IStepData<State>): Promise<IStepResult> {
    // TODO: WE MAY NOT NEED TO SEND RISK EMAIL - CHECK WITH BEN
    // await this.sendRiskEmailToService(d.state)
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  sayCrisis(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName

    return {
      body: this.t(
        [
          "Sorry to hear that {name}",
          "It is common for people to have thoughts of this nature at times",
          "However {organisationName} is not an emergency response service. In emergencies always dial 999 or, to contact the Police in a non-emergency, use 101"
        ],
        { name, organisationName }
      ),
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("I understand") }, { body: this.t("Okay") }],
        dataPointsName: "sayCrisis"
      },
      nextStep: this.sayInstructions
    }
  }

  async sayInstructions(_d: IStepData<State>): Promise<IStepResult> {
    return {
      body: this.t([
        "For urgent mental health support and advice:",
        "In an emergency always call 999 or to contact the Police in a non-emergency call 101",
        "Call NHS 111 for physical or mental health emergencies (available 24/7)",
        "Samaritans: call 116 123 (available 24/7)"
      ]),
      prompt: {
        id: this.getPromptId("sayInstructions"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }]
      },
      nextStep: this.handleSayInstructions
    }
  }

  @step.logState
  async handleSayInstructions(_d: IStepData<State>): Promise<IStepResult> {
    this.referralStore.setCustomField(
      "crisisLinksShared",
      "NHS Getting Help, Samaritans, 999 and 101"
    )
    return { nextStep: this.end }
  }

  /** Generic Handlers */

  async onSaveRiskLevelAndReferralType(state: State): Promise<IStepResult> {
    return { nextStep: !state.canKeepSelfSafe ? this.sayCrisis : this.end }
  }

  // TODO: We may need to remove this
  async sendRiskEmailToService(state: State): Promise<void> {
    try {
      if (!this.clinicalStore.isRisk) return
      const emails = this.getRiskEmails(state) || []
      if (emails?.length) {
        const text = this.createReferralEmail(state, true)
        await sendRiskEmail({
          to: emails,
          text
        })
      }
    } catch (e) {
      this.logException(e, "sendRiskEmailToService")
    }
  }

  getRiskEmails(_state: State): string[] {
    return [""]
  }
}

export default class RiskPathwayWellbeingHubDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayWellbeingHub
  readonly name: string = "RiskPathwayWellbeingHubDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(RiskPathwayWellbeingHubDialogue.id, new RiskPathwayWellbeingHubScript(), state, snapshot)
  }
}
