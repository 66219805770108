import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { GoodbyeScriptState } from "./GoodbyeScript"
import GoodbyeScript from "./GoodbyeScript"
import { IStepData, IStepResult, StepResultBodyType } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"

interface State extends GoodbyeScriptState {
  requiresUrgentSupport?: boolean
}

export type GoodbyeBuckinghamshireScriptState = State

export class GoodbyeBuckinghamshireScript extends GoodbyeScript {
  readonly name: string = "GoodbyeBuckinghamshireScript"

  /** Script Steps */

  @step
  async start(d: IStepData<State>): Promise<IStepResult> {
    // TODO: REMOVE THIS!!!!!!
    // NEEDED BECAUSE FOR NOW SELF REFERRAL DIALOGUE IS NOT BEING LOADED
    // d.state.referralSubmitted = d.state.requiresUrgentSupport ? false : true
    if (d.state.referralSubmitted) {
      return super.start(d)
    }
    return {
      nextStep: this.sayIHopeIHelped
    }
  }

  @step
  async sayGoodBye(d: IStepData<State>): Promise<IStepResult> {
    await this.referralStore.updateReferral({
      isHelpful: d.state.isHelpful,
      improvementSuggestion: d.state.improvementSuggestion
    })
    this.referralStore.stopPinging()

    return {
      body: [
        "Well done for taking this important step towards better mental health",
        "You can now close the window"
      ],
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onHandleRecapMessage(
    state: State,
    body: string[],
    recapMessage: string | undefined
  ): Promise<IStepResult> {
    const choices = [{ body: "Okay" }]
    if (recapMessage) {
      choices.push({ body: "I understand" })
    }

    return {
      body,
      prompt: {
        id: this.getPromptId("recap"),
        trackResponse: true,
        type: "inlinePicker",
        choices
      },
      nextStep: this.askFeedback // TODO: Feedback is skipped if isCrisis - should we change?
    }
  }

  async getIntroMessage(state: State): Promise<string | void> {
    const isCrisis = this.clinicalStore.isCrisis
    const name = this.getName(state)
    if (state.referralSubmitted) {
      return isCrisis
        ? `Thank you for sharing this information with me ${name}`
        : "I hope I’ve been able to help you today"
    }
    return isCrisis
      ? "I hope I’ve been able to help you today"
      : `Thank you for sharing this information with me ${name}`
  }

  // 👇 This is overridden to change the order of the adminWillCall
  // message, to happen after the risk messages
  async getRecapMessage(state: State): Promise<string | void> {
    const recaps: string[] = []
    const referredYouMessage = await this.getReferredYouMessage(state)
    referredYouMessage && recaps.push(referredYouMessage)

    const highRiskContactMessage = await this.getHighRiskContactMessage(state)
    highRiskContactMessage && recaps.push(highRiskContactMessage)

    const moderateRiskContactMessage = await this.getModerateRiskContactMessage(state)
    moderateRiskContactMessage && recaps.push(moderateRiskContactMessage)

    const adminWillCallMessage = await this.getAdminWillCallMessage(state)
    adminWillCallMessage && recaps.push(adminWillCallMessage)

    const treatmentMessage = await this.getTreatmentMessage(state)
    treatmentMessage && recaps.push(treatmentMessage)

    const formattedRecap = recaps.length > 1 ? recaps.map((i, idx) => `${idx + 1}. ${i}`) : recaps
    const recapString = formattedRecap.join("\n")

    if (recapString?.length) {
      return `Just to recap:\n\n${recapString}`
    }
  }

  async getReferredYouMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted) {
      const organisationName = this.rootStore.configStore.organisationName
      let referralMessage = `Well done, you have now referred yourself to ${organisationName}`
      if (this.clinicalStore.isRisk) {
        referralMessage =
          referralMessage +
          ", a Member of the team will contact you to review your referral and if suitable consider alternative services for you"
      }
      return state.isIdleSubmitted //
        ? `It looks like there hasn't been any activity for some time so I've referred you to ${organisationName}`
        : referralMessage
    }
  }

  async getHighRiskContactMessage(_state: State): Promise<string | void> {
    const organisationName = this.rootStore.configStore.organisationName
    if (this.clinicalStore.isHighRisk) {
      return (
        `You indicated that you are potentially experiencing high levels of distress, or potentially at risk. ${organisationName} are not an emergency response service\n` +
        "If you need urgent support, please dial NHS 111 and select Option 2\n" +
        "In medical emergency and life threatening situations only, please dial 999 or attend your local A&E department\n" +
        "You can also contact Bucks Safe Haven that offers a safe and supportive alternative to A&E for adults (aged 18+) who are experiencing a mental health crisis. For High Wycombe (open 7 nights a week) contact 01494 218098 and for Aylesbury (open Sunday, Monday, Tuesday and Wednesday, contact 01296 453017\n\n" +
        "Other helplines available:\n" +
        "- You can contact Samaritans 24 hours a day, 365 days a year\n" +
        "- You can call 116 123 (free from any phone) or email jo@samaritans.org\n" +
        "If you would prefer not to talk but want some mental health support, you could text SHOUT to 85258"
      )
    }
  }

  async getModerateRiskContactMessage(_state: State): Promise<string | void> {
    const organisationName = this.rootStore.configStore.organisationName
    if (this.clinicalStore.isModerateRisk) {
      return (
        `You indicated that you are potentially experiencing high levels of distress, or potentially at risk. ${organisationName} are not an emergency response service\n` +
        "If you need urgent support, please dial NHS 111 and select Option 2\n" +
        "In medical emergency and life threatening situations only, please dial 999 or attend your local A&E department\n" +
        "You can also contact Bucks Safe Haven that offers a safe and supportive alternative to A&E for adults (aged 18+) who are experiencing a mental health crisis. For High Wycombe (open 7 nights a week) contact 01494 218098 and for Aylesbury (open Sunday, Monday, Tuesday and Wednesday, contact 01296 453017\n\n" +
        "Other helplines available:\n" +
        "- You can contact Samaritans 24 hours a day, 365 days a year\n" +
        "- You can call 116 123 (free from any phone) or email jo@samaritans.org\n" +
        "If you would prefer not to talk but want some mental health support, you could text SHOUT to 85258"
      )
    }
  }

  async getAdminWillCallMessage(state: State): Promise<string | void> {
    const organisationName = this.rootStore.configStore.organisationName
    const organisationGenericPhoneNumber = this.rootStore.configStore.organisationGenericPhoneNumber
    const iaptName = this.getIAPTName(state) || organisationName
    if (state.referralSubmitted) {
      return (
        `If you have provided a mobile and given consent to receive SMS, you will shortly receive an SMS from ${iaptName} with a link to book your appointment\n` +
        "After booking, you will receive a confirmation email of your appointment with further information\n" +
        "If you do not receive a direct booking link via SMS, the service will contact you to make your appointment\n" +
        `Alternatively you can contact the service on ${organisationGenericPhoneNumber}\n` +
        "Mon - Thurs 7am - 6.30pm, Fri 7am - 5pm\n" +
        "\n" +
        "Please also be aware that if you require an interpreter, need specific assistance due to hearing difficulties or part of the Afghan or Ukrainian refugee settlement or are an Asylum Seeker, the appointments offered via the SMS link will not be suitable\n" +
        `Please contact us to book the appointment for you with the additional support required on ${organisationGenericPhoneNumber}\n` +
        "Mon - Thurs 7am - 6.30pm, Fri 7am - 5pm\n" +
        "If we do not hear from you within two working days, we will contact you to arrange an appointment"
      )
    }
  }

  async getCustomRecapMessage(state: State): Promise<StepResultBodyType[] | undefined> {
    const isCrisis = this.clinicalStore.isCrisis
    const name = this.getName(state)
    if (state.referralSubmitted) {
      const lastMessage = isCrisis
        ? `Thank you for sharing this information with me ${name}`
        : `Well done for taking this important step towards better mental health`
      return [lastMessage]
    }
    return [`Well done for taking this important step towards better mental health`]
  }
}

/* istanbul ignore next */
export default class GoodbyeBuckinghamshireDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeBuckinghamshire
  readonly name: string = "GoodbyeBuckinghamshireDialogue"

  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeBuckinghamshireDialogue.id, new GoodbyeBuckinghamshireScript(), state, snapshot)
  }
}
