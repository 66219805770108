import dialoguesRegistry, { IDialoguesRegistry } from "./dialoguesRegistry"
import invariant from "../utils/invariant"
import type Dialogue from "../backend/chatbot/Dialogue"
import type { IDialogueSnapshot } from "../backend/chatbot/Dialogue"

export default function hydrator<Key extends keyof IDialoguesRegistry>(
  id: Key,
  snapshot: IDialogueSnapshot
): Dialogue {
  const DialogueClass = dialoguesRegistry.get(id)
  invariant(DialogueClass, `Dialogue Class with id [${id}] not found`)
  invariant(
    DialogueClass.id === id,
    `Looking for dialogue with id [${id}] but got one with id [${DialogueClass.id}]`
  )
  return new DialogueClass(snapshot.state, snapshot)
}
