import { z, ZodSchema } from "zod"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../DialogueIDs"
import type { GoodbyeScriptState } from "./GoodbyeScript"
import GoodbyeScript, { GoodbyeScriptStateSchema } from "./GoodbyeScript"
import type {
  IStepData,
  IStepResult,
  StepResultBodyType
} from "../../../backend/chatbot/models/IStep"
import getNextWorkingDay from "../../../utils/getNextWorkingDay"
import moment from "moment"
import { LanguageCodes } from "@limbic/types"

interface State extends GoodbyeScriptState {
  paymentURL?: string
  recommendationRating?: string
}

export type GoodbyeVitalityScriptState = State

export const GoodbyeVitalityScriptStateSchema = GoodbyeScriptStateSchema.extend({
  paymentURL: z.string().optional(),
  recommendationRating: z.string().optional()
})

export class GoodbyeVitalityScript extends GoodbyeScript {
  readonly name: string = "GoodbyeVitalityScript"

  /** Script Steps */

  @step.logState
  async start(d: IStepData<State>): Promise<IStepResult> {
    this.referralStore.setIdleSubmissionActive(false)
    this.updateReferralType(d.state)
    return { nextStep: this.showPaymentLink }
  }

  @step.logState
  showPaymentLink(d: IStepData<State, true | undefined>): IStepResult {
    const name = this.getName(d.state)
    const great = d.response ? ["Great"] : []
    const hasPaymentURL = !!d.state.paymentURL
    const link = d.state.paymentURL
    const paymentURL = hasPaymentURL
      ? [
          "I'm now going to hand over to IPRS Health to tell you more",
          "As part of our Vitality Health policy, you may be liable for certain charges from this point onwards",
          `To get the most out of your appointment with your therapist click [here](${link}) to have these charges explained and provide your payment details, saving you time and getting the support you need, as fast as possible`
        ]
      : []
    return {
      body: this.t([...great, ...paymentURL], { name, link }),
      nextStep: this.sayNPSSurvey
    }
  }

  @step
  sayNPSSurvey(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "On the basis of your recent interaction how likely are you to recommend us to a friend or colleague?"
      ),
      prompt: {
        id: this.getPromptId("sayNPSSurvey"),
        type: "inlinePicker",
        choices: [
          { body: "0 - Not very likely", value: "0" },
          { body: "1", value: "1" },
          { body: "2", value: "2" },
          { body: "3", value: "3" },
          { body: "4", value: "4" },
          { body: "5", value: "5" },
          { body: "6", value: "6" },
          { body: "7", value: "7" },
          { body: "8", value: "8" },
          { body: "9", value: "9" },
          { body: "10 - Very likely", value: "10" }
        ]
      },
      nextStep: this.handleNPSSurvey
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: GoodbyeVitalityScript) => {
    d.state.recommendationRating = d.response
    void script.referralStore.updateReferral({
      recommendationRating: d.response
    })
  })
  handleNPSSurvey(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.showRecapMessage }
  }

  @step.logState
  async showRecapMessage(d: IStepData<State>): Promise<IStepResult> {
    const body: StepResultBodyType[] = []
    const introMessage = await this.getIntroMessage(d.state)
    introMessage && body.push(introMessage)
    const customRecap = await this.getCustomRecapMessage?.(d.state)
    customRecap?.length && body.push(...customRecap)
    const recapMessage = await this.getRecapMessage(d.state)
    recapMessage && body.push(recapMessage)

    if (!body.length) return { nextStep: this.checkForFeedback }

    const choices: any[] = [
      { body: "Okay", value: true },
      recapMessage && { body: "I understand", value: true }
    ].filter(Boolean)

    return {
      body: this.t(body),
      prompt: {
        id: this.getPromptId("showRecapMessage"),
        trackResponse: true,
        type: "inlinePicker",
        choices
      },
      nextStep: this.checkForFeedback
    }
  }

  @step
  checkForFeedback(d: IStepData<State>): IStepResult {
    if (d.state.referralSubmitted) return { nextStep: this.askFeedback }
    return { nextStep: this.sayFinalWords }
  }

  @step
  async sayGoodBye(d: IStepData<State>): Promise<IStepResult> {
    // skip the dynamic link - we show
    // it in the custom recap message and
    // skip favorite quotes
    const result = await super.sayGoodBye(d)
    if (!d.state.referralSubmitted) delete result.body // don't say "well done for taking this step"
    return { ...result, nextStep: this.sayFinalWords }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return GoodbyeVitalityScriptStateSchema
  }

  async getCustomRecapMessage(state: State): Promise<StepResultBodyType[] | undefined> {
    if (state.referralSubmitted) {
      return undefined
    }

    if (!state.referralSubmitted) {
      const name = this.getName(state)
      return this.t(["I'm sorry I wasn't able to help you today {name}"], { name })
    }
  }

  async getIntroMessage(state: State): Promise<string | void> {
    const isCrisis = this.clinicalStore.isCrisis
    const name = this.getName(state)
    // If referral failed we cannot say any of the following messages
    if (state.referralSubmitted) {
      return this.t(
        isCrisis
          ? "Thank you for sharing this information with me {name}"
          : "Well, it's been a pleasure getting to know you {name}",
        { name }
      )
    }
  }

  async getReferredYouMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted) {
      const appointmentSet = this.referralStore.getCustomField("appointmentSet")
      const serviceName = this.rootStore.configStore.serviceName
      const organisationName = this.rootStore.configStore.organisationName
      if (appointmentSet) {
        const timestamps = this.referralStore.getCustomField("appointmentTimestamps")
        const [timeStart] = timestamps!.split("&&")
        const start = moment(timeStart).format("DD/MM/YYYY H:mm")
        return this.t(
          state.isIdleSubmitted //
            ? "It looks like there hasn't been any activity for some time so I've shared your responses with the {serviceName} team\n\n{organisationName} customer service team will be in contact with you within 1 working day to progress your referral\n\nIf you want to speak to them sooner they can be contacted Monday-Friday 9am-5pm on 0800 2545244"
            : "I've referred you to {serviceName} and your appointment is booked for {start}",
          { serviceName, organisationName, start }
        )
      }
      if (this.clinicalStore.isCrisis) {
        return undefined
      }
      return this.t(
        state.isIdleSubmitted
          ? "It looks like there hasn't been any activity for some time so I've shared your responses with the {serviceName} team\n\n{organisationName} customer service team will be in contact with you within 1 working day to progress your referral\n\nIf you want to speak to them sooner they can be contacted Monday-Friday 9am-5pm on 0800 2545244"
          : "I've referred you to {serviceName}. Someone from our admin team will be in touch the next working day to book you in\n\nIf you want to speak to them sooner they can be contacted Monday-Friday 9am-5pm on 0800 2545244",
        { serviceName, organisationName }
      )
    }
  }

  async getHighRiskContactMessage(state: State): Promise<string | void> {
    // If referral failed they cannot contact the user (no phone number)
    if (state.referralSubmitted && this.clinicalStore.isHighRisk) {
      const organisationName = this.rootStore.configStore.organisationName
      const nextWorkingDay = await getNextWorkingDay()
      return this.t(
        this.rootStore.applicationStore.translator?.language &&
          this.rootStore.applicationStore.translator.language === LanguageCodes.EN
          ? "Because you identified as being in crisis, someone from the {organisationName} duty clinical team will call you {nextWorkingDay}. Please remember, Limbic is not an emergency response service and you are encouraged to contact 999 if you feel you are in danger"
          : "Because you identified as being in crisis, someone from the {organisationName} duty clinical team will call you within the next working day. Please remember, Limbic is not an emergency response service and you are encouraged to contact 999 if you feel you are in danger",
        { organisationName, nextWorkingDay }
      )
    }
  }

  async getModerateRiskContactMessage(state: State): Promise<string | void> {
    // If referral failed they cannot contact the user (no phone number)
    if (state.referralSubmitted && this.clinicalStore.isModerateRisk) {
      const organisationName = this.rootStore.configStore.organisationName
      const nextWorkingDay = await getNextWorkingDay()
      return this.t(
        this.rootStore.applicationStore.translator?.language === LanguageCodes.EN
          ? "Because you identified as being in crisis, someone from the {organisationName} duty clinical team will call you {nextWorkingDay}. Please remember, Limbic is not an emergency response service and you are encouraged to contact 999 if you feel you are in danger"
          : "Because you identified as being in crisis, someone from the {organisationName} duty clinical team will call you within the next working day. Please remember, Limbic is not an emergency response service and you are encouraged to contact 999 if you feel you are in danger",
        { organisationName, nextWorkingDay }
      )
    }
  }
}

/* istanbul ignore next */
export default class GoodbyeVitalityDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeVitality
  readonly name: string = "GoodbyeVitalityDialogue"

  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeVitalityDialogue.id, new GoodbyeVitalityScript(), state, snapshot)
  }
}
