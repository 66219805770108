import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import type { EligibilityCheckIAPTState } from "./EligibilityCheckIAPTDialogue"
import { EligibilityCheckIAPTScript } from "./EligibilityCheckIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

const UNDO_ENABLED = process.env.REACT_APP_UNDO === "enabled"

type SEEKING_SUPPORT_FOR = "myself" | "over16" | "under16"

interface State extends EligibilityCheckIAPTState {
  seekingSupportFor?: SEEKING_SUPPORT_FOR
  isHealthOrSocialWorker?: boolean
  individualOrTeam?: "team" | "individual"
}

export type EligibilityCheckGMHubScriptState = State

export class EligibilityCheckGMHubScript extends EligibilityCheckIAPTScript {
  readonly name: string = "EligibilityCheckGMHubScript"

  /** Script Steps */

  @step.logState
  startEligibilityCheck(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askAreYouSeekingSupportForYourSelf }
  }

  @step.logState
  askAreYouSeekingSupportForYourSelf(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Are you seeking wellbeing support for yourself or someone else?"),
      prompt: {
        id: this.getPromptId("askAreYouSeekingSupportForYourSelf"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: this.t("Myself"), value: "myself" },
          { body: this.t("Someone else (over 16)"), value: "over16" },
          { body: this.t("Someone else (under 16)"), value: "under16" }
        ]
      },
      nextStep: this.handleAreYouSeekingSupportForYourSelf
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, SEEKING_SUPPORT_FOR>, script: EligibilityCheckGMHubScript) => {
      d.state.seekingSupportFor = d.response
      script.referralStore.setCustomField<State>("seekingSupportFor", d.response)
    }
  )
  handleAreYouSeekingSupportForYourSelf(d: IStepData<State, SEEKING_SUPPORT_FOR>): IStepResult {
    if (d.response === "under16") {
      this.wellbeingHubStore.setWellbeingHubUserType("managerIndividual")
      return { nextStep: this.askFamilyMemberOrWorksInHealthCare }
    }
    if (d.response === "over16") {
      return { nextStep: this.askIndividualOrTeam }
    }
    return { nextStep: this.askAreYouAHealthOrSocialWorker }
  }

  @step.logState
  askFamilyMemberOrWorksInHealthCare(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Is the young person you are seeking support for a close family member of someone who works in health or care?"
      ),
      prompt: {
        id: this.getPromptId("askFamilyMemberOrWorksInHealthCare"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleFamilyMemberOrWorksInHealthCare
    }
  }

  @step.logState
  handleFamilyMemberOrWorksInHealthCare(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.sayEligibleForManualSelfReferral }
    }
    return { nextStep: this.sayNotEligible }
  }

  @step.logState
  askIndividualOrTeam(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Are you looking for wellbeing support for an individual or a team?"),
      prompt: {
        id: this.getPromptId("askIndividualOrTeam"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: false,
        choices: [
          { body: this.t("Individual in my team /organisation"), value: "individual" },
          { body: this.t("Team in my organisation"), value: "team" }
        ]
      },
      nextStep: this.handleIndividualOrTeam
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, "team" | "individual">, script: EligibilityCheckGMHubScript) => {
      d.state.individualOrTeam = d.response
      script.referralStore.setCustomField<State>("seekingSupportFor", d.response)
    }
  )
  handleIndividualOrTeam(d: IStepData<State, "team" | "individual">): IStepResult {
    if (d.response === "team") {
      return { nextStep: this.goToSelfReferralManagerTeam }
    }
    return { nextStep: this.goToSelfReferralManagerIndividual }
  }

  @step.logState
  sayEligibleForManualSelfReferral(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const phoneNumbers = this.rootStore.configStore.organisationPhoneNumbers
    const iaptName = this.getIAPTName(d.state) || organisationName

    // TODO: Set this in referral form or config file
    const url = "https://www.penninecare.nhs.uk/c19screening"

    return {
      body: this.t(
        [
          this.wellbeingHubStore.userType === "individual"
            ? "Thanks. Good news - you're eligible for free support for young people from {iaptName}"
            : "Thanks. Good news - they're eligible for free support for young people from {iaptName}",
          "The links to refer someone else under 16 years old are [here]({url})",
          "For children aged between 14 and 15 or 8-13, you can find the related questionnaires on the left of the screen",
          "They'll need a parent or carer to help them",
          "Call on {phoneNumbers} or [email](GM.help@nhs.net) the hub directly to discuss needs of children under 8"
        ],
        { iaptName, url, phoneNumbers }
      ),
      prompt: {
        id: this.getPromptId("sayEligibleForManualSelfReferral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }, { body: this.t("I understand") }]
      },
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  sayYoureEligible(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    return {
      body: this.t(
        d.state.iaptManuallySelected
          ? "Thanks"
          : this.wellbeingHubStore.userType === "individual"
            ? "Thanks. Good news - you're eligible for free support from {iaptName}"
            : "Thanks. Good news - they're eligible for free support from {iaptName}",
        { iaptName }
      ),
      nextStep: this.end
    }
  }

  @step.logState
  askAreYouAHealthOrSocialWorker(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Are you a health or social care staff member living or working in the Greater Manchester area?"
      ),
      prompt: {
        id: this.getPromptId("askAreYouAHealthOrSocialWorker"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleAreYouAHealthOrSocialWorker
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: EligibilityCheckGMHubScript) => {
    d.state.isHealthOrSocialWorker = d.response
    script.referralStore.setCustomField<State>("seekingSupportFor", d.response)
  })
  handleAreYouAHealthOrSocialWorker(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.goToSelfReferralIndividual }
    }
    return { nextStep: this.askWorkDetails }
  }

  @step.logState
  askWorkDetails(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Do any of the following apply to you?"),
      prompt: {
        id: this.getPromptId("askWorkDetails"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: this.t("I work at the Critical Care Unit in Macclesfield"), value: true },
          {
            body: this.t(
              "I'm a close family member of a health/social care worker who lives or works in the Greater Manchester area"
            ),
            value: true
          },
          {
            body: this.t(
              "I'm retired and used to work for the NHS/Social Care sector in Greater Manchester at some point since March 2020"
            ),
            value: true
          },
          { body: this.t("I work for the North West Ambulance Service"), value: true },
          { body: this.t("I work at Nightingale Hospital North West"), value: true },
          { body: this.t("None of the above"), value: false }
        ]
      },
      nextStep: this.handleWorkDetails
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: EligibilityCheckGMHubScript) => {
    d.state.isHealthOrSocialWorker = d.response
    script.referralStore.setCustomField<State>("seekingSupportFor", d.response)
  })
  handleWorkDetails(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.goToSelfReferralIndividual }
    }
    return { nextStep: this.sayNotEligible }
  }

  @step.logState
  sayNotEligible(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        this.wellbeingHubStore.userType === "individual"
          ? `I'm sorry but it seems that you are not eligible for a self referral`
          : `I'm sorry but it seems that they are not eligible for a self referral`,
        this.wellbeingHubStore.userType === "individual"
          ? "You can visit the following links for more information on support for your case"
          : "You can visit the following links for more information on support for their case",
        "[Support for Residents and Vulnerable People](https://www.greatermanchester-ca.gov.uk/what-we-do/health/coronavirus/support-for-residents-and-vulnerable-people/)",
        "[Find a Psychological Therapies Service](https://www.nhs.uk/service-search/find-a-psychological-therapies-service/)",
        "You can also contact us by \nPhone: [03330 095 071](tel:03330095071) \nEmail: GM.help@nhs.net"
      ]),
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  goToSelfReferralManagerTeam(_d: IStepData<State>): IStepResult {
    this.wellbeingHubStore.setWellbeingHubUserType("managerTeam")
    return {
      nextStep: this.end
    }
  }

  @step.logState
  goToSelfReferralManagerIndividual(_d: IStepData<State>): IStepResult {
    this.wellbeingHubStore.setWellbeingHubUserType("managerIndividual")
    return {
      nextStep: this.end
    }
  }

  @step.logState
  goToSelfReferralIndividual(_d: IStepData<State>): IStepResult {
    this.wellbeingHubStore.setWellbeingHubUserType("individual")
    return { nextStep: this.end }
  }

  @step.logState
  checkAgeThresholds(d: IStepData<State>): IStepResult {
    const age = this.getUserAge(d.state)
    const ageThreshold = this.rootStore.configStore.ageThreshold
    if (Number(ageThreshold) > age) {
      this.setUnderAged(d.state, true)
      return { nextStep: this.checkEligibility }
    }
    d.state.isUnderAged = undefined
    d.state.isEligible = undefined
    d.state.signPostToManualReferral = undefined
    return { nextStep: this.startEligibilityCheck }
  }

  @step
  sayINeedToAskSomeDetails(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: this.t(
        UNDO_ENABLED
          ? [
              "In order to create a referral to {organisationName}, I just need to confirm a few details with you",
              "You can re-type your answer at any point by clicking the 'Undo' button next to the message you want to change"
            ]
          : "In order to create a referral to {organisationName}, I just need to confirm a few details with you",
        { organisationName }
      ),
      nextStep: this.askBirthday
    }
  }
}

export default class EligibilityCheckGMHubDialogue extends Dialogue<State> {
  static id = DialogueIDs.EligibilityCheckGMHub
  readonly name: string = "EligibilityCheckGMHubDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(EligibilityCheckGMHubDialogue.id, new EligibilityCheckGMHubScript(), state, snapshot)
  }
}
