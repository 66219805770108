import { IDashboardDoc } from "@limbic/types"
import self from "./getServiceByAPIKeyWithVersion"
import invariant from "../../../utils/invariant"
import Logger from "../../../utils/Logger"
import delay from "../../../utils/delay"
import client from "./_client"

const TOTAL_RETRIES = 2

export default async function getServiceByAPIKeyWithVersion(
  botAPIKey: string,
  version: "draft" | "published",
  retry = 0
): Promise<IDashboardDoc> {
  invariant(botAPIKey, "Cannot fetch bot config without a serviceApiKey")

  try {
    return await client.get(`/v1/dashboard/botkey/${botAPIKey}/${version}`)
  } catch (e) {
    Logger.getInstance().exception(e, "getServiceByAPIKeyWithVersion fetch failed")
    if (retry < TOTAL_RETRIES) {
      Logger.getInstance().message("getServiceByAPIKeyWithVersion retry")
      await delay(1)
      return await self(botAPIKey, version, retry + 1)
    }
    throw e
  }
}
