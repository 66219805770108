import moment from "moment"
import { z, ZodSchema } from "zod"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import SelfReferralIAPTScript, { SelfReferralIAPTScriptStateSchema } from "./SelfReferralIAPTScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { SelfReferralIAPTScriptState } from "./SelfReferralIAPTScript"
import type { IStep, IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type {
  IDefaultChatFlowSettingsCheckPostCodeFromAddressLookup,
  ETHNICITY_MAYDEN_MPFT,
  ARMED_FORCES_MAYDEN_MPFT,
  RELIGION_MAYDEN_MPFT,
  SEXUALITY_MAYDEN_MPFT,
  GENDER_MAYDEN_MPFT,
  PERINATAL_MAYDEN_MPFT,
  LTC_MAYDEN_MPFT,
  DISABILITY_MAYDEN_MPFT
} from "@limbic/types"
import { isValidLandlineNumber, isValidMobilePhone } from "../../../utils/isValidPhoneNumber"
import invariant from "../../../utils/invariant"
import { ICollectDisabilitiesSettings } from "../ad-hoc/CollectDisabilities/CollectDisabilitiesDialogue"
import {
  CollectGenderScriptState,
  ICollectGenderSettings
} from "../ad-hoc/CollectGender/CollectGenderDialogue"
import { ICollectLongTermMedicalConditionSettings } from "../ad-hoc/CollectLongTermMedicalConditionDetails/CollectLongTermMedicalConditionDetailsDialogue"
import { ICollectEmailSettings } from "../ad-hoc/CollectEmail/CollectEmailDialogue"
import {
  CollectMainIssueScriptState,
  ICollectMainIssueSettings
} from "../ad-hoc/CollectMainIssue/CollectMainIssueDialogue"
import {
  CollectLanguageAndInterpreterScriptState,
  ICollectLanguageAndInterpreterSettings
} from "../ad-hoc/CollectLanguageAndInterpreter/CollectLanguageAndInterpreterDialogue"
import {
  CollectGoalForTherapyScriptState,
  ICollectGoalForTherapySettings
} from "../ad-hoc/CollectGoalForTherapy/CollectGoalForTherapyDialogue"
import {
  ethnicities,
  exArmedForces,
  genders,
  perinatalStatuses,
  sexualities,
  religions,
  disabilities,
  ltcs
} from "../../../config/referralForms/mpft-form"
import { MPFTTitles, TrackingEvents } from "../../../models/Constants"
import { removeCountryCodeFromPhoneNumber } from "../../../utils/formatPhoneNumber"
import { PronounsMpft } from "@limbic/types/dist/access/remote/mayden/constants"
import { ICollectAlcoholConsumptionSettings } from "../ad-hoc/CollectAlcoholConsumption/CollectAlcoholConsumptionDialogue"
import { parsePhoneNumber } from "awesome-phonenumber"

type AccessibilityConsideration =
  | "No impairment"
  | "Visual impairment"
  | "Hearing impairment"
  | "Other"

interface State extends SelfReferralIAPTScriptState {
  mainIssue?: string
  mhSupport?: boolean
  mhSupportDetails?: string
  preferredTitle?: MPFTTitles
  preferredPronouns?: PronounsMpft
  emailQuestionnairesPermission?: boolean
  emailCorrespondencePermission?: boolean
  permissionToContactGP?: boolean
  requiresInterpreter?: boolean
  interpreterLanguage?: string
  signLanguageInterpreter?: boolean
  accessibilityIssue?: AccessibilityConsideration
  accessibilityIssueOther?: string
  drugsToManageMood?: boolean
  ltcOtherDetails?: string
  therapyGoal?: string
  usesRecreationalDrugs?: boolean
  usesPrescribedSubstances?: boolean
  canSendTextMessagesToMobilePhoneNumber?: boolean
  canLeaveVoicemailToMobilePhoneNumber?: boolean
  canLeaveVoicemailToHomePhoneNumber?: boolean
  homePhoneNumber?: string
  mobilePhoneNumber?: string
}

export type SelfReferralMPFTState = State

export const SelfReferralMPFTStateSchema = SelfReferralIAPTScriptStateSchema.extend({
  mainIssue: z.string().optional(),
  mhSupport: z.boolean().optional(),
  mhSupportDetails: z.string().optional(),
  mhDiagnosis: z.boolean().optional(),
  emailPermission: z.boolean().optional(),
  requiresInterpreter: z.boolean().optional(),
  interpreterLanguage: z.string().optional(),
  signLanguageInterpreter: z.boolean().optional(),
  accessibilityIssue: z.string().optional(),
  accessibilityIssueOther: z.string().optional(),
  drugsToManageMood: z.boolean().optional(),
  ltcOtherDetails: z.string().optional(),
  preferredTitle: z
    .union([
      z.literal("Mr"),
      z.literal("Mrs"),
      z.literal("Miss"),
      z.literal("Ms"),
      z.literal("Dr"),
      z.literal("Rev"),
      z.literal("Prof"),
      z.literal("Mx"),
      z.literal("Sir"),
      z.literal("Lady")
    ])
    .optional(),
  preferredPronouns: z
    .union([
      z.literal("She/Her/Her"),
      z.literal("He/Him/His"),
      z.literal("They/Them/Their"),
      z.literal("Ae/Aer/Aer"),
      z.literal("Ey/Em/Eir"),
      z.literal("Fae/Faer/Faer"),
      z.literal("Per/Per/Pers"),
      z.literal("Ve/Ver/Vers"),
      z.literal("Xe/Xem/Xyr"),
      z.literal("Ze/Hir/Hir")
    ])
    .optional(),
  therapyGoal: z.string().optional(),
  usesNonPrescribedSubstances: z.boolean().optional(),
  usesPrescribedSubstances: z.boolean().optional(),
  canSendTextMessagesToMobilePhoneNumber: z.boolean().optional(),
  canLeaveVoicemailToMobilePhoneNumber: z.boolean().optional(),
  canLeaveVoicemailToHomePhoneNumber: z.boolean().optional(),
  homePhoneNumber: z.string().optional(),
  mobilePhoneNumber: z.string().optional()
})

export class SelfReferralMPFTScript extends SelfReferralIAPTScript {
  readonly name: string = "SelfReferralMPFTScript"

  /** Script Steps */

  @step.logState
  startSelfReferralPart1(d: IStepData<State>): IStepResult {
    return { nextStep: this.goToCollectNHSNumber }
  }

  @step.logState
  askPermissionToSendEmail(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you happy to receive pre-appointment questionnaires to this email address?",
      prompt: {
        id: this.getPromptId("askPermissionToSendEmail"),
        trackResponse: true,
        type: "checkbox",
        options: [
          {
            body: "I'm happy to receive pre-appointment questionnaires to this email address",
            key: "emailQuestionnaires"
          },
          {
            body: "I'm happy to receive correspondence about my appointments and care to this email address",
            key: "emailCorrespondence"
          }
        ]
      },
      nextStep: this.handlePermissionToSendEmail
    }
  }

  @step.logState
  handlePermissionToSendEmail(
    d: IStepData<State, { emailQuestionnaires: boolean; emailCorrespondence: boolean }>
  ): IStepResult {
    d.state.emailQuestionnairesPermission = d.response.emailQuestionnaires
    d.state.emailCorrespondencePermission = d.response.emailCorrespondence
    return { nextStep: this.goToCheckPostCodeForAddressLookup }
  }

  @step.logState
  askMHSupport(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you in contact with any other mental health services",
      prompt: {
        id: this.getPromptId("askMHSupport"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askMHSupport"
      },
      nextStep: this.handleMHSupport
    }
  }

  @step.logState
  handleMHSupport(d: IStepData<State, boolean>): IStepResult {
    d.state.mhSupport = d.response
    if (d.response) return { nextStep: this.askMHSupportDetails }
    return { nextStep: this.askExArmedForces }
  }

  @step.logState
  askMHSupportDetails(_d: IStepData<State>): IStepResult {
    return {
      body: "Can you tell me which services you are in contact with?",
      prompt: {
        id: this.getPromptId("askMHSupportDetails"),
        trackResponse: true,
        type: "text"
      },
      nextStep: this.handleMHSupportDetailsWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.mhSupportDetails = d.response
  })
  @step.checkInputForCrisis({ getNextStep: (s: SelfReferralMPFTScript) => s.askExArmedForces })
  handleMHSupportDetailsWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askExArmedForces }
  }

  @step.logState
  startSelfReferralPart2(_d: IStepData<State>): IStepResult {
    return {
      body: "Thank you. We now need to ask you some demographic questions. We ask this in order to ensure we are equally supporting all members of our community",
      nextStep: this.goToCollectGender
    }
  }

  @step.logState
  askAccessibilityConsiderations(_d: IStepData<State>): IStepResult {
    return {
      body: "Are there any adjustments we could provide that would help you to access treatment, e.g. difficulty with stairs or certain colour paper or fonts?",
      prompt: {
        id: this.getPromptId("askAccessibilityConsiderations"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "No impairment", value: "No impairment" },
          { body: "Visual impairment", value: "Visual impairment" },
          { body: "Hearing impairment", value: "Hearing impairment" }
        ],
        textPrompt: { forceValue: true },
        dataPointsName: "askAccessibilityConsiderations"
      },
      nextStep: this.handleAccessibilityConsiderationsWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, AccessibilityConsideration | string>) => {
    if (["No impairment", "Visual impairment", "Hearing impairment"].includes(d.response)) {
      d.state.accessibilityIssue = d.response as AccessibilityConsideration
    } else {
      d.state.accessibilityIssue = "Other" as AccessibilityConsideration
      d.state.accessibilityIssueOther = d.response
    }
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralMPFTScript) => s.goToCollectLongTermMedicalConditionDetails
  })
  handleAccessibilityConsiderationsWithCrisis(
    _d: IStepData<State, AccessibilityConsideration | string>
  ): IStepResult {
    return { nextStep: this.goToCollectLongTermMedicalConditionDetails }
  }

  @step.logState
  sayThankYouLetsContinue(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `Thank you for sharing, I know that may have been difficult. You're making good progress ${name}, let's continue with the referral`,
      nextStep: this.goToCollectAlcoholConsumption
    }
  }

  @step.logState
  askRecreationalDrugs(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you currently using any non-prescribed substances, eg. MDMA, Cannabis, Co2 canisters, other controlled drugs?",
      prompt: {
        id: this.getPromptId("askRecreationalDrugs"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleRecreationalDrugs
    }
  }

  @step.logState
  handleRecreationalDrugs(d: IStepData<State, boolean>): IStepResult {
    d.state.usesRecreationalDrugs = d.response
    return { nextStep: this.askPrescribedSubstances }
  }

  @step.logState
  askPrescribedSubstances(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you currently taking any medication that has been prescribed by a doctor?",
      prompt: {
        id: this.getPromptId("askPrescribedSubstances"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handlePrescribedSubstances
    }
  }

  @step.logState
  handlePrescribedSubstances(d: IStepData<State, boolean>): IStepResult {
    d.state.usesPrescribedSubstances = d.response
    return { nextStep: this.doReferralSubmission }
  }

  @step.logState
  checkEmail(d: IStepData<State>): IStepResult {
    if (d.state.email) {
      return { nextStep: this.askPermissionToSendEmail }
    }
    return { nextStep: this.goToCheckPostCodeForAddressLookup }
  }

  @step.logState
  askPerinatal(d: IStepData<State>): IStepResult {
    const perinatalStatuses = this.getPerinatalStatuses(d.state)
    if (!perinatalStatuses?.length) {
      this.logBreadcrumb("PERINATAL STATUSES NOT FOUND", d.state, { perinatalStatuses })
      this.logMessage("PERINATAL STATUSES NOT FOUND")
      return { nextStep: this.goToCollectMainIssue }
    }
    return {
      body: this.t([
        "Are you currently pregnant, or do you have a child under the age of 1?",
        "This includes if you are a father, co-parent or primary care giver"
      ]),
      prompt: {
        id: this.getPromptId("askPerinatal"),
        trackResponse: true,
        type: "inlinePicker",
        choices: perinatalStatuses.map(g => ({ body: this.t(g), value: g })),
        dataPointsName: "askPerinatal"
      },
      nextStep: this.handlePerinatal
    }
  }

  /* Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return SelfReferralMPFTStateSchema
  }

  async onHandleExArmedForces(_state: State): Promise<IStepResult | void> {
    return { nextStep: this.startSelfReferralPart2 }
  }

  async onHandlePerinatal(_state: State): Promise<IStepResult | void> {
    return { nextStep: this.goToCollectMainIssue }
  }

  async onSubmitReferralData(state: State): Promise<boolean> {
    try {
      const payload = await this.getReferralPayload(state)
      await this.referralStore.createReferral(payload)
      this.referralStore.setShouldHavePatientIdAndInstanceId(true)
      state.patientId = this.referralStore.patientId
      state.signupCode = this.referralStore.signupCode
      this.track(TrackingEvents.SELF_REFERRAL_SUBMITTED)
    } catch (e) {
      this.referralStore.setShouldHavePatientIdAndInstanceId(false)
      this.logException(e, "onSubmitReferralData")
      return false
    }

    return true
  }

  async getReferralPayload(state: State): Promise<any> {
    const instanceID = state.iapt?.backendInstanceID
    invariant(instanceID, "Cannot create referral without an Instance ID")
    /**
     * In CollectPhoneNumberMPFT we have two questions for phone numbers:
     * - Home phone number (state.homePhoneNumber)
     * - Mobile phone number (state.mobilePhoneNumber)
     */
    const hasValidMobile =
      state.mobilePhoneNumber && isValidMobilePhone(state.mobilePhoneNumber || "0")
    const hasValidLandline =
      state.homePhoneNumber && isValidLandlineNumber(state.homePhoneNumber || "0")
    const parsedMobile = parsePhoneNumber(state.mobilePhoneNumber || "0", { regionCode: "GB" })
    const parsedLandline = parsePhoneNumber(state.homePhoneNumber || "0", { regionCode: "GB" })

    return {
      instanceID,
      nameFirst: this.getName(state),
      nameLast: this.getLastName(state),
      nameFirstPreferred: state.preferredName,
      title: state.preferredTitle,
      pronounsPreferred: state.preferredPronouns,
      whereHeard: state.whereDidYouHearAboutService,
      dob: moment(state.birthday).format("YYYY-MM-DD"),
      addressHome: {
        address1: state.address,
        address2: state.address2,
        // If address is entered manually then city/county/postcode are undefined
        // Pass an alternate value to avoid errors in the referral submission
        city: state.city || state.address,
        county: state.county || state.address || "n/a",
        postcode: state.userPostcode?.postcode || state.invalidPostcodeEntered || "unknown",
        consentMail: !!state.canSendMailToAddress
      },
      gpName: state.odsGP?.name ?? state.gp?.name,
      gpAddress: state.odsGP?.address
        ? `${state.odsGP.address.address1}, ${state.odsGP.address.address2}, ${state.odsGP.address.city}, ${state.odsGP.address.postcode}`
        : `${state.gp?.address1}, ${state.gp?.address2}, ${state.gp?.postcode}`,
      gpPhone: removeCountryCodeFromPhoneNumber(state.odsGP?.phone[0] ?? state.gp?.phoneNumber),
      gpCodeNACS: state.odsGP?.id ?? state.gp?.nacsCode,
      phoneHome: hasValidLandline
        ? {
            cc: String(parsedLandline.countryCode || ""),
            number: parsedLandline.number?.national.replace(/ /g, "") ?? state.homePhoneNumber!,
            isMobile: false,
            consentVM: !!state.canLeaveVoicemailToHomePhoneNumber
          }
        : undefined,
      phoneMobile: hasValidMobile
        ? {
            cc: String(parsedMobile.countryCode || ""),
            number: parsedMobile.number?.national.replace(/ /g, "") ?? state.mobilePhoneNumber!,
            isMobile: true,
            consentSMS: !!state.canSendTextMessagesToMobilePhoneNumber,
            consentVM: !!state.canLeaveVoicemailToMobilePhoneNumber
          }
        : undefined,
      email: state.email,
      consentEmail: state.canSendEmail,
      nhsNumber: state.nhsNumber,
      consentDataShare: true,
      consentDataStore: true,
      output: this.referralStore.referralType,
      riskLevel: this.clinicalStore.riskLevel,
      riskLevelReason: this.clinicalStore.riskLevelReason,
      triggerWords: this.clinicalStore.triggerWords,
      alcohol: state.alcohol,
      alcoholFrequency: state.alcoholFrequency,
      alcoholQuantity: state.alcoholQuantity,
      ltc: this.getLTC(state),
      disability: this.getDisability(state),
      interpreter: state.requiresInterpreter,
      interpreterLanguage: state.interpreterLanguage,
      interpreterSignLanguage: state.signLanguageInterpreter,
      sexuality: this.getSexuality(state),
      gender: this.getGender(state),
      ethnicity: this.getEthnicity(state),
      armedForces: this.getArmedForce(state),
      religion: this.getReligion(state),
      problemInOwnWords: state.mainIssue,
      treatmentExpectation: state?.therapyGoal,
      perinatal: this.getPerinatal(state),
      questionnaires: this.getQuestionnairesPayload(state),
      consentResearch: state.consentResearch,
      currentSupport: state.mhSupport,
      currentSupportDetailsOther: state.mhSupportDetails,
      consentDataShareGP: state.permissionToContactGP,
      emailQuestionnaires: state.emailQuestionnairesPermission,
      emailCorrespondence: state.emailCorrespondencePermission,
      accessibilityIssue: state.accessibilityIssue,
      accessibilityIssueDetails: state.accessibilityIssueOther,
      substances: state.usesRecreationalDrugs,
      prescribedMedication: state.usesPrescribedSubstances
    }
  }

  getPerinatalMessage(state: State): IStepResult["body"] {
    return []
  }

  getEthnicity(state: State): ETHNICITY_MAYDEN_MPFT {
    return ethnicities[state.ethnicity!]
  }

  getArmedForce(state: State): ARMED_FORCES_MAYDEN_MPFT {
    return exArmedForces[state.isExArmedForces!]
  }

  getReligion(state: State): RELIGION_MAYDEN_MPFT {
    return religions[state.religion!]
  }

  getSexuality(state: State): SEXUALITY_MAYDEN_MPFT {
    return sexualities[state.sexuality!]
  }

  getGender(state: State): GENDER_MAYDEN_MPFT {
    return genders[state.gender!]
  }

  getPerinatal(state: State): PERINATAL_MAYDEN_MPFT {
    return perinatalStatuses[state.perinatalStatus!]
  }

  getLTC(state: State): LTC_MAYDEN_MPFT[] {
    const ltc = state.longTermMedicalCondition?.map(i => ltcs[i]).filter(Boolean)
    return ltc?.length ? ltc : []
  }

  getDisability(state: State): DISABILITY_MAYDEN_MPFT[] {
    return state.disabilities ? state.disabilities.map(d => disabilities[d]) : ["NONE"]
  }

  async getCollectDisabilitiesSettings(state: State): Promise<ICollectDisabilitiesSettings> {
    return {
      choicesMap: this.getDisabilities(state).map(d => ({ body: this.t(d), value: d })),
      shouldAskDisabilityStatus: true,
      multiSelectDisabilities: true
    }
  }

  async onCollectDisabilitiesEnded(_state: State): Promise<IStep> {
    return this.askAccessibilityConsiderations
  }

  async getCollectPostcodeAndAddressSettings(
    _state: State
  ): Promise<IDefaultChatFlowSettingsCheckPostCodeFromAddressLookup> {
    return {
      skipPermissionsQuestion: true,
      hideEarlierYouSaid: false
    }
  }

  async getCollectGenderSettings(state: State): Promise<ICollectGenderSettings> {
    const optionsGender = this.getGenders(state).map(g => ({ body: this.t(g), value: g }))
    const optionsGenderSameAsBirth = this.getGenderSameAsBirthValues(state) //
      .map(g => ({ body: this.t(g), value: g }))
    // This is temporary - to be replaced by actual values when we have the dashboard
    return {
      optionsGender,
      optionsGenderSameAsBirth
    }
  }

  async getCollectGenderState(state: State): Promise<CollectGenderScriptState> {
    return { skipSameGenderAsBirth: true, withTextPrompt: false }
  }

  async getCollectLongTermMedicalConditionSettings(
    state: State
  ): Promise<ICollectLongTermMedicalConditionSettings> {
    const result = await super.getCollectLongTermMedicalConditionSettings(state)
    return {
      ...result,
      includeOther: true,
      shouldAskOtherDetails: true,
      shouldAskDoesLTCAffectsMood: false,
      shouldAskHowMuchLTCAffectsMood: false,
      shouldAskHowWellYouManageYourLTC: false
    }
  }

  async onCollectLongTermMedicalConditionEnded(state: State): Promise<IStep> {
    return this.askPerinatal
  }

  async getCollectEmailSettings(_state: State): Promise<ICollectEmailSettings> {
    return {
      messages: {
        askDoYouWantToShareEmail: this.t(["Do you have an email address?"]),
        askEmailPermission: this.t([
          "Do you allow us to contact you over email (including unencrypted emails when required)?"
        ])
      },
      shouldAskEmailPermission: false,
      hasPreliminaryQuestion: true,
      customisableOptions: {
        askDoYouWantToShareEmail: [
          { body: this.t("Yes, I have an email"), value: true },
          { body: this.t("No, I don't have an email"), value: false }
        ]
      }
    }
  }

  async getCollectAlcoholConsumptionSettings(
    state: State
  ): Promise<ICollectAlcoholConsumptionSettings> {
    return {
      messages: {
        askAlcohol: ["Are you currently using alcohol?"]
      }
    }
  }

  async onCollectAlcoholConsumptionEnded(state: State): Promise<IStep> {
    return this.askRecreationalDrugs
  }

  async onCollectEmailEnded(state: State): Promise<IStep> {
    return this.checkEmail
  }

  async onCollectGenderEnded(state: State): Promise<IStep> {
    return this.goToCollectEthnicity
  }

  async onCollectEthnicityEnded(state: State): Promise<IStep> {
    return this.goToCollectSexuality
  }

  async onCollectReligionEnded(_state: State): Promise<IStep> {
    return this.goToCollectLanguageAndInterpreter
  }

  async getCollectMainIssueSettings(state: State): Promise<ICollectMainIssueSettings> {
    const name = this.getName(state)
    return {
      messages: {
        askMainIssue: this.t(
          [
            "Thanks. You're doing really well",
            "Please don't share any information about people other than yourself when answering the following 2 questions:",
            "What's the main issue that has brought you here today?",
            "(Please try to describe your thoughts, feelings, things that trouble you, and the impact this is having on your life in a few sentences)"
          ],
          { name }
        )
      }
    }
  }

  async getCollectMainIssueState(_state: State): Promise<CollectMainIssueScriptState> {
    return { hideClosingMessage: true, shouldUpdateReferral: false, forceMainIssueValue: true }
  }

  async onCollectMainIssueEnded(_state: State): Promise<IStep> {
    return this.goToCollectGoalForTherapy
  }

  async onCollectPhoneNumberEnded(state: State): Promise<IStep> {
    return this.goToCollectEmail
  }

  async getCollectLanguageAndInterpreterSettings(
    state: State
  ): Promise<ICollectLanguageAndInterpreterSettings> {
    const name = this.getName(state)
    const primaryLanguage = state.primaryLanguage
    return {
      options: this.getLanguages(state).map(l => ({ body: l, value: l })),
      messages: {
        askPrimaryLanguage: this.t(["What is your main / preferred spoken language?"]),
        askRequiresAnInterpreter: this.t(["Do you require an interpreter?"]),
        askInterpreterLanguage: this.t(
          [
            "So {name}, you stated your main spoken language is {primaryLanguage}",
            "Is this the language you need an interpreter for?",
            "(If not, just select the language you'd like an interpreter for)"
          ],
          { name, primaryLanguage }
        )
      }
    }
  }

  async getCollectLanguageAndInterpreterState(
    _state: State
  ): Promise<CollectLanguageAndInterpreterScriptState> {
    return {
      shouldAskAbleToCommunicateInEnglish: false,
      disableInterpreterLanguageQuestion: false,
      includeInterpreterLanguageTextPrompt: false
    }
  }

  async onCollectLanguageAndInterpreterEnded(state: State): Promise<IStep> {
    return this.goToCollectDisabilities
  }

  async getCollectGoalForTherapySettings(
    state: State
  ): Promise<void | ICollectGoalForTherapySettings> {
    return {
      messages: {
        askWhatIsYourGoal: this.t([
          "What would you like to achieve as your goal in having therapy?"
        ])
      }
    }
  }

  async getCollectGoalForTherapyState(state: State): Promise<CollectGoalForTherapyScriptState> {
    return {
      addClinicalNote: false,
      forceGoalForTherapyValue: true
    }
  }

  async onCollectGoalForTherapyEnded(state: State): Promise<IStep> {
    return this.sayThankYouLetsContinue
  }

  async onCollectPostcodeAndAddressEnded(state: State): Promise<IStep> {
    return this.askMHSupport
  }

  async onCollectSexualityEnded(state: State): Promise<IStep> {
    return this.goToCollectReligion
  }
}

/* istanbul ignore next */
export default class SelfReferralMPFTDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralMPFT
  readonly name: string = "SelfReferralMPFTDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(SelfReferralMPFTDialogue.id, new SelfReferralMPFTScript(), state, snapshot)
  }
}
