import { IAPTGPMap, IAPTIDs } from "../../models/IIAPTService"
import { CCGCode } from "../../models/ICCG"

// GP List here 👇
// https://docs.google.com/spreadsheets/d/1spZT95JZuPVR3nXeprdvy1c0J8dUh_nj/edit#gid=745553243
// CCG Codes here 👇
// https://docs.google.com/spreadsheets/d/1spZT95JZuPVR3nXeprdvy1c0J8dUh_nj/edit#gid=947485282
type EligibleCCGs =
  | CCGCode.E_SURREY
  | CCGCode.SURREY_DOWNS
  | CCGCode.GUILDFORD_WAVERLEY
  | CCGCode.NW_SURREY
  | CCGCode.SURREY_HEATH

export const gpsSABP: Record<string, EligibleCCGs> = {
  A99960: CCGCode.GUILDFORD_WAVERLEY,
  H81002: CCGCode.NW_SURREY,
  H81003: CCGCode.NW_SURREY,
  H81004: CCGCode.NW_SURREY,
  H81005: CCGCode.E_SURREY,
  H81006: CCGCode.GUILDFORD_WAVERLEY,
  H81007: CCGCode.NW_SURREY,
  H81009: CCGCode.NW_SURREY,
  H81010: CCGCode.GUILDFORD_WAVERLEY,
  H81011: CCGCode.SURREY_DOWNS,
  H81013: CCGCode.SURREY_HEATH,
  H81015: CCGCode.NW_SURREY,
  H81016: CCGCode.SURREY_DOWNS,
  H81017: CCGCode.SURREY_DOWNS,
  H81019: CCGCode.NW_SURREY,
  H81020: CCGCode.NW_SURREY,
  H81021: CCGCode.GUILDFORD_WAVERLEY,
  H81022: CCGCode.GUILDFORD_WAVERLEY,
  H81023: CCGCode.E_SURREY,
  H81024: CCGCode.NW_SURREY,
  H81025: CCGCode.NW_SURREY,
  H81026: CCGCode.GUILDFORD_WAVERLEY,
  H81028: CCGCode.SURREY_DOWNS,
  H81029: CCGCode.GUILDFORD_WAVERLEY,
  H81030: CCGCode.E_SURREY,
  H81031: CCGCode.GUILDFORD_WAVERLEY,
  H81032: CCGCode.NW_SURREY,
  H81033: CCGCode.NW_SURREY,
  H81034: CCGCode.NW_SURREY,
  H81035: CCGCode.GUILDFORD_WAVERLEY,
  H81036: CCGCode.NW_SURREY,
  H81037: CCGCode.E_SURREY,
  H81038: CCGCode.SURREY_DOWNS,
  H81039: CCGCode.SURREY_HEATH,
  H81040: CCGCode.SURREY_HEATH,
  H81041: CCGCode.NW_SURREY,
  H81042: CCGCode.NW_SURREY,
  H81043: CCGCode.GUILDFORD_WAVERLEY,
  H81044: CCGCode.GUILDFORD_WAVERLEY,
  H81045: CCGCode.E_SURREY,
  H81046: CCGCode.E_SURREY,
  H81048: CCGCode.E_SURREY,
  H81050: CCGCode.NW_SURREY,
  H81051: CCGCode.SURREY_DOWNS,
  H81052: CCGCode.GUILDFORD_WAVERLEY,
  H81053: CCGCode.GUILDFORD_WAVERLEY,
  H81054: CCGCode.NW_SURREY,
  H81055: CCGCode.E_SURREY,
  H81056: CCGCode.E_SURREY,
  H81057: CCGCode.NW_SURREY,
  H81058: CCGCode.E_SURREY,
  H81060: CCGCode.E_SURREY,
  H81061: CCGCode.NW_SURREY,
  H81062: CCGCode.GUILDFORD_WAVERLEY,
  H81064: CCGCode.GUILDFORD_WAVERLEY,
  H81065: CCGCode.NW_SURREY,
  H81066: CCGCode.NW_SURREY,
  H81067: CCGCode.SURREY_DOWNS,
  H81068: CCGCode.SURREY_DOWNS,
  H81069: CCGCode.SURREY_HEATH,
  H81070: CCGCode.SURREY_DOWNS,
  H81071: CCGCode.SURREY_DOWNS,
  H81072: CCGCode.SURREY_DOWNS,
  H81073: CCGCode.NW_SURREY,
  H81074: CCGCode.SURREY_DOWNS,
  H81075: CCGCode.SURREY_HEATH,
  H81076: CCGCode.GUILDFORD_WAVERLEY,
  H81077: CCGCode.GUILDFORD_WAVERLEY,
  H81078: CCGCode.SURREY_DOWNS,
  H81079: CCGCode.NW_SURREY,
  H81080: CCGCode.SURREY_DOWNS,
  H81081: CCGCode.SURREY_DOWNS,
  H81082: CCGCode.SURREY_HEATH,
  H81083: CCGCode.E_SURREY,
  H81084: CCGCode.GUILDFORD_WAVERLEY,
  H81085: CCGCode.GUILDFORD_WAVERLEY,
  H81086: CCGCode.SURREY_DOWNS,
  H81087: CCGCode.NW_SURREY,
  H81089: CCGCode.E_SURREY,
  H81090: CCGCode.GUILDFORD_WAVERLEY,
  H81091: CCGCode.SURREY_DOWNS,
  H81093: CCGCode.NW_SURREY,
  H81094: CCGCode.NW_SURREY,
  H81095: CCGCode.NW_SURREY,
  H81099: CCGCode.SURREY_DOWNS,
  H81101: CCGCode.E_SURREY,
  H81103: CCGCode.SURREY_DOWNS,
  H81104: CCGCode.NW_SURREY,
  H81107: CCGCode.SURREY_DOWNS,
  H81109: CCGCode.SURREY_DOWNS,
  H81111: CCGCode.NW_SURREY,
  H81113: CCGCode.SURREY_DOWNS,
  H81116: CCGCode.E_SURREY,
  H81118: CCGCode.SURREY_DOWNS,
  H81119: CCGCode.E_SURREY,
  H81122: CCGCode.NW_SURREY,
  H81123: CCGCode.NW_SURREY,
  H81125: CCGCode.SURREY_DOWNS,
  H81126: CCGCode.SURREY_DOWNS,
  H81128: CCGCode.SURREY_DOWNS,
  H81129: CCGCode.NW_SURREY,
  H81130: CCGCode.SURREY_HEATH,
  H81131: CCGCode.NW_SURREY,
  H81132: CCGCode.GUILDFORD_WAVERLEY,
  H81133: CCGCode.SURREY_DOWNS,
  H81134: CCGCode.NW_SURREY,
  H81611: CCGCode.SURREY_DOWNS,
  H81613: CCGCode.SURREY_DOWNS,
  H81618: CCGCode.SURREY_DOWNS,
  H81620: CCGCode.SURREY_HEATH,
  H81622: CCGCode.SURREY_HEATH,
  H81632: CCGCode.NW_SURREY,
  H81638: CCGCode.E_SURREY,
  H81641: CCGCode.NW_SURREY,
  H81642: CCGCode.NW_SURREY,
  H81643: CCGCode.NW_SURREY,
  H81644: CCGCode.SURREY_DOWNS,
  H81647: CCGCode.GUILDFORD_WAVERLEY,
  H81655: CCGCode.GUILDFORD_WAVERLEY,
  H81656: CCGCode.SURREY_DOWNS,
  H81658: CCGCode.NW_SURREY,
  H81663: CCGCode.NW_SURREY,
  H81664: CCGCode.NW_SURREY,
  H81667: CCGCode.E_SURREY,
  H81668: CCGCode.NW_SURREY,
  H81669: CCGCode.NW_SURREY,
  H81672: CCGCode.SURREY_DOWNS,
  Y00068: CCGCode.E_SURREY,
  Y00287: CCGCode.SURREY_DOWNS,
  Y00336: CCGCode.SURREY_DOWNS,
  Y00338: CCGCode.SURREY_DOWNS,
  Y00339: CCGCode.SURREY_DOWNS,
  Y00340: CCGCode.SURREY_DOWNS,
  Y00341: CCGCode.SURREY_DOWNS,
  Y00342: CCGCode.SURREY_DOWNS,
  Y00343: CCGCode.SURREY_DOWNS,
  Y00350: CCGCode.GUILDFORD_WAVERLEY,
  Y00640: CCGCode.NW_SURREY,
  Y00641: CCGCode.NW_SURREY,
  Y00882: CCGCode.E_SURREY,
  Y00951: CCGCode.GUILDFORD_WAVERLEY,
  Y01072: CCGCode.SURREY_DOWNS,
  Y01075: CCGCode.SURREY_DOWNS,
  Y01085: CCGCode.E_SURREY,
  Y01215: CCGCode.NW_SURREY,
  Y01250: CCGCode.SURREY_DOWNS,
  Y01260: CCGCode.SURREY_DOWNS,
  Y01298: CCGCode.SURREY_DOWNS,
  Y01660: CCGCode.NW_SURREY,
  Y01682: CCGCode.GUILDFORD_WAVERLEY,
  Y01689: CCGCode.NW_SURREY,
  Y01705: CCGCode.NW_SURREY,
  Y01878: CCGCode.E_SURREY,
  Y01879: CCGCode.E_SURREY,
  Y01916: CCGCode.SURREY_DOWNS,
  Y01918: CCGCode.SURREY_DOWNS,
  Y02005: CCGCode.GUILDFORD_WAVERLEY,
  Y02006: CCGCode.GUILDFORD_WAVERLEY,
  Y02007: CCGCode.GUILDFORD_WAVERLEY,
  Y02008: CCGCode.GUILDFORD_WAVERLEY,
  Y02092: CCGCode.GUILDFORD_WAVERLEY,
  Y02112: CCGCode.NW_SURREY,
  Y02194: CCGCode.E_SURREY,
  Y02206: CCGCode.SURREY_DOWNS,
  Y02364: CCGCode.SURREY_DOWNS,
  Y02475: CCGCode.NW_SURREY,
  Y02527: CCGCode.E_SURREY,
  Y02665: CCGCode.SURREY_DOWNS,
  Y02688: CCGCode.NW_SURREY,
  Y02714: CCGCode.SURREY_HEATH,
  Y02715: CCGCode.GUILDFORD_WAVERLEY,
  Y02840: CCGCode.SURREY_DOWNS,
  Y02848: CCGCode.NW_SURREY,
  Y02858: CCGCode.NW_SURREY,
  Y02859: CCGCode.NW_SURREY,
  Y02860: CCGCode.NW_SURREY,
  Y02861: CCGCode.NW_SURREY,
  Y02862: CCGCode.NW_SURREY,
  Y02863: CCGCode.NW_SURREY,
  Y02895: CCGCode.NW_SURREY,
  Y02905: CCGCode.NW_SURREY,
  Y02965: CCGCode.SURREY_HEATH,
  Y03105: CCGCode.NW_SURREY,
  Y03113: CCGCode.GUILDFORD_WAVERLEY,
  Y03115: CCGCode.NW_SURREY,
  Y03275: CCGCode.NW_SURREY,
  Y03276: CCGCode.NW_SURREY,
  Y03277: CCGCode.NW_SURREY,
  Y03278: CCGCode.NW_SURREY,
  Y03279: CCGCode.NW_SURREY,
  Y03280: CCGCode.NW_SURREY,
  Y03281: CCGCode.NW_SURREY,
  Y03515: CCGCode.SURREY_DOWNS,
  Y03612: CCGCode.NW_SURREY,
  Y04216: CCGCode.E_SURREY,
  Y04314: CCGCode.SURREY_HEATH,
  Y04315: CCGCode.SURREY_HEATH,
  Y04316: CCGCode.SURREY_HEATH,
  Y04317: CCGCode.GUILDFORD_WAVERLEY,
  Y04318: CCGCode.GUILDFORD_WAVERLEY,
  Y04319: CCGCode.GUILDFORD_WAVERLEY,
  Y04320: CCGCode.NW_SURREY,
  Y04321: CCGCode.NW_SURREY,
  Y04322: CCGCode.NW_SURREY,
  Y04364: CCGCode.SURREY_DOWNS,
  Y04500: CCGCode.GUILDFORD_WAVERLEY,
  Y04507: CCGCode.SURREY_DOWNS,
  Y04620: CCGCode.E_SURREY,
  Y04666: CCGCode.E_SURREY,
  Y04847: CCGCode.SURREY_DOWNS,
  Y05089: CCGCode.SURREY_DOWNS,
  Y05090: CCGCode.SURREY_DOWNS,
  Y05093: CCGCode.E_SURREY,
  Y05210: CCGCode.E_SURREY,
  Y05256: CCGCode.GUILDFORD_WAVERLEY,
  Y05271: CCGCode.NW_SURREY,
  Y05273: CCGCode.SURREY_DOWNS,
  Y05414: CCGCode.SURREY_DOWNS,
  Y05477: CCGCode.E_SURREY,
  Y05478: CCGCode.E_SURREY,
  Y05485: CCGCode.SURREY_DOWNS,
  Y05504: CCGCode.GUILDFORD_WAVERLEY,
  Y05550: CCGCode.SURREY_DOWNS,
  Y05680: CCGCode.GUILDFORD_WAVERLEY,
  Y05880: CCGCode.SURREY_DOWNS,
  Y05882: CCGCode.SURREY_DOWNS,
  Y05883: CCGCode.SURREY_DOWNS,
  Y05884: CCGCode.SURREY_DOWNS,
  Y06032: CCGCode.E_SURREY,
  Y06066: CCGCode.GUILDFORD_WAVERLEY,
  Y06069: CCGCode.GUILDFORD_WAVERLEY,
  Y06129: CCGCode.GUILDFORD_WAVERLEY,
  Y06137: CCGCode.NW_SURREY
}

function assignIAPT(): IAPTGPMap {
  let iaptGPMap: IAPTGPMap = {}
  Object.keys(gpsSABP).forEach(gp => {
    iaptGPMap = {
      [gp]: IAPTIDs.MIND_MATTERS_SAB,
      ...iaptGPMap
    }
  })
  return iaptGPMap!
}

export const iaptGPMapSABP: IAPTGPMap = assignIAPT()
