import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { IStepResult } from "../../../backend/chatbot/models/IStep"
import { AssessmentWithADSMScript, AssessmentWithADSMScriptState } from "./AssessmentWithADSMScript"

type State = AssessmentWithADSMScriptState

export class AssessmentCWScript extends AssessmentWithADSMScript {
  readonly name: string = "AssessmentCWScript"

  /** Generic Handlers */

  async onHandleIAPTPhobiaScale(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayReadyForWASAS }
  }

  async onHandleIAPTWorkAndSocialAdjustment(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayThatsEverything }
  }
}

export default class AssessmentCWDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentCW
  readonly name: string = "AssessmentCWDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentCWDialogue.id, new AssessmentCWScript(), state, snapshot)
  }
}
