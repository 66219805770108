import encodeURLParams from "./encodeURLParams"

const URL = "https://api.qrserver.com/v1/create-qr-code"

interface IQROptions {
  width: number
  height: number
}

export default function getQRCodeURL(data: string, options?: IQROptions): string {
  const { width = 150, height = 150 } = options || {}
  const size = `${width}x${height}`
  const params = encodeURLParams({ size, data })
  return `${URL}?${params}`
}
