import React from "react"
import DatePicker from "react-datepicker"
import moment from "moment"
import "react-datepicker/dist/react-datepicker.css"
import "./DatePickerInput.scss"
import TextInput from "./TextInput"

interface Props {
  past?: boolean
  future?: boolean
  onSubmit: (timestamp: number) => void
}

export default function DatePickerInput(props: Props): JSX.Element {
  const { past, future } = props
  const [date, setDate] = React.useState<Date | null>(null)
  const minDate = past === false ? new Date() : undefined
  const maxDate = future === false ? new Date() : undefined
  const onSubmit = React.useCallback(() => date && props.onSubmit(date.getTime()), [date, props])

  return (
    <DatePicker
      customInput={<InputWrapper date={date} onSubmit={onSubmit} />}
      withPortal
      fixedHeight
      showYearDropdown
      dropdownMode="select"
      dateFormatCalendar="MMMM"
      dateFormat="dd/MM/yyyy"
      selected={date}
      onSelect={setDate}
      minDate={minDate}
      maxDate={maxDate}
    />
  )
}

// This wrapping into a class, is necessary
// because the DatePicker component passes
// a ref to the customInput prop that you
// pass it and then React is complaining
// that you cannot pass refs to
// Functional Components.
class InputWrapper extends React.Component<any, any> {
  render() {
    const props = this.props
    return (
      <div className="lb-date-picker-input-text-input-container" onClick={props.onClick}>
        <TextInput
          disabled
          key={moment(props.date).format("DD/MM/YYYY")}
          value={props.date ? moment(props.date).format("DD/MM/YYYY") : undefined}
          placeholder={"Please enter your birth date"}
          onSubmit={props.onSubmit}
          clearOnSubmit={false}
          multiline={false}
        />
      </div>
    )
  }
}
