import { IneligibilityReason } from "@limbic/types"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { exArmedForces } from "../../../config/referralForms/lincolnshire-form"
import { TrackingEvents } from "../../../models/Constants"
import { DialogueIDs } from "../../DialogueIDs"
import {
  EligibilityCheckWithPDSScript,
  EligibilityCheckWithPDSScriptState
} from "./EligibilityCheckWithPDSScript"

type State = EligibilityCheckWithPDSScriptState

export type EligibilityCheckLincolnshireScriptState = State

export class EligibilityCheckLincolnshireScript extends EligibilityCheckWithPDSScript {
  readonly name: string = "EligibilityCheckLincolnshireScript"

  /** Script Steps */
  @step
  askExArmedForces(d: IStepData<State>): IStepResult {
    const exArmedForcesOptions = this.getExArmedForcesValues(d.state)
    if (!exArmedForcesOptions?.length) {
      this.logBreadcrumb("EX ARMED FORCES OPTIONS NOT FOUND", d.state, { exArmedForcesOptions })
      this.logMessage("EX ARMED FORCES OPTIONS NOT FOUND")
      return { nextStep: this.sayIntroToSpineSearch }
    }
    return {
      body: this.t("Have you ever served in the British Armed Forces?"),
      prompt: {
        id: this.getPromptId("askExArmedForces"),
        trackResponse: true,
        type: "inlinePicker",
        choices: exArmedForcesOptions.map(g => ({ body: this.t(this.getChoiceBody(g)), value: g })),
        dataPointsName: "askExArmedForces"
      },
      nextStep: this.handleExArmedForces
    }
  }

  @step
  async handleExArmedForces(d: IStepData<State, string>): Promise<IStepResult> {
    this.setPeople({ isExArmedForces: d.response })
    if (d.response?.match(/currently serving/i)) {
      this.trackUserAsIneligible(d.state, IneligibilityReason.EX_ARMED_FORCES)
      return { nextStep: this.goToSignpostArmedForces }
    }
    d.state.isExArmedForces = d.response
    return { nextStep: this.sayIntroToSpineSearch }
  }

  @step.logState
  goToSignpostArmedForces(d: IStepData<State>): IStepResult {
    this.track(TrackingEvents.ARMED_FORCES_SIGNPOST)
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName

    return {
      body: this.t(
        [
          "So {name}, to get you the more appropriate support we suggest you access specialist support through your current Armed Forces service",
          "Or you can contact your local GP",
          "If you feel there is a risk of harming yourself or others please contact or the Mental Health Matters Helpline on 0800 001 4331 or the Samaritans on 116 123 to talk about how you are feeling",
          "Always contact 999 in the case of a physical emergency",
          "Thank you for contacting {organisationName}"
        ],
        { name, organisationName }
      ),
      prompt: {
        id: this.getPromptId("goToSignpostArmedForces"),
        type: "inlinePicker",
        choices: [{ body: this.t("Understood") }, { body: this.t("Okay") }],
        isUndoAble: true
      },
      nextStep: this.goToGoodbye
    }
  }

  /** Generic Handlers */

  async onPostcodeOfUserSuccessful(_state: State): Promise<IStepResult> {
    return { nextStep: this.askExArmedForces }
  }

  getExArmedForcesValues(_state: State): string[] {
    return Object.keys(exArmedForces)
  }

  getChoiceBody(text: string): string {
    return (
      {
        "Not stated (Person asked but declined to provide a response)": "Prefer not to say",
        "Person asked and does not know or is not sure": "Not sure",
        "Unknown (Person asked and does not know or isn't sure)": "Not sure",
        "Patient Religion Unknown": "Not sure",
        "(None)": "None"
      }[text] || text
    )
  }
}

/* istanbul ignore next */
export default class EligibilityCheckLincolnshireDialogue extends Dialogue<State> {
  static id = DialogueIDs.EligibilityCheckLincolnshire
  readonly name: string = "EligibilityCheckLincolnshireDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      EligibilityCheckLincolnshireDialogue.id,
      new EligibilityCheckLincolnshireScript(),
      state,
      snapshot
    )
  }
}
