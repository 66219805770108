import ChatFlowPayloadBuilder from "../ChatFlowPayloadBuilder"
import { ChatFlowsEnum, ReferralPayload } from "@limbic/types"

export default class DisabilityPayloadBuilder extends ChatFlowPayloadBuilder<ChatFlowsEnum.COLLECT_DISABILITY> {
  chatFlow = ChatFlowsEnum.COLLECT_DISABILITY

  /** Overrides */

  getDefaultPayload(): Partial<Partial<ReferralPayload>> {
    return {
      disability: this.ctx.state?.disability || this.ctx.state?.disabilities
    } as Partial<ReferralPayload>
  }
}

ChatFlowPayloadBuilder.builders[ChatFlowsEnum.COLLECT_DISABILITY] = DisabilityPayloadBuilder
