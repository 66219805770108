import { z, ZodSchema } from "zod"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import {
  GetNameIAPTScript,
  GetNameIAPTScriptState,
  GetNameIAPTScriptStateSchema
} from "./GetNameIAPTDialogue"
import { PronounsOB, TitleOB } from "@limbic/types"

interface State extends GetNameIAPTScriptState {
  title?: TitleOB
  preferredPronouns?: PronounsOB
}

export type GetNameOxfordBucksScriptState = State

export const GetNameOxfordBucksScriptStateSchema = GetNameIAPTScriptStateSchema.extend({
  preferredTitle: z
    .union([
      z.literal("Mr"),
      z.literal("Mrs"),
      z.literal("Miss"),
      z.literal("Ms"),
      z.literal("Mx"),
      z.literal("Dr"),
      z.literal("Rev"),
      z.literal("Prof")
    ])
    .optional(),
  preferredPronouns: z
    .union([
      z.literal("She/her/hers"),
      z.literal("He/him/his"),
      z.literal("They/them/theirs"),
      z.literal("Sie/hir/hirs"),
      z.literal("Other")
    ])
    .optional()
})

export class GetNameOxfordBucksScript extends GetNameIAPTScript {
  readonly name: string = "GetNameOxfordBucksScript"

  /** Script Steps */

  @step.logState
  askIsPreferredName(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `Is ${name} your preferred first name?`,
      prompt: {
        id: this.getPromptId("askIsPreferredName"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleIsPreferredName
    }
  }

  @step.logStateAndResponse
  async handleIsPreferredName(d: IStepData<State, boolean>): Promise<IStepResult> {
    return {
      nextStep: d.response //
        ? this.sayNiceToMeetYou
        : this.sayPleaseGivePreferredName
    }
  }

  @step
  sayPleaseGivePreferredName(_d: IStepData<State>): IStepResult {
    return {
      body: "What would you like to be called?",
      nextStep: this.showPromptForPreferredName
    }
  }

  @step.logState
  showPromptForPreferredName(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("showPromptForPreferredName"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handlePreferredNameWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>, script: GetNameOxfordBucksScript) => {
    const preferredName = d.response?.trim()
    d.state.preferredName = preferredName
    script.rootStore.applicationStore.setPreferredName(preferredName)
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    getNextStep: (s: GetNameOxfordBucksScript) => s.sayPleaseGivePreferredName
  })
  async handlePreferredNameWithCrisis(_d: IStepData<State, string>): Promise<IStepResult> {
    return { nextStep: this.checkPreferredName }
  }

  @step.logState
  async checkPreferredName(d: IStepData<State>): Promise<IStepResult> {
    if (!d.state.preferredName || d.state.preferredName.trim() === "") {
      return { nextStep: this.sayPleaseGivePreferredName }
    }
    return { nextStep: this.sayNiceToMeetYou }
  }

  @step
  sayNiceToMeetYou(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `Nice to meet you ${name}`,
      prompt: {
        id: this.getPromptId("sayNiceToMeetYou"),
        type: "inlinePicker",
        choices: [{ body: "Nice to meet you too" }]
      },
      nextStep: this.askHowWouldYouLikeToBeAddressed
    }
  }

  @step.logState
  askHowWouldYouLikeToBeAddressed(d: IStepData<State>): IStepResult {
    const titles = this.getTitles()
    if (!titles?.length) {
      this.logBreadcrumb("TITLES NOT FOUND", d.state, { titles })
      this.logMessage("TITLES NOT FOUND")
      return { nextStep: this.end }
    }

    return {
      body: "And how would you like to be addressed?",
      prompt: {
        id: this.getPromptId("askHowWouldYouLikeToBeAddressed"),
        type: "inlinePicker",
        choices: titles.map(title => ({ body: title, value: title })),
        isUndoAble: true
      },
      nextStep: this.handleHowWouldYouLikeToBeAddressed
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, TitleOB>) => {
    d.state.title = d.response
  })
  handleHowWouldYouLikeToBeAddressed(_d: IStepData<State, TitleOB>): IStepResult {
    return { nextStep: this.askPreferredPronouns }
  }

  @step.logState
  askPreferredPronouns(d: IStepData<State>): IStepResult {
    const pronouns = this.getPreferredPronouns(d.state)
    if (!pronouns?.length) {
      this.logBreadcrumb("PRONOUNS NOT FOUND", d.state, { pronouns })
      this.logMessage("PRONOUNS NOT FOUND")
      return { nextStep: this.end }
    }
    return {
      body: `And what are your preferred pronouns?`,
      prompt: {
        id: this.getPromptId("askPreferredPronouns"),
        trackResponse: true,
        type: "inlinePicker",
        choices: pronouns.map(g => ({ body: g, value: g }))
      },
      nextStep: this.handlePreferredPronouns
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, PronounsOB>, script: GetNameOxfordBucksScript) => {
    d.state.preferredPronouns = d.response
  })
  handlePreferredPronouns(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.end }
  }

  /** Generic Handlers */

  async onCheckFullName(_state: State): Promise<IStepResult> {
    return {
      nextStep: this.askIsPreferredName
    }
  }

  getTitles(): TitleOB[] {
    return ["Mr", "Mrs", "Miss", "Ms", "Mx", "Dr", "Rev", "Prof"]
  }

  getPreferredPronouns(_state: State): PronounsOB[] {
    return ["She/her/hers", "He/him/his", "They/them/theirs", "Sie/hir/hirs", "Other"]
  }

  getStateSchema(): ZodSchema | undefined {
    return GetNameOxfordBucksScriptStateSchema
  }
}

/* istanbul ignore next */
export default class GetNameOxfordBucksDialogue extends Dialogue<State> {
  static id = DialogueIDs.GetNameOxfordBucks
  readonly name: string = "GetNameOxfordBucksDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GetNameOxfordBucksDialogue.id, new GetNameOxfordBucksScript(), state, snapshot)
  }
}
