import React from "react"
import { observer } from "mobx-react"
import "./Sidebar.scss"
import { useConfigStore } from "../../contexts/RootStoreContext"

export interface Props {
  visible?: boolean
}

function Sidebar(props: Props): JSX.Element | null {
  const config = useConfigStore()
  const { visible } = props

  if (!visible) {
    return null
  }

  return (
    <div className="lb-sidebar-container">
      <article className="lb-sidebar-card">
        <div className="lb-header">
          <img style={{ marginRight: 24 }} src={config.logo} className="lb-header-img" alt="" />
          <h3 className="subtitle">Refer yourself to {config.organisationName}</h3>
        </div>
        <p>
          We are {config.organisationName}. Our NHS service is free, confidential and available for
          people 16+ who are registered with a Shropshire, Telford & Wrekin GP.
        </p>
        <p>
          Talking Therapy services can support people with mild to moderate mental health issues
          such as anxiety or low mood. We offer a range of help, advice, supported self-help,
          groups, and individual therapies for{" "}
          <b>
            people aged 16 and over, who are registered with a GP in Shropshire, Telford & Wrekin
          </b>
          . If you are under 16 years old, please contact{" "}
          <a href="https://camhs.mpft.nhs.uk/beeu" target="_blank" rel="noreferrer">
            BeeU
          </a>{" "}
          for support. Please note people who are 16 and 17 are welcome to use our service, but we
          will ask for contact information for your parent or guardian, in case we have any concerns
          about your safety.
        </p>
        <p>
          Please note, we are not a crisis service.{" "}
          <b>
            If you are currently experiencing a Mental Health Crisis and / or need urgent help,
            please contact the Urgent NHS Mental Health Helpline on 0808 196 4501, NHS 111 or 999 or
            the Samaritans on 116 123.
          </b>
        </p>
        <p>
          To refer yourself to our service, please use the Limbic Self-Referral Assistant on the
          right. This is the easiest way to ensure our team receive your referral quickly. If you
          would prefer to use our standard web form, you can do so{" "}
          <a href={config.formLink} target="_blank" rel="noreferrer">
            here
          </a>
          . Please fill out only one of these referral forms.
        </p>
        <p>
          Alternatively, if you would like to speak to a member of our admin team, please call us on{" "}
          <a href="tel:03001236020" target="_blank" rel="noreferrer">
            0300 1236020
          </a>
        </p>
        {config.iaptIcons?.map?.(link => (
          <img key={link} className="nhs-logo" src={link} alt={link} />
        ))}
      </article>
      <div className="lb-sidebar-spacer" />
      <article className="lb-sidebar-card full-height">
        <p>
          Limbic helps people access psychological support quicker and easier. Your data is secured
          according to the highest NHS standards.
        </p>
        <img
          className="badges"
          src="https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/data-pics.png"
          alt={"Badges"}
        />
      </article>
    </div>
  )
}

export default observer(Sidebar)
