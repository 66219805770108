import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../DialogueIDs"
import CrisisScript, { CrisisScriptState } from "./CrisisScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = CrisisScriptState
export type CrisisCWScriptState = State

export class CrisisCWScript extends CrisisScript {
  readonly name: string = "CrisisCWScript"

  /** Script Steps */

  @step
  saySorryToHear(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        "Thank you for sharing this information",
        `However, ${organisationName} does not provide urgent care`,
        "In medical emergency and life threatening situations only, please dial 999 or attend your local A&E department",
        "You can also give a call to Cheshire & Wirral Partnership 24hr Crisis Line 0800 145 6485"
      ],
      prompt: {
        id: this.getPromptId("saySorryToHear"),
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      },
      nextStep: this.sayCrisisNumbers
    }
  }

  @step.logState
  sayCrisisNumbers(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const crisisPhoneNumbers = this.rootStore.configStore.crisisPhoneNumbers

    return {
      body: [
        "You could also give any one of our services a call 24/7 on the following phone numbers:\n" +
          crisisPhoneNumbers,
        "Other helplines available:\n\n" +
          "You can contact Samaritans 24 hours a day, 365 days a year on 116 123 (free from any phone) or email jo@samaritans.org\n\n" +
          "If you would prefer not to talk but want some mental health support, you could text SHOUT to 85258. Shout offers a confidential 24/7 text service providing support if you are in need of immediate help",
        `We’ll continue with the referral, however ${organisationName} does not provide urgent care. If you require immediate help, please contact one of the numbers listed above`
      ],
      nextStep: this.handleCrisisNumbers
    }
  }

  @step
  handleCrisisNumbers(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "999, Local (0800 145 6485), Samaritans (116 123), SHOUT (85258)"
    )
    return { nextStep: this.end }
  }

  @step.logState
  saySorryForTheMisunderstanding(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Okay",
        "My creators have taught me to listen carefully for specific words or phrases",
        "In case you need help",
        "Sorry for the misunderstanding",
        "Ok, let's carry on with the mental health check in"
      ],
      nextStep: this.end
    }
  }
}

/* istanbul ignore next */
export default class CrisisCWDialogue extends AdHocDialogue<State, CrisisCWScript> {
  static id = DialogueIDs.CrisisCW
  readonly name: string = "CrisisCWDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(CrisisCWDialogue.id, new CrisisCWScript(), state, snapshot)
  }
}
