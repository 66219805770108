import Logger from "../../../utils/Logger"
import delay from "../../../utils/delay"
import isOnline from "is-online"
import { SubmitReferralStatus } from "../../../models/SubmitReferral"
import client from "./_client"

const TOTAL_RETRIES = 3

export async function submitVitalityReferral(
  patientId: string,
  retry = 0
): Promise<[boolean, string, SubmitReferralStatus]> {
  try {
    const hasConnection = await isOnline()
    if (!hasConnection) return [false, "", SubmitReferralStatus.NoInternetConnection]

    const headers = client.headers({ "x-api-key": client.getTimedResourceAPIKey(patientId) })
    const data = await client.post("/v1/vitality/referral/submit", undefined, undefined, headers)

    const isSubmitSuccessful = data?.status === "SUCCESS"
    return [isSubmitSuccessful, data.serviceMeta.paymentURL, SubmitReferralStatus.Success]
  } catch (e) {
    Logger.getInstance().breadcrumb({
      message: "submitVitalityReferral fetch failed",
      data: { id: patientId }
    })
    Logger.getInstance().exception(e, "submitVitalityReferral fetch failed")
    if (retry < TOTAL_RETRIES) {
      Logger.getInstance().message("submitVitalityReferral retry")
      await delay(1)
      return await submitVitalityReferral(patientId, retry + 1)
    }
    return [false, "", SubmitReferralStatus.RequestFailed]
  }
}
