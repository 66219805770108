import moment from "moment"
import SelfReferralIAPTScript from "./SelfReferralIAPTScript"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import invariant from "../../../utils/invariant"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { SelfReferralIAPTScriptState } from "./SelfReferralIAPTScript"
import type { ReferralPayloadInsightWaitlist } from "@limbic/types"

type State = SelfReferralIAPTScriptState

export class SelfReferralInsightWaitlistScript extends SelfReferralIAPTScript {
  readonly name: string = "SelfReferralInsightWaitlistScript"

  /** Script Steps */

  @step.logState
  @step.setState<State>({ addressLookupCounter: 0 })
  sayIntro(d: IStepData<State>): IStepResult {
    this.timeEvent(this.name)
    return { nextStep: this.askWannaDoSelfReferral }
  }

  @step
  askWannaDoSelfReferral(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "There are just a few more details I need from you",
        "This should take no more than a few minutes",
        "The first section collects personal information, the second is around some of your symptoms"
      ]),
      prompt: {
        id: this.getPromptId("askWannaDoSelfReferral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Sure") }, { body: this.t("Okay") }]
      },
      nextStep: this.startSelfReferralPart1
    }
  }

  @step
  startSelfReferralPart1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askBirthday }
  }

  @step.logState
  askBirthday(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("First off, what's your date of birth?"),
      prompt: {
        id: this.getPromptId("askBirthday"),
        trackResponse: true,
        type: "datePicker",
        future: false
      },
      nextStep: this.handleBirthday
    }
  }

  @step.logState
  sayPleaseGiveABirthday(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Please enter your date of birth"),
      prompt: {
        id: this.getPromptId("sayPleaseGiveABirthday"),
        trackResponse: true,
        type: "datePicker",
        future: false
      },
      nextStep: this.handleBirthday
    }
  }

  @step.logState
  handleBirthday(d: IStepData<State, number>): IStepResult {
    try {
      const date = moment(d.response)
      invariant(date, "Date object is falsy")
      invariant(date.isValid(), "Date is not valid")
      invariant(date.isBefore(moment()), "Birth date cannot be in the future")
      d.state.birthday = date.toDate().getTime()
      this.setPeople({ age: moment().diff(date, "years") })
    } catch (e) {
      this.logException(e, "handleBirthday")
      return {
        body: this.t("I'm sorry that's not a valid date"),
        nextStep: this.sayPleaseGiveABirthday
      }
    }

    return {
      body: this.t("Thanks for sharing"),
      nextStep: this.askPostCodeOfUser
    }
  }

  @step.logState
  askPostCodeOfUser(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Please type your postcode below"),
      nextStep: this.promptForPostcode
    }
  }

  @step.logState
  promptForPostcode(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("promptForPostcode"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handlePostCodeOfUserWithCrisis
    }
  }

  @step
  returnToAkPostCodeOfUser(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("So what's your postcode?"),
      nextStep: this.promptForPostcode
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    getNextStep: (s: SelfReferralInsightWaitlistScript) => s.returnToAkPostCodeOfUser
  })
  async handlePostCodeOfUserWithCrisis(d: IStepData<State, string>): Promise<IStepResult> {
    d.state.postcodeEntered = d.response
    return { nextStep: this.goToCollectGoalForTherapy }
  }

  @step.logState
  sayReferralSucceeded(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t(["Thanks {name}, I noted that"], { name }),
      prompt: {
        id: this.getPromptId("sayReferralSucceeded"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Great"), value: false },
          { body: this.t("Good to know"), value: false }
        ]
      },
      nextStep: this.end
    }
  }

  @step.logState
  sayReferralFailed(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    return {
      body: this.t(
        [
          "Oops... I'm really sorry about this, but it seems like something has gone wrong when trying to submit your data to {iaptName}",
          "I've notified my creators of this issue"
        ],
        { iaptName }
      ),
      prompt: {
        id: this.getPromptId("sayReferralFailed"),
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }]
      },
      nextStep: this.goToGoodbye
    }
  }

  /** Generic Handlers */

  async getReferralPayload(state: State): Promise<ReferralPayloadInsightWaitlist> {
    const instanceID = this.referralStore.instanceID
    invariant(instanceID, "Cannot create referral without an Instance ID")

    return {
      instanceID,
      nameFirst: this.getName(state),
      nameLast: this.getLastName(state),
      dob: moment(state.birthday).format("YYYY-MM-DD"),
      addressHome: {
        address1: state.address,
        address2: state.address2,
        // If address is entered manually then city/county/postcode are undefined
        // Pass an alternate value to avoid errors in the referral submission
        city: state.city || "unknown",
        county: state.county || "unknown",
        // 👇 notice this is just the entered string and not from the userPostcode which is a IPostcode
        postcode: state.postcodeEntered || state.invalidPostcodeEntered || "unknown",
        consentMail: !!state.canSendMailToAddress
      },
      consentDataShare: true,
      consentDataStore: true,
      output: this.referralStore.referralType,
      riskLevel: this.clinicalStore.riskLevel,
      riskLevelReason: this.clinicalStore.riskLevelReason,
      triggerWords: this.clinicalStore.triggerWords,
      dynamicLink: undefined, // 👈 TODO: fix the type so this can be removed
      canKeepSelfSafe: undefined, // 👈 TODO: fix the type so this can be removed
      consentResearch: state.consentResearch,
      treatmentExpectation: state.therapyGoal
    }
  }
}

/* istanbul ignore next */
export default class SelfReferralInsightWaitlistDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralInsightWaitlist
  readonly name: string = "SelfReferralInsightWaitlistDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      SelfReferralInsightWaitlistDialogue.id,
      new SelfReferralInsightWaitlistScript(),
      state,
      snapshot
    )
  }
}
