import Dialogue, { IDialogueSnapshot } from "../../backend/chatbot/Dialogue"
import { step } from "../../backend/chatbot/decorators/step"
import BaseScript from "../BaseScript"
import { DialogueIDs } from "../DialogueIDs"
import type { SurveyScriptState } from "../createSurveyDialogue"
import type { IStepData, IStepResult } from "../../backend/chatbot/models/IStep"
import type { IPersistableSurveyResponse, ISurveyResponse } from "../../models/ISurvey"

type State = SurveyScriptState

class IRQA extends BaseScript<State> {
  readonly name: string = "IRQA"

  /** Script Steps */

  @step
  start(d: IStepData<State>): IStepResult<State> {
    const name = this.getName(d.state)
    this.timeEvent(`${this.name} Finished`)
    return {
      body: this.t(
        "Thanks {name}. These are the final few questions I need to ask you. We're almost there!",
        { name }
      ),
      nextStep: this.step1
    }
  }

  @step
  end(d: IStepData<State>): IStepResult {
    this.track(`${this.name} Finished`)
    return super.end(d)
  }

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0)
    return {
      body: this.t("1. Does a member of your family work in health or care?"),
      prompt: {
        id: this.getPromptId("step1"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "IRQ-A - step1",
        isUndoAble: true
      },
      nextStep: this.handleStep1
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "1",
    "1. Does a member of your family work in health or care?",
    "irqaResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  handleStep1(_d: IStepData<State, string>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.09)
    return { nextStep: this.step2 }
  }

  @step
  step2(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("2. Do you work in health and care?"),
      prompt: {
        id: this.getPromptId("step2"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "IRQ-A - step2",
        isUndoAble: true
      },
      nextStep: this.handleStep2
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "2",
    "2. Do you work in health and care?",
    "irqaResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  handleStep2(d: IStepData<State, ISurveyResponse>): IStepResult {
    if (d.response) {
      this.rootStore.applicationStore.setCurrentProgress(0.18)
      return { nextStep: this.step3 }
    }
    this.rootStore.applicationStore.setCurrentProgress(0.45)
    return { nextStep: this.step5 }
  }

  @step
  step3(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("3. Which best describes your setting?"),
      prompt: {
        id: this.getPromptId("step3"),
        type: "inlinePicker",
        choices: [
          {
            body: this.t("Adult social care in the community"),
            value: "Adult social care in the community"
          },
          {
            body: this.t("Adult social care in residential home/nursing home/hospice"),
            value: "Adult social care in residential home/ nursing home/ hospice"
          },
          { body: this.t("CCG"), value: "CCG" },
          { body: this.t("Community services"), value: "Community services" },
          { body: this.t("Child social care"), value: "Child social care" },
          { body: this.t("Dental"), value: "Dental" },
          { body: this.t("Education"), value: "Education" },
          { body: this.t("Emergency services"), value: "Emergency services" },
          { body: this.t("Hospital"), value: "Hospital" },
          {
            body: this.t("Infrastructure / Arms-length body"),
            value: "Infrastructure / Arms-length body"
          },
          { body: this.t("GP Practice"), value: "GP Practice" },
          { body: this.t("Local authority"), value: "Local authority" },
          { body: this.t("Mental Health"), value: "Mental Health" },
          {
            body: this.t("Optometry (as part of primary care)"),
            value: "Optometry (as part of primary care)"
          },
          { body: this.t("Pharmacy"), value: "Pharmacy" },
          {
            body: this.t("Voluntary, community, social enterprise and faith sector"),
            value: "Voluntary, community, social enterprise and faith sector"
          },
          { body: this.t("N/A"), value: "N/A" }
        ],
        dataPointsName: "IRQ-A - step3",
        isUndoAble: true
      },
      nextStep: this.handleStep3
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>("3", "3. Which best describes your setting?", "irqaResponses")
  handleStep3(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.27)
    return { nextStep: this.step4 }
  }

  @step
  step4(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("4. Which best describes your role?"),
      prompt: {
        id: this.getPromptId("step4"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Clinical Role"), value: "Clinical Role" },
          { body: this.t("Non-clinical Role"), value: "Non-clinical Role" },
          { body: this.t("N/A"), value: "N/A" }
        ],
        dataPointsName: "IRQ-A - step4",
        isUndoAble: true
      },
      nextStep: this.handleStep4
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>("4", "4. Which best describes your role?", "irqaResponses")
  handleStep4(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.36)
    return { nextStep: this.step5 }
  }

  @step.logState
  step5(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "5. Has your work circumstances been impacted in any of these ways by Covid-19?"
      ),
      nextStep: this.step6
    }
  }

  @step.logState
  step6(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("a) Have you moved to work in a different location?"),
      prompt: {
        id: this.getPromptId("step6"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: "Yes" },
          { body: this.t("No"), value: "No" },
          { body: this.t("N/A"), value: "N/A" }
        ],
        dataPointsName: "IRQ-A - step6",
        isUndoAble: true
      },
      nextStep: this.handleStep6
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "6",
    "a) Have you moved to work in a different location?",
    "irqaResponses"
  )
  handleStep6(_d: IStepData<State>): IStepResult {
    return { nextStep: this.step7 }
  }

  @step.logState
  step7(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("b) Have you been undertaking new tasks within your usual role?"),
      prompt: {
        id: this.getPromptId("step7"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: "Yes" },
          { body: this.t("No"), value: "No" },
          { body: this.t("N/A"), value: "N/A" }
        ],
        dataPointsName: "IRQ-A - step7",
        isUndoAble: true
      },
      nextStep: this.handleStep7
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "7",
    "b) Have you been undertaking new tasks within your usual role?",
    "irqaResponses"
  )
  handleStep7(_d: IStepData<State>): IStepResult {
    return { nextStep: this.step8 }
  }

  @step.logState
  step8(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("c) Have you retired and returned since March 2020 at any point?"),
      prompt: {
        id: this.getPromptId("step8"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: "Yes" },
          { body: this.t("No"), value: "No" },
          { body: this.t("N/A"), value: "N/A" }
        ],
        dataPointsName: "IRQ-A - step8",
        isUndoAble: true
      },
      nextStep: this.handleStep8
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "8",
    "c) Have you retired and returned since March 2020 at any point?",
    "irqaResponses"
  )
  handleStep8(_d: IStepData<State>): IStepResult {
    return { nextStep: this.step9 }
  }

  @step.logState
  step9(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("d) Have you been redeployed or seconded since March 2020 at any point?"),
      prompt: {
        id: this.getPromptId("step9"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: "Yes" },
          { body: this.t("No"), value: "No" },
          { body: this.t("N/A"), value: "N/A" }
        ],
        dataPointsName: "IRQ-A - step9",
        isUndoAble: true
      },
      nextStep: this.handleStep9
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "9",
    "d) Have you been redeployed or seconded since March 2020 at any point?",
    "irqaResponses"
  )
  handleStep9(_d: IStepData<State>): IStepResult {
    return { nextStep: this.step10 }
  }

  @step.logState
  step10(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("e) Are you in training at a university/ further education establishment?"),
      prompt: {
        id: this.getPromptId("step10"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: "Yes" },
          { body: this.t("No"), value: "No" },
          { body: this.t("N/A"), value: "N/A" }
        ],
        dataPointsName: "IRQ-A - step10",
        isUndoAble: true
      },
      nextStep: this.handleStep10
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "10",
    "e) Are you in training at a university/ further education establishment?",
    "irqaResponses"
  )
  handleStep10(_d: IStepData<State>): IStepResult {
    return { nextStep: this.step11 }
  }

  @step.logState
  step11(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("6. Which option best describes your usual employment status?"),
      prompt: {
        id: this.getPromptId("step11"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Employed full time"), value: "Employed full time" },
          { body: this.t("Employer part-time"), value: "Employer part-time" },
          { body: this.t("Bank/Agency worker"), value: "Bank/Agency worker" },
          { body: this.t("Self-employed"), value: "Self-employed" },
          { body: this.t("In Education"), value: "In Education" },
          { body: this.t("Not working"), value: "Not working" },
          { body: this.t("Other"), value: "Other" }
        ],
        dataPointsName: "IRQ-A - step11",
        isUndoAble: true
      },
      nextStep: this.handleStep11
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "11",
    "6. Which option best describes your usual employment status?",
    "irqaResponses"
  )
  handleStep11(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.54)
    return { nextStep: this.step13to18 }
  }

  // we skip question 12 on purpose

  step13to18(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("7. Home circumstances (select all that apply): Are you currently living:"),
      prompt: {
        id: this.getPromptId("step13to18"),
        type: "inlinePickerMultiSelect",
        choices: [
          { body: this.t("On my own"), value: 1 },
          { body: this.t("With a partner"), value: 2 },
          { body: this.t("With dependent children"), value: 3 },
          { body: this.t("With elderly/disabled relatives"), value: 4 },
          { body: this.t("In a shared house/flat"), value: 5 },
          { body: this.t("Away from home due to Covid-19"), value: 6 }
        ],
        dataPointsName: "IRQ-A - step13to18",
        isUndoAble: true
      },
      nextStep: this.handleStep13to18
    }
  }

  @step.logStateAndResponse
  handleStep13to18(d: IStepData<State, number[]>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.63)
    const a = "a) on your own?"
    const b = "b) with a partner?"
    const c = "c) with dependent children?"
    const _d = "d) with elderly/disabled relatives?"
    const e = "e) in a shared house/flat?"
    const f = "f) away from home due to COVID 19?"
    const getAnswer = value => (d.response.includes(value) ? "Yes" : "No")

    this.saveResponse({ id: "13", title: a, answer: getAnswer(1) }, d.state)
    this.saveResponse({ id: "14", title: b, answer: getAnswer(2) }, d.state)
    this.saveResponse({ id: "15", title: c, answer: getAnswer(3) }, d.state)
    this.saveResponse({ id: "16", title: _d, answer: getAnswer(4) }, d.state)
    this.saveResponse({ id: "17", title: e, answer: getAnswer(5) }, d.state)
    this.saveResponse({ id: "18", title: f, answer: getAnswer(6) }, d.state)
    return { nextStep: this.step19 }
  }

  @step.logStateAndResponse
  step19(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "8. Were you concerned about your emotional wellbeing/mental health before March 2020?"
      ),
      prompt: {
        id: this.getPromptId("step19"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: "Yes" },
          { body: this.t("No"), value: "No" },
          { body: this.t("Unsure"), value: "Unsure" }
        ],
        dataPointsName: "IRQ-A - step19",
        isUndoAble: true
      },
      nextStep: this.handleStep19
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "19",
    "8. Were you concerned about your emotional wellbeing/mental health before March 2020?",
    "irqaResponses"
  )
  handleStep19(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.72)
    return { nextStep: this.step21to29 }
  }

  // we skip question 20 on purpose

  @step.logStateAndResponse
  step21to29(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("9. Have you been impacted in any of the following ways by Covid-19?"),
      prompt: {
        id: this.getPromptId("step14"),
        type: "inlinePickerMultiSelect",
        choices: [
          { body: this.t("a) been ill with confirmed COVID-19 (recovered at home)"), value: 1 },
          {
            body: this.t("b) been ill with confirmed COVID-19 (included being in hospital)"),
            value: 2
          },
          {
            body: this.t("c) family member been ill with confirmed COVID-19 (recovered at home)"),
            value: 3
          },
          {
            body: this.t(
              "d) family member been ill with confirmed COVID-19 (included being in hospital)"
            ),
            value: 4
          },
          { body: this.t("e) suffered financial loss within the household"), value: 5 },
          {
            body: this.t("f) Have you been asked to shield at any times (since March 2020)?"),
            value: 6
          },
          {
            body: this.t(
              "g) Have you experienced a family/close friend bereavement (since March 2020)?"
            ),
            value: 7
          },
          {
            body: this.t("h) Have you experienced a colleague bereavement (since March 2020)?"),
            value: 8
          }
        ],
        dataPointsName: "IRQ-A - step14",
        isUndoAble: true
      },
      nextStep: this.handleStep21to29
    }
  }

  @step.logStateAndResponse
  handleStep21to29(d: IStepData<State, number[]>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.81)
    const a = "a) been ill with confirmed COVID-19 (recovered at home)"
    const b = "b) been ill with confirmed COVID-19 (included being in hospital)"
    const c = "c) family member been ill with confirmed COVID-19 (recovered at home)"
    const _d = "d) family member been ill with confirmed COVID-19 (included being in hospital)"
    const e = "e) suffered financial loss within the household"
    const f = "f) Have you been asked to shield at any times (since March 2020)?"
    const g = "g) Have you experienced a family/close friend bereavement (since March 2020)?"
    const h = "h) Have you experienced a colleague bereavement (since March 2020)?"
    const getAnswer = value => (d.response.includes(value) ? "Yes" : "No")

    this.saveResponse({ id: "21", title: a, answer: getAnswer(1) }, d.state)
    this.saveResponse({ id: "22", title: b, answer: getAnswer(2) }, d.state)
    this.saveResponse({ id: "23", title: c, answer: getAnswer(3) }, d.state)
    this.saveResponse({ id: "24", title: _d, answer: getAnswer(4) }, d.state)
    // we skip id 25 on purpose
    this.saveResponse({ id: "26", title: e, answer: getAnswer(5) }, d.state)
    this.saveResponse({ id: "27", title: f, answer: getAnswer(6) }, d.state)
    this.saveResponse({ id: "28", title: g, answer: getAnswer(7) }, d.state)
    this.saveResponse({ id: "29", title: h, answer: getAnswer(8) }, d.state)

    return { nextStep: this.step30 }
  }

  @step.logStateAndResponse
  step30(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "10. Do you consent for your anonymised data to be used for research to help us better understand the impact of Covid-19?"
      ),
      prompt: {
        id: this.getPromptId("step30"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "IRQ-A - step30",
        isUndoAble: true
      },
      nextStep: this.handleStep30
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "30",
    "10. Do you consent for your anonymised data to be used for research to help us better understand the impact of Covid-19?",
    "irqaResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  handleStep30(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.9)
    return { nextStep: this.step31 }
  }

  step31(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "11. Would you be happy for us to contact you regarding opportunities to take part in further research? There would be no obligation to agree to be part of any research we tell you about"
      ),
      prompt: {
        id: this.getPromptId("step31"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "IRQ-A - step31",
        isUndoAble: true
      },
      nextStep: this.handleStep31
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "31",
    "11. Would you be happy for us to contact you regarding opportunities to take part in further research?",
    "irqaResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  handleStep31(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(1)
    return { nextStep: this.end }
  }

  saveResponse<T extends IPersistableSurveyResponse>(item: T, state: State): void {
    super.saveResponse(item, state, "irqaResponses")
  }
}

export default class IRQADialogue extends Dialogue<State> {
  static id = DialogueIDs.IRQA
  readonly name: string = "IRQADialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(IRQADialogue.id, new IRQA(), state, snapshot)
  }
}
