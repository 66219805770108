import {
  ARMED_FORCES_MAYDEN_MPFT,
  DISABILITY_MAYDEN_MPFT,
  ETHNICITY_MAYDEN_MPFT,
  GENDER_MAYDEN_MPFT,
  LANGUAGE_MAYDEN_MPFT,
  LTC_MAYDEN_MPFT,
  PERINATAL_MAYDEN_MPFT,
  RELIGION_MAYDEN_MPFT,
  SEXUALITY_MAYDEN_MPFT
} from "@limbic/types"

export const perinatalStatuses: Record<string, PERINATAL_MAYDEN_MPFT> = {
  "None apply": "NONE",
  Pregnant: "PREGNANT",
  "Child under 1": "CHILD_UNDER_1"
}

export const sexualities: Record<string, SEXUALITY_MAYDEN_MPFT> = {
  "Heterosexual or straight": "HETEROSEXUAL",
  "Lesbian or gay female": "HOMOSEXUAL_FEMALE",
  "Gay male": "HOMOSEXUAL_MALE",
  Bisexual: "BISEXUAL",
  Other: "NOT_LISTED",
  "Prefer not to say": "NOT_ANSWERED"
}

export const genders: Record<string, GENDER_MAYDEN_MPFT> = {
  "Female (including trans woman)": "FEMALE",
  "Male (including trans man)": "MALE",
  "Non-binary": "NON_BINARY",
  "Prefer not to say": "NOT_ANSWERED",
  "Not Known": "UNKNOWN",
  Other: "NOT_LISTED"
}

export const ethnicities: Record<string, ETHNICITY_MAYDEN_MPFT> = {
  "White - British": "WHITE_BRITISH",
  "White - Irish": "WHITE_IRISH",
  "White - Any other White background": "WHITE_OTHER",
  "Mixed - White and Black Caribbean": "MIXED_WHITE_BLACK_CARIBBEAN",
  "Mixed - White and Black African": "MIXED_WHITE_BLACK_AFRICAN",
  "Mixed - White and Asian": "MIXED_WHITE_ASIAN",
  "Mixed - Any other mixed background": "MIXED_OTHER",
  "Asian or Asian British - Indian": "ASIAN_BRITISH_INDIAN",
  "Asian or Asian British - Pakistani": "ASIAN_BRITISH_PAKISTANI",
  "Asian or Asian British - Bangladeshi": "ASIAN_BRITISH_BANGLADESHI",
  "Asian or Asian British - Any other Asian background": "ASIAN_BRITISH_OTHER",
  "Black or Black British - Caribbean": "BLACK_BRITISH_CARIBBEAN",
  "Black or Black British - African": "BLACK_BRITISH_AFRICAN",
  "Black or Black British - Any other Black background": "BLACK_BRITISH_OTHER",
  "Other Ethnic Groups - Chinese": "CHINESE",
  "Other Ethnic Groups - Any other ethnic group": "OTHER",
  "Not known": "NOT_ANSWERED"
}

export const religions: Record<string, RELIGION_MAYDEN_MPFT> = {
  None: "NONE",
  "Prefer not to say": "DECLINE_TO_RESPOND",
  "Baha'i": "BAHAI",
  Buddhist: "BUDDHIST",
  Christian: "CHRISTIAN",
  Hindu: "HINDU",
  Jain: "JAIN",
  Jewish: "JEWISH",
  Muslim: "MUSLIM",
  Pagan: "PAGAN",
  Zoroastrian: "ZOROASTRIAN",
  Sikh: "SIKH"
}

export const languages: Record<string, LANGUAGE_MAYDEN_MPFT> = {
  English: "ENGLISH",
  Ethiopian: "ETHIOPIAN",
  "Farsi (Persian)": "PERSIAN_FARSI",
  Creole: "CREOLE",
  Dutch: "DUTCH",
  Cantonese: "CANTONESE",
  Vietnamese: "VIETNAMESE",
  Brawa: "BRAWA",
  "British Signing Language": "BRITISH_SIGN_LANGUAGE",
  "Akan (Ashanti)": "AKAN",
  Albanian: "ALBANIAN",
  Amharic: "AMHARIC",
  Arabic: "ARABIC",
  Bengali: "BENGALI",
  Finnish: "FINNISH",
  Flemish: "FLEMISH",
  French: "FRENCH",
  "French creole": "FRENCH_CREOLE",
  Gaelic: "GAELIC",
  German: "GERMAN",
  Greek: "GREEK",
  Gujarati: "GUJARATI",
  Hakka: "HAKKA",
  Hausa: "HAUSA",
  Hebrew: "HEBREW",
  Hindi: "HINDI",
  "Igbo (Ibo)": "IGBO",
  Italian: "ITALIAN",
  Japanese: "JAPANESE",
  Korean: "KOREAN",
  Kurdish: "KURDISH",
  Lingala: "LINGALA",
  Luganda: "LUGANDA",
  "Makaton (sign language)": "MAKATON_SIGN_LANGUAGE",
  Malayalam: "MALAYALAM",
  Mandarin: "MANDARIN",
  Norwegian: "NORWEGIAN",
  "Pashto (Pushtoo)": "PASHTO",
  Patois: "PATOIS",
  Polish: "POLISH",
  Portuguese: "PORTUGUESE",
  Punjabi: "PUNJABI",
  Russian: "RUSSIAN",
  Croatian: "CROATIAN",
  Somali: "SOMALI",
  Serbian: "SERBIAN",
  Sylheti: "SYLHETI",
  Sinhala: "SINHALA",
  Spanish: "SPANISH",
  Swahili: "SWAHILI",
  Swedish: "SWEDISH",
  "Tagalog (Filipino)": "TAGALOG_FILIPINO",
  Tamil: "TAMIL",
  Thai: "THAI",
  Tigrinya: "TIGRINYA",
  Turkish: "TURKISH",
  Urdu: "URDU",
  Welsh: "WELSH",
  Yoruba: "YORUBA",
  Other: "NOT_LISTED"
}

export const exArmedForces: Record<string, ARMED_FORCES_MAYDEN_MPFT> = {
  No: "NO",
  "Yes - ex services": "YES_EX",
  "Dependent of an ex-services member": "DEPENDENT",
  "Prefer not to say": "NOT_ANSWERED"
}

export const disabilities: Record<string, DISABILITY_MAYDEN_MPFT> = {
  "No perceived disability": "NONE",
  "Autistic spectrum disorder": "AUTISM",
  "Behaviour & emotional": "BEHAVIOUR",
  Hearing: "HEARING",
  "Manual dexterity": "MANUAL",
  "Memory or ability to concentrate, learn or understand (Learning Disability)": "LEARNING",
  "Mobility & gross motor": "MOTOR",
  "Perception of physical danger": "PERCEPTION",
  "Personal, Self care & continence": "SELF_CARE",
  "Progressive conditions & physical health (eg. HIV, cancer, fits, MS etc.)": "PROGRESSIVE",
  Sight: "SIGHT",
  Speech: "SPEECH",
  Other: "NOT_LISTED",
  "Prefer not to say": "NOT_ANSWERED"
}

export const ltcs: Record<string, LTC_MAYDEN_MPFT> = {
  Aids: "AIDS",
  Arthritis: "ARTHRITIS",
  "Auto Immune Disease": "AUTO_IMMUNE",
  Asthma: "ASTHMA",
  Cancer: "CANCER",
  "Chronic Pain": "CHRONIC_PAIN",
  "Crohn's Disease": "CROHNS",
  "COPD (chronic obstructive pulmonary disease)": "COPD",
  "Cystic Fibrosis": "CYSTIC_FIBROSIS",
  Dementia: "DEMENTIA",
  "Diabetes Type 1": "DIABETES_TYPE1",
  "Diabetes Type 2": "DIABETES_TYPE2",
  Epilepsy: "EPILEPSY",
  "Me/Chronic Fatigue": "FATIGUE",
  Fibromyalgia: "FIBROMYALGIA",
  "FND (functional neurological disorder)": "FND",
  "Gastrointestinal Disease": "GASTROINTESTINAL",
  "Gynaecological Disease": "GYNAECOLOGICAL",
  "Coronary Heart Disease/Cardiovascular Disease/Heart Attack": "HEART_DISEASE",
  "Hepatitis C": "HEPATITIS_C",
  HIV: "HIV",
  "IBS (Irritable Bowel Disease)": "IBS",
  "Kidney Disease": "KIDNEY",
  "Liver Disease": "LIVER",
  "MUS (Medically unexplained symptoms)": "MEDICALLY_UNEXPLAINED",
  "Neurological Disorder": "NEUROLOGICAL",
  "Parkinson's Disease": "PARKINSON",
  "Covid 19 - Long Term Effects": "POST_COVID",
  "Other respiratory disease (not COPD)": "RESPIRATORY",
  "Skin conditions, including Eczema": "SKIN",
  "Stroke and Transient Ischaemic Attack": "STIA",
  "Thyroid Disease": "THYROID",
  Tinnitus: "TINNITUS",
  "Urological Disorder": "UROLOGICAL"
}

export const form = {
  form_url: "https://gateway.mayden.co.uk/referral-v2/20f88609-0d72-4fd7-a4fe-d5be2e76af91",
  filled_by: "form",
  perinatalStatuses: Object.keys(perinatalStatuses),
  sexualities: Object.keys(sexualities),
  genders: Object.keys(genders),
  ethnicities: Object.keys(ethnicities),
  religions: Object.keys(religions),
  languages: Object.keys(languages),
  exArmedForces: Object.keys(exArmedForces),
  disabilities: Object.keys(disabilities),
  LTCs: Object.keys(ltcs)
}
