import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { IntroductionIAPTScript } from "./IntroductionIAPTDialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IntroductionScriptState } from "./IntroductionScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = IntroductionScriptState

export class IntroductionLWCScript extends IntroductionIAPTScript {
  readonly name: string = "IntroductionLWCScript"

  /** Script Steps */

  @step.logState
  handleCanIHelp(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.sayWhatIsOffered }
    }
    return { nextStep: this.sayIfYouChangeYourMindWakeMeUp }
  }

  @step.logState
  sayWhatIsOffered(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        `As well as Talking Therapy, ${organisationName} offer online Cognitive Behavioral Therapy and free wellbeing workshops`,
        "You have three options:\n",
        "1. <b>Silvercloud</b>: We offer online Computerised Cognitive Behavioural Therapy",
        "2. <b>grounded.</b>: Our Wellbeing Hubs offers wellbeing workshops aimed to support your mental health",
        "3. <b>Talking Therapy</b>: Continue with a self-referral to Living Well Consortium's Talking Therapy service"
      ],
      prompt: {
        id: this.getPromptId("sayWhatIsOffered"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Talking Therapy", value: "referral" },
          { body: "Silvercloud", value: "silvercloud" },
          { body: "grounded. Wellbeing Hubs", value: "grounded-hubs" }
        ],
        isUndoAble: true
      },
      nextStep: this.handleWhatIsOffered
    }
  }

  @step.logStateAndResponse
  handleWhatIsOffered(
    d: IStepData<State, "silvercloud" | "grounded-hubs" | "referral">
  ): IStepResult {
    switch (d.response) {
      case "silvercloud":
        return { nextStep: this.goToSilvercloud }
      case "grounded-hubs":
        return { nextStep: this.goToGroundedHubs }
      case "referral":
      default:
        return { nextStep: this.end }
    }
  }

  @step.logState
  goToSilvercloud(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "It's been great chatting to you",
        "I'll take you there right now",
        "Write 'hi' or 'hello' at any time if you want to carry on with me instead",
        "Or press undo if you want to review the options again"
      ],
      prompt: {
        id: this.getPromptId("goToSilvercloud"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          {
            body: "Start now",
            onPress: () => {
              const a = document.createElement("a")
              a.setAttribute(
                "href",
                "https://gateway.mayden.co.uk/referral-v2/abdffa0c-0d54-477e-ae9f-8ccaa872b26e"
              )
              a.setAttribute("target", "_blank")
              a.click()
            }
          },
          {
            body: "Review options",
            value: undefined
          }
        ],
        textPrompt: {
          placeholder: 'Type "Hello Limbic" to continue here',
          forceValue: true
        }
      },
      nextStep: this.handleSayHelloToContinue
    }
  }

  @step.logState
  goToGroundedHubs(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "It's been great chatting to you",
        "I'll take you there right now",
        "Write 'hi' or 'hello' at any time if you want to carry on with me instead",
        "Or press undo if you want to review the options again"
      ],
      prompt: {
        id: this.getPromptId("goToGroundedHubs"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          {
            body: "Book now",
            onPress: () => {
              const a = document.createElement("a")
              a.setAttribute("href", "https://groundedcafe.co.uk/selly-oak-cafe/events")
              a.setAttribute("target", "_blank")
              a.click()
            }
          },
          {
            body: "Review options",
            value: undefined
          }
        ],
        textPrompt: {
          placeholder: 'Type "Hello Limbic" to continue here',
          forceValue: true
        }
      },
      nextStep: this.handleSayHelloToContinue
    }
  }

  @step.logState
  handleSayHelloToContinue(d: IStepData<State, string>): IStepResult {
    if (d.response === "Review options") {
      return { body: "Of course!", nextStep: this.sayWhatIsOffered }
    }
    return { body: "Welcome back!", nextStep: this.end }
  }
}

export default class IntroductionLWCDialogue extends Dialogue<State> {
  static id = DialogueIDs.IntroductionLWC
  readonly name: string = "IntroductionLWCDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(IntroductionLWCDialogue.id, new IntroductionLWCScript(), state, snapshot)
  }
}
