import { BaseScriptState } from "../../../BaseScript"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { step } from "../../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { DiscussionSteps, IDefaultChatFlowSettingsAssessmentAndTreatments } from "@limbic/types"
import AssessmentIAPTScript from "../../assessment/AssessmentIAPTScript"

export type IAssessmentAndTreatmentsSettings = IDefaultChatFlowSettingsAssessmentAndTreatments

type State = BaseScriptState

export type AssessmentAndTreatmentsScriptState = State

export class AssessmentAndTreatmentsScript extends AssessmentIAPTScript {
  readonly name: string = "AssessmentAndTreatmentsScript"
  protected enableADSM: boolean
  constructor(settings: IAssessmentAndTreatmentsSettings | undefined = {}) {
    super()
    this.enableADSM = settings?.enableADSM ?? true
  }

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    if (this.enableADSM) {
      return { nextStep: this.goToAssessmentADSM }
    }
    return super.start(d)
  }

  @step.logState
  goToAssessmentADSM(d: IStepData<State>): IStepResult {
    // prettier-ignore
    const AssessmentADSMDialogue = this.rootStore.discussionStore.getDialogueClass(
      DiscussionSteps.AssessmentADSM
    )
    if (AssessmentADSMDialogue) {
      const nextDialogue = new AssessmentADSMDialogue(d.state)
      return { nextDialogue, nextStep: this.end }
    }

    this.logException(new Error("no nextDialogue"), "goToAssessmentADSM")
    return { nextStep: this.step1 }
  }

  /** Generic Handlers */

  async onHandleIAPTPhobiaScale(_state: State): Promise<IStepResult<State>> {
    return {
      nextStep: this.sayReadyForWASAS
    }
  }

  async onHandleIAPTWorkAndSocialAdjustment(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayThatsEverything }
  }
}

export default class AssessmentAndTreatmentsDialogue extends AdHocDialogue<
  State,
  AssessmentAndTreatmentsScript
> {
  static id = DialogueIDs.AssessmentAndTreatments
  readonly name: string = "AssessmentAndTreatmentsDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: IAssessmentAndTreatmentsSettings
  ) {
    super(
      AssessmentAndTreatmentsDialogue.id,
      new AssessmentAndTreatmentsScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
