import { z, ZodSchema } from "zod"
import { step } from "../../../backend/chatbot/decorators/step"
import BaseScript, { BaseScriptState, BaseScriptStateSchema } from "../../BaseScript"
import { TrackingEvents } from "../../../models/Constants"
import getQRCodeURL from "../../../utils/getQRCodeURL"
import getNextWorkingDay from "../../../utils/getNextWorkingDay"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { StepResultBodyType } from "../../../backend/chatbot/models/IStep"
import { LanguageCodes } from "@limbic/types"

interface WindowWithLimbicNameSpace extends Window {
  DEEP_LINK: string
}

declare let window: WindowWithLimbicNameSpace

window.DEEP_LINK = String(process.env.REACT_APP_DEEP_LINK ?? "enabled")

const deepLinkEnabled = window.DEEP_LINK !== "disabled"

interface State extends BaseScriptState {
  isIdleSubmitted?: boolean
}

export type GoodbyeScriptState = State

export const GoodbyeScriptStateSchema = BaseScriptStateSchema.extend({
  isIdleSubmitted: z.boolean().optional()
})

export default abstract class GoodbyeScript extends BaseScript<State> {
  /** Optional Abstract Generic Handlers */

  getCustomRecapMessage?(state: State): Promise<StepResultBodyType[] | undefined>

  onHandleRecapMessage?(
    state: State,
    body: StepResultBodyType[],
    recapMessage: string | void
  ): Promise<IStepResult | void>

  /** Script Names */

  @step.logState
  async start(d: IStepData<State>): Promise<IStepResult> {
    this.referralStore.setIdleSubmissionActive(false)
    this.updateReferralType(d.state)
    const body: StepResultBodyType[] = []
    const introMessage = await this.getIntroMessage(d.state)
    introMessage && body.push(introMessage)
    const customRecap = await this.getCustomRecapMessage?.(d.state)
    customRecap?.length && body.push(...customRecap)
    const recapMessage = await this.getRecapMessage(d.state)
    recapMessage && body.push(recapMessage)

    const result = await this.onHandleRecapMessage?.(d.state, body, recapMessage)
    if (result) return result

    if (!body.length) {
      return { nextStep: this.askWhereDidYouHearAboutUs }
    }

    const choices: any[] = [
      { body: this.t("Okay") },
      recapMessage && { body: this.t("I understand") }
    ].filter(Boolean)

    return {
      body: this.t(body),
      prompt: {
        id: this.getPromptId("recap"),
        trackResponse: true,
        type: "inlinePicker",
        choices
      },
      nextStep: this.askWhereDidYouHearAboutUs
    }
  }

  @step.logState
  askWhereDidYouHearAboutUs(_d: IStepData<State>): IStepResult {
    if (this.clinicalStore.isCrisis) {
      this.track(TrackingEvents.SKIP_WHERE_DID_YOU_HEAR)
      return { nextStep: this.sayIHopeIHelped }
    }
    const serviceName = this.rootStore.configStore.serviceName
    return {
      body: this.t("Before we close, where did you hear about {serviceName}?", { serviceName }),
      prompt: {
        id: this.getPromptId("askWhereDidYouHearAboutUs"),
        type: "text",
        cancelIsEmptySubmit: true
      },
      nextStep: this.handleWhereDidYouHearAboutUsWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: GoodbyeScript) => {
    d.state.whereDidYouHearAboutService = d.response
    // TODO: this 👇 needs to be just one property instead
    //        of this mess. We have to do this because we have
    //        a different property name for each service sadly
    // NOTE: Insight doesn't use this step because they ask it
    //       earlier in the GetName dialogue and we send it in
    //       the SelfReferral dialogue
    void script.referralStore.updateReferral({
      whereHeardAboutUs: d.response, // GMHub, Insight, VHGNotts2
      whereHeard: d.response, // vhg, sabp, Lincs, Essex, Sefton, Northumberland
      whereHeardDetails: d.response, // Lincs, Essex
      referralSourceDetails: d.response // SABP, Suffolk
    })
  })
  @step.checkInputForCrisis({
    getNextStep: (s: GoodbyeScript) => s.askFeedback
  })
  handleWhereDidYouHearAboutUsWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askFeedback }
  }

  @step.logState
  sayIHopeIHelped(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("I hope I've been able to help you today"),
      nextStep: this.sayGoodBye
    }
  }

  @step.logState
  askFeedback(d: IStepData<State>): IStepResult {
    const requiresUrgentSupport =
      this.clinicalStore.requiresUrgentSupport || d.state.requiresUrgentSupport
    if (this.clinicalStore.isCrisis || requiresUrgentSupport) {
      this.track(TrackingEvents.SKIP_FEEDBACK_CRISIS)
      return { nextStep: this.sayIHopeIHelped }
    }
    return {
      body: this.t("I hope I've been able to help you today"),
      prompt: {
        id: this.getPromptId("askFeedback"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes thanks - you've helped me"), value: "yes" },
          { body: this.t("I'd have preferred a bit more help"), value: "more" },
          { body: this.t("No - you haven't helped me"), value: "no" }
        ]
      },
      nextStep: this.handleFeedback
    }
  }

  @step
  handleFeedback(d: IStepData<State, "yes" | "more" | "no">): IStepResult {
    if (d.response === "no") {
      d.state.isHelpful = "No"
      return { nextStep: this.saySorryToHearThat }
    }
    if (d.response === "more") {
      d.state.isHelpful = "Needed more"
      return { nextStep: this.sayUnderstood }
    }
    d.state.isHelpful = "Yes"
    return { nextStep: this.sayImGlad }
  }

  @step
  saySorryToHearThat(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Sorry to hear that. How could I improve?",
        "(Please Note: Feedback is audited and used to inform service improvement however, entries given here are not reviewed or responded to by a trained clinician)"
      ]),
      prompt: {
        id: this.getPromptId("howCanIImprove (Not Helpful)"),
        trackResponse: true,
        type: "text",
        cancelIsEmptySubmit: true
      },
      nextStep: this.handleSorryToHearThatWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: GoodbyeScript) => {
    const suggestion = d.response
    script.track(TrackingEvents.IMPROVEMENT_SUGGESTION, { isHelpful: "No", suggestion })
    if (suggestion?.trim()) d.state.improvementSuggestion = suggestion
  })
  @step.checkInputForCrisis({
    getNextStep: (s: GoodbyeScript) => s.sayGoodBye
  })
  handleSorryToHearThatWithCrisis(_d: IStepData<State, string>): IStepResult {
    return {
      body: this.t("Noted - I'm always learning so hopefully I can do better next time"),
      nextStep: this.sayGoodBye
    }
  }

  @step
  sayUnderstood(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Understood. How could I improve?",
        "(Please Note: Feedback is audited and used to inform service improvement however, entries given here are not reviewed or responded to by a trained clinician)"
      ]),
      prompt: {
        id: this.getPromptId("howCanIImprove (Needed More)"),
        trackResponse: true,
        type: "text",
        cancelIsEmptySubmit: true
      },
      nextStep: this.handleUnderstoodWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: GoodbyeScript) => {
    const suggestion = d.response
    script.track(TrackingEvents.IMPROVEMENT_SUGGESTION, { isHelpful: "Needed More", suggestion })
    if (suggestion?.trim()) d.state.improvementSuggestion = suggestion
  })
  @step.checkInputForCrisis({
    getNextStep: (s: GoodbyeScript) => s.sayGoodBye
  })
  handleUnderstoodWithCrisis(_d: IStepData<State, string>): IStepResult {
    return {
      body: this.t("Noted - I'm always learning so hopefully I can do better next time"),
      nextStep: this.sayGoodBye
    }
  }

  @step
  sayImGlad(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "I'm really glad to hear that",
        "What was the main benefit I was able to bring you?",
        "(Please Note: Feedback is audited and used to inform service improvement however, entries given here are not reviewed or responded to by a trained clinician)"
      ]),
      prompt: {
        id: this.getPromptId("howCanIImprove (Helpful)"),
        trackResponse: true,
        type: "text",
        cancelIsEmptySubmit: true
      },
      nextStep: this.handleImGladWithCrisis
    }
  }

  @step.logState
  returnToSayImGlad(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("So you said I was able to help you 😊"),
      nextStep: this.sayImGlad
    }
  }

  @step
  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: GoodbyeScript) => {
    const suggestion = d.response
    script.track(TrackingEvents.IMPROVEMENT_SUGGESTION, { isHelpful: "Yes", suggestion })
    if (suggestion?.trim()) d.state.improvementSuggestion = suggestion
  })
  @step.checkInputForCrisis({
    getNextStep: (s: GoodbyeScript) => s.sayGoodBye
  })
  handleImGladWithCrisis(_d: IStepData<State, string>): IStepResult {
    return {
      body: this.t("Noted - I'm always learning so hopefully I can do even better next time"),
      nextStep: this.sayGoodBye
    }
  }

  @step
  async sayGoodBye(d: IStepData<State>): Promise<IStepResult> {
    void this.referralStore.updateReferral({
      isHelpful: d.state.isHelpful,
      improvementSuggestion: d.state.improvementSuggestion
    })
    this.referralStore.stopPinging()

    return {
      body: this.t("Well done for taking this important step towards better mental health"),
      nextStep: this.sayDynamicLink
    }
  }

  @step.logState
  sayDynamicLink(_d: IStepData<State>): IStepResult {
    if (!deepLinkEnabled || !process.env.REACT_APP_LIMBIC_CARE_APP_STORE_URL) {
      return { nextStep: this.sayFinalWords }
    }
    const qrCodeURL = getQRCodeURL(process.env.REACT_APP_LIMBIC_CARE_APP_STORE_URL)
    return {
      body: [
        this.t("I'm looking forward to going on this journey with you"),
        this.t("We can continue our conversations in my mobile app"),
        this.t("If you're already on your mobile device, just click [here]({url}) to download it", {
          url: process.env.REACT_APP_LIMBIC_CARE_APP_STORE_URL
        }),
        this.t("If you're on a desktop computer, you can scan the QR code below"),
        {
          type: "imageAttachment",
          image: qrCodeURL
        }
      ],
      prompt: {
        id: this.getPromptId("sayDynamicLink"),
        type: "inlinePicker",
        choices: [{ body: this.t("Done"), value: true }]
      },
      nextStep: this.sayFinalWords
    }
  }

  @step.logState
  sayFinalWords(d: IStepData<State, true | undefined>): IStepResult {
    const name = this.getName(d.state)
    const great = d.response ? [this.t("Great")] : []
    return {
      body: [...great, this.t("Goodbye {name} 👋", { name })],
      nextStep: this.end
    }
  }

  @step
  end(_d: IStepData<State>): IStepResult {
    this.track(TrackingEvents.CONVERSATION_END)
    return {
      clearStack: true
    }
  }

  /** Generic Handler */

  getStateSchema(): ZodSchema | undefined {
    return GoodbyeScriptStateSchema
  }

  async getIntroMessage(state: State): Promise<string | void> {
    const isCrisis = this.clinicalStore.isCrisis
    const name = this.getName(state)
    return this.t(
      isCrisis
        ? "Thank you for sharing this information with me {name}"
        : "Well, it's been a pleasure getting to know you {name}",
      { name }
    )
  }

  async getReferredYouMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted) {
      const organisationName = this.rootStore.configStore.organisationName
      const iaptName = this.getIAPTName(state) || organisationName
      return this.t(
        state.isIdleSubmitted //
          ? "It looks like there hasn’t been any activity for some time so I've referred you to {iaptName}"
          : "I've referred you to {iaptName}",
        { iaptName }
      )
    }
  }

  async getAdminWillCallMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted && state.needsAssessmentCall) {
      return this.t(
        "Someone from our admin team will be in touch in the next 3 working days to book you in for an assessment"
      )
    }
  }

  async getHighRiskContactMessage(state: State): Promise<string | void> {
    if (this.clinicalStore.isHighRisk) {
      const nextWorkingDay = await getNextWorkingDay()
      return this.t(
        this.rootStore.applicationStore.translator?.language === LanguageCodes.EN
          ? "Because you identified as being in crisis, someone from our duty of care team will call you {nextWorkingDay}. Please remember, Limbic is not an emergency response service and you are encouraged to contact 999 if you feel you are in danger"
          : "Because you identified as being in crisis, someone from our duty of care team will call you within the next working day. Please remember, Limbic is not an emergency response service and you are encouraged to contact 999 if you feel you are in danger",
        { nextWorkingDay }
      )
    }
  }

  async getModerateRiskContactMessage(state: State): Promise<string | void> {
    if (this.clinicalStore.isModerateRisk) {
      const nextWorkingDay = await getNextWorkingDay()
      return this.t(
        this.rootStore.applicationStore.translator?.language === LanguageCodes.EN
          ? "Because you identified as being in crisis, someone from our duty of care team will call you {nextWorkingDay}. Please remember, Limbic is not an emergency response service and you are encouraged to contact 999 if you feel you are in danger"
          : "Because you identified as being in crisis, someone from our duty of care team will call you within the next working day. Please remember, Limbic is not an emergency response service and you are encouraged to contact 999 if you feel you are in danger",
        { nextWorkingDay }
      )
    }
  }

  async getTreatmentMessage(_state: State): Promise<string | void> {
    const treatment = this.clinicalStore.getAcceptedTreatment()
    if (treatment) {
      return this.t(
        "You have been offered {treatment} and someone from our team will be in touch in the next 3 working days to book you in for your first session",
        { treatment: treatment.formattedName }
      )
    }
  }

  async getResourcesMessage(_state: State): Promise<string | void> {
    return undefined
  }

  async getRecapMessage(state: State): Promise<string | void> {
    const recaps: string[] = []
    const referredYouMessage = await this.getReferredYouMessage(state)
    referredYouMessage && recaps.push(referredYouMessage)

    const adminWillCallMessage = await this.getAdminWillCallMessage(state)
    adminWillCallMessage && recaps.push(adminWillCallMessage)

    const highRiskContactMessage = await this.getHighRiskContactMessage(state)
    highRiskContactMessage && recaps.push(highRiskContactMessage)

    const moderateRiskContactMessage = await this.getModerateRiskContactMessage(state)
    moderateRiskContactMessage && recaps.push(moderateRiskContactMessage)

    const treatmentMessage = await this.getTreatmentMessage(state)
    treatmentMessage && recaps.push(treatmentMessage)

    const resourcesMessage = await this.getResourcesMessage(state)
    resourcesMessage && recaps.push(resourcesMessage)

    const formattedRecap = recaps.length > 1 ? recaps.map((i, idx) => `${idx + 1}. ${i}`) : recaps
    const recapString = formattedRecap.join("\n")

    if (recapString?.length) {
      const justToRecap = this.t("Just to recap")
      return `${justToRecap}:\n\n${recapString}`
    }
  }
}
