import { DiscussionSteps } from "@limbic/types"
import { DialogueIDs } from "../../conversation/DialogueIDs"

// 👇 this just means partially partial - aka we want some steps to always exist
interface IDialoguesMap extends Partial<Record<DiscussionSteps, DialogueIDs>> {
  [DiscussionSteps.SelfReferral]: DialogueIDs
  [DiscussionSteps.Assessment]: DialogueIDs
  [DiscussionSteps.RiskPathway]: DialogueIDs
  [DiscussionSteps.Crisis]: DialogueIDs
  [DiscussionSteps.Goodbye]: DialogueIDs
}

const defaultMap: Partial<IDialoguesMap> = {
  [DiscussionSteps.Intro]: DialogueIDs.IntroductionIAPT,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameIAPT,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckIAPT,
  [DiscussionSteps.Permissions]: DialogueIDs.PermissionsIAPT,
  [DiscussionSteps.SelfReferralPitch]: DialogueIDs.SelfReferralPitchIAPT,
  [DiscussionSteps.ResearchConsent]: DialogueIDs.ResearchConsent,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchIAPT,
  [DiscussionSteps.ADSMConsent]: DialogueIDs.ADSMConsent,
  [DiscussionSteps.PHQ9]: DialogueIDs.PHQ9,
  [DiscussionSteps.TreatmentOptions]: DialogueIDs.TreatmentOptionsIAPT,
  [DiscussionSteps.SuitableServicesSignpost]: DialogueIDs.SuitableServicesSignpostIAPT,
  [DiscussionSteps.UnderageSignpost]: DialogueIDs.UnderageSignpost,
  [DiscussionSteps.CollectPhoneNumber]: DialogueIDs.CollectPhoneNumber,
  [DiscussionSteps.CollectAlcoholConsumption]: DialogueIDs.CollectAlcoholConsumption,
  [DiscussionSteps.CollectMedicationAndSubstancesDetails]:
    DialogueIDs.CollectMedicationAndSubstancesDetails,
  [DiscussionSteps.CollectSubstances]: DialogueIDs.CollectSubstances,
  [DiscussionSteps.CheckPostCodeFromAddressLookup]: DialogueIDs.CheckPostCodeFromAddressLookup,
  [DiscussionSteps.CheckCovidAndDetails]: DialogueIDs.CheckCovidAndDetails,
  [DiscussionSteps.CollectMainIssue]: DialogueIDs.CollectMainIssue,
  [DiscussionSteps.CollectGoalForTherapy]: DialogueIDs.CollectGoalForTherapy,
  [DiscussionSteps.CollectNHSNumber]: DialogueIDs.CollectNHSNumber,
  [DiscussionSteps.CollectASD]: DialogueIDs.CollectASD,
  [DiscussionSteps.CollectADHD]: DialogueIDs.CollectADHD,
  [DiscussionSteps.CollectPreferredCorrespondence]: DialogueIDs.CollectPreferredCorrespondence,
  [DiscussionSteps.CollectNationality]: DialogueIDs.CollectNationality,
  [DiscussionSteps.CollectEthnicity]: DialogueIDs.CollectEthnicity,
  [DiscussionSteps.CollectPriorMHTreatmentDetails]: DialogueIDs.CollectPriorMHTreatmentDetails,
  [DiscussionSteps.CollectCurrentMHTreatmentDetails]: DialogueIDs.CollectCurrentMHTreatmentDetails,
  [DiscussionSteps.CollectSexuality]: DialogueIDs.CollectSexuality,
  [DiscussionSteps.CollectGender]: DialogueIDs.CollectGender,
  [DiscussionSteps.CollectDisabilities]: DialogueIDs.CollectDisabilities,
  [DiscussionSteps.CollectLanguageAndInterpreter]: DialogueIDs.CollectLanguageAndInterpreter,
  [DiscussionSteps.CollectEmail]: DialogueIDs.CollectEmail,
  [DiscussionSteps.CollectReligion]: DialogueIDs.CollectReligion,
  [DiscussionSteps.CollectDateOfBirth]: DialogueIDs.CollectDateOfBirth,
  [DiscussionSteps.CollectName]: DialogueIDs.CollectName,
  [DiscussionSteps.CollectFeedback]: DialogueIDs.CollectFeedback,
  [DiscussionSteps.CollectSMI]: DialogueIDs.CollectSMI,
  [DiscussionSteps.CheckCrisisDetection]: DialogueIDs.CheckCrisisDetection,
  [DiscussionSteps.GetPermissions]: DialogueIDs.GetPermissions,
  [DiscussionSteps.CollectLongTermMedicalConditionDetails]:
    DialogueIDs.CollectLongTermMedicalConditionDetails,
  [DiscussionSteps.ServiceSearch]: DialogueIDs.ServiceSearch,
  [DiscussionSteps.SpineSearch]: DialogueIDs.SpineSearch,
  [DiscussionSteps.SubmitReferral]: DialogueIDs.SubmitReferral,
  [DiscussionSteps.AssessmentAndTreatments]: DialogueIDs.AssessmentAndTreatments,
  [DiscussionSteps.BookAppointment]: DialogueIDs.BookAppointmentDynamic,
  [DiscussionSteps.BookAppointmentChatflow]: DialogueIDs.BookAppointmentChatflow,
  [DiscussionSteps.GoodbyeRecap]: DialogueIDs.GoodbyeRecap
}

export const dialoguesMap_DemoDynamic: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameDynamic,
  [DiscussionSteps.Permissions]: DialogueIDs.PermissionsDynamic,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckDynamic,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralDynamic,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentDemo,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayDemo,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeDemo,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisDynamic
}

export const dialoguesMap_Demo: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckDemo,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralDemo,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentDemo,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayDemo,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeDemo,
  [DiscussionSteps.BookAppointment]: DialogueIDs.BookAppointment,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisDemo
}

export const dialoguesMap_DemoPrivateProviders: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.Intro]: DialogueIDs.IntroductionDemoPrivateProviders,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameDemoPrivateProviders,
  [DiscussionSteps.PeaceOfMind]: DialogueIDs.PeaceOfMindDemoPrivateProviders,
  [DiscussionSteps.Permissions]: DialogueIDs.PermissionsDemoPrivateProviders,
  [DiscussionSteps.SelfReferralPitch]: DialogueIDs.SelfReferralPitchDemoPrivateProviders,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralDemoPrivateProviders,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchDemoPrivateProviders,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentDemoPrivateProviders,
  [DiscussionSteps.TreatmentOptions]: DialogueIDs.TreatmentOptionsDemoPrivateProviders,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayDemoPrivateProviders,
  [DiscussionSteps.WellbeingHub]: DialogueIDs.WellbeingDemoPrivateProviders,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeDemoPrivateProviders,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisDemoPrivateProviders
}

export const dialoguesMap_GM_HUB: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.Intro]: DialogueIDs.IntroductionGMHub,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckGMHub,
  [DiscussionSteps.Permissions]: DialogueIDs.PermissionsGMHub,
  [DiscussionSteps.SelfReferralPitch]: DialogueIDs.SelfReferralPitchGMHub,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralGMHub,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchGMHub,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentGMHub,
  [DiscussionSteps.PHQ9]: DialogueIDs.PHQ9GMHub,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayGMHub,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeGMHub,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisGMHub,
  [DiscussionSteps.UnderageSignpost]: DialogueIDs.UnderageSignpostGMHub
}

export const dialoguesMap_ESSEX: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.Intro]: DialogueIDs.IntroductionEssex,
  [DiscussionSteps.Permissions]: DialogueIDs.PermissionsEssex,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameEssex,
  [DiscussionSteps.CollectPhoneNumber]: DialogueIDs.CollectPhoneNumberEssex,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckEssex,
  [DiscussionSteps.UnderageSignpost]: DialogueIDs.UnderageSignpostEssex,
  [DiscussionSteps.SuitableServicesSignpost]: DialogueIDs.SuitableServicesSignpostEssex,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralEssex,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchEssex,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentEssex,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayEssex,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisEssex,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeEssex
}

export const dialogueMap_HEALIX: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.Intro]: DialogueIDs.IntroductionHealix,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameHealix,
  [DiscussionSteps.PeaceOfMind]: DialogueIDs.PeaceOfMindHealix,
  [DiscussionSteps.SelfReferralPitch]: DialogueIDs.SelfReferralPitchHealix,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralHealix,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchHealix,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentHealix,
  [DiscussionSteps.TreatmentOptions]: DialogueIDs.TreatmentOptionsHealix,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayHealix,
  [DiscussionSteps.WellbeingHub]: DialogueIDs.WellbeingHealix,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeHealix,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisHealix
}

export const dialogueMap_VITALITY: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.Intro]: DialogueIDs.IntroductionVitality,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameVitality,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckVitality,
  [DiscussionSteps.PeaceOfMind]: DialogueIDs.PeaceOfMindVitality,
  [DiscussionSteps.Permissions]: DialogueIDs.PermissionsVitality,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchVitality,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralVitality,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentVitality,
  [DiscussionSteps.PHQ9]: DialogueIDs.PHQ9Vitality,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayVitality,
  [DiscussionSteps.TreatmentOptions]: DialogueIDs.TreatmentOptionsVitality,
  [DiscussionSteps.BookAppointment]: DialogueIDs.BookAppointmentVitality,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeVitality,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisVitality
}

export const dialoguesMap_INSIGHT: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.Intro]: DialogueIDs.IntroductionInsight,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameInsight,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckInsight,
  [DiscussionSteps.Permissions]: DialogueIDs.PermissionsInsight,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralInsight,
  [DiscussionSteps.ADSMConsent]: DialogueIDs.ADSMConsentInsight,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchInsight,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentInsight,
  [DiscussionSteps.AssessmentADSM]: DialogueIDs.AssessmentADSMInsight,
  [DiscussionSteps.TreatmentOptions]: DialogueIDs.TreatmentOptionsInsight,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayInsight,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeInsight,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisInsight,
  [DiscussionSteps.CollectPhoneNumber]: DialogueIDs.CollectPhoneNumberInsight
}

export const dialoguesMap_LINCOLNSHIRE: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.Permissions]: DialogueIDs.PermissionsLincolnshire,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameLincolnshire,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckLincolnshire,
  [DiscussionSteps.UnderageSignpost]: DialogueIDs.UnderageSignpostLincolnshire,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralLincolnshire,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchLincolnshire,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentLincolnshire,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayLincolnshire,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisLincolnshire,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeLincolnshire
}

export const dialoguesMap_SABP: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.Permissions]: DialogueIDs.PermissionsSABP,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameSABP,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckSABP,
  [DiscussionSteps.SuitableServicesSignpost]: DialogueIDs.SuitableServicesSignpostSABP,
  [DiscussionSteps.UnderageSignpost]: DialogueIDs.UnderageSignpostSABP,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralSABP,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchSABP,
  [DiscussionSteps.ResearchConsent]: DialogueIDs.ResearchConsentSABP,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentSABP,
  [DiscussionSteps.PHQ9]: DialogueIDs.PHQ9SABP,
  [DiscussionSteps.TreatmentOptions]: DialogueIDs.TreatmentOptionsSABP,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwaySABP,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeSABP,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisSABP,
  [DiscussionSteps.CheckPostCodeFromAddressLookup]: DialogueIDs.CheckPostCodeFromAddressLookupSABP
}

export const dialoguesMap_SABP_V2: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.Intro]: DialogueIDs.IntroductionDynamic,
  [DiscussionSteps.Permissions]: DialogueIDs.PermissionsDynamic,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameDynamic,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckDynamic,
  [DiscussionSteps.SuitableServicesSignpost]: DialogueIDs.SuitableServicesSignpostSABP,
  [DiscussionSteps.UnderageSignpost]: DialogueIDs.UnderageSignpostSABP,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralDynamic,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchSABP,
  [DiscussionSteps.ResearchConsent]: DialogueIDs.ResearchConsentSABP,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentADSMDynamic,
  [DiscussionSteps.PHQ9]: DialogueIDs.PHQ9SABP,
  [DiscussionSteps.TreatmentOptions]: DialogueIDs.TreatmentOptionsSABP,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwaySABP,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeSABP,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisDynamic,
  [DiscussionSteps.CheckPostCodeFromAddressLookup]: DialogueIDs.CheckPostCodeFromAddressLookupSABP
}

export const dialoguesMap_VHG: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.Intro]: DialogueIDs.IntroductionVHG,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameVHG,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckVHG,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralVHG,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchVHG,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentVHG,
  [DiscussionSteps.PHQ9]: DialogueIDs.PHQ9VHG,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayVHG,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeVHG,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisVHG
}

export const dialoguesMapINSIGHT_WAITLIST: Partial<Record<DiscussionSteps, DialogueIDs>> = {
  ...dialoguesMap_INSIGHT,
  [DiscussionSteps.Intro]: DialogueIDs.IntroductionInsightWaitlist,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameInsightWaitlist,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralInsightWaitlist
}

export const dialoguesMapINSIGHT_FOLLOWUP: Partial<Record<DiscussionSteps, DialogueIDs>> = {
  ...dialoguesMap_INSIGHT,
  [DiscussionSteps.Intro]: DialogueIDs.IntroductionInsightFollowUp,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameInsightFollowUp,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralInsightWaitlist,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchInsightFollowUp,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayInsightFollowUp,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeInsightFollowUp
}

export const dialoguesMap_MPFT: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.Intro]: DialogueIDs.IntroductionMPFT,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameMPFT,
  [DiscussionSteps.Permissions]: DialogueIDs.PermissionsMPFT,
  [DiscussionSteps.CollectPhoneNumber]: DialogueIDs.CollectPhoneNumberMPFT,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckMPFT,
  [DiscussionSteps.UnderageSignpost]: DialogueIDs.UnderageSignpostMPFT,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralMPFT,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchMPFT,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentMPFT,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayMPFT,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisMPFT,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeMPFT
}

export const dialoguesMap_MHM: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.Permissions]: DialogueIDs.PermissionsMHM,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameMHM,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckMHM,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralMHM,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchMHM,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentMHM,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayMHM,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeMHM,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisMHM,
  [DiscussionSteps.CollectPhoneNumber]: DialogueIDs.CollectPhoneNumberMHM,
  [DiscussionSteps.CheckPostCodeFromAddressLookup]: DialogueIDs.CheckPostCodeFromAddressLookupMHM
}

export const dialoguesMap_BRADFORD: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameBradford,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckBradford,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralDynamic,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchBradford,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentBradford,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayBradford,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisBradford,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeBradford,
  [DiscussionSteps.UnderageSignpost]: DialogueIDs.UnderageSignpostBradford
}

export const dialoguesMap_MIND: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.Intro]: DialogueIDs.IntroductionMind,
  [DiscussionSteps.Permissions]: DialogueIDs.PermissionsMind,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameMind,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckMind,
  [DiscussionSteps.UnderageSignpost]: DialogueIDs.UnderageSignpostMind,
  [DiscussionSteps.SuitableServicesSignpost]: DialogueIDs.SuitableServicesSignpostMind,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralMind,
  [DiscussionSteps.SelfReferralPitch]: DialogueIDs.SelfReferralPitchMind,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchMind,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentMind,
  [DiscussionSteps.BookAppointment]: DialogueIDs.BookAppointmentMind,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayMind,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisMind,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeMind,
  [DiscussionSteps.CheckPostCodeFromAddressLookup]: DialogueIDs.CheckPostCodeFromAddressLookupMind
}

export const dialoguesMap_LWC: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.Intro]: DialogueIDs.IntroductionLWC,
  [DiscussionSteps.Permissions]: DialogueIDs.PermissionsLWC,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameLWC,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckDynamic,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralDynamic,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchLWC,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentLWC,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayLWC,
  [DiscussionSteps.TreatmentOptions]: DialogueIDs.TreatmentOptionsLWC,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeLWC,
  [DiscussionSteps.SuitableServicesSignpost]: DialogueIDs.SuitableServicesSignpostLWC,
  [DiscussionSteps.UnderageSignpost]: DialogueIDs.UnderAgeSignpostLWC,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisLWC
}

export const dialoguesMap_BEXLEY: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.Intro]: DialogueIDs.IntroductionDynamic,
  [DiscussionSteps.Permissions]: DialogueIDs.PermissionsDynamic,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameDynamic,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckDynamic,
  /**
   * 👇
   * Self referral pitch is in the Self Referral Dialogue in the dashboard
   * */
  [DiscussionSteps.SelfReferralPitch]: undefined,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralDynamic,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentDynamic,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayDynamic, // TODO: Check 100% this is correct assignment
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeBexley,
  [DiscussionSteps.SuitableServicesSignpost]: DialogueIDs.SuitableServicesSignpostIAPT,
  [DiscussionSteps.UnderageSignpost]: DialogueIDs.UnderAgeSignpostBexley,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisDynamic
}

export const dialoguesMap_CHESHIRE_WIRRAL: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.Intro]: DialogueIDs.IntroductionDynamic,
  [DiscussionSteps.Permissions]: DialogueIDs.PermissionsCW,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameCW,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckCW,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralDynamic,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchCW,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentCW,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayCW,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisCW,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeCW,
  [DiscussionSteps.UnderageSignpost]: DialogueIDs.UnderAgeSignpostCW
}

export const dialoguesMap_OXFORD: Partial<IDialoguesMap> = {
  ...defaultMap,
  [DiscussionSteps.Intro]: DialogueIDs.IntroductionOxford,
  [DiscussionSteps.Permissions]: DialogueIDs.PermissionsOxfordBucks,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameOxfordBucks,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckOxford,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralDynamic,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchOxfordBucks,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentOxfordBucks,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayOxford,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeOxford,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisOxfordBucks,
  [DiscussionSteps.CollectAlcoholConsumption]: DialogueIDs.CollectAlcoholConsumption,
  [DiscussionSteps.UnderageSignpost]: DialogueIDs.UnderageSignpostOxford,
  [DiscussionSteps.SuitableServicesSignpost]: DialogueIDs.SuitableServicesSignpostOxford
}

export const dialoguesMap_BUCKINGHSAMHIRE: Partial<IDialoguesMap> = {
  ...defaultMap,
  [DiscussionSteps.Intro]: DialogueIDs.IntroductionBuckinghamshire,
  [DiscussionSteps.Permissions]: DialogueIDs.PermissionsOxfordBucks,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameOxfordBucks,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckBuckinghamshire,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralDynamic,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchOxfordBucks,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentOxfordBucks,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayBuckinghamshire,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeBuckinghamshire,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisOxfordBucks,
  [DiscussionSteps.CollectAlcoholConsumption]: DialogueIDs.CollectAlcoholConsumption,
  [DiscussionSteps.UnderageSignpost]: DialogueIDs.UnderAgeSignpostBuckinghamshire,
  [DiscussionSteps.SuitableServicesSignpost]: DialogueIDs.SuitableServicesSignpostBuckinghamshire
}

export const dialoguesMap_DORKING: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.Intro]: DialogueIDs.IntroductionDorking,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameDorking,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckDorking,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralDynamic,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchDorking,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentDorking,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayDorking,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisDorking,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeDorking,
  [DiscussionSteps.UnderageSignpost]: DialogueIDs.UnderageSignpostDorking
}
