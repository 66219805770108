// noinspection JSUnusedGlobalSymbols

import invariant from "./invariant"
import { differenceWith, isEqual } from "lodash"

export function separateArrayBySlices(array: any[], slicesCount = 2): any[][] {
  return array.reduce((acc, item, index) => {
    const colIndex = index % slicesCount
    const current = acc[colIndex] || []
    acc[colIndex] = [...current, item]
    return acc
  }, [])
}

export function findLast<T>(
  array: T[] = [],
  finderFn: (item: T, index?: number, collection?: T[]) => boolean
): T | undefined {
  invariant(finderFn, "No iterating function applied to findLast")
  for (let i = array.length - 1; i >= 0; i--) {
    const currentItem = array[i]
    if (finderFn(currentItem, i, array)) {
      return currentItem
    }
  }
}

export function arrayUpsert<T>(
  array: T[] = [],
  item: T,
  matchFn: (item: any, index: number, collection: any[]) => boolean
): T[] {
  invariant(matchFn, "No iterating function applied to arrayUpsert")
  if (array) {
    let itemFound = false
    for (let i = 0, { length } = array; i < length; i++) {
      const currentItem = array[i]
      if (matchFn(currentItem, i, array)) {
        array[i] = item
        itemFound = true
        break
      }
    }
    if (!itemFound) {
      array.push(item)
    }
  }
  return array
}

export function joinWithAnd<T>(array: T[] = [], and = "and"): string {
  if (!array?.length) {
    return ""
  }
  return array.reduce((t, current: T, i, col) => {
    if (i === 0) {
      return `${current}`
    }
    if (current) {
      return i === col.length - 1 ? `${t} ${and} ${current}` : `${t}, ${current}`
    }
    return t
  }, "")
}

export function joinWithOr<T>(array: T[] = [], or = "or"): string {
  if (!array?.length) {
    return ""
  }
  return array.reduce((t, current: T, i, col) => {
    if (i === 0) {
      return `${current}`
    }
    if (current) {
      return i === col.length - 1 ? `${t} ${or} ${current}` : `${t}, ${current}`
    }
    return t
  }, "")
}

export function uniquelyConcat(...params: any[]): any[] {
  return [...new Set([].concat(...params))]
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function enumToArray(someEnum: any): (typeof someEnum)[] {
  if (someEnum == null) return []
  const enumValues: Array<typeof someEnum> = []
  for (const key in someEnum) {
    enumValues.push(someEnum[key] as typeof someEnum)
  }
  return enumValues
}

/**
 * Returns an array of the common elements from arrays a and b
 * @param a {*[]} one of the arrays to get the intersection from
 * @param b {*[]} one of the arrays to get the intersection from
 */
export function getIntersection<T = unknown>(a: T[], b: unknown[]): T[] {
  return b.filter((i: any) => a.includes(i)) as T[]
}

/**
 * Returns an array of the elements that are in a, but not in b
 * @param a {*[]} one of the arrays to get the difference from
 * @param b {*[]} one of the arrays to get the difference from
 */
export function getDifference<T = unknown>(a: T[], b: unknown[]): T[] {
  return a.filter((i: any) => !b.includes(i)) as T[]
}

/**
 * Filters out all elements from a that match with an element from b
 * @param a {*[]} the array to filter
 * @param b {*[]} the array to check for matches
 */
export function excludeMatches(a: string[], b: string[]): string[] {
  return a.filter(item => !b.some(i => item.includes(i)))
}

/**
 * Accepts an uniques array and checks an input array for common
 * elements. If the common elements are more than one, then it
 * means that more than one unique was found and thus the input
 * array contains items that shouldn't be together in a list.
 * @param uniques {*[]} a list of items that can't live together in a list
 * @param input {*[]} the list to check for validity
 */
export function isValidUniqueCombo<T = unknown>(uniques: T[], input: T[]): boolean {
  if (uniques?.length && !input?.length) return false
  return getIntersection(uniques, input).length <= 1
}

/**
 * Accepts an array and returns an array of element combinations
 * @param list {*[]} the array from which we'll generate the combos
 */
export function getCombinations<T>(list: T[]): T[][] {
  const set = [] as any[]
  const listSize = list.length
  const combinationsCount = 1 << listSize
  let combination

  for (let i = 1; i < combinationsCount; i++) {
    combination = [] as any[]
    for (let j = 0; j < listSize; j++) {
      if (i & (1 << j)) {
        combination.push(list[j])
      }
    }
    set.push(combination)
  }
  return set
}

/**
 * Accepts two arrays and returns true if they are equal.
 * They can be equal if they have the same items by deep
 * compare and without taking into account their order
 * @param listA {*[]}
 * @param listB {*[]}
 */
export function getArraysAreEqual(listA: any[], listB: any[]): boolean {
  if (listA?.length !== listB?.length) return false
  const diff1 = differenceWith(listA, listB, isEqual)
  const diff2 = differenceWith(listB, listA, isEqual)
  return !diff1.length && !diff2.length
}
