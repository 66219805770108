/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Loggable from "./Loggable"
import invariant from "../utils/invariant"
import type ITracker from "./Tracker/ITracker"

export default abstract class Trackable extends Loggable {
  static _tracker: ITracker
  _tracker?: ITracker

  /** Static Methods */

  static setTracker(tracker: ITracker): void {
    invariant(tracker, `Trackable needs a static _tracker property. [${tracker}]`)
    this._tracker = tracker
  }

  /** Static Getters / Setters */

  static get tracker(): ITracker {
    invariant(this._tracker, `Trackable needs a static _tracker property. [${this._tracker}]`)
    return this._tracker
  }

  setTracker(tracker: ITracker): void {
    invariant(tracker, `Trackable needs a static _tracker property. [${tracker}]`)
    invariant(
      process.env.NODE_ENV === "test",
      "non-static setTracker of Trackable is only for testing"
    )
    this._tracker = tracker
  }

  /** Methods */

  identify(id: string): void {
    try {
      this.tracker.identify(id)
    } catch (e) {
      this.logException(e, "identify")
    }
  }

  register(data: any): void {
    try {
      this.tracker.register(data)
    } catch (e) {
      this.logException(e, "register")
    }
  }

  unregister(data?: any): void {
    try {
      this.tracker.unregister(data)
    } catch (e) {
      this.logException(e, "unregister")
    }
  }

  timeEvent(event?: string): void {
    try {
      this.tracker.timeEvent(event)
    } catch (e) {
      this.logException(e, "timeEvent")
    }
  }

  setPeople(data?: any): void {
    try {
      this.tracker.setPeople(data)
    } catch (e) {
      this.logException(e, "setPeople")
    }
  }

  incrementPeople(event?: string): void {
    try {
      this.tracker.incrementPeople(event)
    } catch (e) {
      this.logException(e, "incrementPeople")
    }
  }

  track(event?: string, data?: any): void {
    try {
      this.tracker.track(event, data)
    } catch (e) {
      this.logException(e, "track")
    }
  }

  /** Getters / Setters */

  get tracker(): ITracker {
    if (this._tracker) return this._tracker
    return (this.constructor as any).tracker
  }
}
