export function formatPhoneNumber(phoneNumberString?: string | number): string | undefined {
  if (phoneNumberString != null) {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "")
    const match = cleaned.match(/^(0)?(\d{4})(\d{6})$/)
    if (match) {
      const intlCode = "+44 "
      return [intlCode, match[2], " ", match[3]].join("")
    }
  }
}

export function removeCountryCodeFromPhoneNumber(
  phoneNumber: string | undefined
): string | undefined {
  // if undefined return undefined
  if (!phoneNumber) {
    return
  }
  const ukCountryCode = "+44"
  const phoneNumberString = phoneNumber + ""
  if (phoneNumberString.includes(ukCountryCode)) {
    const cleaned = ("" + phoneNumberString)
      .replace(ukCountryCode, "")
      .replace(/[^a-zA-Z0-9]/g, "")
      .trim()
    if (cleaned.length === 10) {
      return `0${cleaned}`
    }
    return cleaned
  }
  // trim and remove special characters and spaces
  return phoneNumber.trim().replace(/[^a-zA-Z0-9]/g, "")
}
