/* eslint-disable @typescript-eslint/no-empty-function */
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../../DialogueIDs"
import { step } from "../../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import {
  IDefaultChatFlowMessagesCollectLongTermMedicalConditionDetails,
  IDefaultChatFlowSettingsCollectLongTermMedicalConditionDetails,
  ISelectableExtended
} from "@limbic/types"
import { Category } from "@limbic/crisis-detection"
import { joinWithOr } from "../../../../utils/array"
import BaseScript, { BaseScriptState } from "../../../BaseScript"

const OTHER_IDENTIFIER = "NOT_LISTED"
const OTHER_OPTION = { body: "Other", value: OTHER_IDENTIFIER }
const NONE_IDENTIFIER = "NONE"

export interface ICollectLongTermMedicalConditionSettings
  extends IDefaultChatFlowSettingsCollectLongTermMedicalConditionDetails {
  messages?: IDefaultChatFlowMessagesCollectLongTermMedicalConditionDetails
}

interface State extends BaseScriptState {
  longTermConditionOther?: string
}

export type CollectLongTermMedicalConditionDetailsScriptState = State

export class CollectLongTermMedicalConditionDetailsScript extends BaseScript<State> {
  readonly name: string = "CollectLongTermMedicalConditionDetailsScript"
  protected choicesMap: ISelectableExtended[] | undefined
  protected messages: IDefaultChatFlowMessagesCollectLongTermMedicalConditionDetails
  protected includeOther?: boolean
  protected shouldAskOtherDetails?: boolean
  protected shouldAskDoesLTCAffectsMood?: boolean
  protected shouldAskHowMuchLTCAffectsMood?: boolean
  protected shouldAskHowWellYouManageYourLTC?: boolean
  protected noneOption: ISelectableExtended

  constructor(settings: ICollectLongTermMedicalConditionSettings | undefined = {}) {
    super()
    this.choicesMap = settings?.choicesMap ?? []
    this.messages = settings?.messages ?? {}
    this.includeOther = settings?.includeOther ?? true
    this.shouldAskOtherDetails = settings?.shouldAskOtherDetails ?? true
    this.shouldAskDoesLTCAffectsMood = settings?.shouldAskDoesLTCAffectsMood ?? true
    this.shouldAskHowMuchLTCAffectsMood = settings?.shouldAskHowMuchLTCAffectsMood ?? true
    this.shouldAskHowWellYouManageYourLTC = settings?.shouldAskHowWellYouManageYourLTC ?? true
    this.noneOption = {
      body: this.t(settings?.noneLabel ?? "I don't"),
      value: NONE_IDENTIFIER,
      backgroundColor: "#EC9CC8",
      selectIndividually: true
    }
  }

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askLongTermMedicalCondition }
  }

  @step.logState
  askLongTermMedicalCondition(d: IStepData<State>): IStepResult {
    const medicalConditions = this.choicesMap ?? []
    if (!medicalConditions?.length) {
      this.logBreadcrumb("MEDICAL CONDITIONS NOT FOUND", d.state, { medicalConditions })
      this.logMessage("MEDICAL CONDITIONS NOT FOUND")
      return { nextStep: this.end }
    }
    const choices = [this.noneOption, ...medicalConditions]

    if (this.includeOther) choices.push({ ...OTHER_OPTION, body: this.t(OTHER_OPTION.body) })

    return {
      body: this.t(
        this.messages.askLongTermMedicalCondition ?? "Do you have a long term medical condition?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askLongTermMedicalCondition"),
        trackResponse: true,
        type: "inlinePickerMultiSelect",
        choices,
        dataPointsName: "askLongTermMedicalCondition"
      },
      nextStep: this.handleLongTermMedicalCondition
    }
  }

  @step.logStateAndResponse
  async handleLongTermMedicalCondition(d: IStepData<State, string[]>): Promise<IStepResult> {
    const conditions = this.choicesMap?.map(o => o.value) ?? []
    const isInvalid = d.response.find(
      r => ![NONE_IDENTIFIER, OTHER_IDENTIFIER, ...conditions].includes(r)
    )
    if (isInvalid) {
      return {
        body: this.t(
          "Sorry I can't recognise this medical condition. Let's try again",
          this.getContext(d.state)
        ),
        nextStep: this.askLongTermMedicalCondition
      }
    }

    const ltc = d.response?.filter(d => d !== NONE_IDENTIFIER)
    d.state.longTermMedicalCondition = ltc
    this.setPeople({ ltc: ltc })

    // "No" is selected individually, so you only need to check
    // the first item of the array if you want to see whether the
    // user said they don't have any LTC
    const hasLTC = ltc?.length !== 0
    const hasOther = !!d.response?.find(ltc => ltc === OTHER_IDENTIFIER)

    if (hasOther && this.shouldAskOtherDetails) {
      return { nextStep: this.askLongTermMedicalConditionOther }
    }

    if (hasLTC)
      return {
        nextStep: this.shouldAskDoesLTCAffectsMood
          ? this.askDoesLTCAffectMood
          : this.shouldAskHowMuchLTCAffectsMood
            ? this.askHowMuchLTCAffectsMood
            : this.shouldAskHowWellYouManageYourLTC
              ? this.askHowWellYouManageYourLTC
              : this.end
      }
    return { nextStep: this.end }
  }

  @step
  askDoesLTCAffectMood(d: IStepData<State>): IStepResult {
    const LTCs = d.state.longTermMedicalCondition
    const formattedLTCs = LTCs?.map(
      ltc => this.choicesMap?.find(option => option.value === ltc)?.body ?? ltc
    )

    const ltc = joinWithOr(formattedLTCs)
    return {
      body: this.t("Does your {ltc} impact your mood?", { ...this.getContext(d.state), ltc }),
      prompt: {
        id: this.getPromptId("askDoesLTCAffectMood"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askDoesLTCAffectMood"
      },
      nextStep: this.handleDoesLTCAffectMood
    }
  }

  @step
  async handleDoesLTCAffectMood(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.ltcAffectsMood = d.response
    this.setPeople({ ltcAffectsMood: d.response })

    return {
      nextStep:
        d.response && this.shouldAskHowMuchLTCAffectsMood
          ? this.askHowMuchLTCAffectsMood
          : d.response && this.shouldAskHowWellYouManageYourLTC
            ? this.askHowWellYouManageYourLTC
            : this.end
    }
  }

  @step
  askHowMuchLTCAffectsMood(d: IStepData<State>): IStepResult {
    const ltcCount = d.state.longTermMedicalCondition?.length ?? 0
    const totalCount = ltcCount + (d.state.longTermConditionOther?.length ? 1 : 0)
    return {
      body: this.t(
        totalCount > 1
          ? "How much do these conditions impact your mood?"
          : "How much does this condition impact your mood?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askHowMuchLTCAffectsMood"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("A little"), value: "little" },
          { body: this.t("Somewhat"), value: "somewhat" },
          { body: this.t("Very much"), value: "very" }
        ],
        dataPointsName: "askHowMuchLTCAffectsMood"
      },
      nextStep: this.handleHowMuchLTCAffectsMood
    }
  }

  @step
  async handleHowMuchLTCAffectsMood(
    d: IStepData<State, "little" | "somewhat" | "very">
  ): Promise<IStepResult> {
    d.state.ltcMoodImpact = d.response
    this.setPeople({ ltcMoodImpact: d.response })

    return {
      nextStep:
        d.response !== "little" && this.shouldAskHowWellYouManageYourLTC
          ? this.askHowWellYouManageYourLTC
          : this.end
    }
  }

  @step
  askHowWellYouManageYourLTC(d: IStepData<State>): IStepResult {
    const ltcCount = d.state.longTermMedicalCondition?.length ?? 0
    const totalCount = ltcCount + (d.state.longTermConditionOther?.length ? 1 : 0)
    return {
      body: this.t(
        totalCount > 1
          ? "And how well do you think you manage these conditions?"
          : "And how well do you think you manage this condition?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askHowWellYouManageYourLTC"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Not very well"), value: "little" },
          { body: this.t("Fairly well"), value: "fairly" },
          { body: this.t("Very well"), value: "very" }
        ],
        dataPointsName: "askHowWellYouManageYourLTC"
      },
      nextStep: this.handleHowWellYouManageYourLTC
    }
  }

  @step
  async handleHowWellYouManageYourLTC(
    d: IStepData<State, "little" | "fairly" | "very">
  ): Promise<IStepResult> {
    const ltcManagement =
      {
        little: "Not very well",
        fairly: "Fairly well",
        very: "Very well"
      }[d.response] || d.response
    d.state.ltcManagement = d.response
    this.setPeople({ ltcManagement })

    return { nextStep: this.end }
  }

  @step.logState
  askLongTermMedicalConditionOther(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messages?.askLongTermMedicalConditionOther ?? [
          'I see you selected "Other"',
          "Please specify"
        ],
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askLongTermMedicalConditionOther"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handleLongTermMedicalConditionOtherWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.longTermConditionOther = d.response
  })
  @step.checkInputForCrisis({
    getNextStep: (s: CollectLongTermMedicalConditionDetailsScript) => s.askDoesOtherLTCAffectMood,
    ignoredCategories: [Category.Medication]
  })
  handleLongTermMedicalConditionOtherWithCrisis(_d: IStepData<State, string>): IStepResult {
    return {
      nextStep: this.shouldAskDoesLTCAffectsMood
        ? this.askDoesOtherLTCAffectMood
        : this.shouldAskHowMuchLTCAffectsMood
          ? this.askHowMuchLTCAffectsMood
          : this.shouldAskHowWellYouManageYourLTC
            ? this.askHowWellYouManageYourLTC
            : this.end
    }
  }

  @step
  askDoesOtherLTCAffectMood(d: IStepData<State>): IStepResult {
    const ltcCount = d.state.longTermMedicalCondition?.length ?? 0
    const totalCount = ltcCount + (d.state.longTermConditionOther?.length ? 1 : 0)
    return {
      body: this.t(
        totalCount > 1
          ? "Do these conditions impact your mood?"
          : "Does this condition impact your mood?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askDoesOtherLTCAffectMood"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askDoesOtherLTCAffectMood"
      },
      nextStep: this.handleDoesOtherLTCAffectMood
    }
  }

  @step
  async handleDoesOtherLTCAffectMood(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.ltcAffectsMood = d.response
    this.setPeople({ ltcAffectsMood: d.response })
    return {
      nextStep:
        d.response && this.shouldAskHowMuchLTCAffectsMood
          ? this.askHowMuchLTCAffectsMood
          : d.response && this.shouldAskHowWellYouManageYourLTC
            ? this.askHowWellYouManageYourLTC
            : this.end
    }
  }
}

/* istanbul ignore next */
export default class CollectLongTermMedicalConditionDetailsDialogue extends AdHocDialogue<
  State,
  CollectLongTermMedicalConditionDetailsScript
> {
  static id = DialogueIDs.CollectLongTermMedicalConditionDetails
  readonly name: string = "CollectLongTermMedicalConditionDetailsDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: ICollectLongTermMedicalConditionSettings
  ) {
    super(
      CollectLongTermMedicalConditionDetailsDialogue.id,
      new CollectLongTermMedicalConditionDetailsScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
