import React from "react"
import Timer from "tiny-timer"
import classNames from "classnames"
import { observer } from "mobx-react"
import { IDataPoints } from "@limbic/types"
import MUIInput from "@material-ui/core/Input"
import { Send } from "@material-ui/icons"
import { withStyles } from "@material-ui/core/styles"
import { DataPointsType } from "../../../models/DataPoints"
import { useApplicationStore, useConfigStore } from "../../contexts/RootStoreContext"
import "./TextInput.scss"

const timer = new Timer({ stopwatch: true })

const getIsInputValid = (validation: RegExp[], input?: string): boolean => {
  if (!input?.length) return true
  if (!validation?.length) return true
  return !!validation.find(regex => new RegExp(regex, "g").test(input))
}

interface Props {
  autoFocus?: boolean
  disabled?: boolean
  hasError?: boolean
  value?: string
  forceValue?: boolean
  cancelIsEmptySubmit?: boolean
  validation?: Array<RegExp | string>
  validationExplainer?: string[]
  placeholder?: string
  cancelLabel?: string
  trimAllSpacesOnSubmit?: boolean
  trimAllSpacesOnValidation?: boolean
  clearOnSubmit?: boolean
  borderless?: boolean
  multiline?: boolean
  autoCorrect?: string
  autoCapitalize?: string
  autoComplete?: string
  dataPointsName?: string
  showOnSubmitButton?: boolean
  skipValue?: string
  dataTestId?: string
  onSubmit?: (text?: string, dataPoints?: IDataPoints) => void
  onCancel?: () => void
  onClick?: () => void
  onChangeText?: (text?: string) => void
}

function TextInput(props: Props): JSX.Element {
  const {
    placeholder = "Write your message here",
    forceValue = false,
    clearOnSubmit = true,
    multiline = true,
    autoFocus = true,
    disabled,
    hasError,
    value: initialValue,
    cancelIsEmptySubmit,
    validationExplainer,
    trimAllSpacesOnSubmit,
    trimAllSpacesOnValidation,
    cancelLabel,
    borderless,
    autoCorrect,
    autoCapitalize,
    autoComplete,
    showOnSubmitButton = true,
    skipValue,
    dataTestId,
    onSubmit,
    onCancel,
    onClick,
    onChangeText
  } = props
  const config = useConfigStore()
  const app = useApplicationStore()
  const inputRef = React.useRef<any>(null)
  const [hasUserStartedTyping, setHasUserStartedTyping] = React.useState<boolean>(false)
  const [startTypingTime, setStartTypingTime] = React.useState<number>(0)
  const [endTypingTime, setEndTypingTime] = React.useState<number>(0)
  const [numberOfDeleteAndBackspacePresses, setNumberOfDeleteAndBackspacePresses] =
    React.useState<number>(0)
  const [text, setText] = React.useState<string | undefined>(initialValue ?? "")
  const [error, setError] = React.useState<string[] | undefined>()
  const [skipped, setSkipped] = React.useState<boolean>(false)
  const submitDisabled = forceValue && !text
  const showCancelButton = !!onCancel || (cancelIsEmptySubmit && !text)
  const trimmedText = text?.trim()

  // 📎 check the polyfills.ts file for what the fromJSON does
  const validation: RegExp[] | undefined = props.validation?.map(r => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return typeof r === "string" ? RegExp.fromJSON(r) : r
  })

  const getDataPoints = React.useCallback(
    value => {
      if (!props.dataPointsName) return undefined

      const timerHasStopped = timer.status === "stopped"
      return {
        q: props.dataPointsName,
        type: DataPointsType.TEXT,
        startTypingTime: timerHasStopped ? 600 : startTypingTime / 1000,
        typingDuration: timerHasStopped ? 600 : (endTypingTime - startTypingTime) / 1000,
        numberOfDeleteAndBackspacePresses,
        submitTimeFromFirstViewing: timerHasStopped ? 600 : timer.time / 1000,
        value
      }
    },
    [endTypingTime, startTypingTime, numberOfDeleteAndBackspacePresses, props.dataPointsName]
  )

  const submit = e => {
    e.preventDefault()
    e.stopPropagation()

    if (forceValue && !trimmedText) {
      setError(app.t(["Input can't be empty"]))
      return
    }

    if (validation?.length) {
      const validationInput = trimAllSpacesOnValidation
        ? trimmedText?.replace(/\s+/g, "")
        : trimmedText
      const isValid = getIsInputValid(validation, validationInput)
      if (!isValid) {
        const errorStrings: string[] = []
        !errorStrings.length && errorStrings.push(...(validationExplainer || []))
        !errorStrings.length && errorStrings.push(app.t("This is not a valid input"))
        setError(errorStrings)
        return
      }
    }

    if (!submitDisabled && onSubmit) {
      const submittable = trimAllSpacesOnSubmit ? trimmedText?.replace(/\s+/g, "") : trimmedText

      const dataPoints: IDataPoints | undefined = getDataPoints(submittable)
      timer.stop()
      onSubmit(submittable, dataPoints)
      clearOnSubmit && setText("")
    }
  }
  const onKeyDown = event => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault()
      submit(event)
    }
    if (event.keyCode === 8 || event.keyCode === 46) {
      setNumberOfDeleteAndBackspacePresses(numberOfDeleteAndBackspacePresses + 1)
    }
  }
  const cancel = () => {
    if (!skipped && cancelIsEmptySubmit && onSubmit) {
      onSubmit(skipValue || undefined)
      setSkipped(true)
    }
    if (onCancel) {
      onCancel()
    }
  }
  const onChange = event => {
    const newText = event.target?.value?.replace?.("↵", "\n") || ""
    if (!text && !hasUserStartedTyping) {
      setHasUserStartedTyping(true)
      setStartTypingTime(timer.time)
    }
    setEndTypingTime(timer.time)
    setText(newText)
    setError(undefined)
    onChangeText?.(newText)
  }

  React.useEffect(() => {
    // a timer running time is required - set to 10 minutes after
    // Max realised that for some questions (specifically "askDayToDayDifficulties")
    // the user goes over the originally set 90 seconds
    timer.start(600000)
    setTimeout(() => inputRef.current?.focus(), 240)
    return () => {
      timer.stop()
    }
  }, [])

  const containerCSS = classNames("lb-user-input-text-container", { borderless, disabled })
  const contentCSS = classNames("lb-user-input-text-content", { error: error || hasError })
  const sendButtonCSS = classNames({ disabled: submitDisabled })
  const textCount =
    config.freetextLimit && text?.length && config.freetextLimit - text.length <= 20 //
      ? `${text?.length}/${config.freetextLimit}`
      : undefined
  return (
    <>
      {!!error?.length && (
        <div className="lb-user-input-text-error-container">
          {error?.map((e, idx) => (
            <span key={idx} className="lb-user-input-text-error">
              {e}
            </span>
          ))}
        </div>
      )}
      <div className={containerCSS} onSubmit={submit} data-testid={dataTestId || "text-input"}>
        <div className={contentCSS}>
          <div className="lb-user-input-text-input-container">
            <Input
              classes={{ input: "lb-user-input-override" }}
              ref={inputRef}
              autoFocus={autoFocus}
              disabled={disabled}
              fullWidth
              type={"text"}
              value={text}
              placeholder={placeholder}
              disableUnderline
              multiline={multiline}
              autoCorrect={autoCorrect}
              autoCapitalize={autoCapitalize}
              autoComplete={autoComplete}
              onChange={onChange}
              onKeyDown={onKeyDown}
              onClick={onClick}
              inputProps={{ maxLength: config.freetextLimit || undefined }}
            />
          </div>
          {textCount && (
            <span
              style={{
                color: text?.length === config.freetextLimit ? "red" : undefined,
                marginLeft: "4px",
                marginRight: "4px"
              }}>
              {textCount}
            </span>
          )}
          {showCancelButton && (
            <div className={"lb-user-input-text-cancel-button"} onClick={cancel}>
              <span className={"lb-user-input-text-cancel-button-text"}>
                {cancelLabel || app.t("Skip")}
              </span>
            </div>
          )}
          {onSubmit && showOnSubmitButton && (
            <button
              aria-label={app.t("Submit Text")}
              aria-describedby={app.t("Submit Text")}
              name={app.t("Submit Text")}
              className="lb-submit-text-button"
              onClick={submit}>
              <SendIcon className={sendButtonCSS} />
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export default observer(TextInput)

const Input = withStyles({
  root: {
    flex: 1,
    paddingLeft: 12,
    paddingRight: 12,
    cursor: "text"
  },
  input: {
    display: "flex",
    alignItems: "flex-end",
    flex: 1,
    fontFamily: "Aeroport, Roboto, sans-serif",
    border: "none",
    cursor: "text",
    "&:disabled": {
      color: "black",
      cursor: "pointer",
      pointerEvents: "none"
    },
    "&::placeholder": {
      textAlign: "left"
    }
  }
})(MUIInput)

const SendIcon = withStyles({
  root: {
    "-webkit-box-sizing": "content-box",
    "-moz-box-sizing": "content-box",
    "-o-box-sizing": "content-box",
    boxSizing: "content-box",
    width: "1.3em",
    height: "1.3em",
    alignSelf: "flex-end",
    padding: "8px",
    marginBottom: 3,
    borderRadius: "50%",
    color: "#F5F5F8",
    backgroundColor: "#6A6983",
    cursor: "default",
    "&.disabled": {
      opacity: 0.5,
      cursor: "auto"
    },
    "&:hover": {
      opacity: 0.8,
      transition: "120ms"
    },
    "&:active": {
      opacity: "0.5",
      transition: "120ms"
    }
  }
})(Send)
