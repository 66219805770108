import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { PHQ9Script } from "./PHQ9"
import type { PHQ9ScriptState } from "./PHQ9"
import type { IStepResult } from "../../../backend/chatbot/models/IStep"

type State = PHQ9ScriptState

class PHQ9VHGScript extends PHQ9Script {
  readonly name: string = "PHQ9VHGScript"

  /** Generic Handlers */

  async onHandleRiskQ9Score(state: State): Promise<IStepResult | void> {
    this.rootStore.applicationStore.setCurrentProgress(0.88)
    return { nextStep: this.goToRiskPathway }
  }
}

export default class PHQ9VHGDialogue extends Dialogue<State> {
  static id = DialogueIDs.PHQ9VHG
  readonly name: string = "survey:PHQ9_VHG Dialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PHQ9VHGDialogue.id, new PHQ9VHGScript(), state, snapshot)
  }
}
