import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import GoodbyeScript from "./GoodbyeScript"
import type { GoodbyeScriptState } from "./GoodbyeScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult, StepResultBodyType } from "../../../backend/chatbot/models/IStep"

type State = GoodbyeScriptState

export type GoodbyeLWCScriptState = State
export class GoodbyeLWCScript extends GoodbyeScript {
  readonly name: string = "GoodbyeLWCScript"

  /** Script Steps */

  @step
  async sayGoodBye(d: IStepData<State>): Promise<IStepResult> {
    void this.referralStore.updateReferral({
      isHelpful: d.state.isHelpful,
      improvementSuggestion: d.state.improvementSuggestion
    })
    this.referralStore.stopPinging()
    const name = this.getName(d.state)

    return {
      body: [
        "Well done for taking this important step towards better mental health",
        `Goodbye ${name} 👋`
      ],
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onHandleRecapMessage(
    _state: State,
    body: StepResultBodyType[],
    recapMessage: string | void
  ): Promise<IStepResult | void> {
    const isCrisis = this.clinicalStore.isCrisis
    if (!body.length) {
      return { nextStep: isCrisis ? this.end : this.askFeedback }
    }

    const choices: any[] = [{ body: "Okay" }, recapMessage && { body: "I understand" }] //
      .filter(Boolean)

    return {
      body,
      prompt: {
        id: this.getPromptId("recap"),
        trackResponse: true,
        type: "inlinePicker",
        choices
      },
      nextStep: isCrisis ? this.end : this.askFeedback
    }
  }

  async getAdminWillCallMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted && !this.clinicalStore.isRisk) {
      return "Someone from our team will be in touch in the next few weeks to discuss next steps"
    }
  }

  async getHighRiskContactMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted && this.clinicalStore.isHighRisk) {
      return (
        "Because you identified as potentially experiencing high levels of distress, or you are potentially at risk, someone from our clinical team will look over your referral within 1 working day and will be in touch if they have any concerns\n" +
        "- If you are under 25 and live in Birmingham, please contact the Forward Thinking Birmingham Crisis Team at 0300 300 0099.\n" +
        "- If you are under 19 and live in Solihull, please contact Solar's Crisis Team at 0121 301 2750.\n" +
        "- If you are over 18, please call the Birmingham Mind Urgent Mental Health Helpline at 0121 262 3555 (freephone - 0800 915 9292).\n" +
        "- You can also contact the Samaritans on 116123 to talk through how you are feeling"
      )
    }
  }

  async getModerateRiskContactMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted && this.clinicalStore.isModerateRisk) {
      return (
        "Because you identified as potentially experiencing high levels of distress, or you are potentially at risk, someone from our clinical team will look over your referral within 1 working day and will be in touch if they have any concerns\n" +
        "- If you are under 25 and live in Birmingham, please contact the Forward Thinking Birmingham Crisis Team at 0300 300 0099.\n" +
        "- If you are under 19 and live in Solihull, please contact Solar's Crisis Team at 0121 301 2750.\n" +
        "- If you are over 18, please call the Birmingham Mind Urgent Mental Health Helpline at 0121 262 3555 (freephone - 0800 915 9292).\n" +
        "- You can also contact the Samaritans on 116123 to talk through how you are feeling"
      )
    }
  }

  getCustomReferralType(state: State): string | undefined {
    if (state.requiresInterpreter) return "Extended Assessment"
  }
}

/* istanbul ignore next */
export default class GoodbyeLWCDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeLWC
  readonly name: string = "GoodbyeLWCDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeLWCDialogue.id, new GoodbyeLWCScript(), state, snapshot)
  }
}
