import { z, ZodSchema } from "zod"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import type { EligibilityCheckIAPTState } from "./EligibilityCheckIAPTDialogue"
import {
  EligibilityCheckIAPTScript,
  EligibilityCheckIAPTStateSchema
} from "./EligibilityCheckIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { checkEligibilityVitality } from "../../../backend/api/limbic/vitalityEligibility"
import invariant from "../../../utils/invariant"
import { EligibilityReason, VitalityEligibilityStatus } from "../../../models/IVitalityEligibility"
import Logger from "../../../utils/Logger"
import { TrackingEvents } from "../../../models/Constants"
import { IneligibilityReason } from "@limbic/types"

interface State extends EligibilityCheckIAPTState {
  eligibilityReason?: EligibilityReason
  retryRequest?: number
  howDistressed?: number
}

export type EligibilityCheckVitalityScriptState = State

export const EligibilityCheckVitalityScriptStateSchema = EligibilityCheckIAPTStateSchema.extend({
  eligibilityReason: z.nativeEnum(EligibilityReason).optional(),
  retryRequest: z.number().optional(),
  howDistressed: z.number().optional()
})

export class EligibilityCheckVitalityScript extends EligibilityCheckIAPTScript {
  readonly name: string = "EligibilityCheckVitalityScript"

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    this.timeEvent(this.name)
    return { nextStep: this.checkVitalityEligibility }
  }

  @step
  async checkVitalityEligibility(d: IStepData<State>): Promise<IStepResult> {
    d.state.retryRequest ??= 0
    try {
      const entityId = this.referralStore.getCustomField("entityId")
      const isEligible = this.referralStore.getCustomField("isEligible")
      invariant(entityId, "entityId not provided")

      if (!isEligible) {
        // if isEligible is undefined we don't care - they asked to
        // signpost them just like the ineligible ones
        this.setEligibility(d.state, false)
        return { nextStep: this.handleEligibilityCheck }
      }

      const [eligibility, status] = await checkEligibilityVitality(Number(entityId))
      if (status === VitalityEligibilityStatus.NoInternetConnection) {
        d.state.retryRequest = 0
        return { nextStep: this.sayNoInternetConnection }
      }

      if (status === VitalityEligibilityStatus.RequestFailed && d.state.retryRequest < 3) {
        this.track(TrackingEvents.ASK_RETRY_CONNECTION_VITALITY_ELIGIBILITY_CHECK)
        return { nextStep: this.saySomethingWentWrongWithEligibilityCheck }
      }

      if (status === VitalityEligibilityStatus.RequestFailed && d.state.retryRequest === 3) {
        this.track(TrackingEvents.ASK_RETRY_REQUEST_FAILED_VITALITY_ELIGIBILITY_CHECK)
        return { nextStep: this.sayThereIsSomethingWrong }
      }

      const withinQuota = !!eligibility?.eligible
      if (!withinQuota) d.state.eligibilityReason = EligibilityReason.QuotaExceeded
      this.setEligibility(d.state, isEligible && withinQuota)
      return { nextStep: this.handleEligibilityCheck }
    } catch (error) {
      Logger.getInstance().exception(error, "entityId does not exist")
      this.track(TrackingEvents.MEMBER_ID_NOT_PROVIDED)
      return { nextStep: this.somethingWentWrongWithMemberID }
    }
  }

  @step.logState
  handleEligibilityCheck(d: IStepData<State>): IStepResult {
    if (d.state.isEligible) {
      return { nextStep: this.end }
    }
    this.logBreadcrumb("handleEligibilityCheck", { ...d.state })
    this.logMessage("handleEligibilityCheck")
    if (d.state.eligibilityReason === EligibilityReason.QuotaExceeded) {
      this.trackUserAsIneligible(d.state, IneligibilityReason.QUOTA_EXCEEDED)
      return { nextStep: this.sayQuotaExceeded }
    }
    this.trackUserAsIneligible(d.state, IneligibilityReason.NO_ACCESS_TO_SERVICE)
    return { nextStep: this.sayYouAreNotEligible }
  }

  @step.logState
  sayYouAreNotEligible(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const staffMember = !!this.referralStore.getCustomField("staffMember")
    const phoneNumber = staffMember ? "0345 6060166" : "0345 602 3523"
    const email = staffMember ? "staffclaims@vitality.co.uk" : "specialised.care@vitality.co.uk"
    return {
      body: this.t(
        [
          "Hmmm",
          "I'm really sorry, {name}, but it doesn't look like you have access to this service right now",
          "You’ll need to speak to Vitality to discuss accessing mental health services",
          "Please contact their customer support team on {phoneNumber}",
          "Or you can email them at {email}"
        ],
        { name, phoneNumber, email }
      ),
      prompt: {
        id: this.getPromptId("sayYouAreNotEligible"),
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }]
      },
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  sayQuotaExceeded(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t([
        `Okay ${name} it looks like you have access to mental health services with Vitality`,
        "However, I work best when I can connect you with a therapist right away",
        "I've just checked and there are no therapists available at IPRS Health",
        "Sorry about that"
      ]),
      prompt: {
        id: this.getPromptId("sayQuotaExceeded"),
        type: "inlinePicker",
        choices: [
          {
            body: this.t("Take me back to Vitality 👋"),
            onPress: () => {
              const a = document.createElement("a")
              a.setAttribute("href", "https://member.vitality.co.uk")
              a.setAttribute("target", "_blank")
              a.click()
            }
          }
        ]
      },
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  sayNoInternetConnection(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Hmmm... it seems that you are not connected to the internet",
        "Please try again"
      ]),
      prompt: {
        id: this.getPromptId("sayNoInternetConnection"),
        type: "inlinePicker",
        choices: [{ body: this.t("Try again") }]
      },
      nextStep: this.checkVitalityEligibility
    }
  }

  @step.logState
  saySomethingWentWrongWithEligibilityCheck(d: IStepData<State>): IStepResult {
    d.state.retryRequest = d.state.retryRequest! + 1
    return {
      body: this.t([
        "Hmmm, it looks like something went wrong with the eligibility check",
        "Please try again"
      ]),
      prompt: {
        id: this.getPromptId("saySomethingWentWrongWithEligibilityCheck"),
        type: "inlinePicker",
        choices: [{ body: this.t("Try again") }]
      },
      nextStep: this.checkVitalityEligibility
    }
  }

  @step.logState
  somethingWentWrongWithMemberID(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const staffMember = !!this.referralStore.getCustomField("staffMember")
    const phoneNumber = staffMember ? "0345 6060166" : "0345 602 3523"
    const email = staffMember ? "staffclaims@vitality.co.uk" : "specialised.care@vitality.co.uk"
    return {
      body: this.t(
        [
          "Sorry {name}, something seems to have gone wrong while looking up your coverage",
          "You’ll need to speak to Vitality to discuss accessing mental health services",
          "Please contact their customer support team on {phoneNumber}",
          "Or you can email them at {email}"
        ],
        { name, phoneNumber, email }
      ),
      prompt: {
        id: this.getPromptId("somethingWentWrongWithMemberID"),
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }]
      },
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  sayThereIsSomethingWrong(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const staffMember = !!this.referralStore.getCustomField("staffMember")
    const phoneNumber = staffMember ? "0345 6060166" : "0345 602 3523"
    const email = staffMember ? "staffclaims@vitality.co.uk" : "specialised.care@vitality.co.uk"
    return {
      body: this.t(
        [
          "Sorry {name}, it looks like there's something wrong",
          "You’ll need to speak to Vitality to discuss accessing mental health services",
          "Please contact their customer support team on {phoneNumber}",
          "Or you can email them at {email}"
        ],
        { name, phoneNumber, email }
      ),
      prompt: {
        id: this.getPromptId("sayThereIsSomethingWrong"),
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }]
      },
      nextStep: this.goToGoodbye
    }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return EligibilityCheckVitalityScriptStateSchema
  }
}

/* istanbul ignore next */
export default class EligibilityCheckVitalityDialogue extends Dialogue<State> {
  static id = DialogueIDs.EligibilityCheckVitality
  readonly name: string = "EligibilityCheckVitalityDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      EligibilityCheckVitalityDialogue.id,
      new EligibilityCheckVitalityScript(),
      state,
      snapshot
    )
  }
}
