import { z, ZodSchema } from "zod"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../DialogueIDs"
import BaseScript, { BaseScriptState, BaseScriptStateSchema } from "../../BaseScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { IStep } from "../../../backend/chatbot/models/IStep"

interface State extends BaseScriptState {
  assessmentPreference?: "telephone" | "digital"
}

export type AssessmentPitchVitalityScriptState = State

export const AssessmentPitchVitalityScriptStateSchema = BaseScriptStateSchema.extend({
  assessmentPreference: z.union([z.literal("telephone"), z.literal("digital")]).optional()
})

export class AssessmentPitchVitalityScript extends BaseScript<State> {
  readonly name: string = "AssessmentPitchVitalityScript"

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    if (d.state.phoneNumber) {
      return { nextStep: this.sayIntro }
    }
    return { nextStep: this.goToCollectPhoneNumber }
  }

  @step.logState
  sayIntro(d: IStepData<State>): IStepResult {
    this.updateReferralType(d.state)
    return {
      body: this.t([
        "I've been designed by therapists to give you a digital mental health assessment",
        "I'll ask you a few questions to measure symptoms of common mental illness",
        "I'll then share the results with you and help you find the best support options available"
      ]),
      prompt: {
        id: this.getPromptId("sayIntro"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Sounds good 👍"), value: true },
          { body: this.t("Okay"), value: true },
          { body: this.t("I'd rather do this on a phone call"), value: false }
        ]
      },
      nextStep: this.handleIntro
    }
  }

  @step.logState
  async handleIntro(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.assessmentPreference = d.response ? "digital" : "telephone"
    return {
      body: d.response ? "Brilliant!" : "No problem",
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return AssessmentPitchVitalityScriptStateSchema
  }

  async onCollectPhoneNumberEnded(state: State): Promise<IStep | void> {
    return this.sayIntro
  }
}

/* istanbul ignore next */
export default class AssessmentPitchVitalityDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentPitchVitality
  readonly name: string = "AssessmentPitchVitalityDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentPitchVitalityDialogue.id, new AssessmentPitchVitalityScript(), state, snapshot)
  }
}
