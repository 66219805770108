import { Translations } from "./Languages"
import invariant from "../utils/invariant"
import formatUnicorn from "../utils/formatUnicorn"
import { TypedEvent } from "../backend/chatbot/TypedEvent"
import Persistable from "../models/Persistable"
import Syncable from "../models/Syncable"
import { LanguageCodes } from "@limbic/types"

export class Translator extends Persistable implements Syncable {
  static _instance: Translator

  /** Static Methods */

  static getInstance(defaultLanguage?: LanguageCodes): Translator {
    if (!this._instance) {
      this._instance = new Translator(defaultLanguage)
    }
    return this._instance
  }

  _language?: LanguageCodes
  _translations?: Translations

  readonly name = "Translator"
  readonly events = {
    languageChanged: new TypedEvent<string>(),
    translationsChanged: new TypedEvent<Translations>()
  }

  constructor(defaultLanguage: LanguageCodes = LanguageCodes.EN) {
    super()
    const savedLanguage = this.hydrate("_language")
    this.setLanguage(savedLanguage ?? defaultLanguage)
  }

  rehydrate(): void {
    const savedLanguage = this.hydrate("_language")
    this.setLanguage(savedLanguage ?? this.language)
  }

  removeEventListeners(): void {
    this.events.languageChanged.clear()
    this.events.translationsChanged.clear()
  }

  setLanguage(language = LanguageCodes.EN): Translator {
    this._language = language
    this.persist("_language", this._language)
    this.events.languageChanged.emit(this._language)
    return this
  }

  setTranslations(translations: Translations = {}): Translator {
    this._translations = translations
    this.events.translationsChanged.emit(this._translations)
    return this
  }

  // this is on purpose an arrow Fn property and not
  // a method so that `this` is always bound to the
  // translator, so we can then use this in getters to
  // make it easy to call this.t("") from translatables
  get = <T>(phrase: T, ctx?: Record<string, any>): T => {
    if (typeof phrase === "string") {
      const value = this.translations[phrase]?.[this.language] ?? phrase
      return (ctx ? formatUnicorn(value, ctx) : value) as T
    }

    if (Array.isArray(phrase) && phrase.length && phrase.map) {
      return phrase.map(p => this.get(p, ctx) as string) as T
    }

    return phrase
  }

  /** Getters / Setters */

  get language(): LanguageCodes {
    invariant(
      this._language,
      `trying to access Translator.language but none has been set yet [${this._language}]`
    )
    return this._language
  }

  get translations(): Translations {
    invariant(
      this._translations,
      `trying to access Translator.translations but none has been set yet [${this._translations}]`
    )
    return this._translations
  }
}
