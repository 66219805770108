import { ClinicalFlags, ClinicalGroups, ProblemCategories, IClinicalPath } from "@limbic/types"
import treatmentsInsight from "../treatments/insight"
import generateClinicalGroupName from "../../../../utils/generateClinicalGroupName"

// The concept is: Moderate cases of Health Anxiety and
// Panic should go to Step 1.
//
// We have severity related problem descriptors for Health
// Anxiety and Social Phobia, so we can use Health Anxiety's
// default clinical paths to output the Step 1 assessment type.
//
// However, the severity related paths are relying on the
// corresponding questionnaire to be answered, so for now
// these paths can only be used in the ADSM flows.
//
// For the scenarios where the user didn't do the ADSM flow
// for whatever reason, but still flagged for Social Phobia,
// Health Anxiety or Panic, we have to use the non-severity
// paths. For this reason, we need to define custom clinical
// paths for Health Anxiety and Panic, in order to output
// Step 1 because we need to look at the combination of
// primary problem descriptors and secondary problem descriptors
// in order to determine a moderate caseness.
//
// But also, because we are talking about non ADSM clinical
// paths here, we have to consider the following that he
// non-severity paths for Social Phobia, Health Anxiety and
// Panic, rely on the anxiety screening to be answered which
// means they can't exist without GAD

const primaryProblems: ProblemCategories[] = [
  ProblemCategories.HealthAnxiety,
  ProblemCategories.Panic
]

const secondaryCombos: ProblemCategories[][] = [
  [ProblemCategories.M_GAD],
  [ProblemCategories.M_Depression, ProblemCategories.M_GAD],
  [ProblemCategories.MS_Depression, ProblemCategories.M_GAD]
]

const flagCombos: ClinicalFlags[][] = [
  [],
  [ClinicalFlags.MS_FunctionalImpairment],
  [ClinicalFlags.S_FunctionalImpairment]
]

const paths: Omit<IClinicalPath, "id">[] = []

const clinicalGroupsMap = {
  none: {
    [ProblemCategories.HealthAnxiety]: ClinicalGroups.HealthAnxiety,
    [ProblemCategories.Panic]: ClinicalGroups.Panic
  },
  [ClinicalFlags.MS_FunctionalImpairment]: {
    [ProblemCategories.HealthAnxiety]: ClinicalGroups.HealthAnxiety_MS_FI,
    [ProblemCategories.Panic]: ClinicalGroups.Panic_MS_FI
  },
  [ClinicalFlags.S_FunctionalImpairment]: {
    [ProblemCategories.HealthAnxiety]: ClinicalGroups.HealthAnxiety_S_FI,
    [ProblemCategories.Panic]: ClinicalGroups.Panic_S_FI
  }
}

primaryProblems.forEach(primary => {
  secondaryCombos.forEach(secondaryProblems => {
    flagCombos.forEach(flags =>
      paths.push({
        matcher: { primaryProblems: [primary], secondaryProblems, flags },
        enableSecondary: true,
        clinicalGroup: clinicalGroupsMap[flags[0] ?? "none"][primary],
        suggestTreatments: true,
        defaultReferralType: `Step 1 Limbic Assessment (${generateClinicalGroupName({
          primaryProblems: [primary],
          secondaryProblems,
          flags
        })})`,
        treatments: treatmentsInsight,
        declinedTreatmentsReferralType:
          "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
      })
    )
  })
})

const insightCustomClinicalPaths: IClinicalPath[] = paths.map((path, index) => ({
  ...path,
  id: `insight-${index + 1}`
}))

export { insightCustomClinicalPaths, primaryProblems, secondaryCombos, flagCombos }
