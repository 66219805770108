import { IAPTGPMap, IAPTIDs } from "../../models/IIAPTService"

export const iaptGPsMapLWC: IAPTGPMap = {
  M81062: IAPTIDs.LWC_SERVICE_1,
  M85001: IAPTIDs.LWC_SERVICE_1,
  M85002: IAPTIDs.LWC_SERVICE_1,
  M85005: IAPTIDs.LWC_SERVICE_1,
  M85006: IAPTIDs.LWC_SERVICE_1,
  M85007: IAPTIDs.LWC_SERVICE_1,
  M85008: IAPTIDs.LWC_SERVICE_1,
  M85009: IAPTIDs.LWC_SERVICE_1,
  M85011: IAPTIDs.LWC_SERVICE_1,
  M85013: IAPTIDs.LWC_SERVICE_1,
  M85014: IAPTIDs.LWC_SERVICE_1,
  M85016: IAPTIDs.LWC_SERVICE_1,
  M85018: IAPTIDs.LWC_SERVICE_1,
  M85019: IAPTIDs.LWC_SERVICE_1,
  M85020: IAPTIDs.LWC_SERVICE_1,
  M85021: IAPTIDs.LWC_SERVICE_1,
  M85023: IAPTIDs.LWC_SERVICE_1,
  M85024: IAPTIDs.LWC_SERVICE_1,
  M85025: IAPTIDs.LWC_SERVICE_1,
  M85026: IAPTIDs.LWC_SERVICE_1,
  M85027: IAPTIDs.LWC_SERVICE_1,
  M85028: IAPTIDs.LWC_SERVICE_1,
  M85029: IAPTIDs.LWC_SERVICE_1,
  M85030: IAPTIDs.LWC_SERVICE_1,
  M85031: IAPTIDs.LWC_SERVICE_1,
  M85033: IAPTIDs.LWC_SERVICE_1,
  M85034: IAPTIDs.LWC_SERVICE_1,
  M85035: IAPTIDs.LWC_SERVICE_1,
  M85037: IAPTIDs.LWC_SERVICE_1,
  M85041: IAPTIDs.LWC_SERVICE_1,
  M85042: IAPTIDs.LWC_SERVICE_1,
  M85043: IAPTIDs.LWC_SERVICE_1,
  M85046: IAPTIDs.LWC_SERVICE_1,
  M85047: IAPTIDs.LWC_SERVICE_1,
  M85048: IAPTIDs.LWC_SERVICE_1,
  M85051: IAPTIDs.LWC_SERVICE_1,
  M85053: IAPTIDs.LWC_SERVICE_1,
  M85055: IAPTIDs.LWC_SERVICE_1,
  M85056: IAPTIDs.LWC_SERVICE_1,
  M85058: IAPTIDs.LWC_SERVICE_1,
  M85060: IAPTIDs.LWC_SERVICE_1,
  M85061: IAPTIDs.LWC_SERVICE_1,
  M85062: IAPTIDs.LWC_SERVICE_1,
  M85063: IAPTIDs.LWC_SERVICE_1,
  M85065: IAPTIDs.LWC_SERVICE_1,
  M85066: IAPTIDs.LWC_SERVICE_1,
  M85069: IAPTIDs.LWC_SERVICE_1,
  M85070: IAPTIDs.LWC_SERVICE_1,
  M85071: IAPTIDs.LWC_SERVICE_1,
  M85074: IAPTIDs.LWC_SERVICE_1,
  M85076: IAPTIDs.LWC_SERVICE_1,
  M85077: IAPTIDs.LWC_SERVICE_1,
  M85078: IAPTIDs.LWC_SERVICE_1,
  M85079: IAPTIDs.LWC_SERVICE_1,
  M85081: IAPTIDs.LWC_SERVICE_1,
  M85082: IAPTIDs.LWC_SERVICE_1,
  M85084: IAPTIDs.LWC_SERVICE_1,
  M85086: IAPTIDs.LWC_SERVICE_1,
  M85087: IAPTIDs.LWC_SERVICE_1,
  M85088: IAPTIDs.LWC_SERVICE_1,
  M85094: IAPTIDs.LWC_SERVICE_1,
  M85097: IAPTIDs.LWC_SERVICE_1,
  M85098: IAPTIDs.LWC_SERVICE_1,
  M85105: IAPTIDs.LWC_SERVICE_1,
  M85107: IAPTIDs.LWC_SERVICE_1,
  M85108: IAPTIDs.LWC_SERVICE_1,
  M85110: IAPTIDs.LWC_SERVICE_1,
  M85113: IAPTIDs.LWC_SERVICE_1,
  M85115: IAPTIDs.LWC_SERVICE_1,
  M85116: IAPTIDs.LWC_SERVICE_1,
  M85117: IAPTIDs.LWC_SERVICE_1,
  M85118: IAPTIDs.LWC_SERVICE_1,
  M85123: IAPTIDs.LWC_SERVICE_1,
  M85124: IAPTIDs.LWC_SERVICE_1,
  M85128: IAPTIDs.LWC_SERVICE_1,
  M85134: IAPTIDs.LWC_SERVICE_1,
  M85136: IAPTIDs.LWC_SERVICE_1,
  M85139: IAPTIDs.LWC_SERVICE_1,
  M85141: IAPTIDs.LWC_SERVICE_1,
  M85142: IAPTIDs.LWC_SERVICE_1,
  M85143: IAPTIDs.LWC_SERVICE_1,
  M85145: IAPTIDs.LWC_SERVICE_1,
  M85146: IAPTIDs.LWC_SERVICE_1,
  M85149: IAPTIDs.LWC_SERVICE_1,
  M85153: IAPTIDs.LWC_SERVICE_1,
  M85154: IAPTIDs.LWC_SERVICE_1,
  M85155: IAPTIDs.LWC_SERVICE_1,
  M85156: IAPTIDs.LWC_SERVICE_1,
  M85158: IAPTIDs.LWC_SERVICE_1,
  M85164: IAPTIDs.LWC_SERVICE_1,
  M85167: IAPTIDs.LWC_SERVICE_1,
  M85170: IAPTIDs.LWC_SERVICE_1,
  M85171: IAPTIDs.LWC_SERVICE_1,
  M85172: IAPTIDs.LWC_SERVICE_1,
  M85174: IAPTIDs.LWC_SERVICE_1,
  M85175: IAPTIDs.LWC_SERVICE_1,
  M85176: IAPTIDs.LWC_SERVICE_1,
  M85177: IAPTIDs.LWC_SERVICE_1,
  M85178: IAPTIDs.LWC_SERVICE_1,
  M85179: IAPTIDs.LWC_SERVICE_1,
  M85600: IAPTIDs.LWC_SERVICE_1,
  M85624: IAPTIDs.LWC_SERVICE_1,
  M85634: IAPTIDs.LWC_SERVICE_1,
  M85642: IAPTIDs.LWC_SERVICE_1,
  M85669: IAPTIDs.LWC_SERVICE_1,
  M85670: IAPTIDs.LWC_SERVICE_1,
  M85671: IAPTIDs.LWC_SERVICE_1,
  M85676: IAPTIDs.LWC_SERVICE_1,
  M85679: IAPTIDs.LWC_SERVICE_1,
  M85680: IAPTIDs.LWC_SERVICE_1,
  M85684: IAPTIDs.LWC_SERVICE_1,
  M85686: IAPTIDs.LWC_SERVICE_1,
  M85693: IAPTIDs.LWC_SERVICE_1,
  M85694: IAPTIDs.LWC_SERVICE_1,
  M85697: IAPTIDs.LWC_SERVICE_1,
  M85699: IAPTIDs.LWC_SERVICE_1,
  M85701: IAPTIDs.LWC_SERVICE_1,
  M85706: IAPTIDs.LWC_SERVICE_1,
  M85711: IAPTIDs.LWC_SERVICE_1,
  M85713: IAPTIDs.LWC_SERVICE_1,
  M85715: IAPTIDs.LWC_SERVICE_1,
  M85716: IAPTIDs.LWC_SERVICE_1,
  M85717: IAPTIDs.LWC_SERVICE_1,
  M85721: IAPTIDs.LWC_SERVICE_1,
  M85722: IAPTIDs.LWC_SERVICE_1,
  M85730: IAPTIDs.LWC_SERVICE_1,
  M85732: IAPTIDs.LWC_SERVICE_1,
  M85733: IAPTIDs.LWC_SERVICE_1,
  M85735: IAPTIDs.LWC_SERVICE_1,
  M85736: IAPTIDs.LWC_SERVICE_1,
  M85739: IAPTIDs.LWC_SERVICE_1,
  M85746: IAPTIDs.LWC_SERVICE_1,
  M85749: IAPTIDs.LWC_SERVICE_1,
  M85756: IAPTIDs.LWC_SERVICE_1,
  M85757: IAPTIDs.LWC_SERVICE_1,
  M85759: IAPTIDs.LWC_SERVICE_1,
  M85766: IAPTIDs.LWC_SERVICE_1,
  M85768: IAPTIDs.LWC_SERVICE_1,
  M85770: IAPTIDs.LWC_SERVICE_1,
  M85774: IAPTIDs.LWC_SERVICE_1,
  M85778: IAPTIDs.LWC_SERVICE_1,
  M85779: IAPTIDs.LWC_SERVICE_1,
  M85781: IAPTIDs.LWC_SERVICE_1,
  M85782: IAPTIDs.LWC_SERVICE_1,
  M85783: IAPTIDs.LWC_SERVICE_1,
  M85792: IAPTIDs.LWC_SERVICE_1,
  M85794: IAPTIDs.LWC_SERVICE_1,
  M85797: IAPTIDs.LWC_SERVICE_1,
  M85803: IAPTIDs.LWC_SERVICE_1,
  M85805: IAPTIDs.LWC_SERVICE_1,
  M85813: IAPTIDs.LWC_SERVICE_1,
  M87001: IAPTIDs.LWC_SERVICE_1,
  M87003: IAPTIDs.LWC_SERVICE_1,
  M87005: IAPTIDs.LWC_SERVICE_1,
  M87006: IAPTIDs.LWC_SERVICE_1,
  M87007: IAPTIDs.LWC_SERVICE_1,
  M87008: IAPTIDs.LWC_SERVICE_1,
  M87009: IAPTIDs.LWC_SERVICE_1,
  M87010: IAPTIDs.LWC_SERVICE_1,
  M87011: IAPTIDs.LWC_SERVICE_1,
  M87012: IAPTIDs.LWC_SERVICE_1,
  M87013: IAPTIDs.LWC_SERVICE_1,
  M87014: IAPTIDs.LWC_SERVICE_1,
  M87015: IAPTIDs.LWC_SERVICE_1,
  M87016: IAPTIDs.LWC_SERVICE_1,
  M87017: IAPTIDs.LWC_SERVICE_1,
  M87018: IAPTIDs.LWC_SERVICE_1,
  M87019: IAPTIDs.LWC_SERVICE_1,
  M87020: IAPTIDs.LWC_SERVICE_1,
  M87021: IAPTIDs.LWC_SERVICE_1,
  M87023: IAPTIDs.LWC_SERVICE_1,
  M87024: IAPTIDs.LWC_SERVICE_1,
  M87025: IAPTIDs.LWC_SERVICE_1,
  M87026: IAPTIDs.LWC_SERVICE_1,
  M87027: IAPTIDs.LWC_SERVICE_1,
  M87028: IAPTIDs.LWC_SERVICE_1,
  M87030: IAPTIDs.LWC_SERVICE_1,
  M87034: IAPTIDs.LWC_SERVICE_1,
  M87036: IAPTIDs.LWC_SERVICE_1,
  M87037: IAPTIDs.LWC_SERVICE_1,
  M87041: IAPTIDs.LWC_SERVICE_1,
  M87601: IAPTIDs.LWC_SERVICE_1,
  M87602: IAPTIDs.LWC_SERVICE_1,
  M87605: IAPTIDs.LWC_SERVICE_1,
  M87612: IAPTIDs.LWC_SERVICE_1,
  M87617: IAPTIDs.LWC_SERVICE_1,
  M87618: IAPTIDs.LWC_SERVICE_1,
  M87620: IAPTIDs.LWC_SERVICE_1,
  M87621: IAPTIDs.LWC_SERVICE_1,
  M87623: IAPTIDs.LWC_SERVICE_1,
  M87628: IAPTIDs.LWC_SERVICE_1,
  M87632: IAPTIDs.LWC_SERVICE_1,
  M87637: IAPTIDs.LWC_SERVICE_1,
  M87638: IAPTIDs.LWC_SERVICE_1,
  M87639: IAPTIDs.LWC_SERVICE_1,
  M87640: IAPTIDs.LWC_SERVICE_1,
  M88001: IAPTIDs.LWC_SERVICE_1,
  M88002: IAPTIDs.LWC_SERVICE_1,
  M88003: IAPTIDs.LWC_SERVICE_1,
  M88004: IAPTIDs.LWC_SERVICE_1,
  M88006: IAPTIDs.LWC_SERVICE_1,
  M88007: IAPTIDs.LWC_SERVICE_1,
  M88008: IAPTIDs.LWC_SERVICE_1,
  M88009: IAPTIDs.LWC_SERVICE_1,
  M88010: IAPTIDs.LWC_SERVICE_1,
  M88013: IAPTIDs.LWC_SERVICE_1,
  M88014: IAPTIDs.LWC_SERVICE_1,
  M88015: IAPTIDs.LWC_SERVICE_1,
  M88016: IAPTIDs.LWC_SERVICE_1,
  M88018: IAPTIDs.LWC_SERVICE_1,
  M88019: IAPTIDs.LWC_SERVICE_1,
  M88020: IAPTIDs.LWC_SERVICE_1,
  M88021: IAPTIDs.LWC_SERVICE_1,
  M88022: IAPTIDs.LWC_SERVICE_1,
  M88023: IAPTIDs.LWC_SERVICE_1,
  M88026: IAPTIDs.LWC_SERVICE_1,
  M88030: IAPTIDs.LWC_SERVICE_1,
  M88031: IAPTIDs.LWC_SERVICE_1,
  M88035: IAPTIDs.LWC_SERVICE_1,
  M88038: IAPTIDs.LWC_SERVICE_1,
  M88040: IAPTIDs.LWC_SERVICE_1,
  M88041: IAPTIDs.LWC_SERVICE_1,
  M88042: IAPTIDs.LWC_SERVICE_1,
  M88043: IAPTIDs.LWC_SERVICE_1,
  M88044: IAPTIDs.LWC_SERVICE_1,
  M88600: IAPTIDs.LWC_SERVICE_1,
  M88610: IAPTIDs.LWC_SERVICE_1,
  M88612: IAPTIDs.LWC_SERVICE_1,
  M88616: IAPTIDs.LWC_SERVICE_1,
  M88618: IAPTIDs.LWC_SERVICE_1,
  M88619: IAPTIDs.LWC_SERVICE_1,
  M88620: IAPTIDs.LWC_SERVICE_1,
  M88625: IAPTIDs.LWC_SERVICE_1,
  M88626: IAPTIDs.LWC_SERVICE_1,
  M88628: IAPTIDs.LWC_SERVICE_1,
  M88630: IAPTIDs.LWC_SERVICE_1,
  M88633: IAPTIDs.LWC_SERVICE_1,
  M88635: IAPTIDs.LWC_SERVICE_1,
  M88639: IAPTIDs.LWC_SERVICE_1,
  M88640: IAPTIDs.LWC_SERVICE_1,
  M88643: IAPTIDs.LWC_SERVICE_1,
  M88645: IAPTIDs.LWC_SERVICE_1,
  M88646: IAPTIDs.LWC_SERVICE_1,
  M88647: IAPTIDs.LWC_SERVICE_1,
  M89001: IAPTIDs.LWC_SERVICE_1,
  M89002: IAPTIDs.LWC_SERVICE_1,
  M89003: IAPTIDs.LWC_SERVICE_1,
  M89005: IAPTIDs.LWC_SERVICE_1,
  M89007: IAPTIDs.LWC_SERVICE_1,
  M89008: IAPTIDs.LWC_SERVICE_1,
  M89009: IAPTIDs.LWC_SERVICE_1,
  M89010: IAPTIDs.LWC_SERVICE_1,
  M89012: IAPTIDs.LWC_SERVICE_1,
  M89013: IAPTIDs.LWC_SERVICE_1,
  M89015: IAPTIDs.LWC_SERVICE_1,
  M89016: IAPTIDs.LWC_SERVICE_1,
  M89017: IAPTIDs.LWC_SERVICE_1,
  M89019: IAPTIDs.LWC_SERVICE_1,
  M89021: IAPTIDs.LWC_SERVICE_1,
  M89024: IAPTIDs.LWC_SERVICE_1,
  M89026: IAPTIDs.LWC_SERVICE_1,
  M89027: IAPTIDs.LWC_SERVICE_1,
  M89030: IAPTIDs.LWC_SERVICE_1,
  M89608: IAPTIDs.LWC_SERVICE_1,
  M91003: IAPTIDs.LWC_SERVICE_1,
  M91004: IAPTIDs.LWC_SERVICE_1,
  M91006: IAPTIDs.LWC_SERVICE_1,
  M91007: IAPTIDs.LWC_SERVICE_1,
  M91008: IAPTIDs.LWC_SERVICE_1,
  M91009: IAPTIDs.LWC_SERVICE_1,
  M91010: IAPTIDs.LWC_SERVICE_1,
  M91011: IAPTIDs.LWC_SERVICE_1,
  M91013: IAPTIDs.LWC_SERVICE_1,
  M91014: IAPTIDs.LWC_SERVICE_1,
  M91015: IAPTIDs.LWC_SERVICE_1,
  M91016: IAPTIDs.LWC_SERVICE_1,
  M91017: IAPTIDs.LWC_SERVICE_1,
  M91018: IAPTIDs.LWC_SERVICE_1,
  M91019: IAPTIDs.LWC_SERVICE_1,
  M91020: IAPTIDs.LWC_SERVICE_1,
  M91021: IAPTIDs.LWC_SERVICE_1,
  M91024: IAPTIDs.LWC_SERVICE_1,
  M91026: IAPTIDs.LWC_SERVICE_1,
  M91028: IAPTIDs.LWC_SERVICE_1,
  M91029: IAPTIDs.LWC_SERVICE_1,
  M91032: IAPTIDs.LWC_SERVICE_1,
  M91033: IAPTIDs.LWC_SERVICE_1,
  M91034: IAPTIDs.LWC_SERVICE_1,
  M91036: IAPTIDs.LWC_SERVICE_1,
  M91602: IAPTIDs.LWC_SERVICE_1,
  M91609: IAPTIDs.LWC_SERVICE_1,
  M91611: IAPTIDs.LWC_SERVICE_1,
  M91612: IAPTIDs.LWC_SERVICE_1,
  M91613: IAPTIDs.LWC_SERVICE_1,
  M91614: IAPTIDs.LWC_SERVICE_1,
  M91616: IAPTIDs.LWC_SERVICE_1,
  M91619: IAPTIDs.LWC_SERVICE_1,
  M91621: IAPTIDs.LWC_SERVICE_1,
  M91623: IAPTIDs.LWC_SERVICE_1,
  M91624: IAPTIDs.LWC_SERVICE_1,
  M91626: IAPTIDs.LWC_SERVICE_1,
  M91628: IAPTIDs.LWC_SERVICE_1,
  M91629: IAPTIDs.LWC_SERVICE_1,
  M91637: IAPTIDs.LWC_SERVICE_1,
  M91639: IAPTIDs.LWC_SERVICE_1,
  M91640: IAPTIDs.LWC_SERVICE_1,
  M91641: IAPTIDs.LWC_SERVICE_1,
  M91642: IAPTIDs.LWC_SERVICE_1,
  M91647: IAPTIDs.LWC_SERVICE_1,
  M91650: IAPTIDs.LWC_SERVICE_1,
  M91654: IAPTIDs.LWC_SERVICE_1,
  M91659: IAPTIDs.LWC_SERVICE_1,
  M91660: IAPTIDs.LWC_SERVICE_1,
  M92001: IAPTIDs.LWC_SERVICE_1,
  M92002: IAPTIDs.LWC_SERVICE_1,
  M92004: IAPTIDs.LWC_SERVICE_1,
  M92006: IAPTIDs.LWC_SERVICE_1,
  M92007: IAPTIDs.LWC_SERVICE_1,
  M92008: IAPTIDs.LWC_SERVICE_1,
  M92009: IAPTIDs.LWC_SERVICE_1,
  M92010: IAPTIDs.LWC_SERVICE_1,
  M92011: IAPTIDs.LWC_SERVICE_1,
  M92012: IAPTIDs.LWC_SERVICE_1,
  M92013: IAPTIDs.LWC_SERVICE_1,
  M92014: IAPTIDs.LWC_SERVICE_1,
  M92015: IAPTIDs.LWC_SERVICE_1,
  M92016: IAPTIDs.LWC_SERVICE_1,
  M92019: IAPTIDs.LWC_SERVICE_1,
  M92022: IAPTIDs.LWC_SERVICE_1,
  M92026: IAPTIDs.LWC_SERVICE_1,
  M92028: IAPTIDs.LWC_SERVICE_1,
  M92029: IAPTIDs.LWC_SERVICE_1,
  M92039: IAPTIDs.LWC_SERVICE_1,
  M92040: IAPTIDs.LWC_SERVICE_1,
  M92041: IAPTIDs.LWC_SERVICE_1,
  M92042: IAPTIDs.LWC_SERVICE_1,
  M92043: IAPTIDs.LWC_SERVICE_1,
  M92044: IAPTIDs.LWC_SERVICE_1,
  M92607: IAPTIDs.LWC_SERVICE_1,
  M92609: IAPTIDs.LWC_SERVICE_1,
  M92612: IAPTIDs.LWC_SERVICE_1,
  M92627: IAPTIDs.LWC_SERVICE_1,
  M92629: IAPTIDs.LWC_SERVICE_1,
  M92630: IAPTIDs.LWC_SERVICE_1,
  M92640: IAPTIDs.LWC_SERVICE_1,
  M92649: IAPTIDs.LWC_SERVICE_1,
  M92654: IAPTIDs.LWC_SERVICE_1,
  M92656: IAPTIDs.LWC_SERVICE_1,
  M92657: IAPTIDs.LWC_SERVICE_1,
  Y00159: IAPTIDs.LWC_SERVICE_1,
  Y00228: IAPTIDs.LWC_SERVICE_1,
  Y00278: IAPTIDs.LWC_SERVICE_1,
  Y00281: IAPTIDs.LWC_SERVICE_1,
  Y00412: IAPTIDs.LWC_SERVICE_1,
  Y00471: IAPTIDs.LWC_SERVICE_1,
  Y00492: IAPTIDs.LWC_SERVICE_1,
  Y00646: IAPTIDs.LWC_SERVICE_1,
  Y00694: IAPTIDs.LWC_SERVICE_1,
  Y00738: IAPTIDs.LWC_SERVICE_1,
  Y00828: IAPTIDs.LWC_SERVICE_1,
  Y00886: IAPTIDs.LWC_SERVICE_1,
  Y00887: IAPTIDs.LWC_SERVICE_1,
  Y00919: IAPTIDs.LWC_SERVICE_1,
  Y00920: IAPTIDs.LWC_SERVICE_1,
  Y00921: IAPTIDs.LWC_SERVICE_1,
  Y00922: IAPTIDs.LWC_SERVICE_1,
  Y00949: IAPTIDs.LWC_SERVICE_1,
  Y00954: IAPTIDs.LWC_SERVICE_1,
  Y01002: IAPTIDs.LWC_SERVICE_1,
  Y01039: IAPTIDs.LWC_SERVICE_1,
  Y01057: IAPTIDs.LWC_SERVICE_1,
  Y01068: IAPTIDs.LWC_SERVICE_1,
  Y01091: IAPTIDs.LWC_SERVICE_1,
  Y01133: IAPTIDs.LWC_SERVICE_1,
  Y01146: IAPTIDs.LWC_SERVICE_1,
  Y01176: IAPTIDs.LWC_SERVICE_1,
  Y01191: IAPTIDs.LWC_SERVICE_1,
  Y01192: IAPTIDs.LWC_SERVICE_1,
  Y01683: IAPTIDs.LWC_SERVICE_1,
  Y01710: IAPTIDs.LWC_SERVICE_1,
  Y01732: IAPTIDs.LWC_SERVICE_1,
  Y01746: IAPTIDs.LWC_SERVICE_1,
  Y01748: IAPTIDs.LWC_SERVICE_1,
  Y01756: IAPTIDs.LWC_SERVICE_1,
  Y01836: IAPTIDs.LWC_SERVICE_1,
  Y01866: IAPTIDs.LWC_SERVICE_1,
  Y01930: IAPTIDs.LWC_SERVICE_1,
  Y01980: IAPTIDs.LWC_SERVICE_1,
  Y01981: IAPTIDs.LWC_SERVICE_1,
  Y01982: IAPTIDs.LWC_SERVICE_1,
  Y02212: IAPTIDs.LWC_SERVICE_1,
  Y02245: IAPTIDs.LWC_SERVICE_1,
  Y02298: IAPTIDs.LWC_SERVICE_1,
  Y02403: IAPTIDs.LWC_SERVICE_1,
  Y02430: IAPTIDs.LWC_SERVICE_1,
  Y02431: IAPTIDs.LWC_SERVICE_1,
  Y02432: IAPTIDs.LWC_SERVICE_1,
  Y02433: IAPTIDs.LWC_SERVICE_1,
  Y02434: IAPTIDs.LWC_SERVICE_1,
  Y02435: IAPTIDs.LWC_SERVICE_1,
  Y02436: IAPTIDs.LWC_SERVICE_1,
  Y02437: IAPTIDs.LWC_SERVICE_1,
  Y02438: IAPTIDs.LWC_SERVICE_1,
  Y02439: IAPTIDs.LWC_SERVICE_1,
  Y02440: IAPTIDs.LWC_SERVICE_1,
  Y02441: IAPTIDs.LWC_SERVICE_1,
  Y02481: IAPTIDs.LWC_SERVICE_1,
  Y02528: IAPTIDs.LWC_SERVICE_1,
  Y02567: IAPTIDs.LWC_SERVICE_1,
  Y02571: IAPTIDs.LWC_SERVICE_1,
  Y02615: IAPTIDs.LWC_SERVICE_1,
  Y02620: IAPTIDs.LWC_SERVICE_1,
  Y02626: IAPTIDs.LWC_SERVICE_1,
  Y02627: IAPTIDs.LWC_SERVICE_1,
  Y02628: IAPTIDs.LWC_SERVICE_1,
  Y02636: IAPTIDs.LWC_SERVICE_1,
  Y02653: IAPTIDs.LWC_SERVICE_1,
  Y02701: IAPTIDs.LWC_SERVICE_1,
  Y02728: IAPTIDs.LWC_SERVICE_1,
  Y02736: IAPTIDs.LWC_SERVICE_1,
  Y02757: IAPTIDs.LWC_SERVICE_1,
  Y02794: IAPTIDs.LWC_SERVICE_1,
  Y02845: IAPTIDs.LWC_SERVICE_1,
  Y02879: IAPTIDs.LWC_SERVICE_1,
  Y02889: IAPTIDs.LWC_SERVICE_1,
  Y02893: IAPTIDs.LWC_SERVICE_1,
  Y02901: IAPTIDs.LWC_SERVICE_1,
  Y02929: IAPTIDs.LWC_SERVICE_1,
  Y02931: IAPTIDs.LWC_SERVICE_1,
  Y02979: IAPTIDs.LWC_SERVICE_1,
  Y03002: IAPTIDs.LWC_SERVICE_1,
  Y03007: IAPTIDs.LWC_SERVICE_1,
  Y03015: IAPTIDs.LWC_SERVICE_1,
  Y03016: IAPTIDs.LWC_SERVICE_1,
  Y03018: IAPTIDs.LWC_SERVICE_1,
  Y03034: IAPTIDs.LWC_SERVICE_1,
  Y03091: IAPTIDs.LWC_SERVICE_1,
  Y03166: IAPTIDs.LWC_SERVICE_1,
  Y03241: IAPTIDs.LWC_SERVICE_1,
  Y03242: IAPTIDs.LWC_SERVICE_1,
  Y03315: IAPTIDs.LWC_SERVICE_1,
  Y03331: IAPTIDs.LWC_SERVICE_1,
  Y03358: IAPTIDs.LWC_SERVICE_1,
  Y03361: IAPTIDs.LWC_SERVICE_1,
  Y03392: IAPTIDs.LWC_SERVICE_1,
  Y03421: IAPTIDs.LWC_SERVICE_1,
  Y03432: IAPTIDs.LWC_SERVICE_1,
  Y03451: IAPTIDs.LWC_SERVICE_1,
  Y03452: IAPTIDs.LWC_SERVICE_1,
  Y03457: IAPTIDs.LWC_SERVICE_1,
  Y03597: IAPTIDs.LWC_SERVICE_1,
  Y03598: IAPTIDs.LWC_SERVICE_1,
  Y03623: IAPTIDs.LWC_SERVICE_1,
  Y03653: IAPTIDs.LWC_SERVICE_1,
  Y03712: IAPTIDs.LWC_SERVICE_1,
  Y03747: IAPTIDs.LWC_SERVICE_1,
  Y03835: IAPTIDs.LWC_SERVICE_1,
  Y03938: IAPTIDs.LWC_SERVICE_1,
  Y03942: IAPTIDs.LWC_SERVICE_1,
  Y03965: IAPTIDs.LWC_SERVICE_1,
  Y04159: IAPTIDs.LWC_SERVICE_1,
  Y04206: IAPTIDs.LWC_SERVICE_1,
  Y04217: IAPTIDs.LWC_SERVICE_1,
  Y04221: IAPTIDs.LWC_SERVICE_1,
  Y04236: IAPTIDs.LWC_SERVICE_1,
  Y04249: IAPTIDs.LWC_SERVICE_1,
  Y04376: IAPTIDs.LWC_SERVICE_1,
  Y04412: IAPTIDs.LWC_SERVICE_1,
  Y04441: IAPTIDs.LWC_SERVICE_1,
  Y04642: IAPTIDs.LWC_SERVICE_1,
  Y04643: IAPTIDs.LWC_SERVICE_1,
  Y04656: IAPTIDs.LWC_SERVICE_1,
  Y04697: IAPTIDs.LWC_SERVICE_1,
  Y04726: IAPTIDs.LWC_SERVICE_1,
  Y04730: IAPTIDs.LWC_SERVICE_1,
  Y04732: IAPTIDs.LWC_SERVICE_1,
  Y04733: IAPTIDs.LWC_SERVICE_1,
  Y04736: IAPTIDs.LWC_SERVICE_1,
  Y04738: IAPTIDs.LWC_SERVICE_1,
  Y04739: IAPTIDs.LWC_SERVICE_1,
  Y04740: IAPTIDs.LWC_SERVICE_1,
  Y04741: IAPTIDs.LWC_SERVICE_1,
  Y04747: IAPTIDs.LWC_SERVICE_1,
  Y04748: IAPTIDs.LWC_SERVICE_1,
  Y04749: IAPTIDs.LWC_SERVICE_1,
  Y04750: IAPTIDs.LWC_SERVICE_1,
  Y04752: IAPTIDs.LWC_SERVICE_1,
  Y04753: IAPTIDs.LWC_SERVICE_1,
  Y04754: IAPTIDs.LWC_SERVICE_1,
  Y04757: IAPTIDs.LWC_SERVICE_1,
  Y04758: IAPTIDs.LWC_SERVICE_1,
  Y04759: IAPTIDs.LWC_SERVICE_1,
  Y04762: IAPTIDs.LWC_SERVICE_1,
  Y04767: IAPTIDs.LWC_SERVICE_1,
  Y04768: IAPTIDs.LWC_SERVICE_1,
  Y04775: IAPTIDs.LWC_SERVICE_1,
  Y04793: IAPTIDs.LWC_SERVICE_1,
  Y04805: IAPTIDs.LWC_SERVICE_1,
  Y04844: IAPTIDs.LWC_SERVICE_1,
  Y04845: IAPTIDs.LWC_SERVICE_1,
  Y04857: IAPTIDs.LWC_SERVICE_1,
  Y04859: IAPTIDs.LWC_SERVICE_1,
  Y04867: IAPTIDs.LWC_SERVICE_1,
  Y04869: IAPTIDs.LWC_SERVICE_1,
  Y04870: IAPTIDs.LWC_SERVICE_1,
  Y04878: IAPTIDs.LWC_SERVICE_1,
  Y04904: IAPTIDs.LWC_SERVICE_1,
  Y04907: IAPTIDs.LWC_SERVICE_1,
  Y04911: IAPTIDs.LWC_SERVICE_1,
  Y04928: IAPTIDs.LWC_SERVICE_1,
  Y04931: IAPTIDs.LWC_SERVICE_1,
  Y04938: IAPTIDs.LWC_SERVICE_1,
  Y04946: IAPTIDs.LWC_SERVICE_1,
  Y04948: IAPTIDs.LWC_SERVICE_1,
  Y04953: IAPTIDs.LWC_SERVICE_1,
  Y04966: IAPTIDs.LWC_SERVICE_1,
  Y04992: IAPTIDs.LWC_SERVICE_1,
  Y05003: IAPTIDs.LWC_SERVICE_1,
  Y05082: IAPTIDs.LWC_SERVICE_1,
  Y05083: IAPTIDs.LWC_SERVICE_1,
  Y05106: IAPTIDs.LWC_SERVICE_1,
  Y05156: IAPTIDs.LWC_SERVICE_1,
  Y05219: IAPTIDs.LWC_SERVICE_1,
  Y05220: IAPTIDs.LWC_SERVICE_1,
  Y05259: IAPTIDs.LWC_SERVICE_1,
  Y05344: IAPTIDs.LWC_SERVICE_1,
  Y05373: IAPTIDs.LWC_SERVICE_1,
  Y05389: IAPTIDs.LWC_SERVICE_1,
  Y05451: IAPTIDs.LWC_SERVICE_1,
  Y05608: IAPTIDs.LWC_SERVICE_1,
  Y05617: IAPTIDs.LWC_SERVICE_1,
  Y05628: IAPTIDs.LWC_SERVICE_1,
  Y05640: IAPTIDs.LWC_SERVICE_1,
  Y05724: IAPTIDs.LWC_SERVICE_1,
  Y05789: IAPTIDs.LWC_SERVICE_1,
  Y05826: IAPTIDs.LWC_SERVICE_1,
  Y05847: IAPTIDs.LWC_SERVICE_1,
  Y05853: IAPTIDs.LWC_SERVICE_1,
  Y05854: IAPTIDs.LWC_SERVICE_1,
  Y05856: IAPTIDs.LWC_SERVICE_1,
  Y05865: IAPTIDs.LWC_SERVICE_1,
  Y05869: IAPTIDs.LWC_SERVICE_1,
  Y05914: IAPTIDs.LWC_SERVICE_1,
  Y05949: IAPTIDs.LWC_SERVICE_1,
  Y05973: IAPTIDs.LWC_SERVICE_1,
  Y05981: IAPTIDs.LWC_SERVICE_1,
  Y06082: IAPTIDs.LWC_SERVICE_1,
  Y06094: IAPTIDs.LWC_SERVICE_1,
  Y06125: IAPTIDs.LWC_SERVICE_1,
  Y06141: IAPTIDs.LWC_SERVICE_1,
  Y06146: IAPTIDs.LWC_SERVICE_1,
  Y06149: IAPTIDs.LWC_SERVICE_1,
  Y06174: IAPTIDs.LWC_SERVICE_1,
  Y06175: IAPTIDs.LWC_SERVICE_1,
  Y06176: IAPTIDs.LWC_SERVICE_1,
  Y06177: IAPTIDs.LWC_SERVICE_1,
  Y06178: IAPTIDs.LWC_SERVICE_1,
  Y06234: IAPTIDs.LWC_SERVICE_1,
  Y06235: IAPTIDs.LWC_SERVICE_1,
  Y06236: IAPTIDs.LWC_SERVICE_1,
  Y06290: IAPTIDs.LWC_SERVICE_1,
  Y06296: IAPTIDs.LWC_SERVICE_1,
  Y06310: IAPTIDs.LWC_SERVICE_1,
  Y06341: IAPTIDs.LWC_SERVICE_1,
  Y06353: IAPTIDs.LWC_SERVICE_1,
  Y06358: IAPTIDs.LWC_SERVICE_1,
  Y06363: IAPTIDs.LWC_SERVICE_1,
  Y06368: IAPTIDs.LWC_SERVICE_1,
  Y06369: IAPTIDs.LWC_SERVICE_1,
  Y06370: IAPTIDs.LWC_SERVICE_1,
  Y06371: IAPTIDs.LWC_SERVICE_1,
  Y06372: IAPTIDs.LWC_SERVICE_1,
  Y06373: IAPTIDs.LWC_SERVICE_1,
  Y06376: IAPTIDs.LWC_SERVICE_1,
  Y06378: IAPTIDs.LWC_SERVICE_1,
  Y06379: IAPTIDs.LWC_SERVICE_1,
  Y06421: IAPTIDs.LWC_SERVICE_1,
  Y06428: IAPTIDs.LWC_SERVICE_1,
  Y06502: IAPTIDs.LWC_SERVICE_1,
  Y06505: IAPTIDs.LWC_SERVICE_1,
  Y06509: IAPTIDs.LWC_SERVICE_1,
  Y06510: IAPTIDs.LWC_SERVICE_1,
  Y06511: IAPTIDs.LWC_SERVICE_1,
  Y06512: IAPTIDs.LWC_SERVICE_1,
  Y06528: IAPTIDs.LWC_SERVICE_1,
  Y06533: IAPTIDs.LWC_SERVICE_1,
  Y06557: IAPTIDs.LWC_SERVICE_1,
  Y06558: IAPTIDs.LWC_SERVICE_1,
  Y06563: IAPTIDs.LWC_SERVICE_1,
  Y06566: IAPTIDs.LWC_SERVICE_1,
  Y06567: IAPTIDs.LWC_SERVICE_1,
  Y06572: IAPTIDs.LWC_SERVICE_1,
  Y06578: IAPTIDs.LWC_SERVICE_1,
  Y06589: IAPTIDs.LWC_SERVICE_1,
  Y06591: IAPTIDs.LWC_SERVICE_1,
  Y06606: IAPTIDs.LWC_SERVICE_1,
  Y06623: IAPTIDs.LWC_SERVICE_1,
  Y06663: IAPTIDs.LWC_SERVICE_1,
  Y06665: IAPTIDs.LWC_SERVICE_1,
  Y06666: IAPTIDs.LWC_SERVICE_1,
  Y06667: IAPTIDs.LWC_SERVICE_1,
  Y06723: IAPTIDs.LWC_SERVICE_1,
  Y06725: IAPTIDs.LWC_SERVICE_1,
  Y06726: IAPTIDs.LWC_SERVICE_1,
  Y06788: IAPTIDs.LWC_SERVICE_1,
  Y06795: IAPTIDs.LWC_SERVICE_1,
  Y06813: IAPTIDs.LWC_SERVICE_1,
  Y06854: IAPTIDs.LWC_SERVICE_1,
  Y06883: IAPTIDs.LWC_SERVICE_1,
  Y06884: IAPTIDs.LWC_SERVICE_1,
  Y06885: IAPTIDs.LWC_SERVICE_1,
  Y06886: IAPTIDs.LWC_SERVICE_1,
  Y06887: IAPTIDs.LWC_SERVICE_1,
  Y06888: IAPTIDs.LWC_SERVICE_1,
  Y06889: IAPTIDs.LWC_SERVICE_1,
  Y06943: IAPTIDs.LWC_SERVICE_1,
  Y06978: IAPTIDs.LWC_SERVICE_1,
  Y07003: IAPTIDs.LWC_SERVICE_1,
  Y07005: IAPTIDs.LWC_SERVICE_1,
  Y07017: IAPTIDs.LWC_SERVICE_1,
  Y07027: IAPTIDs.LWC_SERVICE_1,
  Y07031: IAPTIDs.LWC_SERVICE_1,
  Y07033: IAPTIDs.LWC_SERVICE_1,
  Y07098: IAPTIDs.LWC_SERVICE_1,
  Y07110: IAPTIDs.LWC_SERVICE_1,
  Y07128: IAPTIDs.LWC_SERVICE_1,
  Y07203: IAPTIDs.LWC_SERVICE_1,
  Y07213: IAPTIDs.LWC_SERVICE_1,
  Y07215: IAPTIDs.LWC_SERVICE_1,
  Y07379: IAPTIDs.LWC_SERVICE_1,
  Y07399: IAPTIDs.LWC_SERVICE_1,
  Y07633: IAPTIDs.LWC_SERVICE_1,
  Y07634: IAPTIDs.LWC_SERVICE_1,
  Y07640: IAPTIDs.LWC_SERVICE_1,
  Y07642: IAPTIDs.LWC_SERVICE_1,
  Y07668: IAPTIDs.LWC_SERVICE_1,
  Y07681: IAPTIDs.LWC_SERVICE_1
}
