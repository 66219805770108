import { IClinicalPath } from "@limbic/types"
import { IUserClinicalPathDescriptor } from "../models/IClinicalPath"
import { getArraysAreEqual } from "./array"

export default function getClinicalPath(
  defaults: IClinicalPath[] = [],
  custom: IClinicalPath[] = [],
  input: IUserClinicalPathDescriptor
): IClinicalPath | undefined {
  const customWithSecondary = custom.filter(p => p.enableSecondary)
  const customWithoutSecondary = custom.filter(p => !p.enableSecondary)
  const allPaths = [...customWithSecondary, ...customWithoutSecondary, ...defaults]
  return allPaths.find(p => isMatchingPath(input, p))
}

export function getAllMatchingPaths(
  clinicalPaths: IClinicalPath[],
  input: IUserClinicalPathDescriptor
): IClinicalPath[] {
  return clinicalPaths.filter(p => isMatchingPath(input, p))
}

export function isMatchingPath(input: IUserClinicalPathDescriptor, path: IClinicalPath): boolean {
  if (!path.matcher) return false

  // if primaries don't match
  if (!getArraysAreEqual(input.primaryProblems, path.matcher.primaryProblems)) return false

  // if flags don't match
  if (!getArraysAreEqual(input.flags, path.matcher.flags ?? [])) return false

  // if secondaries are enabled and don't match
  // prettier-ignore
  // noinspection RedundantIfStatementJS
  if (path.enableSecondary && !getArraysAreEqual(input.secondaryProblems, path.matcher.secondaryProblems ?? [])) {
    return false
  }
  return true
}
