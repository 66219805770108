import BaseScript, { BaseScriptState } from "../../../BaseScript"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { step } from "../../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { IDefaultChatFlowMessagesCollectSexuality, ISelectableExtended } from "@limbic/types"

export type ICollectSexualitySettings = {
  options?: ISelectableExtended[] | undefined
  messages?: IDefaultChatFlowMessagesCollectSexuality
}

interface State extends BaseScriptState {
  withTextPrompt?: boolean
}

export type CollectSexualityScriptState = State

export class CollectSexualityScript extends BaseScript<State> {
  readonly name: string = "CollectSexualityScript"
  protected sexualities: ISelectableExtended[] | undefined
  protected messages: IDefaultChatFlowMessagesCollectSexuality | undefined
  constructor(settings: ICollectSexualitySettings | undefined = {}) {
    super()
    this.sexualities = settings?.options ?? []
    this.messages = settings?.messages ?? {}
  }

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askSexuality }
  }

  @step.logState
  async askSexuality(d: IStepData<State>): Promise<IStepResult> {
    const sexualities = this.sexualities ?? []
    if (!sexualities?.length) {
      this.logBreadcrumb("SEXUALITIES NOT FOUND", d.state, { sexualities })
      this.logMessage("SEXUALITIES NOT FOUND")
      return { nextStep: this.end }
    }

    return {
      body: this.t(
        this.messages?.askSexuality ?? "How would you describe your sexuality?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askSexuality"),
        trackResponse: true,
        type: "inlinePicker",
        choices: sexualities,
        dataPointsName: "askSexuality",
        textPrompt: d.state.withTextPrompt
          ? { forceValue: true, placeholder: this.t("Other (please specify)") }
          : undefined
      },
      nextStep: this.handleSexualityWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: CollectSexualityScript) => {
    const sexualities = script.sexualities ?? []
    const isAnswerFromList = !!sexualities?.find(s => s.value === d.response)

    d.state.sexuality = isAnswerFromList ? d.response : undefined
    d.state.sexualityOther = isAnswerFromList ? undefined : d.response
    script.setPeople({ sexuality: d.state.sexuality, sexualityOther: d.state.sexualityOther })
  })
  @step.checkInputForCrisis({
    getNextStep: (s: CollectSexualityScript) => s.end
  })
  async handleSexualityWithCrisis(d: IStepData<State, string>): Promise<IStepResult> {
    const sexualities = this.sexualities ?? []
    const isAnswerFromList = !!sexualities?.find(s => s.value === d.response)

    if (!d.state.withTextPrompt && !isAnswerFromList) {
      return {
        body: this.t("Sorry I can't recognize this sexuality. Let's try again"),
        nextStep: this.askSexuality
      }
    }

    return { nextStep: this.end }
  }
}

export default class CollectSexualityDialogue extends AdHocDialogue<State, CollectSexualityScript> {
  static id = DialogueIDs.CollectSexuality
  readonly name: string = "CollectSexualityDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: ICollectSexualitySettings
  ) {
    super(
      CollectSexualityDialogue.id,
      new CollectSexualityScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
