import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { UnderAgeSignpostScript, UnderAgeSignpostScriptState } from "./UnderAgeSignpostDialogue"
import { DialogueIDs } from "../../DialogueIDs"

type State = UnderAgeSignpostScriptState
export type UnderAgeSignpostLWCScriptState = State

export class UnderAgeSignpostLWCScript extends UnderAgeSignpostScript {
  readonly name: string = "UnderAgeSignpostLWCScript"

  /** Script Steps */

  @step.logState
  sayNotEligible(d: IStepData<State>): IStepResult {
    const threshold = this.getIAPTServiceAgeThreshold(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    const name = this.getName(d.state)
    return {
      body: [
        `So ${name}, there's a special type of mental health support for people under the age of ${threshold}`,
        `${organisationName} do not currently provide this, so we are unable to refer you at this time`,
        "However, you can find loads of helpful resources from the following organisations:",
        "If you live in Birmingham, please contact Forward Thinking Birmingham Crisis Team at 0300 300 0099, or attend a Pause drop in session, which can be found [here](https://forwardthinkingbirmingham.nhs.uk/pause)",
        "If you live in Solihull, please contact Solar at 0121 301 2750",
        "To find the best service for you, there's a helpful website [here](https://www.nhs.uk/service-search/other-services/Mental-health-support-for-young-people/LocationSearch/1430)",
        "Or you can talk to your doctor and they will help you"
      ],
      prompt: {
        id: this.getPromptId("sayNotEligible"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Okay", value: "okay" },
          { body: "Thanks", value: "thanks" }
        ]
      },
      nextStep: this.handleNotEligible
    }
  }
}

export default class UnderAgeSignpostDialogue extends Dialogue<State> {
  static id = DialogueIDs.UnderAgeSignpostLWC
  readonly name: string = "UnderAgeSignpostLWCDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(UnderAgeSignpostDialogue.id, new UnderAgeSignpostLWCScript(), state, snapshot)
  }
}
