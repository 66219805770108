import BaseScript, { BaseScriptState } from "../../../BaseScript"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { step } from "../../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import isValidPhoneNumber, { isValidMobilePhone } from "../../../../utils/isValidPhoneNumber"
import { TrackingEvents } from "../../../../models/Constants"
import {
  IDefaultChatFlowMessagesCollectPhoneNumber,
  IDefaultChatFlowSettingsCollectPhoneNumber
} from "@limbic/types"
import { IPhoneNumberPrompt } from "../../../../backend/chatbot/models/IPrompt"

export type ICollectPhoneNumberSettings = IDefaultChatFlowSettingsCollectPhoneNumber & {
  messages?: IDefaultChatFlowMessagesCollectPhoneNumber
}

type State = BaseScriptState
export type CollectPhoneNumberScriptState = State

export class CollectPhoneNumberScript extends BaseScript<State> {
  readonly name: string = "CollectPhoneNumberScript"
  protected readonly messages: IDefaultChatFlowMessagesCollectPhoneNumber | undefined
  protected readonly forceMobile?: boolean
  protected readonly forceLandline?: boolean
  protected readonly requestVoicemailConsent?: boolean
  protected readonly requestSMSConsent?: boolean
  protected readonly supportedCountries?: string[]
  protected readonly phoneNumberKey?: string
  protected readonly voicemailConsentKey?: string
  protected readonly smsConsentKey?: string

  constructor(settings?: ICollectPhoneNumberSettings | undefined) {
    super()
    this.messages = settings?.messages ?? {}
    this.forceMobile = settings?.forceMobile
    this.forceLandline = settings?.forceLandline
    this.requestVoicemailConsent = settings?.requestVoicemailConsent
    this.requestSMSConsent = settings?.requestSMSConsent
    this.supportedCountries = settings?.supportedCountries
    this.phoneNumberKey = settings?.phoneNumberKey
    this.voicemailConsentKey = settings?.voicemailConsentKey
    this.smsConsentKey = settings?.smsConsentKey
  }

  /** Optional Generic Handlers */

  onHandlePhoneNumber?(state: State): Promise<IStepResult>
  onHandleCanIContactYouOnPhoneNumber?(state: State): Promise<IStepResult>

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    return { nextStep: this.askPhoneNumber }
  }

  @step.logState
  askPhoneNumber(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messages?.askPhoneNumber ?? "What's the best phone number to reach you on?",
        this.getContext(d.state)
      ),
      nextStep: this.showPhoneNumberPrompt
    }
  }

  @step
  sayPleaseTypePhoneNumber(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Please type your phone number"),
      nextStep: this.showPhoneNumberPrompt
    }
  }

  @step.logState
  showPhoneNumberPrompt(_d: IStepData<State>): IStepResult {
    const prompt: IPhoneNumberPrompt = {
      id: this.getPromptId("showPhoneNumberPrompt"),
      type: "phoneNumber",
      supportedCountries: this.supportedCountries ?? []
    }
    if (this.forceMobile != null) prompt.forceMobile = this.forceMobile
    if (this.forceLandline != null) prompt.forceLandline = this.forceLandline
    return { prompt, nextStep: this.handlePhoneNumber }
  }

  @step
  returnToAskPhoneNumber(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("So..."),
      nextStep: this.sayPleaseTypePhoneNumber
    }
  }

  @step.logState
  @step.checkInputForCrisis({
    getInput: (d: IStepData<State>) => (d.response ? d.response : ""),
    disableDetectionIfWrong: true,
    getNextStep: (s: CollectPhoneNumberScript) => s.returnToAskPhoneNumber
  })
  async handlePhoneNumber(d: IStepData<State, string>): Promise<IStepResult> {
    const isValid = isValidPhoneNumber(d.response)
    if (!isValid) {
      this.track(TrackingEvents.INVALID_PHONE_NUMBER)
      return {
        body: this.t("Sorry this is not a valid phone number. Let's try again"),
        nextStep: this.sayPleaseTypePhoneNumber
      }
    }

    const key = this.phoneNumberKey ?? "phoneNumber"
    d.state[key] = d.response

    this.setPeople({
      [key]: isValidMobilePhone(d.response) ? "MOBILE PHONE ENTERED" : "LANDLINE PHONE ENTERED"
    })
    const result = await this.onHandlePhoneNumber?.(d.state)
    if (result) return result
    return {
      body: this.t("Thanks"),
      nextStep: this.askCanIContactYouOnPhoneNumber
    }
  }

  @step.logState
  async askCanIContactYouOnPhoneNumber(d: IStepData<State>): Promise<IStepResult> {
    const requestSMSConsent = this.requestSMSConsent ?? true
    const requestVoicemailConsent = this.requestVoicemailConsent ?? true
    const smsKey = this.smsConsentKey ?? "canSendTextMessagesToPhoneNumber"
    const voicemailKey = this.voicemailConsentKey ?? "canLeaveVoicemailToPhoneNumber"

    // 👇 just in case of an undo
    if (!requestSMSConsent) d.state[smsKey] = undefined
    if (!requestVoicemailConsent) d.state[voicemailKey] = undefined

    if (!requestSMSConsent && !requestVoicemailConsent) {
      const result = await this.onHandleCanIContactYouOnPhoneNumber?.(d.state)
      if (result) return result
      return { nextStep: this.end }
    }

    // prettier-ignore
    const VOICEMAIL_MESSAGES_PROMPT = { body: this.t("You may leave voicemail messages"), key: voicemailKey }
    const TEXT_MESSAGES_PROMPT = { body: this.t("You may send me text messages"), key: smsKey }

    const key = this.phoneNumberKey ?? "phoneNumber"
    const isMobilePhone = isValidMobilePhone(d.state[key])
    const options = [] as any[]
    if (requestSMSConsent && isMobilePhone) options.push(TEXT_MESSAGES_PROMPT)
    if (requestVoicemailConsent) options.push(VOICEMAIL_MESSAGES_PROMPT)

    return {
      body: this.t(
        this.messages?.askCanIContactYouOnPhoneNumber ??
          (isMobilePhone
            ? "And is it ok to send you text messages or leave a voicemail if I can't get through?"
            : "And is it ok to leave a voicemail if I can't get through?"),
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askCanIContactYouOnPhoneNumber"),
        trackResponse: true,
        type: "checkbox",
        options
      },
      nextStep: this.handleCanIContactYouOnPhoneNumber
    }
  }

  @step.logStateAndResponse
  async handleCanIContactYouOnPhoneNumber(
    d: IStepData<
      State,
      {
        canSendTextMessagesToPhoneNumber?: boolean
        canLeaveVoicemailToPhoneNumber?: boolean
        [key: string]: boolean | undefined
      }
    >
  ): Promise<IStepResult> {
    const smsKey = this.smsConsentKey ?? "canSendTextMessagesToPhoneNumber"
    const voicemailKey = this.voicemailConsentKey ?? "canLeaveVoicemailToPhoneNumber"

    if (d.response[smsKey] != null) {
      d.state[smsKey] = d.response[smsKey]
    }

    if (d.response[voicemailKey] != null) {
      d.state[voicemailKey] = d.response[voicemailKey]
    }

    const data = { [smsKey]: d.response[smsKey], [voicemailKey]: d.response[voicemailKey] }
    this.setPeople(data)
    this.track(TrackingEvents.PHONE_PERMISSIONS, data)
    const result = await this.onHandleCanIContactYouOnPhoneNumber?.(d.state)
    if (result) return result
    return { nextStep: this.end }
  }
}

export default class CollectPhoneNumberDialogue extends AdHocDialogue<
  State,
  CollectPhoneNumberScript
> {
  static id = DialogueIDs.CollectPhoneNumber
  readonly name: string = "CollectPhoneNumberDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: ICollectPhoneNumberSettings
  ) {
    super(
      CollectPhoneNumberDialogue.id,
      new CollectPhoneNumberScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
