import BaseScript, { BaseScriptState } from "../../../BaseScript"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { step } from "../../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import {
  IDefaultChatFlowMessagesCollectDisabilities,
  IDefaultChatFlowSettingsCollectDisabilities,
  ISelectableExtended
} from "@limbic/types"

const OTHER_VALUE = "Other"
const OTHER_CODE = "NOT_LISTED"

export interface ICollectDisabilitiesSettings extends IDefaultChatFlowSettingsCollectDisabilities {
  options?: ISelectableExtended[] | undefined
  messages?: IDefaultChatFlowMessagesCollectDisabilities
}

interface State extends BaseScriptState {
  disabilityOther?: string
}

export type CollectDisabilitiesScriptState = State

export class CollectDisabilitiesScript extends BaseScript<State> {
  readonly name: string = "CollectDisabilitiesScript"
  protected disabilities: ISelectableExtended[] | undefined
  protected messages: IDefaultChatFlowMessagesCollectDisabilities | undefined
  protected shouldAskOtherDetails?: boolean
  protected shouldAskDisabilityStatus?: boolean
  protected multiSelectDisabilities?: boolean

  constructor(settings: ICollectDisabilitiesSettings | undefined = {}) {
    super()
    this.disabilities = settings?.choicesMap ?? []
    this.messages = settings?.messages ?? {}
    this.shouldAskOtherDetails = settings?.shouldAskOtherDetails ?? false
    this.shouldAskDisabilityStatus = settings?.shouldAskDisabilityStatus ?? false
    this.multiSelectDisabilities = settings?.multiSelectDisabilities ?? false
  }

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    return {
      nextStep: this.shouldAskDisabilityStatus ? this.askDisabilityStatus : this.askDisability
    }
  }

  @step.logState
  askDisabilityStatus(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messages?.askDisabilityStatus ?? "Do you have a disability?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askDisabilityStatus"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askDisability"
      },
      nextStep: this.handleDisabilityStatus
    }
  }

  @step.logStateAndResponse
  async handleDisabilityStatus(d: IStepData<State, boolean>): Promise<IStepResult> {
    this.setPeople({ disabilityStatus: d.response })
    d.state.disabilityStatus = d.response

    return {
      nextStep: d.response //
        ? this.askDisability
        : this.end
    }
  }

  @step.logState
  async askDisability(d: IStepData<State>): Promise<IStepResult> {
    const disabilities = this.disabilities
    if (!disabilities?.length) {
      this.logBreadcrumb("DISABILITIES NOT FOUND", d.state, { disabilities })
      this.logMessage("DISABILITIES NOT FOUND")
      return { nextStep: this.end }
    }

    if (d.state.disabilityStatus === false) {
      // prettier-ignore
      this.logBreadcrumb("DISABILITIES SKIPPED BECAUSE DISABILITY STATUS IS ALREADY SET TO FALSE", d.state, { disabilities })
      this.logMessage("DISABILITIES SKIPPED BECAUSE DISABILITY STATUS IS ALREADY SET TO FALSE")
      return { nextStep: this.end }
    }

    let body: IStepResult["body"] =
      d.state.disabilityStatus == null //
        ? ["Do you have a disability?"]
        : ["Okay, please specify"]

    body = this.messages?.askDisability ?? body
    const choices: ISelectableExtended[] = []
    disabilities.forEach(item => {
      if (
        item.body === "None" ||
        item.body === "No disability" ||
        item.body === "No perceived difficulty"
      ) {
        choices.push({
          ...item,
          selectIndividually: true
        })
      } else {
        choices.push(item)
      }
    })
    return {
      body: this.t(body, this.getContext(d.state)),
      prompt: {
        id: this.getPromptId("askDisability"),
        trackResponse: true,
        type: this.multiSelectDisabilities ? "inlinePickerMultiSelect" : "inlinePicker",
        choices: choices.map(d => ({ ...d, body: this.t(d.body) })),
        dataPointsName: "askDisability"
      },
      nextStep: this.handleDisability
    }
  }

  @step.logStateAndResponse
  async handleDisability(d: IStepData<State, string | string[]>): Promise<IStepResult> {
    let hasOther = false

    if (typeof d.response === "string") {
      hasOther = [OTHER_VALUE, OTHER_CODE].includes(d.response)
      this.setPeople({ disability: d.response })
      d.state.disability = d.response
    } else {
      // prettier-ignore
      hasOther = !!d.response?.find(disability => [OTHER_VALUE, OTHER_CODE].includes(disability))
      this.setPeople({
        disability: d.response?.length === 1 ? d.response[0] : d.response.join(", ")
      })
      this.setPeople({ disabilities: d.response })
      d.state.disabilities = d.response
    }

    if (hasOther && this.shouldAskOtherDetails) {
      return { nextStep: this.askDisabilityOther }
    }

    return { nextStep: this.end }
  }

  @step.logState
  askDisabilityOther(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messages?.askDisabilityOther ?? ['I see you selected "Other"', "Please specify"],
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askDisabilityOther"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handleDisabilityOtherWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.disabilityOther = d.response
  })
  @step.checkInputForCrisis({
    getNextStep: (s: CollectDisabilitiesScript) => s.end
  })
  handleDisabilityOtherWithCrisis(_d: IStepData<State, string>): IStepResult {
    return {
      nextStep: this.end
    }
  }
}

export default class CollectDisabilitiesDialogue extends AdHocDialogue<
  State,
  CollectDisabilitiesScript
> {
  static id = DialogueIDs.CollectDisabilities
  readonly name: string = "CollectDisabilitiesDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: ICollectDisabilitiesSettings
  ) {
    super(
      CollectDisabilitiesDialogue.id,
      new CollectDisabilitiesScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
