import BaseScript, { BaseScriptState } from "../../../BaseScript"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { step } from "../../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { ContactOptions } from "./Constants"
import {
  IDefaultChatFlowMessagesCollectPreferredCorrespondece,
  IDefaultChatFlowSettingsCollectPreferredCorrespondence
} from "@limbic/types"

export type ICollectPreferredCorrespondenceSettings =
  IDefaultChatFlowSettingsCollectPreferredCorrespondence & {
    options?: string[] | undefined
    messages?: IDefaultChatFlowMessagesCollectPreferredCorrespondece
  }

type State = BaseScriptState

export type CollectPreferredCorrespondenceScriptState = State

export class CollectPreferredCorrespondenceScript extends BaseScript<State> {
  readonly name: string = "CollectPreferredCorrespondenceScript"
  protected messages: IDefaultChatFlowMessagesCollectPreferredCorrespondece | undefined
  protected options?: string[]
  protected shouldDisplayTextMessageOption?: boolean

  constructor(settings: ICollectPreferredCorrespondenceSettings | undefined = {}) {
    super()
    this.messages = settings?.messages ?? {}
    this.options = settings?.options
    this.shouldDisplayTextMessageOption = settings?.shouldDisplayTextMessageOption ?? true
  }

  /** Script Steps */
  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askPreferredContactMethod }
  }

  @step.logState
  askPreferredContactMethod(d: IStepData<State>): IStepResult {
    let options = this.options ?? Object.keys(ContactOptions)
    if (!d.state.canSendEmail) {
      options = options.filter(i => i !== "E-mail")
    }
    if (!this.shouldDisplayTextMessageOption) {
      options = options.filter(i => i !== "Text message")
    }
    return {
      body: this.t(
        this.messages?.askPreferredContactMethod ??
          "And what is your preferred method for correspondence?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askPreferredContactMethod"),
        trackResponse: true,
        type: "inlinePicker",
        choices: options.map(o => ({
          body: this.t(o),
          value: this.options ? o : ContactOptions[o]
        })),
        dataPointsName: "askPreferredContactMethod"
      },
      nextStep: this.handlePreferredContactMethod
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, ContactOptions>) => {
    d.state.preferredContactMethod = d.response
  })
  handlePreferredContactMethod(_d: IStepData<State, ContactOptions>): IStepResult {
    return { nextStep: this.end }
  }
}

export default class CollectPreferredCorrespondenceDialogue extends AdHocDialogue<
  State,
  CollectPreferredCorrespondenceScript
> {
  static id = DialogueIDs.CollectPreferredCorrespondence
  readonly name: string = "CollectPreferredCorrespondenceDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: ICollectPreferredCorrespondenceSettings
  ) {
    super(
      CollectPreferredCorrespondenceDialogue.id,
      new CollectPreferredCorrespondenceScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
