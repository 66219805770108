import React from "react"
import "./BubbleButton.scss"
import classNames from "classnames"
import { useConfigStore } from "../../contexts/RootStoreContext"

interface Props {
  onPress?: (...data: any[]) => any
  disabled?: boolean
  className?: string
  containerClassName?: string
  style?: any
  children?: React.ReactChild | React.ReactChild[]
}

export default function BubbleButton(props: Props): JSX.Element {
  const { onPress, children, className, containerClassName, disabled, style } = props
  const config = useConfigStore()
  const containerCSS = classNames("lb-bubble-button-container", containerClassName, className, {
    disabled
  })

  const backgroundColor = config.userMessageBackground
  const buttonColor = style || { backgroundColor } || {}

  return (
    <div className={containerCSS} onClick={onPress} style={buttonColor}>
      {children}
    </div>
  )
}
