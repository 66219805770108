import React from "react"
import { createRoot } from "react-dom/client"
import "../app/styles/styles.scss"
import pkg from "../../package.json"
import Logger from "../utils/Logger"
import Tracker from "../utils/Tracker"
import Trackable from "../models/Trackable"
import Persistable from "../models/Persistable"
import RootStore from "../app/stores/rootStore"
import { RootStoreProvider } from "../app/contexts/RootStoreContext"
import BaseScript from "../conversation/BaseScript"
import App from "../app/App"
import CrisisDetector from "../models/CrisisDetector"
import { getBrowserLanguage } from "../utils/language"
import invariant from "../utils/invariant"
import { apiKeysMap } from "../config/apiKeysMap"
import customFields from "../config/customFields"
import validateCustomFields from "../utils/validateCustomFields"
import { Translator } from "../i18n/Translator"
import type IAppLaunchConfig from "../models/IAppLaunchConfig"
import type { WindowWithLimbicNameSpace } from "./index"
import { initDashboardConfig } from "./initDashboardConfig"
import { setCrisisDetectionConfig } from "@limbic/crisis-detection"
import SnippetInjector from "../app/components/SnippetInjector/SnippetInjector"

declare let window: WindowWithLimbicNameSpace

const botConfigVersion = process.env.REACT_APP_BOT_VERSION ?? "draft"
const mixPanelAPIKey = process.env.REACT_APP_MIXPANEL_API_KEY ?? ""
const customInitsEnabled = process.env.REACT_APP_CUSTOM_INITS === "enabled"

export const dashboardInits: Array<keyof typeof apiKeysMap> = [
  "TESTING_SERVICE_PLAYWRIGHT",
  "BRADFORD",
  "LIVING_WELL_CONSORTIUM",
  "CHESHIRE_AND_WIRRAL",
  "CHESHIRE_AND_WIRRAL_FULLSCREEN",
  "OXFORD",
  "OXFORD_FULLSCREEN",
  "BUCKINGHAMSHIRE",
  "BUCKINGHAMSHIRE_FULLSCREEN",
  "DORKING",
  "DORKING_FULLSCREEN",
  "SABP_V2",
  "SABP_V2_FULLSCREEN",
  "BEXLEY",
  "BEXLEY_FULLSCREEN"
]

export function init(setupConfig: IAppLaunchConfig): void {
  console.log("==> Initialising Limbic Web Bot...")
  if (!window.LimbicChatbot.initialized) {
    const key = setupConfig.API_KEY
    invariant(key, "no API key found")

    // setup singletons
    Persistable.setStorage(sessionStorage)
    CrisisDetector.setStorage(localStorage)
    RootStore.getInstance()
    BaseScript.setRootStore(RootStore.getInstance())
    BaseScript.setTranslator(Translator.getInstance())
    setCrisisDetectionConfig({ nativeEnabled: false, remoteEnabled: false })

    const crisisDetector = CrisisDetector.getInstance()
    const translatorSingleton = Translator.getInstance()
    RootStore.ApplicationStore.setTranslator(translatorSingleton)

    // `withCustomInitCheck` is needed to break the loop between
    // the custom initializer that will end up using `init` again
    // but with the same API_KEY.
    const withCustomInitCheck = setupConfig.withCustomInitCheck ?? true

    if (!(key in apiKeysMap) && withCustomInitCheck) {
      invariant(customInitsEnabled, "Custom inits are not enabled")
      void initDashboardConfig(setupConfig)
      return
    }

    // if the passed key is not for a custom init, we don't care about
    // custom inits being enabled or not, but if it is, we need to make
    // sure custom inits are enabled.
    if (dashboardInits.includes(key)) {
      if (withCustomInitCheck) {
        invariant(customInitsEnabled, "Custom inits are not enabled")
        void initDashboardConfig(setupConfig)
        return
      }
    }

    window.LimbicChatbot.initialized = true
    const config = { ...apiKeysMap[key], ...(setupConfig.overrides || {}) }
    const fullScreen = !!config.fullscreen

    try {
      if (mixPanelAPIKey) Tracker.setAPIKey(mixPanelAPIKey)
      Tracker.setup({ BOT_API_KEY: key })
      const trackerSingleton = Tracker.getInstance()
      const urlParams = new URLSearchParams(window.location.search)
      trackerSingleton.register({ build: pkg.version })
      trackerSingleton.setPeople({
        lastBuildUsed: pkg.version,
        browserLanguage: getBrowserLanguage(),
        ...config,
        source: urlParams ? urlParams.get("source") : "",
        url: window.location.href,
        fullScreen,
        botConfigVersion
      })
      trackerSingleton.register(config)
      trackerSingleton.register({ botConfigVersion })
      Trackable.setTracker(trackerSingleton)
    } catch (e) {
      console.log(`init: ${e.message}`)
      Logger.getInstance().exception(e, "init -> Trackers setup")
    }

    try {
      RootStore.ConfigStore.setConfig(key, config)
      RootStore.DiscussionStore.setDiscussionStepsOrder(config.discussionStepsOrder)
      RootStore.DiscussionStore.setDialoguesMap(config.dialoguesMap)
      RootStore.ClinicalStore.setDefaultPaths(config.defaultClinicalPaths)
      RootStore.ClinicalStore.setCustomPaths(config.customClinicalPaths)
      RootStore.ClinicalStore.setComplexComorbidPath(config.complexComorbidPath)
      RootStore.ClinicalStore.setUndeterminedPath(config.undeterminedPath)
      RootStore.ReferralStore.setSkipOnlineReferral(config.skipOnlineReferral ?? false)
      if (config.backendInstanceID) RootStore.ReferralStore.setInstanceID(config.backendInstanceID)

      const allFields = customFields[key]?.allFields ?? []
      const requiredFields = customFields[key]?.requiredFields ?? []
      validateCustomFields(allFields, requiredFields, setupConfig.customFields)
      if (setupConfig.customFields) {
        Object.entries(setupConfig.customFields) //
          .forEach(([key, value]) => RootStore.ReferralStore.setCustomField(key, value))
      }
      const urlParams = getURLParams()
      RootStore.ReferralStore.setCustomField("urlParams", urlParams)

      translatorSingleton.events.languageChanged.addListener((lang: string) => {
        void crisisDetector
          .setCrisisDetectionLanguage(lang)
          .catch(e =>
            Logger.getInstance().exception(e, "init -> set crisis detection language listener")
          )
      })
      translatorSingleton.setLanguage(config.defaultLanguage)
      translatorSingleton.setTranslations(config.translations)
    } catch (e) {
      Logger.getInstance().exception(e, "init -> RootStore setup")
    }

    const el = getMountingPoint()
    const root = createRoot(el)
    root.render(
      <React.StrictMode>
        <RootStoreProvider>
          <>
            <SnippetInjector snippets={config?.advanced?.snippet || []} />
            <App />
          </>
        </RootStoreProvider>
      </React.StrictMode>
    )
  }
}

function getMountingPoint(): Element {
  const el = document.querySelector("#limbic-chat")
  if (!el) {
    const newEl = document.createElement("div")
    newEl.setAttribute("id", "limbic-chat")
    document.body.appendChild(newEl)
    return newEl
  }
  return el
}

function getURLParams(): Record<string, any> {
  try {
    const params = new URLSearchParams(window.location.search)
    const result: Record<string, any> = {}
    params.forEach((value, key) => (result[key] = value))
    return result
  } catch {
    return {}
  }
}
