import invariant from "../../../utils/invariant"
import Logger from "../../../utils/Logger"
import delay from "../../../utils/delay"
import { IDataPoints } from "@limbic/types"
import client from "./_client"

interface Response {
  id: string
  signupCode: string
}

const TOTAL_RETRIES = 2

export default async function postDataPoints(
  id: { patientID?: string; signupCode?: string }, // Always one OR the other
  age: number,
  dataPoints: IDataPoints[],
  retry = 0
): Promise<Response | undefined> {
  invariant(id, "Cannot post data without an id")
  const body = { ...id, age: Math.round(age), data: dataPoints }
  try {
    return await client.post("/v1/data/send", body)
  } catch (e) {
    Logger.getInstance().exception(e, "postDataPoints failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`postDataPoints body for retry ${retry}`, JSON.stringify(body))
      Logger.getInstance().message("postDataPoints retry")
      await delay(1)
      return await postDataPoints(id, age, dataPoints, retry + 1)
    }
    throw e
  }
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}
