import Dialogue, { IDialogueSnapshot } from "../../backend/chatbot/Dialogue"
import { step } from "../../backend/chatbot/decorators/step"
import BaseScript from "../BaseScript"
import { DialogueIDs } from "../DialogueIDs"
import type { SurveyScriptState } from "../createSurveyDialogue"
import type { IStepData, IStepResult } from "../../backend/chatbot/models/IStep"
import type { IPersistableSurveyResponse, ISurveyResponse } from "../../models/ISurvey"

type State = SurveyScriptState

class ITQ extends BaseScript<State> {
  readonly name: string = "ITQ"

  /** Script Steps */

  @step
  start(_d: IStepData<State>): IStepResult<State> {
    this.timeEvent(`${this.name} Finished`)
    return {
      body: this.t([
        "I'm going to show you a number of problems that people sometimes report in response to traumatic or stressful life events",
        "Please choose a response to indicate how much you have been bothered by that problem in the past month"
      ]),
      nextStep: this.step1
    }
  }

  @step
  end(d: IStepData<State>): IStepResult {
    this.track(`${this.name} Finished`)
    return super.end(d)
  }

  @step
  step1(_d: IStepData<State>): IStepResult {
    const q =
      "1. Having upsetting dreams that replay part of the experience or are clearly related to the experience?"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step1"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          this.getQuestionChoice(q, "Not at all", 0),
          this.getQuestionChoice(q, "A little bit", 1),
          this.getQuestionChoice(q, "Moderately", 2),
          this.getQuestionChoice(q, "Quite a bit", 3),
          this.getQuestionChoice(q, "Extremely", 4)
        ],
        dataPointsName: "ITQ - step1"
      },
      nextStep: this.handleStep1
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("1", "itqResponses")
  handleStep1(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.055)
    return { nextStep: this.step4 }
  }

  @step
  step2(_d: IStepData<State>): IStepResult {
    const q =
      "2. Having powerful images or memories that sometimes come into your mind in which you feel the experience is happening again in the here and now?"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step2"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          this.getQuestionChoice(q, "Not at all", 0),
          this.getQuestionChoice(q, "A little bit", 1),
          this.getQuestionChoice(q, "Moderately", 2),
          this.getQuestionChoice(q, "Quite a bit", 3),
          this.getQuestionChoice(q, "Extremely", 4)
        ],
        dataPointsName: "ITQ - step2"
      },
      nextStep: this.handleStep2
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("2", "itqResponses")
  handleStep2(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.11)
    return { nextStep: this.step3 }
  }

  @step
  step3(_d: IStepData<State>): IStepResult {
    const q =
      "3. Avoiding internal reminders of the experience (for example, thoughts, feelings, or physical sensations)?"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step3"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          this.getQuestionChoice(q, "Not at all", 0),
          this.getQuestionChoice(q, "A little bit", 1),
          this.getQuestionChoice(q, "Moderately", 2),
          this.getQuestionChoice(q, "Quite a bit", 3),
          this.getQuestionChoice(q, "Extremely", 4)
        ],
        dataPointsName: "ITQ - step3"
      },
      nextStep: this.handleStep3
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("3", "itqResponses")
  handleStep3(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.165)
    return { nextStep: this.step4 }
  }

  @step
  step4(_d: IStepData<State>): IStepResult {
    const q =
      "4. Avoiding external reminders of the experience (for example, people, places, conversations, objects, activities, or situations)?"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step4"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          this.getQuestionChoice(q, "Not at all", 0),
          this.getQuestionChoice(q, "A little bit", 1),
          this.getQuestionChoice(q, "Moderately", 2),
          this.getQuestionChoice(q, "Quite a bit", 3),
          this.getQuestionChoice(q, "Extremely", 4)
        ],
        dataPointsName: "ITQ - step4"
      },
      nextStep: this.handleStep4
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("4", "itqResponses")
  handleStep4(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.22)
    return { nextStep: this.step5 }
  }

  @step
  step5(_d: IStepData<State>): IStepResult {
    const q = "5. Being “super-alert”, watchful, or on guard?"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step5"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          this.getQuestionChoice(q, "Not at all", 0),
          this.getQuestionChoice(q, "A little bit", 1),
          this.getQuestionChoice(q, "Moderately", 2),
          this.getQuestionChoice(q, "Quite a bit", 3),
          this.getQuestionChoice(q, "Extremely", 4)
        ],
        dataPointsName: "ITQ - step5"
      },
      nextStep: this.handleStep5
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("5", "itqResponses")
  handleStep5(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.275)
    return { nextStep: this.step8 }
  }

  @step
  step6(_d: IStepData<State>): IStepResult {
    const q = "6. Feeling jumpy or easily startled?"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step6"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          this.getQuestionChoice(q, "Not at all", 0),
          this.getQuestionChoice(q, "A little bit", 1),
          this.getQuestionChoice(q, "Moderately", 2),
          this.getQuestionChoice(q, "Quite a bit", 3),
          this.getQuestionChoice(q, "Extremely", 4)
        ],
        dataPointsName: "ITQ - step6"
      },
      nextStep: this.handleStep6
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("6", "itqResponses")
  handleStep6(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.33)
    return { nextStep: this.step7 }
  }

  @step.logState
  step7(_d: IStepData<State>): IStepResult {
    const q = "7. Affected your relationships or social life?"
    return {
      body: this.t(["In the past month have the above problems:", q]),
      prompt: {
        id: this.getPromptId("step7"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          this.getQuestionChoice(q, "Not at all", 0),
          this.getQuestionChoice(q, "A little bit", 1),
          this.getQuestionChoice(q, "Moderately", 2),
          this.getQuestionChoice(q, "Quite a bit", 3),
          this.getQuestionChoice(q, "Extremely", 4)
        ],
        dataPointsName: "ITQ - step7"
      },
      nextStep: this.handleStep7
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("7", "itqResponses")
  handleStep7(_d: IStepData<State, string>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.385)
    return { nextStep: this.step8 }
  }

  @step.logState
  step8(_d: IStepData<State>): IStepResult {
    const q = "8. Affected your work or ability to work?"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step8"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          this.getQuestionChoice(q, "Not at all", 0),
          this.getQuestionChoice(q, "A little bit", 1),
          this.getQuestionChoice(q, "Moderately", 2),
          this.getQuestionChoice(q, "Quite a bit", 3),
          this.getQuestionChoice(q, "Extremely", 4)
        ],
        dataPointsName: "ITQ - step8"
      },
      nextStep: this.handleStep8
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("8", "itqResponses")
  handleStep8(_d: IStepData<State, string>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.44)
    return { nextStep: this.step9 }
  }

  @step.logState
  step9(_d: IStepData<State>): IStepResult {
    const q =
      "9. Affected any other important part of your life such as parenting, or school or college work, or other important activities?"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step9"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          this.getQuestionChoice(q, "Not at all", 0),
          this.getQuestionChoice(q, "A little bit", 1),
          this.getQuestionChoice(q, "Moderately", 2),
          this.getQuestionChoice(q, "Quite a bit", 3),
          this.getQuestionChoice(q, "Extremely", 4)
        ],
        dataPointsName: "ITQ - step9"
      },
      nextStep: this.handleStep9
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("9", "itqResponses")
  handleStep9(_d: IStepData<State, string>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.495)
    return { nextStep: this.goToSection3 }
  }

  @step
  goToSection3(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Below are problems that people who have had stressful or traumatic events sometimes experience",
        "The questions refer to ways you <b>typically</b> feel, ways you <b>typically</b> think about yourself and ways you <b>typically</b> relate to others. Answer the following thinking about how true each statement is of you",
        "This section is completely optional"
      ]),
      prompt: {
        id: this.getPromptId("goToSection3"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Go on"), value: true },
          { body: this.t("No thanks, I'll skip"), value: false }
        ]
      },
      nextStep: this.handleGoToSection3
    }
  }

  @step
  handleGoToSection3(d: IStepData<State, string>): IStepResult {
    if (d.response) {
      return { body: this.t("How true are the following for you?"), nextStep: this.step10 }
    }
    this.rootStore.applicationStore.setCurrentProgress(1)
    return {
      nextStep: this.end
    }
  }

  @step.logState
  step10(_d: IStepData<State>): IStepResult {
    const q = "10. When I am upset, it takes me a long time to calm down"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step10"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          this.getQuestionChoice(q, "Not at all", 0),
          this.getQuestionChoice(q, "A little bit", 1),
          this.getQuestionChoice(q, "Moderately", 2),
          this.getQuestionChoice(q, "Quite a bit", 3),
          this.getQuestionChoice(q, "Extremely", 4)
        ],
        dataPointsName: "ITQ - step10"
      },
      nextStep: this.handleStep10
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("10", "itqResponses")
  handleStep10(_d: IStepData<State, string>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.55)
    return { nextStep: this.step11 }
  }

  @step.logState
  step11(_d: IStepData<State>): IStepResult {
    const q = "11. I feel numb or emotionally shut down"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step11"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          this.getQuestionChoice(q, "Not at all", 0),
          this.getQuestionChoice(q, "A little bit", 1),
          this.getQuestionChoice(q, "Moderately", 2),
          this.getQuestionChoice(q, "Quite a bit", 3),
          this.getQuestionChoice(q, "Extremely", 4)
        ],
        dataPointsName: "ITQ - step11"
      },
      nextStep: this.handleStep11
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("11", "itqResponses")
  handleStep11(_d: IStepData<State, string>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.605)
    return { nextStep: this.step12 }
  }

  @step.logState
  step12(_d: IStepData<State>): IStepResult {
    const q = "12. I feel like a failure"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step12"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          this.getQuestionChoice(q, "Not at all", 0),
          this.getQuestionChoice(q, "A little bit", 1),
          this.getQuestionChoice(q, "Moderately", 2),
          this.getQuestionChoice(q, "Quite a bit", 3),
          this.getQuestionChoice(q, "Extremely", 4)
        ],
        dataPointsName: "ITQ - step12"
      },
      nextStep: this.handleStep12
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("12", "itqResponses")
  handleStep12(_d: IStepData<State, string>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.66)
    return { nextStep: this.step13 }
  }

  @step.logState
  step13(_d: IStepData<State>): IStepResult {
    const q = "13. I feel worthless"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step13"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          this.getQuestionChoice(q, "Not at all", 0),
          this.getQuestionChoice(q, "A little bit", 1),
          this.getQuestionChoice(q, "Moderately", 2),
          this.getQuestionChoice(q, "Quite a bit", 3),
          this.getQuestionChoice(q, "Extremely", 4)
        ],
        dataPointsName: "ITQ - step13"
      },
      nextStep: this.handleStep13
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("13", "itqResponses")
  handleStep13(_d: IStepData<State, string>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.715)
    return { nextStep: this.step14 }
  }

  @step.logState
  step14(_d: IStepData<State>): IStepResult {
    const q = "14. I feel distant or cut off from people"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step14"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          this.getQuestionChoice(q, "Not at all", 0),
          this.getQuestionChoice(q, "A little bit", 1),
          this.getQuestionChoice(q, "Moderately", 2),
          this.getQuestionChoice(q, "Quite a bit", 3),
          this.getQuestionChoice(q, "Extremely", 4)
        ],
        dataPointsName: "ITQ - step14"
      },
      nextStep: this.handleStep14
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("14", "itqResponses")
  handleStep14(_d: IStepData<State, string>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.77)
    return { nextStep: this.step15 }
  }

  @step.logState
  step15(_d: IStepData<State>): IStepResult {
    const q = "15. I find it hard to stay emotionally close to people"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step15"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          this.getQuestionChoice(q, "Not at all", 0),
          this.getQuestionChoice(q, "A little bit", 1),
          this.getQuestionChoice(q, "Moderately", 2),
          this.getQuestionChoice(q, "Quite a bit", 3),
          this.getQuestionChoice(q, "Extremely", 4)
        ],
        dataPointsName: "ITQ - step15"
      },
      nextStep: this.handleStep15
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("15", "itqResponses")
  handleStep15(_d: IStepData<State, string>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.825)
    return { nextStep: this.step16 }
  }

  @step.logState
  step16(_d: IStepData<State>): IStepResult {
    const q = "16. Created concern or distress about your relationships or social life?"
    return {
      body: this.t([
        "In the past month, have the above problems in emotions, in beliefs about yourself and in relationships:",
        q
      ]),
      prompt: {
        id: this.getPromptId("step16"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          this.getQuestionChoice(q, "Not at all", 0),
          this.getQuestionChoice(q, "A little bit", 1),
          this.getQuestionChoice(q, "Moderately", 2),
          this.getQuestionChoice(q, "Quite a bit", 3),
          this.getQuestionChoice(q, "Extremely", 4)
        ],
        dataPointsName: "ITQ - step16"
      },
      nextStep: this.handleStep16
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("16", "itqResponses")
  handleStep16(_d: IStepData<State, string>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.88)
    return { nextStep: this.step17 }
  }

  @step.logState
  step17(_d: IStepData<State>): IStepResult {
    const q = "17. Affected your work or ability to work?"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step17"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          this.getQuestionChoice(q, "Not at all", 0),
          this.getQuestionChoice(q, "A little bit", 1),
          this.getQuestionChoice(q, "Moderately", 2),
          this.getQuestionChoice(q, "Quite a bit", 3),
          this.getQuestionChoice(q, "Extremely", 4)
        ],
        dataPointsName: "ITQ - step17"
      },
      nextStep: this.handleStep17
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("17", "itqResponses")
  handleStep17(_d: IStepData<State, string>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.935)
    return { nextStep: this.step18 }
  }

  @step.logState
  step18(_d: IStepData<State>): IStepResult {
    const q =
      "18. Affected any other important parts of your life such as parenting, or school or college work, or other important activities?"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step18"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          this.getQuestionChoice(q, "Not at all", 0),
          this.getQuestionChoice(q, "A little bit", 1),
          this.getQuestionChoice(q, "Moderately", 2),
          this.getQuestionChoice(q, "Quite a bit", 3),
          this.getQuestionChoice(q, "Extremely", 4)
        ],
        dataPointsName: "ITQ - step18"
      },
      nextStep: this.handleStep18
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("18", "itqResponses")
  handleStep18(_d: IStepData<State, string>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(1)
    return { nextStep: this.end }
  }

  /** Generic Handlers */

  saveResponse<T extends IPersistableSurveyResponse>(item: T, state: State): void {
    super.saveResponse(item, state, "itqResponses")
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getQuestionChoice(title: string, answer: string, points: number) {
    return {
      body: this.t(answer),
      value: {
        title,
        answer,
        points
      }
    }
  }
}

export default class ITQDialogue extends Dialogue<State> {
  static id = DialogueIDs.ITQ
  readonly name: string = "ITQDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(ITQDialogue.id, new ITQ(), state, snapshot)
  }
}
