import { AssessmentPitchIAPTScript } from "./AssessmentPitchIAPTDialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { AssessmentPitchIAPTScriptState } from "./AssessmentPitchIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"

type State = AssessmentPitchIAPTScriptState

export class AssessmentPitchMindScript extends AssessmentPitchIAPTScript {
  readonly name: string = "AssessmentPitchMindScript"

  /** Script Steps */

  @step.logState
  checkForNeedToBeCalled(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askWannaDoQuestionnaires }
  }

  @step.logState
  askWannaDoQuestionnaires(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "If you want to give us more information on how you experience your mental health, please continue the conversation",
        "Your referral has been made so don't worry if you aren't able to continue. If you don't want to continue the chat, you can press the 'x' button in the top right-hand corner to close Limbic. Your referral has still been made",
        "I'm now going to ask you a few questions to measure symptoms of common mental health issues",
        "Please note that these results will be read by the practitioner before your session. They are not monitored for an immediate response"
      ]),
      prompt: {
        id: this.getPromptId("askWannaDoQuestionnaires"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleWannaDoQuestionnaires
    }
  }

  @step.logState
  handleWannaDoQuestionnaires(d: IStepData<State, "okay">): IStepResult {
    this.referralStore.setIdleSubmissionActive(true)
    return {
      body: "Alright, let's see what we can do",
      nextStep: this.end
    }
  }
}

/* istanbul ignore next */
export default class AssessmentPitchMindDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentPitchMind
  readonly name: string = "AssessmentPitchMindDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentPitchMindDialogue.id, new AssessmentPitchMindScript(), state, snapshot)
  }
}
