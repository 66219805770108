import Logger from "../../../utils/Logger"
import delay from "../../../utils/delay"
import isOnline from "is-online"
import { EligibilityReason, VitalityEligibilityStatus } from "../../../models/IVitalityEligibility"
import client from "./_client"

const TOTAL_RETRIES = 3

export async function checkEligibilityVitality(
  entityId: number,
  retry = 0
): Promise<
  [{ eligible: boolean; reason: EligibilityReason } | undefined, VitalityEligibilityStatus]
> {
  const body = { instanceID: "VITALITY_MAIN", memberNumber: entityId }
  try {
    const hasConnection = await isOnline()
    if (!hasConnection) return [undefined, VitalityEligibilityStatus.NoInternetConnection]

    const data = await client.post("/v1/vitality/eligibility/check", body)
    return [data, VitalityEligibilityStatus.Success]
  } catch (e) {
    Logger.getInstance().exception(e, "checkEligibilityVitality fetch failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`checkEligibilityVitality body for retry ${retry}`, JSON.stringify(body))
      Logger.getInstance().message("checkEligibilityVitality retry")
      await delay(1)
      return await checkEligibilityVitality(entityId, retry + 1)
    }
    return [undefined, VitalityEligibilityStatus.RequestFailed]
  }
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}
