import moment from "moment"
import { z, ZodSchema } from "zod"
import {
  GENDER_INSIGHT,
  ETHNICITY_INSIGHT,
  ARMED_FORCES_INSIGHT,
  DISABILITY_INSIGHT,
  RELIGION_INSIGHT,
  InsightEmploymentStatus,
  InsightEmploymentBenefits,
  InsightEmploymentSupport,
  IDefaultChatFlowSettingsCheckCovidAndDetails,
  GenderBirthAssigned
} from "@limbic/types"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import SelfReferralIAPTScript, {
  SelfReferralIAPTScriptState,
  SelfReferralIAPTScriptStateSchema
} from "./SelfReferralIAPTScript"
import invariant from "../../../utils/invariant"
import { isValidLandlineNumber, isValidMobilePhone } from "../../../utils/isValidPhoneNumber"
import type {
  LANGUAGE_INSIGHT,
  CIVIL_STATUS_INSIGHT,
  LIMBIC_IMPACT_LEVEL,
  LTC_INSIGHT,
  ReferralPayloadInsight,
  SEXUALITY_INSIGHT
} from "@limbic/types"
import type { IStep, IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"
import { IAPTIDs } from "../../../models/IIAPTService"
import {
  disabilities,
  ethnicities,
  exArmedForces,
  genders,
  languages,
  ltcs,
  maritalStatuses,
  religions,
  sameGenderAsBirth,
  sexualities,
  employmentSupportsType
} from "../../../config/referralForms/insight-form"
import { toLowerCaseProperties } from "../../../utils/object"
import dialoguesRegistry from "../../dialoguesRegistry"
import {
  CollectMainIssueScriptState,
  ICollectMainIssueSettings
} from "../ad-hoc/CollectMainIssue/CollectMainIssueDialogue"
import {
  CollectGenderScriptState,
  ICollectGenderSettings
} from "../ad-hoc/CollectGender/CollectGenderDialogue"
import { CollectSexualityScriptState } from "../ad-hoc/CollectSexuality/CollectSexualityDialogue"
import { ICollectDisabilitiesSettings } from "../ad-hoc/CollectDisabilities/CollectDisabilitiesDialogue"
import { CollectLanguageAndInterpreterScriptState } from "../ad-hoc/CollectLanguageAndInterpreter/CollectLanguageAndInterpreterDialogue"
import { ICollectReligionSettings } from "../ad-hoc/CollectReligion/CollectReligionDialogue"
import { ICollectEmailSettings } from "../ad-hoc/CollectEmail/CollectEmailDialogue"
import { parsePhoneNumber } from "awesome-phonenumber"

interface State extends SelfReferralIAPTScriptState {
  asylumSeeker?: boolean
  childUnderEighteen?: boolean
  hasLongCovid?: boolean
  isReferralForLongCovid?: boolean
  benefits?: InsightEmploymentBenefits
  employmentStatus?: InsightEmploymentStatus
  employmentSupport?: InsightEmploymentSupport
  employmentAdvisorAccepted?: boolean | undefined
  maritalStatus?: string
  moneyWorries?: boolean
  involvedInDisaster?: string[]
  perinatal?: string[]
  numberOfPreviousIaptTreatments?: "Never" | "Once" | "Twice" | "Three or more times"
  receivedPreviousIaptTreatment?: boolean
  receivingStatutorySickPay?: boolean
  keyWorker?: boolean
  gpConsent1?: boolean
  gpConsent2?: boolean
  genderOther?: string
  sexualityOther?: string
}
export type SelfReferralInsightState = State

export const SelfReferralInsightStateSchema = SelfReferralIAPTScriptStateSchema.extend({
  asylumSeeker: z.boolean().optional(),
  childUnderEighteen: z.boolean().optional(),
  disabilities: z.array(z.string()).optional(),
  hasLongCovid: z.boolean().optional(),
  isReferralForLongCovid: z.boolean().optional(),
  benefits: z
    .union([
      z.literal("JSA"),
      z.literal("ESA"),
      z.literal("PIP"),
      z.literal("Incapacity Benefit"),
      z.literal("Universal Credit"),
      z.literal("None")
    ])
    .optional(),
  employmentStatus: z
    .union([
      z.literal("Employed full time"),
      z.literal("Employed part time"),
      z.literal("Employed but signed off from work"),
      z.literal("Unemployed"),
      z.literal("Full time student"),
      z.literal("Retired"),
      z.literal("Full time homemaker or carer"),
      z.literal("Long term sick or disabled"),
      z.literal("Unpaid voluntary work"),
      z.literal("Not receiving benefits")
    ])
    .optional(),
  employmentSupport: z
    .union([
      z.literal("Yes - Returning to work"),
      z.literal("Yes - Staying in work"),
      z.literal("Yes - Career support"),
      z.literal("Yes - Finding employment"),
      z.literal("No employment support needed")
    ])
    .optional(),
  employmentAdvisorAccepted: z.boolean().optional(),
  maritalStatus: z.string().optional(),
  moneyWorries: z.boolean().optional(),
  involvedInDisaster: z
    .array(
      z.union([
        z.literal("none"),
        z.literal("Manchester Bombings"),
        z.literal("Hillsborough"),
        z.literal("New Ferry Blast")
      ])
    )
    .optional(),
  perinatal: z.array(z.string()).optional(),
  numberOfPreviousIaptTreatments: z
    .union([
      z.literal("Never"),
      z.literal("Once"),
      z.literal("Twice"),
      z.literal("Three or more times")
    ])
    .optional(),
  receivedPreviousIaptTreatment: z.boolean().optional(),
  receivingStatutorySickPay: z.boolean().optional(),
  keyWorker: z.boolean().optional(),
  gpConsent1: z.boolean().optional(),
  gpConsent2: z.boolean().optional(),
  genderOther: z.string().optional(),
  sexualityOther: z.string().optional()
})

export class SelfReferralInsightScript extends SelfReferralIAPTScript {
  readonly name: string = "SelfReferralInsightScript"

  /** Script Steps */

  @step
  startSelfReferralPart1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.goToCollectMainIssue }
  }

  @step
  askWannaDoSelfReferral(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Ok, I just need some more details from you",
        "This should take around 10 minutes",
        "The first section collects personal information, the second section is around some of your symptoms"
      ]),
      prompt: {
        id: this.getPromptId("askWannaDoSelfReferral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Sure") }, { body: this.t("Okay") }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.startSelfReferralPart1
    }
  }

  @step.logState
  askPerinatalStatus(d: IStepData<State>): IStepResult {
    const perinatals = this.getPerinatals(d.state)
    if (!perinatals?.length) {
      this.logBreadcrumb("PERINATAL STATUSES NOT FOUND", d.state, { perinatals })
      this.logMessage("PERINATAL STATUSES NOT FOUND")
      return { nextStep: this.askChildUnderEighteen }
    }

    return {
      body: this.t("Which of the following apply to you?"),
      prompt: {
        id: this.getPromptId("askPerinatalStatus"),
        trackResponse: true,
        type: "inlinePickerMultiSelect",
        choices: [
          {
            body: this.t("None apply"),
            value: "none",
            backgroundColor: "#8C96D4FF",
            selectIndividually: true
          },
          ...perinatals.map(g => ({ body: this.t(g), value: g }))
        ],
        dataPointsName: "askPerinatalStatus"
      },
      nextStep: this.handlePerinatalStatus
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, string[] | "none">, script: SelfReferralInsightScript) => {
      if (d.response === "none") return
      d.state.perinatal = d.response
      script.referralStore.setCustomField<State>("perinatal", d.response)
      script.setPeople({ perinatal: d.response })
    }
  )
  handlePerinatalStatus(_d: IStepData<State, string[]>): IStepResult {
    return { nextStep: this.askChildUnderEighteen }
  }

  @step.logState
  askChildUnderEighteen(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Do you have children under the age of 18 living in your household?"),
      prompt: {
        id: this.getPromptId("askChildUnderEighteen"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleChildUnderEighteen
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralInsightScript) => {
    d.state.childUnderEighteen = d.response
    script.referralStore.setCustomField<State>("childUnderEighteen", d.response)
  })
  handleChildUnderEighteen(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askAsylumSeeker }
  }

  @step.logState
  askAsylumSeeker(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Are you an asylum seeker?"),
      prompt: {
        id: this.getPromptId("askAsylumSeeker"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleAsylumSeeker
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralInsightScript) => {
    d.state.asylumSeeker = d.response
    script.referralStore.setCustomField<State>("asylumSeeker", d.response)
  })
  handleAsylumSeeker(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.goToCollectLanguageAndInterpreter }
  }

  @step.logState
  askMaritalStatus(d: IStepData<State>): IStepResult {
    const maritalStatuses = this.getMaritalStatuses(d.state)

    if (!maritalStatuses?.length) {
      this.logBreadcrumb("MARITAL STATUSES NOT FOUND", d.state, { maritalStatuses })
      this.logMessage("MARITAL STATUSES NOT FOUND")
      return { nextStep: this.goToCollectReligion }
    }

    return {
      body: this.t("What is your current relationship status?"),
      prompt: {
        id: this.getPromptId("askMaritalStatus"),
        type: "inlinePicker",
        choices: maritalStatuses.map(g => ({ body: this.t(g), value: g })),
        dataPointsName: "askMaritalStatus"
      },
      nextStep: this.handleMaritalStatus
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralInsightScript) => {
    d.state.maritalStatus = d.response
    script.referralStore.setCustomField<State>("maritalStatus", d.response)
  })
  handleMaritalStatus(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.goToCollectReligion }
  }

  @step.logState
  askEmploymentStatus(d: IStepData<State>): IStepResult {
    const employmentStatuses = this.getEmploymentStatuses(d.state)
    if (!employmentStatuses?.length) {
      this.logBreadcrumb("EMPLOYMENT STATUSES NOT FOUND", d.state, { employmentStatuses })
      this.logMessage("EMPLOYMENT STATUSES NOT FOUND")
      return { nextStep: this.askBenefits }
    }

    return {
      body: this.t("What is your current employment status?"),
      prompt: {
        id: this.getPromptId("askEmploymentStatus"),
        type: "inlinePicker",
        choices: employmentStatuses.map(g => ({ body: this.t(g), value: g })),
        dataPointsName: "askEmploymentStatus"
      },
      nextStep: this.handleEmploymentStatus
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, InsightEmploymentStatus>, script: SelfReferralInsightScript) => {
      d.state.employmentStatus = d.response
      script.referralStore.setCustomField<State>("employmentStatus", d.response)
    }
  )
  handleEmploymentStatus(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askBenefits }
  }

  @step.logState
  askBenefits(d: IStepData<State>): IStepResult {
    const benefits = this.getBenefits(d.state)
    if (!benefits?.length) {
      this.logBreadcrumb("BENEFITS NOT FOUND", d.state, { benefits })
      this.logMessage("BENEFITS NOT FOUND")
      return { nextStep: this.askEmploymentSupport }
    }

    return {
      body: this.t("Are you receiving any of the following benefits?"),
      prompt: {
        id: this.getPromptId("askBenefits"),
        type: "inlinePicker",
        choices: [
          {
            body: this.t("None"),
            value: "None",
            backgroundColor: "#EC9CC8",
            selectIndividually: true
          },
          ...benefits
            .filter(m => !["None", "none"].includes(m))
            .map(m => ({ body: this.t(m), value: m }))
        ],
        dataPointsName: "askBenefits"
      },
      nextStep: this.handleBenefits
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, InsightEmploymentBenefits>, script: SelfReferralInsightScript) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (d.response !== "None") {
        d.state.benefits = d.response
        script.referralStore.setCustomField<State>("benefits", d.response)
      }
    }
  )
  handleBenefits(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askReceivingStatutorySickPay }
  }

  @step.logState
  askReceivingStatutorySickPay(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Are you currently receiving statutory sick pay?"),
      prompt: {
        id: this.getPromptId("askReceivingStatutorySickPay"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleStatutorySickPay
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralInsightScript) => {
    d.state.receivingStatutorySickPay = d.response
    script.referralStore.setCustomField<State>("receivingStatutorySickPay", d.response)
  })
  handleStatutorySickPay(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askEmploymentSupport }
  }

  @step.logState
  askEmploymentSupport(d: IStepData<State>): IStepResult {
    // In case there's an Undo, we need to reset this 👇
    d.state.employmentAdvisorAccepted = undefined
    const employmentSupports = this.getEmploymentSupports(d.state)
    if (!employmentSupports?.length) {
      this.logBreadcrumb("EMPLOYMENT SUPPORTS NOT FOUND", d.state, { employmentSupports })
      this.logMessage("EMPLOYMENT SUPPORTS NOT FOUND")
      return { nextStep: this.askMoneyWorries }
    }

    return {
      body: this.t("Would you like support with any employment related difficulties?"),
      prompt: {
        id: this.getPromptId("askEmploymentSupport"),
        type: "inlinePicker",
        choices: employmentSupports.map(g => ({ body: this.t(g), value: g })),
        dataPointsName: "askEmploymentSupport"
      },
      nextStep: this.handleEmploymentSupport
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, employmentSupportsType>, script: SelfReferralInsightScript) => {
      d.state.employmentSupport = d.response
      script.referralStore.setCustomField<State>("employmentSupport", d.response)
    }
  )
  handleEmploymentSupport(d: IStepData<State, employmentSupportsType>): IStepResult {
    const iaptID = d.state.iapt?.id
    const askPracticalEmploymentIAPTIDs = [
      IAPTIDs.INSIGHT_NOTTS,
      IAPTIDs.INSIGHT_DERBY,
      IAPTIDs.INSIGHT_MEDWAY,
      IAPTIDs.INSIGHT_KENT
    ]
    const askPracticalEmploymentQuestion = askPracticalEmploymentIAPTIDs.includes(iaptID as IAPTIDs)
    if (d.response === "No employment support needed" || !askPracticalEmploymentQuestion) {
      // If askPracticalEmploymentSupport is not asked the value should always be set to undefined
      d.state.employmentAdvisorAccepted = undefined
      return { nextStep: this.askMoneyWorries }
    }
    // 👇 should never be the case to need to check if we need to ask the practical employment question - just a fail safe
    return {
      nextStep: askPracticalEmploymentQuestion
        ? this.askPracticalEmploymentSupport
        : this.askMoneyWorries
    }
  }

  @step.logState
  askPracticalEmploymentSupport(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "We have practical employment support available from our Employment Advisory Service. Would you like to be contacted by an Employment Advisor?"
      ),
      prompt: {
        id: this.getPromptId("askPracticalEmploymentSupport"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askPracticalEmploymentSupport"
      },
      nextStep: this.handlePracticalEmploymentSupport
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralInsightScript) => {
    d.state.employmentAdvisorAccepted = d.response
    script.referralStore.setCustomField<State>("employmentAdvisorAccepted", d.response)
  })
  handlePracticalEmploymentSupport(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.askMoneyWorries }
  }

  @step.logState
  askMoneyWorries(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Do you have money worries?"),
      prompt: {
        id: this.getPromptId("askMoneyWorries"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleMoneyWorries
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralInsightScript) => {
    d.state.moneyWorries = d.response
    script.referralStore.setCustomField<State>("moneyWorries", d.response)
  })
  handleMoneyWorries(d: IStepData<State, string>): IStepResult {
    if (d.state?.iapt?.id === IAPTIDs.INSIGHT_WIRRAL) {
      return { nextStep: this.askInvolvedInDisaster }
    }
    return { nextStep: this.goToIAPTAccommodation }
  }

  @step.logState
  goToIAPTAccommodation(d: IStepData<State>): IStepResult {
    // prettier-ignore
    const IAPTAccommodationDialogue = dialoguesRegistry.get(DialogueIDs.IAPTAccommodation)
    return {
      nextDialogue: new IAPTAccommodationDialogue({ ...d.state }),
      nextStep: this.goToCollectAlcoholConsumption
    }
  }

  @step.logState
  askInvolvedInDisaster(d: IStepData<State>): IStepResult {
    const disasters = this.getDisasters(d.state)
    if (!disasters?.length) {
      this.logBreadcrumb("DISASTERS NOT FOUND", d.state, { disasters })
      this.logMessage("DISASTERS NOT FOUND")
      return { nextStep: this.goToIAPTAccommodation }
    }

    return {
      body: this.t("Have you been directly involved in any of these incidents?"),
      prompt: {
        id: this.getPromptId("askInvolvedInDisaster"),
        type: "inlinePickerMultiSelect",
        choices: [
          {
            body: this.t("None"),
            value: "none",
            backgroundColor: "#EC9CC8",
            selectIndividually: true
          },
          ...disasters.filter(m => m !== "None").map(m => ({ body: this.t(m), value: m }))
        ]
      },
      nextStep: this.handleInvolvedInDisaster
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string[]>, script: SelfReferralInsightScript) => {
    d.state.involvedInDisaster = d.response
    script.referralStore.setCustomField<State>("involvedInDisaster", d.response)
  })
  handleInvolvedInDisaster(_d: IStepData<State, string[]>): IStepResult {
    return { nextStep: this.goToIAPTAccommodation }
  }

  @step.logState
  askReceivedPreviousIaptTreatment(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Have you previously received treatment from an IAPT service?"),
      prompt: {
        id: this.getPromptId("askReceivedPreviousIaptTreatment"),
        type: "inlinePicker",
        isUndoAble: false,
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askReceivedPreviousIaptTreatment"
      },
      nextStep: this.handleReceivedPreviousIaptTreatment
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralInsightScript) => {
    d.state.receivedPreviousIaptTreatment = d.response
    script.referralStore.setCustomField<State>("receivedPreviousIaptTreatment", d.response)
  })
  handleReceivedPreviousIaptTreatment(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.askNumberOfPreviousIaptTreatments }
    }
    return { nextStep: this.askCurrentSupport }
  }

  @step.logState
  askNumberOfPreviousIaptTreatments(d: IStepData<State>): IStepResult {
    const previousIaptTreatments = this.getPreviousIaptTreatments(d.state)
    if (!previousIaptTreatments?.length) {
      this.logBreadcrumb("PREVIOUS IAPT TREATMENTS NOT FOUND", d.state, { previousIaptTreatments })
      this.logMessage("PREVIOUS IAPT TREATMENTS NOT FOUND")
      return { nextStep: this.askCurrentSupport }
    }

    return {
      body: this.t("How many times have you accessed an IAPT service in the past?"),
      prompt: {
        id: this.getPromptId("askNumberOfPreviousIaptTreatments"),
        type: "inlinePicker",
        choices: previousIaptTreatments
          .filter(m => m !== "None")
          .map(m => ({ body: this.t(m), value: m }))
      },
      nextStep: this.handleNumberOfPreviousIaptTreatments
    }
  }

  @step.logState
  @step.handleResponse(
    (
      d: IStepData<State, "Never" | "Once" | "Twice" | "Three or more times">,
      script: SelfReferralInsightScript
    ) => {
      d.state.numberOfPreviousIaptTreatments = d.response
      script.referralStore.setCustomField<State>("numberOfPreviousIaptTreatments", d.response)
    }
  )
  handleNumberOfPreviousIaptTreatments(d: IStepData<State, string>): IStepResult {
    this.updateReferralType(d.state)
    return { nextStep: this.askCurrentSupport }
  }

  @step.logState
  askKeyWorker(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Do you work for the NHS or the care system?"),
      prompt: {
        id: this.getPromptId("askKeyWorker"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleAskKeyWorker
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralInsightScript) => {
    d.state.keyWorker = d.response
    script.referralStore.setCustomField<State>("keyWorker", d.response)
  })
  handleAskKeyWorker(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.askGPConsent1 }
  }

  @step.logState
  askGPConsent1(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "As part of our process in Everyturn Mental Health, we share assessment outcomes and treatment summaries with your GP",
        "Are you happy for us to share these summaries with your GP?"
      ]),
      prompt: {
        id: this.getPromptId("askGPConsent1"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleGPConsent1
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralInsightScript) => {
    d.state.gpConsent1 = d.response
    script.referralStore.setCustomField<State>("gpConsent1", d.response)
  })
  handleGPConsent1(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.askGPConsent2 }
    }
    return {
      body: this.t(
        "You selected that you don’t want the clinical summaries shared with you GP, please be aware that in the event of an emergency or issues with risk or safeguarding we would contact your GP for their support"
      ),
      nextStep: this.askGPConsent2
    }
  }

  @step.logState
  askGPConsent2(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Would you like to receive a copy of the summaries that are written after your assessment and after treatment?"
      ),
      prompt: {
        id: this.getPromptId("askGPConsent2"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleGPConsent2
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralInsightScript) => {
    d.state.gpConsent2 = d.response
    script.referralStore.setCustomField<State>("gpConsent2", d.response)
  })
  handleGPConsent2(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.goToCollectGoalForTherapy }
  }

  @step.logState
  sayReferralSucceeded(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    return {
      body: this.t(["Good news, {name}", "Your referral to {iaptName} is nearly ready to go ✉️"], {
        name,
        iaptName
      }),
      prompt: {
        id: this.getPromptId("sayReferralSucceeded"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Great"), value: false },
          { body: this.t("Good to know"), value: false }
        ]
      },
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return SelfReferralInsightStateSchema
  }

  getDisabilities(state: State): string[] {
    return super.getDisabilities(state)
  }

  getPerinatals(state: State): string[] {
    const statuses = ["I am pregnant", "New parent"]
    // TODO: 👇 this needs to include all kent services when we figure them out
    const isKent = state.iapt?.id === "2917833"
    statuses.push(isKent ? "I have a child under 5" : "I have a child under 2")
    return statuses
  }

  getMaritalStatuses(state: State): string[] {
    return state.iapt?.referralForm?.maritalStatuses ?? []
  }

  getEmploymentStatuses(state: State): InsightEmploymentStatus[] {
    return state.iapt?.referralForm?.employmentStatuses ?? ([] as any[])
  }

  getBenefits(state: State): InsightEmploymentBenefits[] {
    return state.iapt?.referralForm?.benefits ?? ([] as any[])
  }

  getEmploymentSupports(state: State): InsightEmploymentSupport[] {
    return state.iapt?.referralForm?.employmentSupports ?? ([] as any[])
  }

  getDisasters(state: State): string[] {
    return state.iapt?.referralForm?.disasters ?? []
  }

  getPreviousIaptTreatments(state: State): string[] {
    return state.iapt?.referralForm?.previousIaptTreatments ?? []
  }

  async getCollectEmailSettings(state: State): Promise<ICollectEmailSettings> {
    return {
      messages: {
        askDoYouWantToShareEmail: this.t(["Do you have an email address?"]),
        askEmailPermission: this.t(["Do you allow us to contact you over email?"])
      },
      shouldAskEmailPermission: true,
      hasPreliminaryQuestion: true,
      customisableOptions: {
        askDoYouWantToShareEmail: [
          { body: this.t("Yes, I have an email"), value: true },
          { body: this.t("No, I don't have an email"), value: false }
        ]
      }
    }
  }

  async onCollectEmailEnded(state: State): Promise<IStep> {
    return this.goToCheckPostCodeForAddressLookup
  }

  async getCollectMainIssueState(state: State): Promise<CollectMainIssueScriptState> {
    return { hideClosingMessage: true }
  }

  async getCollectMainIssueSettings(state: State): Promise<ICollectMainIssueSettings> {
    const name = this.getName(state)
    return {
      messages: {
        askMainIssue: this.t(
          [
            "So {name}, please could you describe the main concern or problem that brought you here today (be sure to include specific feelings, behaviours, or thoughts that are bothering you)"
          ],
          { name }
        )
      }
    }
  }

  async onCollectMainIssueEnded(state: State): Promise<IStep> {
    return this.goToCollectPhoneNumber
  }

  async getCollectGenderSettings(state: State): Promise<ICollectGenderSettings> {
    const genders = this.getGenders(state)
    const gendersSameAsBirth = this.getGenderSameAsBirthValues(state)

    return {
      messages: {
        askSameGenderAsBirth: this.t([
          "Is your gender the same as the sex you were registered at birth?"
        ])
      },
      optionsGender: genders.map(g => ({ body: this.t(g), value: g })),
      optionsGenderSameAsBirth: gendersSameAsBirth.map(g => ({ body: this.t(g), value: g }))
    }
  }

  async getCollectGenderState(state: State): Promise<CollectGenderScriptState> {
    return {
      skipSameGenderAsBirth: false,
      withTextPrompt: true
    }
  }

  async onCollectGenderEnded(state: State): Promise<IStep> {
    return this.askPerinatalStatus
  }

  async getCollectLanguageAndInterpreterState(
    state: State
  ): Promise<CollectLanguageAndInterpreterScriptState> {
    return { skipInterpreterLanguageQuestion: true }
  }

  async onCollectLanguageAndInterpreterEnded(state: State): Promise<IStep> {
    return this.goToCollectDisabilities
  }

  async getCollectDisabilitiesSettings(state: State): Promise<ICollectDisabilitiesSettings> {
    return {
      choicesMap: this.getDisabilities(state).map(d => ({ body: this.t(d), value: d })),
      shouldAskDisabilityStatus: true,
      multiSelectDisabilities: true
    }
  }

  async onCollectDisabilitiesEnded(state: State): Promise<IStep> {
    return this.goToCollectASD
  }

  async getCollectSexualityState(state: State): Promise<CollectSexualityScriptState> {
    return { withTextPrompt: true }
  }

  async onCollectSexualityEnded(state: State): Promise<IStep> {
    return this.askMaritalStatus
  }

  async getCollectReligionSettings(state: State): Promise<ICollectReligionSettings> {
    const optionsReligions = this.getReligions(state).map(g => ({ body: g, value: g }))

    return {
      options: optionsReligions
    }
  }

  async onCollectReligionEnded(_state: State): Promise<IStep> {
    return this.goToCollectLongTermMedicalConditionDetails
  }

  async onCollectLongTermMedicalConditionEnded(state: State): Promise<IStep> {
    return this.goToCheckCovidAndDetails
  }

  async getCheckCovidAndDetailsSettings(
    state: State
  ): Promise<IDefaultChatFlowSettingsCheckCovidAndDetails> {
    return {
      askHasLongCovid: true,
      askIsReferralForLongCovid: true
    }
  }

  async onCheckCovidDetailsEnded(state: State): Promise<IStep> {
    return this.askEmploymentStatus
  }

  async onHandleSubstances(state: State): Promise<IStepResult<State>> {
    if (state.substances) {
      return { nextStep: this.askSubstancesOrigin }
    }
    return { nextStep: this.askReceivedPreviousIaptTreatment }
  }

  async onHandleMedicationWithinDoseRange(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askReceivedPreviousIaptTreatment }
  }

  async onHandleSubstancesInfoWithCrisis(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askReceivedPreviousIaptTreatment }
  }

  async onHandleCurrentSupport(state: State): Promise<IStepResult | void> {
    return { nextStep: this.askKeyWorker }
  }

  async getReferralPayload(state: State): Promise<ReferralPayloadInsight> {
    const instanceID = state.iapt?.backendInstanceID
    invariant(instanceID, "Cannot create referral without an Instance ID")
    const isValidMobile = isValidMobilePhone(state.phoneNumber || "0")
    const isValidLandline = isValidLandlineNumber(state.phoneNumber || "0") && !isValidMobile
    const parsed = parsePhoneNumber(state.phoneNumber || "0", { regionCode: "GB" })

    return {
      instanceID,
      nameFirst: state.spineName?.firstName ?? this.getName(state),
      nameLast: state.spineName?.lastName ?? this.getLastName(state),
      nhsNumber: state.nhsNumber,
      whereHeardAboutUs: state.whereDidYouHearAboutService || undefined,
      dob: moment(state.birthday).format("YYYY-MM-DD"),
      addressHome: {
        address1: state.address,
        address2: state.address2,
        // If address is entered manually then city/county/postcode are undefined
        // Pass an alternate value to avoid errors in the referral submission
        city: state.city || "unknown",
        county: state.county || "unknown",
        postcode: state.userPostcode?.postcode || state.invalidPostcodeEntered || "unknown",
        consentMail: !!state.canSendMailToAddress
      },
      phoneHome: isValidLandline
        ? {
            cc: String(parsed.countryCode || ""),
            number: parsed.number?.national.replace(/ /g, "") ?? state.phoneNumber!,
            isMobile: false,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      phoneMobile: isValidMobile
        ? {
            cc: String(parsed.countryCode || ""),
            number: parsed.number?.national.replace(/ /g, "") ?? state.phoneNumber!,
            isMobile: true,
            consentSMS: !!state.canSendTextMessagesToPhoneNumber,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      email: state.email,
      consentEmail: state.canSendEmail ?? false,
      consentDataShare: true,
      consentDataStore: true,
      output: this.referralStore.referralType,
      riskLevel: this.clinicalStore.riskLevel,
      riskLevelReason: this.clinicalStore.riskLevelReason,
      triggerWords: this.clinicalStore.triggerWords,
      title: state.title,
      alcohol: state.alcohol,
      alcoholFrequency: state.alcoholFrequency,
      alcoholQuantity: state.alcoholQuantity,
      substances: state.substances,
      substancesAreMedication: state.substancesAreMedications,
      substancesInfo:
        !state.substancesAreMedications && state.substancesInfo
          ? [state.substancesInfo]
          : undefined,
      medication:
        state.substancesAreMedications && state.medicationInfo //
          ? [state.medicationInfo]
          : undefined,
      medicationWithinDosage: state.substancesAreMedications
        ? !!state.medicationWithinDoseRange
        : undefined,
      ltc: this.getLTC(state),
      ltcAffectMood: state.ltcAffectsMood,
      ltcMoodImpact: this.getLTCMoodImpact(state),
      ltcManagement: this.getLTCManagement(state),
      covidStatus: state.covidStatus,
      covidDate: state.covidDate,
      longCovid: !!this.referralStore.getCustomField<State>("hasLongCovid"),
      longCovidReferral: !!this.referralStore.getCustomField<State>("isReferralForLongCovid"),
      employmentStatus: state.employmentStatus,
      benefits: state.benefits,
      employmentSupport: state.employmentSupport,
      employmentAdvisorAccepted: state.employmentAdvisorAccepted,
      // prettier-ignore
      receivingStatutorySickPay: !!this.referralStore.getCustomField<State>("receivingStatutorySickPay"),
      moneyWorries: !!this.referralStore.getCustomField<State>("moneyWorries"),
      asylumSeeker: !!this.referralStore.getCustomField<State>("asylumSeeker"),
      disasterInvolvement: this.referralStore.getCustomField<State>(
        "involvedInDisaster"
      ) as ReferralPayloadInsight["disasterInvolvement"],
      language: this.getLanguage(state),
      interpreter:
        state.spineInterpreterRequired ??
        !!this.referralStore.getCustomField<State>("requiresInterpreter"),
      receivedPreviousIaptTreatment: !!this.referralStore.getCustomField<State>(
        "receivedPreviousIaptTreatment"
      ),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // prettier-ignore
      numberOfPreviousIaptTreatments: this.referralStore.getCustomField<State>("numberOfPreviousIaptTreatments"),
      civilStatus: this.getCivilStatus(state),
      ccgId: state.odsGP?.ccgs[0],
      surgeryId: state?.odsGP?.id,
      currentSupport: state.hasCurrentSupport,
      sexuality: this.getSexuality(state),
      sexualityOther: state.sexualityOther,
      gender: this.getGender(state),
      genderOther: state.genderOther,
      genderSameAsBirthAssigned: this.getGenderSameAsBirthAssigned(state),
      ethnicity: this.getEthnicity(state),
      armedForces: this.getArmedForced(state),
      disability: this.getDisability(state),
      adhd: state.hasADHD,
      asd: state.hasASD,
      religion: this.getReligion(state),
      descriptionMainDiff: this.referralStore.getCustomField<State>("mainIssue") as string,
      childrenUnder2: state.perinatal?.includes("I have a child under 2"),
      childrenUnder5: state.perinatal?.includes("I have a child under 5"),
      childrenUnder18: !!this.referralStore.getCustomField<State>("childUnderEighteen"),
      pregnant: !!state.perinatal?.includes("I am pregnant"),
      newParent: !!state.perinatal?.includes("New parent"),
      keyWorker: !!state.keyWorker,
      gpConsent1: !!state.gpConsent1,
      gpConsent2: !!state.gpConsent2,
      questionnaires: this.getQuestionnairesPayload(state),
      clinicalNotes: this.referralStore.clinicalNotes,
      consentResearch: state.consentResearch,
      source: this.getSource(),
      treatmentExpectation: state.therapyGoal
    }
  }

  getSource(): "NOTTS_TRUST" | undefined {
    const href = window.location.href
    if (href.match(/nottinghamshire/i) || href.match(/letstalkwellbeing/i)) return "NOTTS_TRUST"
  }

  getReligion(state: State): RELIGION_INSIGHT {
    return religions[state.religion!] ?? "NOT_ANSWERED"
  }

  getDisability(state: State): DISABILITY_INSIGHT[] {
    if (state.disabilityStatus === false) return ["NONE"]
    if (!state.disabilityStatus || !state.disabilities?.length) {
      this.logBreadcrumb("getDisability without answer", state)
      this.logMessage("getDisability without answer")
    }
    return state.disabilities
      ? state.disabilities?.map(disability => disabilities[disability]).filter(Boolean)
      : ["NOT_ANSWERED"]
  }

  getArmedForced(state: State): ARMED_FORCES_INSIGHT {
    return exArmedForces[state.isExArmedForces!] ?? "NOT_ANSWERED"
  }

  getEthnicity(state: State): ETHNICITY_INSIGHT {
    return ethnicities[state.ethnicity!] ?? "NOT_ANSWERED"
  }

  getGender(state: State): GENDER_INSIGHT {
    return genders[state.gender!] ?? "NOT_LISTED"
  }

  getGenderSameAsBirthAssigned(state: State): GenderBirthAssigned {
    const genderSameAsBirthAssigned = sameGenderAsBirth[state.sameGenderAsBirth!]
    return genderSameAsBirthAssigned ?? "UNKNOWN"
  }

  getSexuality(state: State): SEXUALITY_INSIGHT | undefined {
    /**
     * NOT_LISTED when the response if a free text (custom input)
     * We used to do this:
     * sexualities[state.sexuality!] || state.sexualityOther || "NOT_LISTED"
     * which was a wrong value for "sexuality", so instead, we always set it to
     * NOT_LISTED and sexualityOther is set in the payload
     * */
    return sexualities[state.sexuality!] || "NOT_LISTED"
  }

  getCivilStatus(state: State): CIVIL_STATUS_INSIGHT | undefined {
    return maritalStatuses[state.maritalStatus!]
  }

  getLanguage(state: State): LANGUAGE_INSIGHT | undefined {
    const spineLanguageKey = `${state.spineLanguage ?? ""}`.toLowerCase()
    const primaryLanguageKey = `${state.primaryLanguage ?? ""}`.toLowerCase()
    const languagesLowerCase = toLowerCaseProperties(languages)
    return languagesLowerCase[spineLanguageKey] ?? languagesLowerCase[primaryLanguageKey]
  }

  getLTC(state: State): LTC_INSIGHT[] | undefined {
    const ltc = state.longTermMedicalCondition?.map(i => ltcs[i]).filter(Boolean)
    return ltc?.length ? ltc : undefined
  }

  getLTCMoodImpact(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      somewhat: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcMoodImpact!]
  }

  getLTCManagement(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      fairly: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcManagement!]
  }

  async onRiskReferralFinished(_state: State): Promise<void> {
    // overridden on purpose to not do anything for risk referrals
  }
}

/** istanbul ignore next */
export default class SelfReferralInsightDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralInsight
  readonly name: string = "SelfReferralInsightDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(SelfReferralInsightDialogue.id, new SelfReferralInsightScript(), state, snapshot)
  }
}
