import BaseScript, { BaseScriptState } from "../../../BaseScript"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { step } from "../../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import {
  IDefaultChatFlowMessagesCollecLanguageAndInterpreter,
  ISelectableExtended
} from "@limbic/types"
import formatUnicorn from "../../../../utils/formatUnicorn"

export type ICollectLanguageAndInterpreterSettings = {
  options?: ISelectableExtended[] | undefined
  messages?: IDefaultChatFlowMessagesCollecLanguageAndInterpreter
}

interface State extends BaseScriptState {
  shouldAskAbleToCommunicateInEnglish?: boolean
  usePrimaryLanguageTextPrompt?: boolean // Applies to TrentPTS for now
  disableInterpreterLanguageQuestion?: boolean
  includeInterpreterLanguageTextPrompt?: boolean // Applies to SABP for now
  skipInterpreterLanguageQuestion?: boolean // Applies to Insight for now
}

export type CollectLanguageAndInterpreterScriptState = State

export class CollectLanguageAndInterpreterScript extends BaseScript<State> {
  readonly name: string = "CollectLanguageAndInterpreterScript"
  protected languages: ISelectableExtended[] | undefined
  protected messages: IDefaultChatFlowMessagesCollecLanguageAndInterpreter | undefined
  constructor(settings: ICollectLanguageAndInterpreterSettings | undefined = {}) {
    super()
    this.languages = settings?.options ?? []
    this.messages = settings?.messages ?? {}
  }

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askPrimaryLanguage }
  }

  @step.logState
  async askPrimaryLanguage(d: IStepData<State>): Promise<IStepResult> {
    // TODO: find a way to also account for the spine language in here
    //       the problem would be that if the language that comes from the
    //       spine is not in the list of languages that we have, then we
    //       would have to ask the user to select a language from the list
    if (d.state.primaryLanguage) {
      return { nextStep: this.end }
    }

    const languages = this.languages
    if (!languages?.length) {
      this.logBreadcrumb("LANGUAGES NOT FOUND", d.state, { languages })
      this.logMessage("LANGUAGES NOT FOUND")
      return { nextStep: this.end }
    }

    const choices = d.state.usePrimaryLanguageTextPrompt
      ? [{ body: this.t("English"), value: "English" }]
      : languages.sort((a, b) => {
          if (a.body?.toLowerCase() === "english") return -1
          if (b.body?.toLowerCase() === "english") return 1
          return 0
        })

    return {
      body: this.t(
        this.messages?.askPrimaryLanguage ?? "What is your primary spoken language?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askPrimaryLanguage"),
        trackResponse: true,
        type: "inlinePicker",
        choices,
        dataPointsName: "askPrimaryLanguage",
        textPrompt: d.state.usePrimaryLanguageTextPrompt ? { forceValue: true } : undefined
      },
      nextStep: this.handlePrimaryLanguage
    }
  }

  @step.logState
  async handlePrimaryLanguage(d: IStepData<State, string>): Promise<IStepResult> {
    d.state.primaryLanguage = d.response
    this.referralStore.setIsMainSpokenLanguageEnglish(d.response)
    if (this.referralStore.isMainSpokenLanguageEnglish) {
      return { nextStep: this.end }
    }
    const nextStep = d.state.shouldAskAbleToCommunicateInEnglish
      ? this.askAbleToCommunicateInEnglish
      : this.askRequiresAnInterpreter

    return {
      nextStep: d.state.disableInterpreterLanguageQuestion ? this.end : nextStep
    }
  }

  @step.logState
  askAbleToCommunicateInEnglish(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messages?.askAbleToCommunicateInEnglish ?? "Are you able to communicate in English?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askAbleToCommunicateInEnglish"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        isUndoAble: true,
        trackResponse: true,
        dataPointsName: "askAbleToCommunicateInEnglish"
      },
      nextStep: this.handleAbleToCommunicateInEnglish
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.ableToCommunicateInEnglish = d.response
  })
  handleAbleToCommunicateInEnglish(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.end }
    }
    return { nextStep: this.askRequiresAnInterpreter }
  }

  @step.logState
  askRequiresAnInterpreter(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messages?.askRequiresAnInterpreter ?? "Do you require an interpreter?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askRequiresAnInterpreter"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askRequiresAnInterpreter"
      },
      nextStep: this.handleRequiresInterpreter
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, boolean>, script: CollectLanguageAndInterpreterScript) => {
      d.state.requiresInterpreter = d.response
      script.referralStore.setCustomField<State>("requiresInterpreter", d.response)
    }
  )
  handleRequiresInterpreter(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return {
        nextStep: d.state.skipInterpreterLanguageQuestion ? this.end : this.askInterpreterLanguage
      }
    }
    return { nextStep: this.end }
  }

  @step.logState
  askInterpreterLanguage(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const initialPrimaryLanguage = d.state.primaryLanguage
    let primaryLanguage = d.state.primaryLanguage
    const bodyString = this.languages?.find(language => language.value === primaryLanguage)?.body
    if (bodyString) {
      primaryLanguage = bodyString
    }

    const languages = this.languages
    if (!languages?.length) {
      this.logBreadcrumb("LANGUAGES NOT FOUND", d.state, { languages })
      this.logMessage("LANGUAGES NOT FOUND")
      return { nextStep: this.end }
    }

    const choices: ISelectableExtended[] = [
      {
        body: this.t("Yes, I need an interpreter for {primaryLanguage}", {
          primaryLanguage: this.t(primaryLanguage)
        }),
        value: initialPrimaryLanguage
      }
    ]

    if (d.state.includeInterpreterLanguageTextPrompt !== true) {
      languages.filter(l => l.body !== primaryLanguage).forEach(g => choices.push(g))
    }

    let body: string | string[] = this.t(
      [
        "So {name}, you stated your main spoken language is {primaryLanguage}",
        "Is this the language you need an interpreter for?",
        "(If not, just type the language you'd like an interpreter for)"
      ],
      { name, primaryLanguage }
    )

    if (this.messages?.askInterpreterLanguage && this.messages?.askInterpreterLanguage.length) {
      body = this.messages?.askInterpreterLanguage.map(message =>
        formatUnicorn(message, {
          primaryLanguage
        })
      )
    }

    return {
      body: this.t(body, this.getContext(d.state)),
      prompt: {
        id: this.getPromptId("askInterpreterLanguage"),
        trackResponse: true,
        type: "inlinePicker",
        choices,
        dataPointsName: "askInterpreterLanguage",
        textPrompt: d.state.includeInterpreterLanguageTextPrompt
          ? { forceValue: true, placeholder: this.t("Enter language") }
          : undefined
      },
      nextStep: this.handleInterpreterLanguage
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, string>, script: CollectLanguageAndInterpreterScript) => {
      d.state.interpreterLanguage = d.response
      script.referralStore.setCustomField<State>("interpreterLanguage", d.state.interpreterLanguage)
    }
  )
  handleInterpreterLanguage(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.end }
  }
}

export default class CollectLanguageAndInterpreterDialogue extends AdHocDialogue<
  State,
  CollectLanguageAndInterpreterScript
> {
  static id = DialogueIDs.CollectLanguageAndInterpreter
  readonly name: string = "CollectLanguageAndInterpreterDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: ICollectLanguageAndInterpreterSettings
  ) {
    super(
      CollectLanguageAndInterpreterDialogue.id,
      new CollectLanguageAndInterpreterScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
