import { main } from "./main"
import { checkInputForCrisis } from "./checkInputForCrisis"
import { delayStep } from "./delayStep"
import { handleResponse } from "./handleResponse"
import { saveResponse } from "./saveResponse"
import { saveSurveyResponse } from "./saveSurveyResponse"
import { setState } from "./setState"
import { startTyping } from "./startTyping"

type MainFn = typeof main

export interface StepDecoratorsCombined extends MainFn {
  checkInputForCrisis: typeof checkInputForCrisis
  delay: typeof delayStep
  handleResponse: typeof handleResponse
  saveResponse: typeof saveResponse
  saveSurveyResponse: typeof saveSurveyResponse
  setState: typeof setState
  startTyping: typeof startTyping
}

export const step = main as StepDecoratorsCombined

step.checkInputForCrisis = checkInputForCrisis
step.delay = delayStep
step.handleResponse = handleResponse
step.saveResponse = saveResponse
step.saveSurveyResponse = saveSurveyResponse
step.setState = setState
step.startTyping = startTyping
