import {
  ARMED_FORCES_VHGNOTTS2,
  DISABILITY_VHGNOTTS2,
  ETHNICITY_VHGNOTTS2,
  GENDER_VHGNOTTS2,
  LTC_VHGNOTTS2,
  PERINATAL_VHGNOTTS2,
  SEXUALITY_VHGNOTTS2,
  GenderBirthAssigned,
  CIVIL_STATUS_VHGNOTTS2,
  LANGUAGE_VHGNOTTS2,
  RELIGION_VHGNOTTS2
} from "@limbic/types"

export const religions: Record<string, RELIGION_VHGNOTTS2> = {
  "No Religious group or secular": "NONE",
  Agnostic: "AGNOSTIC",
  "Baha'i": "BAHAI",
  Buddhist: "BUDDHIST",
  "Church of England": "CHURCH_OF_ENGLAND",
  Hindu: "HINDU",
  Jain: "JAIN",
  Jewish: "JEWISH",
  Muslim: "MUSLIM",
  "Other Protestant": "PROTESTANT",
  "Other Christian": "CHRISTIAN",
  "Orthodox Christian": "CHRISTIAN_ORTHODOX",
  "Orthodox Jewish": "JEWISH_ORTHODOX",
  Rastafarian: "RASTAFARIAN",
  "Roman Catholic": "ROMAN_CATHOLIC",
  "Shi'ite Muslim": "SHIITE_MUSLIM",
  Sikh: "SIKH",
  "Sunni Muslim": "SUNNI_MUSLIM",
  "Parsi / Zoroastrian": "ZOROASTRIAN",
  "Any other religion": "OTHER"
}

export const disabilities: Record<string, DISABILITY_VHGNOTTS2> = {
  None: "NONE",
  Hearing: "HEARING",
  "Learning Disability": "LEARNING",
  "Physical Disability": "MOTOR",
  Sight: "SIGHT",
  Speech: "SPEECH",
  Other: "NOT_LISTED"
}

export const exArmedForces: Record<string, ARMED_FORCES_VHGNOTTS2> = {
  "Yes - ex-services": "YES_EX",
  "Yes - currently serving": "YES_CURRENT",
  "Dependant on ex-serving member": "DEPENDENT",
  No: "NO",
  Unknown: "UNKNOWN",
  "Not stated (Person asked but declined to provide a response)": "NOT_ANSWERED"
}

export const ethnicities: Record<string, ETHNICITY_VHGNOTTS2> = {
  "White - British": "WHITE_BRITISH",
  "White - Irish": "WHITE_IRISH",
  "White - Any other White background": "WHITE_OTHER",
  "Mixed - White and Black Caribbean": "MIXED_WHITE_BLACK_CARIBBEAN",
  "Mixed - White and Black African": "BLACK_BRITISH_AFRICAN",
  "Mixed - White and Asian": "MIXED_WHITE_ASIAN",
  "Mixed - Any other mixed background": "MIXED_OTHER",
  "Asian or Asian British - Indian": "ASIAN_BRITISH_INDIAN",
  "Asian or Asian British - Pakistani": "ASIAN_BRITISH_PAKISTANI",
  "Asian or Asian British - Bangladeshi": "ASIAN_BRITISH_BANGLADESHI",
  "Asian or Asian British - Any other Asian background": "ASIAN_BRITISH_OTHER",
  "Black or Black British - Caribbean": "BLACK_BRITISH_CARIBBEAN",
  "Black or Black British - African": "BLACK_BRITISH_AFRICAN",
  "Black or Black British - Any other Black background": "BLACK_BRITISH_OTHER",
  "Other Ethnic Groups - Chinese": "CHINESE",
  "Other Ethnic Groups - Any other ethnic group": "OTHER",
  "Not Known": "UNKNOWN"
}

export const genders: Record<string, GENDER_VHGNOTTS2> = {
  "Male (including trans man)": "MALE",
  "Female (including trans woman)": "FEMALE",
  "Non-binary": "NON_BINARY",
  Other: "NOT_LISTED",
  "I don't want to say": "NOT_ANSWERED"
}

export const sameGenderAsBirth: Record<string, GenderBirthAssigned> = {
  Yes: "YES",
  No: "NO",
  "I don't know": "UNKNOWN",
  "I don't want to say": "NOT_ANSWERED"
}

export const sexualities: Record<string, SEXUALITY_VHGNOTTS2> = {
  "Heterosexual/straight": "HETEROSEXUAL",
  "Gay woman/lesbian": "HOMOSEXUAL_FEMALE",
  "Gay man": "HOMOSEXUAL_MALE",
  Bisexual: "BISEXUAL",
  "Prefer not to say": "NOT_ANSWERED",
  Unknown: "UNKNOWN"
}

export const maritalStatuses: Record<string, CIVIL_STATUS_VHGNOTTS2> = {
  Single: "SINGLE",
  Married: "MARRIED",
  Divorced: "DIVORCED",
  Widowed: "WIDOWED",
  "Co-habiting": "COHABITING",
  Separated: "SEPARATED",
  "Civil partnership": "CIVIL_PARTNERSHIP"
}

export const languages: Record<string, LANGUAGE_VHGNOTTS2> = {
  English: "ENGLISH",
  Albanian: "ALBANIAN",
  Arabic: "ARABIC",
  Bengali: "BENGALI",
  "British Sign Language": "BRITISH_SIGN_LANGUAGE",
  Bulgarian: "BULGARIAN",
  Cantonese: "CANTONESE",
  Czech: "CZECH",
  "Dari - Afghan Persion": "DARI_AFGHAN_PERSIAN",
  French: "FRENCH",
  German: "GERMAN",
  Greek: "GREEK",
  Gujerati: "GUJARATI",
  Hindi: "HINDI",
  Hungarian: "HUNGARIAN",
  Italian: "ITALIAN",
  "Kurdish - Kurmanji": "KURDISH_KURMANJI",
  "Kurdish - Sorani": "KURDISH_SORANI",
  Latvian: "LATVIAN",
  Lithuanian: "LITHUANIAN",
  Mandarin: "MANDARIN",
  Mirpuri: "MIRPURI",
  Nepalese: "NEPALESE",
  Oromo: "OROMO",
  Pashto: "PASHTO",
  "Persian/Farsi": "PERSIAN_FARSI",
  Polish: "POLISH",
  Portuguese: "PORTUGUESE",
  Punjabi: "PUNJABI",
  Romanian: "ROMANIAN",
  Russian: "RUSSIAN",
  Sinahlese: "SINHALA",
  Slovak: "SLOVAK",
  Somali: "SOMALI",
  Spanish: "SPANISH",
  Swedish: "SWEDISH",
  "Tagalog/Filipino": "TAGALOG_FILIPINO",
  Tamil: "TAMIL",
  Thai: "THAI",
  Tigrinya: "TIGRINYA",
  Turkish: "TURKISH",
  Urdu: "URDU",
  Vietnamese: "VIETNAMESE"
}

export const ltcs: Record<string, LTC_VHGNOTTS2> = {
  Asthma: "ASTHMA",
  Cancer: "CANCER",
  "Chronic Pain including Fibromyalgia": "CHRONIC_PAIN",
  COPD: "COPD",
  Diabetes: "DIABETES",
  "Digestive Tract Conditions": "DIGESTIVE",
  Epilepsy: "EPILEPSY",
  "Heart Disease": "HEART_DISEASE",
  "Irritable Bowel Syndrome": "IBS",
  "Musculoskeletal Disorder": "MUSCULOSKELETAL",
  "Other Respiratory Disease": "RESPIRATORY",
  "Skin Condition including Eczema": "SKIN"
}

export const perinatalStatuses: Record<string, PERINATAL_VHGNOTTS2> = {
  "None apply": "NONE",
  "I am pregnant": "PREGNANT",
  "New parent": "NEW_PARENT",
  "I have a child under 2": "CHILD_UNDER_2",
  "I have a child under 5": "CHILD_UNDER_2"
}

export type employmentSupportsType =
  | "Yes - Returning to work"
  | "Yes - Staying in work"
  | "Yes - Career support"
  | "Yes - Finding employment"
  | "No employment support needed"

export const employmentSupports: employmentSupportsType[] = [
  "Yes - Returning to work",
  "Yes - Staying in work",
  "Yes - Career support",
  "Yes - Finding employment",
  "No employment support needed"
]

const form = {
  form_url: "https://external-referrals.iaptus.co.uk/api/D3kJ8d6BwPlKZg9W/self-refer",
  filled_by: "API",
  perinatalStatuses: Object.keys(perinatalStatuses),
  sexualities: Object.keys(sexualities),
  genders: Object.keys(genders),
  sameGenderAsBirth: Object.keys(sameGenderAsBirth),
  ethnicities: Object.keys(ethnicities),
  exArmedForces: Object.keys(exArmedForces),
  disabilities: Object.keys(disabilities),
  LTCs: Object.keys(ltcs),
  languages: Object.keys(languages),
  maritalStatuses: Object.keys(maritalStatuses),
  religions: Object.keys(religions),
  // No mapping takes place for employmentStatus - state.employmentStatus is used
  employmentStatuses: [
    "Employed full time",
    "Employed part time",
    "Employed but signed off from work",
    "Unemployed",
    "Full time student",
    "Retired",
    "Full time homemaker or carer",
    "Long term sick or disabled",
    "Unpaid voluntary work"
  ],
  // No mapping takes place for employmentSupports - state.employmentSupports is used
  employmentSupports,
  // No mapping takes place for previousIaptTreatments - state.previousIaptTreatments is used
  previousIaptTreatments: ["Never", "Once", "Twice", "Three or more times"],
  // No mapping takes place for benefits - state.benefits is used
  benefits: ["JSA", "ESA", "PIP", "Incapacity Benefit", "Universal Credit", "None"],
  // No mapping takes place for disasters - state.disasters is used
  disasters: ["None", "Manchester Bombings", "Hillsborough", "New Ferry Blast"]
}

export default form
