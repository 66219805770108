import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import GoodbyeScript from "./GoodbyeScript"
import type { GoodbyeScriptState } from "./GoodbyeScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult, StepResultBodyType } from "../../../backend/chatbot/models/IStep"

type State = GoodbyeScriptState

export type GoodbyeBexleyScriptState = State
export class GoodbyeBexleyScript extends GoodbyeScript {
  readonly name: string = "GoodbyeBexleyScript"

  /** Script Steps */

  @step
  async sayGoodBye(d: IStepData<State>): Promise<IStepResult> {
    void this.referralStore.updateReferral({
      isHelpful: d.state.isHelpful,
      improvementSuggestion: d.state.improvementSuggestion
    })
    this.referralStore.stopPinging()

    return {
      body: ["Well done for taking this important step towards better mental health"],
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onHandleRecapMessage(
    _state: State,
    body: StepResultBodyType[],
    recapMessage: string | void
  ): Promise<IStepResult | void> {
    const isCrisis = this.clinicalStore.isCrisis
    if (!body.length) {
      return { nextStep: isCrisis ? this.end : this.askFeedback }
    }

    const choices: any[] = [{ body: "Okay" }, recapMessage && { body: "I understand" }] //
      .filter(Boolean)

    return {
      body,
      prompt: {
        id: this.getPromptId("recap"),
        trackResponse: true,
        type: "inlinePicker",
        choices
      },
      nextStep: this.askFeedback
    }
  }

  async getAdminWillCallMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted && !this.clinicalStore.isRisk) {
      return "Someone from the team will be in contact within 7 working days to discuss next steps"
    }
  }

  async getHighRiskContactMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted && this.clinicalStore.isHighRisk) {
      return (
        "Because you identified as potentially experiencing high levels of distress, or potentially at risk someone from our team will contact you as soon as possible\n\n" +
        "Please remember, Limbic is not an emergency response service. If you need urgent help you are encouraged to contact 999 or NHS 111 and select Option 2\n\n" +
        "You can also contact the Samaritans on 116123 to talk  through how you are feeling\n\n" +
        "Urgent Advice Line at Oxleas is: 0800 330 8590\n\n" +
        "The Bexley Crisis Café is open from 6pm-10pm Monday-Sunday including Bank Holidays, please see link for further information [https://mindinbexley.org.uk/crisis-cafe/](https://mindinbexley.org.uk/crisis-cafe/)"
      )
    }
  }

  async getModerateRiskContactMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted && this.clinicalStore.isModerateRisk) {
      return (
        "Because you identified as potentially experiencing high levels of distress, or potentially at risk someone from our team will contact you as soon as possible\n\n" +
        "Please remember, Limbic is not an emergency response service. If you need urgent help you are encouraged to contact 999 or NHS 111 and select Option 2\n\n" +
        "You can also contact the Samaritans on 116123 to talk  through how you are feeling\n\n" +
        "Urgent Advice Line at Oxleas is: 0800 330 8590\n\n" +
        "The Bexley Crisis Café is open from 6pm-10pm Monday-Sunday including Bank Holidays, please see link for further information [https://mindinbexley.org.uk/crisis-cafe/](https://mindinbexley.org.uk/crisis-cafe/)"
      )
    }
  }
}

/* istanbul ignore next */
export default class GoodbyeBexleyDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeBexley
  readonly name: string = "GoodbyeBexleyDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeBexleyDialogue.id, new GoodbyeBexleyScript(), state, snapshot)
  }
}
