import React from "react"
import { observer } from "mobx-react"
import "./Sidebar.scss"
import "./SidebarEssex.scss"
import { useConfigStore } from "../../contexts/RootStoreContext"

export interface Props {
  visible?: boolean
}

function Sidebar(props: Props): JSX.Element | null {
  const config = useConfigStore()
  const { visible } = props
  if (!visible) {
    return null
  }

  return (
    <div className="lb-sidebar-container">
      <article className="lb-sidebar-card">
        <div className="lb-header">
          <img src={config.logo} className="lb-header-img" alt="" />
          <h3 className="subtitle">
            Refer yourself to NHS West and Central Cheshire Talking Therapies
          </h3>
        </div>
        <p>
          Welcome to NHS Cheshire West and Central Cheshire Talking Therapies. We are a free and
          confidential service available to anyone aged 16 and over, registered with a GP in
          Central, South and West Cheshire. Our service exclusively offers evidence-based,
          short-term talking therapies designed to help with common mental health concerns, such as:
          <ul>
            <li>Low Mood</li>
            <li>Anxiety or worry</li>
            <li>Stress</li>
            <li>Depression</li>
          </ul>
        </p>
        <p>
          If you feel that your referral is something you would like to discuss with the team,
          please call us on 0300 303 0639 if you live in Central Cheshire or 0124 439 7761 if you
          live in West Cheshire or Chester to speak to someone from our team.
        </p>
        <p>
          If you'd like more information please visit{" "}
          <a href="https://www.cwp.nhs.uk/" target="_blank noreferrer">
            our website
          </a>
        </p>
        {config.iaptIcons?.map?.(link => (
          <img key={link} className="nhs-logo" src={link} alt={link} />
        ))}
      </article>
      <div className="lb-sidebar-spacer" />
      <article className="lb-sidebar-card">
        <p>
          Limbic helps people access psychological support quicker and easier. Your data is secured
          according to the highest NHS standards.
        </p>
        <img
          className="badges"
          src="https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/data-pics.png"
          alt={"Badges"}
        />
      </article>
    </div>
  )
}

export default observer(Sidebar)
