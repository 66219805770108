import invariant from "../../../utils/invariant"
import { IReferralDoc } from "@limbic/types"
import Logger from "../../../utils/Logger"
import delay from "../../../utils/delay"
import client from "./_client"

const TOTAL_RETRIES = 2

export default async function getReferral(
  _id: string,
  retry = 0
): Promise<IReferralDoc | undefined> {
  invariant(_id, "Cannot get referral without an id")
  try {
    return await client.get(`/referral/show/${_id}`)
  } catch (e) {
    Logger.getInstance().exception(e, "createReferral fetch failed")
    if (retry < TOTAL_RETRIES) {
      Logger.getInstance().message("updateReferral retry")
      await delay(1)
      return await getReferral(_id, retry + 1)
    }
    throw e
  }
}
