import { IAPTGPMap, IAPTIDs } from "../../models/IIAPTService"

// GP List here 👇
// https://linear.app/limbic/issue/ENG2-1057/demo-updates-for-sabp

const iaptSABP_v2_GPs: IAPTGPMap = {
  H81017: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81071: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81663: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81006: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81013: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81026: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81068: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81109: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81045: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81033: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81022: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81015: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81073: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81067: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81641: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81052: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81042: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81029: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81051: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81028: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81638: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81103: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81116: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81099: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81016: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81064: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81057: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81644: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81078: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81024: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81076: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81066: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81132: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81010: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81062: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81055: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81032: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81070: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81061: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81048: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81122: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81133: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81002: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81113: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81023: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81038: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81080: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81034: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81643: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81072: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81035: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81618: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81658: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81011: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81632: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81107: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81056: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81111: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81036: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81129: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81005: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81094: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81007: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81656: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81123: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81004: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81077: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81041: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81091: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81044: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81087: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81025: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81085: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81074: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81134: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81104: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81613: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81009: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81003: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81019: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81081: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81126: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81020: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81672: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81065: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81084: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81030: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81021: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81086: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81642: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81053: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81128: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81089: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81119: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81046: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81050: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81131: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81031: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81043: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81090: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81058: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81095: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81082: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81075: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81620: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81069: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81622: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81130: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81039: IAPTIDs.MIND_MATTERS_SAB_V2,
  H81040: IAPTIDs.MIND_MATTERS_SAB_V2
}

export const iaptGPMapSABP_v2: IAPTGPMap = {
  ...iaptSABP_v2_GPs
}
