import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { RiskLevelReason } from "../../../models/Constants"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = RiskPathwayScriptState

export type RiskPathwayDorkingScriptState = State

export class RiskPathwayDorkingScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayDorkingScript"

  /** Script Steps */
  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askCanYouKeepYourselfSafe }
  }

  @step.logState
  askCanYouKeepYourselfSafe(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Are you able to keep yourself and dependents in your care safe until your next appointment?",
        "This could be up to 10 days, although likely to be sooner"
      ],
      prompt: {
        id: this.getPromptId("askCanYouKeepYourselfSafe"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askCanYouKeepYourselfSafe"
      },
      nextStep: this.handleCanYouKeepYourselfSafe
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.saveResponse<State>(
    "10",
    "Are you able to keep yourself and dependents in your care safe until your next appointment? This could be up to 10 days, although likely to be sooner",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  @step.handleResponse((d: IStepData<State, boolean>, script: RiskPathwayDorkingScript) => {
    d.state.canKeepSelfSafe = d.response
    script.referralStore.setCustomField<State>("canKeepSelfSafe", d.response)
  })
  handleCanYouKeepYourselfSafe(d: IStepData<State>): IStepResult {
    if (!d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.CANNOT_KEEP_SELF_SAFE)
    }
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  async sayCrisis(d: IStepData<State>): Promise<IStepResult> {
    const name = this.getName(d.state)
    const serviceName = this.rootStore.configStore.serviceName

    return {
      body: [
        `Thank you for sharing this information ${name}`,
        `However ${serviceName} is not an emergency response service`,
        "If you need urgent support, please dial NHS 111 and select Option 2"
      ],
      nextStep: this.sayCrisisNumbers
    }
  }

  @step.logState
  sayCrisisNumbers(_d: IStepData<State>): IStepResult {
    const serviceName = this.rootStore.configStore.serviceName

    return {
      body: [
        "You could also visit a local 'safe haven' for support. [Where to find your local safe haven?](https://www.sabp.nhs.uk/our-services/mental-health/safe-havens)",
        "If you are hard of hearing, have speech difficulties or prefer not to talk over the phone, you can contact the following organisations for support via text message:\n\n" +
          "NHS Mental Health Crisis Line – text service available on 07717 989 024. If you have speech or hearing difficulties, this service can be accessed by dialling through the BT Relay app or textphone: 18001 0800 915 4644 [More info here](https://www.relayuk.bt.com/)\n\n" +
          "Shout – text shout to 85258 for 24/7 free support\n\n" +
          "For immediate support if you cannot keep yourself safe text 999 if you have registered your phone, or go to A&E",
        "Other helplines available:\n\n" +
          "You can contact Samaritans 24 hours a day, 365 days a year on 116 123 (free from any phone) or email jo@samaritans.org\n\n" +
          `We’ll continue with the referral, however ${serviceName} does not provide urgent care. If you require immediate help, please contact one of the numbers listed above`
      ],
      prompt: {
        id: this.getPromptId("sayCrisisNumbers"),
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }]
      },
      nextStep: this.handleCrisisNumbers
    }
  }

  @step.logState
  handleCrisisNumbers(_d: IStepData<State>): IStepResult {
    const serviceName = this.rootStore.configStore.serviceName

    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "999, NHS 111 (Option 2), NHS (07717 989 024), Local (0800 145 6485), Samaritans (116 123), SHOUT (85258)"
    )
    return {
      body: [
        `We’ll continue with the referral, however ${serviceName} does not provide urgent care`,
        "If you require immediate help, please contact one of the numbers listed above"
      ],
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onSaveRiskLevelAndReferralType(state: State): Promise<IStepResult> {
    return { nextStep: !state.canKeepSelfSafe ? this.sayCrisis : this.end }
  }

  getKeepingSafeAttachmentUrls(_state: State): string[] {
    return [
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/dhc/Dorking+Healthcare+-+Keeping+Safe+leaflet.pdf"
    ]
  }

  createKeepingSafeLeafletEmail(title: string, logo: string): string {
    return `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" lang='en'>
<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>${title}</title>
    ${this.getEmailHTMLStyle()}
    <!--[if IEMobile 7]>
    <style type="text/css">
        /* Targeting Windows Mobile */
    </style>
    <![endif]-->
    <!--[if gte mso 9]>
    <style>
        /* Target Outlook 2007 and 2010 */
    </style>
    <![endif]-->
</head>

<body>
<table cellpadding="0" cellspacing="0" border="0" id="backgroundTable">
    <tr>
        <td>
            <table cellpadding="0" cellspacing="0" border="0" id="contentTable">
                <tr>
                    <td><img style='max-height: 200px; display: block; margin-left: auto; margin-right: auto;' class="image_fix" src="${logo}" alt="${title}" title="${title}" width="x" height="x" /><br /><br /></td>
                </tr>
                <tr>
                    <td>
                        You have made a self-referral today to DHC Talking Therapies. In one of your answers you
                        said you were experiencing some thoughts of self-harm or taking your own life.
                    </td>
                </tr>
                <tr>
                    <td>
                        <br />
                        If you are concerned about yours or someone else’s immediate safety, then call <a href="tel:999">999</a>, text
                        999 if you have registered your phone, or go to A&amp;E where there are specialist mental
                        health teams that can support you.
                    </td>
                </tr>
                <tr>
                    <td>
                        <br />
                        If you are struggling with thoughts of harming yourself or ending your life whilst you are
                        waiting to access our service, you can contact the services listed below and in the attached
                        document for further support.
                    </td>
                </tr>
                <tr>
                    <td>
                        <br />
                        <ul>
                            <li>
                                <b><u>Samaritans</u></b>: call <a href="tel:116123">116 123</a> (Open 24/7). Email: <a href="mailto:jo@samaritans.org">jo@samaritans.org</a>, download the
                                Samaritans app or drop into your local branch. For more information click <a href=" https://www.samaritans.org/how-we-can-help/contact-samaritan/">here</a>.

                            </li>
                            <br />
                            <li>
                                <b><u>NHS Mental Health Crisis Line</u></b>: call <a href="tel:08009154644">0800 915 4644</a> for 24 hour support, 365 days a
                                year from mental health professionals. Text service available on <a href="tel:07717989024">07717 989 024</a>. If you
                                have speech or hearing difficulties, this service can be accessed by dialling through the
                                BT Relay app or textphone: <a href="tel:1800108009154644">18001 0800 915 4644</a>. For more information click <a href="https://www.relayuk.bt.com/">here</a>.
                            </li>
                            <br />
                            <li>
                                <b><u>Drop into a ‘Safe haven’</u></b> for a chat or a change of scenery. Further information and
                                details on ‘virtual Safe Havens’ can be found <a href="https://www.sabp.nhs.uk/ourservices/mental-health/safe-havens">here</a>.
                                <br />
                                <br />
                                <ul>
                                    <li>
                                        <u>Epsom</u>: Brickfield Centre, Portland Place, Epsom, Surrey, KT17 1DL. Open: 365
                                        days per year: 6-11pm
                                    </li>
                                    <br />
                                    <li>
                                        <u>Guildford</u>: Oakleaf Enterprise, 101 Walnut Tree Close, Guildford, GU1 4UQ. Open:
                                        365 days per year: 6-11pm. And drop in peer and wellbeing support 6pm-8pm daily
                                    </li>
                                    <br />
                                    <li>
                                        <u>Redhill</u>: Wingfield Resource Centre, St Annes Drive, Redhill, RH1 1AU. Open: 365
                                        days per year: 6-11pm. And drop in peer and wellbeing support 6pm-8pm daily
                                    </li>
                                    <br />
                                    <li>
                                        <u>Aldershot</u>: Walpole House, Pickford Street, Aldershot GU11 1TZ. Monday- Friday: 6-
                                        11pm. Weekends and bank holidays: 12.30-11pm
                                    </li>
                                    <br />
                                    <li>
                                        <u>Woking</u>: The Prop, 30 Goldsworth Road, Woking, GU21 6JT. Open: Monday -
                                        Sunday: 6- 11pm.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>
                        <br /><br />
                        <em>DHC Talking Therapies Team</em>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
</table>
</body>
</html>`
  }
}

/* istanbul ignore next */
export default class RiskPathwayDorkingDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayDorking
  readonly name: string = "RiskPathwayDorkingDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(RiskPathwayDorkingDialogue.id, new RiskPathwayDorkingScript(), state, snapshot)
  }
}
