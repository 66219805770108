import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { UnderAgeSignpostScript, UnderAgeSignpostScriptState } from "./UnderAgeSignpostDialogue"
import { DialogueIDs } from "../../DialogueIDs"

type State = UnderAgeSignpostScriptState
export type UnderAgeSignpostOxfordScriptState = State

export class UnderAgeSignpostOxfordScript extends UnderAgeSignpostScript {
  readonly name: string = "UnderAgeSignpostOxfordScript"

  /** Script Steps */
  @step
  sayOtherResources(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName

    return {
      body: this.t(
        [
          "You might also be interested in checking out the following resources...",
          "Kooth: a counseling resource for young people (https://kooth.com)",
          "The Mix: a free and confidential service that aims to find young people the best help, whatever the problem (https://www.themix.org.uk/",
          "Childline: 0800 1111",
          "{organisationName} and the Oxfordshire Child and Adolescent Mental Health Service (CAMHS) work in partnership with each other to ensure that young people aged 16-18 yrs can access talking therapy",
          "In order to access {organisationName}, please contact the CAMHS Team on: 01865 902515. A suitable CAMHS professional will review your case and can refer on your behalf if this is the right treatment decision for you"
        ],
        { organisationName }
      ),
      prompt: {
        id: this.getPromptId("sayOtherResources"),
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }]
      },
      nextStep: this.sayCrisisCallLine
    }
  }
}

export default class UnderAgeSignpostDialogue extends Dialogue<State> {
  static id = DialogueIDs.UnderageSignpostOxford
  readonly name: string = "UnderAgeSignpostOxfordDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(UnderAgeSignpostDialogue.id, new UnderAgeSignpostOxfordScript(), state, snapshot)
  }
}
