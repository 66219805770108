import moment from "moment"
import { z, ZodSchema } from "zod"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { SelfReferralIAPTScriptState } from "./SelfReferralIAPTScript"
import SelfReferralIAPTScript, { SelfReferralIAPTScriptStateSchema } from "./SelfReferralIAPTScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import {
  GENDER_VITALITY,
  IDefaultChatFlowSettingsCheckPostCodeFromAddressLookup,
  ReferralPayloadVitality,
  DiscussionSteps
} from "@limbic/types"
import { isValidLandlineNumber, isValidMobilePhone } from "../../../utils/isValidPhoneNumber"
import invariant from "../../../utils/invariant"
import { step } from "../../../backend/chatbot/decorators/step"
import { TrackingEvents, VitalityTitles } from "../../../models/Constants"
import { getGPServicesByName } from "../../../backend/api/external/nhs"
import ISelectable from "../../../models/ISelectable"
import { IGPService } from "../../../models/IGPService"
import { IStep } from "../../../backend/chatbot/models/IStep"
import { ICollectEmailSettings } from "../ad-hoc/CollectEmail/CollectEmailDialogue"
import { parsePhoneNumber } from "awesome-phonenumber"

interface State extends SelfReferralIAPTScriptState {
  preferredTitle?: VitalityTitles
  appointment?: string
  hideEarlierYouSaid?: boolean
  birthday?: number
  claimNumber?: string
  source?: "telephone" | "memberzone"
  assessmentPreference?: "telephone" | "digital"
  gpSurgeryNameEntered?: string
  gpAddressEntered?: string
}

export type SelfReferralVitalityScriptState = State

export const SelfReferralVitalityScriptStateSchema = SelfReferralIAPTScriptStateSchema.extend({
  preferredTitle: z
    .union([z.literal("Mr"), z.literal("Ms"), z.literal("Mrs"), z.literal("Miss"), z.literal("Mx")])
    .optional(),
  appointment: z.string().optional(),
  hideEarlierYouSaid: z.boolean().optional(),
  birthday: z.number().optional(),
  claimNumber: z.string().optional(),
  source: z.union([z.literal("telephone"), z.literal("memberzone")]).optional(),
  assessmentPreference: z.union([z.literal("telephone"), z.literal("digital")]).optional(),
  gpSurgeryNameEntered: z.string().optional(),
  gpAddressEntered: z.string().optional()
})

export class SelfReferralVitalityScript extends SelfReferralIAPTScript {
  readonly name: string = "SelfReferralVitalityScript"

  /** Script Steps */

  @step.logState
  @step.setState<State>({ addressLookupCounter: 0 })
  sayIntro(_d: IStepData<State>): IStepResult {
    this.timeEvent(this.name)
    return {
      body: this.t(["I just need to collect some basic information from you", "Firstly..."]),
      nextStep: this.goToCollectGender
    }
  }

  @step.logState
  askGP(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("And what's the name of your GP"),
      prompt: {
        id: this.getPromptId("askGP"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handleGPWithCrisis
    }
  }

  @step.logState
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralVitalityScript) => s.askGPAddress
  })
  handleGPWithCrisis(d: IStepData<State, string>): IStepResult {
    d.state.gpSurgeryNameEntered = d.response
    return { nextStep: this.askSelectGPByName }
  }

  @step.logState
  async askSelectGPByName(d: IStepData<State>): Promise<IStepResult> {
    const gpServices = await getGPServicesByName(d.state.gpSurgeryNameEntered)
    if (!gpServices?.data?.length) {
      return { nextStep: this.askGPAddress }
    }

    return {
      body: this.t([
        "I've found a few GPs matching the name you typed",
        "Are you registered with any of the following? (Please select)"
      ]),
      prompt: {
        id: this.getPromptId("askSelectGPByName"),
        type: "inlinePicker",
        choices: (
          gpServices.data.map(gp => ({
            body: gp.formattedName,
            value: gp
          })) as ISelectable<any>[]
        ) //
          .concat(
            {
              body: this.t("My GP is not on this list"),
              value: "notListed",
              backgroundColor: "#EC9CC8"
            },
            { body: this.t("I'm not sure"), value: "notSure", backgroundColor: "#EC9CC8" }
          )
      },
      nextStep: this.handleSelectGPByName
    }
  }

  @step.logStateAndResponse
  handleSelectGPByName(d: IStepData<State, "notListed" | "notSure" | IGPService>): IStepResult {
    const body =
      d.response === "notListed"
        ? "My GP is not on this list"
        : d.response === "notSure"
          ? "I'm not sure"
          : "GP selected"
    this.track(TrackingEvents.SELECT_GP_BY_NAME, { body })
    if (["notListed", "notSure"].includes(d.response as string)) {
      const name = this.getName(d.state)
      return { body: this.t("Ok {name}, no worries", { name }), nextStep: this.askGPAddress }
    }
    const gp = d.response as IGPService
    d.state.gpSurgeryNameEntered = gp.formattedName || gp.name || d.state.gpSurgeryNameEntered
    if (gp.address1) {
      d.state.gpAddressEntered = gp.address2 ? gp.address1 + ", " + gp.address2 : gp.address1
      return { nextStep: this.checkNeedToAskMore }
    }
    return { nextStep: this.askGPAddress }
  }

  @step.logState
  askGPAddress(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("And what's your GP's address?"),
      prompt: {
        id: this.getPromptId("askGPAddress"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handleGPAddressWithCrisis
    }
  }

  @step.logState
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralVitalityScript) => s.checkNeedToAskMore
  })
  handleGPAddressWithCrisis(d: IStepData<State, string>): IStepResult {
    d.state.gpAddressEntered = d.response
    return { nextStep: this.checkNeedToAskMore }
  }

  @step.logState
  checkNeedToAskMore(d: IStepData<State>): IStepResult {
    this.saveCustomFieldsToState(d.state)
    const needsToAskMore = !d.state.birthday || !d.state.userPostcode?.postcode || !d.state.address
    if (needsToAskMore) return { nextStep: this.sayINeedAFewMoreDetails }
    return { nextStep: this.goToCollectGoalForTherapy }
  }

  @step.logState
  sayINeedAFewMoreDetails(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("There are just a few more details I need from you"),
      prompt: {
        id: this.getPromptId("sayINeedAFewMoreDetails"),
        type: "inlinePicker",
        choices: [{ body: this.t("Sure") }, { body: this.t("Okay") }]
      },
      nextStep: this.askBirthday
    }
  }

  @step.logState
  askBirthday(d: IStepData<State>): IStepResult {
    if (d.state.birthday) return { nextStep: this.startAddressFlow }

    return {
      body: this.t("What's your date of birth?"),
      prompt: {
        id: this.getPromptId("askBirthday"),
        trackResponse: true,
        type: "date",
        isUndoAble: true
      },
      nextStep: this.handleBirthday
    }
  }

  @step.logState
  sayPleaseGiveABirthday(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Please enter your date of birth"),
      prompt: {
        id: this.getPromptId("sayPleaseGiveABirthday"),
        trackResponse: true,
        type: "date",
        isUndoAble: true
      },
      nextStep: this.handleBirthday
    }
  }

  @step
  handleBirthday(d: IStepData<State, number>): IStepResult {
    try {
      const date = moment(d.response)
      invariant(date, "I'm sorry that's not a valid date. Please enter your date of birth")
      invariant(
        date.isValid(),
        "I'm sorry that's not a valid date. Please enter your date of birth"
      )
      invariant(
        date.isBefore(moment()),
        "Hmm… I don’t think humans can time-travel. Can you try and edit your date of birth?"
      )
      invariant(
        date.isAfter(moment("1899-12-31")),
        "Hmm… I don’t think humans live that long. Can you try and edit your date of birth?"
      )
      d.state.birthday = date.toDate().getTime()
      this.setPeople({ age: moment().diff(date, "years") })
    } catch (e) {
      this.logException(e, "handleBirthday")
      return {
        body: this.t(e.message),
        nextStep: this.sayPleaseGiveABirthday
      }
    }
    return {
      body: this.t("Thanks for sharing"),
      nextStep: this.startAddressFlow
    }
  }

  @step.logState
  startAddressFlow(d: IStepData<State>): IStepResult {
    if (!d.state.userPostcode?.postcode || !d.state.address) {
      return { nextStep: this.goToCheckPostCodeForAddressLookup }
    }
    return { nextStep: this.goToCollectGoalForTherapy }
  }

  @step.logState
  sayReferralSucceeded(d: IStepData<State>): IStepResult {
    return { nextStep: this.checkUserAge }
  }

  @step.logState
  sayReferralFailed(d: IStepData<State>): IStepResult {
    const phoneNumber = this.rootStore.configStore.organisationGenericPhoneNumber
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: this.t(
        [
          "Oops... I'm really sorry about this, but it seems like something has gone wrong when trying to submit your data",
          "I've notified my creators of this issue",
          "Please call {organisationName} on: {phoneNumber}"
        ],
        { organisationName, phoneNumber }
      ),
      prompt: {
        id: this.getPromptId("sayReferralFailed"),
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }]
      },
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  checkUserAge(d: IStepData<State>): IStepResult {
    const age = this.getUserAge(d.state)
    if (age < 18) {
      this.setUnderAged(d.state, true)
      return { nextStep: this.sayMinorSignposting }
    }
    return { nextStep: this.checkPreferredAssessment }
  }

  @step.logState
  sayMinorSignposting(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t(
        [
          "So {name}, I was designed to offer a digital assessment to people over the age of 18",
          "As you are under 18, I need to send your referral directly to IPRS Health’s specialist referral team",
          "They will give you a call in the next 1 working day to arrange your initial assessment",
          "This team are available Monday-Friday, 9am-5pm. If you have any queries in the meantime you can contact IPRS Health on 0800 2545244"
        ],
        { name }
      ),
      prompt: {
        id: this.getPromptId("sayMinorSignposting"),
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }, { body: this.t("I understand") }]
      },
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  checkPreferredAssessment(d: IStepData<State>): IStepResult {
    if (d.state.assessmentPreference === "telephone") {
      return { nextStep: this.goToBookAppointmentDialogue }
    }
    return { nextStep: this.end }
  }

  @step.logState
  goToBookAppointmentDialogue(d: IStepData<State>): IStepResult {
    const BookAppointmentDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.BookAppointment
    )
    const nextDialogue = BookAppointmentDialogue ? new BookAppointmentDialogue(d.state) : undefined
    return {
      nextDialogue,
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return SelfReferralVitalityScriptStateSchema
  }

  saveCustomFieldsToState(state: State): void {
    const dateOfBirth = this.referralStore.getCustomField("dateOfBirth") || undefined
    if (dateOfBirth) state.birthday = dateOfBirth

    // checking if they are also a string because I don't trust
    // what vitality will send via their api. They might send an
    // object while we're waiting a string and screw us up
    const address = this.referralStore.getCustomField("address") || undefined
    const postcode = this.referralStore.getCustomField("postCode") || undefined
    const hasPostcode = !!postcode && typeof postcode === "string"
    const hasAddress = !!address && typeof address === "string"
    if (hasPostcode && hasAddress) {
      // we don't care for anything else other than the postcode
      // property here, because it will only be used for address
      // lookup which only needs the actual postcode entry.
      // Of course, this is an injected postcode, which means it's
      // not a validated postcode so the address look up might fail
      state.userPostcode = { postcode, longitude: 0, latitude: 0, ccg: "", ccgId: "" }
      state.address = address
      state.city = address
      state.county = address
    }
  }

  async onCollectGenderEnded(state: State): Promise<IStep> {
    return state.phoneNumber //
      ? this.goToCollectEmail
      : this.goToCollectPhoneNumber
  }

  async getCollectEmailSettings(state: State): Promise<ICollectEmailSettings> {
    return {
      messages: {
        askDoYouWantToShareEmail: this.t(["Could you also please share an email address?"]),
        askEmail: this.t([
          "And what's the best email for contact?",
          "This can be a personal email address if preferred"
        ])
      },
      hasPreliminaryQuestion: true,
      customisableOptions: {
        askDoYouWantToShareEmail: [
          { body: this.t("Okay"), value: true },
          { body: this.t("No, I'd rather not"), value: false }
        ]
      }
    }
  }

  async onCollectEmailEnded(state: State): Promise<IStep> {
    return this.askGP
  }

  async getCollectPostcodeAndAddressSettings(
    state: State
  ): Promise<IDefaultChatFlowSettingsCheckPostCodeFromAddressLookup> {
    return { startWithAskPostcode: true, hideEarlierYouSaid: true }
  }

  async onCollectPostcodeAndAddressEnded(state: State): Promise<IStep> {
    return this.goToCollectGoalForTherapy
  }

  async getReferralPayload(state: State): Promise<ReferralPayloadVitality> {
    const instanceID = this.referralStore.instanceID
    invariant(instanceID, "Cannot create referral without an Instance ID")
    const isValidMobile = isValidMobilePhone(state.phoneNumber || "0")
    const isValidLandline = isValidLandlineNumber(state.phoneNumber || "0") && !isValidMobile
    const parsed = parsePhoneNumber(state.phoneNumber || "0", { regionCode: "GB" })

    return {
      instanceID,
      nameFirst: this.getFirstName(state),
      nameLast: this.getLastName(state),
      dob: moment(state.birthday).format("DD/MM/YYYY"),
      addressHome: {
        address1: state.address,
        address2: state.address2,
        // If address is entered manually then city/county/postcode are undefined
        // Pass an alternate value to avoid errors in the referral submission
        city: state.city || "unknown",
        county: state.county || "unknown",
        postcode: state.userPostcode?.postcode || state.invalidPostcodeEntered || "unknown",
        consentMail: !!state.canSendMailToAddress
      },
      email: state.email,
      consentEmail: !!state.email,
      phoneHome: isValidLandline
        ? {
            cc: String(parsed.countryCode || ""),
            number: parsed.number?.national.replace(/ /g, "") ?? state.phoneNumber!,
            isMobile: false,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      phoneMobile: isValidMobile
        ? {
            cc: String(parsed.countryCode || ""),
            number: parsed.number?.national.replace(/ /g, "") ?? state.phoneNumber!,
            isMobile: true,
            consentSMS: !!state.canSendTextMessagesToPhoneNumber,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      assessmentPreference: state.assessmentPreference,
      riskLevel: this.clinicalStore.riskLevel,
      riskLevelReason: this.clinicalStore.riskLevelReason,
      triggerWords: this.clinicalStore.triggerWords,
      title: state.preferredTitle,
      gender: this.getGender(state),
      consentDataShare: true,
      consentDataStore: true,
      output: this.referralStore.referralType,
      gpSurgery: state.gpSurgeryNameEntered,
      gpAddress: state.gpAddressEntered,
      questionnaires: this.getQuestionnairesPayload(state),
      clinicalNotes: this.referralStore.clinicalNotes,
      clinicalFlags: this.clinicalStore.flags,
      problemDescriptorPrimary: this.clinicalStore.primaryProblems,
      problemDescriptorSecondary: this.clinicalStore.secondaryProblems,
      treatmentExpectation: state.therapyGoal,
      source: state.source,
      claimNumber: state.claimNumber,
      memberNumber: this.referralStore.getCustomField("entityId"),
      nextRenewalDate: this.referralStore.getCustomField("nextRenewalDate"),
      excessAmount: this.referralStore.getCustomField("excessAmount"),
      excessType: this.referralStore.getCustomField("excessType"),
      staffMember: this.referralStore.getCustomField("staffMember"),
      planType: this.referralStore.getCustomField("planType")
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async onRiskReferralFinished(state: State): Promise<void> {}

  getGenders(): string[] {
    return ["Male", "Female", "Intersex", "Non-binary", "Don't want to say"]
  }

  getGender(state: State): GENDER_VITALITY {
    const map: Record<string, GENDER_VITALITY> = {
      Male: "MALE",
      Female: "FEMALE",
      Intersex: "INTERSEX",
      "Non-binary": "NON_BINARY",
      "Don't want to say": "NOT_ANSWERED"
    }
    const gender = map[state.gender!]
    return gender ?? "UNKNOWN"
  }
}

/* istanbul ignore next */
export default class SelfReferralVitalityDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralVitality
  readonly name: string = "SelfReferralVitalityDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(SelfReferralVitalityDialogue.id, new SelfReferralVitalityScript(), state, snapshot)
  }
}
