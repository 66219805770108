import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import { PermissionsIAPTScript } from "./PermissionsIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { PermissionsScriptState } from "./PermissionsScript"

type State = PermissionsScriptState
export type PermissionsCWScriptState = State

export class PermissionsCWScript extends PermissionsIAPTScript {
  readonly name: string = "PermissionsCWScript"

  /** Script Steps */

  @step.logState
  showTOSLinks(_d: IStepData<State>): IStepResult {
    const organisationName =
      this.rootStore.configStore.organisationName ?? this.rootStore.configStore.serviceName
    const organisationTerms = this.rootStore.configStore.organisationTerms ?? ""
    return {
      body: this.t(
        [
          "To get you the best referral, I will need to share your answers with {organisationName}",
          "I want to reassure you that your details will be stored safely and kept confidential",
          "Please note the service may need to share information in relation to your care with your GP and other health and social care providers",
          "You can find all the details here:\n\n" +
            "Limbic [Terms of Service](https://www.limbic.ai/terms-of-use)\n" +
            "Limbic [Privacy Policy](https://www.limbic.ai/privacy)\n" +
            "{organisationTerms}"
        ],
        { organisationName, organisationTerms }
      ),
      nextStep: this.promptIUnderstandTerms
    }
  }
}

/* istanbul ignore next */
export default class PermissionsCWDialogue extends Dialogue<State> {
  static id = DialogueIDs.PermissionsCW
  readonly name: string = "PermissionsCWDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PermissionsCWDialogue.id, new PermissionsCWScript(), state, snapshot)
  }
}
