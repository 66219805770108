import React from "react"
import { Typography } from "@material-ui/core"
import { Autorenew as RedoIcon } from "@material-ui/icons"
import Linkify from "react-linkify"
import "./UserBubble.scss"
import { IBubbleProps } from "./IBubbleProps"
import { observer } from "mobx-react"
import {
  useApplicationStore,
  useChatStore,
  useConfigStore,
  useUndoStore
} from "../../contexts/RootStoreContext"

const UNDO_ENABLED = process.env.REACT_APP_UNDO === "enabled"

interface Props extends IBubbleProps {
  tabIndex?: number
  children?: React.ReactChild
  isUndoAble?: boolean
  onUndoPress?: () => void
}

function UserBubble(props: Props): JSX.Element {
  const chat = useChatStore()
  const config = useConfigStore()
  const app = useApplicationStore()
  const undo = useUndoStore()
  const { isUndoAble, onUndoPress, message, children, tabIndex } = props
  const text = typeof children === "string" ? children : message
  const backgroundColor = config.userMessageBackground
  /**
   * we always have 1 snapshot in the array (the current one) so undo should
   * be enabled, when there are more than 1 snapshots
   */
  const snapshotAvailable = undo.getSnapshotsList()?.length > 1

  return (
    <div className="lb-user-bubble-container">
      {isUndoAble && UNDO_ENABLED && snapshotAvailable && (
        <button
          aria-label="Undo Last Step"
          aria-describedby="Undo Last Step"
          onClick={chat.isTyping ? undefined : onUndoPress}
          className="lb-user-bubble-undo-container"
          data-testid="lb-user-bubble-undo-button">
          <span className="lb-user-bubble-undo-text" aria-label="Undo" tabIndex={0}>
            {app.t("Undo")}
          </span>
          <RedoIcon className="lb-user-bubble-undo-button" style={{ fill: backgroundColor }} />
        </button>
      )}
      {/* Not sure why they removed this - I had to add backgroundColor back */}
      {/* <div className="lb-user-bubble-content" style={{ backgroundColor }}> */}
      <div
        className="lb-user-bubble-content"
        style={{ backgroundColor }}
        aria-label={text}
        tabIndex={tabIndex}>
        <Typography className="lb-user-bubble-text" data-testid="lb-user-bubble-text">
          <Linkify componentDecorator={componentDecorator}>{text}</Linkify>
        </Typography>
      </div>
    </div>
  )
}

const componentDecorator = (href, text, key) => (
  <a
    className="lb-user-bubble-text-link"
    href={href}
    key={key}
    target="_blank"
    rel="noopener noreferrer">
    {text}
  </a>
)

export default observer(UserBubble)
