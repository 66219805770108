import Logger from "../../../utils/Logger"
import delay from "../../../utils/delay"
import isOnline from "is-online"
import { ADSMStatus } from "../../../models/ADSMStatus"
import { Questionnaires } from "@limbic/types"
import client from "./_client"

const TOTAL_RETRIES = 3

export async function adsmTriage(
  signupCode: string,
  retry = 0
): Promise<
  [Array<keyof Questionnaires> | undefined, ADSMStatus, Record<string, number> | undefined]
> {
  try {
    const hasConnection = await isOnline()
    if (!hasConnection) return [undefined, ADSMStatus.NoInternetConnection, undefined]

    const data = await client.post("/v1/ml/adsm/triage", { signupCode }, undefined, undefined, 2000)
    return [data.questionnaires, ADSMStatus.Success, data.probabilities]
  } catch (e) {
    Logger.getInstance().breadcrumb({ message: "adsmTriage post failed", data: { signupCode } })
    Logger.getInstance().exception(e, "adsmTriage post failed")
    if (retry < TOTAL_RETRIES) {
      Logger.getInstance().message("adsmTriage retry")
      await delay(1)
      return await adsmTriage(signupCode, retry + 1)
    }
    return [undefined, ADSMStatus.RequestFailed, undefined]
  }
}
