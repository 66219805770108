import React from "react"
import { observer } from "mobx-react"
import "./Sidebar.scss"
import { useConfigStore } from "../../contexts/RootStoreContext"

export interface Props {
  visible?: boolean
}

function Sidebar(props: Props): JSX.Element | null {
  const config = useConfigStore()
  const { visible } = props
  if (!visible) {
    return null
  }

  return (
    <div className="lb-sidebar-container">
      <article className="lb-sidebar-card">
        <div className="lb-header">
          <img src={config.logo} className="lb-header-img" alt="" />
          <h3 className="subtitle">Refer yourself to {config.organisationName}</h3>
        </div>
        <p>I'm here to help you access Mind in Bexley Services</p>
        <p>
          If you feel that your referral is something you would like to discuss with the team please
          call us Monday to Friday 9am - 5pm at 0208 303 8932 and select Option 1. Please leave a
          clear message if your call is not answered
        </p>
        <p>
          If you prefer to use our standard referral from, click{" "}
          <a
            href="https://gateway.mayden.co.uk/referral-v2/af3aaece-3d22-40ba-b1e6-7cd5937423d4"
            target="_blank"
            rel="noreferrer">
            here
          </a>
        </p>
        <p>
          Mind in Bexley Services:
          <ul>
            <li>
              <a
                href="https://mindinbexley.org.uk/talking-therapies/"
                target="_blank"
                rel="noreferrer">
                NHS Bexley Talking Therapies
              </a>
            </li>
            <li>
              <a href="https://mindinbexley.org.uk/recovery/" target="_blank" rel="noreferrer">
                Recovery College and Workshops
              </a>
            </li>
            <li>
              <a
                href="https://mindinbexley.org.uk/family-and-carers-support/"
                target="_blank"
                rel="noreferrer">
                Mental Health Carers Support
              </a>{" "}
              - Support if you are caring for a friend/relative with mental ill health
            </li>
            <li>
              <a href="https://mindinbexley.org.uk/digitalhub/" target="_blank" rel="noreferrer">
                Digital Hub
              </a>{" "}
              - Support to improve your digital skills using a phone, tablet or computer
            </li>
            <li>
              <a href="https://mindinbexley.org.uk/employment/" target="_blank" rel="noreferrer">
                Employment Support
              </a>{" "}
              for individuals with mental health issues
            </li>
            <li>
              <a href="https://mindinbexley.org.uk/crisis-cafe/" target="_blank" rel="noreferrer">
                Crisis Café
              </a>
            </li>
          </ul>
        </p>
        {config.iaptIcons?.map?.(link => (
          <img key={link} className="nhs-logo" src={link} alt={link} />
        ))}
      </article>
      <div className="lb-sidebar-spacer" />
      <article className="lb-sidebar-card full-height">
        <p>
          Limbic helps people access psychological support quicker and easier. Your data is secured
          according to the highest NHS standards.
        </p>
        <img
          className="badges"
          src="https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/data-pics.png"
          alt={"Badges"}
        />
      </article>
    </div>
  )
}

export default observer(Sidebar)
