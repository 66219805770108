import React from "react"
import classNames from "classnames"
import "./ChatMessage.scss"
import IChatMessage from "../../../models/IChatMessage"
import BotBubble from "../ChatBubbles/BotBubble"
import UserBubble from "../ChatBubbles/UserBubble"
import ImageBubble from "../ChatBubbles/ImageBubble"
import TypingIndicator from "../ChatBubbles/TypingIndicator"

interface Props {
  message: IChatMessage
  onUndoPress?: (id: string) => void
  onTypingEnd?: () => void
}

export default function ChatMessage(props: Props): JSX.Element {
  const { message, onTypingEnd } = props
  const onUndoPress = React.useCallback(
    () => message.isUndoAble && props.onUndoPress?.(message.id), //
    [message.isUndoAble, message.id, props]
  )
  const isBot = message.author === "bot"
  const containerCSS = classNames("lb-m-container", {
    last: message.isLast,
    staticReferralURL: message.isStaticReferralURL
  })

  const messageComponent = React.useMemo(() => {
    switch (message.type) {
      case "text":
        if (message.author === "bot") {
          return (
            <BotBubble //
              tabIndex={message.lastBotMessages ? 0 : -1}
              isFirst={message.isFirst}
              isLast={message.isLast}
              message={message.body}
            />
          )
        }
        return (
          <UserBubble
            tabIndex={message.lastUserMessage ? 0 : -1}
            isFirst={message.isFirst}
            isLast={message.isLast}
            message={message.body}
            isUndoAble={message.isUndoAble}
            onUndoPress={onUndoPress}
          />
        )
      case "image":
        return (
          <ImageBubble //
            tabIndex={message.lastBotMessages ? 0 : -1}
            image={message.image}
            description={message.imageDescription}
            message={message.body}
            isFirst={!!message.isFirst}
            isLast={!!message.isLast}
          />
        )
      case "typingIndicator":
        return message.body ? (
          <BotBubble //
            isFirst
            isLast
            stream
            message={message.body}
            onTypingEnd={onTypingEnd}
          />
        ) : (
          <>
            <div className="spacer" />
            <TypingIndicator />
          </>
        )
      default:
        return null
    }
  }, [message, onUndoPress, onTypingEnd])
  return (
    <li className={containerCSS}>
      {isBot && message.isFirst && messageComponent && (
        <img
          className="lb-m-bot-logo"
          src={"https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png"}
          alt={"Logo"}
          aria-label="logo"
        />
      )}
      {messageComponent}
    </li>
  )
}
