import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { GoodbyeScriptState } from "./GoodbyeScript"
import GoodbyeScript from "./GoodbyeScript"
import { IStepData, IStepResult, StepResultBodyType } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"

interface State extends GoodbyeScriptState {
  canKeepSelfSafe?: boolean
  requiresUrgentSupport?: boolean
}

export type GoodbyeCWScriptState = State

export class GoodbyeCWScript extends GoodbyeScript {
  readonly name: string = "GoodbyeCWScript"

  /** Script Steps */

  @step
  async sayGoodBye(d: IStepData<State>): Promise<IStepResult> {
    await this.referralStore.updateReferral({
      isHelpful: d.state.isHelpful,
      improvementSuggestion: d.state.improvementSuggestion
    })
    this.referralStore.stopPinging()
    const name = this.getName(d.state)

    return {
      body: [
        "Well done for taking this important step towards better mental health",
        `Goodbye ${name} 👋`
      ],
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onHandleRecapMessage(
    _state: State,
    body: StepResultBodyType[],
    recapMessage: string | void
  ): Promise<IStepResult | void> {
    const isCrisis = this.clinicalStore.isCrisis
    if (!body.length) {
      return { nextStep: isCrisis ? this.end : this.askFeedback }
    }

    const choices: any[] = [{ body: "Okay" }, recapMessage && { body: "I understand" }] //
      .filter(Boolean)

    return {
      body,
      prompt: {
        id: this.getPromptId("recap"),
        trackResponse: true,
        type: "inlinePicker",
        choices
      },
      nextStep: isCrisis ? this.end : this.askFeedback
    }
  }

  async getIntroMessage(state: State): Promise<string | void> {
    const isCrisis = this.clinicalStore.isCrisis
    const name = this.getName(state)
    return isCrisis
      ? "I hope I’ve been able to help you today"
      : `Thank you for sharing this information with me ${name}`
  }

  // 👇 This is overridden to change the order of the adminWillCall
  // message, to happen after the risk messages
  async getRecapMessage(state: State): Promise<string | void> {
    const recaps: string[] = []
    const referredYouMessage = await this.getReferredYouMessage(state)
    referredYouMessage && recaps.push(referredYouMessage)

    const highRiskContactMessage = await this.getHighRiskContactMessage(state)
    highRiskContactMessage && recaps.push(highRiskContactMessage)

    const moderateRiskContactMessage = await this.getModerateRiskContactMessage(state)
    moderateRiskContactMessage && recaps.push(moderateRiskContactMessage)

    const adminWillCallMessage = await this.getAdminWillCallMessage(state)
    adminWillCallMessage && recaps.push(adminWillCallMessage)

    const treatmentMessage = await this.getTreatmentMessage(state)
    treatmentMessage && recaps.push(treatmentMessage)

    const formattedRecap = recaps.length > 1 ? recaps.map((i, idx) => `${idx + 1}. ${i}`) : recaps
    const recapString = formattedRecap.join("\n")

    if (recapString?.length) {
      return `Just to recap:\n\n${recapString}`
    }
  }

  async getReferredYouMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted) {
      const organisationName = this.rootStore.configStore.organisationName
      const iaptName = this.getIAPTName(state) || organisationName
      if (state.isIdleSubmitted) {
        return `It looks like there hasn’t been any activity for some time so I've referred you to ${iaptName}`
      }

      return `I've referred you to ${iaptName}`
    }
  }

  async getAdminWillCallMessage(state: State): Promise<string | void> {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(state) || organisationName
    if (state.referralSubmitted) {
      if (state.canSendTextMessagesToPhoneNumber) {
        return (
          `You will shortly receive an SMS from ${iaptName} with a link to book your appointment. After booking, you will receive a confirmation email of your appointment with further information\n` +
          "If you do not receive a confirmation email after booking your appointment, please call us after 1 working day on the following numbers:\n" +
          "\n" +
          "If you live in Central Cheshire: <b>0300 303 0639</b>\n" +
          "If you live in West Cheshire /Chester: <b>0124 439 7761</b>"
        )
      }
      return (
        "Please call us after 1 working day on the following numbers:\n" +
        "\n" +
        "If you live in Central Cheshire: <b>0300 303 0639</b>\n" +
        "If you live in West Cheshire /Chester: <b>0124 439 7761</b>"
      )
    }
  }

  async getHighRiskContactMessage(state: State): Promise<string | void> {
    if (this.clinicalStore.isHighRisk) {
      return (
        `Please remember, we are not an emergency response service. For urgent support you can telephone the Cheshire and Wirral Partnership 24 hour crisis line on 0800 145 6485.\n\n` +
        "If you have an immediate, life-threatening emergency - requiring mental or physical health assistance – please call 999.\n\n" +
        "You can also contact the Samaritans on 116123 to talk through how you are feeling"
      )
    }
  }

  async getModerateRiskContactMessage(state: State): Promise<string | void> {
    if (this.clinicalStore.isModerateRisk) {
      return (
        `Please remember, we are not an emergency response service. For urgent support you can telephone the Cheshire and Wirral Partnership 24 hour crisis line on 0800 145 6485.\n\n` +
        "If you have an immediate, life-threatening emergency - requiring mental or physical health assistance – please call 999.\n\n" +
        "You can also contact the Samaritans on 116123 to talk through how you are feeling"
      )
    }
  }

  async getCustomRecapMessage(state: State): Promise<StepResultBodyType[] | undefined> {
    const isCrisis = this.clinicalStore.isCrisis
    const name = this.getName(state)
    const lastMessage = state.requiresUrgentSupport
      ? `I look forward to talking with you again ${name}`
      : `Well, it's been a pleasure getting to know you ${name}`
    return [isCrisis ? `Thank you for sharing this information with me ${name}` : lastMessage]
  }
}

/* istanbul ignore next */
export default class GoodbyeCWDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeCW
  readonly name: string = "GoodbyeCWDialogue"

  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeCWDialogue.id, new GoodbyeCWScript(), state, snapshot)
  }
}
