import createSurveyDialogue from "../createSurveyDialogue"
import ISurvey, { SurveyType } from "../../models/ISurvey"
import { DialogueIDs } from "../DialogueIDs"

const PCL_5: ISurvey = {
  startingQuestion:
    "Below is a list of problems that people sometimes have in response to a very stressful experience. Please read each problem carefully and then select one to indicate how much you have been bothered by that problem in the past month",
  questions: [
    {
      id: "1",
      questionName: "PCL5 - step1",
      question: "1. Repeated, disturbing, and unwanted memories of the stressful experience?",
      type: "inline",
      progress: 0.05,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Moderately" },
        { points: 3, answer: "Quite a bit" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "2",
      questionName: "PCL5 - step2",
      question: "2. Repeated, disturbing dreams of the stressful experience?",
      type: "inline",
      progress: 0.1,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Moderately" },
        { points: 3, answer: "Quite a bit" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "3",
      questionName: "PCL5 - step3",
      question:
        "3. Suddenly feeling or acting as if the stressful experience were actually happening again (as if you were actually back there reliving it)?",
      type: "inline",
      progress: 0.15,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Moderately" },
        { points: 3, answer: "Quite a bit" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "4",
      questionName: "PCL5 - step4",
      question: "4. Feeling very upset when something reminded you of the stressful experience?",
      type: "inline",
      progress: 0.2,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Moderately" },
        { points: 3, answer: "Quite a bit" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "5",
      questionName: "PCL5 - step5",
      question:
        "5. Having strong physical reactions when something reminded you of the stressful experience (for example, heart pounding, trouble breathing, sweating)?",
      type: "inline",
      progress: 0.25,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Moderately" },
        { points: 3, answer: "Quite a bit" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "6",
      questionName: "PCL5 - step6",
      question: "6. Avoiding memories, thoughts, or feelings related to the stressful experience?",
      type: "inline",
      progress: 0.3,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Moderately" },
        { points: 3, answer: "Quite a bit" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "7",
      questionName: "PCL5 - step7",
      question:
        "7. Avoiding external reminders of the stressful experience (for example, people, places, conversations, activities, objects, or situations)?",
      type: "inline",
      progress: 0.35,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Moderately" },
        { points: 3, answer: "Quite a bit" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "8",
      questionName: "PCL5 - step8",
      question: "8. Trouble remembering important parts of the stressful experience?",
      type: "inline",
      progress: 0.4,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Moderately" },
        { points: 3, answer: "Quite a bit" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "9",
      questionName: "PCL5 - step9",
      question:
        "9. Having strong negative beliefs about yourself, other people, or the world (for example, having thoughts such as: I am bad, there is something seriously wrong with me, no one can be trusted, the world is completely dangerous)?",
      type: "inline",
      progress: 0.45,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Moderately" },
        { points: 3, answer: "Quite a bit" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "10",
      questionName: "PCL5 - step10",
      question:
        "10. Blaming yourself or someone else for the stressful experience or what happened after it?",
      type: "inline",
      progress: 0.5,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Moderately" },
        { points: 3, answer: "Quite a bit" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "11",
      questionName: "PCL5 - step11",
      question: "11. Having strong negative feelings such as fear, horror, anger, guilt, or shame?",
      type: "inline",
      progress: 0.55,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Moderately" },
        { points: 3, answer: "Quite a bit" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "12",
      questionName: "PCL5 - step12",
      question: "12. Loss of interest in activities that you used to enjoy?",
      type: "inline",
      progress: 0.6,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Moderately" },
        { points: 3, answer: "Quite a bit" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "13",
      questionName: "PCL5 - step13",
      question: "13. Feeling distant or cut off from other people?",
      type: "inline",
      progress: 0.65,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Moderately" },
        { points: 3, answer: "Quite a bit" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "14",
      questionName: "PCL5 - step14",
      question:
        "14. Trouble experiencing positive feelings (for example, being unable to feel happiness or have loving feelings for people close to you)?",
      type: "inline",
      progress: 0.7,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Moderately" },
        { points: 3, answer: "Quite a bit" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "15",
      questionName: "PCL5 - step15",
      question: "15. Irritable behavior, angry outbursts, or acting aggressively?",
      type: "inline",
      progress: 0.75,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Moderately" },
        { points: 3, answer: "Quite a bit" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "16",
      questionName: "PCL5 - step16",
      question: "16. Taking too many risks or doing things that could cause you harm?",
      type: "inline",
      progress: 0.8,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Moderately" },
        { points: 3, answer: "Quite a bit" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "17",
      questionName: "PCL5 - step17",
      question: '17. Being "superalert" or watchful or on guard?',
      type: "inline",
      progress: 0.85,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Moderately" },
        { points: 3, answer: "Quite a bit" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "18",
      questionName: "PCL5 - step18",
      question: "18. Feeling jumpy or easily startled?",
      type: "inline",
      progress: 0.9,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Moderately" },
        { points: 3, answer: "Quite a bit" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "19",
      questionName: "PCL5 - step19",
      question: "19. Having difficulty concentrating?",
      type: "inline",
      progress: 0.95,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Moderately" },
        { points: 3, answer: "Quite a bit" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "20",
      questionName: "PCL5 - step20",
      question: "20. Trouble falling or staying asleep?",
      type: "inline",
      progress: 1,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Moderately" },
        { points: 3, answer: "Quite a bit" },
        { points: 4, answer: "Extremely" }
      ]
    }
  ]
}

export default createSurveyDialogue(DialogueIDs.PCL5, PCL_5, SurveyType.PCL5)
