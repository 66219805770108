import { z, ZodSchema } from "zod"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import {
  GetNameIAPTScript,
  GetNameIAPTScriptState,
  GetNameIAPTScriptStateSchema
} from "./GetNameIAPTDialogue"
import { MindPronouns, MindTitles } from "@limbic/types"
import { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"

const UNDO_ENABLED = process.env.REACT_APP_UNDO === "enabled"

interface State extends GetNameIAPTScriptState {
  preferredTitle?: MindTitles
  preferredPronouns?: MindPronouns
}

export type GetNameMindScriptState = State

export const GetNameMindScriptStateSchema = GetNameIAPTScriptStateSchema.extend({
  preferredTitle: z
    .union([
      z.literal("Mr"),
      z.literal("Mrs"),
      z.literal("Miss"),
      z.literal("Ms"),
      z.literal("Dr"),
      z.literal("Rev"),
      z.literal("Prof"),
      z.literal("Mx")
    ])
    .optional(),
  preferredPronouns: z
    .union([
      z.literal("She/Her/Hers"),
      z.literal("He/Him/His"),
      z.literal("They/Them/Their"),
      z.literal("Ae/Aer/Aer"),
      z.literal("Ey/Em/Eir"),
      z.literal("Fae/Faer/Faer"),
      z.literal("Per/Per/Pers"),
      z.literal("Ve/Ver/Vers"),
      z.literal("Xe/Xem/Xyr"),
      z.literal("Ze/Hir/Hir")
    ])
    .optional()
})

export class GetNameMindScript extends GetNameIAPTScript {
  readonly name: string = "GetNameMindScript"

  /** Script Steps */

  @step
  step1(_d: IStepData<State>): IStepResult {
    // overriding to remove the word "patient"
    const { directReferral } = this.rootStore.configStore
    return {
      body: this.t(
        directReferral
          ? "I'm now going to create a record for you"
          : "Ok, I'm just going to create a record for you"
      ),
      prompt: directReferral
        ? ({
            type: "inlinePicker",
            choices: [{ body: this.t("Okay") }]
          } as IInlinePickerSingleSelectPrompt)
        : undefined,
      nextStep: this.askFullName
    }
  }

  @step
  askFullName(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        UNDO_ENABLED
          ? [
              "What's your full name? ✏️",
              "You can re-type your answer at any point by clicking the 'Undo' button next to the message you want to change"
            ]
          : "What's your full name? ✏️"
      ),
      nextStep: this.showPromptForFullName
    }
  }

  @step.logState
  askIsPreferredName(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t("Is {name} your preferred first name?", { name }),
      prompt: {
        id: this.getPromptId("askIsPreferredName"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleIsPreferredName
    }
  }

  @step.logStateAndResponse
  async handleIsPreferredName(d: IStepData<State, boolean>): Promise<IStepResult> {
    return {
      nextStep: d.response //
        ? this.sayNiceToMeetYou
        : this.sayPleaseGivePreferredName
    }
  }

  @step
  sayPleaseGivePreferredName(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("What would you like to be called?"),
      nextStep: this.showPromptForPreferredName
    }
  }

  @step.logState
  showPromptForPreferredName(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("showPromptForPreferredName"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handlePreferredNameWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>, script: GetNameMindScript) => {
    const preferredName = d.response?.trim()
    d.state.preferredName = preferredName
    script.rootStore.applicationStore.setPreferredName(preferredName)
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    getNextStep: (s: GetNameMindScript) => s.sayPleaseGivePreferredName
  })
  async handlePreferredNameWithCrisis(_d: IStepData<State, string>): Promise<IStepResult> {
    return { nextStep: this.checkPreferredName }
  }

  @step.logState
  async checkPreferredName(d: IStepData<State>): Promise<IStepResult> {
    if (!d.state.preferredName || d.state.preferredName.trim() === "") {
      return { nextStep: this.sayPleaseGivePreferredName }
    }
    return { nextStep: this.sayNiceToMeetYou }
  }

  @step
  sayNiceToMeetYou(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t("Nice to meet you {name}", { name }),
      prompt: {
        id: this.getPromptId("sayNiceToMeetYou"),
        type: "inlinePicker",
        choices: [{ body: this.t("Nice to meet you too") }]
      },
      nextStep: this.askHowWouldYouLikeToBeAddressed
    }
  }

  @step.logState
  askHowWouldYouLikeToBeAddressed(d: IStepData<State>): IStepResult {
    const titles = this.getTitles()
    if (!titles?.length) {
      this.logBreadcrumb("TITLES NOT FOUND", d.state, { titles })
      this.logMessage("TITLES NOT FOUND")
      return { nextStep: this.end }
    }

    return {
      body: this.t("And how would you like to be addressed?"),
      prompt: {
        id: this.getPromptId("askHowWouldYouLikeToBeAddressed"),
        type: "inlinePicker",
        choices: titles.map(title => ({ body: this.t(title), value: title })),
        isUndoAble: true
      },
      nextStep: this.handleHowWouldYouLikeToBeAddressed
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, MindTitles>) => {
    d.state.preferredTitle = d.response
  })
  handleHowWouldYouLikeToBeAddressed(_d: IStepData<State, MindTitles>): IStepResult {
    return { nextStep: this.askPreferredPronouns }
  }

  @step.logState
  askPreferredPronouns(d: IStepData<State>): IStepResult {
    const pronouns = this.getPreferredPronouns(d.state)
    if (!pronouns?.length) {
      this.logBreadcrumb("PRONOUNS NOT FOUND", d.state, { pronouns })
      this.logMessage("PRONOUNS NOT FOUND")
      return { nextStep: this.end }
    }
    return {
      body: this.t("And what are your preferred pronouns?"),
      prompt: {
        id: this.getPromptId("askPreferredPronouns"),
        trackResponse: true,
        type: "inlinePicker",
        choices: pronouns.map(g => ({ body: this.t(g), value: g }))
      },
      nextStep: this.handlePreferredPronouns
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, MindPronouns>) => {
    d.state.preferredPronouns = d.response
  })
  handlePreferredPronouns(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.end }
  }

  /** Generic Handlers */

  async onCheckFullName(_state: State): Promise<IStepResult> {
    return {
      nextStep: this.askIsPreferredName
    }
  }

  getTitles(): MindTitles[] {
    return ["Mr", "Mrs", "Miss", "Ms", "Dr", "Rev", "Prof", "Mx"]
  }

  getPreferredPronouns(_state: State): MindPronouns[] {
    return [
      "She/Her/Hers",
      "He/Him/His",
      "They/Them/Their",
      "Ae/Aer/Aer",
      "Ey/Em/Eir",
      "Fae/Faer/Faer",
      "Per/Per/Pers",
      "Ve/Ver/Vers",
      "Xe/Xem/Xyr",
      "Ze/Hir/Hir"
    ]
  }

  getStateSchema(): ZodSchema | undefined {
    return GetNameMindScriptStateSchema
  }
}

/* istanbul ignore next */
export default class GetNameMindDialogue extends Dialogue<State> {
  static id = DialogueIDs.GetNameMind
  readonly name: string = "GetNameMindDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GetNameMindDialogue.id, new GetNameMindScript(), state, snapshot)
  }
}
