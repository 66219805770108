import { slackUrl } from "../config/config"
import Logger from "../utils/Logger"
import delay from "../utils/delay"

const TOTAL_RETRIES = 2

export async function postToSlack(message: Record<string, string>, retry = 0): Promise<void> {
  try {
    await fetch(slackUrl, {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(message)
    })
  } catch (e) {
    if (retry < TOTAL_RETRIES) {
      Logger.getInstance().message("postToSlack Retry")
      await delay(2 * retry || 1.5)
      return await postToSlack(message, retry + 1)
    }
    Logger.getInstance().exception(e, "postToSlack fetch failed")
  }
}
