import React from "react"
import { Snippet } from "@limbic/types"

export default function SnippetInjector({ snippets }: { snippets: Snippet[] }): null {
  // for every snippet, create a temporary div, inject the snippet content into it,
  // so that the snippet becomes part of the DOM, then just append the children of the
  // temporary div to the target element (head or body) and store the reference for
  // cleanup when the component unmounts

  React.useEffect(() => {
    const removables: { node: HTMLElement; target: HTMLBodyElement | HTMLHeadElement }[] = []

    snippets.forEach(snippet => {
      const target = snippet.position === "head" ? document.head : document.body
      const tempDiv = document.createElement("div")
      tempDiv.innerHTML = snippet.content

      tempDiv.childNodes.forEach(child => {
        if (child.nodeType === Node.ELEMENT_NODE) {
          const el = child as HTMLElement
          if (el.tagName === "SCRIPT") {
            const scriptElement = el as HTMLScriptElement
            const script = document.createElement("script")
            if (scriptElement.src) script.src = scriptElement.src
            else script.textContent = scriptElement.textContent
            target.appendChild(script)
            removables.push({ node: script, target })
            return
          }
          target.appendChild(el)
          removables.push({ node: el, target })
        }
      })
    })

    return () => {
      removables.forEach(i => i.target.removeChild(i.node))
    }
  }, [snippets])

  return null
}
