/* eslint-disable @typescript-eslint/no-empty-function */
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../../DialogueIDs"
import { step } from "../../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { TrackingEvents } from "../../../../models/Constants"
import getAddressesByPostcode, {
  IAddress
} from "../../../../backend/api/external/getAddressesByPostcode"
import { IGetAddressStatus } from "../../../../models/IGetAddress"
import { getPostCodeDetails } from "../../../../backend/api/external/postcodes"
import { PostcodeStatus } from "../../../../models/IPostcode"
import BaseScript, { BaseScriptState } from "../../../BaseScript"

interface State extends BaseScriptState {
  addressLookupCounter?: number
  postcodeLookupCounter?: number
  hideEarlierYouSaid?: boolean
  retryPostcode?: string
  invalidPostcodeEntered?: string
  startWithAskPostcode?: boolean
  skipPermissionsQuestion?: boolean
}

export type CheckPostCodeFromAddressLookupScriptState = State

export class CheckPostCodeFromAddressLookupScript extends BaseScript<State> {
  readonly name: string = "CheckPostCodeFromAddressLookupScript"

  onHandlePostCodeForAddressLookup?(state: State): Promise<IStepResult | void>
  onHandleInvalidPostCodeForAddressLookupWithCrisis?(state: State): Promise<IStepResult | void>
  onHandleSelectAddressFromPostCode?(state: State): Promise<IStepResult | void>
  onHandleAddressWithCrisis?(state: State): Promise<IStepResult | void>
  onHandlePermissionToSendMailToAddress?(state: State): Promise<IStepResult | void>

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    const shouldAskForPostcode = d.state.startWithAskPostcode && !d.state.userPostcode?.postcode
    return {
      nextStep: shouldAskForPostcode
        ? this.askPostCodeForAddressLookup
        : this.checkPostCodeFromAddressLookup
    }
  }

  @step
  @step.setState<State>({ addressLookupCounter: 0, postcodeLookupCounter: 0 })
  checkPostCodeFromAddressLookup(d: IStepData<State>): IStepResult {
    const p = d.state.userPostcode
    if (!p?.postcode) {
      return { nextStep: this.askPostCodeForAddressLookup }
    }
    const hasAddress = d.state.address && (d.state.address2 || d.state.city || d.state.county)
    if (hasAddress) return { nextStep: this.handleSelectAddressFromPostCode }
    return { nextStep: this.askSelectAddressFromPostCode }
  }

  @step.logState
  askRetrySelectAddressFromPostcode(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Hmmm, something went wrong while looking up addresses based on your postcode"),
      nextStep: this.returnToSelectAddressFromPostcode
    }
  }

  @step.logState
  returnToSelectAddressFromPostcode(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Let's try again"),
      prompt: {
        id: this.getPromptId("returnToSelectAddressFromPostcode"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Okay"), value: false },
          { body: this.t("Let me re-type my postcode"), value: true }
        ]
      },
      nextStep: this.handleReturnToSelectAddressFromPostcode
    }
  }

  @step.logStateAndResponse
  handleReturnToSelectAddressFromPostcode(d: IStepData<State, string>): IStepResult {
    if (d.response) {
      d.state.addressLookupCounter = 1
      return { nextStep: this.askPostCodeForAddressLookup }
    }
    return { nextStep: this.askSelectAddressFromPostCode }
  }

  @step.logState
  askRetryInternetConnectionSelectAddressFromPostcode(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Hmmm, It looks like you're not connected to the internet"),
      prompt: {
        id: this.getPromptId("askRetryInternetConnectionSelectAddressFromPostcode"),
        type: "inlinePicker",
        choices: [{ body: this.t("Try again") }],
        isUndoAble: false
      },
      nextStep: this.askSelectAddressFromPostCode
    }
  }

  @step.logState
  async askSelectAddressFromPostCode(d: IStepData<State>): Promise<IStepResult> {
    const isFirstTime = !d.state.addressLookupCounter
    const hideEarlierYouSaid = d.state.hideEarlierYouSaid
    const p = d.state.userPostcode
    const [addresses, addressesStatus] = await getAddressesByPostcode(p)

    if (addressesStatus === IGetAddressStatus.RequestFailed) {
      return { nextStep: this.askRetrySelectAddressFromPostcode }
    }

    if (addressesStatus === IGetAddressStatus.NoInternetConnection) {
      return { nextStep: this.askRetryInternetConnectionSelectAddressFromPostcode }
    }

    if (!addresses?.length) {
      return { nextStep: this.askAddress }
    }

    const addressOptions = addresses.map(a => ({ body: `${a.address} ${a.address2}`, value: a }))
    return {
      body: this.t(
        [
          isFirstTime && !hideEarlierYouSaid && p?.postcode
            ? "So earlier you said your postcode is {postcode}"
            : undefined,
          "Please can you select your house/apartment from the list below?"
        ],
        { postcode: p?.postcode ?? "" }
      ),
      prompt: {
        id: this.getPromptId("askSelectAddressFromPostCode"),
        type: "inlinePicker",
        choices: [
          ...addressOptions,
          {
            body: this.t("Let me enter my address manually"),
            value: "manually",
            backgroundColor: "#EC9CC8"
          }
        ]
      },
      nextStep: this.handleSelectAddressFromPostCode
    }
  }

  // TODO: split this into two steps ffs. One should be handleSelectAddressFromPostCode
  //       that goes to askAddress if the response is "manual-address" otherwise goes to
  //       the askPostCodeForAddressLookup which has no other responsibility but to ask that
  //       -.-
  @step
  askPostCodeForAddressLookup(d: IStepData<State>): IStepResult {
    if (d.response === "manual-address") {
      this.track(TrackingEvents.MANUAL_ADDRESS_POSTCODE_NOT_FOUND, {
        body: d.state.postcodeEntered
      })
      return {
        nextStep: this.askAddress
      }
    }
    return {
      body: this.t("Okay, so what's your postcode?"),
      prompt: {
        id: this.getPromptId("askPostCodeForAddressLookup"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handlePostCodeForAddressLookupWithCrisis
    }
  }

  @step
  returnToAskPostCodeForAddressLookup(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("So I was going to ask you about your address"),
      nextStep: this.askPostCodeForAddressLookup
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    getNextStep: (s: CheckPostCodeFromAddressLookupScript) => s.returnToAskPostCodeForAddressLookup
  })
  async handlePostCodeForAddressLookupWithCrisis(
    d: IStepData<State, string | undefined>
  ): Promise<IStepResult> {
    d.state.postcodeEntered = d.response || d.state.retryPostcode
    d.state.retryPostcode = d.state.postcodeEntered

    const [postcode, postcodeStatus] = await getPostCodeDetails(d.response || d.state.retryPostcode)
    if (postcodeStatus === PostcodeStatus.NoInternetConnection) {
      return { nextStep: this.askRetryInternetConnection }
    }

    if (postcodeStatus === PostcodeStatus.Success) {
      d.state.userPostcode = postcode
      const result = await this.onHandlePostCodeForAddressLookup?.(d.state)
      if (result) return result
      return { nextStep: this.askSelectAddressFromPostCode }
    }

    d.state.postcodeLookupCounter!++
    if (postcodeStatus === PostcodeStatus.RequestFailed) {
      return { nextStep: this.sayPostcodeLookupFailed }
    }

    d.state.invalidPostcodeEntered = d.response || d.state.retryPostcode
    const result = await this.onHandleInvalidPostCodeForAddressLookupWithCrisis?.(d.state)
    if (result) return result
    return { nextStep: this.sayNotValidPostcode }
  }

  @step.logState
  sayPostcodeLookupFailed(d: IStepData<State>): IStepResult {
    const needsToCallIn = d.state.postcodeLookupCounter! >= 3
    if (needsToCallIn) {
      this.setEligibility(d.state, false)
      this.setUserNeedsToCallIn(d.state)
      const organisationName = this.rootStore.configStore.organisationName
      const iaptName = this.getIAPTName(d.state) || organisationName
      return {
        body: this.t(
          [
            "Hmmm, it looks like something went wrong while looking up your postcode",
            "And a valid UK postcode is required in order for me to refer you to {iaptName}"
          ],
          { ...this.getContext(d.state), iaptName }
        ),
        nextStep: this.sayUserNeedsToCallIn
      }
    }
    return {
      body: this.t(
        ["Hmmm, it looks like something went wrong while looking up your postcode"],
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askPostcodeRetry"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Try again") }],
        isUndoAble: false
      },
      nextStep: this.retryPostCodeForAddressLookup
    }
  }

  @step.logState
  askRetryInternetConnection(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Hmmm, It looks like you're not connected to the internet",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askRetryConnection"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Try again") }],
        isUndoAble: false
      },
      nextStep: this.retryPostCodeForAddressLookup
    }
  }

  @step.logStateAndResponse
  retryPostCodeForAddressLookup(_d: IStepData<State, boolean>): IStepResult {
    // This step is needed to ensure the next will run without a d.response
    return { nextStep: this.handlePostCodeForAddressLookupWithCrisis }
  }

  @step
  sayNotValidPostcode(d: IStepData<State>): IStepResult {
    const needsToCallIn = d.state.postcodeLookupCounter! >= 3
    if (needsToCallIn) {
      this.setEligibility(d.state, false)
      this.setUserNeedsToCallIn(d.state)
    }
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    return {
      body: this.t(
        [
          "Hmmm, I wasn't able to find any addresses with the postcode you provided",
          "I need a valid UK postcode in order to refer you to {iaptName}"
        ],
        { ...this.getContext(d.state), iaptName }
      ),
      nextStep: needsToCallIn //
        ? this.sayUserNeedsToCallIn
        : this.sayPleaseRetypeYourPostcode
    }
  }

  @step
  sayPleaseRetypeYourPostcode(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("sayPleaseRetypeYourPostcode"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Change my postcode") },
          { body: this.t("My postcode is correct"), value: "manual-address" }
        ]
      },
      nextStep: this.askPostCodeForAddressLookup
    }
  }

  @step
  sayUserNeedsToCallIn(d: IStepData<State>): IStepResult {
    const organisationPhoneNumbers = this.rootStore.configStore.organisationPhoneNumbers ?? ""
    return {
      body: this.t(
        "Please give any one of our services a call on the following phone numbers:\n{organisationPhoneNumbers}",
        { ...this.getContext(d.state), organisationPhoneNumbers }
      ),
      clearStack: true,
      nextStep: this.end
    }
  }

  @step.logStateAndResponse
  @step.setState((s: CheckPostCodeFromAddressLookupScriptState) => ({
    addressLookupCounter: s.addressLookupCounter || 0
  }))
  async handleSelectAddressFromPostCode(
    d: IStepData<State, "manually" | IAddress>
  ): Promise<IStepResult> {
    const hasAddress = d.state.address && (d.state.address2 || d.state.city || d.state.county)
    if (!d.response && hasAddress) {
      d.response = {
        address: d.state.address!,
        address2: d.state.address2,
        city: d.state.city ?? d.state.county ?? d.state.userPostcode?.postcode ?? "unknown",
        county: d.state.county ?? d.state.city ?? d.state.userPostcode?.postcode ?? "unknown"
      }
    }
    const body = d.response === "manually" ? "Let me enter my address manually" : "Address selected"
    this.track(TrackingEvents.SELECT_ADDRESS, { body })
    if (d.response === "manually") {
      return { nextStep: this.askAddress }
    }
    d.state.address = d.response.address
    d.state.address2 = d.response.address2
    d.state.city = d.response.city
    d.state.county = d.response.county
    const result = await this.onHandleSelectAddressFromPostCode?.(d.state)
    if (result) return result
    if (d.state.skipPermissionsQuestion) {
      d.state.canSendMailToAddress = true
      return { nextStep: this.end }
    }
    return { nextStep: this.askPermissionToSendMailToAddress }
  }

  @step.logState
  askAddress(d: IStepData<State>): IStepResult {
    return {
      body: this.t("Okay, please type your address below", this.getContext(d.state)),
      prompt: {
        id: this.getPromptId("askAddress"),
        type: "address"
      },
      nextStep: this.handleAddressWithCrisis
    }
  }

  @step
  returnToAskAddress(d: IStepData<State>): IStepResult {
    return {
      body: this.t("So I asked about your address earlier", this.getContext(d.state)),
      nextStep: this.askAddress
    }
  }

  @step.logStateAndResponse
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    getInput: (d: IStepData<State, IAddress>) => Object.values(d.response ?? {}).join(" "),
    getNextStep: (s: CheckPostCodeFromAddressLookupScript) => s.returnToAskAddress
  })
  async handleAddressWithCrisis(d: IStepData<State, IAddress>): Promise<IStepResult> {
    d.state.address = d.response?.address
    d.state.address2 = d.response?.address2
    d.state.city = d.response?.city
    d.state.county = d.response?.county
    const result = await this.onHandleAddressWithCrisis?.(d.state)
    if (result) return result
    if (d.state.skipPermissionsQuestion) {
      d.state.canSendMailToAddress = true
      return { nextStep: this.end }
    }
    return { nextStep: this.askPermissionToSendMailToAddress }
  }

  @step.logState
  askPermissionToSendMailToAddress(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Are you happy for us to send you written communications to your home?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askPermissionToSendMailToAddress"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handlePermissionToSendMailToAddress
    }
  }

  @step.logState
  async handlePermissionToSendMailToAddress(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.canSendMailToAddress = d.response
    const result = await this.onHandlePermissionToSendMailToAddress?.(d.state)
    if (result) return result

    return { nextStep: this.end }
  }
}

/* istanbul ignore next */
export default class CheckPostCodeFromAddressLookupDialogue extends AdHocDialogue<
  State,
  CheckPostCodeFromAddressLookupScript
> {
  static id = DialogueIDs.CheckPostCodeFromAddressLookup
  readonly name: string = "CheckPostCodeFromAddressLookupDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      CheckPostCodeFromAddressLookupDialogue.id,
      new CheckPostCodeFromAddressLookupScript(),
      state,
      snapshot
    )
  }
}
