import { IAPTGPMap, IAPTIDs } from "../../models/IIAPTService"

export const iaptGPMapCWCentral: IAPTGPMap = {
  N81032: IAPTIDs.CW_CHE,
  N81001: IAPTIDs.CW_CHE,
  N81008: IAPTIDs.CW_CHE,
  N81087: IAPTIDs.CW_CHE,
  N81053: IAPTIDs.CW_CHE,
  N81025: IAPTIDs.CW_CHE,
  N81071: IAPTIDs.CW_CHE,
  N81068: IAPTIDs.CW_CHE,
  N81043: IAPTIDs.CW_CHE,
  N81040: IAPTIDs.CW_CHE,
  N81044: IAPTIDs.CW_CHE,
  N81047: IAPTIDs.CW_CHE,
  N81074: IAPTIDs.CW_CHE,
  N81111: IAPTIDs.CW_CHE,
  N81113: IAPTIDs.CW_CHE,
  N81016: IAPTIDs.CW_CHE,
  N81010: IAPTIDs.CW_CHE,
  N81039: IAPTIDs.CW_CHE,
  N81067: IAPTIDs.CW_CHE,
  N81084: IAPTIDs.CW_CHE,
  N81024: IAPTIDs.CW_CHE,
  N81090: IAPTIDs.CW_CHE,
  N81642: IAPTIDs.CW_CHE,
  N81055: IAPTIDs.CW_CHE,
  N81127: IAPTIDs.CW_CHE,
  N81051: IAPTIDs.CW_CHE,
  N81123: IAPTIDs.CW_CHE,
  N81061: IAPTIDs.CW_CHE,
  N81614: IAPTIDs.CW_CHE
}

export const iaptGPMapCWWest: IAPTGPMap = {
  N81034: IAPTIDs.CW_CHW,
  N81006: IAPTIDs.CW_CHW,
  N81082: IAPTIDs.CW_CHW,
  N81031: IAPTIDs.CW_CHW,
  N81102: IAPTIDs.CW_CHW,
  N81081: IAPTIDs.CW_CHW,
  N81050: IAPTIDs.CW_CHW,
  N81101: IAPTIDs.CW_CHW,
  N81009: IAPTIDs.CW_CHW,
  N81005: IAPTIDs.CW_CHW,
  N81092: IAPTIDs.CW_CHW,
  N81120: IAPTIDs.CW_CHW,
  N81115: IAPTIDs.CW_CHW,
  N81038: IAPTIDs.CW_CHW,
  N81125: IAPTIDs.CW_CHW,
  N81060: IAPTIDs.CW_CHW,
  N81080: IAPTIDs.CW_CHW,
  N81121: IAPTIDs.CW_CHW,
  N81117: IAPTIDs.CW_CHW,
  N81046: IAPTIDs.CW_CHW,
  N81655: IAPTIDs.CW_CHW,
  N81018: IAPTIDs.CW_CHW,
  N81079: IAPTIDs.CW_CHW,
  N81030: IAPTIDs.CW_CHW,
  N81624: IAPTIDs.CW_CHW,
  Y04664: IAPTIDs.CW_CHW,
  N81100: IAPTIDs.CW_CHW,
  N81626: IAPTIDs.CW_CHW,
  N81607: IAPTIDs.CW_CHW,
  N81093: IAPTIDs.CW_CHW,
  N81063: IAPTIDs.CW_CHW
}

export const iaptGPMapCW: IAPTGPMap = {
  ...iaptGPMapCWCentral,
  ...iaptGPMapCWWest
}
