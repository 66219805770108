import BaseScript, { BaseScriptState } from "../../../BaseScript"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { step } from "../../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { IDefaultChatFlowMessagesCollectGoalForTherapy } from "@limbic/types"

export type ICollectGoalForTherapySettings = {
  messages?: IDefaultChatFlowMessagesCollectGoalForTherapy
}

interface State extends BaseScriptState {
  addClinicalNote?: boolean
  forceGoalForTherapyValue?: boolean // TODO: this is not in the dashboard
}

export type CollectGoalForTherapyScriptState = State

export class CollectGoalForTherapyScript extends BaseScript<State> {
  readonly name: string = "CollectGoalForTherapyScript"
  protected messages: IDefaultChatFlowMessagesCollectGoalForTherapy | undefined

  constructor(settings: ICollectGoalForTherapySettings | undefined = {}) {
    super()
    this.messages = settings?.messages ?? {}
  }

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askWhatIsYourGoal }
  }

  @step.logState
  async askWhatIsYourGoal(d: IStepData<State>): Promise<IStepResult> {
    const body = this.t(
      this.messages?.askWhatIsYourGoal ?? [
        "By seeking support what are you hoping will change for you?",
        "For example, being able to engage in activities you are not currently able to do, or get back to doing things you enjoy"
      ],
      this.getContext(d.state)
    )
    await this.appendQuestionToLLMThread?.(body)
    return {
      body,
      prompt: {
        id: this.getPromptId("askWhatIsYourGoal"),
        type: "text",
        dataPointsName: "askWhatIsYourGoal",
        forceValue: d.state.forceGoalForTherapyValue ?? false,
        cancelIsEmptySubmit: !d.state.forceGoalForTherapyValue,
        isUndoAble: false
      },
      nextStep: this.handleWhatIsYourGoalWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.therapyGoal = d.response
  })
  @step.checkInputForCrisis({
    getNextStep: async (s: CollectGoalForTherapyScript, _state: State) => s.end
  })
  async handleWhatIsYourGoalWithCrisis(d: IStepData<State, string>): Promise<IStepResult> {
    if (d.state.addClinicalNote) {
      this.referralStore.addClinicalNote(`Expectations from Support: ${d.state.therapyGoal}`)
    }
    const llmResponse = await this.getLLMResponse?.(d.response ?? "[skipped]")
    if (llmResponse) return { body: llmResponse, nextStep: this.end }
    return { nextStep: this.end }
  }
}

export default class CollectGoalForTherapyDialogue extends AdHocDialogue<
  State,
  CollectGoalForTherapyScript
> {
  static id = DialogueIDs.CollectGoalForTherapy
  readonly name: string = "CollectGoalForTherapyDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: ICollectGoalForTherapySettings
  ) {
    super(
      CollectGoalForTherapyDialogue.id,
      new CollectGoalForTherapyScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
