import BaseScript, { BaseScriptState } from "../../BaseScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = BaseScriptState
export type PeaceOfMindScriptState = State

export abstract class PeaceOfMindScript extends BaseScript<State> {
  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    return { nextStep: this.sayItsConfidential }
  }

  @step.logState
  sayItsConfidential(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Now, before we continue, I want to be very clear about something:",
        "<b>This conversation is completely confidential and separate from your place of work</b>",
        "The information you share will only be used to help find the best support for you - that's it"
      ]),
      nextStep: this.promptUserToAgree
    }
  }

  @step.logState
  promptUserToAgree(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("promptUserToAgree"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("I understand") },
          { body: this.t("Okay") },
          { body: this.t("Good to know") }
        ]
      },
      nextStep: this.end
    }
  }
}
