import { step } from "../../../backend/chatbot/decorators/step"
import { AssessmentPitchIAPTScript } from "./AssessmentPitchIAPTDialogue"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { AssessmentPitchIAPTScriptState } from "./AssessmentPitchIAPTDialogue"

type State = AssessmentPitchIAPTScriptState

export type AssessmentPitchOxfordBucksScriptState = State

export class AssessmentPitchOxfordBucksScript extends AssessmentPitchIAPTScript {
  readonly name: string = "AssessmentPitchOxfordBucksScript"

  /** Script Steps */
  @step.logState
  start(d: IStepData<State>): IStepResult {
    this.updateReferralType(d.state)
    if (d.state.phoneNumber || d.state.isPhoneNumberAvailable) {
      return { nextStep: this.checkForNeedToBeCalled }
    }
    return { nextStep: this.goToCollectPhoneNumber }
  }

  @step.logState
  checkForNeedToBeCalled(_d: IStepData<State>): IStepResult {
    return { nextStep: this.sayWeCanSpeedUpReferral }
  }

  @step.logState
  sayWeCanSpeedUpReferral(d: IStepData<State>): IStepResult {
    const serviceName =
      this.rootStore.configStore.serviceName ?? this.rootStore.configStore.organisationName
    return {
      body: `I'm now going to ask you a few questions to measure symptoms of common mental health issues, so that ${serviceName} can match you / make decisions about your care`,
      prompt: {
        id: this.getPromptId("sayWeCanSpeedUpReferral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      },
      nextStep: this.end
    }
  }

  /** Generic Handlers */
}

export default class AssessmentPitchOxfordBucksDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentPitchOxfordBucks
  readonly name: string = "AssessmentPitchOxfordBucksDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      AssessmentPitchOxfordBucksDialogue.id,
      new AssessmentPitchOxfordBucksScript(),
      state,
      snapshot
    )
  }
}
