import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { UnderAgeSignpostScript, UnderAgeSignpostScriptState } from "./UnderAgeSignpostDialogue"
import { DialogueIDs } from "../../DialogueIDs"

type State = UnderAgeSignpostScriptState
export type UnderAgeSignpostMPFTScriptState = State

export class UnderAgeSignpostMPFTScript extends UnderAgeSignpostScript {
  readonly name: string = "UnderAgeSignpostMPFTScript"

  /** Script Steps */
  @step.logState
  sayNotEligible(d: IStepData<State>): IStepResult {
    const threshold = this.getIAPTServiceAgeThreshold(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    const name = this.getName(d.state)
    return {
      body: [
        `So ${name}, there's a special type of mental health support for people under the age of ${threshold}`,
        `${iaptName} do not currently provide this`,
        "However, you can find loads of helpful resources from [BeeU](https://camhs.mpft.nhs.uk/beeu)",
        "Or you can talk to your doctor and they will help you"
      ],
      prompt: {
        id: this.getPromptId("sayNotEligible"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Okay", value: "okay" },
          { body: "Thanks", value: "thanks" }
        ]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleNotEligible
    }
  }
}

/* istanbul ignore next */
export default class UnderAgeSignpostMPFTDialogue extends Dialogue<State> {
  static id = DialogueIDs.UnderageSignpostMPFT
  readonly name: string = "UnderAgeSignpostMPFTDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(UnderAgeSignpostMPFTDialogue.id, new UnderAgeSignpostMPFTScript(), state, snapshot)
  }
}
