const customFields: Record<string, Record<"allFields" | "requiredFields", string[]>> = {
  HEALIX: {
    allFields: [
      "firstName",
      "middleNames",
      "lastName",
      "address",
      "dateOfBirth",
      "companyName",
      "memberID",
      "coverageLevel",
      "coverEndDate",
      "insurerID",
      "availableCover"
    ],
    requiredFields: ["coverEndDate", "insurerID", "availableCover"]
  },
  VITALITY: {
    allFields: [
      "firstName",
      "middleNames",
      "lastname",
      "entityId",
      "claimNumber",
      "nextRenewalDate",
      "isEligible",
      "excessAmount",
      "excessType",
      "staffMember",
      "planType"
    ],
    requiredFields: ["entityId", "isEligible"]
  }
}

export default customFields
