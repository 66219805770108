import React, { useState } from "react"
import "./PhoneNumberInput.scss"
import TextInput from "./TextInput"
import { useApplicationStore, useConfigStore } from "../../contexts/RootStoreContext"
import { COUNTRIES_MAP, ICountry } from "@limbic/types"
import Select, { OptionsOrGroups } from "react-select"
import InlineButton from "../InlineButton/InlineButton"
import classnames from "classnames"
import { parsePhoneNumber } from "awesome-phonenumber"
import invariant from "../../../utils/invariant"

const DEFAULT_COUNTRY_CODE = process.env.REACT_APP_DEFAULT_PHONE_NUMBER_REGION_CODE
invariant(DEFAULT_COUNTRY_CODE, "Default country code is required")
const DEFAULT_COUNTRY = {
  value: COUNTRIES_MAP[DEFAULT_COUNTRY_CODE].isoAlpha2Code,
  label: `${COUNTRIES_MAP[DEFAULT_COUNTRY_CODE].name} (${COUNTRIES_MAP[DEFAULT_COUNTRY_CODE].isoAlpha2Code}) +${COUNTRIES_MAP[DEFAULT_COUNTRY_CODE].phonePrefix}`
}
invariant(DEFAULT_COUNTRY, `Unrecognised default Country code [${DEFAULT_COUNTRY_CODE}] passed`)

interface Props {
  forceMobile?: boolean
  forceLandline?: boolean
  placeholder?: string
  supportedCountries?: string[]
  cancelLabel?: string
  cancelIsEmptySubmit?: boolean
  skipValue?: string
  onCancel?: () => void
  onSubmit: (phoneNumber: string) => void
}

export default function PhoneNumberInput(props: Props): JSX.Element {
  const {
    forceMobile,
    forceLandline,
    placeholder,
    cancelLabel,
    supportedCountries,
    onCancel,
    cancelIsEmptySubmit,
    skipValue
  } = props
  const app = useApplicationStore()
  const config = useConfigStore()

  const options = React.useMemo(() => {
    return (supportedCountries ?? [])
      .filter(code => !!COUNTRIES_MAP[code]) // making sure nothing weird is passed
      .map(code => {
        const c: ICountry = COUNTRIES_MAP[code]
        return {
          value: c.isoAlpha2Code,
          label: `${c.name} (${c.isoAlpha2Code}) +${c.phonePrefix}`
        }
      })
      .filter(Boolean) as OptionsOrGroups<any, any>
  }, [supportedCountries])

  const [selectedCountry, setSelectedCountry] = useState(() => options[0] ?? DEFAULT_COUNTRY)
  const [phone, setPhone] = useState<string>()
  const [phoneError, setPhoneError] = useState<string | undefined>()
  const [disableSubmit, setDisableSubmit] = useState(true)

  const submitButtonCSS = classnames("lb-user-input-phone-number-submit-button", {
    disabled: disableSubmit
  })

  const handlePhoneChange = (text?: string) => {
    setPhoneError(undefined)
    setDisableSubmit(!text?.trim())
    setPhone(text)
  }

  const handleCountryChange = selectedOption => {
    setSelectedCountry(selectedOption)
  }

  const isValidPhone = () => {
    if (!phone?.trim()) {
      setPhoneError(app.t("Phone number cannot be empty"))
      return
    }

    const parsedNumber = parsePhoneNumber(phone, { regionCode: selectedCountry?.value })

    if (!parsedNumber.valid) {
      setPhoneError(app.t("Phone number is not valid for the selected country"))
      return
    }

    if (forceMobile && !parsedNumber.typeIsMobile) {
      setPhoneError(app.t("Phone number is not a mobile number for the selected country"))
      return
    }

    if (forceLandline && !parsedNumber.typeIsFixedLine) {
      setPhoneError(app.t("Phone number is not a landline number for the selected country"))
      return
    }
    setPhoneError(undefined)
    return true
  }

  const handleOnSubmit = () => {
    if (isValidPhone()) {
      const parsedNumber = parsePhoneNumber(phone ?? "", { regionCode: selectedCountry?.value })
      props.onSubmit?.(parsedNumber.number?.international ?? "")
      return
    }
  }

  return (
    <div className="lb-user-input-phone-number-container">
      {!!options.length && (
        <Select
          options={options}
          defaultValue={DEFAULT_COUNTRY}
          isSearchable={true}
          value={selectedCountry}
          onChange={handleCountryChange}
          styles={{ container: base => ({ ...base, paddingLeft: 16, paddingRight: 16 }) }}
        />
      )}

      <ErrorInfo error={phoneError} />
      <TextInput
        forceValue
        borderless
        trimAllSpacesOnSubmit
        trimAllSpacesOnValidation
        dataTestId="phone-number-input"
        placeholder={placeholder || app.t("Type your phone number")}
        clearOnSubmit={false}
        autoCorrect={"none"}
        autoCapitalize={"none"}
        autoComplete={"phoneNumber"}
        onSubmit={handleOnSubmit}
        cancelIsEmptySubmit={cancelIsEmptySubmit}
        cancelLabel={cancelLabel}
        onCancel={onCancel}
        skipValue={skipValue}
        showOnSubmitButton={false}
        onChangeText={handlePhoneChange}
      />
      <InlineButton //
        fullWidth
        disabled={disableSubmit}
        style={{ backgroundColor: config.userMessageBackground }}
        btn={{ body: app.t("Submit") }}
        onSelect={handleOnSubmit}
        buttonClassName={submitButtonCSS}
      />
    </div>
  )
}

interface IErrorInfoProps {
  error?: string
}

function ErrorInfo(props: IErrorInfoProps) {
  const { error } = props
  if (!error) return null
  return (
    <div className="lb-phone-number-input-error-container ">
      <span className="lb-phone-number-input-error">{error}</span>
    </div>
  )
}
