import React from "react"
import { observer } from "mobx-react"
import "./Sidebar.scss"
import "./SidebarEssex.scss"
import { useConfigStore } from "../../contexts/RootStoreContext"

export interface Props {
  visible?: boolean
}

function Sidebar(props: Props): JSX.Element | null {
  const config = useConfigStore()
  const { visible } = props
  if (!visible) {
    return null
  }

  return (
    <div className="lb-sidebar-container">
      <article className="lb-sidebar-card">
        <div className="lb-header">
          <img src={config.logo} className="lb-header-img" alt="" />
          <h3 className="subtitle">Refer yourself to Living Well Consortium</h3>
        </div>
        <p>
          We are your local provider of the NHS talking therapies service. It's a free service. We
          are open and ready to support you. We offer a range of short-term psychological therapies
          to adults. We do not offer diagnosis. Please read more about what we have available in the
          link below:
          <br />
          <a
            href="https://livingwellconsortium.com/psychological-therapies/"
            rel="noreferrer"
            target="_blank">
            https://livingwellconsortium.com/psychological-therapies/
          </a>
        </p>
        <p>
          If you feel that your referral is something you would like to discuss with the team please
          call us on 0121 663 1217 to progress this. We are available 9am - 5pm Monday to Thursday,
          and 9am - 4pm on Friday
        </p>
        {config.iaptIcons?.map?.(link => (
          <img key={link} className="nhs-logo" src={link} alt={link} />
        ))}
      </article>
      <div className="lb-sidebar-spacer" />
      <article className="lb-sidebar-card">
        <p>
          Limbic helps people access psychological support quicker and easier. Your data is secured
          according to the highest NHS standards.
        </p>
        <img
          className="badges"
          src="https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/data-pics.png"
          alt={"Badges"}
        />
      </article>
    </div>
  )
}

export default observer(Sidebar)
