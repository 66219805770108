import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import dialoguesRegistry from "../../dialoguesRegistry"
import type { SurveyScriptState } from "../../createSurveyDialogue"
import { ITQTimeAgo } from "@limbic/types"
import AssessmentIAPTScript, { AssessmentIAPTScriptState } from "./AssessmentIAPTScript"

interface State extends AssessmentIAPTScriptState {
  experienceThatTroublesYou?: string
  whenDidThatExperienceOccur?: ITQTimeAgo
}

export type AssessmentGMHubScriptState = State

export class AssessmentGMHubScript extends AssessmentIAPTScript {
  readonly name: string = "AssessmentGMHubScript"

  /** Script Steps */
  @step.logState
  start(d: IStepData<State>): IStepResult {
    if (this.wellbeingHubStore.userType !== "individual") {
      return {
        nextStep: this.goToGoodbye
      }
    }
    this.rootStore.applicationStore.setTotalProgressBars(8)
    this.rootStore.applicationStore.setCurrentProgressBar(1)
    this.rootStore.applicationStore.setCurrentProgress(0)
    return {
      nextStep: this.step1
    }
  }

  @step.logState
  goToPart2(_d: IStepData<State>): IStepResult {
    return {
      nextStep: this.goToDrugsAndSmoking
    }
  }

  @step.logState
  goToDrugsAndSmoking(d: IStepData<State>): IStepResult {
    const DrugsAndSmoking = dialoguesRegistry.get(DialogueIDs.DrugsAndSmoking)
    return {
      nextDialogue: new DrugsAndSmoking({ ...d.state }),
      nextStep: this.handleDrugsAndSmokingQuestions
    }
  }

  @step.logStateAndResponse
  async handleDrugsAndSmokingQuestions(
    d: IStepData<State, undefined, SurveyScriptState>
  ): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    await this.handleDrugsAndSmokingQuestionsResponses(d.state)
    this.rootStore.applicationStore.setCurrentProgress(1)
    return { nextStep: this.goToAudit }
  }

  @step.logState
  goToAudit(d: IStepData<State>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgressBar(6)
    this.rootStore.applicationStore.setCurrentProgress(0)
    const AuditDialogue = dialoguesRegistry.get(DialogueIDs.Audit)
    return {
      nextDialogue: new AuditDialogue({ ...d.state }),
      nextStep: this.handleAudit
    }
  }

  @step.logStateAndResponse
  async handleAudit(d: IStepData<State, undefined, SurveyScriptState>): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    await this.handleAuditResponses(d.state)
    this.rootStore.applicationStore.setCurrentProgress(1)
    return {
      body: this.t(
        "The next set of questions takes a look at stressful and potentially traumatic life events"
      ),
      nextStep: this.askExperienceThatTroublesYou
    }
  }

  @step.logState
  askExperienceThatTroublesYou(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t(
        "Firstly {name}, could you identify an experience that troubles you the most, and provide a brief description?",
        { name }
      ),
      prompt: {
        id: this.getPromptId("askExperienceThatTroublesYou"),
        type: "text",
        cancelIsEmptySubmit: true,
        dataPointsName: "askExperienceThatTroublesYou"
      },
      nextStep: this.handleExperienceThatTroublesYouWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>, script: AssessmentGMHubScript) => {
    d.state.experienceThatTroublesYou = d.response
    script.referralStore.updateReferral({ questionnaireITQExperienceDetails: d.response })
  })
  @step.checkInputForCrisis({
    getNextStep: (s: AssessmentGMHubScript) => s.askWhenDidThatExperienceOccur
  })
  handleExperienceThatTroublesYouWithCrisis(_d: IStepData<State, string>): IStepResult {
    return {
      body: this.t("I appreciate that may have been difficult"),
      nextStep: this.askWhenDidThatExperienceOccur
    }
  }

  @step
  askWhenDidThatExperienceOccur(d: IStepData<State>): IStepResult {
    if (d.state.experienceThatTroublesYou) {
      return {
        body: this.t("When did the experience occur?"),
        prompt: {
          id: this.getPromptId("askWhenDidThatExperienceOccur"),
          type: "inlinePicker",
          choices: [
            { body: this.t("Less than 6 months ago"), value: "Less than 6 months ago" },
            { body: this.t("6 to 12 months ago"), value: "6 to 12 months ago" },
            { body: this.t("1 to 5 years ago"), value: "1 to 5 years ago" },
            { body: this.t("5 to 10 years ago"), value: "5 to 10 years ago" },
            { body: this.t("10 to 20 years ago"), value: "10 to 20 years ago" },
            { body: this.t("More than 20 years ago"), value: "More than 20 years ago" }
          ],
          dataPointsName: "askWhenDidThatExperienceOccur",
          isUndoAble: true
        },
        nextStep: this.handleWhenDidThatExperienceOccur
      }
    }
    return {
      nextStep: this.handleWhenDidThatExperienceOccur
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, ITQTimeAgo>, script: AssessmentGMHubScript) => {
    d.state.whenDidThatExperienceOccur = d.response
    script.referralStore.updateReferral({ questionnaireITQTimeAgo: d.response })
  })
  handleWhenDidThatExperienceOccur(_d: IStepData<State, ITQTimeAgo>): IStepResult {
    return { nextStep: this.goToITQ }
  }

  @step.logState
  goToITQ(d: IStepData<State>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgressBar(7)
    this.rootStore.applicationStore.setCurrentProgress(0)
    const ITQDialogue = dialoguesRegistry.get(DialogueIDs.ITQ)
    const name = this.getName(d.state)
    return {
      body: this.t("Thanks {name}", { name }),
      nextDialogue: new ITQDialogue({ ...d.state }),
      nextStep: this.handleITQ
    }
  }

  @step.logStateAndResponse
  async handleITQ(d: IStepData<State, undefined, SurveyScriptState>): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    await this.handleITQResponses(d.state)
    this.rootStore.applicationStore.setCurrentProgress(1)
    return { nextStep: this.goToIRQA }
  }

  @step.logState
  goToIRQA(d: IStepData<State>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgressBar(8)
    this.rootStore.applicationStore.setCurrentProgress(0)
    const IRQADialogue = dialoguesRegistry.get(DialogueIDs.IRQA)
    return {
      nextDialogue: new IRQADialogue({ ...d.state }),
      nextStep: this.handleIRQA
    }
  }

  @step.logStateAndResponse
  async handleIRQA(d: IStepData<State, undefined, SurveyScriptState>): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    await this.handleIRQAResponses(d.state)
    this.rootStore.applicationStore.setCurrentProgress(1)
    return { nextStep: this.sayThatsEverything }
  }

  /** Generic Handlers */

  async onHandleIAPTPhobiaScale(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayReadyForWASAS }
  }

  async onHandleIAPTWorkAndSocialAdjustment(_state: State): Promise<IStepResult<State>> {
    this.rootStore.applicationStore.setCurrentProgressBar(5)
    this.rootStore.applicationStore.setCurrentProgress(0)
    return { nextStep: this.goToPart2 }
  }

  handleAuditResponses(state: State): void {
    this.sendAudit(state)
  }

  handleDrugsAndSmokingQuestionsResponses(state: State): void {
    this.sendDrugsAndSmoking(state)
  }

  handleITQResponses(state: State): void {
    this.sendITQ(state)
  }

  handleIRQAResponses(state: State): void {
    this.sendIRQA(state)
  }
}

export default class AssessmentGMHubDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentGMHub
  readonly name: string = "AssessmentGMHubDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentGMHubDialogue.id, new AssessmentGMHubScript(), state, snapshot)
  }
}
