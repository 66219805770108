import { IIAPTService } from "../models/IIAPTService"
import { IGPService, IGPServiceODS } from "../models/IGPService"

export default function getIsEligibleGPForIAPT(
  iapt?: IIAPTService,
  gp?: IGPService | IGPServiceODS
): boolean {
  if (!iapt?.eligibleGPs?.length) return true
  return !!iapt.eligibleGPs?.find(g => g.id === gp?.id)
}
