import ChatFlowPayloadBuilder from "../ChatFlowPayloadBuilder"
import { ChatFlowsEnum, ReferralPayload } from "@limbic/types"

export default class CovidPayloadBuilder extends ChatFlowPayloadBuilder<ChatFlowsEnum.ASK_COVID_DETAILS> {
  chatFlow = ChatFlowsEnum.ASK_COVID_DETAILS

  /** Overrides */

  getDefaultPayload(): Partial<Partial<ReferralPayload>> {
    return {
      covidStatus: this.ctx.state?.covidStatus,
      covidDate: this.ctx.state?.covidDate,
      longCovid: this.ctx.state?.hasLongCovid,
      longCovidReferral: this.ctx.state?.isReferralForLongCovid
    } as Partial<ReferralPayload>
  }
}

ChatFlowPayloadBuilder.builders[ChatFlowsEnum.ASK_COVID_DETAILS] = CovidPayloadBuilder
