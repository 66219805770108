import CrisisScript, { CrisisScriptState } from "./CrisisScript"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = CrisisScriptState

export type CrisisLincolnshireScriptState = State

export class CrisisLincolnshireScript extends CrisisScript {
  readonly name: string = "CrisisLincolnshireScript"

  @step
  saySorryToHear(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t(
        [
          "Sorry to hear that {name}",
          "It is normal for people to have thoughts of this nature at times",
          "However, this is not an emergency response service",
          "If you need urgent, life threatening medical help please call [999](tel:999)",
          "Please contact the Mental Health Matters Helpline on 0800 001 4331 or SPA on 0303 123 4000 or your GP if you feel you are at risk of harming yourself"
        ],
        { name }
      ),
      prompt: {
        id: this.getPromptId("saySorryToHear"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("I understand") }, { body: this.t("Okay") }]
      },
      nextStep: this.handleSorryToHear
    }
  }

  @step.logState
  handleSorryToHear(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField("crisisNumbersShared", "999 and crisis numbers from services")
    return { nextStep: this.sayTheyWillCall }
  }

  sayTheyWillCall(d: IStepData<State>): IStepResult {
    if (!d.state.phoneNumber) {
      return { nextStep: this.askPhoneNumber }
    }

    const serviceName = this.rootStore.configStore.serviceName
    return {
      body: this.t(
        "One of {serviceName}' clinical team will contact you on {phoneNumber} within one working day to check that you have referred yourself in to one of the crisis support services",
        { serviceName, phoneNumber: d.state.phoneNumber }
      ),
      nextStep: this.end
    }
  }

  @step
  async sayInstructions(d: IStepData<State>): Promise<IStepResult> {
    // Extending sayInstructions because we are not sending risk emails for Lincolnshire
    // Instead a tag will be set in the referral (from what I've been told)
    return {
      body: this.t([
        "In the meantime, if you feel that you or someone you know is in danger, please call 999 immediately",
        "Further support is also provided by the Samaritans, available anytime by calling: 116 123"
      ]),
      prompt: {
        id: this.getPromptId("sayInstructions"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }, { body: this.t("I understand") }]
      },
      nextStep: this.handleSayInstructions
    }
  }
}

/* istanbul ignore next */
export default class CrisisLincolnshireDialogue extends Dialogue<State> {
  static id = DialogueIDs.CrisisLincolnshire
  readonly name: string = "CrisisLincolnshireDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(CrisisLincolnshireDialogue.id, new CrisisLincolnshireScript(), state, snapshot)
  }
}
