/* eslint-disable @typescript-eslint/no-var-requires */
/* istanbul ignore file */
import { DialogueIDs } from "./DialogueIDs"
import type MainFlowDialogue from "./dialogues/MainFlowDialogue"
import type IntroductionDynamicDialogue from "./dialogues/introduction/IntroductionDynamicDialogue"
import type IntroductionIAPTDialogue from "./dialogues/introduction/IntroductionIAPTDialogue"
import type IntroductionWellbeingHubDialogue from "./dialogues/introduction/IntroductionWellbeingHubDialogue"
import type IntroductionGPDialogue from "./dialogues/introduction/IntroductionGPDialogue"
import type IntroductionEssexDialogue from "./dialogues/introduction/IntroductionEssexDialogue"
import type IntroductionHealixDialogue from "./dialogues/introduction/IntroductionHealixDialogue"
import type IntroductionGMHubDialogue from "./dialogues/introduction/IntroductionGMHubDialogue"
import type IntroductionDemoPrivateProvidersDialogue from "./dialogues/introduction/IntroductionDemoPrivateProvidersDialogue"
import type IntroductionInsightDialogue from "./dialogues/introduction/IntroductionInsightDialogue"
import type IntroductionInsightWaitlistDialogue from "./dialogues/introduction/IntroductionInsightWaitlistDialogue"
import type IntroductionInsightFollowUpDialogue from "./dialogues/introduction/IntroductionInsightFollowUpDialogue"
import type IntroductionOxfordDialogue from "./dialogues/introduction/IntroductionOxfordDialogue"
import type IntroductionBuckinghamshireDialogue from "./dialogues/introduction/IntroductionBuckinghamshireDialogue"
import type IntroductionVitalityDialogue from "./dialogues/introduction/IntroductionVitalityDialogue"
import type IntroductionMPFTDialogue from "./dialogues/introduction/IntroductionMPFTDialogue"
import type IntroductionVHGDialogue from "./dialogues/introduction/IntroductionVHGDialogue"
import type IntroductionMindDialogue from "./dialogues/introduction/IntroductionMindDialogue"
import type IntroductionLWCDialogue from "./dialogues/introduction/IntroductionLWCDialogue"
import type IntroductionDorkingDialogue from "./dialogues/introduction/IntroductionDorkingDialogue"
import type GetNameDynamicDialogue from "./dialogues/getName/GetNameDynamicDialogue"
import type GetNameBradfordDialogue from "./dialogues/getName/GetNameBradfordDialogue"
import type GetNameIAPTDialogue from "./dialogues/getName/GetNameIAPTDialogue"
import type GetNameEssexDialogue from "./dialogues/getName/GetNameEssexDialogue"
import type GetNameInsightDialogue from "./dialogues/getName/GetNameInsightDialogue"
import type GetNameLincolnshireDialogue from "./dialogues/getName/GetNameLincolnshireDialogue"
import type GetNameHealixDialogue from "./dialogues/getName/GetNameHealixDialogue"
import type GetNameOxfordBucksDialogue from "./dialogues/getName/GetNameOxfordBucksDialogue"
import type GetNameDemoPrivateProvidersDialogue from "./dialogues/getName/GetNameDemoPrivateProvidersDialogue"
import type GetNameInsightWaitlistDialogue from "./dialogues/getName/GetNameInsightWaitlistDialogue"
import type GetNameInsightFollowUpDialogue from "./dialogues/getName/GetNameInsightFollowUpDialogue"
import type GetNameSABPDialogue from "./dialogues/getName/GetNameSABPDialogue"
import type GetNameVHGDialogue from "./dialogues/getName/GetNameVHGDialogue"
import type GetNameVitalityDialogue from "./dialogues/getName/GetNameVitalityDialogue"
import type GetNameMPFTDialogue from "./dialogues/getName/GetNameMPFTDialogue"
import type GetNameMHMDialogue from "./dialogues/getName/GetNameMHMDialogue"
import type GetNameMindDialogue from "./dialogues/getName/GetNameMindDialogue"
import type GetNameLWCDialogue from "./dialogues/getName/GetNameLWCDialogue"
import type GetNameCWDialogue from "./dialogues/getName/GetNameCWDialogue"
import type GetNameDorkingDialogue from "./dialogues/getName/GetNameDorkingDialogue"
import type EligibilityCheckDynamicDialogue from "./dialogues/eligibility/EligibilityCheckDynamicDialogue"
import type EligibilityCheckBradfordDialogue from "./dialogues/eligibility/EligibilityCheckBradfordDialogue"
import type EligibilityCheckDemoDialogue from "./dialogues/eligibility/EligibilityCheckDemoDialogue"
import type EligibilityCheckEssexDialogue from "./dialogues/eligibility/EligibilityCheckEssexDialogue"
import type EligibilityCheckIAPTDialogue from "./dialogues/eligibility/EligibilityCheckIAPTDialogue"
import type EligibilityCheckGPDialogue from "./dialogues/eligibility/EligibilityCheckGPDialogue"
import type EligibilityCheckWellbeingHubDialogue from "./dialogues/eligibility/EligibilityCheckWellbeingHubDialogue"
import type EligibilityCheckGMHubDialogue from "./dialogues/eligibility/EligibilityCheckGMHubDialogue"
import type EligibilityCheckInsightDialogue from "./dialogues/eligibility/EligibilityCheckInsightDialogue"
import type EligibilityCheckOxfordDialogue from "./dialogues/eligibility/EligibilityCheckOxfordDialogue"
import type EligibilityCheckBuckinghamshireDialogue from "./dialogues/eligibility/EligibilityCheckBuckinghamshireDialogue"
import type EligibilityCheckLincolnshireDialogue from "./dialogues/eligibility/EligibilityCheckLincolnshireDialogue"
import type EligibilityCheckSABPDialogue from "./dialogues/eligibility/EligibilityCheckSABPDialogue"
import type EligibilityCheckVHGDialogue from "./dialogues/eligibility/EligibilityCheckVHGDialogue"
import type EligibilityCheckVitalityDialogue from "./dialogues/eligibility/EligibilityCheckVitalityDialogue"
import type EligibilityCheckMPFTDialogue from "./dialogues/eligibility/EligibilityCheckMPFTDialogue"
import type EligibilityCheckMHMDialogue from "./dialogues/eligibility/EligibilityCheckMHMDialogue"
import type EligibilityCheckMindDialogue from "./dialogues/eligibility/EligibilityCheckMindDialogue"
import type EligibilityCheckCWDialogue from "./dialogues/eligibility/EligibilityCheckCWDialogue"
import type EligibilityCheckDorkingDialogue from "./dialogues/eligibility/EligibilityCheckDorkingDialogue"
import type SuitableServicesSignpostIAPTDialogue from "./dialogues/signposts/SuitableServicesSignpostIAPTDialogue"
import type SuitableServicesSignpostCCGDialogue from "./dialogues/signposts/SuitableServicesSignpostCCGDialogue"
import type SuitableServicesSignpostSABPDialogue from "./dialogues/signposts/SuitableServicesSignpostSABPDialogue"
import type SuitableServicesSignpostEssexDialogue from "./dialogues/signposts/SuitableServicesSignpostEssexDialogue"
import type SuitableServicesSignpostMindDialogue from "./dialogues/signposts/SuitableServicesSignpostMindDialogue"
import type SuitableServicesSignpostLWCDialogue from "./dialogues/signposts/SuitableServicesSignpostLWCDialogue"
import type SuitableServicesSignpostOxfordDialogue from "./dialogues/signposts/SuitableServicesSignpostOxfordDialogue"
import type SuitableServicesSignpostBuckinghamshireDialogue from "./dialogues/signposts/SuitableServicesSignpostBuckinghamshireDialogue"
import type UnderAgeSignpostDialogue from "./dialogues/signposts/UnderAgeSignpostDialogue"
import type UnderAgeSignpostOxfordDialogue from "./dialogues/signposts/UnderAgeSignpostOxfordDialogue"
import type UnderAgeSignpostBuckinghamshireDialogue from "./dialogues/signposts/UnderAgeSignpostBuckinghamshireDialogue"
import type UnderAgeSignpostBradfordDialogue from "./dialogues/signposts/UnderAgeSignpostBradfordDialogue"
import type UnderAgeSignpostDorkingDialogue from "./dialogues/signposts/UnderAgeSignpostDorkingDialogue"
import type UnderAgeSignpostEssexDialogue from "./dialogues/signposts/UnderAgeSignpostEssexDialogue"
import type UnderAgeSignpostWellbeingHubDialogue from "./dialogues/signposts/UnderAgeSignpostWellbeingHubDialogue"
import type UnderAgeSignpostGMHubDialogue from "./dialogues/signposts/UnderAgeSignpostGMHubDialogue"
import type UnderAgeSignpostLincolnshireDialogue from "./dialogues/signposts/UnderAgeSignpostLincolnshireDialogue"
import type UnderAgeSignpostSABPDialogue from "./dialogues/signposts/UnderAgeSignpostSABPDialogue"
import type UnderAgeSignpostMPFTDialogue from "./dialogues/signposts/UnderAgeSignpostMPFTDialogue"
import type UnderAgeSignpostMindDialogue from "./dialogues/signposts/UnderAgeSignpostMindDialogue"
import type UnderAgeSignpostLWCDialogue from "./dialogues/signposts/UnderAgeSignpostLWCDialogue"
import type UnderAgeSignpostBexleyDialogue from "./dialogues/signposts/UnderAgeSignpostBexleyDialogue"
import type UnderAgeSignpostCWDialogue from "./dialogues/signposts/UnderAgeSignpostCWDialogue"
import type PeaceOfMindIAPTDialogue from "./dialogues/peaceOfMind/PeaceOfMindIAPTDialogue"
import type PeaceOfMindGPDialogue from "./dialogues/peaceOfMind/PeaceOfMindGPDialogue"
import type PeaceOfMindWellbeingHubDialogue from "./dialogues/peaceOfMind/PeaceOfMindWellbeingHubDialogue"
import type PeaceOfMindHealixDialogue from "./dialogues/peaceOfMind/PeaceOfMindHealixDialogue"
import type PeaceOfMindDemoPrivateProvidersDialogue from "./dialogues/peaceOfMind/PeaceOfMindDemoPrivateProvidersDialogue"
import type PeaceOfMindVitalityDialogue from "./dialogues/peaceOfMind/PeaceOfMindVitalityDialogue"
import type PermissionsDynamicDialogue from "./dialogues/permissions/PermissionsDynamicDialogue"
import type PermissionsEssexDialogue from "./dialogues/permissions/PermissionsEssexDialogue"
import type PermissionsIAPTDialogue from "./dialogues/permissions/PermissionsIAPTDialogue"
import type PermissionsWellbeingHubDialogue from "./dialogues/permissions/PermissionsWellbeingHubDialogue"
import type PermissionsGMDialogue from "./dialogues/permissions/PermissionsGMHubDialogue"
import type PermissionsSABPDialogue from "./dialogues/permissions/PermissionsSABPDialogue"
import type PermissionsInsightDialogue from "./dialogues/permissions/PermissionsInsightDialogue"
import type PermissionsDemoPrivateProvidersDialogue from "./dialogues/permissions/PermissionsDemoPrivateProvidersDialogue"
import type PermissionsVitalityDialogue from "./dialogues/permissions/PermissionsVitalityDialogue"
import type PermissionsMPFTDialogue from "./dialogues/permissions/PermissionsMPFTDialogue"
import type PermissionsMHMDialogue from "./dialogues/permissions/PermissionsMHMDialogue"
import type PermissionsLincolnshireDialogue from "./dialogues/permissions/PermissionsLincolnshireDialogue"
import type PermissionsMindDialogue from "./dialogues/permissions/PermissionsMindDialogue"
import type PermissionsLWCDialogue from "./dialogues/permissions/PermissionsLWCDialogue"
import type ResearchConsentDialogue from "./dialogues/researchConsent/ResearchConsentDialogue"
import type ResearchConsentSABPDialogue from "./dialogues/researchConsent/ResearchConsentSABPDialogue"
import type PermissionsOxfordBucksDialogue from "./dialogues/permissions/PermissionsOxfordBucksDialogue"
import type PermissionsCWDialogue from "./dialogues/permissions/PermissionsCWDialogue"
import type SelfReferralPitchIAPTDialogue from "./dialogues/selfReferralPitch/SelfReferralPitchIAPTDialogue"
import type SelfReferralPitchHealixDialogue from "./dialogues/selfReferralPitch/SelfReferralPitchHealixDialogue"
import type SelfReferralPitchGMHubDialogue from "./dialogues/selfReferralPitch/SelfReferralPitchGMHubDialogue"
import type SelfReferralPitchDialogueWellbeingHub from "./dialogues/selfReferralPitch/SelfReferralPitchDialogueWellbeingHub"
import type SelfReferralPitchDemoPrivateProvidersDialogue from "./dialogues/selfReferralPitch/SelfReferralPitchDemoPrivateProvidersDialogue"
import type SelfReferralDynamicDialogue from "./dialogues/selfReferral/SelfReferralDynamicDialogue"
import type SelfReferralPitchMindDialogue from "./dialogues/selfReferralPitch/SelfReferralPitchMindDialogue"
import type SelfReferralDemoDialogue from "./dialogues/selfReferral/SelfReferralDemoDialogue"
import type SelfReferralEssexDialogue from "./dialogues/selfReferral/SelfReferralEssexDialogue"
import type SelfReferralWellbeingHubDialogue from "./dialogues/selfReferral/SelfReferralWellbeingHubDialogue"
import type SelfReferralWellbeingHubIndividualDialogue from "./dialogues/selfReferral/SelfReferralWellbeingHubIndividualDialogue"
import type SelfReferralWellbeingHubManagerIndividualDialogue from "./dialogues/selfReferral/SelfReferralWellbeingHubManagerIndividualDialogue"
import type SelfReferralWellbeingHubManagerTeamDialogue from "./dialogues/selfReferral/SelfReferralWellbeingHubManagerTeamDialogue"
import type SelfReferralGMHubDialogue from "./dialogues/selfReferral/SelfReferralGMHubDialogue"
import type SelfReferralGMHubManagerTeamDialogue from "./dialogues/selfReferral/SelfReferralGMHubManagerTeamDialogue"
import type SelfReferralGMHubManagerIndividualDialogue from "./dialogues/selfReferral/SelfReferralGMHubManagerIndividualDialogue"
import type SelfReferralGMHubIndividualDialogue from "./dialogues/selfReferral/SelfReferralGMHubIndividualDialogue"
import type SelfReferralHealixDialogue from "./dialogues/selfReferral/SelfReferralHealixDialogue"
import type SelfReferralInsightDialogue from "./dialogues/selfReferral/SelfReferralInsightDialogue"
import type SelfReferralSABPDialogue from "./dialogues/selfReferral/SelfReferralSABPDialogue"
import type SelfReferralLincolnshireDialogue from "./dialogues/selfReferral/SelfReferralLincolnshireDialogue"
import type SelfReferralVHGDialogue from "./dialogues/selfReferral/SelfReferralVHGDialogue"
import type SelfReferralDemoPrivateProvidersDialogue from "./dialogues/selfReferral/SelfReferralDemoPrivateProvidersDialogue"
import type SelfReferralInsightWaitlistDialogue from "./dialogues/selfReferral/SelfReferralInsightWaitlistDialogue"
import type SelfReferralVitalityDialogue from "./dialogues/selfReferral/SelfReferralVitalityDialogue"
import type SelfReferralMPFTDialogue from "./dialogues/selfReferral/SelfReferralMPFTDialogue"
import type SelfReferralMindDialogue from "./dialogues/selfReferral/SelfReferralMindDialogue"
import type ADSMConsentDialogue from "./dialogues/adsmConsent/ADSMConsentDialogue"
import type ADSMConsentInsightDialogue from "./dialogues/adsmConsent/ADSMConsentInsightDialogue"
import type SelfReferralMHMDialogue from "./dialogues/selfReferral/SelfReferralMHMDialogue"
import type AssessmentPitchBradfordDialogue from "./dialogues/assessmentPitch/AssessmentPitchBradfordDialogue"
import type AssessmentPitchIAPTDialogue from "./dialogues/assessmentPitch/AssessmentPitchIAPTDialogue"
import type AssessmentPitchGPDialogue from "./dialogues/assessmentPitch/AssessmentPitchGPDialogue"
import type AssessmentPitchEssexDialogue from "./dialogues/assessmentPitch/AssessmentPitchEssexDialogue"
import type AssessmentPitchWellbeingHubDialogue from "./dialogues/assessmentPitch/AssessmentPitchWellbeingHubDialogue"
import type AssessmentPitchGMHubDialogue from "./dialogues/assessmentPitch/AssessmentPitchGMHubDialogue"
import type AssessmentPitchHealixDialogue from "./dialogues/assessmentPitch/AssessmentPitchHealixDialogue"
import type AssessmentPitchInsightDialogue from "./dialogues/assessmentPitch/AssessmentPitchInsightDialogue"
import type AssessmentPitchInsightFollowUpDialogue from "./dialogues/assessmentPitch/AssessmentPitchInsightFollowUpDialogue"
import type AssessmentPitchLincolnshireDialogue from "./dialogues/assessmentPitch/AssessmentPitchLincolnshireDialogue"
import type AssessmentPitchOxfordBucksDialogue from "./dialogues/assessmentPitch/AssessmentPitchOxfordBucksDialogue"
import type AssessmentPitchSABPDialogue from "./dialogues/assessmentPitch/AssessmentPitchSABPDialogue"
import type AssessmentPitchVHGDialogue from "./dialogues/assessmentPitch/AssessmentPitchVHGDialogue"
import type AssessmentPitchDemoPrivateProvidersDialogue from "./dialogues/assessmentPitch/AssessmentPitchDemoPrivateProvidersDialogue"
import type AssessmentPitchVitalityDialogue from "./dialogues/assessmentPitch/AssessmentPitchVitalityDialogue"
import type AssessmentPitchMPFTDialogue from "./dialogues/assessmentPitch/AssessmentPitchMPFTDialogue"
import type AssessmentPitchMHMDialogue from "./dialogues/assessmentPitch/AssessmentPitchMHMDialogue"
import type AssessmentBradfordDialogue from "./dialogues/assessment/AssessmentBradfordDialogue"
import type AssessmentPitchMindDialogue from "./dialogues/assessmentPitch/AssessmentPitchMindDialogue"
import type AssessmentPitchLWCDialogue from "./dialogues/assessmentPitch/AssessmentPitchLWCDialogue"
import type AssessmentPitchCWDialogue from "./dialogues/assessmentPitch/AssessmentPitchCWDialogue"
import type AssessmentPitchDorkingDialogue from "./dialogues/assessmentPitch/AssessmentPitchDorkingDialogue"
import type AssessmentDynamicDialogue from "./dialogues/assessment/AssessmentDynamicDialogue"
import type AssessmentDemoDialogue from "./dialogues/assessment/AssessmentDemoDialogue"
import type AssessmentEssexDialogue from "./dialogues/assessment/AssessmentEssexDialogue"
import type AssessmentWellbeingHubDialogue from "./dialogues/assessment/AssessmentWellbeingHubDialogue"
import type AssessmentSABPDialogue from "./dialogues/assessment/AssessmentSABPDialogue"
import type AssessmentGMHubDialogue from "./dialogues/assessment/AssessmentGMHubDialogue"
import type AssessmentHealixDialogue from "./dialogues/assessment/AssessmentHealixDialogue"
import type AssessmentInsightDialogue from "./dialogues/assessment/AssessmentInsightDialogue"
import type AssessmentADSMDynamicDialogue from "./dialogues/assessment/AssessmentADSMDynamicDialogue"
import type AssessmentADSMInsightDialogue from "./dialogues/assessment/AssessmentADSMInsightDialogue"
import type AssessmentOxfordBucksDialogue from "./dialogues/assessment/AssessmentOxfordBucksDialogue"
import type AssessmentVHGDialogue from "./dialogues/assessment/AssessmentVHGDialogue"
import type AssessmentLincolnshireDialogue from "./dialogues/assessment/AssessmentLincolnshireDialogue"
import type AssessmentDemoPrivateProvidersDialogue from "./dialogues/assessment/AssessmentDemoPrivateProvidersDialogue"
import type AssessmentVitalityDialogue from "./dialogues/assessment/AssessmentVitalityDialogue"
import type AssessmentMPFTDialogue from "./dialogues/assessment/AssessmentMPFTDialogue"
import type AssessmentMHMDialogue from "./dialogues/assessment/AssessmentMHMDialogue"
import type AssessmentMindDialogue from "./dialogues/assessment/AssessmentMindDialogue"
import type AssessmentLWCDialogue from "./dialogues/assessment/AssessmentLWCDialogue"
import type AssessmentCWDialogue from "./dialogues/assessment/AssessmentCWDialogue"
import type AssessmentDorkingDialogue from "./dialogues/assessment/AssessmentDorkingDialogue"
import type PHQ9Dialogue from "./surveys/PHQ9/PHQ9"
import type PHQ9DynamicDialogue from "./surveys/PHQ9/PHQ9DynamicDialogue"
import type PHQ9GMHubDialogue from "./surveys/PHQ9/PHQ9GMHubDialogue"
import type PHQ9WellbeingHubDialogue from "./surveys/PHQ9/PHQ9WellbeingHubDialogue"
import type PHQ9SABPDialogue from "./surveys/PHQ9/PHQ9SABPDialogue"
import type PHQ9VHGDialogue from "./surveys/PHQ9/PHQ9VHGDialogue"
import type PHQ9VitalityDialogue from "./surveys/PHQ9/PHQ9VitalityDialogue"
import type RiskPathwayDynamicDialogue from "./dialogues/riskPathway/RiskPathwayDynamicDialogue"
import type RiskPathwayBradfordDialogue from "./dialogues/riskPathway/RiskPathwayBradfordDialogue"
import type RiskPathwayDemoDialogue from "./dialogues/riskPathway/RiskPathwayDemoDialogue"
import type RiskPathwayWellbeingHubDialogue from "./dialogues/riskPathway/RiskPathwayWellbeingHubDialogue"
import type RiskPathwayGMHubDialogue from "./dialogues/riskPathway/RiskPathwayGMHubDialogue"
import type RiskPathwayEssexDialogue from "./dialogues/riskPathway/RiskPathwayEssexDialogue"
import type RiskPathwayHealixDialogue from "./dialogues/riskPathway/RiskPathwayHealixDialogue"
import type RiskPathwayInsightDialogue from "./dialogues/riskPathway/RiskPathwayInsightDialogue"
import type RiskPathwayInsightFollowUpDialogue from "./dialogues/riskPathway/RiskPathwayInsightFollowUpDialogue"
import type RiskPathwayOxfordDialogue from "./dialogues/riskPathway/RiskPathwayOxfordDialogue"
import type RiskPathwayBuckinghamshireDialogue from "./dialogues/riskPathway/RiskPathwayBuckinghamshireDialogue"
import type RiskPathwaySABPDialogue from "./dialogues/riskPathway/RiskPathwaySABPDialogue"
import type RiskPathwayVHGDialogue from "./dialogues/riskPathway/RiskPathwayVHGDialogue"
import type RiskPathwayLincolnshireDialogue from "./dialogues/riskPathway/RiskPathwayLincolnshireDialogue"
import type RiskPathwayDemoPrivateProvidersDialogue from "./dialogues/riskPathway/RiskPathwayDemoPrivateProvidersDialogue"
import type RiskPathwayMPFTDialogue from "./dialogues/riskPathway/RiskPathwayMPFTDialogue"
import type RiskPathwayMHMDialogue from "./dialogues/riskPathway/RiskPathwayMHMDialogue"
import type RiskPathwayMindDialogue from "./dialogues/riskPathway/RiskPathwayMindDialogue"
import type RiskPathwayLWCDialogue from "./dialogues/riskPathway/RiskPathwayLWCDialogue"
import type RiskPathwayCWDialogue from "./dialogues/riskPathway/RiskPathwayCWDialogue"
import type RiskPathwayDorkingDialogue from "./dialogues/riskPathway/RiskPathwayDorkingDialogue"
import type AuditDialogue from "./surveys/Audit"
import type DrugsAndSmokingDialogue from "./surveys/DrugsAndSmoking"
import type ITQDialogue from "./surveys/ITQ"
import type IRQADialogue from "./surveys/IRQ-A"
import type RiskPathwayVitalityDialogue from "./dialogues/riskPathway/RiskPathwayVitalityDialogue"
import type GAD7Dialogue from "./surveys/GAD7"
import type IAPTPhobiaScaleDialogue from "./surveys/IAPTPhobiaScale"
import type IAPTEmploymentStatusDialogue from "./surveys/IAPTEmploymentStatus"
import type IAPTEmploymentStatusKMDialogue from "./surveys/IAPTEmploymentStatusKM"
import type IAPTWorkAndSocialAdjustmentDialogue from "./surveys/IAPTWorkAndSocialAdjustment"
import type IAPTMedicationDialogue from "./surveys/IAPTMedication"
import type IAPTAccommodationDialogue from "./surveys/IAPTAccommodation"
import type PCL5Dialogue from "./surveys/PCL5"
import type PDSSDialogue from "./surveys/PDSS"
import type SPINDialogue from "./surveys/SPIN"
import type OCIDialogue from "./surveys/OCI"
import type SHAI18Dialogue from "./surveys/SHAI18"
import type SpecificPhobiaIntroDialogue from "./surveys/SpecificPhobiaIntro"
import type SpecificPhobiaDialogue from "./surveys/SpecificPhobia"
import type TreatmentOptionsIAPTDialogue from "./dialogues/treatmentOptions/TreatmentOptionsIAPTDialogue"
import type TreatmentOptionsSABPDialogue from "./dialogues/treatmentOptions/TreatmentOptionsSABPDialogue"
import type TreatmentOptionsHealixDialogue from "./dialogues/treatmentOptions/TreatmentOptionsHealixDialogue"
import type TreatmentOptionsInsightDialogue from "./dialogues/treatmentOptions/TreatmentOptionsInsightDialogue"
import type TreatmentOptionsDemoPrivateProvidersDialogue from "./dialogues/treatmentOptions/TreatmentOptionsDemoPrivateProvidersDialogue"
import type TreatmentOptionsVitalityDialogue from "./dialogues/treatmentOptions/TreatmentOptionsVitalityDialogue"
import type TreatmentOptionsLWCDialogue from "./dialogues/treatmentOptions/TreatmentOptionsLWCDialogue"
import type WellbeingHealixDialogue from "./dialogues/wellbeing/WellbeingHealixDialogue"
import type WellbeingDemoPrivateProvidersDialogue from "./dialogues/wellbeing/WellbeingDemoPrivateProvidersDialogue"
import type GoodbyeBradfordDialogue from "./dialogues/goodbye/GoodbyeBradfordDialogue"
import type GoodbyeDemoDialogue from "./dialogues/goodbye/GoodbyeDemoDialogue"
import type GoodbyeGMHubDialogue from "./dialogues/goodbye/GoodbyeGMHubDialogue"
import type GoodbyeWellbeingHubDialogue from "./dialogues/goodbye/GoodbyeWellbeingHubDialogue"
import type GoodbyeEssexDialogue from "./dialogues/goodbye/GoodbyeEssexDialogue"
import type GoodbyeSABPDialogue from "./dialogues/goodbye/GoodbyeSABPDialogue"
import type GoodbyeHealixDialogue from "./dialogues/goodbye/GoodbyeHealixDialogue"
import type GoodbyeInsightDialogue from "./dialogues/goodbye/GoodbyeInsightDialogue"
import type GoodbyeInsightFollowUpDialogue from "./dialogues/goodbye/GoodbyeInsightFollowUpDialogue"
import type GoodbyeVHGDialogue from "./dialogues/goodbye/GoodbyeVHGDialogue"
import type GoodbyeLincolnshireDialogue from "./dialogues/goodbye/GoodbyeLincolnshireDialogue"
import type GoodbyeDemoPrivateProvidersDialogue from "./dialogues/goodbye/GoodbyeDemoPrivateProvidersDialogue"
import type GoodbyeOxfordDialogue from "./dialogues/goodbye/GoodbyeOxfordDialogue"
import type GoodbyeBuckinghamshireDialogue from "./dialogues/goodbye/GoodbyeBuckinghamshireDialogue"
import type GoodbyeVitalityDialogue from "./dialogues/goodbye/GoodbyeVitalityDialogue"
import type GoodbyeMPFTDialogue from "./dialogues/goodbye/GoodbyeMPFTDialogue"
import type GoodbyeMHMDialogue from "./dialogues/goodbye/GoodbyeMHMDialogue"
import type GoodbyeMindDialogue from "./dialogues/goodbye/GoodbyeMindDialogue"
import type GoodbyeLWCDialogue from "./dialogues/goodbye/GoodbyeLWCDialogue"
import type GoodbyeCWDialogue from "./dialogues/goodbye/GoodbyeCWDialogue"
import type GoodbyeDorkingDialogue from "./dialogues/goodbye/GoodbyeDorkingDialogue"
import type GoodbyeBexleyDialogue from "./dialogues/goodbye/GoodbyeBexleyDialogue"
import type GoodbyeDynamicDialogue from "./dialogues/goodbye/GoodbyeDynamicDialogue"
import type CrisisBradfordDialogue from "./dialogues/crisis/CrisisBradfordDialogue"
import type CrisisDynamicDialogue from "./dialogues/crisis/CrisisDynamicDialogue"
import type CrisisDemoDialogue from "./dialogues/crisis/CrisisDemoDialogue"
import type CrisisGMHubDialogue from "./dialogues/crisis/CrisisGMHubDialogue"
import type CrisisWellbeingHubDialogue from "./dialogues/crisis/CrisisWellbeingHubDialogue"
import type CrisisEssexDialogue from "./dialogues/crisis/CrisisEssexDialogue"
import type CrisisHealixDialogue from "./dialogues/crisis/CrisisHealixDialogue"
import type CrisisInsightDialogue from "./dialogues/crisis/CrisisInsightDialogue"
import type CrisisOxfordBucksDialogue from "./dialogues/crisis/CrisisOxfordBucksDialogue"
import type CrisisSABPDialogue from "./dialogues/crisis/CrisisSABPDialogue"
import type CrisisVHGDialogue from "./dialogues/crisis/CrisisVHGDialogue"
import type CrisisLincolnshireDialogue from "./dialogues/crisis/CrisisLincolnshireDialogue"
import type CrisisDemoPrivateProvidersDialogue from "./dialogues/crisis/CrisisDemoPrivateProvidersDialogue"
import type CrisisVitalityDialogue from "./dialogues/crisis/CrisisVitalityDialogue"
import type CrisisMPFTDialogue from "./dialogues/crisis/CrisisMPFTDialogue"
import type CrisisMHMDialogue from "./dialogues/crisis/CrisisMHMDialogue"
import type CrisisMindDialogue from "./dialogues/crisis/CrisisMindDialogue"
import type CrisisLWCDialogue from "./dialogues/crisis/CrisisLWCDialogue"
import type CrisisCWDialogue from "./dialogues/crisis/CrisisCWDialogue"
import type CrisisDorkingDialogue from "./dialogues/crisis/CrisisDorkingDialogue"
import type BookAppointmentDialogue from "./dialogues/ad-hoc/BookAppointment/BookAppointmentDialogue"
import type BookAppointmentDynamicDialogue from "./dialogues/bookAppointment/BookAppointmentDynamicDialogue"
import type BookAppointmentVitalityDialogue from "./dialogues/bookAppointment/BookAppointmentVitalityDialogue"
import type BookAppointmentMindDialogue from "./dialogues/bookAppointment/BookAppointmentMindDialogue"
import type CollectPhoneNumberDialogue from "./dialogues/ad-hoc/CollectPhoneNumber/CollectPhoneNumberDialogue"
import type CollectPhoneNumberEssexDialogue from "./dialogues/ad-hoc/CollectPhoneNumber/CollectPhoneNumberEssexDialogue"
import type CollectPhoneNumberInsightDialogue from "./dialogues/ad-hoc/CollectPhoneNumber/CollectPhoneNumberInsightDialogue"
import type CollectPhoneNumberMPFTDialogue from "./dialogues/ad-hoc/CollectPhoneNumber/CollectPhoneNumberMPFTDialogue"
import type CollectPhoneNumberMHMDialogue from "./dialogues/ad-hoc/CollectPhoneNumber/CollectPhoneNumberMHMDialogue"
import type CollectAlcoholConsumptionDialogue from "./dialogues/ad-hoc/CollectAlcoholConsumption/CollectAlcoholConsumptionDialogue"
import type CheckPostCodeFromAddressLookupDialogue from "./dialogues/ad-hoc/CheckPostCodeFromAddressLookup/CheckPostCodeFromAddressLookupDialogue"
import type CheckPostcodeFromAddressLookupMHMDialogue from "./dialogues/ad-hoc/CheckPostCodeFromAddressLookup/CheckPostCodeFromAddressLookupMHMDialogue"
import type CheckPostCodeFromAddressLookupSABPDialogue from "./dialogues/ad-hoc/CheckPostCodeFromAddressLookup/CheckPostCodeFromAddressLookupSABPDialogue"
import type CheckPostCodeFromAddressLookupMindDialogue from "./dialogues/ad-hoc/CheckPostCodeFromAddressLookup/CheckPostCodeFromAddressLookupMindDialogue"
import type CheckCovidAndDetailsDialogue from "./dialogues/ad-hoc/CheckCovidAndDetails/CheckCovidAndDetailsDialogue"
import type CollectMainIssueDialogue from "./dialogues/ad-hoc/CollectMainIssue/CollectMainIssueDialogue"
import type CollectGoalForTherapyDialogue from "./dialogues/ad-hoc/CollectGoalForTherapy/CollectGoalForTherapyDialogue"
import type CollectNHSNumberDialogue from "./dialogues/ad-hoc/CollectNHSNumber/CollectNHSNumberDialogue"
import type CollectASDDialogue from "./dialogues/ad-hoc/CollectASD/CollectASDDialogue"
import type CollectADHDDialogue from "./dialogues/ad-hoc/CollectADHD/CollectADHDDialogue"
import type CollectPreferredCorrespondenceDialogue from "./dialogues/ad-hoc/CollectPreferredCorrespondence/CollectPreferredCorrespondenceDialogue"
import type CollectNationalityDialogue from "./dialogues/ad-hoc/CollectNationality/CollectNationalityDialogue"
import type CollectEthnicityDialogue from "./dialogues/ad-hoc/CollectEthnicity/CollectEthnicityDialogue"
import type CollectPriorMHTreatmentDetailsDialogue from "./dialogues/ad-hoc/CollectPriorMHTreatmentDetails/CollectPriorMHTreatmentDetailsDialogue"
import type CollectCurrentMHTreatmentDetailsDialogue from "./dialogues/ad-hoc/CollectCurrentMHTreatmentDetails/CollectCurrentMHTreatmentDetailsDialogue"
import type CollectSexualityDialogue from "./dialogues/ad-hoc/CollectSexuality/CollectSexualityDialogue"
import type CollectGenderDialogue from "./dialogues/ad-hoc/CollectGender/CollectGenderDialogue"
import type CollectDisabilitiesDialogue from "./dialogues/ad-hoc/CollectDisabilities/CollectDisabilitiesDialogue"
import type CollectLanguageAndInterpreterDialogue from "./dialogues/ad-hoc/CollectLanguageAndInterpreter/CollectLanguageAndInterpreterDialogue"
import type CollectEmailDialogue from "./dialogues/ad-hoc/CollectEmail/CollectEmailDialogue"
import type CollectMedicationAndSubstancesDetailsDialogue from "./dialogues/ad-hoc/CollectMedicationAndSubstancesDetails/CollectMedicationAndSubstancesDetailsDialogue"
import type CollectSubstancesDialogue from "./dialogues/ad-hoc/CollectSubstances/CollectSubstancesDialogue"
import type CollectLongTermMedicalConditionDetailsDialogue from "./dialogues/ad-hoc/CollectLongTermMedicalConditionDetails/CollectLongTermMedicalConditionDetailsDialogue"
import type CollectReligionDialogue from "./dialogues/ad-hoc/CollectReligion/CollectReligionDialogue"
import type CollectDateOfBirthDialogue from "./dialogues/ad-hoc/CollectDateOfBirth/CollectDateOfBirthDialogue"
import type CollectNameDialogue from "./dialogues/ad-hoc/CollectName/CollectNameDialogue"
import type CollectFeedbackDialogue from "./dialogues/ad-hoc/CollectFeedback/CollectFeedbackDialogue"
import type CollectSMIDialogue from "./dialogues/ad-hoc/CollectSMI/CollectSMIDialogue"
import type CheckCrisisDetectionDialogue from "./dialogues/ad-hoc/CheckCrisisDetection/CheckCrisisDetectionDialogue"
import type GetPermissionsDialogue from "./dialogues/ad-hoc/GetPermissions/GetPermissionsDialogue"
import type ServiceSearchDialogue from "./dialogues/ad-hoc/ServiceSearch/ServiceSearchDialogue"
import type SpineSearchDialogue from "./dialogues/ad-hoc/SpineSearch/SpineSearchDialogue"
import type SubmitReferralDialogue from "./dialogues/ad-hoc/SubmitReferral/SubmitReferralDialogue"
import type AssessmentAndTreatmentsDialogue from "./dialogues/ad-hoc/AssessmentAndTreatments/AssessmentAndTreatmentsDialogue"
import type GoodbyeRecapDialogue from "./dialogues/ad-hoc/GoodbyeRecap/GoodbyeRecapDialogue"
import Logger from "../utils/Logger"

/**
 * @IMPORTANT: Dialogues in this file MUST NOT be imported directly.
 *             You can import the dialogue as a type only, because
 *             you'll need to use it in the interface below 👇, but
 *             it's important to only fetch dialogues by requiring
 *             them at runtime.
 */

export interface IDialoguesRegistry {
  [DialogueIDs.MainFlow]: typeof MainFlowDialogue
  [DialogueIDs.IntroductionDynamic]: typeof IntroductionDynamicDialogue
  [DialogueIDs.IntroductionIAPT]: typeof IntroductionIAPTDialogue
  [DialogueIDs.IntroductionGP]: typeof IntroductionGPDialogue
  [DialogueIDs.IntroductionWellbeingHub]: typeof IntroductionWellbeingHubDialogue
  [DialogueIDs.IntroductionEssex]: typeof IntroductionEssexDialogue
  [DialogueIDs.IntroductionHealix]: typeof IntroductionHealixDialogue
  [DialogueIDs.IntroductionGMHub]: typeof IntroductionGMHubDialogue
  [DialogueIDs.IntroductionDemoPrivateProviders]: typeof IntroductionDemoPrivateProvidersDialogue
  [DialogueIDs.IntroductionInsight]: typeof IntroductionInsightDialogue
  [DialogueIDs.IntroductionInsightWaitlist]: typeof IntroductionInsightWaitlistDialogue
  [DialogueIDs.IntroductionInsightFollowUp]: typeof IntroductionInsightFollowUpDialogue
  [DialogueIDs.IntroductionOxford]: typeof IntroductionOxfordDialogue
  [DialogueIDs.IntroductionBuckinghamshire]: typeof IntroductionBuckinghamshireDialogue
  [DialogueIDs.IntroductionVitality]: typeof IntroductionVitalityDialogue
  [DialogueIDs.IntroductionMPFT]: typeof IntroductionMPFTDialogue
  [DialogueIDs.IntroductionVHG]: typeof IntroductionVHGDialogue
  [DialogueIDs.IntroductionMind]: typeof IntroductionMindDialogue
  [DialogueIDs.IntroductionLWC]: typeof IntroductionLWCDialogue
  [DialogueIDs.IntroductionDorking]: typeof IntroductionDorkingDialogue
  [DialogueIDs.GetNameDynamic]: typeof GetNameDynamicDialogue
  [DialogueIDs.GetNameBradford]: typeof GetNameBradfordDialogue
  [DialogueIDs.GetNameIAPT]: typeof GetNameIAPTDialogue
  [DialogueIDs.GetNameEssex]: typeof GetNameEssexDialogue
  [DialogueIDs.GetNameInsight]: typeof GetNameInsightDialogue
  [DialogueIDs.GetNameLincolnshire]: typeof GetNameLincolnshireDialogue
  [DialogueIDs.GetNameHealix]: typeof GetNameHealixDialogue
  [DialogueIDs.GetNameOxfordBucks]: typeof GetNameOxfordBucksDialogue
  [DialogueIDs.GetNameDemoPrivateProviders]: typeof GetNameDemoPrivateProvidersDialogue
  [DialogueIDs.GetNameInsightWaitlist]: typeof GetNameInsightWaitlistDialogue
  [DialogueIDs.GetNameInsightFollowUp]: typeof GetNameInsightFollowUpDialogue
  [DialogueIDs.GetNameSABP]: typeof GetNameSABPDialogue
  [DialogueIDs.GetNameVHG]: typeof GetNameVHGDialogue
  [DialogueIDs.GetNameMHM]: typeof GetNameMHMDialogue
  [DialogueIDs.GetNameVitality]: typeof GetNameVitalityDialogue
  [DialogueIDs.GetNameMPFT]: typeof GetNameMPFTDialogue
  [DialogueIDs.GetNameMind]: typeof GetNameMindDialogue
  [DialogueIDs.GetNameCW]: typeof GetNameCWDialogue
  [DialogueIDs.GetNameLWC]: typeof GetNameLWCDialogue
  [DialogueIDs.GetNameDorking]: typeof GetNameDorkingDialogue
  [DialogueIDs.EligibilityCheckDynamic]: typeof EligibilityCheckDynamicDialogue
  [DialogueIDs.EligibilityCheckBradford]: typeof EligibilityCheckBradfordDialogue
  [DialogueIDs.EligibilityCheckDemo]: typeof EligibilityCheckDemoDialogue
  [DialogueIDs.EligibilityCheckEssex]: typeof EligibilityCheckEssexDialogue
  [DialogueIDs.EligibilityCheckIAPT]: typeof EligibilityCheckIAPTDialogue
  [DialogueIDs.EligibilityCheckGP]: typeof EligibilityCheckGPDialogue
  [DialogueIDs.EligibilityCheckWellbeingHub]: typeof EligibilityCheckWellbeingHubDialogue
  [DialogueIDs.EligibilityCheckGMHub]: typeof EligibilityCheckGMHubDialogue
  [DialogueIDs.EligibilityCheckInsight]: typeof EligibilityCheckInsightDialogue
  [DialogueIDs.EligibilityCheckOxford]: typeof EligibilityCheckOxfordDialogue
  [DialogueIDs.EligibilityCheckBuckinghamshire]: typeof EligibilityCheckBuckinghamshireDialogue
  [DialogueIDs.EligibilityCheckLincolnshire]: typeof EligibilityCheckLincolnshireDialogue
  [DialogueIDs.EligibilityCheckSABP]: typeof EligibilityCheckSABPDialogue
  [DialogueIDs.EligibilityCheckVHG]: typeof EligibilityCheckVHGDialogue
  [DialogueIDs.EligibilityCheckVitality]: typeof EligibilityCheckVitalityDialogue
  [DialogueIDs.EligibilityCheckMPFT]: typeof EligibilityCheckMPFTDialogue
  [DialogueIDs.EligibilityCheckMHM]: typeof EligibilityCheckMHMDialogue
  [DialogueIDs.EligibilityCheckMind]: typeof EligibilityCheckMindDialogue
  [DialogueIDs.EligibilityCheckCW]: typeof EligibilityCheckCWDialogue
  [DialogueIDs.EligibilityCheckDorking]: typeof EligibilityCheckDorkingDialogue
  [DialogueIDs.SuitableServicesSignpostIAPT]: typeof SuitableServicesSignpostIAPTDialogue
  [DialogueIDs.SuitableServicesSignpostCCG]: typeof SuitableServicesSignpostCCGDialogue
  [DialogueIDs.SuitableServicesSignpostSABP]: typeof SuitableServicesSignpostSABPDialogue
  [DialogueIDs.SuitableServicesSignpostEssex]: typeof SuitableServicesSignpostEssexDialogue
  [DialogueIDs.SuitableServicesSignpostMind]: typeof SuitableServicesSignpostMindDialogue
  [DialogueIDs.SuitableServicesSignpostLWC]: typeof SuitableServicesSignpostLWCDialogue
  [DialogueIDs.SuitableServicesSignpostOxford]: typeof SuitableServicesSignpostOxfordDialogue
  [DialogueIDs.SuitableServicesSignpostBuckinghamshire]: typeof SuitableServicesSignpostBuckinghamshireDialogue
  [DialogueIDs.UnderageSignpost]: typeof UnderAgeSignpostDialogue
  [DialogueIDs.UnderageSignpostOxford]: typeof UnderAgeSignpostOxfordDialogue
  [DialogueIDs.UnderAgeSignpostBuckinghamshire]: typeof UnderAgeSignpostBuckinghamshireDialogue
  [DialogueIDs.UnderageSignpostBradford]: typeof UnderAgeSignpostBradfordDialogue
  [DialogueIDs.UnderageSignpostDorking]: typeof UnderAgeSignpostDorkingDialogue
  [DialogueIDs.UnderageSignpostGMHub]: typeof UnderAgeSignpostGMHubDialogue
  [DialogueIDs.UnderageSignpostWellbeingHub]: typeof UnderAgeSignpostWellbeingHubDialogue
  [DialogueIDs.UnderageSignpostEssex]: typeof UnderAgeSignpostEssexDialogue
  [DialogueIDs.UnderageSignpostLincolnshire]: typeof UnderAgeSignpostLincolnshireDialogue
  [DialogueIDs.UnderageSignpostSABP]: typeof UnderAgeSignpostSABPDialogue
  [DialogueIDs.UnderageSignpostMPFT]: typeof UnderAgeSignpostMPFTDialogue
  [DialogueIDs.UnderageSignpostMind]: typeof UnderAgeSignpostMindDialogue
  [DialogueIDs.UnderAgeSignpostLWC]: typeof UnderAgeSignpostLWCDialogue
  [DialogueIDs.UnderAgeSignpostBexley]: typeof UnderAgeSignpostBexleyDialogue
  [DialogueIDs.UnderAgeSignpostCW]: typeof UnderAgeSignpostCWDialogue
  [DialogueIDs.PeaceOfMindIAPT]: typeof PeaceOfMindIAPTDialogue
  [DialogueIDs.PeaceOfMindGP]: typeof PeaceOfMindGPDialogue
  [DialogueIDs.PeaceOfMindWellbeingHub]: typeof PeaceOfMindWellbeingHubDialogue
  [DialogueIDs.PeaceOfMindHealix]: typeof PeaceOfMindHealixDialogue
  [DialogueIDs.PeaceOfMindDemoPrivateProviders]: typeof PeaceOfMindDemoPrivateProvidersDialogue
  [DialogueIDs.PeaceOfMindVitality]: typeof PeaceOfMindVitalityDialogue
  [DialogueIDs.PermissionsDynamic]: typeof PermissionsDynamicDialogue
  [DialogueIDs.PermissionsEssex]: typeof PermissionsEssexDialogue
  [DialogueIDs.PermissionsIAPT]: typeof PermissionsIAPTDialogue
  [DialogueIDs.PermissionsWellbeingHub]: typeof PermissionsWellbeingHubDialogue
  [DialogueIDs.PermissionsGMHub]: typeof PermissionsGMDialogue
  [DialogueIDs.PermissionsSABP]: typeof PermissionsSABPDialogue
  [DialogueIDs.PermissionsInsight]: typeof PermissionsInsightDialogue
  [DialogueIDs.PermissionsDemoPrivateProviders]: typeof PermissionsDemoPrivateProvidersDialogue
  [DialogueIDs.PermissionsVitality]: typeof PermissionsVitalityDialogue
  [DialogueIDs.PermissionsMPFT]: typeof PermissionsMPFTDialogue
  [DialogueIDs.PermissionsMHM]: typeof PermissionsMHMDialogue
  [DialogueIDs.PermissionsLincolnshire]: typeof PermissionsLincolnshireDialogue
  [DialogueIDs.PermissionsMind]: typeof PermissionsMindDialogue
  [DialogueIDs.PermissionsLWC]: typeof PermissionsLWCDialogue
  [DialogueIDs.ResearchConsent]: typeof ResearchConsentDialogue
  [DialogueIDs.ResearchConsentSABP]: typeof ResearchConsentSABPDialogue
  [DialogueIDs.PermissionsOxfordBucks]: typeof PermissionsOxfordBucksDialogue
  [DialogueIDs.PermissionsCW]: typeof PermissionsCWDialogue
  [DialogueIDs.SelfReferralPitchIAPT]: typeof SelfReferralPitchIAPTDialogue
  [DialogueIDs.SelfReferralPitchHealix]: typeof SelfReferralPitchHealixDialogue
  [DialogueIDs.SelfReferralPitchGMHub]: typeof SelfReferralPitchGMHubDialogue
  [DialogueIDs.SelfReferralPitchWellbeingHub]: typeof SelfReferralPitchDialogueWellbeingHub
  [DialogueIDs.SelfReferralPitchDemoPrivateProviders]: typeof SelfReferralPitchDemoPrivateProvidersDialogue
  [DialogueIDs.SelfReferralPitchMind]: typeof SelfReferralPitchMindDialogue
  [DialogueIDs.SelfReferralDynamic]: typeof SelfReferralDynamicDialogue
  [DialogueIDs.SelfReferralDemo]: typeof SelfReferralDemoDialogue
  [DialogueIDs.SelfReferralEssex]: typeof SelfReferralEssexDialogue
  [DialogueIDs.SelfReferralWellbeingHub]: typeof SelfReferralWellbeingHubDialogue
  [DialogueIDs.SelfReferralWellbeingHubIndividual]: typeof SelfReferralWellbeingHubIndividualDialogue
  [DialogueIDs.SelfReferralWellbeingHubManagerIndividual]: typeof SelfReferralWellbeingHubManagerIndividualDialogue
  [DialogueIDs.SelfReferralWellbeingHubManagerTeam]: typeof SelfReferralWellbeingHubManagerTeamDialogue
  [DialogueIDs.SelfReferralGMHub]: typeof SelfReferralGMHubDialogue
  [DialogueIDs.SelfReferralGMHubManagerTeam]: typeof SelfReferralGMHubManagerTeamDialogue
  [DialogueIDs.SelfReferralGMHubManagerIndividual]: typeof SelfReferralGMHubManagerIndividualDialogue
  [DialogueIDs.SelfReferralGMHubIndividual]: typeof SelfReferralGMHubIndividualDialogue
  [DialogueIDs.SelfReferralHealix]: typeof SelfReferralHealixDialogue
  [DialogueIDs.SelfReferralInsight]: typeof SelfReferralInsightDialogue
  [DialogueIDs.SelfReferralSABP]: typeof SelfReferralSABPDialogue
  [DialogueIDs.SelfReferralLincolnshire]: typeof SelfReferralLincolnshireDialogue
  [DialogueIDs.SelfReferralVHG]: typeof SelfReferralVHGDialogue
  [DialogueIDs.SelfReferralDemoPrivateProviders]: typeof SelfReferralDemoPrivateProvidersDialogue
  [DialogueIDs.SelfReferralInsightWaitlist]: typeof SelfReferralInsightWaitlistDialogue
  [DialogueIDs.SelfReferralVitality]: typeof SelfReferralVitalityDialogue
  [DialogueIDs.SelfReferralMPFT]: typeof SelfReferralMPFTDialogue
  [DialogueIDs.SelfReferralMind]: typeof SelfReferralMindDialogue
  [DialogueIDs.ADSMConsent]: typeof ADSMConsentDialogue
  [DialogueIDs.ADSMConsentInsight]: typeof ADSMConsentInsightDialogue
  [DialogueIDs.SelfReferralMHM]: typeof SelfReferralMHMDialogue
  [DialogueIDs.AssessmentPitchBradford]: typeof AssessmentPitchBradfordDialogue
  [DialogueIDs.AssessmentPitchIAPT]: typeof AssessmentPitchIAPTDialogue
  [DialogueIDs.AssessmentPitchGP]: typeof AssessmentPitchGPDialogue
  [DialogueIDs.AssessmentPitchWellbeingHub]: typeof AssessmentPitchWellbeingHubDialogue
  [DialogueIDs.AssessmentPitchEssex]: typeof AssessmentPitchEssexDialogue
  [DialogueIDs.AssessmentPitchGMHub]: typeof AssessmentPitchGMHubDialogue
  [DialogueIDs.AssessmentPitchHealix]: typeof AssessmentPitchHealixDialogue
  [DialogueIDs.AssessmentPitchInsight]: typeof AssessmentPitchInsightDialogue
  [DialogueIDs.AssessmentPitchInsightFollowUp]: typeof AssessmentPitchInsightFollowUpDialogue
  [DialogueIDs.AssessmentPitchLincolnshire]: typeof AssessmentPitchLincolnshireDialogue
  [DialogueIDs.AssessmentPitchOxfordBucks]: typeof AssessmentPitchOxfordBucksDialogue
  [DialogueIDs.AssessmentPitchSABP]: typeof AssessmentPitchSABPDialogue
  [DialogueIDs.AssessmentPitchVHG]: typeof AssessmentPitchVHGDialogue
  [DialogueIDs.AssessmentPitchDemoPrivateProviders]: typeof AssessmentPitchDemoPrivateProvidersDialogue
  [DialogueIDs.AssessmentPitchVitality]: typeof AssessmentPitchVitalityDialogue
  [DialogueIDs.AssessmentPitchMPFT]: typeof AssessmentPitchMPFTDialogue
  [DialogueIDs.AssessmentPitchMHM]: typeof AssessmentPitchMHMDialogue
  [DialogueIDs.AssessmentBradford]: typeof AssessmentBradfordDialogue
  [DialogueIDs.AssessmentPitchMind]: typeof AssessmentPitchMindDialogue
  [DialogueIDs.AssessmentPitchLWC]: typeof AssessmentPitchLWCDialogue
  [DialogueIDs.AssessmentPitchCW]: typeof AssessmentPitchCWDialogue
  [DialogueIDs.AssessmentPitchDorking]: typeof AssessmentPitchDorkingDialogue
  [DialogueIDs.AssessmentDynamic]: typeof AssessmentDynamicDialogue
  [DialogueIDs.AssessmentDemo]: typeof AssessmentDemoDialogue
  [DialogueIDs.AssessmentWellbeingHub]: typeof AssessmentWellbeingHubDialogue
  [DialogueIDs.AssessmentEssex]: typeof AssessmentEssexDialogue
  [DialogueIDs.AssessmentSABP]: typeof AssessmentSABPDialogue
  [DialogueIDs.AssessmentGMHub]: typeof AssessmentGMHubDialogue
  [DialogueIDs.AssessmentHealix]: typeof AssessmentHealixDialogue
  [DialogueIDs.AssessmentInsight]: typeof AssessmentInsightDialogue
  [DialogueIDs.AssessmentADSMDynamic]: typeof AssessmentADSMDynamicDialogue
  [DialogueIDs.AssessmentADSMInsight]: typeof AssessmentADSMInsightDialogue
  [DialogueIDs.AssessmentOxfordBucks]: typeof AssessmentOxfordBucksDialogue
  [DialogueIDs.AssessmentVHG]: typeof AssessmentVHGDialogue
  [DialogueIDs.AssessmentLincolnshire]: typeof AssessmentLincolnshireDialogue
  [DialogueIDs.AssessmentDemoPrivateProviders]: typeof AssessmentDemoPrivateProvidersDialogue
  [DialogueIDs.AssessmentVitality]: typeof AssessmentVitalityDialogue
  [DialogueIDs.AssessmentMPFT]: typeof AssessmentMPFTDialogue
  [DialogueIDs.AssessmentMHM]: typeof AssessmentMHMDialogue
  [DialogueIDs.AssessmentMind]: typeof AssessmentMindDialogue
  [DialogueIDs.AssessmentLWC]: typeof AssessmentLWCDialogue
  [DialogueIDs.AssessmentCW]: typeof AssessmentCWDialogue
  [DialogueIDs.AssessmentDorking]: typeof AssessmentDorkingDialogue
  [DialogueIDs.PHQ9]: typeof PHQ9Dialogue
  [DialogueIDs.PHQ9Dynamic]: typeof PHQ9DynamicDialogue
  [DialogueIDs.PHQ9GMHub]: typeof PHQ9GMHubDialogue
  [DialogueIDs.PHQ9WellbeingHub]: typeof PHQ9WellbeingHubDialogue
  [DialogueIDs.PHQ9SABP]: typeof PHQ9SABPDialogue
  [DialogueIDs.PHQ9VHG]: typeof PHQ9VHGDialogue
  [DialogueIDs.PHQ9Vitality]: typeof PHQ9VitalityDialogue
  [DialogueIDs.RiskPathwayDynamic]: typeof RiskPathwayDynamicDialogue
  [DialogueIDs.RiskPathwayBradford]: typeof RiskPathwayBradfordDialogue
  [DialogueIDs.RiskPathwayDemo]: typeof RiskPathwayDemoDialogue
  [DialogueIDs.RiskPathwayGMHub]: typeof RiskPathwayGMHubDialogue
  [DialogueIDs.RiskPathwayWellbeingHub]: typeof RiskPathwayWellbeingHubDialogue
  [DialogueIDs.RiskPathwayEssex]: typeof RiskPathwayEssexDialogue
  [DialogueIDs.RiskPathwayHealix]: typeof RiskPathwayHealixDialogue
  [DialogueIDs.RiskPathwayInsight]: typeof RiskPathwayInsightDialogue
  [DialogueIDs.RiskPathwayInsightFollowUp]: typeof RiskPathwayInsightFollowUpDialogue
  [DialogueIDs.RiskPathwayOxford]: typeof RiskPathwayOxfordDialogue
  [DialogueIDs.RiskPathwayBuckinghamshire]: typeof RiskPathwayBuckinghamshireDialogue
  [DialogueIDs.RiskPathwaySABP]: typeof RiskPathwaySABPDialogue
  [DialogueIDs.RiskPathwayVHG]: typeof RiskPathwayVHGDialogue
  [DialogueIDs.RiskPathwayLincolnshire]: typeof RiskPathwayLincolnshireDialogue
  [DialogueIDs.RiskPathwayDemoPrivateProviders]: typeof RiskPathwayDemoPrivateProvidersDialogue
  [DialogueIDs.RiskPathwayMPFT]: typeof RiskPathwayMPFTDialogue
  [DialogueIDs.RiskPathwayMHM]: typeof RiskPathwayMHMDialogue
  [DialogueIDs.RiskPathwayMind]: typeof RiskPathwayMindDialogue
  [DialogueIDs.RiskPathwayLWC]: typeof RiskPathwayLWCDialogue
  [DialogueIDs.RiskPathwayCW]: typeof RiskPathwayCWDialogue
  [DialogueIDs.RiskPathwayDorking]: typeof RiskPathwayDorkingDialogue
  [DialogueIDs.Audit]: typeof AuditDialogue
  [DialogueIDs.DrugsAndSmoking]: typeof DrugsAndSmokingDialogue
  [DialogueIDs.ITQ]: typeof ITQDialogue
  [DialogueIDs.IRQA]: typeof IRQADialogue
  [DialogueIDs.RiskPathwayVitality]: typeof RiskPathwayVitalityDialogue
  [DialogueIDs.GAD7]: typeof GAD7Dialogue
  [DialogueIDs.IAPTPhobiaScale]: typeof IAPTPhobiaScaleDialogue
  [DialogueIDs.IAPTEmploymentStatus]: typeof IAPTEmploymentStatusDialogue
  [DialogueIDs.IAPTEmploymentStatusKM]: typeof IAPTEmploymentStatusKMDialogue
  [DialogueIDs.IAPTWorkAndSocialAdjustment]: typeof IAPTWorkAndSocialAdjustmentDialogue
  [DialogueIDs.IAPTMedication]: typeof IAPTMedicationDialogue
  [DialogueIDs.IAPTAccommodation]: typeof IAPTAccommodationDialogue
  [DialogueIDs.PCL5]: typeof PCL5Dialogue
  [DialogueIDs.PDSS]: typeof PDSSDialogue
  [DialogueIDs.SPIN]: typeof SPINDialogue
  [DialogueIDs.OCI]: typeof OCIDialogue
  [DialogueIDs.SHAI18]: typeof SHAI18Dialogue
  [DialogueIDs.SpecificPhobiaIntro]: typeof SpecificPhobiaIntroDialogue
  [DialogueIDs.SpecificPhobia]: typeof SpecificPhobiaDialogue
  [DialogueIDs.TreatmentOptionsIAPT]: typeof TreatmentOptionsIAPTDialogue
  [DialogueIDs.TreatmentOptionsSABP]: typeof TreatmentOptionsSABPDialogue
  [DialogueIDs.TreatmentOptionsHealix]: typeof TreatmentOptionsHealixDialogue
  [DialogueIDs.TreatmentOptionsInsight]: typeof TreatmentOptionsInsightDialogue
  [DialogueIDs.TreatmentOptionsDemoPrivateProviders]: typeof TreatmentOptionsDemoPrivateProvidersDialogue
  [DialogueIDs.TreatmentOptionsVitality]: typeof TreatmentOptionsVitalityDialogue
  [DialogueIDs.TreatmentOptionsLWC]: typeof TreatmentOptionsLWCDialogue
  [DialogueIDs.WellbeingHealix]: typeof WellbeingHealixDialogue
  [DialogueIDs.WellbeingDemoPrivateProviders]: typeof WellbeingDemoPrivateProvidersDialogue
  [DialogueIDs.GoodbyeDynamic]: typeof GoodbyeDynamicDialogue
  [DialogueIDs.GoodbyeBradford]: typeof GoodbyeBradfordDialogue
  [DialogueIDs.GoodbyeDemo]: typeof GoodbyeDemoDialogue
  [DialogueIDs.GoodbyeWellbeingHub]: typeof GoodbyeWellbeingHubDialogue
  [DialogueIDs.GoodbyeGMHub]: typeof GoodbyeGMHubDialogue
  [DialogueIDs.GoodbyeEssex]: typeof GoodbyeEssexDialogue
  [DialogueIDs.GoodbyeSABP]: typeof GoodbyeSABPDialogue
  [DialogueIDs.GoodbyeHealix]: typeof GoodbyeHealixDialogue
  [DialogueIDs.GoodbyeInsight]: typeof GoodbyeInsightDialogue
  [DialogueIDs.GoodbyeInsightFollowUp]: typeof GoodbyeInsightFollowUpDialogue
  [DialogueIDs.GoodbyeOxford]: typeof GoodbyeOxfordDialogue
  [DialogueIDs.GoodbyeBuckinghamshire]: typeof GoodbyeBuckinghamshireDialogue
  [DialogueIDs.GoodbyeVHG]: typeof GoodbyeVHGDialogue
  [DialogueIDs.GoodbyeMHM]: typeof GoodbyeMHMDialogue
  [DialogueIDs.GoodbyeLincolnshire]: typeof GoodbyeLincolnshireDialogue
  [DialogueIDs.GoodbyeDemoPrivateProviders]: typeof GoodbyeDemoPrivateProvidersDialogue
  [DialogueIDs.GoodbyeVitality]: typeof GoodbyeVitalityDialogue
  [DialogueIDs.GoodbyeMPFT]: typeof GoodbyeMPFTDialogue
  [DialogueIDs.GoodbyeMind]: typeof GoodbyeMindDialogue
  [DialogueIDs.GoodbyeLWC]: typeof GoodbyeLWCDialogue
  [DialogueIDs.GoodbyeCW]: typeof GoodbyeCWDialogue
  [DialogueIDs.GoodbyeDorking]: typeof GoodbyeDorkingDialogue
  [DialogueIDs.GoodbyeBexley]: typeof GoodbyeBexleyDialogue
  [DialogueIDs.CrisisBradford]: typeof CrisisBradfordDialogue
  [DialogueIDs.CrisisDynamic]: typeof CrisisDynamicDialogue
  [DialogueIDs.CrisisDemo]: typeof CrisisDemoDialogue
  [DialogueIDs.CrisisGMHub]: typeof CrisisGMHubDialogue
  [DialogueIDs.CrisisWellbeingHub]: typeof CrisisWellbeingHubDialogue
  [DialogueIDs.CrisisEssex]: typeof CrisisEssexDialogue
  [DialogueIDs.CrisisHealix]: typeof CrisisHealixDialogue
  [DialogueIDs.CrisisInsight]: typeof CrisisInsightDialogue
  [DialogueIDs.CrisisOxfordBucks]: typeof CrisisOxfordBucksDialogue
  [DialogueIDs.CrisisSABP]: typeof CrisisSABPDialogue
  [DialogueIDs.CrisisVHG]: typeof CrisisVHGDialogue
  [DialogueIDs.CrisisLincolnshire]: typeof CrisisLincolnshireDialogue
  [DialogueIDs.CrisisDemoPrivateProviders]: typeof CrisisDemoPrivateProvidersDialogue
  [DialogueIDs.CrisisVitality]: typeof CrisisVitalityDialogue
  [DialogueIDs.CrisisMPFT]: typeof CrisisMPFTDialogue
  [DialogueIDs.CrisisMHM]: typeof CrisisMHMDialogue
  [DialogueIDs.CrisisMind]: typeof CrisisMindDialogue
  [DialogueIDs.CrisisLWC]: typeof CrisisLWCDialogue
  [DialogueIDs.CrisisCW]: typeof CrisisCWDialogue
  [DialogueIDs.CrisisDorking]: typeof CrisisDorkingDialogue
  [DialogueIDs.BookAppointmentDynamic]: typeof BookAppointmentDynamicDialogue
  [DialogueIDs.BookAppointmentVitality]: typeof BookAppointmentVitalityDialogue
  [DialogueIDs.BookAppointmentMind]: typeof BookAppointmentMindDialogue
  [DialogueIDs.BookAppointmentChatflow]: typeof BookAppointmentDialogue
  [DialogueIDs.CollectPhoneNumber]: typeof CollectPhoneNumberDialogue
  [DialogueIDs.CollectPhoneNumberEssex]: typeof CollectPhoneNumberEssexDialogue
  [DialogueIDs.CollectPhoneNumberInsight]: typeof CollectPhoneNumberInsightDialogue
  [DialogueIDs.CollectPhoneNumberMPFT]: typeof CollectPhoneNumberMPFTDialogue
  [DialogueIDs.CollectPhoneNumberMHM]: typeof CollectPhoneNumberMHMDialogue
  [DialogueIDs.CollectAlcoholConsumption]: typeof CollectAlcoholConsumptionDialogue
  [DialogueIDs.CheckPostCodeFromAddressLookup]: typeof CheckPostCodeFromAddressLookupDialogue
  [DialogueIDs.CheckPostCodeFromAddressLookupMHM]: typeof CheckPostcodeFromAddressLookupMHMDialogue
  [DialogueIDs.CheckPostCodeFromAddressLookupSABP]: typeof CheckPostCodeFromAddressLookupSABPDialogue
  [DialogueIDs.CheckPostCodeFromAddressLookupMind]: typeof CheckPostCodeFromAddressLookupMindDialogue
  [DialogueIDs.CheckCovidAndDetails]: typeof CheckCovidAndDetailsDialogue
  [DialogueIDs.CollectMainIssue]: typeof CollectMainIssueDialogue
  [DialogueIDs.CollectGoalForTherapy]: typeof CollectGoalForTherapyDialogue
  [DialogueIDs.CollectNHSNumber]: typeof CollectNHSNumberDialogue
  [DialogueIDs.CollectASD]: typeof CollectASDDialogue
  [DialogueIDs.CollectADHD]: typeof CollectADHDDialogue
  [DialogueIDs.CollectPreferredCorrespondence]: typeof CollectPreferredCorrespondenceDialogue
  [DialogueIDs.CollectNationality]: typeof CollectNationalityDialogue
  [DialogueIDs.CollectEthnicity]: typeof CollectEthnicityDialogue
  [DialogueIDs.CollectPriorMHTreatmentDetails]: typeof CollectPriorMHTreatmentDetailsDialogue
  [DialogueIDs.CollectCurrentMHTreatmentDetails]: typeof CollectCurrentMHTreatmentDetailsDialogue
  [DialogueIDs.CollectSexuality]: typeof CollectSexualityDialogue
  [DialogueIDs.CollectGender]: typeof CollectGenderDialogue
  [DialogueIDs.CollectDisabilities]: typeof CollectDisabilitiesDialogue
  [DialogueIDs.CollectLanguageAndInterpreter]: typeof CollectLanguageAndInterpreterDialogue
  [DialogueIDs.CollectEmail]: typeof CollectEmailDialogue
  [DialogueIDs.CollectMedicationAndSubstancesDetails]: typeof CollectMedicationAndSubstancesDetailsDialogue
  [DialogueIDs.CollectSubstances]: typeof CollectSubstancesDialogue
  [DialogueIDs.CollectLongTermMedicalConditionDetails]: typeof CollectLongTermMedicalConditionDetailsDialogue
  [DialogueIDs.CollectReligion]: typeof CollectReligionDialogue
  [DialogueIDs.CollectDateOfBirth]: typeof CollectDateOfBirthDialogue
  [DialogueIDs.CollectName]: typeof CollectNameDialogue
  [DialogueIDs.CollectFeedback]: typeof CollectFeedbackDialogue
  [DialogueIDs.CollectSMI]: typeof CollectSMIDialogue
  [DialogueIDs.CheckCrisisDetection]: typeof CheckCrisisDetectionDialogue
  [DialogueIDs.GetPermissions]: typeof GetPermissionsDialogue
  [DialogueIDs.ServiceSearch]: typeof ServiceSearchDialogue
  [DialogueIDs.SpineSearch]: typeof SpineSearchDialogue
  [DialogueIDs.SubmitReferral]: typeof SubmitReferralDialogue
  [DialogueIDs.AssessmentAndTreatments]: typeof AssessmentAndTreatmentsDialogue
  [DialogueIDs.GoodbyeRecap]: typeof GoodbyeRecapDialogue
}

export class DialoguesRegistry {
  readonly #registry: Partial<IDialoguesRegistry>

  constructor() {
    this.#registry = {}
  }

  get<T extends keyof IDialoguesRegistry>(id: T): IDialoguesRegistry[T] {
    if (!this.#registry[id]) this.#registry[id] = DialoguesRegistry.require(id)
    return this.#registry[id] as IDialoguesRegistry[T]
  }

  /**
   * Before you WTF yourself to death looking at this, let
   * me shine some light into what happens here. This is
   * basically a replacement for inline requires or imports
   * from one dialogue to another in order to avoid require
   * cycles. Because of how the bot and the dialogues are
   * structured, each dialogue is responsible for pushing
   * the next dialogue into the dialogues stack, which means
   * a lot of dialogues have knowledge of the next dialogue
   * after them, so they need to instantiate it within their
   * own steps. So if you import the next dialogue right in
   * the same file, then that next dialogue might be importing
   * another dialogue which in turn could be looping back by
   * importing the first dialogue creating a require cycle
   * ¯\_(ツ)_/¯
   */
  private static require<T extends keyof IDialoguesRegistry>(id: T): IDialoguesRegistry[T] {
    switch (id) {
      case DialogueIDs.MainFlow:
        return require("./dialogues/MainFlowDialogue").default
      case DialogueIDs.IntroductionDynamic:
        return require("./dialogues/introduction/IntroductionDynamicDialogue").default
      case DialogueIDs.IntroductionIAPT:
        return require("./dialogues/introduction/IntroductionIAPTDialogue").default
      case DialogueIDs.IntroductionGP:
        return require("./dialogues/introduction/IntroductionGPDialogue").default
      case DialogueIDs.IntroductionWellbeingHub:
        return require("./dialogues/introduction/IntroductionWellbeingHubDialogue").default
      case DialogueIDs.IntroductionEssex:
        return require("./dialogues/introduction/IntroductionEssexDialogue").default
      case DialogueIDs.IntroductionHealix:
        return require("./dialogues/introduction/IntroductionHealixDialogue").default
      case DialogueIDs.IntroductionGMHub:
        return require("./dialogues/introduction/IntroductionGMHubDialogue").default
      case DialogueIDs.IntroductionDemoPrivateProviders:
        return require("./dialogues/introduction/IntroductionDemoPrivateProvidersDialogue").default
      case DialogueIDs.IntroductionInsight:
        return require("./dialogues/introduction/IntroductionInsightDialogue").default
      case DialogueIDs.IntroductionInsightWaitlist:
        return require("./dialogues/introduction/IntroductionInsightWaitlistDialogue").default
      case DialogueIDs.IntroductionInsightFollowUp:
        return require("./dialogues/introduction/IntroductionInsightFollowUpDialogue").default
      case DialogueIDs.IntroductionOxford:
        return require("./dialogues/introduction/IntroductionOxfordDialogue").default
      case DialogueIDs.IntroductionBuckinghamshire:
        return require("./dialogues/introduction/IntroductionBuckinghamshireDialogue").default
      case DialogueIDs.IntroductionVitality:
        return require("./dialogues/introduction/IntroductionVitalityDialogue").default
      case DialogueIDs.IntroductionMPFT:
        return require("./dialogues/introduction/IntroductionMPFTDialogue").default
      case DialogueIDs.IntroductionVHG:
        return require("./dialogues/introduction/IntroductionVHGDialogue").default
      case DialogueIDs.IntroductionMind:
        return require("./dialogues/introduction/IntroductionMindDialogue").default
      case DialogueIDs.IntroductionLWC:
        return require("./dialogues/introduction/IntroductionLWCDialogue").default
      case DialogueIDs.IntroductionDorking:
        return require("./dialogues/introduction/IntroductionDorkingDialogue").default
      case DialogueIDs.GetNameDynamic:
        return require("./dialogues/getName/GetNameDynamicDialogue").default
      case DialogueIDs.GetNameBradford:
        return require("./dialogues/getName/GetNameBradfordDialogue").default
      case DialogueIDs.GetNameEssex:
        return require("./dialogues/getName/GetNameEssexDialogue").default
      case DialogueIDs.GetNameIAPT:
        return require("./dialogues/getName/GetNameIAPTDialogue").default
      case DialogueIDs.GetNameInsight:
        return require("./dialogues/getName/GetNameInsightDialogue").default
      case DialogueIDs.GetNameLincolnshire:
        return require("./dialogues/getName/GetNameLincolnshireDialogue").default
      case DialogueIDs.GetNameHealix:
        return require("./dialogues/getName/GetNameHealixDialogue").default
      case DialogueIDs.GetNameDemoPrivateProviders:
        return require("./dialogues/getName/GetNameDemoPrivateProvidersDialogue").default
      case DialogueIDs.GetNameInsightWaitlist:
        return require("./dialogues/getName/GetNameInsightWaitlistDialogue").default
      case DialogueIDs.GetNameInsightFollowUp:
        return require("./dialogues/getName/GetNameInsightFollowUpDialogue").default
      case DialogueIDs.GetNameOxfordBucks:
        return require("./dialogues/getName/GetNameOxfordBucksDialogue").default
      case DialogueIDs.GetNameSABP:
        return require("./dialogues/getName/GetNameSABPDialogue").default
      case DialogueIDs.GetNameVHG:
        return require("./dialogues/getName/GetNameVHGDialogue").default
      case DialogueIDs.GetNameVitality:
        return require("./dialogues/getName/GetNameVitalityDialogue").default
      case DialogueIDs.GetNameMPFT:
        return require("./dialogues/getName/GetNameMPFTDialogue").default
      case DialogueIDs.GetNameMHM:
        return require("./dialogues/getName/GetNameMHMDialogue").default
      case DialogueIDs.GetNameMind:
        return require("./dialogues/getName/GetNameMindDialogue").default
      case DialogueIDs.GetNameLWC:
        return require("./dialogues/getName/GetNameLWCDialogue").default
      case DialogueIDs.GetNameCW:
        return require("./dialogues/getName/GetNameCWDialogue").default
      case DialogueIDs.GetNameDorking:
        return require("./dialogues/getName/GetNameDorkingDialogue").default
      case DialogueIDs.EligibilityCheckDynamic:
        return require("./dialogues/eligibility/EligibilityCheckDynamicDialogue").default
      case DialogueIDs.EligibilityCheckBradford:
        return require("./dialogues/eligibility/EligibilityCheckBradfordDialogue").default
      case DialogueIDs.EligibilityCheckDemo:
        return require("./dialogues/eligibility/EligibilityCheckDemoDialogue").default
      case DialogueIDs.EligibilityCheckEssex:
        return require("./dialogues/eligibility/EligibilityCheckEssexDialogue").default
      case DialogueIDs.EligibilityCheckIAPT:
        return require("./dialogues/eligibility/EligibilityCheckIAPTDialogue").default
      case DialogueIDs.EligibilityCheckGP:
        return require("./dialogues/eligibility/EligibilityCheckGPDialogue").default
      case DialogueIDs.EligibilityCheckWellbeingHub:
        return require("./dialogues/eligibility/EligibilityCheckWellbeingHubDialogue").default
      case DialogueIDs.EligibilityCheckGMHub:
        return require("./dialogues/eligibility/EligibilityCheckGMHubDialogue").default
      case DialogueIDs.EligibilityCheckInsight:
        return require("./dialogues/eligibility/EligibilityCheckInsightDialogue").default
      case DialogueIDs.EligibilityCheckOxford:
        return require("./dialogues/eligibility/EligibilityCheckOxfordDialogue").default
      case DialogueIDs.EligibilityCheckBuckinghamshire:
        return require("./dialogues/eligibility/EligibilityCheckBuckinghamshireDialogue").default
      case DialogueIDs.EligibilityCheckLincolnshire:
        return require("./dialogues/eligibility/EligibilityCheckLincolnshireDialogue").default
      case DialogueIDs.EligibilityCheckSABP:
        return require("./dialogues/eligibility/EligibilityCheckSABPDialogue").default
      case DialogueIDs.EligibilityCheckVHG:
        return require("./dialogues/eligibility/EligibilityCheckVHGDialogue").default
      case DialogueIDs.EligibilityCheckVitality:
        return require("./dialogues/eligibility/EligibilityCheckVitalityDialogue").default
      case DialogueIDs.EligibilityCheckMPFT:
        return require("./dialogues/eligibility/EligibilityCheckMPFTDialogue").default
      case DialogueIDs.EligibilityCheckMHM:
        return require("./dialogues/eligibility/EligibilityCheckMHMDialogue").default
      case DialogueIDs.EligibilityCheckMind:
        return require("./dialogues/eligibility/EligibilityCheckMindDialogue").default
      case DialogueIDs.EligibilityCheckCW:
        return require("./dialogues/eligibility/EligibilityCheckCWDialogue").default
      case DialogueIDs.EligibilityCheckDorking:
        return require("./dialogues/eligibility/EligibilityCheckDorkingDialogue").default
      case DialogueIDs.SuitableServicesSignpostIAPT:
        return require("./dialogues/signposts/SuitableServicesSignpostIAPTDialogue").default
      case DialogueIDs.SuitableServicesSignpostCCG:
        return require("./dialogues/signposts/SuitableServicesSignpostCCGDialogue").default
      case DialogueIDs.SuitableServicesSignpostSABP:
        return require("./dialogues/signposts/SuitableServicesSignpostSABPDialogue").default
      case DialogueIDs.SuitableServicesSignpostEssex:
        return require("./dialogues/signposts/SuitableServicesSignpostEssexDialogue").default
      case DialogueIDs.SuitableServicesSignpostMind:
        return require("./dialogues/signposts/SuitableServicesSignpostMindDialogue").default
      case DialogueIDs.SuitableServicesSignpostLWC:
        return require("./dialogues/signposts/SuitableServicesSignpostLWCDialogue").default
      case DialogueIDs.SuitableServicesSignpostOxford:
        return require("./dialogues/signposts/SuitableServicesSignpostOxfordDialogue").default
      case DialogueIDs.SuitableServicesSignpostBuckinghamshire:
        return require("./dialogues/signposts/SuitableServicesSignpostBuckinghamshireDialogue")
          .default
      case DialogueIDs.UnderageSignpost:
        return require("./dialogues/signposts/UnderAgeSignpostDialogue").default
      case DialogueIDs.UnderageSignpostOxford:
        return require("./dialogues/signposts/UnderAgeSignpostOxfordDialogue").default
      case DialogueIDs.UnderAgeSignpostBuckinghamshire:
        return require("./dialogues/signposts/UnderAgeSignpostBuckinghamshireDialogue").default
      case DialogueIDs.UnderageSignpostBradford:
        return require("./dialogues/signposts/UnderAgeSignpostBradfordDialogue").default
      case DialogueIDs.UnderageSignpostDorking:
        return require("./dialogues/signposts/UnderAgeSignpostDorkingDialogue").default
      case DialogueIDs.UnderageSignpostGMHub:
        return require("./dialogues/signposts/UnderAgeSignpostGMHubDialogue").default
      case DialogueIDs.UnderageSignpostWellbeingHub:
        return require("./dialogues/signposts/UnderAgeSignpostWellbeingHubDialogue").default
      case DialogueIDs.UnderageSignpostEssex:
        return require("./dialogues/signposts/UnderAgeSignpostEssexDialogue").default
      case DialogueIDs.UnderageSignpostLincolnshire:
        return require("./dialogues/signposts/UnderAgeSignpostLincolnshireDialogue").default
      case DialogueIDs.UnderageSignpostSABP:
        return require("./dialogues/signposts/UnderAgeSignpostSABPDialogue").default
      case DialogueIDs.UnderageSignpostMPFT:
        return require("./dialogues/signposts/UnderAgeSignpostMPFTDialogue").default
      case DialogueIDs.UnderageSignpostMind:
        return require("./dialogues/signposts/UnderAgeSignpostMindDialogue").default
      case DialogueIDs.UnderAgeSignpostLWC:
        return require("./dialogues/signposts/UnderAgeSignpostLWCDialogue").default
      case DialogueIDs.UnderAgeSignpostBexley:
        return require("./dialogues/signposts/UnderAgeSignpostBexleyDialogue").default
      case DialogueIDs.UnderAgeSignpostCW:
        return require("./dialogues/signposts/UnderAgeSignpostCWDialogue").default
      case DialogueIDs.PeaceOfMindIAPT:
        return require("./dialogues/peaceOfMind/PeaceOfMindIAPTDialogue").default
      case DialogueIDs.PeaceOfMindGP:
        return require("./dialogues/peaceOfMind/PeaceOfMindGPDialogue").default
      case DialogueIDs.PeaceOfMindWellbeingHub:
        return require("./dialogues/peaceOfMind/PeaceOfMindWellbeingHubDialogue").default
      case DialogueIDs.PeaceOfMindHealix:
        return require("./dialogues/peaceOfMind/PeaceOfMindHealixDialogue").default
      case DialogueIDs.PeaceOfMindDemoPrivateProviders:
        return require("./dialogues/peaceOfMind/PeaceOfMindDemoPrivateProvidersDialogue").default
      case DialogueIDs.PeaceOfMindVitality:
        return require("./dialogues/peaceOfMind/PeaceOfMindVitalityDialogue").default
      case DialogueIDs.PermissionsDynamic:
        return require("./dialogues/permissions/PermissionsDynamicDialogue").default
      case DialogueIDs.PermissionsEssex:
        return require("./dialogues/permissions/PermissionsEssexDialogue").default
      case DialogueIDs.PermissionsIAPT:
        return require("./dialogues/permissions/PermissionsIAPTDialogue").default
      case DialogueIDs.PermissionsWellbeingHub:
        return require("./dialogues/permissions/PermissionsWellbeingHubDialogue").default
      case DialogueIDs.PermissionsGMHub:
        return require("./dialogues/permissions/PermissionsGMHubDialogue").default
      case DialogueIDs.PermissionsSABP:
        return require("./dialogues/permissions/PermissionsSABPDialogue").default
      case DialogueIDs.PermissionsInsight:
        return require("./dialogues/permissions/PermissionsInsightDialogue").default
      case DialogueIDs.PermissionsDemoPrivateProviders:
        return require("./dialogues/permissions/PermissionsDemoPrivateProvidersDialogue").default
      case DialogueIDs.PermissionsVitality:
        return require("./dialogues/permissions/PermissionsVitalityDialogue").default
      case DialogueIDs.PermissionsMPFT:
        return require("./dialogues/permissions/PermissionsMPFTDialogue").default
      case DialogueIDs.PermissionsMHM:
        return require("./dialogues/permissions/PermissionsMHMDialogue").default
      case DialogueIDs.PermissionsLincolnshire:
        return require("./dialogues/permissions/PermissionsLincolnshireDialogue").default
      case DialogueIDs.PermissionsMind:
        return require("./dialogues/permissions/PermissionsMindDialogue").default
      case DialogueIDs.PermissionsLWC:
        return require("./dialogues/permissions/PermissionsLWCDialogue").default
      case DialogueIDs.ResearchConsent:
        return require("./dialogues/researchConsent/ResearchConsentDialogue").default
      case DialogueIDs.ResearchConsentSABP:
        return require("./dialogues/researchConsent/ResearchConsentSABPDialogue").default
      case DialogueIDs.PermissionsOxfordBucks:
        return require("./dialogues/permissions/PermissionsOxfordBucksDialogue").default
      case DialogueIDs.PermissionsCW:
        return require("./dialogues/permissions/PermissionsCWDialogue").default
      case DialogueIDs.SelfReferralPitchIAPT:
        return require("./dialogues/selfReferralPitch/SelfReferralPitchIAPTDialogue").default
      case DialogueIDs.SelfReferralPitchHealix:
        return require("./dialogues/selfReferralPitch/SelfReferralPitchHealixDialogue").default
      case DialogueIDs.SelfReferralPitchGMHub:
        return require("./dialogues/selfReferralPitch/SelfReferralPitchGMHubDialogue").default
      case DialogueIDs.SelfReferralPitchWellbeingHub:
        return require("./dialogues/selfReferralPitch/SelfReferralPitchDialogueWellbeingHub")
          .default
      case DialogueIDs.SelfReferralPitchDemoPrivateProviders:
        return require("./dialogues/selfReferralPitch/SelfReferralPitchDemoPrivateProvidersDialogue")
          .default
      case DialogueIDs.SelfReferralPitchMind:
        return require("./dialogues/selfReferralPitch/SelfReferralPitchMindDialogue").default
      case DialogueIDs.SelfReferralDynamic:
        return require("./dialogues/selfReferral/SelfReferralDynamicDialogue").default
      case DialogueIDs.SelfReferralDemo:
        return require("./dialogues/selfReferral/SelfReferralDemoDialogue").default
      case DialogueIDs.SelfReferralEssex:
        return require("./dialogues/selfReferral/SelfReferralEssexDialogue").default
      case DialogueIDs.SelfReferralWellbeingHub:
        return require("./dialogues/selfReferral/SelfReferralWellbeingHubDialogue").default
      case DialogueIDs.SelfReferralWellbeingHubIndividual:
        return require("./dialogues/selfReferral/SelfReferralWellbeingHubIndividualDialogue")
          .default
      case DialogueIDs.SelfReferralWellbeingHubManagerIndividual:
        return require("./dialogues/selfReferral/SelfReferralWellbeingHubManagerIndividualDialogue")
          .default
      case DialogueIDs.SelfReferralWellbeingHubManagerTeam:
        return require("./dialogues/selfReferral/SelfReferralWellbeingHubManagerTeamDialogue")
          .default
      case DialogueIDs.SelfReferralGMHub:
        return require("./dialogues/selfReferral/SelfReferralGMHubDialogue").default
      case DialogueIDs.SelfReferralGMHubManagerTeam:
        return require("./dialogues/selfReferral/SelfReferralGMHubManagerTeamDialogue").default
      case DialogueIDs.SelfReferralGMHubManagerIndividual:
        return require("./dialogues/selfReferral/SelfReferralGMHubManagerIndividualDialogue")
          .default
      case DialogueIDs.SelfReferralGMHubIndividual:
        return require("./dialogues/selfReferral/SelfReferralGMHubIndividualDialogue").default
      case DialogueIDs.SelfReferralHealix:
        return require("./dialogues/selfReferral/SelfReferralHealixDialogue").default
      case DialogueIDs.SelfReferralInsight:
        return require("./dialogues/selfReferral/SelfReferralInsightDialogue").default
      case DialogueIDs.SelfReferralSABP:
        return require("./dialogues/selfReferral/SelfReferralSABPDialogue").default
      case DialogueIDs.SelfReferralLincolnshire:
        return require("./dialogues/selfReferral/SelfReferralLincolnshireDialogue").default
      case DialogueIDs.SelfReferralVHG:
        return require("./dialogues/selfReferral/SelfReferralVHGDialogue").default
      case DialogueIDs.SelfReferralDemoPrivateProviders:
        return require("./dialogues/selfReferral/SelfReferralDemoPrivateProvidersDialogue").default
      case DialogueIDs.SelfReferralInsightWaitlist:
        return require("./dialogues/selfReferral/SelfReferralInsightWaitlistDialogue").default
      case DialogueIDs.SelfReferralVitality:
        return require("./dialogues/selfReferral/SelfReferralVitalityDialogue").default
      case DialogueIDs.SelfReferralMPFT:
        return require("./dialogues/selfReferral/SelfReferralMPFTDialogue").default
      case DialogueIDs.SelfReferralMind:
        return require("./dialogues/selfReferral/SelfReferralMindDialogue").default
      case DialogueIDs.ADSMConsent:
        return require("./dialogues/adsmConsent/ADSMConsentDialogue").default
      case DialogueIDs.ADSMConsentInsight:
        return require("./dialogues/adsmConsent/ADSMConsentInsightDialogue").default
      case DialogueIDs.SelfReferralMHM:
        return require("./dialogues/selfReferral/SelfReferralMHMDialogue").default
      case DialogueIDs.AssessmentPitchBradford:
        return require("./dialogues/assessmentPitch/AssessmentPitchBradfordDialogue").default
      case DialogueIDs.AssessmentPitchIAPT:
        return require("./dialogues/assessmentPitch/AssessmentPitchIAPTDialogue").default
      case DialogueIDs.AssessmentPitchGP:
        return require("./dialogues/assessmentPitch/AssessmentPitchGPDialogue").default
      case DialogueIDs.AssessmentPitchWellbeingHub:
        return require("./dialogues/assessmentPitch/AssessmentPitchWellbeingHubDialogue").default
      case DialogueIDs.AssessmentPitchEssex:
        return require("./dialogues/assessmentPitch/AssessmentPitchEssexDialogue").default
      case DialogueIDs.AssessmentPitchGMHub:
        return require("./dialogues/assessmentPitch/AssessmentPitchGMHubDialogue").default
      case DialogueIDs.AssessmentPitchHealix:
        return require("./dialogues/assessmentPitch/AssessmentPitchHealixDialogue").default
      case DialogueIDs.AssessmentPitchInsight:
        return require("./dialogues/assessmentPitch/AssessmentPitchInsightDialogue").default
      case DialogueIDs.AssessmentPitchInsightFollowUp:
        return require("./dialogues/assessmentPitch/AssessmentPitchInsightFollowUpDialogue").default
      case DialogueIDs.AssessmentPitchLincolnshire:
        return require("./dialogues/assessmentPitch/AssessmentPitchLincolnshireDialogue").default
      case DialogueIDs.AssessmentPitchOxfordBucks:
        return require("./dialogues/assessmentPitch/AssessmentPitchOxfordBucksDialogue").default
      case DialogueIDs.AssessmentPitchSABP:
        return require("./dialogues/assessmentPitch/AssessmentPitchSABPDialogue").default
      case DialogueIDs.AssessmentPitchVHG:
        return require("./dialogues/assessmentPitch/AssessmentPitchVHGDialogue").default
      case DialogueIDs.AssessmentPitchDemoPrivateProviders:
        return require("./dialogues/assessmentPitch/AssessmentPitchDemoPrivateProvidersDialogue")
          .default
      case DialogueIDs.AssessmentPitchVitality:
        return require("./dialogues/assessmentPitch/AssessmentPitchVitalityDialogue").default
      case DialogueIDs.AssessmentPitchMPFT:
        return require("./dialogues/assessmentPitch/AssessmentPitchMPFTDialogue").default
      case DialogueIDs.AssessmentPitchMHM:
        return require("./dialogues/assessmentPitch/AssessmentPitchMHMDialogue").default
      case DialogueIDs.AssessmentBradford:
        return require("./dialogues/assessment/AssessmentBradfordDialogue").default
      case DialogueIDs.AssessmentPitchMind:
        return require("./dialogues/assessmentPitch/AssessmentPitchMindDialogue").default
      case DialogueIDs.AssessmentPitchLWC:
        return require("./dialogues/assessmentPitch/AssessmentPitchLWCDialogue").default
      case DialogueIDs.AssessmentPitchCW:
        return require("./dialogues/assessmentPitch/AssessmentPitchCWDialogue").default
      case DialogueIDs.AssessmentPitchDorking:
        return require("./dialogues/assessmentPitch/AssessmentPitchDorkingDialogue").default
      case DialogueIDs.AssessmentDynamic:
        return require("./dialogues/assessment/AssessmentDynamicDialogue").default
      case DialogueIDs.AssessmentDemo:
        return require("./dialogues/assessment/AssessmentDemoDialogue").default
      case DialogueIDs.AssessmentWellbeingHub:
        return require("./dialogues/assessment/AssessmentWellbeingHubDialogue").default
      case DialogueIDs.AssessmentEssex:
        return require("./dialogues/assessment/AssessmentEssexDialogue").default
      case DialogueIDs.AssessmentSABP:
        return require("./dialogues/assessment/AssessmentSABPDialogue").default
      case DialogueIDs.AssessmentGMHub:
        return require("./dialogues/assessment/AssessmentGMHubDialogue").default
      case DialogueIDs.AssessmentHealix:
        return require("./dialogues/assessment/AssessmentHealixDialogue").default
      case DialogueIDs.AssessmentInsight:
        return require("./dialogues/assessment/AssessmentInsightDialogue").default
      case DialogueIDs.AssessmentADSMDynamic:
        return require("./dialogues/assessment/AssessmentADSMDynamicDialogue").default
      case DialogueIDs.AssessmentADSMInsight:
        return require("./dialogues/assessment/AssessmentADSMInsightDialogue").default
      case DialogueIDs.AssessmentOxfordBucks:
        return require("./dialogues/assessment/AssessmentOxfordBucksDialogue").default
      case DialogueIDs.AssessmentVHG:
        return require("./dialogues/assessment/AssessmentVHGDialogue").default
      case DialogueIDs.AssessmentLincolnshire:
        return require("./dialogues/assessment/AssessmentLincolnshireDialogue").default
      case DialogueIDs.AssessmentDemoPrivateProviders:
        return require("./dialogues/assessment/AssessmentDemoPrivateProvidersDialogue").default
      case DialogueIDs.AssessmentVitality:
        return require("./dialogues/assessment/AssessmentVitalityDialogue").default
      case DialogueIDs.AssessmentMPFT:
        return require("./dialogues/assessment/AssessmentMPFTDialogue").default
      case DialogueIDs.AssessmentMHM:
        return require("./dialogues/assessment/AssessmentMHMDialogue").default
      case DialogueIDs.AssessmentMind:
        return require("./dialogues/assessment/AssessmentMindDialogue").default
      case DialogueIDs.AssessmentLWC:
        return require("./dialogues/assessment/AssessmentLWCDialogue").default
      case DialogueIDs.AssessmentCW:
        return require("./dialogues/assessment/AssessmentCWDialogue").default
      case DialogueIDs.AssessmentDorking:
        return require("./dialogues/assessment/AssessmentDorkingDialogue").default
      case DialogueIDs.PHQ9:
        return require("./surveys/PHQ9/PHQ9").default
      case DialogueIDs.PHQ9Dynamic:
        return require("./surveys/PHQ9/PHQ9DynamicDialogue").default
      case DialogueIDs.PHQ9GMHub:
        return require("./surveys/PHQ9/PHQ9GMHubDialogue").default
      case DialogueIDs.PHQ9WellbeingHub:
        return require("./surveys/PHQ9/PHQ9WellbeingHubDialogue").default
      case DialogueIDs.PHQ9SABP:
        return require("./surveys/PHQ9/PHQ9SABPDialogue").default
      case DialogueIDs.PHQ9VHG:
        return require("./surveys/PHQ9/PHQ9VHGDialogue").default
      case DialogueIDs.PHQ9Vitality:
        return require("./surveys/PHQ9/PHQ9VitalityDialogue").default
      case DialogueIDs.RiskPathwayDynamic:
        return require("./dialogues/riskPathway/RiskPathwayDynamicDialogue").default
      case DialogueIDs.RiskPathwayBradford:
        return require("./dialogues/riskPathway/RiskPathwayBradfordDialogue").default
      case DialogueIDs.RiskPathwayDemo:
        return require("./dialogues/riskPathway/RiskPathwayDemoDialogue").default
      case DialogueIDs.RiskPathwayGMHub:
        return require("./dialogues/riskPathway/RiskPathwayGMHubDialogue").default
      case DialogueIDs.RiskPathwayWellbeingHub:
        return require("./dialogues/riskPathway/RiskPathwayWellbeingHubDialogue").default
      case DialogueIDs.RiskPathwayEssex:
        return require("./dialogues/riskPathway/RiskPathwayEssexDialogue").default
      case DialogueIDs.RiskPathwayHealix:
        return require("./dialogues/riskPathway/RiskPathwayHealixDialogue").default
      case DialogueIDs.RiskPathwayInsight:
        return require("./dialogues/riskPathway/RiskPathwayInsightDialogue").default
      case DialogueIDs.RiskPathwayInsightFollowUp:
        return require("./dialogues/riskPathway/RiskPathwayInsightFollowUpDialogue").default
      case DialogueIDs.RiskPathwayOxford:
        return require("./dialogues/riskPathway/RiskPathwayOxfordDialogue").default
      case DialogueIDs.RiskPathwayBuckinghamshire:
        return require("./dialogues/riskPathway/RiskPathwayBuckinghamshireDialogue").default
      case DialogueIDs.RiskPathwaySABP:
        return require("./dialogues/riskPathway/RiskPathwaySABPDialogue").default
      case DialogueIDs.RiskPathwayVHG:
        return require("./dialogues/riskPathway/RiskPathwayVHGDialogue").default
      case DialogueIDs.RiskPathwayLincolnshire:
        return require("./dialogues/riskPathway/RiskPathwayLincolnshireDialogue").default
      case DialogueIDs.RiskPathwayDemoPrivateProviders:
        return require("./dialogues/riskPathway/RiskPathwayDemoPrivateProvidersDialogue").default
      case DialogueIDs.RiskPathwayMPFT:
        return require("./dialogues/riskPathway/RiskPathwayMPFTDialogue").default
      case DialogueIDs.RiskPathwayMHM:
        return require("./dialogues/riskPathway/RiskPathwayMHMDialogue").default
      case DialogueIDs.RiskPathwayMind:
        return require("./dialogues/riskPathway/RiskPathwayMindDialogue").default
      case DialogueIDs.RiskPathwayLWC:
        return require("./dialogues/riskPathway/RiskPathwayLWCDialogue").default
      case DialogueIDs.RiskPathwayCW:
        return require("./dialogues/riskPathway/RiskPathwayCWDialogue").default
      case DialogueIDs.RiskPathwayVitality:
        return require("./dialogues/riskPathway/RiskPathwayVitalityDialogue").default
      case DialogueIDs.RiskPathwayDorking:
        return require("./dialogues/riskPathway/RiskPathwayDorkingDialogue").default
      case DialogueIDs.Audit:
        return require("./surveys/Audit").default
      case DialogueIDs.DrugsAndSmoking:
        return require("./surveys/DrugsAndSmoking").default
      case DialogueIDs.ITQ:
        return require("./surveys/ITQ").default
      case DialogueIDs.IRQA:
        return require("./surveys/IRQ-A").default
      case DialogueIDs.GAD7:
        return require("./surveys/GAD7").default
      case DialogueIDs.IAPTPhobiaScale:
        return require("./surveys/IAPTPhobiaScale").default
      case DialogueIDs.IAPTEmploymentStatus:
        return require("./surveys/IAPTEmploymentStatus").default
      case DialogueIDs.IAPTEmploymentStatusKM:
        return require("./surveys/IAPTEmploymentStatusKM").default
      case DialogueIDs.IAPTWorkAndSocialAdjustment:
        return require("./surveys/IAPTWorkAndSocialAdjustment").default
      case DialogueIDs.IAPTMedication:
        return require("./surveys/IAPTMedication").default
      case DialogueIDs.IAPTAccommodation:
        return require("./surveys/IAPTAccommodation").default
      case DialogueIDs.PCL5:
        return require("./surveys/PCL5").default
      case DialogueIDs.PDSS:
        return require("./surveys/PDSS").default
      case DialogueIDs.SPIN:
        return require("./surveys/SPIN").default
      case DialogueIDs.OCI:
        return require("./surveys/OCI").default
      case DialogueIDs.SHAI18:
        return require("./surveys/SHAI18").default
      case DialogueIDs.SpecificPhobiaIntro:
        return require("./surveys/SpecificPhobiaIntro").default
      case DialogueIDs.SpecificPhobia:
        return require("./surveys/SpecificPhobia").default
      case DialogueIDs.TreatmentOptionsIAPT:
        return require("./dialogues/treatmentOptions/TreatmentOptionsIAPTDialogue").default
      case DialogueIDs.TreatmentOptionsHealix:
        return require("./dialogues/treatmentOptions/TreatmentOptionsHealixDialogue").default
      case DialogueIDs.TreatmentOptionsSABP:
        return require("./dialogues/treatmentOptions/TreatmentOptionsSABPDialogue").default
      case DialogueIDs.TreatmentOptionsInsight:
        return require("./dialogues/treatmentOptions/TreatmentOptionsInsightDialogue").default
      case DialogueIDs.TreatmentOptionsDemoPrivateProviders:
        return require("./dialogues/treatmentOptions/TreatmentOptionsDemoPrivateProvidersDialogue")
          .default
      case DialogueIDs.TreatmentOptionsVitality:
        return require("./dialogues/treatmentOptions/TreatmentOptionsVitalityDialogue").default
      case DialogueIDs.TreatmentOptionsLWC:
        return require("./dialogues/treatmentOptions/TreatmentOptionsLWCDialogue").default
      case DialogueIDs.WellbeingHealix:
        return require("./dialogues/wellbeing/WellbeingHealixDialogue").default
      case DialogueIDs.WellbeingDemoPrivateProviders:
        return require("./dialogues/wellbeing/WellbeingDemoPrivateProvidersDialogue").default
      case DialogueIDs.GoodbyeDynamic:
        return require("./dialogues/goodbye/GoodbyeDynamicDialogue").default
      case DialogueIDs.GoodbyeBradford:
        return require("./dialogues/goodbye/GoodbyeBradfordDialogue").default
      case DialogueIDs.GoodbyeDemo:
        return require("./dialogues/goodbye/GoodbyeDemoDialogue").default
      case DialogueIDs.GoodbyeGMHub:
        return require("./dialogues/goodbye/GoodbyeGMHubDialogue").default
      case DialogueIDs.GoodbyeWellbeingHub:
        return require("./dialogues/goodbye/GoodbyeWellbeingHubDialogue").default
      case DialogueIDs.GoodbyeEssex:
        return require("./dialogues/goodbye/GoodbyeEssexDialogue").default
      case DialogueIDs.GoodbyeHealix:
        return require("./dialogues/goodbye/GoodbyeHealixDialogue").default
      case DialogueIDs.GoodbyeInsight:
        return require("./dialogues/goodbye/GoodbyeInsightDialogue").default
      case DialogueIDs.GoodbyeInsightFollowUp:
        return require("./dialogues/goodbye/GoodbyeInsightFollowUpDialogue").default
      case DialogueIDs.GoodbyeOxford:
        return require("./dialogues/goodbye/GoodbyeOxfordDialogue").default
      case DialogueIDs.GoodbyeBuckinghamshire:
        return require("./dialogues/goodbye/GoodbyeBuckinghamshireDialogue").default
      case DialogueIDs.GoodbyeSABP:
        return require("./dialogues/goodbye/GoodbyeSABPDialogue").default
      case DialogueIDs.GoodbyeVHG:
        return require("./dialogues/goodbye/GoodbyeVHGDialogue").default
      case DialogueIDs.GoodbyeLincolnshire:
        return require("./dialogues/goodbye/GoodbyeLincolnshireDialogue").default
      case DialogueIDs.GoodbyeDemoPrivateProviders:
        return require("./dialogues/goodbye/GoodbyeDemoPrivateProvidersDialogue").default
      case DialogueIDs.GoodbyeVitality:
        return require("./dialogues/goodbye/GoodbyeVitalityDialogue").default
      case DialogueIDs.GoodbyeMPFT:
        return require("./dialogues/goodbye/GoodbyeMPFTDialogue").default
      case DialogueIDs.GoodbyeMHM:
        return require("./dialogues/goodbye/GoodbyeMHMDialogue").default
      case DialogueIDs.GoodbyeMind:
        return require("./dialogues/goodbye/GoodbyeMindDialogue").default
      case DialogueIDs.GoodbyeLWC:
        return require("./dialogues/goodbye/GoodbyeLWCDialogue").default
      case DialogueIDs.GoodbyeCW:
        return require("./dialogues/goodbye/GoodbyeCWDialogue").default
      case DialogueIDs.GoodbyeDorking:
        return require("./dialogues/goodbye/GoodbyeDorkingDialogue").default
      case DialogueIDs.GoodbyeBexley:
        return require("./dialogues/goodbye/GoodbyeBexleyDialogue").default
      case DialogueIDs.CrisisBradford:
        return require("./dialogues/crisis/CrisisBradfordDialogue").default
      case DialogueIDs.CrisisDynamic:
        return require("./dialogues/crisis/CrisisDynamicDialogue").default
      case DialogueIDs.CrisisDemo:
        return require("./dialogues/crisis/CrisisDemoDialogue").default
      case DialogueIDs.CrisisGMHub:
        return require("./dialogues/crisis/CrisisGMHubDialogue").default
      case DialogueIDs.CrisisWellbeingHub:
        return require("./dialogues/crisis/CrisisWellbeingHubDialogue").default
      case DialogueIDs.CrisisEssex:
        return require("./dialogues/crisis/CrisisEssexDialogue").default
      case DialogueIDs.CrisisInsight:
        return require("./dialogues/crisis/CrisisInsightDialogue").default
      case DialogueIDs.CrisisOxfordBucks:
        return require("./dialogues/crisis/CrisisOxfordBucksDialogue").default
      case DialogueIDs.CrisisHealix:
        return require("./dialogues/crisis/CrisisHealixDialogue").default
      case DialogueIDs.CrisisSABP:
        return require("./dialogues/crisis/CrisisSABPDialogue").default
      case DialogueIDs.CrisisVHG:
        return require("./dialogues/crisis/CrisisVHGDialogue").default
      case DialogueIDs.CrisisLincolnshire:
        return require("./dialogues/crisis/CrisisLincolnshireDialogue").default
      case DialogueIDs.CrisisDemoPrivateProviders:
        return require("./dialogues/crisis/CrisisDemoPrivateProvidersDialogue").default
      case DialogueIDs.CrisisVitality:
        return require("./dialogues/crisis/CrisisVitalityDialogue").default
      case DialogueIDs.CrisisMPFT:
        return require("./dialogues/crisis/CrisisMPFTDialogue").default
      case DialogueIDs.CrisisMHM:
        return require("./dialogues/crisis/CrisisMHMDialogue").default
      case DialogueIDs.CrisisMind:
        return require("./dialogues/crisis/CrisisMindDialogue").default
      case DialogueIDs.CrisisLWC:
        return require("./dialogues/crisis/CrisisLWCDialogue").default
      case DialogueIDs.CrisisCW:
        return require("./dialogues/crisis/CrisisCWDialogue").default
      case DialogueIDs.CrisisDorking:
        return require("./dialogues/crisis/CrisisDorkingDialogue").default
      case DialogueIDs.BookAppointmentChatflow:
        return require("./dialogues/ad-hoc/BookAppointment/BookAppointmentDialogue").default
      case DialogueIDs.BookAppointmentDynamic:
        return require("./dialogues/bookAppointment/BookAppointmentDynamicDialogue").default
      case DialogueIDs.BookAppointmentVitality:
        return require("./dialogues/bookAppointment/BookAppointmentVitalityDialogue").default
      case DialogueIDs.BookAppointmentMind:
        return require("./dialogues/bookAppointment/BookAppointmentMindDialogue").default
      case DialogueIDs.CollectPhoneNumber:
        return require("./dialogues/ad-hoc/CollectPhoneNumber/CollectPhoneNumberDialogue").default
      case DialogueIDs.CollectPhoneNumberEssex:
        return require("./dialogues/ad-hoc/CollectPhoneNumber/CollectPhoneNumberEssexDialogue")
          .default
      case DialogueIDs.CollectPhoneNumberInsight:
        return require("./dialogues/ad-hoc/CollectPhoneNumber/CollectPhoneNumberInsightDialogue")
          .default
      case DialogueIDs.CollectPhoneNumberMPFT:
        return require("./dialogues/ad-hoc/CollectPhoneNumber/CollectPhoneNumberMPFTDialogue")
          .default
      case DialogueIDs.CollectPhoneNumberMHM:
        return require("./dialogues/ad-hoc/CollectPhoneNumber/CollectPhoneNumberMHMDialogue")
          .default
      case DialogueIDs.CollectAlcoholConsumption:
        return require("./dialogues/ad-hoc/CollectAlcoholConsumption/CollectAlcoholConsumptionDialogue")
          .default
      case DialogueIDs.CheckPostCodeFromAddressLookup:
        return require("./dialogues/ad-hoc/CheckPostCodeFromAddressLookup/CheckPostCodeFromAddressLookupDialogue")
          .default
      case DialogueIDs.CheckPostCodeFromAddressLookupMHM:
        return require("./dialogues/ad-hoc/CheckPostCodeFromAddressLookup/CheckPostCodeFromAddressLookupMHMDialogue")
          .default
      case DialogueIDs.CheckPostCodeFromAddressLookupSABP:
        return require("./dialogues/ad-hoc/CheckPostCodeFromAddressLookup/CheckPostCodeFromAddressLookupSABPDialogue")
          .default
      case DialogueIDs.CheckPostCodeFromAddressLookupMind:
        return require("./dialogues/ad-hoc/CheckPostCodeFromAddressLookup/CheckPostCodeFromAddressLookupMindDialogue")
          .default
      case DialogueIDs.CheckCovidAndDetails:
        return require("./dialogues/ad-hoc/CheckCovidAndDetails/CheckCovidAndDetailsDialogue")
          .default
      case DialogueIDs.CollectMainIssue:
        return require("./dialogues/ad-hoc/CollectMainIssue/CollectMainIssueDialogue").default
      case DialogueIDs.CollectGoalForTherapy:
        return require("./dialogues/ad-hoc/CollectGoalForTherapy/CollectGoalForTherapyDialogue")
          .default
      case DialogueIDs.CollectNHSNumber:
        return require("./dialogues/ad-hoc/CollectNHSNumber/CollectNHSNumberDialogue").default
      case DialogueIDs.CollectASD:
        return require("./dialogues/ad-hoc/CollectASD/CollectASDDialogue").default
      case DialogueIDs.CollectADHD:
        return require("./dialogues/ad-hoc/CollectADHD/CollectADHDDialogue").default
      case DialogueIDs.CollectPreferredCorrespondence:
        return require("./dialogues/ad-hoc/CollectPreferredCorrespondence/CollectPreferredCorrespondenceDialogue")
          .default
      case DialogueIDs.CollectNationality:
        return require("./dialogues/ad-hoc/CollectNationality/CollectNationalityDialogue").default
      case DialogueIDs.CollectEthnicity:
        return require("./dialogues/ad-hoc/CollectEthnicity/CollectEthnicityDialogue").default
      case DialogueIDs.CollectPriorMHTreatmentDetails:
        return require("./dialogues/ad-hoc/CollectPriorMHTreatmentDetails/CollectPriorMHTreatmentDetailsDialogue")
          .default
      case DialogueIDs.CollectCurrentMHTreatmentDetails:
        return require("./dialogues/ad-hoc/CollectCurrentMHTreatmentDetails/CollectCurrentMHTreatmentDetailsDialogue")
          .default
      case DialogueIDs.CollectSexuality:
        return require("./dialogues/ad-hoc/CollectSexuality/CollectSexualityDialogue").default
      case DialogueIDs.CollectGender:
        return require("./dialogues/ad-hoc/CollectGender/CollectGenderDialogue").default
      case DialogueIDs.CollectDisabilities:
        return require("./dialogues/ad-hoc/CollectDisabilities/CollectDisabilitiesDialogue").default
      case DialogueIDs.CollectLanguageAndInterpreter:
        return require("./dialogues/ad-hoc/CollectLanguageAndInterpreter/CollectLanguageAndInterpreterDialogue")
          .default
      case DialogueIDs.CollectEmail:
        return require("./dialogues/ad-hoc/CollectEmail/CollectEmailDialogue").default
      case DialogueIDs.CollectMedicationAndSubstancesDetails:
        return require("./dialogues/ad-hoc/CollectMedicationAndSubstancesDetails/CollectMedicationAndSubstancesDetailsDialogue")
          .default
      case DialogueIDs.CollectSubstances:
        return require("./dialogues/ad-hoc/CollectSubstances/CollectSubstancesDialogue").default
      case DialogueIDs.CollectLongTermMedicalConditionDetails:
        return require("./dialogues/ad-hoc/CollectLongTermMedicalConditionDetails/CollectLongTermMedicalConditionDetailsDialogue")
          .default
      case DialogueIDs.CollectReligion:
        return require("./dialogues/ad-hoc/CollectReligion/CollectReligionDialogue").default
      case DialogueIDs.CollectDateOfBirth:
        return require("./dialogues/ad-hoc/CollectDateOfBirth/CollectDateOfBirthDialogue").default
      case DialogueIDs.CollectName:
        return require("./dialogues/ad-hoc/CollectName/CollectNameDialogue").default
      case DialogueIDs.CollectFeedback:
        return require("./dialogues/ad-hoc/CollectFeedback/CollectFeedbackDialogue").default
      case DialogueIDs.CollectSMI:
        return require("./dialogues/ad-hoc/CollectSMI/CollectSMIDialogue").default
      case DialogueIDs.CheckCrisisDetection:
        return require("./dialogues/ad-hoc/CheckCrisisDetection/CheckCrisisDetectionDialogue")
          .default
      case DialogueIDs.GetPermissions:
        return require("./dialogues/ad-hoc/GetPermissions/GetPermissionsDialogue").default
      case DialogueIDs.ServiceSearch:
        return require("./dialogues/ad-hoc/ServiceSearch/ServiceSearchDialogue").default
      case DialogueIDs.SpineSearch:
        return require("./dialogues/ad-hoc/SpineSearch/SpineSearchDialogue").default
      case DialogueIDs.SubmitReferral:
        return require("./dialogues/ad-hoc/SubmitReferral/SubmitReferralDialogue").default
      case DialogueIDs.AssessmentAndTreatments:
        return require("./dialogues/ad-hoc/AssessmentAndTreatments/AssessmentAndTreatmentsDialogue")
          .default
      case DialogueIDs.GoodbyeRecap:
        return require("./dialogues/ad-hoc/GoodbyeRecap/GoodbyeRecapDialogue").default
      default: {
        const errorMessage = `Unknown dialogue identifier: ${id}`
        Logger.getInstance().exception(new Error(errorMessage))
        throw new Error(errorMessage)
      }
    }
  }
}

export default new DialoguesRegistry()
