import type Script from "../../Script"
import type { ScriptState } from "../../Script"
import type {
  IStep,
  IStepData,
  IStepResult,
  StepDecorator,
  StepDescriptor
} from "../../models/IStep"

type Props<State extends ScriptState> = Partial<State> | ((state: State) => Partial<State>)

function decorate<State extends ScriptState, S extends Script<State> = any>(
  step: IStep<State>,
  props: Props<State>
): IStep<State> {
  return async function (this: S, d: IStepData<State>): Promise<IStepResult> {
    if (d) {
      const newState = typeof props === "function" ? props(d.state) : props
      try {
        // I don't use spread operators to create
        // the new state merge because I want to
        // make sure the initial state is mutated
        // and not just reassigned
        const keys = Object.keys(newState)
        for (const key of keys) {
          d.state[key] = newState[key]
        }
      } catch (e) {
        this.logException(e, "step setState decorator")
      }
    }
    return step.call(this, d)
  }
}

export function setState<State extends ScriptState, S extends Script<State> = any>(
  props: Props<State>
): StepDecorator<State, S> {
  return function (_: S, __: keyof S, desc: StepDescriptor): StepDescriptor {
    const org = desc.value
    desc.value = decorate(org!, props)
    return desc
  }
}

setState.decorate = decorate
