import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { GoodbyeScriptState } from "./GoodbyeScript"
import GoodbyeScript from "./GoodbyeScript"
import { IStepData, IStepResult, StepResultBodyType } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"

interface State extends GoodbyeScriptState {
  canKeepSelfSafe?: boolean
  requiresUrgentSupport?: boolean
}

export type GoodbyeDorkingScriptState = State

export class GoodbyeDorkingScript extends GoodbyeScript {
  readonly name: string = "GoodbyeDorkingScript"

  /** Script Steps */

  @step
  async sayGoodBye(d: IStepData<State>): Promise<IStepResult> {
    await this.referralStore.updateReferral({
      isHelpful: d.state.isHelpful,
      improvementSuggestion: d.state.improvementSuggestion
    })
    this.referralStore.stopPinging()
    const name = this.getName(d.state)

    return {
      body: [
        "Well done for taking this important step towards better mental health",
        `Goodbye ${name} 👋`
      ],
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onHandleRecapMessage(
    _state: State,
    body: StepResultBodyType[],
    recapMessage: string | void
  ): Promise<IStepResult | void> {
    const isCrisis = this.clinicalStore.isCrisis
    if (!body.length) {
      return { nextStep: isCrisis ? this.end : this.askFeedback }
    }

    const choices: any[] = [{ body: "Okay" }, recapMessage && { body: "I understand" }] //
      .filter(Boolean)

    return {
      body,
      prompt: {
        id: this.getPromptId("recap"),
        trackResponse: true,
        type: "inlinePicker",
        choices
      },
      nextStep: isCrisis ? this.end : this.askFeedback
    }
  }

  async getIntroMessage(state: State): Promise<string | void> {
    const isCrisis = this.clinicalStore.isCrisis
    const name = this.getName(state)
    return isCrisis
      ? "I hope I’ve been able to help you today"
      : `Thank you for sharing this information with me ${name}`
  }

  async getRecapMessage(state: State): Promise<string | void> {
    const recaps: string[] = []
    const referredYouMessage = await this.getReferredYouMessage(state)
    referredYouMessage && recaps.push(referredYouMessage)

    const contactMessage = await this.getContactMessage(state)
    contactMessage && recaps.push(contactMessage)

    const riskMessage = await this.getRiskMessage(state)
    riskMessage && recaps.push(riskMessage)

    const closingMessage = await this.getClosingMessage(state)
    closingMessage && recaps.push(closingMessage)

    const formattedRecap = recaps.length > 1 ? recaps.map((i, idx) => `${idx + 1}. ${i}`) : recaps
    const recapString = formattedRecap.join("\n")

    if (recapString?.length) {
      return `Just to recap:\n\n${recapString}`
    }
  }

  async getReferredYouMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted) {
      const serviceName = this.rootStore.configStore.serviceName
      const referralMessage = `I've referred you to ${serviceName}, thank you for taking the time to reach out and complete this referral`
      return state.isIdleSubmitted //
        ? `It looks like there hasn’t been any activity for some time so I've referred you to ${serviceName}`
        : referralMessage
    }
  }

  async getContactMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted) {
      if (this.clinicalStore.isRisk) {
        return "Because you mentioned you are concerned about your safety or experiencing high levels of distress, someone from our team will aim to contact you by the end of the next working day at the latest. If you have not given us permission to leave a message, you may not know that we have tried to contact you, but we will try again"
      } else {
        return "Someone from our team will aim to contact you by the end of the next working day at the latest. If you have not given us permission to leave a message, you may not know that we have tried to contact you, but we will try again"
      }
    }
  }

  async getRiskMessage(_state: State): Promise<string | void> {
    if (this.clinicalStore.isRisk) {
      return (
        "Please remember, Limbic is not an emergency response service. If you need urgent help, please contact 999 or NHS 111 and select Option 2. You can also contact the Samaritans 24/7 on 116123\n\n" +
        "If you are hard of hearing, have speech difficulties or prefer not to talk over the phone, you can contact the following organisations for support via text message:\n\n" +
        "NHS Mental Health Crisis Line – text service available on 07717 989 024. If you have speech or hearing difficulties, this service can be accessed by dialling through the BT Relay app or textphone: 18001 0800 915 4644 [More info here](https://www.relayuk.bt.com/)\n\n" +
        "Shout – text shout to 85258 for 24/7 free support\n\n" +
        "For immediate support if you cannot keep yourself safe text 999 if you have registered your phone, or go to A&E"
      )
    }
  }

  async getClosingMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted) {
      return "If you have any queries or concerns, please don't hesitate to get in touch with us on 01483 906392"
    }
  }

  async getCustomRecapMessage(state: State): Promise<StepResultBodyType[] | undefined> {
    const isCrisis = this.clinicalStore.isCrisis
    const name = this.getName(state)
    const lastMessage = state.requiresUrgentSupport
      ? `I look forward to talking with you again ${name}`
      : `Well, it's been a pleasure getting to know you ${name}`
    return [isCrisis ? `Thank you for sharing this information with me ${name}` : lastMessage]
  }
}

/* istanbul ignore next */
export default class GoodbyeDorkingDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeDorking
  readonly name: string = "GoodbyeDorkingDialogue"

  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeDorkingDialogue.id, new GoodbyeDorkingScript(), state, snapshot)
  }
}
