import Logger from "../../../utils/Logger"
import delay from "../../../utils/delay"
import sendEmail from "./sendEmail"

const TOTAL_RETRIES = 2
const riskEmailEnabled = process.env.REACT_APP_RISK_EMAIL === "enabled"

interface Params {
  to: string[]
  cc?: string[]
  bcc?: string[]
  text: string
  isNewNumber?: boolean
}

export default async function sendRiskEmail(params: Params, retry = 0): Promise<void> {
  if (!riskEmailEnabled) {
    Logger.getInstance().breadcrumb({ message: "email dispatch skipped" })
    return
  }

  const { to, cc, bcc, text, isNewNumber } = params
  const subject = isNewNumber ? "Urgent: LIMBIC RISK - UPDATED PHONE NUMBER" : "Urgent: LIMBIC RISK"
  const payload = { to, cc, bcc, text, subject }
  try {
    await sendEmail(payload) // 👈 data is empty
  } catch (e) {
    Logger.getInstance().exception(e, "sendRiskEmail fetch failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`email data for retry ${retry}`, JSON.stringify(payload))
      Logger.getInstance().message("sendRiskEmail Retry")
      await delay(3 * retry || 1.5)
      return await sendRiskEmail(params, retry + 1)
    }
    throw e
  }
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}
