import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { AssessmentIAPTScriptState } from "./AssessmentIAPTScript"
import AssessmentIAPTScript from "./AssessmentIAPTScript"
import type { IStepResult } from "../../../backend/chatbot/models/IStep"

type State = AssessmentIAPTScriptState

export class AssessmentMPFTScript extends AssessmentIAPTScript {
  readonly name: string = "AssessmentMPFTScript"

  /** Generic Handlers */

  async onHandleIAPTPhobiaScale(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayReadyForWASAS }
  }

  async onHandleIAPTWorkAndSocialAdjustment(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayThatsEverything }
  }
}

/* istanbul ignore next */
export default class AssessmentMPFTDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentMPFT
  readonly name: string = "AssessmentMPFTDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentMPFTDialogue.id, new AssessmentMPFTScript(), state, snapshot)
  }
}
