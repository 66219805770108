import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import GoodbyeScript from "./GoodbyeScript"
import { ReferralType, TrackingEvents } from "../../../models/Constants"
import type { GoodbyeScriptState } from "./GoodbyeScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = GoodbyeScriptState
export type GoodbyeSABPScriptState = State

export class GoodbyeSABPScript extends GoodbyeScript {
  readonly name: string = "GoodbyeSABPScript"

  @step.logState
  askWhereDidYouHearAboutUs(d: IStepData<State>): IStepResult {
    const requiresUrgentSupport =
      this.clinicalStore.requiresUrgentSupport || d.state.requiresUrgentSupport

    if (this.clinicalStore.isCrisis || requiresUrgentSupport) {
      this.track(TrackingEvents.SKIP_WHERE_DID_YOU_HEAR)
      return { nextStep: this.sayIHopeIHelped }
    }
    const serviceName = this.rootStore.configStore.serviceName
    return {
      body: this.t("Before we close, where did you hear about {serviceName}?", { serviceName }),
      prompt: {
        id: this.getPromptId("askWhereDidYouHearAboutUs"),
        type: "text",
        cancelIsEmptySubmit: true
      },
      nextStep: this.handleWhereDidYouHearAboutUsWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: GoodbyeScript) => {
    d.state.whereDidYouHearAboutService = d.response
    void script.referralStore.updateReferral({
      whereHeard: d.response,
      referralSourceDetails: d.response
    })
  })
  @step.checkInputForCrisis({
    getNextStep: (s: GoodbyeScript) => s.askFeedback
  })
  handleWhereDidYouHearAboutUsWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askFeedback }
  }

  /** Generic Handlers */

  async getHighRiskContactMessage(state: State): Promise<string | void> {
    if (this.clinicalStore.isHighRisk) {
      const organisationName = this.rootStore.configStore.organisationName
      const iaptName = this.getIAPTName(state) || organisationName
      return this.t(
        "Because you identified as being in crisis, I have let {iaptName} know in the referral. Remember, for life threatening medical help you can call 999 or the Mental Health Crisis Helpline on 0800 915 4644 if it's not a life-threatening situation",
        { iaptName }
      )
    }
  }

  async getModerateRiskContactMessage(state: State): Promise<string | void> {
    if (this.clinicalStore.isModerateRisk) {
      const organisationName = this.rootStore.configStore.organisationName
      const iaptName = this.getIAPTName(state) || organisationName
      return this.t(
        "Because you identified as being in crisis, I have let {iaptName} know in the referral. Remember, for life threatening medical help you can call 999 or the Mental Health Crisis Helpline on 0800 915 4644 if it's not a life-threatening situation",
        { iaptName }
      )
    }
  }

  getCustomReferralType(state: State): string | undefined {
    const currentMHTreatment = this.referralStore.getCustomField("currentMHTreatment")
    const hasCurrentMHTreatment = !!currentMHTreatment?.length
    if (hasCurrentMHTreatment) return "Extended Assessment (User seeing other MH professionals)"
  }

  getReferralTypeForRisk(_state: State): string | undefined {
    if (this.clinicalStore.isHighRisk) return ReferralType.RISK_PATIENT
    if (this.clinicalStore.isModerateRisk) return ReferralType.RISK_PATIENT
  }
}

/* istanbul ignore next */
export default class GoodbyeSABPDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeSABP
  readonly name: string = "GoodbyeSABPDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeSABPDialogue.id, new GoodbyeSABPScript(), state, snapshot)
  }
}
