import ChatFlowPayloadBuilder from "../ChatFlowPayloadBuilder"
import { ChatFlowsEnum, ReferralPayload } from "@limbic/types"

export default class SexualityPayloadBuilder extends ChatFlowPayloadBuilder<ChatFlowsEnum.COLLECT_SEXUALITY> {
  chatFlow = ChatFlowsEnum.COLLECT_SEXUALITY

  /** Overrides */

  getDefaultPayload(): Partial<Partial<ReferralPayload>> {
    return { sexuality: this.ctx.state?.sexuality } as Partial<ReferralPayload>
  }
}

ChatFlowPayloadBuilder.builders[ChatFlowsEnum.COLLECT_SEXUALITY] = SexualityPayloadBuilder
