import ChatFlowPayloadBuilder from "../ChatFlowPayloadBuilder"
import { ChatFlowsEnum, ReferralPayload } from "@limbic/types"

export default class GoalForTherapyePayloadBuilder extends ChatFlowPayloadBuilder<ChatFlowsEnum.COLLECT_GOAL_FOR_THERAPY> {
  chatFlow = ChatFlowsEnum.COLLECT_GOAL_FOR_THERAPY

  /** Overrides */

  getDefaultPayload(): Partial<Partial<ReferralPayload>> {
    return { treatmentExpectation: this.ctx.state?.therapyGoal } as Partial<ReferralPayload>
  }
}

// prettier-ignore
ChatFlowPayloadBuilder.builders[ChatFlowsEnum.COLLECT_GOAL_FOR_THERAPY] = GoalForTherapyePayloadBuilder
