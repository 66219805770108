import moment from "moment"
import { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { SelfReferralIAPTScriptState } from "./SelfReferralIAPTScript"
import SelfReferralIAPTScript from "./SelfReferralIAPTScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import { fullNameRegex } from "../../../utils/fullNameRegex"
import invariant from "../../../utils/invariant"
import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"
import { isValidLandlineNumber, isValidMobilePhone } from "../../../utils/isValidPhoneNumber"
import {
  DISABILITY_PCMIS_GMHUB,
  ETHNICITY_PCMIS_GMHUB,
  GENDER_PCMIS,
  GenderBirthAssigned,
  IDefaultChatFlowSettingsCheckPostCodeFromAddressLookup,
  LANGUAGE_PCMIS_GMHUB,
  ReferralPayloadPCMISGMHub,
  RELIGION_PCMIS,
  SEXUALITY_PCMIS_GMHUB
} from "@limbic/types"
import { IStep } from "../../../backend/chatbot/models/IStep"
import {
  CollectGenderScriptState,
  ICollectGenderSettings
} from "../ad-hoc/CollectGender/CollectGenderDialogue"
import { CollectLanguageAndInterpreterScriptState } from "../ad-hoc/CollectLanguageAndInterpreter/CollectLanguageAndInterpreterDialogue"
import { ICollectReligionSettings } from "../ad-hoc/CollectReligion/CollectReligionDialogue"
import { parsePhoneNumber } from "awesome-phonenumber"

type State = SelfReferralIAPTScriptState

export type SelfReferralGMHubIndividualScriptState = State

export class SelfReferralGMHubIndividualScript extends SelfReferralIAPTScript {
  readonly name: string = "SelfReferralGMHubIndividualScript"

  /** Script Steps */
  @step.logState
  @step.setState<State>({ addressLookupCounter: 0, postcodeLookupCounter: 0 })
  start(_d: IStepData<State>): IStepResult {
    this.timeEvent(this.name)
    return {
      body: this.t([
        "Wonderful!",
        "There are just a few more details I need from you",
        "This should take no more than 5 minutes"
      ]),
      nextStep: this.startSelfReferralPart1
    }
  }

  @step.logState
  startSelfReferralPart1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askFullName }
  }

  @step
  askFullName(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("What's your full name? ✏️"),
      nextStep: this.showPromptForFullName
    }
  }

  @step
  sayPleaseGiveFullName(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Please enter your full name"),
      nextStep: this.showPromptForFullName
    }
  }

  @step.logState
  showPromptForFullName(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("showPromptForFullName"),
        type: "text",
        validation: [new RegExp(fullNameRegex)],
        validationExplainer: this.t(["Please enter your full name"]),
        forceValue: true
      },
      nextStep: this.handleFullNameWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralGMHubIndividualScript) => {
    const username = d.response?.trim()
    d.state.username = username
    script.rootStore.applicationStore.setUsername(username)
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    getNextStep: (s: SelfReferralGMHubIndividualScript) => s.sayPleaseGiveFullName
  })
  async handleFullNameWithCrisis(_d: IStepData<State, string>): Promise<IStepResult> {
    return { nextStep: this.checkFullName }
  }

  @step.logState
  async checkFullName(d: IStepData<State>): Promise<IStepResult> {
    if (!d.state.username || d.state.username.trim() === "") {
      return { nextStep: this.sayPleaseGiveFullName }
    }
    return { nextStep: this.sayNiceToMeetYou }
  }

  @step
  sayNiceToMeetYou(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t("Nice to meet you {name}", { name }),
      prompt: {
        id: this.getPromptId("sayNiceToMeetYou"),
        type: "inlinePicker",
        choices: [{ body: this.t("Nice to meet you too") }]
      },
      nextStep: this.goToCheckPostCodeForAddressLookup
    }
  }

  @step.logState
  askWhereDidYouHearAboutUs(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("And finally, where did you hear about us?"),
      prompt: {
        id: this.getPromptId("askWhereDidYouHearAboutUs"),
        type: "text",
        cancelIsEmptySubmit: true
      },
      nextStep: this.handleWhereDidYouHearAboutUsWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.whereDidYouHearAboutService = d.response
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralGMHubIndividualScript) => s.doReferralSubmission
  })
  handleWhereDidYouHearAboutUsWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.doReferralSubmission }
  }

  @step.logState
  sayReferralSucceeded(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    return {
      body: this.t(
        [
          "And that's everything",
          "You've officially been referred to {iaptName}",
          "Congratulations on taking this important step towards better mental health!"
        ],
        { iaptName }
      ),
      prompt: {
        id: this.getPromptId("sayReferralSucceeded"),
        type: "inlinePicker",
        choices: [{ body: this.t("What happens next?") }]
      },
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async getCollectPostcodeAndAddressSettings(
    state: State
  ): Promise<IDefaultChatFlowSettingsCheckPostCodeFromAddressLookup> {
    return { startWithAskPostcode: true }
  }

  async onCollectPostcodeAndAddressEnded(state: State): Promise<IStep> {
    return this.goToCollectPhoneNumber
  }

  async onCollectEmailEnded(state: State): Promise<IStep> {
    return this.goToCollectGender
  }

  async getCollectGenderSettings(state: State): Promise<ICollectGenderSettings> {
    const genders = this.getGenders(state)
    const gendersSameAsBirth = this.getGenderSameAsBirthValues(state)
    // This is temporary - to be replaced by actual value when we have the dashboard
    return {
      messages: {
        askSameGenderAsBirth: this.t([
          "Do you identify as the same gender you were assigned at birth?"
        ])
      },
      optionsGender: genders.map(g => ({ body: this.t(g), value: g })),
      optionsGenderSameAsBirth: gendersSameAsBirth.map(g => ({ body: this.t(g), value: g }))
    }
  }

  async getCollectGenderState(state: State): Promise<CollectGenderScriptState> {
    return { skipSameGenderAsBirth: false }
  }

  async onCollectGenderEnded(state: State): Promise<IStep> {
    return this.goToCollectReligion
  }

  async getCollectReligionSettings(state: State): Promise<ICollectReligionSettings> {
    const optionsReligions = this.getReligions(state).map(g => ({ body: g, value: g }))

    return {
      options: optionsReligions
    }
  }

  async onCollectReligionEnded(_state: State): Promise<IStep> {
    return this.goToCollectLanguageAndInterpreter
  }

  async getCollectLanguageAndInterpreterState(
    state: State
  ): Promise<CollectLanguageAndInterpreterScriptState> {
    return { skipInterpreterLanguageQuestion: true }
  }

  async onCollectLanguageAndInterpreterEnded(state: State): Promise<IStep> {
    return this.goToCollectEthnicity
  }

  async onCollectEthnicityEnded(state: State): Promise<IStep> {
    return this.goToCollectSexuality
  }

  async onCollectSexualityEnded(state: State): Promise<IStep> {
    return this.goToCollectDisabilities
  }

  async onCollectDisabilitiesEnded(state: State): Promise<IStep> {
    return this.goToCollectGoalForTherapy
  }

  async onCollectGoalForTherapyEnded(state: State): Promise<IStep> {
    return this.askWhereDidYouHearAboutUs
  }

  async getReferralPayload(state: State): Promise<ReferralPayloadPCMISGMHub> {
    const instanceID = this.rootStore.configStore.backendInstanceID || state.iapt?.backendInstanceID
    invariant(instanceID, "Cannot create referral without an Instance ID")
    this.referralStore.setInstanceID(instanceID)

    const isValidMobile = isValidMobilePhone(state.phoneNumber || "0")
    const isValidLandline = isValidLandlineNumber(state.phoneNumber || "0") && !isValidMobile
    const parsed = parsePhoneNumber(state.phoneNumber || "0", { regionCode: "GB" })

    return {
      instanceID,
      nameFirst: this.getFirstName(state),
      nameLast: this.getLastName(state),
      title: state.title,
      dob:
        this.referralStore.getCustomField("dateOfBirth") ||
        moment(state.birthday).format("DD/MM/YYYY"),
      addressHome: {
        address1: state.address,
        address2: state.address2,
        city: state.city,
        county: state.county,
        postcode: state.userPostcode?.postcode,
        consentMail: !!state.canSendMailToAddress
      },
      email: state.email,
      consentEmail: !!state.email,
      phoneHome: isValidLandline
        ? {
            cc: String(parsed.countryCode || ""),
            number: parsed.number?.national.replace(/ /g, "") ?? state.phoneNumber!,
            isMobile: false,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      phoneMobile: isValidMobile
        ? {
            cc: String(parsed.countryCode || ""),
            number: parsed.number?.national.replace(/ /g, "") ?? state.phoneNumber!,
            isMobile: true,
            consentSMS: !!state.canSendTextMessagesToPhoneNumber,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      ethnicity: this.getEthnicity(state),
      disability: this.getDisability(state),
      gender: this.getGender(state),
      genderSameAsBirthAssigned: this.getGenderSameAsBirth(state),
      religion: this.getReligion(state),
      sexuality: this.getSexuality(state),
      riskLevel: this.clinicalStore.riskLevel,
      riskLevelReason: this.clinicalStore.riskLevelReason,
      triggerWords: this.clinicalStore.triggerWords,
      language: this.getLanguage(state),
      interpreter: state.requiresInterpreter,
      consentDataShare: true,
      consentDataStore: true,
      output: this.referralStore.referralType,
      clinicalNotes: this.referralStore.clinicalNotes,
      clinicalFlags: this.clinicalStore.flags,
      problemDescriptorPrimary: this.clinicalStore.primaryProblems,
      problemDescriptorSecondary: this.clinicalStore.secondaryProblems,
      whereHeardAboutUs: state.whereDidYouHearAboutService,
      consentResearch: state.consentResearch,
      treatmentExpectation: state.therapyGoal
    }
  }

  getGenders(_state: State): string[] {
    return ["Boy / Man", "Girl / Woman", "Non-binary"]
  }

  getGenderSameAsBirthValues(_state: State): string[] {
    return ["Yes", "No", "Prefer not to answer"]
  }

  getReligions(_state: State): string[] {
    // TODO: "Other" selection may require free text - to be confirmed if PCMIS accepts it
    return [
      "Baha'i",
      "Buddhist",
      "Christian",
      "Hindu",
      "Jain",
      "Jewish",
      "Muslim",
      "Pagan",
      "Sikh",
      "Zoroastrian",
      "Other",
      "None",
      "Do not know / not sure"
    ]
  }

  getLanguages(_state: State): string[] {
    return [
      "English",
      "Akan (Ashanti)",
      "Albanian",
      "Amharic",
      "Arabic",
      "Bengali & Sylheti",
      "Brawa & Somali",
      "British Sign Language",
      "Cantonese",
      "Cantonese & Vietnamese",
      "Creole",
      "Dutch",
      "Ethiopian",
      "Farsi (Persian)",
      "Finnish",
      "Flemish",
      "French",
      "French creole",
      "Gaelic",
      "German",
      "Greek",
      "Gujerati",
      "Hakka",
      "Hausa",
      "Hebrew",
      "Hindi",
      "Igbo (Ibo)",
      "Italian",
      "Japanese",
      "Korean",
      "Kurdish",
      "Lingala",
      "Luganda",
      "Makaton (sign language)",
      "Malayalam",
      "Mandarin",
      "Norwegian",
      "Pashto (Pushtoo)",
      "Patois",
      "Polish",
      "Portuguese",
      "Punjabi",
      "Russian",
      "Serbian/Croatian",
      "Sinhala",
      "Somali",
      "Spanish",
      "Swahili",
      "Swedish",
      "Tagalog (Filipino)",
      "Tamil",
      "Thai",
      "Tigrinya",
      "Turkish",
      "Urdu",
      "Vietnamese",
      "Welsh",
      "Yoruba",
      "Other"
    ]
  }

  getEthnicities(_state: State): string[] {
    return [
      "White - British",
      "White - Irish",
      "White - Any Other",
      "Mixed - White and Black Caribbean",
      "Mixed - White and Black African",
      "Mixed - White and Asian",
      "Mixed - Any Other",
      "Indian",
      "Pakistani",
      "Bangladeshi",
      "Any other Asian",
      "Caribbean",
      "African",
      "Any other Black",
      "Chinese",
      "Other",
      "Not known"
    ]
  }

  getSexualities(_state: State): string[] {
    return ["Heterosexual", "Gay", "Bisexual", "Prefer not to say"]
  }

  getDisabilities(_state: State): string[] {
    return [
      "Behavioural & emotional",
      "Hearing",
      "Learning Disability",
      "Manual Dexterity",
      "Mobility",
      "Perception of Danger",
      "Personal Care",
      "Progessive Condition",
      "Sight",
      "Speech",
      "Other",
      "Prefer not to say"
    ]
  }

  getGenderSameAsBirth(state: State): GenderBirthAssigned {
    // ["Yes", "No", "Prefer not to answer"]
    // This is a bit dodgy... temp solution
    if (state.sameGenderAsBirth === "Prefer not to answer") {
      return "NOT_ANSWERED"
    }
    return state.sameGenderAsBirth === "Yes" ? "YES" : "NO"
  }

  getEthnicity(state: State): ETHNICITY_PCMIS_GMHUB {
    const map: Record<string, ETHNICITY_PCMIS_GMHUB> = {
      "White - British": "WHITE_BRITISH",
      "White - Irish": "WHITE_IRISH",
      "White - Any Other": "WHITE_OTHER",
      "Mixed - White and Black Caribbean": "MIXED_WHITE_BLACK",
      "Mixed - White and Black African": "MIXED_WHITE_BLACK",
      "Mixed - White and Asian": "MIXED_WHITE_ASIAN",
      "Mixed - Any Other": "MIXED_OTHER",
      Indian: "ASIAN_INDIAN",
      Pakistani: "ASIAN_PAKISTANI",
      Bangladeshi: "ASIAN_BANGLADESHI",
      "Any other Asian": "ASIAN_OTHER",
      Caribbean: "BLACK_CARIBBEAN",
      African: "BLACK_AFRICAN",
      "Any other Black": "BLACK_OTHER",
      Chinese: "CHINESE",
      Other: "OTHER",
      "Not known": "UNKNOWN"
    }
    return map[state.ethnicity!] ?? "NOT_ANSWERED"
  }

  getDisability(state: State): DISABILITY_PCMIS_GMHUB | undefined {
    if (state.disabilityStatus === false) return
    if (!state.disabilityStatus || !state.disability) {
      this.logBreadcrumb("getDisability without answer", state)
      this.logMessage("getDisability without answer")
    }
    const map: Record<string, DISABILITY_PCMIS_GMHUB> = {
      "Behavioural & emotional": "BEHAVIOUR",
      Hearing: "HEARING",
      "Learning Disability": "LEARNING",
      "Manual Dexterity": "MANUAL",
      Mobility: "MOTOR",
      "Perception of Danger": "PERCEPTION",
      "Personal Care": "SELF_CARE",
      "Progessive Condition": "PROGRESSIVE",
      Sight: "SIGHT",
      Speech: "SPEECH",
      Other: "NOT_LISTED",
      "Prefer not to say": "NOT_ANSWERED"
    }
    return map[state.disability!] ?? "NOT_ANSWERED"
  }

  getGender(state: State): GENDER_PCMIS {
    if (state.spineGender) return state.spineGender as any
    const map: Record<string, GENDER_PCMIS> = {
      "Boy / Man": "MALE",
      "Girl / Woman": "FEMALE",
      // TODO: is the following correct? No other options that match
      "Non-binary": "NOT_LISTED"
    }
    return map[state.gender!] ?? "NOT_LISTED"
  }

  getReligion(state: State): RELIGION_PCMIS {
    const map: Record<string, RELIGION_PCMIS> = {
      "Baha'i": "BAHAI",
      Buddhist: "BUDDHIST",
      Christian: "CHRISTIAN",
      Hindu: "HINDU",
      Jain: "JAIN",
      Jewish: "JEWISH",
      Muslim: "MUSLIM",
      Pagan: "PAGAN",
      Sikh: "SIKH",
      Zoroastrian: "ZOROASTRIAN",
      Other: "OTHER",
      None: "NONE",
      "Do not know / not sure": "UNKNOWN"
    }
    return map[state.religion!] ?? "NOT_ANSWERED"
  }

  getSexuality(state: State): SEXUALITY_PCMIS_GMHUB | undefined {
    const map: Record<string, SEXUALITY_PCMIS_GMHUB> = {
      Heterosexual: "HETEROSEXUAL",
      Gay: "HOMOSEXUAL",
      Bisexual: "BISEXUAL",
      "Prefer not to say": "NOT_ANSWERED"
    }
    return map[state.sexuality!] || "NOT_LISTED"
  }

  getLanguage(state: State): LANGUAGE_PCMIS_GMHUB | undefined {
    const map: Record<string, LANGUAGE_PCMIS_GMHUB> = {
      English: "ENGLISH",
      "Akan (Ashanti)": "AKAN",
      Albanian: "ALBANIAN",
      Amharic: "AMHARIC",
      Arabic: "ARABIC",
      "Bengali & Sylheti": "BENGALI",
      "Brawa & Somali": "BRAWA",
      "British Sign Language": "BRITISH_SIGN_LANGUAGE",
      Cantonese: "CANTONESE",
      "Cantonese & Vietnamese": "CANTONESE",
      Creole: "CREOLE",
      Dutch: "DUTCH",
      Ethiopian: "ETHIOPIAN",
      "Farsi (Persian)": "PERSIAN_FARSI",
      Finnish: "FINNISH",
      Flemish: "FLEMISH",
      French: "FRENCH",
      "French creole": "FRENCH_CREOLE",
      Gaelic: "GAELIC",
      German: "GERMAN",
      Greek: "GREEK",
      Gujerati: "GUJARATI",
      Hakka: "HAKKA",
      Hausa: "HAUSA",
      Hebrew: "HEBREW",
      Hindi: "HINDI",
      "Igbo (Ibo)": "IGBO",
      Italian: "ITALIAN",
      Japanese: "JAPANESE",
      Korean: "KOREAN",
      Kurdish: "KURDISH",
      Lingala: "LINGALA",
      Luganda: "LUGANDA",
      "Makaton (sign language)": "MAKATON_SIGN_LANGUAGE",
      Malayalam: "MALAYALAM",
      Mandarin: "MANDARIN",
      Norwegian: "NORWEGIAN",
      "Pashto (Pushtoo)": "PASHTO",
      Patois: "PATOIS",
      Polish: "POLISH",
      Portuguese: "PORTUGUESE",
      Punjabi: "PUNJABI",
      Russian: "RUSSIAN",
      "Serbian/Croatian": "SERBIAN",
      Sinhala: "SINHALA",
      Somali: "SOMALI",
      Spanish: "SPANISH",
      Swahili: "SWAHILI",
      Swedish: "SWEDISH",
      "Tagalog (Filipino)": "TAGALOG_FILIPINO",
      Tamil: "TAMIL",
      Thai: "THAI",
      Tigrinya: "TIGRINYA",
      Turkish: "TURKISH",
      Urdu: "URDU",
      Vietnamese: "VIETNAMESE",
      Welsh: "WELSH",
      Yoruba: "YORUBA",
      Other: "NOT_LISTED"
    }
    return map[state.primaryLanguage!] ?? "NOT_LISTED"
  }
}

export default class SelfReferralGMHubIndividualDialogue extends AdHocDialogue<
  State,
  SelfReferralGMHubIndividualScript
> {
  static id = DialogueIDs.SelfReferralGMHubIndividual
  readonly name: string = "SelfReferralGMHubIndividualDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      SelfReferralGMHubIndividualDialogue.id,
      new SelfReferralGMHubIndividualScript(),
      state,
      snapshot
    )
  }
}
