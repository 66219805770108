import { getCountryCodeForRegionCode, parsePhoneNumber } from "awesome-phonenumber"

//"GB" is the isoAlpha2Code for the United Kingdom
const serviceCountry = process.env.REACT_APP_BOT_COUNTRY ?? "GB"

export default function isValidPhoneNumber(phoneNumber: string): boolean {
  const pn = phoneNumber.replace(/^00/, "+")
  const phoneWithCountryCode = !pn.startsWith("+")
    ? `+${getCountryCodeForRegionCode(serviceCountry)}${pn}`
    : pn
  return isValidMobilePhone(phoneWithCountryCode) || isValidLandlineNumber(phoneWithCountryCode)
}

export function isValidMobilePhone(phoneNumber: string): boolean {
  const pn = phoneNumber.replace(/^00/, "+")
  const phoneWithCountryCode = !pn.startsWith("+")
    ? `+${getCountryCodeForRegionCode(serviceCountry)}${pn}`
    : pn
  const parsed = parsePhoneNumber(phoneWithCountryCode)
  return parsed.typeIsMobile ?? false
}

export function isValidLandlineNumber(phoneNumber: string): boolean {
  const pn = phoneNumber.replace(/^00/, "+")
  const phoneWithCountryCode = !pn.startsWith("+")
    ? `+${getCountryCodeForRegionCode(serviceCountry)}${pn}`
    : pn
  const parsed = parsePhoneNumber(phoneWithCountryCode)
  return parsed.typeIsFixedLine ?? false
}
