import {
  ChatFlowsEnum,
  IChatBotFlow,
  IDefaultChatFlowSettings,
  IDialogueStep,
  ReferralPayload,
  StepType
} from "@limbic/types"
import { PayloadBuilder } from "./PayloadBuilder"
import ChatFlowPayloadBuilder from "./ChatFlowPayloadBuilder"

export class ReferralPayloadBuilder extends PayloadBuilder<Partial<ReferralPayload>> {
  /**
   * Accepts the overall chatbot flow, and for each dialogue, it will
   * go through each step and use the corresponding builder to create
   * the payload for that step
   * @param flow {IChatBotFlow} The chatbot flow containing all the dialogues
   */
  withChatBotFlow(flow?: IChatBotFlow): ReferralPayloadBuilder {
    Object.values(flow ?? {})
      .flat()
      .forEach((step: IDialogueStep) => {
        if (step.chatFlow) {
          this.withChatFlow(step.chatFlow, step.settings)
        }

        if (step.userResponseKey) {
          Object.entries(step.userResponseKey).forEach(([ctxKey, key]) => {
            if (typeof key === "string") {
              // TODO: Shouldn't have to replace the spaces and question marks here.
              //       Fix the diagram blocks so that the user explicitly mentions
              //       what the key should be and then use that, or not save the
              //       answer at all if they didn't add a key, because we're now
              //       inferring the key from the question name and that's error
              //       prone and calls for hacks like this replace

              // The reason why we need to remove the "__inlineFreeTextValue__" bit
              // from the key, is because when an inline picker also has a free text
              // input, we need to create two separate transforms, one for the sourceKey
              // in case they want to map the free text value to a specific value in the
              // target key, but also a separate transform for the free text value itself
              // in case they want to map it to a different target key altogether, but because
              // the transformMap is an object where the keys are the sourceKeys, we can't
              // have two transforms with the same sourceKey, so we need to name one a bit
              // differently, and as such we're using the "__inlineFreeTextValue__" prefix for
              // the free text value transform, and we need to remove it here so that we can
              // find the correct value in the state
              const sanitisedKey = key
                .replace(/^__inlineFreeTextValue__/g, "")
                .replace(/ /g, "")
                .replace(/\?/g, "")
              const sourceValue = this.ctx[ctxKey]?.[sanitisedKey]
              this.withData(this.getTransformedKeyValue(sanitisedKey, sourceValue))
            }

            if (Array.isArray(key)) {
              key.forEach(k => {
                const sanitisedKey = k
                  .replace(/^__inlineFreeTextValue__/g, "")
                  .replace(/ /g, "")
                  .replace(/\?/g, "")
                const sourceValue = this.ctx[ctxKey]?.[sanitisedKey]
                this.withData(this.getTransformedKeyValue(k, sourceValue))
              })
            }
          })
        }

        if (step.type === StepType.Action) {
          if (step.actionStateKey) {
            const sourceValue = this.ctx.state?.[step.actionStateKey]
            this.withData(this.getTransformedKeyValue(step.actionStateKey, sourceValue))
          }
        }
      })
    return this
  }

  /**
   * Accepts a chat flow and uses the corresponding builder to create
   * the payload for that chat flow
   * @param chatFlow {ChatFlowsEnum} The chat flow to use
   * @param settings {IDefaultChatFlowSettings} The chat flow's settings
   */
  withChatFlow(
    chatFlow: ChatFlowsEnum,
    settings?: IDefaultChatFlowSettings
  ): ReferralPayloadBuilder {
    const Builder = ChatFlowPayloadBuilder.builders[chatFlow]
    if (Builder) this.withBuilder(new Builder(settings ?? {}))
    return this
  }

  /**
   * Accepts a list of keys and uses the corresponding transforms
   * to create the payload for those keys
   * @param keys {string[]} The keys to use
   */
  withSpecificKeysList(keys?: string[]): ReferralPayloadBuilder {
    if (keys) {
      keys.forEach(key => {
        const transform = this.transformMap[key]
        if (!transform) return

        const ctxKey = transform.contextKey ?? "state"
        const sanitisedKey = transform.sourceKey
          .replace(/^__inlineFreeTextValue__/g, "")
          .replace(/ /g, "")
          .replace(/\?/g, "")
        const sourceValue = this.ctx[ctxKey]?.[sanitisedKey]
        this.withData(this.getTransformedKeyValue(key, sourceValue))
      })
    }
    return this
  }
}
