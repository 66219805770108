import {
  dialogueMap_HEALIX,
  dialogueMap_VITALITY,
  dialoguesMapINSIGHT_FOLLOWUP,
  dialoguesMapINSIGHT_WAITLIST,
  dialoguesMap_BRADFORD,
  dialoguesMap_Demo,
  dialoguesMap_DemoDynamic,
  dialoguesMap_DemoPrivateProviders,
  dialoguesMap_ESSEX,
  dialoguesMap_GM_HUB,
  dialoguesMap_INSIGHT,
  dialoguesMap_LINCOLNSHIRE,
  dialoguesMap_LWC,
  dialoguesMap_MHM,
  dialoguesMap_SABP,
  dialoguesMap_VHG,
  dialoguesMap_CHESHIRE_WIRRAL,
  dialoguesMap_OXFORD,
  dialoguesMap_BUCKINGHSAMHIRE,
  dialoguesMap_DORKING,
  dialoguesMap_BEXLEY,
  dialoguesMap_SABP_V2
} from "../../config/discussion/dialoguesMap"
import {
  stepsOrder,
  stepsOrder_BRADFORD,
  stepsOrder_CHESHIRE_WIRRAL,
  stepsOrder_DORKING,
  stepsOrder_DEMO_PRIVATE_PROVIDERS,
  stepsOrder_ESSEX,
  stepsOrder_GM_HUB,
  stepsOrder_HEALIX,
  stepsOrder_INSIGHT,
  stepsOrder_Insight_Waitlist,
  stepsOrder_LINCS,
  stepsOrder_LWC,
  stepsOrder_MHM,
  stepsOrder_OXFORD_BUCKINGHAMSHIRE,
  stepsOrder_VITALITY,
  stepsOrder_BEXLEY,
  stepsOrder_SABP
} from "../../config/discussion/stepsOrder"
import { DialogueIDs } from "../../conversation/DialogueIDs"
import { DiscussionSteps } from "@limbic/types"
import IAppLaunchConfig from "../../models/IAppLaunchConfig"

export const defaultDiscussionSteps: Record<IAppLaunchConfig["API_KEY"], DiscussionSteps[]> = {
  DASHBOARD_DEFAULT: stepsOrder,
  DEMO: stepsOrder,
  DEMO_PRIVATE_PROVIDERS: stepsOrder_DEMO_PRIVATE_PROVIDERS,
  VHG_NOTTS: stepsOrder,
  VHG_NOTTS_FULLSCREEN: stepsOrder,
  ESSEX: stepsOrder_ESSEX,
  ESSEX_FULLSCREEN: stepsOrder_ESSEX,
  ESSEX_DEMO: stepsOrder,
  INSIGHT_TALKING_THERAPIES: stepsOrder_INSIGHT,
  INSIGHT_TALKING_THERAPIES_FULLSCREEN: stepsOrder_INSIGHT,
  INSIGHT_WAITLIST: stepsOrder_Insight_Waitlist,
  INSIGHT_FOLLOWUP: stepsOrder_Insight_Waitlist,
  INSIGHT_TALKING_THERAPIES_DEMO: stepsOrder,
  LINCOLNSHIRE: stepsOrder_LINCS,
  LINCOLNSHIRE_FULLSCREEN: stepsOrder_LINCS,
  SABP: stepsOrder_SABP,
  SABP_FULLSCREEN: stepsOrder_SABP,
  SABP_V2: stepsOrder_SABP,
  SABP_V2_FULLSCREEN: stepsOrder_SABP,
  TESTING_SERVICE_PLAYWRIGHT: stepsOrder,
  GREATER_MANCHESTER_HUB: stepsOrder_GM_HUB,
  GREATER_MANCHESTER_HUB_FULLSCREEN: stepsOrder_GM_HUB,
  HEALIX: stepsOrder_HEALIX,
  VITALITY: stepsOrder_VITALITY,
  MENTAL_HEALTH_MATTERS: stepsOrder_MHM,
  MENTAL_HEALTH_MATTERS_FULLSCREEN: stepsOrder_MHM,
  BRADFORD: stepsOrder_BRADFORD,
  LIVING_WELL_CONSORTIUM: stepsOrder_LWC,
  LIVING_WELL_CONSORTIUM_FULLSCREEN: stepsOrder_LWC,
  CHESHIRE_AND_WIRRAL: stepsOrder_CHESHIRE_WIRRAL,
  CHESHIRE_AND_WIRRAL_FULLSCREEN: stepsOrder_CHESHIRE_WIRRAL,
  OXFORD: stepsOrder_OXFORD_BUCKINGHAMSHIRE,
  OXFORD_FULLSCREEN: stepsOrder_OXFORD_BUCKINGHAMSHIRE,
  BUCKINGHAMSHIRE: stepsOrder_OXFORD_BUCKINGHAMSHIRE,
  BUCKINGHAMSHIRE_FULLSCREEN: stepsOrder_OXFORD_BUCKINGHAMSHIRE,
  DORKING: stepsOrder_DORKING,
  DORKING_FULLSCREEN: stepsOrder_DORKING,
  BEXLEY: stepsOrder_BEXLEY
}

export const defaultDialoguesMap: Record<
  IAppLaunchConfig["API_KEY"],
  Partial<Record<DiscussionSteps, DialogueIDs>>
> = {
  DASHBOARD_DEFAULT: dialoguesMap_Demo,
  DEMO: dialoguesMap_Demo,
  DEMO_PRIVATE_PROVIDERS: dialoguesMap_DemoPrivateProviders,
  VHG_NOTTS: dialoguesMap_VHG,
  VHG_NOTTS_FULLSCREEN: dialoguesMap_VHG,
  ESSEX: dialoguesMap_ESSEX,
  ESSEX_FULLSCREEN: dialoguesMap_ESSEX,
  ESSEX_DEMO: dialoguesMap_ESSEX,
  INSIGHT_TALKING_THERAPIES: dialoguesMap_INSIGHT,
  INSIGHT_TALKING_THERAPIES_FULLSCREEN: dialoguesMap_INSIGHT,
  INSIGHT_WAITLIST: dialoguesMapINSIGHT_WAITLIST,
  INSIGHT_FOLLOWUP: dialoguesMapINSIGHT_FOLLOWUP,
  INSIGHT_TALKING_THERAPIES_DEMO: dialoguesMap_INSIGHT,
  LINCOLNSHIRE: dialoguesMap_LINCOLNSHIRE,
  LINCOLNSHIRE_FULLSCREEN: dialoguesMap_LINCOLNSHIRE,
  TESTING_SERVICE_PLAYWRIGHT: dialoguesMap_DemoDynamic,
  SABP: dialoguesMap_SABP,
  SABP_FULLSCREEN: dialoguesMap_SABP,
  SABP_V2: dialoguesMap_SABP_V2,
  SABP_V2_FULLSCREEN: dialoguesMap_SABP_V2,
  GREATER_MANCHESTER_HUB: dialoguesMap_GM_HUB,
  GREATER_MANCHESTER_HUB_FULLSCREEN: dialoguesMap_GM_HUB,
  HEALIX: dialogueMap_HEALIX,
  VITALITY: dialogueMap_VITALITY,
  MENTAL_HEALTH_MATTERS: dialoguesMap_MHM,
  MENTAL_HEALTH_MATTERS_FULLSCREEN: dialoguesMap_MHM,
  BRADFORD: dialoguesMap_BRADFORD,
  LIVING_WELL_CONSORTIUM: dialoguesMap_LWC,
  LIVING_WELL_CONSORTIUM_FULLSCREEN: dialoguesMap_LWC,
  CHESHIRE_AND_WIRRAL: dialoguesMap_CHESHIRE_WIRRAL,
  CHESHIRE_AND_WIRRAL_FULLSCREEN: dialoguesMap_CHESHIRE_WIRRAL,
  OXFORD: dialoguesMap_OXFORD,
  OXFORD_FULLSCREEN: dialoguesMap_OXFORD,
  BUCKINGHAMSHIRE: dialoguesMap_BUCKINGHSAMHIRE,
  BUCKINGHAMSHIRE_FULLSCREEN: dialoguesMap_BUCKINGHSAMHIRE,
  DORKING: dialoguesMap_DORKING,
  DORKING_FULLSCREEN: dialoguesMap_DORKING,
  BEXLEY: dialoguesMap_BEXLEY
}
