import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { DialogueIDs } from "../../DialogueIDs"
import {
  EligibilityCheckWithPDSScript,
  EligibilityCheckWithPDSScriptState
} from "./EligibilityCheckWithPDSScript"
import ISelectable from "../../../models/ISelectable"
import { TrackingEvents } from "../../../models/Constants"
import { IneligibilityReason } from "@limbic/types"

type ArmedForces = "YES_CURRENT" | "NO" | "YES_EX" | "DEPENDANT" | "UNKNOWN"

interface State extends EligibilityCheckWithPDSScriptState {
  currentSupport?: string
  currentSupportDetails?: string
  armedForces?: ArmedForces
}

export type EligibilityCheckDorkingScriptState = State

export class EligibilityCheckDorkingScript extends EligibilityCheckWithPDSScript {
  readonly name: string = "EligibilityCheckDorkingScript"

  /** Script Steps */

  @step.logState
  startEligibilityCheck(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askArmedForces }
  }

  @step
  askArmedForces(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you currently serving or have you ever served in the British Armed Forces?",
      prompt: {
        id: this.getPromptId("askExArmedForces"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          {
            body: "Currently serving",
            value: "YES_CURRENT"
          },
          { body: "No", value: "NO" },
          { body: "Ex-services member", value: "YES_EX" },
          {
            body: "Dependent of current or ex-services member",
            value: "DEPENDENT"
          },
          { body: "Not known/not sure", value: "UNKNOWN" }
        ],
        dataPointsName: "askArmedForces"
      },
      nextStep: this.handleArmedForces
    }
  }

  @step.logState
  async handleArmedForces(d: IStepData<State, ArmedForces>): Promise<IStepResult> {
    d.state.armedForces = d.response
    if (d.response === "YES_CURRENT") {
      this.setPeople({ isArmedForces: d.response })
      this.trackUserAsIneligible(d.state, IneligibilityReason.EX_ARMED_FORCES)
      this.setEligibility(d.state, false)
      return { nextStep: this.goToSignpostArmedForces }
    }
    return { nextStep: this.askPostCodeOfUser }
  }

  @step.logState
  askSelectIAPTServiceManually(d: IStepData<State>): IStepResult {
    const eligibleIAPTs = this.getEligibleIAPTSByAgeThreshold(d.state)
    return {
      body: "And which service would you like to be referred into?",
      prompt: {
        id: this.getPromptId("askSelectIAPTServiceManually"),
        trackResponse: true,
        type: "inlinePicker",
        choices: (
          eligibleIAPTs.map(iapt => ({
            body: `${iapt.formattedName}`,
            value: iapt
          })) as ISelectable<any>[]
        ) //
          .concat({
            body: "Actually, I want to speak to a human",
            value: "speakToHuman",
            backgroundColor: "#EC9CC8"
          })
      },
      nextStep: this.handleSelectIAPTServiceManually
    }
  }

  @step.logState
  goToSignpostArmedForces(d: IStepData<State>): IStepResult {
    const serviceName = this.rootStore.configStore.serviceName
    const name = this.getName(d.state)

    this.track(TrackingEvents.ARMED_FORCES_SIGNPOST)
    return {
      body: [
        `So ${name}, there's a specific type of mental health support for people currently serving in the armed forces`,
        `${serviceName} do not currently provide this so we are unable to refer you at this time`,
        "You should talk to any medical officer or their chain of command and they will help you"
      ],
      prompt: {
        id: this.getPromptId("goToSignpostCurrentSupport"),
        type: "inlinePicker",
        choices: [{ body: "Understood" }, { body: "Okay" }],
        isUndoAble: true
      },
      nextStep: this.goToGoodbye
    }
  }
}

/* istanbul ignore next */
export default class EligibilityCheckDorkingDialogue extends Dialogue<State> {
  static id = DialogueIDs.EligibilityCheckDorking
  readonly name: string = "EligibilityCheckDorkingDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(EligibilityCheckDorkingDialogue.id, new EligibilityCheckDorkingScript(), state, snapshot)
  }
}
