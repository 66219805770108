import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { RiskLevelReason } from "../../../models/Constants"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

const shouldSendKeepingSafe = process.env.REACT_APP_SHOULD_SEND_KEEPING_SAFE === "enabled"

interface State extends RiskPathwayScriptState {
  riskEmailSent?: boolean
}

export type RiskPathwayBuckinghamshireScriptState = State

export class RiskPathwayBuckinghamshireScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayBuckinghamshireScript"

  /** Script Steps */
  async start(d: IStepData<State>): Promise<IStepResult<State>> {
    const canSendEmail = d.state.email && d.state.canSendEmail
    if (canSendEmail && shouldSendKeepingSafe) {
      await this.sendKeepingSafeEmail(d.state)
    }
    this.rootStore.applicationStore.setCurrentProgress(0.88)
    return { nextStep: this.step1 }
  }

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askCanYouKeepYourselfSafe }
  }

  @step.logState
  askCanYouKeepYourselfSafe(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you able to keep yourself, and any dependants in your care, safe at the moment?",
      prompt: {
        id: this.getPromptId("askCanYouKeepYourselfSafe"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askCanYouKeepYourselfSafe"
      },
      nextStep: this.handleCanYouKeepYourselfSafe
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.saveResponse<State>(
    "10",
    "Are you able to keep yourself, and any dependants in your care, safe at the moment?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  @step.handleResponse((d: IStepData<State, boolean>, script: RiskPathwayBuckinghamshireScript) => {
    d.state.canKeepSelfSafe = d.response
    script.referralStore.setCustomField<State>("canKeepSelfSafe", d.response)
  })
  handleCanYouKeepYourselfSafe(d: IStepData<State>): IStepResult {
    if (!d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.CANNOT_KEEP_SELF_SAFE)
      return { nextStep: this.saveRiskLevelAndReferralType }
    }
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  async informRiskToService(d: IStepData<State>): Promise<IStepResult> {
    if (!d.state.riskEmailSent) await this.sendRiskEmailToService(d.state)
    return { nextStep: this.sayCrisis }
  }

  @step.logState
  async sayCrisis(d: IStepData<State>): Promise<IStepResult> {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName

    return {
      body: [
        `Sorry to hear that ${name}`,
        "It is normal for people to have thoughts of this nature at times",
        `However, ${organisationName} does not provide urgent care`,
        "If you need urgent support, please dial NHS 111 and select Option 2",
        "In medical emergency and life threatening situations only, please dial 999 or attend your local A&E department",
        "You can also contact Bucks Safe Haven that offers a safe and supportive alternative to A&E for adults (aged 18+) who are experiencing a mental health crisis. For High Wycombe (open 7 nights a week) contact 01494 218098 and for Aylesbury (open Sunday, Monday, Tuesday and Wednesday, contact 01296 453017",
        "Other helplines available:",
        "You can contact Samaritans 24 hours a day, 365 days a year",
        "You can call 116 123 (free from any phone) or email jo@samaritans.org",
        "If you would prefer not to talk but want some mental health support, you could text SHOUT to 85258",
        "To recap:",
        `We'll continue with the referral, however ${organisationName} does not provide urgent care. If you require immediate help, please contact one of the numbers listed above`,
        "Let's carry on with the referral"
      ],
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }],
        dataPointsName: "sayCrisis"
      },
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onSaveRiskLevelAndReferralType(state: State): Promise<IStepResult> {
    return { nextStep: !state.canKeepSelfSafe ? this.informRiskToService : this.end }
  }

  getKeepingSafeAttachmentUrls(state: State): string[] {
    return [
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/buckinghamshire/keeping+safe+leaflet.pdf"
    ]
  }

  createKeepingSafeLeafletEmail(title: string, logo: string): string {
    return `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html xmlns='http://www.w3.org/1999/xhtml' lang=''>

<head>
  <meta http-equiv='Content-Type' content='text/html; charset=utf-8' />
  <meta name='viewport' content='width=device-width, initial-scale=1.0' />
  <title>${title}</title>
  <style>
    #outlook a {
      padding: 0;
    }

    body {
      width: 100% !important;
      -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
      margin: 0;
      padding: 0;
      font-family: sans-serif;
    }

    .ExternalClass {
      width: 100%;
    }

    .ExternalClass,
    .ExternalClass p,
    .ExternalClass span,
    .ExternalClass font,
    .ExternalClass td,
    .ExternalClass div {
      line-height: 100%;
    }

    #backgroundTable {
      margin: 0;
      padding: 0;
      width: 100% !important;
      line-height: 100% !important;
    }

    #contentTable {
      margin: 64px auto;
      max-width: 800px !important;
      line-height: 100% !important;
    }

    img {
      outline: none;
      text-decoration: none;
      -ms-interpolation-mode: bicubic;
    }

    a img {
      border: none;
    }

    .image_fix {
      display: block;
    }

    p {
      margin: 1em 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: black !important;
    }

    h1 a,
    h2 a,
    h3 a,
    h4 a,
    h5 a,
    h6 a {
      color: blue !important;
    }

    h1 a:active,
    h2 a:active,
    h3 a:active,
    h4 a:active,
    h5 a:active,
    h6 a:active {
      color: red !important;
    }

    h1 a:visited,
    h2 a:visited,
    h3 a:visited,
    h4 a:visited,
    h5 a:visited,
    h6 a:visited {
      color: purple !important;
    }

    table td {
      padding-left: 24px;
      padding-right: 24px;
      border-collapse: collapse;
      line-height: 24px;
    }

    table {
      border-collapse: collapse;
      mso-table-lspace: 0;
      mso-table-rspace: 0;
    }

    a {
      color: orange;
    }

    h1,
    h2,
    h3,
    a {
      color: #375491 !important;
    }

    h1 {
      font-size: 1.8rem;
      text-align: center;
      font-weight: normal;
    }

    .red {
      color: red;
    }

    .blue {
      color: #375491 !important;
      font-weight: bold;
    }

    .big {
      font-size: 21px;
      font-weight: bold;
    }
  </style>
  <!--[if IEMobile 7]>        <style type="text/css">          /* Targeting Windows Mobile */        </style>      <![endif]-->
  <!--[if gte mso 9]>        <style>          /* Target Outlook 2007 and 2010 */        </style>      <![endif]-->
</head>

<body>
  <table cellpadding='0' cellspacing='0' border='0' id='backgroundTable'>
    <tr>
      <td>
        <table cellpadding='0' cellspacing='0' border='0' id='contentTable'>
          <tr>
            <td> <img class='image_fix'
                style='max-height: 200px; display: block; margin-left: auto; margin-right: auto;'
                src='${logo}'
                alt='${title}'
                title='${title}' width='x' height='x'
                onerror="this.style.display='none'" /> <br /><br /> </td>
          </tr>
          <tr>
            <td>
              <h1 style='color: #dc186d !important;'>${title}</h1>
            </td>
          </tr>
          <tr>
            <td>
              <p>You have made a self-referral today to NHS Buckinghamshire Talking Therapies. In one of your answers you said
                you were experiencing some thoughts of self-harm or taking your own life.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>As you are waiting to access our service, if you feel you may act on <i>suicidal thoughts</i>, cannot
                keep yourself or are a risk to others around you, please call 999 or look for your local crisis team
                contact details by following the link:</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <a href="https://www.oxfordhealth.nhs.uk/bucks-talking-therapies/contact/emergency/">
                  https://www.oxfordhealth.nhs.uk/bucks-talking-therapies/contact/emergency/
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p> <strong>The NHS Buckinghamshire Talking Therapies Team</strong> </p>
            </td>
          <tr>
        </table>
      </td>
    </tr>
  </table>
</body>

</html>
    `
  }
}

/* istanbul ignore next */
export default class RiskPathwayBuckinghamshireDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayBuckinghamshire
  readonly name: string = "RiskPathwayBuckinghamshireDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      RiskPathwayBuckinghamshireDialogue.id,
      new RiskPathwayBuckinghamshireScript(),
      state,
      snapshot
    )
  }
}
