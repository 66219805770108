import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../DialogueIDs"
import { SuitableServicesSignpostIAPTScript } from "./SuitableServicesSignpostIAPTDialogue"
import type { SuitableServicesSignpostIAPTScriptState } from "./SuitableServicesSignpostIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = SuitableServicesSignpostIAPTScriptState
export type SuitableServicesSignpostEssexScriptState = State

export class SuitableServicesSignpostEssexScript extends SuitableServicesSignpostIAPTScript {
  readonly name: string = "SuitableServicesSignpostEssexScript"

  /** Script Steps */

  @step.logState
  sayWeDontSupportYourArea(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: this.t(
        [
          "So {name}, with the NHS, you can get mental health support from services in the same area as your registered GP",
          "I've just done a search, and it doesn't look like {organisationName} have any services in the same area as your GP",
          "If you are a University student in the Essex area, you may still be eligible for support"
        ],
        { name, organisationName }
      ),
      prompt: {
        id: this.getPromptId("sayWeDontSupportYourArea"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("No, I'm not a University student"), value: false },
          { body: this.t("Yes, I'm a University student"), value: true }
        ],
        isUndoAble: true
      },
      nextStep: this.handleWeDontSupportYourArea
    }
  }

  @step
  handleWeDontSupportYourArea(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    if (d.response) {
      return { nextStep: this.sayUserNeedsToCallIn }
    }
    return {
      body: this.t("Sorry about that {name}", { name }),
      nextStep: this.checkIAPTServices
    }
  }

  @step.logState
  sayUserNeedsToCallIn(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationPhoneNumbers = this.rootStore.configStore.organisationPhoneNumbers ?? ""

    return {
      body: this.t(
        "Great {name}, please give any one of our services a call on the following phone numbers to check your eligibility:\n\n{organisationPhoneNumbers}",
        { name, organisationPhoneNumbers }
      ),
      prompt: {
        id: this.getPromptId("sayUserNeedsToCallIn"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Okay"), value: "okay" },
          { body: this.t("Thanks"), value: "thanks" }
        ]
      },
      nextStep: this.handleThanksOK
    }
  }
}

/* istanbul ignore next */
export default class SuitableServicesSignpostEssexDialogue extends Dialogue<State> {
  static id = DialogueIDs.SuitableServicesSignpostEssex
  readonly name: string = "SuitableServicesSignpostEssexDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      SuitableServicesSignpostEssexDialogue.id,
      new SuitableServicesSignpostEssexScript(),
      state,
      snapshot
    )
  }
}
