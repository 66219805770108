import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { GoodbyeScriptState } from "./GoodbyeScript"
import GoodbyeScript from "./GoodbyeScript"
import { IStepData, IStepResult, StepResultBodyType } from "../../../backend/chatbot/models/IStep"
import getQRCodeURL from "../../../utils/getQRCodeURL"
import { step } from "../../../backend/chatbot/decorators/step"
import { TrackingEvents } from "../../../models/Constants"

interface State extends GoodbyeScriptState {
  canKeepSelfSafe?: boolean
  requiresUrgentSupport?: boolean
}

export type GoodbyeEssexScriptState = State

interface WindowWithLimbicNameSpace extends Window {
  DEEP_LINK: string
}

declare let window: WindowWithLimbicNameSpace

window.DEEP_LINK = String(process.env.REACT_APP_DEEP_LINK ?? "enabled")

const deepLinkEnabled = window.DEEP_LINK !== "disabled"

export class GoodbyeEssexScript extends GoodbyeScript {
  readonly name: string = "GoodbyeEssexScript"

  /** Script Steps */
  @step
  async sayGoodBye(d: IStepData<State>): Promise<IStepResult> {
    void this.referralStore.updateReferral({
      isHelpful: d.state.isHelpful,
      improvementSuggestion: d.state.improvementSuggestion
    })
    this.referralStore.stopPinging()

    return {
      body: this.t("Well done for taking this important step towards better mental health"),
      nextStep: this.sayFinalWords
    }
  }

  /** Generic Handlers */
  async getIntroMessage(state: State): Promise<string | void> {
    if (state.needsToCall) {
      return
    }
    return this.t(
      state.requiresUrgentSupport
        ? "I'll be happy to help you with your journey after you've received this immediate support"
        : "I'm looking forward to going on this journey with you"
    )
  }

  async getReferredYouMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted) {
      const serviceName = this.rootStore.configStore.serviceName
      return this.t(
        state.isIdleSubmitted //
          ? "It looks like there hasn't been any activity for some time so I've referred you to {serviceName}"
          : "I've referred you to {serviceName}\n",
        { serviceName }
      )
    }
    const organisationName = this.rootStore.configStore.organisationName

    if (state.needsToCall) {
      const reason = this.referralStore.getCustomField("callInReason")
      let body = this.t(
        "You said you would like to talk with a human, therefore I did not refer you to {organisationName}",
        { organisationName }
      )
      if (reason === "dataConsent") {
        body = this.t(
          "Because you did not consent to sharing your data with your GP or wider NHS, I cannot refer you to {organisationName}",
          { organisationName }
        )
      }
      if (reason === "mobilePhone") {
        body = this.t(
          "You said you don't have a mobile phone number, therefore I cannot refer you to {organisationName}",
          { organisationName }
        )
      }
      return this.t(
        `${body}\n\n${this.t("You can reach {organisationName} on the phone numbers above", {
          organisationName
        })}`
      )
    }
    if (state.requiresUrgentSupport) {
      const iaptName = this.getIAPTName(state) || organisationName
      return this.t(
        "Because you are in need of urgent support, I cannot refer you into {iaptName}\n",
        { iaptName }
      )
    }
  }

  async getAdminWillCallMessage(state: State): Promise<string | void> {
    const organisationName = this.rootStore.configStore.organisationName
    if (state.needsToCall) {
      return
    }
    if (!state.requiresUrgentSupport) {
      return this.t(
        "{organisationName} will contact you either by phone or via a text/emailed link to our 'Choose and Book service' in the next working day\n",
        { organisationName }
      )
    }
  }

  @step.logState
  askWhereDidYouHearAboutUs(d: IStepData<State>): IStepResult {
    const requiresUrgentSupport =
      this.clinicalStore.requiresUrgentSupport || d.state.requiresUrgentSupport
    if (this.clinicalStore.isCrisis || requiresUrgentSupport) {
      this.track(TrackingEvents.SKIP_WHERE_DID_YOU_HEAR)
      return { nextStep: this.sayIHopeIHelped }
    }
    const serviceName = this.rootStore.configStore.serviceName
    return {
      body: this.t("Before we close, where did you hear about {serviceName}?", { serviceName }),
      prompt: {
        id: this.getPromptId("askWhereDidYouHearAboutUs"),
        type: "text",
        cancelIsEmptySubmit: true
      },
      nextStep: this.handleWhereDidYouHearAboutUsWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: GoodbyeScript) => {
    d.state.whereDidYouHearAboutService = d.response
    void script.referralStore.updateReferral({
      whereHeard: d.response
    })
  })
  @step.checkInputForCrisis({
    getNextStep: (s: GoodbyeScript) => s.askFeedback
  })
  handleWhereDidYouHearAboutUsWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askFeedback }
  }

  async getHighRiskContactMessage(_state: State): Promise<string | void> {
    const organisationName = this.rootStore.configStore.organisationName
    if (this.clinicalStore.isHighRisk) {
      return this.t(
        "Please remember, {organisationName} is not an emergency response service. If you need urgent help you are encouraged to contact 999 or NHS 111 and select Option 2\n\nYou can also contact the Samaritans on 116123 to talk through how you are feeling\n",
        { organisationName }
      )
    }
  }

  async getModerateRiskContactMessage(_state: State): Promise<string | void> {
    const organisationName = this.rootStore.configStore.organisationName
    if (this.clinicalStore.isModerateRisk) {
      return this.t(
        "Please remember, {organisationName} is not an emergency response service. If you need urgent help you are encouraged to contact 999 or NHS 111 and select Option 2\n\nYou can also contact the Samaritans on 116123 to talk through how you are feeling\n",
        { organisationName }
      )
    }
  }

  async getTreatmentMessage(state: State): Promise<string | void> {
    if (!state.isEligible) return undefined
    if (!state.requiresUrgentSupport) {
      return this.t(
        "To support us in being able to offer our treatments effectively and timely we will:\n\n" +
          "- Offer a maximum choice of two first appointments. If you decline both appointment offers we will be unable to offer you a further appointment and you will be discharged from the service\n\n" +
          "- In the event that you do not attend one of your booked appointments and you have not let us know beforehand you will be discharged from our service. Please be assured this will not stop you accessing the service at a future date\n"
      )
    }
  }

  async getResourcesMessage(state: State): Promise<string | void> {
    if (!state.isEligible) return undefined
    if (!state.requiresUrgentSupport) {
      return this.t(
        "If you feel that your referral is something you would like to discuss with the team please call us on 01268 739128/01206 334001 to progress this"
      )
    }
  }

  async getCustomRecapMessage(state: State): Promise<StepResultBodyType[] | undefined> {
    const hasDynamicLink = deepLinkEnabled && process.env.REACT_APP_LIMBIC_CARE_APP_STORE_URL
    const isCrisis = this.clinicalStore.isCrisis
    const name = this.getName(state)
    const lastMessage = state.requiresUrgentSupport
      ? "I look forward to talking with you again {name}"
      : "Well, it's been a pleasure getting to know you {name}"
    if (state.referralSubmitted && hasDynamicLink) {
      const qrCodeURL = getQRCodeURL(process.env.REACT_APP_LIMBIC_CARE_APP_STORE_URL!)

      return this.t(
        [
          "We can continue our conversations in my mobile app",
          "If you're already on your mobile device, just click [here]({url}) to download it",
          "If you're on a desktop computer, you can scan the QR code below",
          {
            type: "imageAttachment",
            image: qrCodeURL
          },
          isCrisis ? `Thank you for sharing this information with me {name}` : lastMessage
        ],
        { name, url: process.env.REACT_APP_LIMBIC_CARE_APP_STORE_URL }
      )
    }

    if (state.needsToCall) {
      return
    }
    return this.t(
      [isCrisis ? "Thank you for sharing this information with me {name}" : lastMessage],
      { name }
    )
  }
}

export default class GoodbyeEssexDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeEssex
  readonly name: string = "GoodbyeEssexDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeEssexDialogue.id, new GoodbyeEssexScript(), state, snapshot)
  }
}
