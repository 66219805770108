import createSurveyDialogue from "../createSurveyDialogue"
import ISurvey, { SurveyType } from "../../models/ISurvey"
import { DialogueIDs } from "../DialogueIDs"

const IAPTWorkAndSocialAdjustment: ISurvey = {
  startingQuestion: "So {name}, how do your problems impair your ability to do things...",
  questions: [
    {
      id: "1",
      questionName: "wasaAtWork",
      question:
        "1. At work\nIf you are retired or choose not to have a job for reasons unrelated to your problem, please click N/A (not applicable)",
      type: "slider",
      progress: 0.4,
      min: 0,
      max: 8,
      labels: {
        0: "Not at all",
        2: "Slightly",
        4: "Definitely",
        6: "Markedly",
        8: "Very severely, I cannot work"
      },
      notApplicable: true
    },
    {
      id: "2",
      questionName: "wasaAtHome",
      question:
        "2. At home\n(E.g. cleaning, tidying, shopping, cooking, looking after home/children, paying bills etc.)",
      type: "slider",
      progress: 0.5,
      min: 0,
      max: 8,
      labels: {
        0: "Not at all",
        2: "Slightly",
        4: "Definitely",
        6: "Markedly",
        8: "Very severely"
      }
    },
    {
      id: "3",
      questionName: "wasaAtLeisureActivities",
      question:
        "3. During social leisure activities\n(E.g. spending time with other people at parties, pubs, outings etc.)",
      type: "slider",
      progress: 0.6,
      min: 0,
      max: 8,
      labels: {
        0: "Not at all",
        2: "Slightly",
        4: "Definitely",
        6: "Markedly",
        8: "Very severely"
      }
    },
    {
      id: "4",
      questionName: "wasaAtPrivateLeisureActivities",
      question:
        "4. During private leisure activities\n(E.g. spending time alone reading, gardening, sewing, walking, and other hobbies etc.)",
      type: "slider",
      progress: 0.7,
      min: 0,
      max: 8,
      labels: {
        0: "Not at all",
        2: "Slightly",
        4: "Definitely",
        6: "Markedly",
        8: "Very severely"
      }
    },
    {
      id: "5",
      questionName: "wasaWithFamilyAndRelationships",
      question:
        "5. With family and relationships\n(E.g. forming and maintaining close relationships with others)",
      type: "slider",
      progress: 0.8,
      min: 0,
      max: 8,
      labels: {
        0: "Not at all",
        2: "Slightly",
        4: "Definitely",
        6: "Markedly",
        8: "Very severely"
      }
    }
  ]
}

const id = DialogueIDs.IAPTWorkAndSocialAdjustment
const type = SurveyType.IAPTWorkAndSocialAdjustment
export default createSurveyDialogue(id, IAPTWorkAndSocialAdjustment, type)
