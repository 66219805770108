/* eslint-disable @typescript-eslint/no-var-requires */
import { IAPTIDs, IIAPTService } from "../models/IIAPTService"
import { VHGEmailsProduction, VHGEmailsStaging } from "./emails"

const VHGEmails =
  process.env.REACT_APP_BACKEND_ENV === "production" ? VHGEmailsProduction : VHGEmailsStaging

const KMCCGIaptEmails: Record<string, string[]> =
  process.env.REACT_APP_BACKEND_ENV === "production"
    ? {
        dover: ["angiewiggins@nhs.net"],
        faversham: ["kmccg.fcstalkingtherapies@nhs.net"],
        insight: ["admin.national@InsightHealthcare.org"],
        withYou: ["enquiries@wearewithyou.org.uk"]
      }
    : {
        dover: ["andreas+dover@limbic.ai", "john+dover@limbic.ai", "chaellene+dover@limbic.ai"],
        faversham: [
          "andreas+faversham@limbic.ai",
          "john+faversham@limbic.ai",
          "chaellene+faversham@limbic.ai"
        ],
        insight: [
          "andreas+insightkent@limbic.ai",
          "john+insightkent@limbic.ai",
          "chaellene+insightkent@limbic.ai"
        ],
        withYou: [
          "andreas+withyou@limbic.ai",
          "john+withyou@limbic.ai",
          "chaellene+withyou@limbic.ai"
        ]
      }

const SABPIaptEmails: Record<string, string[]> =
  process.env.REACT_APP_BACKEND_ENV === "production"
    ? {
        riskEmails: ["duty.mindmatters@sabp.nhs.uk"],
        riskEmailsCC: [],
        riskEmailsBCC: []
      }
    : {
        riskEmails: ["andreas@limbic.ai", "john@limbic.ai"],
        riskEmailsCC: [],
        riskEmailsBCC: []
      }

const EssexIaptEmails: Record<"southEast" | "northEast", string[]> =
  process.env.REACT_APP_BACKEND_ENV === "production"
    ? {
        southEast: ["epunft.contactcentre.iapt@nhs.net"],
        northEast: ["epunft.neeiaptcontactcentre@nhs.net"]
      }
    : {
        southEast: ["andreas+se+essex@limbic.ai", "john+se+essex@limbic.ai"],
        northEast: ["andreas+ne+essex@limbic.ai", "john+ne+essex@limbic.ai"]
      }

const EssexIaptEmailsDemo: Record<"southEast" | "northEast", string[]> =
  process.env.REACT_APP_BACKEND_ENV === "production"
    ? {
        southEast: ["andreas+se+essex@limbic.ai", "john+se+essex@limbic.ai"],
        northEast: ["andreas+ne+essex@limbic.ai", "john+ne+essex@limbic.ai"]
      }
    : {
        southEast: ["andreas+se+essex@limbic.ai", "john+se+essex@limbic.ai"],
        northEast: ["andreas+ne+essex@limbic.ai", "john+ne+essex@limbic.ai"]
      }

const MHMEmails: Record<"sefton" | "warrington" | "northumberland", string[]> =
  process.env.REACT_APP_BACKEND_ENV === "production"
    ? {
        sefton: ["sefton.limbic.risk@mhm.org.uk"],
        warrington: ["tmw.referrals@nhs.net"],
        northumberland: ["tmn.info@nhs.net"]
      }
    : {
        sefton: ["zsuzsa+se+mhm@limbic.ai", "john+se+mhm@limbic.ai"],
        warrington: ["zsuzsa+wa+mhm@limbic.ai", "john+wa+mhm@limbic.ai"],
        northumberland: ["zsuzsa+no+mhm@limbic.ai", "john+no+mhm@limbic.ai"]
      }

const MPFTEmails: Record<string, string[]> =
  process.env.REACT_APP_BACKEND_ENV === "production"
    ? {
        emails: [],
        riskEmails: ["STWTT@mpft.nhs.uk"],
        riskEmailsCC: [],
        riskEmailsBCC: []
      }
    : {
        emails: [],
        riskEmails: ["zsuzsa+mpft@limbic.ai", "john+mpft@limbic.ai"],
        riskEmailsCC: [],
        riskEmailsBCC: []
      }

const bexleyEmails: Record<string, string[]> =
  process.env.REACT_APP_BACKEND_ENV === "production"
    ? {
        emails: [],
        riskEmails: ["supervision@mindinbexley.org.uk"],
        riskEmailsCC: [],
        riskEmailsBCC: []
      }
    : {
        emails: [],
        riskEmails: [
          "andreas+bexley@limbic.ai",
          "john+bexley@limbic.ai",
          "jessica+bexley@limbic.ai"
        ],
        riskEmailsCC: [],
        riskEmailsBCC: []
      }

const OxfordEmails: Record<string, string[]> =
  process.env.REACT_APP_BACKEND_ENV === "production"
    ? {
        emails: [],
        riskEmails: ["oxon-talking-therapies@oxfordhealth.nhs.uk"],
        riskEmailsCC: [],
        riskEmailsBCC: []
      }
    : {
        emails: [],
        riskEmails: ["andreas+oxfordshire@limbic.ai", "john+oxfordshire@limbic.ai"],
        riskEmailsCC: [],
        riskEmailsBCC: []
      }

const BucksEmails: Record<string, string[]> =
  process.env.REACT_APP_BACKEND_ENV === "production"
    ? {
        emails: [],
        riskEmails: ["Bucks-Talking-Therapies@oxfordhealth.nhs.uk"],
        riskEmailsCC: [],
        riskEmailsBCC: []
      }
    : {
        emails: [],
        riskEmails: ["andreas+bucks@limbic.ai", "john+bucks@limbic.ai"],
        riskEmailsCC: [],
        riskEmailsBCC: []
      }

export const iapts: Record<IAPTIDs, IIAPTService> = {
  [IAPTIDs.DEMO]: {
    id: IAPTIDs.DEMO,
    name: "Demo Service",
    formattedName: "Demo Service",
    postcode: "OL14 8RS",
    backendInstanceID: "DEMO_MAIN",
    phoneNumber: "+44 1234 567890",
    ageThreshold: 16,
    ageThresholdForTreatments: 18,
    emails: ["ross@limbic.ai", "john@limbic.ai"],
    riskEmails: ["ross@limbic.ai", "john@limbic.ai"],
    referralForm: require("./referralForms/demo.ts").default
  },
  [IAPTIDs.VHG_DERBY]: {
    id: IAPTIDs.VHG_DERBY,
    name: "Vita Health Group: Derby Talking Therapies",
    formattedName: "Derby Talking Therapies",
    postcode: "IP33 1UZ",
    phoneNumber: "+44 3330 153496",
    ageThreshold: 16,
    ageThresholdForTreatments: 18,
    ...VHGEmails["vhg-derby"]
  },
  [IAPTIDs.VHG_BB]: {
    id: IAPTIDs.VHG_BB,
    name: "Vita Health Group: Basildon & Brentwood Talking Therapies",
    formattedName: "Basildon & Brentwood Talking Therapies",
    postcode: "IP33 1UZ",
    phoneNumber: "+44 1268 977171",
    ageThreshold: 16,
    ageThresholdForTreatments: 16,
    ...VHGEmails["vhg-bb"]
  },
  [IAPTIDs.VHG_CALDERDALE]: {
    id: IAPTIDs.VHG_CALDERDALE,
    name: "Vita Health Group: Calderdale Talking Therapies",
    formattedName: "Calderdale Talking Therapies",
    postcode: "IP33 1UZ",
    phoneNumber: "+44 3330 153494",
    ageThreshold: 18,
    ageThresholdForTreatments: 18,
    ...VHGEmails["vhg"]
  },
  [IAPTIDs.VHG_BNSSG]: {
    id: IAPTIDs.VHG_BNSSG,
    name: "Vita Health Group: Bristol, North Somerset & South Gloucestershire Talking Therapies",
    formattedName: "Bristol, North Somerset & South Gloucestershire Talking Therapies",
    postcode: "BS1 1EH",
    phoneNumber: "+44 3332 001893",
    ageThreshold: 16,
    ageThresholdForTreatments: 16,
    ...VHGEmails["vhg-bnssg"]
  },
  [IAPTIDs.VHG_KM]: {
    id: IAPTIDs.VHG_KM,
    name: "Vita Health Group: Kent and Medway Talking Therapies",
    formattedName: "Kent and Medway Talking Therapies",
    postcode: "IP33 1UZ",
    phoneNumber: "+44 3330 153495",
    ageThreshold: 17.5,
    ageThresholdForTreatments: 17.5,
    ...VHGEmails["vhg-kent"]
  },
  [IAPTIDs.VHG_LLR]: {
    id: IAPTIDs.VHG_LLR,
    name: "Vita Health Group: Leicester, Leicestershire & Rutland Talking Therapies",
    formattedName: "Leicester, Leicestershire & Rutland Talking Therapies",
    postcode: "IP33 1UZ",
    phoneNumber: "+44 3300 945595",
    ageThreshold: 16,
    ageThresholdForTreatments: 16,
    ...VHGEmails["vhg-llr"]
  },
  [IAPTIDs.VHG_WEST_ESSEX]: {
    id: IAPTIDs.VHG_WEST_ESSEX,
    name: "Vita Health Group: West Essex Talking Therapies",
    formattedName: "West Essex Talking Therapies",
    postcode: "CM18 7BL",
    phoneNumber: "+44 3330 152966",
    ageThreshold: 16,
    ageThresholdForTreatments: 16,
    ...VHGEmails["vhg-westessex"]
  },
  [IAPTIDs.VHG_NOTTS]: {
    id: IAPTIDs.VHG_NOTTS,
    name: "Nottingham and Nottinghamshire Talking Therapies",
    formattedName: "Nottingham and Nottinghamshire Talking Therapies",
    postcode: "NG3 6AA",
    backendInstanceID: "VHG_NOTTS2",
    phoneNumber: "+44 3330 152966",
    ageThreshold: 17.5,
    ageThresholdForTreatments: 17.5,
    ...VHGEmails["vhg-notts"],
    referralForm: require("./referralForms/vhg-form.ts").default
  },
  [IAPTIDs.INSIGHT_KENT]: {
    id: IAPTIDs.INSIGHT_KENT,
    name: "Everyturn NHS Talking Therapies (Kent)",
    formattedName: "Everyturn NHS Talking Therapies (Kent) service",
    postcode: "ME13 8XZ",
    emails: KMCCGIaptEmails.insight,
    phoneNumber: "0300 555 5555",
    backendInstanceID: "INSIGHT_KENT_EAST",
    ageThreshold: 17.5,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_PETTERSBOROUGH]: {
    id: IAPTIDs.INSIGHT_PETTERSBOROUGH,
    name: "Everyturn NHS Talking Therapies (Peterborough)",
    formattedName: "Everyturn NHS Talking Therapies (Peterborough) service",
    postcode: "PE1 3PB",
    phoneNumber: "0300 555 0888",
    backendInstanceID: "INSIGHT_PETERBOROUGH",
    ageThreshold: 17,
    referralForm: require("./referralForms/insight-form.ts").default,
    emails: ["ccc.peterborough@everyturn.org"]
  },
  [IAPTIDs.INSIGHT_NOTTS]: {
    id: IAPTIDs.INSIGHT_NOTTS,
    name: "Everyturn NHS Talking Therapies (Nottinghamshire)",
    formattedName: "Everyturn NHS Talking Therapies (Nottinghamshire) service",
    postcode: "NG2 1RT",
    phoneNumber: "0300 555 5582",
    backendInstanceID: "INSIGHT_NOTTINGHAM_GREATER",
    ageThreshold: 17.75,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_BASSETLAW]: {
    id: IAPTIDs.INSIGHT_BASSETLAW,
    name: "Everyturn NHS Talking Therapies (Bassetlaw)",
    formattedName: "Everyturn NHS Talking Therapies (Bassetlaw) service",
    postcode: "S80 2PU",
    phoneNumber: "0300 300 0033",
    backendInstanceID: "INSIGHT_BASSETLAW",
    ageThreshold: 17.75,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_DERBY]: {
    id: IAPTIDs.INSIGHT_DERBY,
    name: "Everyturn NHS Talking Therapies (Derbyshire)",
    formattedName: "Everyturn NHS Talking Therapies (Derbyshire) service",
    postcode: "NG2 1RT",
    phoneNumber: "0300 555 5580",
    backendInstanceID: "INSIGHT_DERBY",
    ageThreshold: 18,
    referralForm: require("./referralForms/insight-form.ts").default,
    emails: ["ccc.derbyshire@everyturn.org"]
  },
  [IAPTIDs.INSIGHT_WIRRAL]: {
    id: IAPTIDs.INSIGHT_WIRRAL,
    name: "Everyturn NHS Talking Therapies (Wirral)",
    formattedName: "Everyturn NHS Talking Therapies (Wirral) service",
    postcode: "CH41 1EL",
    backendInstanceID: "INSIGHT_WIRRAL",
    phoneNumber: "0151 649 1859",
    ageThreshold: 16,
    referralForm: require("./referralForms/insight-form.ts").default,
    emails: ["ccc.wirral@everyturn.org"]
  },
  [IAPTIDs.INSIGHT_MEDWAY]: {
    id: IAPTIDs.INSIGHT_MEDWAY,
    name: "Everyturn NHS Talking Therapies (Medway)",
    formattedName: "Everyturn NHS Talking Therapies (Medway) service",
    backendInstanceID: "INSIGHT_MEDWAY",
    postcode: "ME4 6SL",
    emails: ["john+with-you@limbic.ai", "ross+with-you@limbic.ai"],
    ageThreshold: 17.5,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_KENT_DEMO]: {
    id: IAPTIDs.INSIGHT_KENT,
    name: "Everyturn NHS Talking Therapies (Kent)",
    formattedName: "Everyturn NHS Talking Therapies (Kent) service",
    postcode: "ME13 8XZ",
    phoneNumber: "0300 555 5555",
    backendInstanceID: "INSIGHT_KENT_EAST",
    ageThreshold: 17.5,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_PETTERSBOROUGH_DEMO]: {
    id: IAPTIDs.INSIGHT_PETTERSBOROUGH,
    name: "Everyturn NHS Talking Therapies (Peterborough)",
    formattedName: "Everyturn NHS Talking Therapies (Peterborough) service",
    postcode: "PE1 3PB",
    phoneNumber: "0300 555 0888",
    backendInstanceID: "INSIGHT_PETERBOROUGH",
    ageThreshold: 17,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_NOTTS_DEMO]: {
    id: IAPTIDs.INSIGHT_NOTTS,
    name: "Everyturn NHS Talking Therapies (Nottinghamshire)",
    formattedName: "Everyturn NHS Talking Therapies (Nottinghamshire) service",
    postcode: "NG2 1RT",
    phoneNumber: "0300 555 5582",
    backendInstanceID: "INSIGHT_NOTTINGHAM_GREATER",
    ageThreshold: 17.75,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_BASSETLAW_DEMO]: {
    id: IAPTIDs.INSIGHT_BASSETLAW,
    name: "Everyturn NHS Talking Therapies (Bassetlaw)",
    formattedName: "Everyturn NHS Talking Therapies (Bassetlaw) service",
    postcode: "S80 2PU",
    phoneNumber: "0300 300 0033",
    backendInstanceID: "INSIGHT_BASSETLAW",
    ageThreshold: 17.75,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_DERBY_DEMO]: {
    id: IAPTIDs.INSIGHT_DERBY,
    name: "Everyturn NHS Talking Therapies (Derbyshire)",
    formattedName: "Everyturn NHS Talking Therapies (Derbyshire) service",
    postcode: "NG2 1RT",
    phoneNumber: "0300 555 5582",
    backendInstanceID: "INSIGHT_DERBY",
    ageThreshold: 18,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_WIRRAL_DEMO]: {
    id: IAPTIDs.INSIGHT_WIRRAL,
    name: "Everyturn NHS Talking Therapies (Wirral)",
    formattedName: "Everyturn NHS Talking Therapies (Wirral) service",
    postcode: "CH41 1EL",
    backendInstanceID: "INSIGHT_WIRRAL",
    phoneNumber: "0151 649 1859",
    ageThreshold: 16,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_MEDWAY_DEMO]: {
    id: IAPTIDs.INSIGHT_MEDWAY,
    name: "Everyturn NHS Talking Therapies (Medway)",
    formattedName: "Everyturn NHS Talking Therapies (Medway) service",
    backendInstanceID: "INSIGHT_MEDWAY",
    postcode: "ME4 6SL",
    emails: ["john+with-you@limbic.ai", "ross+with-you@limbic.ai"],
    ageThreshold: 17.5,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.MIND_MATTERS_SAB]: {
    id: IAPTIDs.MIND_MATTERS_SAB,
    name: "Mind Matters Surrey NHS",
    formattedName: "Mind Matters Surrey NHS service",
    postcode: "KT16 9FG",
    backendInstanceID: "SABP_MAIN",
    phoneNumber: "+44 3003 305450",
    ageThreshold: 17,
    ageThresholdForTreatments: 17,
    riskEmails: SABPIaptEmails.riskEmails,
    riskEmailsCC: SABPIaptEmails.riskEmailsCC,
    riskEmailsBCC: SABPIaptEmails.riskEmailsBCC,
    referralForm: require("./referralForms/sabp.ts").default
  },
  [IAPTIDs.LINCS_STAMFORD]: {
    id: IAPTIDs.LINCS_STAMFORD,
    backendInstanceID: "LINCS_STAMFORD",
    name: "Lincolnshire Talking Therapies (Stamford)",
    clinicName: "steps2change-stamford",
    formattedName: "Lincolnshire Talking Therapies (Stamford) service",
    postcode: "PE9 1UN",
    phoneNumber: "+44 3031 234000",
    ageThreshold: 16,
    referralForm: require("./referralForms/lincolnshire-form.ts").default
  },
  [IAPTIDs.LINCS_SPALDING]: {
    id: IAPTIDs.LINCS_SPALDING,
    backendInstanceID: "LINCS_SPALDING",
    name: "Lincolnshire Talking Therapies (Spalding)",
    clinicName: "steps2change-spalding",
    formattedName: "Lincolnshire Talking Therapies service",
    postcode: "PE11 1TW",
    phoneNumber: "+44 3031 234000",
    ageThreshold: 16,
    referralForm: require("./referralForms/lincolnshire-form.ts").default
  },
  [IAPTIDs.LINCS_GRANTHAM]: {
    id: IAPTIDs.LINCS_GRANTHAM,
    backendInstanceID: "LINCS_GRANTHAM",
    name: "Lincolnshire Talking Therapies (Grantham)",
    clinicName: "steps2change-grantham",
    formattedName: "Lincolnshire Talking Therapies service",
    postcode: "NG31 9DF",
    phoneNumber: "+44 3031 234000",
    ageThreshold: 16,
    referralForm: require("./referralForms/lincolnshire-form.ts").default
  },
  [IAPTIDs.LINCS_BOSTON]: {
    id: IAPTIDs.LINCS_BOSTON,
    backendInstanceID: "LINCS_BOSTON",
    name: "Lincolnshire Talking Therapies (Boston)",
    clinicName: "steps2change-boston",
    formattedName: "Lincolnshire Talking Therapies service",
    postcode: "PE21 8EG",
    phoneNumber: "+44 3031 234000",
    ageThreshold: 16,
    referralForm: require("./referralForms/lincolnshire-form.ts").default
  },
  [IAPTIDs.LINCS_SLEAFORD]: {
    id: IAPTIDs.LINCS_SLEAFORD,
    backendInstanceID: "LINCS_SLEAFORD",
    name: "Lincolnshire Talking Therapies (Sleaford)",
    clinicName: "steps2change-sleaford",
    formattedName: "Lincolnshire Talking Therapies service",
    postcode: "NG34 8GG",
    phoneNumber: "+44 3031 234000",
    ageThreshold: 16,
    referralForm: require("./referralForms/lincolnshire-form.ts").default
  },
  [IAPTIDs.LINCS_SKEGNESS]: {
    id: IAPTIDs.LINCS_SKEGNESS,
    backendInstanceID: "LINCS_SKEGNESS",
    name: "Lincolnshire Talking Therapies (Skegness)",
    clinicName: "steps2change-skegness",
    formattedName: "Lincolnshire Talking Therapies service",
    postcode: "PE25 2JA",
    phoneNumber: "+44 3031 234000",
    ageThreshold: 16,
    referralForm: require("./referralForms/lincolnshire-form.ts").default
  },
  [IAPTIDs.LINCS_LINCOLN]: {
    id: IAPTIDs.LINCS_LINCOLN,
    backendInstanceID: "LINCS_LINCOLN",
    name: "Lincolnshire Talking Therapies (Lincoln)",
    clinicName: "steps2change-lincoln",
    formattedName: "Lincolnshire Talking Therapies service",
    postcode: "LN2 4WA",
    phoneNumber: "+44 3031 234000",
    ageThreshold: 16,
    referralForm: require("./referralForms/lincolnshire-form.ts").default
  },
  [IAPTIDs.LINCS_LOUTH]: {
    id: IAPTIDs.LINCS_LOUTH,
    backendInstanceID: "LINCS_LOUTH",
    name: "Lincolnshire Talking Therapies (Louth)",
    clinicName: "steps2change-louth",
    formattedName: "Lincolnshire Talking Therapies service",
    postcode: "LN11 0LF",
    phoneNumber: "+44 3031 234000",
    ageThreshold: 16,
    referralForm: require("./referralForms/lincolnshire-form.ts").default
  },
  [IAPTIDs.LINCS_GAINSBOROUGH]: {
    id: IAPTIDs.LINCS_GAINSBOROUGH,
    backendInstanceID: "LINCS_GAINSBOROUGH",
    name: "Lincolnshire Talking Therapies (Gainsborough)",
    clinicName: "steps2change-gainsborough",
    formattedName: "Lincolnshire Talking Therapies service",
    postcode: "DN21 2DZ",
    phoneNumber: "+44 3031 234000",
    ageThreshold: 16,
    referralForm: require("./referralForms/lincolnshire-form.ts").default
  },
  [IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX]: {
    id: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
    name: "Therapy For You",
    formattedName: "Therapy For You",
    postcode: "CO3 3BT",
    backendInstanceID: "ESSEX_MAIN",
    phoneNumber: "+44 1206 334001",
    ageThreshold: 16,
    emails: EssexIaptEmails.northEast,
    referralForm: require("./referralForms/essex-form.ts").ne_form
  },
  [IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX]: {
    id: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
    name: "Therapy For You",
    formattedName: "Therapy For You",
    postcode: "SS15 6EA",
    backendInstanceID: "ESSEX_SOUTHEAST",
    phoneNumber: "+44 1268 739128",
    ageThreshold: 18,
    emails: EssexIaptEmails.southEast,
    referralForm: require("./referralForms/essex-form.ts").se_form
  },
  [IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO]: {
    id: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
    name: "Therapy For You",
    formattedName: "Therapy For You (NE Essex)",
    postcode: "CO3 3BT",
    backendInstanceID: "ESSEX_E2E_DEMO",
    phoneNumber: "+44 1206 334001",
    ageThreshold: 16,
    emails: EssexIaptEmailsDemo.northEast,
    referralForm: require("./referralForms/essex-form.ts").ne_form
  },
  [IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO]: {
    id: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
    name: "Therapy For You",
    formattedName: "Therapy For You (SE Essex)",
    postcode: "SS15 6EA",
    backendInstanceID: "ESSEX_E2E_DEMO",
    phoneNumber: "+44 1268 739128",
    ageThreshold: 18,
    emails: EssexIaptEmailsDemo.southEast,
    referralForm: require("./referralForms/essex-form.ts").se_form
  },
  [IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT]: {
    id: IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT,
    name: "Shropshire, Telford & Wrekin NHS Talking Therapies",
    formattedName: "Shropshire, Telford & Wrekin NHS Talking Therapies",
    postcode: "TF3 4NP",
    backendInstanceID: "MPFT_SHROPSHIRE",
    phoneNumber: "+44 300 1236020",
    ageThreshold: 16,
    emails: MPFTEmails.emails,
    riskEmails: MPFTEmails.riskEmails,
    referralForm: require("./referralForms/mpft-form").form
  },
  [IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON]: {
    id: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
    name: "NHS Talking Therapies Warrington",
    formattedName: "NHS Talking Therapies Warrington",
    postcode: "OL14 8RS",
    backendInstanceID: "MHM_WARRINGTON",
    phoneNumber: "+44 01925 401 720",
    ageThreshold: 16,
    emails: [],
    riskEmails: MHMEmails.warrington,
    referralForm: require("./referralForms/mental-health-matters.ts").warrington_form
  },
  [IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND]: {
    id: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
    name: "NHS Talking Therapies Northumberland",
    formattedName: "NHS Talking Therapies Northumberland",
    postcode: "OL14 8RS",
    backendInstanceID: "MHM_NORTHUMBERLAND",
    phoneNumber: "+44 300 3030 700",
    ageThreshold: 16,
    emails: [],
    riskEmails: MHMEmails.northumberland,
    referralForm: require("./referralForms/mental-health-matters.ts").northumberland_form
  },
  [IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON]: {
    id: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
    name: "NHS Talking Therapies Sefton",
    formattedName: "NHS Talking Therapies Sefton",
    postcode: "OL14 8RS",
    backendInstanceID: "MHM_SEFTON",
    phoneNumber: "+44 300 303 2708",
    ageThreshold: 16,
    emails: [],
    riskEmails: MHMEmails.sefton,
    referralForm: require("./referralForms/mental-health-matters.ts").sefton_form
  },
  [IAPTIDs.BRADFORD]: {
    id: IAPTIDs.BRADFORD,
    name: "NHS Bradford District and Craven Talking Therapies",
    formattedName: "NHS Bradford District and Craven Talking Therapies",
    postcode: "BD4 7BH",
    backendInstanceID: "BRADFORD_MAIN",
    phoneNumber: "+44 127 422 1234",
    ageThreshold: 16,
    emails: [],
    riskEmails: []
  },
  [IAPTIDs.LWC_SERVICE_1]: {
    id: IAPTIDs.LWC_SERVICE_1,
    name: "Living Well Consortium Talking Therapies",
    formattedName: "Living Well Consortium Talking Therapies",
    postcode: "B23 6DJ",
    backendInstanceID: "LWC_MAIN",
    phoneNumber: "+44 0121 663 1217",
    ageThreshold: 16,
    emails: [],
    riskEmails: [],
    referralForm: require("./referralForms/demo").default
  },
  [IAPTIDs.CW_CHE]: {
    id: IAPTIDs.CW_CHE,
    name: "Central Cheshire NHS Talking Therapies",
    formattedName: "Central Cheshire NHS Talking Therapies",
    postcode: "OL14 8RS",
    backendInstanceID: "CHESHIRE_WIRRAL_CHE",
    phoneNumber: "+44 300 303 0639",
    ageThreshold: 16,
    emails: [],
    riskEmails: [],
    referralForm: require("./referralForms/demo").default
  },
  [IAPTIDs.CW_CHW]: {
    id: IAPTIDs.CW_CHW,
    name: "West Cheshire NHS Talking Therapies",
    formattedName: "West Cheshire NHS Talking Therapies",
    postcode: "OL14 8RS",
    backendInstanceID: "CHESHIRE_WIRRAL_CHW",
    phoneNumber: "+44 151 488 8348",
    ageThreshold: 16,
    emails: [],
    riskEmails: [],
    referralForm: require("./referralForms/demo").default
  },
  [IAPTIDs.OXFORDSHIRE]: {
    id: IAPTIDs.OXFORDSHIRE,
    name: "Oxfordshire Talking Therapies",
    formattedName: "Oxfordshire Talking Therapies",
    postcode: "OX3 7JH", // TODO: Confirm postcode
    backendInstanceID: "OXFORD_MAIN",
    phoneNumber: "+44 1865 901222",
    ageThreshold: 18,
    ageThresholdForTreatments: 18,
    riskEmails: OxfordEmails.riskEmails,
    riskEmailsCC: OxfordEmails.riskEmailsCC,
    riskEmailsBCC: OxfordEmails.riskEmailsBCC,
    referralForm: require("./referralForms/demo").default
  },
  [IAPTIDs.BUCKINGHAMSHIRE]: {
    id: IAPTIDs.BUCKINGHAMSHIRE,
    name: "Buckinghamshire Talking Therapies",
    formattedName: "Buckinghamshire Talking Therapies",
    postcode: "HP13 6LA", // TODO: Confirm postcode
    backendInstanceID: "BUCKS_MAIN",
    phoneNumber: "+44 1865 901600",
    ageThreshold: 18,
    ageThresholdForTreatments: 18,
    riskEmails: BucksEmails.riskEmails,
    riskEmailsCC: BucksEmails.riskEmailsCC,
    riskEmailsBCC: BucksEmails.riskEmailsBCC,
    referralForm: require("./referralForms/demo").default
  },
  [IAPTIDs.DORKING]: {
    id: IAPTIDs.DORKING,
    name: "DHC Talking Therapies",
    formattedName: "DHC Talking Therapies",
    postcode: "RH1 2NP",
    backendInstanceID: "DORKING_MAIN",
    phoneNumber: "+44 1483 906392",
    ageThreshold: 17,
    emails: [],
    riskEmails: ["iaptduty.dhc@nhs.net"],
    referralForm: require("./referralForms/demo").default
  },
  [IAPTIDs.BEXLEY]: {
    id: IAPTIDs.BEXLEY,
    name: "Mind in Bexley Services",
    formattedName: "Mind in Bexley Services",
    postcode: "DA6 8DS",
    backendInstanceID: "DEMO_MAIN", // TODO: Update with correct instanceID
    phoneNumber: "+44 2083 038932",
    ageThreshold: 18,
    emails: bexleyEmails.emails,
    riskEmails: bexleyEmails.riskEmails,
    riskEmailsCC: bexleyEmails.riskEmailsCC,
    riskEmailsBCC: bexleyEmails.riskEmailsBCC,
    referralForm: require("./referralForms/demo").default
  },
  [IAPTIDs.MIND_MATTERS_SAB_V2]: {
    id: IAPTIDs.MIND_MATTERS_SAB_V2,
    name: "Mind Matters Surrey NHS",
    formattedName: "Mind Matters Surrey NHS service",
    postcode: "KT16 9FG",
    backendInstanceID: "SABP_V2_MAIN",
    phoneNumber: "+44 3003 305450",
    ageThreshold: 17,
    ageThresholdForTreatments: 17,
    riskEmails: SABPIaptEmails.riskEmails,
    riskEmailsCC: SABPIaptEmails.riskEmailsCC,
    riskEmailsBCC: SABPIaptEmails.riskEmailsBCC,
    referralForm: require("./referralForms/sabp.ts").default
  }
}
