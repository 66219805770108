import ChatFlowPayloadBuilder from "../ChatFlowPayloadBuilder"
import { ChatFlowsEnum, ReferralPayload } from "@limbic/types"
import invariant from "../../../../utils/invariant"

export default class SubstancesPayloadBuilder extends ChatFlowPayloadBuilder<ChatFlowsEnum.COLLECT_SUBSTANCES> {
  chatFlow = ChatFlowsEnum.COLLECT_SUBSTANCES

  /** Overrides */

  getTransformedKeys(transformedPayload?: Record<string, any>): (keyof ReferralPayload)[] {
    const substancesKey = this.settings?.substancesKey
    const substancesForMoodKey = this.settings?.substancesForMoodKey
    const substancesInfoKey = this.settings?.substancesInfoKey
    const substancesImpactLifeKey = this.settings?.substancesImpactLifeKey
    const nonPrescribedSubstancesKey = this.settings?.nonPrescribedSubstancesKey
    const substancesAreMedicationKey = this.settings?.substancesAreMedicationKey
    const prescribedMedicationKey = this.settings?.prescribedMedicationKey
    const medicationInfoKey = this.settings?.medicationInfoKey
    const medicationWithinDosageKey = this.settings?.medicationWithinDosageKey
    const medicationNotTakingInfoKey = this.settings?.medicationNotTakingInfoKey
    const result: string[] = []

    if (substancesKey! in (transformedPayload ?? {})) result.push("substances")
    else if (this.settings?.shouldAskSubstances === false) {
      // 👆 this is because shouldAskSubstances defaults to true
      if (substancesForMoodKey! in (transformedPayload ?? {})) result.push("substances")
    } else if (!this.settings?.shouldAskSubstancesForMood) {
      if (nonPrescribedSubstancesKey! in (transformedPayload ?? {})) result.push("substances")
    }
    if (substancesAreMedicationKey! in (transformedPayload ?? {})) {
      result.push("substancesAreMedication")
    }
    if (substancesForMoodKey! in (transformedPayload ?? {})) result.push("substancesForMood")
    if (substancesInfoKey! in (transformedPayload ?? {})) result.push("substancesInfo")
    if (substancesImpactLifeKey! in (transformedPayload ?? {})) result.push("substancesImpactLife")
    if (prescribedMedicationKey! in (transformedPayload ?? {})) result.push("prescribedMedication")
    if (medicationInfoKey! in (transformedPayload ?? {})) {
      result.push("medication", "medicationTaking")
    }
    if (medicationWithinDosageKey! in (transformedPayload ?? {})) {
      result.push("medicationWithinDosage")
    }
    // we don't care about medicationNotTakingKey, because if it's mapped,
    // then we save that boolean separately, because it doesn't affect
    // whether we have content for medicationNotTaking in the payload.
    if (medicationNotTakingInfoKey! in (transformedPayload ?? {})) {
      result.push("medicationNotTaking")
    }

    return result
  }

  getDefaultPayload(): Partial<Partial<ReferralPayload>> {
    const substancesKey = this.getSourceKey("substancesKey")
    const substancesForMoodKey = this.getSourceKey("substancesForMoodKey")
    const substancesInfoKey = this.getSourceKey("substancesInfoKey")
    const substancesImpactLifeKey = this.getSourceKey("substancesImpactLifeKey")
    const nonPrescribedSubstancesKey = this.getSourceKey("nonPrescribedSubstancesKey")
    const substancesAreMedicationKey = this.getSourceKey("substancesAreMedicationKey")
    const prescribedMedicationKey = this.getSourceKey("prescribedMedicationKey")
    const medicationInfoKey = this.getSourceKey("medicationInfoKey")
    const medicationWithinDosageKey = this.getSourceKey("medicationWithinDosageKey")
    const medicationNotTakingKey = this.getSourceKey("medicationNotTakingKey")
    const medicationNotTakingInfoKey = this.getSourceKey("medicationNotTakingInfoKey")
    invariant(substancesKey, "no mapping key for substancesKey")
    invariant(substancesForMoodKey, "no mapping key for substancesForMoodKey")
    invariant(substancesInfoKey, "no mapping key for substancesInfoKey")
    invariant(substancesImpactLifeKey, "no mapping key for substancesImpactLifeKey")
    invariant(nonPrescribedSubstancesKey, "no mapping key for nonPrescribedSubstancesKey")
    invariant(substancesAreMedicationKey, "no mapping key for substancesAreMedicationKey")
    invariant(prescribedMedicationKey, "no mapping key for prescribedMedicationKey")
    invariant(medicationInfoKey, "no mapping key for medicationInfoKey")
    invariant(medicationWithinDosageKey, "no mapping key for medicationWithinDosageKey")
    invariant(medicationNotTakingKey, "no mapping key for medicationNotTakingKey")
    invariant(medicationNotTakingInfoKey, "no mapping key for medicationNotTakingInfoKey")

    const substances = this.ctx.state?.[substancesKey]
    const substancesAreMedication = this.ctx.state?.[substancesAreMedicationKey]
    const substancesForMood = this.ctx.state?.[substancesForMoodKey]
    const substancesInfo = this.ctx.state?.[substancesInfoKey]
    const substancesImpactLife = this.ctx.state?.[substancesImpactLifeKey]
    const nonPrescribedSubstances = this.ctx.state?.[nonPrescribedSubstancesKey]
    const prescribedMedication = this.ctx.state?.[prescribedMedicationKey]
    const medicationInfo = this.ctx.state?.[medicationInfoKey]
    const medicationWithinDosage = this.ctx.state?.[medicationWithinDosageKey]
    const medicationNotTaking = this.ctx.state?.[medicationNotTakingKey]
    const medicationNotTakingInfo = this.ctx.state?.[medicationNotTakingInfoKey]

    return {
      // prettier-ignore
      substances: (substances || substancesForMood || nonPrescribedSubstances),
      substancesAreMedication,
      substancesForMood,
      substancesInfo: substancesInfo ? [substancesInfo] : undefined,
      substancesImpactLife,
      prescribedMedication,
      medication: medicationInfo ? [medicationInfo] : undefined,
      medicationWithinDosage,
      // Some services might not ask for details and only ask if they are prescribed
      // any medication that they are not taking. The backend, however, doesn't
      // differentiate the two, so when we only have the boolean question, the empty
      // array indicates "they're not taking prescribed medication, but we don't
      // know the details", and when we have both questions the array will be
      // populated anyway
      medicationNotTaking:
        medicationNotTaking || medicationNotTakingInfo?.length
          ? [medicationNotTakingInfo].filter(Boolean)
          : undefined
    } as Partial<ReferralPayload>
  }
}

ChatFlowPayloadBuilder.builders[ChatFlowsEnum.COLLECT_SUBSTANCES] = SubstancesPayloadBuilder
