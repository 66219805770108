import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { RiskLevelReason } from "../../../models/Constants"
import type {
  IStepData,
  IStepResult,
  StepResultBodyType
} from "../../../backend/chatbot/models/IStep"

type State = RiskPathwayScriptState
export type RiskPathwayVHGScriptState = State

export class RiskPathwayVHGScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayVHGScript"

  /** Script Steps */

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askCanYouKeepYourselfSafe }
  }

  @step.logState
  askCanYouKeepYourselfSafe(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Are you able to keep yourself, and any dependants in your care, safe until your appointment (within 3 weeks time)?"
      ),
      prompt: {
        id: this.getPromptId("askCanYouKeepYourselfSafe"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askCanYouKeepYourselfSafe"
      },
      nextStep: this.handleCanYouKeepYourselfSafe
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.saveResponse<State>(
    "10",
    "Are you able to keep yourself, and any dependants in your care, safe until your appointment (within 3 weeks time)?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.canKeepSelfSafe = d.response
  })
  handleCanYouKeepYourselfSafe(d: IStepData<State>): IStepResult {
    // PHQ9Q9 >= 2 and Yes to keeping safe
    if (d.response && d.state.php9q9Score! >= 2) {
      if (this.clinicalStore.isCrisis || this.clinicalStore.isHighRisk) {
        this.setRiskLevelHigh(d.state, RiskLevelReason.PHQ9_Q9_2_PLUS_WITH_CRISIS)
      } else {
        this.setRiskLevelModerate(d.state, RiskLevelReason.PHQ9_Q9_2_PLUS)
      }
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
    }
    // PHQ9Q9 >= 2 and No to keeping safe
    if (!d.response && d.state.php9q9Score! >= 2) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.PHQ9_Q9_CANT_KEEP_SAFE_2_PLUS)
    }
    // PHQ9Q9 === 1 and answer NO, cannot keep self safe
    if (!d.response && d.state.php9q9Score! === 1) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.PHQ9_Q9_CANT_KEEP_SAFE_1)
    }

    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  sayCrisis(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const serviceName = this.rootStore.configStore.serviceName

    return {
      body: this.t(
        [
          "Sorry to hear that {name}",
          "It is normal for people to have thoughts of this nature at times",
          "However, this is not an emergency response service",
          "There are alternative services that are better placed to support you",
          "If you need urgent, life threatening medical help please call [999](tel:999)",
          "One of {serviceName}'s clinical team will contact you to check that you have referred yourself in to one of the crisis support services"
        ],
        { name, serviceName }
      ),
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("I understand") }, { body: this.t("Okay") }],
        dataPointsName: "sayCrisis"
      },
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onSaveRiskLevelAndReferralType(state: State): Promise<IStepResult> {
    return { nextStep: !state.canKeepSelfSafe ? this.sayCrisis : this.end }
  }

  getClosingMessage(state: State): StepResultBodyType[] | undefined {
    return this.t(["Ok, let's carry on with the mental health check in"])
  }

  getAdditionalInfoHTML(state: State): string {
    const original = super.getAdditionalInfoHTML(state)
    const isRisk = this.clinicalStore.isRisk
    const reason = this.clinicalStore.riskLevelReason
    return `
        ${original}
        <b>Duty team should call:</b> ${isRisk ? "Yes" : "No"}
        <b>Reason why duty team should call:</b> ${reason}
        `
  }
}

/* istanbul ignore next */
export default class RiskPathwayVHGDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayVHG
  readonly name: string = "RiskPathwayVHGDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(RiskPathwayVHGDialogue.id, new RiskPathwayVHGScript(), state, snapshot)
  }
}
