import { ClinicalFlags, ClinicalGroups, ProblemCategories, IClinicalPath } from "@limbic/types"

// The concept is: Complex comorbid scenarios
// where there's a combination of M_GAD / S_GAD
// and one of OCD, Specific Phobia, Specific Phobia NBI,
// Health Anxiety or Panic, we want them to go to
// shortened assessment

const primaryCombos: ProblemCategories[][] = [
  [ProblemCategories.M_GAD, ProblemCategories.OCD],
  [ProblemCategories.S_GAD, ProblemCategories.OCD],
  [ProblemCategories.M_GAD, ProblemCategories.SpecificPhobia],
  [ProblemCategories.S_GAD, ProblemCategories.SpecificPhobia],
  [ProblemCategories.M_GAD, ProblemCategories.SpecificPhobiaNBI],
  [ProblemCategories.S_GAD, ProblemCategories.SpecificPhobiaNBI],
  [ProblemCategories.M_GAD, ProblemCategories.HealthAnxiety],
  [ProblemCategories.S_GAD, ProblemCategories.HealthAnxiety],
  [ProblemCategories.M_GAD, ProblemCategories.Panic],
  [ProblemCategories.S_GAD, ProblemCategories.Panic]
]

const flagCombos: ClinicalFlags[][] = [
  [],
  [ClinicalFlags.MS_FunctionalImpairment],
  [ClinicalFlags.S_FunctionalImpairment]
]

const paths: Omit<IClinicalPath, "id">[] = []

primaryCombos.forEach(primaryProblems =>
  flagCombos.forEach(flags =>
    paths.push({
      matcher: { primaryProblems, flags },
      enableSecondary: false,
      clinicalGroup: ClinicalGroups.ComplexComorbid,
      suggestTreatments: false,
      defaultReferralType: "Short Assessment",
      treatments: [],
      declinedTreatmentsReferralType:
        "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
    })
  )
)

const lincolnshireCustomClinicalPaths: IClinicalPath[] = paths.map((path, index) => ({
  ...path,
  id: `lincolnshire-${index + 1}`
}))

export { lincolnshireCustomClinicalPaths }
