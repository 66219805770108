/* eslint-disable @typescript-eslint/no-empty-function */
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../../DialogueIDs"
import { step } from "../../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import BaseScript, { BaseScriptState } from "../../../BaseScript"
import { Category } from "@limbic/crisis-detection"

interface State extends BaseScriptState {
  askMedicationInfo?: boolean
  askBothMedicationAndSubstances?: boolean
  isTakingMedication?: boolean
}

export type CollectMedicationAndSubstancesDetailsScriptState = State

export class CollectMedicationAndSubstancesDetailsScript extends BaseScript<State> {
  readonly name: string = "CollectMedicationAndSubstancesDetailsScript"

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askSubstances }
  }

  @step.logState
  askSubstances(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Okay. And over the past month, have you taken any non-prescribed medication or substances to help you manage your mood?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askSubstances"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askSubstances"
      },
      nextStep: this.handleSubstances
    }
  }

  @step.logStateAndResponse
  async handleSubstances(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.substances = d.response
    this.setPeople({ substances: d.response })

    const shouldEnd = !d.state.askBothMedicationAndSubstances

    return {
      nextStep: d.response //
        ? this.askSubstancesOrigin
        : shouldEnd
          ? this.end
          : this.askMedication
    }
  }

  @step.logState
  askSubstancesOrigin(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Are these medications brought over the counter at a pharmacy or supermarket? (and include herbal remedies, probiotics and vitamins)",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askSubstancesOrigin"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askSubstancesOrigin"
      },
      nextStep: this.handleSubstancesOrigin
    }
  }

  @step.logState
  async handleSubstancesOrigin(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.substancesAreMedications = d.response
    this.setPeople({ substancesAreMedications: d.response })

    // TODO: Not entirely sure about this... SABP is going through this.askMedicationInfo
    // but in general (i.e. in the SelfReferralScript) the this.askMedicationInfo is not being used
    // The only case that it's being used (the method in SelfReferralScript) is with SABP...
    const medicationNextStep = d.state.askMedicationInfo
      ? this.askMedicationInfo
      : this.askMedicationWithinDoseRange

    return {
      nextStep: d.state.substancesAreMedications ? medicationNextStep : this.askSubstancesInfo
    }
  }

  @step.logState
  askMedicationWithinDoseRange(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Are you using the medication within the recommended dose range on the packet?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askMedicationDoseRange"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: "Yes" },
          { body: this.t("No"), value: "No" },
          { body: this.t("Not sure"), value: "Not sure" }
        ],
        dataPointsName: "askMedicationDoseRange"
      },
      nextStep: this.handleMedicationWithinDoseRange
    }
  }

  @step.logState
  async handleMedicationWithinDoseRange(
    d: IStepData<State, "Yes" | "No" | "Not sure">
  ): Promise<IStepResult> {
    d.state.medicationWithinDoseRange = d.response
    this.setPeople({ medicationWithinDoseRange: d.response })

    return { nextStep: d.state.askBothMedicationAndSubstances ? this.askMedication : this.end }
  }

  @step.logState
  askSubstancesInfo(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Could you please describe what your use is, and how often do you use it?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askSubstancesInfo"),
        trackResponse: false,
        type: "text",
        forceValue: false,
        cancelLabel: "skip",
        cancelIsEmptySubmit: true
      },
      nextStep: this.handleSubstancesInfoWithCrisis
    }
  }

  @step.logState
  returnToAskSubstancesInfo(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "So we were talking about the substances you're taking to help manage your mood",
        this.getContext(d.state)
      ),
      nextStep: this.askSubstancesInfo
    }
  }

  @step.logState
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    ignoredCategories: [Category.Medication],
    getNextStep: (s: CollectMedicationAndSubstancesDetailsScript) => s.returnToAskSubstancesInfo
  })
  async handleSubstancesInfoWithCrisis(d: IStepData<State, string>): Promise<IStepResult> {
    d.state.substancesInfo = d.response
    this.setPeople({ substancesInfo: d.response })

    return { nextStep: d.state.askBothMedicationAndSubstances ? this.askMedication : this.end }
  }

  @step.logState
  askMedication(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Are you currently taking any prescribed medication for Mental Health?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askMedication"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askMedication"
      },
      nextStep: this.handleMedication
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, boolean>, script: CollectMedicationAndSubstancesDetailsScript) => {
      d.state.isTakingMedication = d.response
      script.referralStore.setCustomField<State>("isTakingMedication", d.response)
    }
  )
  handleMedication(d: IStepData<State, boolean>): IStepResult {
    return { nextStep: d.response ? this.askMedicationInfo : this.end }
  }

  @step.logState
  askMedicationInfo(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Could you please describe what your use is, and how often do you use it?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askMedicationInfo"),
        trackResponse: false,
        type: "text",
        forceValue: false,
        cancelLabel: "skip",
        cancelIsEmptySubmit: true,
        dataPointsName: "askMedicationInfo"
      },
      nextStep: this.handleMedicationInfoWithCrisis
    }
  }

  @step.logState
  returnToAskMedicationInfo(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "So we were talking about the medication you're taking to help manage your mood",
        this.getContext(d.state)
      ),
      nextStep: this.askSubstancesInfo
    }
  }

  @step.logState
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    ignoredCategories: [Category.Medication],
    getNextStep: (s: CollectMedicationAndSubstancesDetailsScript) => s.returnToAskMedicationInfo
  })
  async handleMedicationInfoWithCrisis(d: IStepData<State, string>): Promise<IStepResult> {
    d.state.medicationInfo = d.response
    this.setPeople({ medicationInfo: d.response })
    return { nextStep: this.end }
  }
}

/* istanbul ignore next */
export default class CollectMedicationAndSubstancesDetailsDialogue extends AdHocDialogue<
  State,
  CollectMedicationAndSubstancesDetailsScript
> {
  static id = DialogueIDs.CollectMedicationAndSubstancesDetails
  readonly name: string = "CollectMedicationAndSubstancesDetailsDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      CollectMedicationAndSubstancesDetailsDialogue.id,
      new CollectMedicationAndSubstancesDetailsScript(),
      state,
      snapshot
    )
  }
}
