import ChatFlowPayloadBuilder from "../ChatFlowPayloadBuilder"
import { ChatFlowsEnum, ReferralPayload } from "@limbic/types"

export default class GenderPayloadBuilder extends ChatFlowPayloadBuilder<ChatFlowsEnum.COLLECT_GENDER> {
  chatFlow = ChatFlowsEnum.COLLECT_GENDER

  /** Overrides */

  getDefaultPayload(): Partial<Partial<ReferralPayload>> {
    return {
      gender: this.ctx.state?.gender,
      genderSameAsBirthAssigned: this.ctx.state?.sameGenderAsBirth
    } as Partial<ReferralPayload>
  }
}

ChatFlowPayloadBuilder.builders[ChatFlowsEnum.COLLECT_GENDER] = GenderPayloadBuilder
