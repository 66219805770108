import { action, observable, computed } from "mobx"
import moment from "moment"
import Dialogue from "../../backend/chatbot/Dialogue"
import Persistable from "../../models/Persistable"
import uuidv4 from "../../backend/chatbot/utils/uuidv4"
import invariant from "../../utils/invariant"
import { findLast } from "../../utils/array"
import type IPrompt from "../../backend/chatbot/models/IPrompt"
import type IMessage from "../../backend/chatbot/models/IMessage"
import type { IBotMessage, IUserMessage } from "../../backend/chatbot/models/IMessage"
import Syncable from "../../models/Syncable"

export default class ChatStore extends Persistable implements Syncable {
  readonly name: string = "ChatStore"
  @observable messages: IMessage[]
  @observable isTyping: boolean
  @observable typingMessage?: IBotMessage

  constructor() {
    super()
    this.isTyping = false
    this.messages = []
  }

  restart?(): void

  /** Overrides */

  hydrateValueOfKey(key: string, jsonValue: unknown): any {
    if (key === "createdAt") return new Date(jsonValue as string)
    return jsonValue
  }

  rehydrate(): void {
    this.setupWithMessageHistory()
  }

  /** Actions */

  @action
  setupWithMessageHistory(): void {
    this.isTyping = false
    this.messages = this.hydrate("messages") ?? []
    this.typingMessage = undefined
  }

  @action
  setup(): void {
    this.isTyping = false
    this.messages = []
    this.typingMessage = undefined
  }

  @action
  setMessages(messages: IMessage[] = []): void {
    this.messages = messages
  }

  @action
  setTypingMessage(message?: IBotMessage): void {
    this.typingMessage = message
  }

  @action
  addUserMessage(body?: string, value?: unknown, dialogue?: Dialogue): void {
    if (body) {
      const identifier = this.userPrompt?.id
      const lastMessage = this.messages[this.messages.length - 1] as IBotMessage
      const lastMessageIsCurrent = dialogue?.uuid === lastMessage?._meta?.dialogueUUID
      const isUndoAble = lastMessageIsCurrent && lastMessage.prompt?.isUndoAble
      const message: IUserMessage = {
        id: uuidv4(),
        author: "user",
        createdAt: new Date(),
        body,
        value,
        isUndoAble,
        identifier
      }
      this.messages.push(message)
      this.setPeople({ lastUserMessageId: identifier })
      this.incrementPeople("User Messages")
      this.setPeople({ endDate: moment().format("DD/MM/YYYY hh:mm") })
    }
  }

  @action
  addBotMessage(message: IBotMessage): void {
    const lastMessage = this.messages[this.messages.length - 1]
    const lastIsBotMessage = lastMessage?.author === "bot"
    const bodyIsSame = lastMessage?.body && lastMessage?.body === message.body
    if (lastIsBotMessage && bodyIsSame) {
      return
    }
    this.messages.push(message)
    this.setPeople({
      lastStep: message._meta.step,
      lastBotMessageId: message._meta.promptId,
      lastDialogue: message._meta.dialogueName
    })
    this.incrementPeople("Bot Messages")
    this.setPeople({ endDate: moment().format("DD/MM/YYYY hh:mm") })
  }

  @action
  updateBotMessage(message: IBotMessage): void {
    const index = this.messages.findIndex(m => m.id === message.id)
    invariant(index > -1, `Message with id ${message.id} not found`)
    this.messages[index] = message
  }

  @action
  freezeDialogueMessages(): void {
    this.setMessages(this.messages.map(i => ({ ...i, isUndoAble: false })))
  }

  @action
  startBotTyping(): void {
    this.isTyping = true
    this.setTypingMessage()
  }

  @action
  stopBotTyping(): void {
    this.isTyping = false
    this.setTypingMessage()
  }

  /** Generic Handlers */

  updateSnapShots(): void {
    this.persist("messages", this.messages)
  }

  findLastBotMessage(id: string): IBotMessage | undefined {
    const index = this.messages.findIndex(m => m.id === id && m.author === "user")
    invariant(index > -1, `Message with id ${id} not found`)

    let botMessage: IBotMessage | undefined
    for (let i = index; i >= 0; i--) {
      const message = this.messages[i]
      if (message.author === "bot") {
        botMessage = message
        break
      }
    }
    return botMessage
  }

  /** Getters / Setters */

  get lastMessage(): IMessage | undefined {
    return findLast<IMessage>(this.messages, m => m.id !== "_typing")
  }

  get lastBotMessage(): IBotMessage | undefined {
    return findLast<IBotMessage>(this.messages as any, m => m.author === "bot")
  }

  @computed
  get userPrompt(): IPrompt | undefined {
    const lastMessage = this.messages[this.messages.length - 1]
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return lastMessage ? lastMessage.prompt : undefined
  }
}

if (process.env.REACT_APP__DEV_TOOLS__ === "enabled") {
  ChatStore.prototype.restart = action(function restart(this: ChatStore) {
    this.isTyping = false
    this.messages = []
    this.typingMessage = undefined
    this.updateSnapShots()
  })
}
