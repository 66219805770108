import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { DialogueIDs } from "../../DialogueIDs"
import {
  SuitableServicesSignpostScript,
  SuitableServicesSignpostScriptState
} from "./SuitableServicesSignpostScript"

type State = SuitableServicesSignpostScriptState
export type SuitableServicesSignpostCCGScriptState = State

export class SuitableServicesSignpostCCGScript extends SuitableServicesSignpostScript {
  readonly name: string = "SuitableServicesSignpostCCGScript"

  /** Script Steps */

  @step.logState
  sayWeDontSupportYourArea(d: IStepData<State>): IStepResult {
    const ccgService = this.rootStore.configStore.ccgService!
    const name = this.getName(d.state)
    return {
      body: this.t(
        [
          "So {name}, the NHS has set up Staff Wellbeing Hubs to support staff in different areas of the country",
          "You're currently on the Wellbeing Hub for {ccgService}",
          "This resource is only for NHS staff working in the {ccgService} area"
        ],
        { name, ccgService: ccgService.formattedName }
      ),
      prompt: {
        id: this.getPromptId("sayWeDontSupportYourArea"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Okay"), value: false },
          { body: this.t("I understand"), value: false },
          { body: this.t("What is available to me then?"), value: true }
        ]
      },
      nextStep: this.handleWeDontSupportYourArea
    }
  }

  @step.logState
  handleWeDontSupportYourArea(d: IStepData<State, boolean>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t(
        d.response ? "I'm glad you asked 😊" : ["Sorry about that {name}", "Don't worry though"],
        { name }
      ),
      nextStep: this.sayOtherOptions
    }
  }

  @step.logState
  sayOtherOptions(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "If you're an NHS staff member, you can find the right Wellbeing Hub for you [here](https://www.england.nhs.uk/supporting-our-nhs-people/support-now/staff-mental-health-and-wellbeing-hubs/)"
      ),
      nextStep: this.promptThanksOK
    }
  }
}

/* istanbul ignore next */
export default class SuitableServicesSignpostCCGDialogue extends Dialogue<State> {
  static id = DialogueIDs.SuitableServicesSignpostCCG
  readonly name: string = "SuitableServicesSignpostCCGDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      SuitableServicesSignpostCCGDialogue.id,
      new SuitableServicesSignpostCCGScript(),
      state,
      snapshot
    )
  }
}
