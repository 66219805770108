import {
  CheckPostCodeFromAddressLookupScript,
  CheckPostCodeFromAddressLookupScriptState
} from "./CheckPostCodeFromAddressLookupDialogue"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { step } from "../../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"

interface State extends CheckPostCodeFromAddressLookupScriptState {
  accommodationStatus?: string
}

export type CheckPostCodeFromAddressLookupSABPScriptState = State

export class CheckPostCodeFromAddressLookupSABPScript extends CheckPostCodeFromAddressLookupScript {
  readonly name: string = "CheckPostCodeFromAddressLookupSABPScript"

  /** Script Steps */

  @step.logState
  askPermissionToSendMailToAddress(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Brilliant!",
        "Can we contact you via regular mail about your treatment plan?"
      ]),
      prompt: {
        id: this.getPromptId("askPermissionToSendMailToAddress"),
        trackResponse: true,
        type: "checkbox",
        options: [
          {
            body: this.t("Yes, you can send me letters at home"),
            key: "canSendMailToAddress",
            initialValue: true
          }
        ]
      },
      nextStep: this.handlePermissionToSendMailToAddressCheckbox
    }
  }

  @step.logState
  async handlePermissionToSendMailToAddressCheckbox(
    d: IStepData<State, { canSendMailToAddress: boolean }>
  ): Promise<IStepResult> {
    d.state.canSendMailToAddress = d.response.canSendMailToAddress
    const result = await this.onHandlePermissionToSendMailToAddress?.(d.state)
    if (result) return result
    return { nextStep: this.end }
  }
}

export default class CheckPostCodeFromAddressLookupSABPDialogue extends AdHocDialogue<
  State,
  CheckPostCodeFromAddressLookupSABPScript
> {
  static id = DialogueIDs.CheckPostCodeFromAddressLookupSABP
  readonly name: string = "CheckPostCodeFromAddressLookupSABPDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      CheckPostCodeFromAddressLookupSABPDialogue.id,
      new CheckPostCodeFromAddressLookupSABPScript(),
      state,
      snapshot
    )
  }
}
