import invariant from "../../../../utils/invariant"
import formatUnicorn from "../../../../utils/formatUnicorn"
import type Script from "../../Script"
import type { ScriptState } from "../../Script"
import type { ISurveyResponse } from "../../../../models/ISurvey"
import type {
  IStep,
  IStepData,
  IStepResult,
  StepDecorator,
  StepDescriptor
} from "../../models/IStep"

function decorate<State extends ScriptState, S extends Script<State> = any>(
  step: IStep<State>,
  id: string,
  responsesKey: keyof State
): IStep<State> {
  return async function (this: S, d: IStepData<State, ISurveyResponse>): Promise<IStepResult> {
    if (d) {
      try {
        const name = this.getName(d.state)
        const title = formatUnicorn(d.response.title, { name })
        const item = { ...d.response, id, title }
        this.saveResponse(item, d.state, responsesKey)
      } catch (e) {
        this.logException(e, "step saveSurveyResponse decorator")
      }
    }
    return step.call(this, d)
  }
}

export function saveSurveyResponse<State extends ScriptState, S extends Script<State> = any>(
  id: string,
  responsesKey: keyof State
): StepDecorator<State, S> {
  invariant(id, "You can't save the response without an id [%s]", id)
  return function (target: S, key: keyof S, desc: StepDescriptor): StepDescriptor {
    const org = desc.value
    desc.value = decorate(org!, id, responsesKey)
    return desc
  }
}

saveSurveyResponse.decorate = decorate
